<template>
    <div>
    <v-icon color="#ffc107">mdi-star</v-icon>
    <v-icon color="#ffc107">mdi-star</v-icon>
    <v-icon color="#ffc107">mdi-star</v-icon>
    <v-icon color="#ffc107">mdi-star</v-icon>
    <v-icon color="#ffc107">mdi-star-half</v-icon>
    </div>
</template>

<script>
    export default {
        name: "FourPointFiveStars"
    }
</script>

<style scoped>

</style>
