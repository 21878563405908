<template>
    <div>
        <section
                style="padding: 45px 15px;display: flex;justify-content: center;flex-direction: column;align-items: center">
            <img src="https://i.postimg.cc/tC3dVsxF/66067470-2426644867399125-2551228964873961472-n.jpg"
                 alt="IVYED Interactive Academic Writing"
                 style="width: 100%;height: 210px;max-width: 340px;border-radius: 10px"/>
            <div style="margin: 20px 0px;display: flex;flex-direction: column;justify-content: space-evenly;height: 150px;">
                <span style="color: #E8344E;font-weight: bold">U of Chicago 석사 선생님</span>
                <h3 style="font-size: 24px;font-weight: 800;margin-bottom: 5px">Interactive Academic Writing</h3>
                <p style="color: rgba(0,0,0,0.7);font-size: smaller;">학년별 적절한 Writing Skill을 배워서 영어로<br/> 글쓰기 마스터해보자!
                </p>
            </div>
            <ul style="border-bottom: 1px solid rgba(0,0,0,0.1);padding: 20px 0px;border-top: 1px solid rgba(0,0,0,0.1);">
                <li class="study_info">
                    <span>💻</span><span style="font-weight: 500;place-self: start">강의 방식</span><span>100% 온라인 강의</span>
                </li>
                <li class="study_info">
                    <span>📒️</span><span style="font-weight: 500">Writing for beginners (초급)</span><span
                        style="color:#E8344E ">7~9학년</span>
                </li>
                <li class="study_info">
                    <span>📘</span><span style="font-weight: 500">Academic Writing I급 (중급)</span><span
                        style="color:#E8344E ">9~11학년</span>
                </li>
                <li class="study_info">
                    <span>📕️</span><span style="font-weight: 500">Academic Writing II (고급)</span><span
                        style="color:#E8344E ">10~12학년</span>
                </li>
                <li class="study_info">
                    <span>⏰</span><span style="font-weight: 500">과정</span><span
                        style="color:#E8344E;white-space: nowrap">단기반 신청 가능</span>
                </li>
            </ul>
            <v-btn to="/bootcamp/register"
                   style="background-color: #E8344E;color: white;width: 100%;margin-top: 30px;padding: 25px 0px;font-weight: 900;box-shadow: none;font-size: 16px;max-width: 380px">
                참가 신청하기
            </v-btn>
        </section>
        <v-toolbar
                style="color: rgba(0,0,0,0.5);background-color: white;top: 56px;box-shadow: none;font-weight: 500;border-bottom: 1px solid rgba(0,0,0,0.1);z-index: 99"
                class=" hidden-xl-only hidden-lg-only hidden-md-only hidden-sm-only sticky-toolbar text-center toolbar-style-mobile">
            <v-row style="display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;font-size: 14.5px;">
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center;"
                       cols="" class="hover_nav toolbar-menu"
                       @click="scrollto('lecture-introduction', { behavior: 'smooth' })">
                    강의소개
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="hover_nav toolbar-menu" @click="scrollto('teacher', { behavior: 'smooth' })">
                    선생님
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="toolbar-menu hover_nav" @click="scrollto('curriculum', { behavior: 'smooth' })">
                    커리큘럼
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="toolbar-menu hover_nav" @click="scrollto('review', { behavior: 'smooth' })">
                    후기
                </v-col>
            </v-row>
        </v-toolbar>
        <v-toolbar
                style="color: rgba(0,0,0,0.5);background-color: white;top: 56px;box-shadow: none;font-weight: 500;border-bottom: 1px solid rgba(0,0,0,0.1);z-index: 99"
                class=" hidden-xs-only hidden-xs sticky-toolbar text-center toolbar-style" sticky="" color="#494949">
            <v-row justify="center px-5">
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('lecture-introduction', { behavior: 'smooth' })">
                        강의소개
                    </div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('teacher', { behavior: 'smooth' })">선생님</div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('curriculum', { behavior: 'smooth' })">커리큘럼
                    </div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('review', { behavior: 'smooth' })">후기
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <!--        강의 소개-->
        <section id="lecture-introduction"
                 style="padding: 45px 15px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">강의소개</span></div>
            </div>

            <h3 style="margin-bottom: 50px;text-align: center">아래에 해당한다면 꼭 이 수업을 수강하세요!</h3>
            <div style="min-width: 320px;max-width: 650px">
                <div style="height: 70px;background-color: #FEF6F8;border: 1px solid #E8344E;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 10px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr;max-width: 600px">
                    <span style="color: #E8344E;place-self: center start">✔</span>
                    <span class="notice_why_study1">미국/캐나다 유학 중, Writing 관련 과제를 시행하는데 어려움을 겪고있나요?</span>
                </span>
                </div>
                <div style="height: 70px;background-color: #FEF6F8;border: 1px solid #E8344E;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 10px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr">
                    <span style="color: #E8344E;place-self: center start">✔</span>
                    <span class="notice_why_study">국제학교에서 Writing 평가를 하면 항상 낮은 점수를 받았나요?</span>
                </span>
                </div>
                <div style="height: 70px;background-color: #FEF6F8;border: 1px solid #E8344E;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 10px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr">
                    <span
                            style="color: #E8344E;place-self: center start">✔</span>
                    <span>미국대학입시에 써야할 Essay를 쓰는게 두렵나요?</span>
                </span>
                </div>
                <div style="height: 70px;background-color: #FEF6F8;border: 1px solid #E8344E;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 80px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr"><span
                        style="color: #E8344E;place-self: center start">✔</span>
                    <span>문법구조를 지켜가면서 Writing하는게 어렵나요?</span>
                    </span>
                </div>
            </div>

            <h3 style="margin-bottom: 50px;text-align: center">Writing, 아직 어렵게만 느껴진다면?</h3>
            <div style="height: 460px;background-color: #FEF6F8;border: 1px solid #E8344E;border-radius: 15px;padding: 25px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 80px;max-width: 530px">
                <h4 style="font-size: 18px;padding: 10px 0px;color: #E8344E;">Interactive Academic Writing!</h4>
                <p style="font-size: small;color: rgba(0,0,0,0.7);line-height: 22px;display: flex;flex-direction: column;justify-content: space-evenly;height: 70%">
                    <span>- 학문적 글쓰기의 깊이에 대한 통찰을 얻을 수 있는 집중 프로그램입니다.</span>
                    <span>- 학문적 글쓰기의 기초부터 고급 기술까지 포괄적으로 다루고, 학문적 글쓰기의 효과적인 전략과 기법을 전달합니다.</span>
                    <span>- 에세이 작성을 연습하면서 구조 및 문체 스타일 등을 습득할 수 있습니다.</span>
                    <span>- 논리적으로 주장하고 논증하는 능력을 향상시키는 데 도움을 줍니다.</span>
                    <span>- 학문적 주제를 탐구하며 효과적으로 표현하는 방법을 배울 수 있습니다.</span>
                </p>
            </div>

            <h3 style="margin-bottom: 50px;text-align: center;">Interactive Academic Writing<br/> 부트캠프란?</h3>
            <div style="height: 460px;background-color: #FEF6F8;border: 1px solid #E8344E;border-radius: 15px;padding: 25px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 80px;max-width: 530px">
                <h4 style="color: #E8344E;font-size: 18px;padding: 10px 0px">정예 참가자로만 구성</h4>
                <p style="font-size: small;color: rgba(0,0,0,0.7);line-height: 22px;display: flex;flex-direction: column;justify-content: space-evenly;height: 70%">
                    <span>- 아직도 대규모 특강에 현혹되고 계신가요?</span>
                    <span>- 아이비에드 Interactive Academic Writing 부트캠프에서는 시험기간까지 기초부터 탄탄하게 잡아주는 최고의 쌍방향 소통 부트캠프입니다.</span>
                    <span>- 단순한 지식 전달에 그치지 않습니다. 계속해서 질문하고 성장할 수 있게 실시간으로 교육합니다.</span>
                    <span>- 부트캠프 진행기간 동안 학교 과제까지 직접 도움받으며 실력을 쌓을 수 있습니다.</span>
                    <span>- 최소 4주간 수업과 실습으로 자신만의 실력을 갈고 닦으며 경험이 풍부한 선생님들께 도움을 받아 학생의 꿈을 보다 더 넓은 미래로 이끌어 보세요.</span>
                </p>
            </div>

            <h3>왜 이 수업을 들어야하나요?</h3>
            <ul style="margin-top: 25px;max-width: 600px;margin-bottom: 50px">
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">1</span>
                        <span style="font-weight: bolder;font-size: small">소규모 학습을 하는 학생들이 작성한 Writing글에 대한 선생님의 피드백을 들으면서 학습 효율을 극대화</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">소규모 그룹 학습 Interactive Writing학습</span>
                            <span>여러명이서 브레인스토밍 학습 가능</span>
                        </p>
                    </div>

                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">2</span><span
                            style="font-weight: bolder;font-size: small">5년간 Writing 수업을 통해서 얻은 노하우 제공</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);"> 직접 제작한 Writing Skills 학습 자료
                            제공 </p>
                    </div>

                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">3</span><span
                            style="font-weight: bolder;font-size: small">여기저기 분산된 Writing Skills 한번에 학습 가능</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column">
                            <span style="margin-bottom: 5px">학습효율 극대화를 위해서 모든 학습 교재들을 활용</span>
                            <span>단기간 Writing Skill up!</span>
                        </p>
                    </div>

                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">4</span><span
                            style="font-weight: bolder;font-size: small">여기저기 분산된 Writing Skills 한번에 학습 가능</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column">
                            <span>글쓰기 기술과 분석력을 향상시켜, 완벽하게 Writing을 할 수 있도록 지원합니다.</span>
                        </p>
                    </div>

                </li>
            </ul>

            <h3>이 수업을 듣고나면</h3>
            <ul style="margin-top: 25px;max-width: 600px;margin-bottom: 50px">
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">1</span>
                        <span style="font-weight: bolder;font-size: small">Writing에 관련된 과목에서 높은 점수를 받을 수 있습니다.</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">Writing 시험 평가 및 숙제에서 높은 성적을 받을 수 있을 것입니다.</span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">2</span>
                        <span style="font-weight: bolder;font-size: small">단기간 내 Writing Skill 향상</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">짧은 기간 동안 학생들이 Writing Skill을 향상시키도록 지원합니다</span>
                            <span>단기간 내 스킬 향상으로 Writing에 자신감을 가질 수 있을 것입니다.</span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">3</span>
                        <span style="font-weight: bolder;font-size: small">Writing의 즐거움</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span>영어로 글을 작성하는 것에 큰 즐거움을 찾을 수 있을 것입니다.</span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">4</span>
                        <span style="font-weight: bolder;font-size: small">추후 미국 대학 입시에서 높은 평가</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span>입시 Essay에서 배운 Writing Skill을 사용하여 Admission 위원들에게 강력한 인상을 주고 높은 평가를 받을 수 있을 것입니다.</span>
                        </p>
                    </div>
                </li>
            </ul>

            <h3>학년별 Writing 반</h3>
            <div style="font-size: 12px;padding:10px 20px;max-width: 600px;display: flex;flex-direction: column;color: rgba(0,0,0,0.6)">
                <span>Interactive Academic Writing은 각 난이도별로 수업이 구성되어있습니다.</span>
                <span>추천 학년이 아니더라도 더 낮은 단계의 Writing 수업을 원하신다면 신청 가능합니다.</span>
            </div>
            <ul style="margin-top: 25px;max-width: 600px;margin-bottom: 20px">
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">1</span>
                        <span style="font-weight: bolder;font-size: small">7~9학년 대상 Writing for beginners</span>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">2</span>
                        <span style="font-weight: bolder;font-size: small">9~11학년 대상 Academic Writing l</span>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #FEF6F8;color: #E8344E;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">3</span>
                        <span style="font-weight: bolder;font-size: small">10~12학년 대상 Academic Writing ll</span>
                    </div>
                </li>
            </ul>


        </section>
        <!--        선생님 소개-->
        <section id="teacher"
                 style="padding: 45px 15px;display: flex;flex-direction: column;align-items: center;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">선생님</span></div>
            </div>
<!--            <h3 style="padding-bottom: 50px">선생님 소개</h3>-->
            <div style="display: grid;grid-template-columns: 1fr 1fr;max-width: 600px">
                <img src="../../assets/teacher/김가영1.jpeg"
                     class="teacher_img"
                     alt="IVYED 담당 선생님 이미지">
                <div style="display: flex;flex-direction: column;justify-content: space-between;padding:0px 20px;">
                    <h5 style="font-size: large;border-bottom: 1px solid rgba(0,0,0,0.2);padding-bottom: 15px">Kay
                        선생님</h5>
                    <img src="../../assets/school/chicagouniversitylogo.jpg" class="teacher_school_img"/>
                    <div class="teacher_info">University of Chicago Humanities Division 석사 출신
                    </div>
                    <div class="teacher_info">+5년 경력</div>
                </div>
            </div>
            <div style="margin-top: 20px;background-color: #F2F6F8;border-radius: 5px;padding: 20px;font-size: 15px;max-width: 600px">
                저는 현재 University of Chicago Humanities Division 석사 재학 중입니다. <br/>
                <br/>
                고려대학교, 대원외고를 졸업했으며 대학교 때 논문 우수상과 고등학교 때 영어에세이 대상을 수상했습니다.<br/>
                <br/>
                Writing Skill 재능을 활용하기 위해서 5년 넘게 중/고등학생을 대상으로 Academic Writing을 가르쳐 왔으며 여러 학생들의 글쓰기 능력 향상에 도움을
                줬습니다.<br/>
                <br/>
                영어로 글쓰는 것에는 Draft를 쓰는 것도 중요하지만 Feedback을 받고 그에 맞게 글을 수정하는 능력이 굉장히 중요합니다.<br/>
                <br/>
                그래서 항상 많은 Revision부분을 체크하여 항상 학생들의 글쓰기 능력에 맞춘 Feedback을 제공하여 실시간으로 발전할 수 있도록 도움을 주는 수업을 진행합니다.<br/>
                <br/>
                여러분들이 더 이상 영어로 글쓰는데에 두려움을 느끼지 않게 해드리겠습니다.
            </div>
            <h3 style="margin-top: 30px">수업 미리보기</h3>
            <iframe style="margin: 30px 0px;max-width: 560px" width="100%" height="330" src="https://www.youtube.com/embed/uSPhZYK2an4?si=k3lakLxNJB12rHlf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </section>
        <!--        커리큘럼-->
        <section id="curriculum" style="padding: 45px 15px;display: flex;flex-direction: column;align-items: center;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">커리큘럼</span></div>
            </div>
<!--            <h3 style="margin-bottom: 55px">상세 커리큘럼</h3>-->
            <h4>Writing for Beginners</h4>
            <div class="curriculum_description_info">
                <span style="font-weight: bolder;margin-bottom: 5px">Course Objectives (수업목표)</span>
                <div>
                    <div>- 문법, 구두법, 문장 구조에 대한 이해를 향상 시킵니다.</div>
                    <div>- 다양한 유형의 학술적 글쓰기에 대한 기본 요소들을 배웁니다.</div>
                    <div>- Research 능력 및 인용구 사용법을 학습합니다.</div>
                    <div>- 비판적 사고 및 분석능력을 향상 시킵니다.</div>
                    <div>- revision 및 editing 테크닉들을 공부합니다.</div>
                </div>
                <div>
                    <div>- Develop proficiency in grammar, punctuation, and sentence structure.</div>
                    <div>- Understand the basic elements of different types of academic writing.</div>
                    <div>- Learn effective research skills and citation methods.</div>
                    <div>- Enhance critical thinking and analytical skills.</div>
                    <div>- Practice revision and editing techniques to improve writing quality.</div>
                </div>
            </div>
            <div class="curriculum_container">
                <div class="curriculum_list_week">
                    <div @click="toggleList(1)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Introduction to Academic Writing </span>
                        <span style="font-size: 10px" v-if="showList[1]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[1]"
                        style="background-color: #F6F9FA;border-radius: 5px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Preview of upcoming classes</li>
                        <li>- Understanding the writing process: (prewriting, drafting, revising, editing, publishing)
                        </li>
                        <li>- Freewrite exercise #1</li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Write: Freewrite exercise #2</li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(2)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Introduction to Academic Writing 2</span>
                        <span style="font-size: 10px" v-if="showList[2]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[2]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Grammar basics: sentence
                            structure, parts of speech,
                            punctuation.
                        </li>
                        <li>- Developing a strong thesis
                            statement.
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Read: Kids Can’t Read
                            (New York Times)
                        </li>
                        <li>Write: Literacy
                            Autobiography draft 1
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(3)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Essay Structure</span>
                        <span style="font-size: 10px" v-if="showList[3]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[3]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Introduction to essay structure:
                            introduction, body paragraphs,
                            conclusion.
                        </li>
                        <li>- Writing engaging introductions and
                            conclusions.
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Rewrite: Literacy
                            Autobiography
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(4)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Argumentative Essays</span>
                        <span style="font-size: 10px" v-if="showList[4]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[4]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Narrative, descriptive, and
                            expository essays.
                        </li>
                        <li>- Incorporating textual evidence to
                            support arguments.
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Final Draft: Literacy
                            Autobiography
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(5)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Research Skills</span>
                        <span style="font-size: 10px" v-if="showList[5]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[5]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Conducting effective research
                            using various sources: books,<br/>
                            articles, online databases.
                        </li>
                        <li>- Evaluating sources for credibility
                            and relevance.
                        </li>
                        <li>- Brainstorm final paper</li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Brainstorm: academic
                            essay, research potential
                            topics
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(6)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Research Skills 2</span>
                        <span style="font-size: 10px" v-if="showList[6]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[6]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Proper citation methods (MLA or
                            APA) and creating a bibliography.
                        </li>
                        <li>- Avoiding plagiarism: understanding
                            and using paraphrasing and quoting
                            techniques.
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic essay: find at
                            least 3 credible sources for
                            your essay and make
                            annotations
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(7)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Report Writing</span>
                        <span style="font-size: 10px" v-if="showList[7]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[7]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Structure of a research report:
                            abstract, introduction, methodology,
                            results, conclusion.
                        </li>
                        <li>- Presenting data and findings
                            effectively.
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic essay: create an
                            outline
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(8)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Report Writing 2</span>
                        <span style="font-size: 10px" v-if="showList[8]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[8]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Using visual aids: charts, graphs,
                            tables.
                        </li>
                        <li>- Peer review and collaborative
                            editing.
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic Essay: write
                            introduction and first
                            paragraph + citations
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(9)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Writing Workshop</span>
                        <span style="font-size: 10px" v-if="showList[9]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[9]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- writing workshop for final essay
                        </li>
                        <li>- revise introduction (thesis
                            statement) and citations
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic Essay: write the
                            second paragraph and
                            conclusion + citations
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(10)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Revision</span>
                        <span style="font-size: 10px" v-if="showList[10]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[10]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Importance of revision: self-editing
                            techniques, peer editing, teacher
                            feedback.
                        </li>
                        <li>
                            - Present paper in class
                        </li>
                    </ul>
                </div>
            </div>

            <h4 style="margin-top: 105px">Academic Writing I</h4>
            <div style="padding: 20px;
                        background-color: #F6F9FA;
                        font-size: small;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        margin-top:20px;
                        border-radius: 5px;
                        max-width: 700px;
">
                <span style="font-weight: bolder;margin:30px 0px">Course Objectives (수업목표)</span>
                <div style="margin-bottom: 30px">
                    <div>- 글쓰기 이해력, 해석능력, 정보분석 능력향상을 시킵니다.</div>
                    <div>- 글을 쓰는데 있어 필수 및 중요과정 (prewriting, drafting, revision, editing) 에 대한 이해를 학습합니다.</div>
                    <div>- 독자들을 위해 글을 작성하는 방법을 개발하고 어떻게 독자, 목적, 언어에따라 작가가 다양한 기법을 택할 수 있었는지에 대해 배우게 됩니다.</div>
                    <div>- 영어 언어의 표준 문법, 구두법, 문장 구조 등을 학습하여 첨삭 능력을 키웁니다.</div>
                    <div>- 다양한 학술적 문헌들을 활용하여 research paper writing을 연습하게 됩니다.</div>
                    <div>- 교과서: Patterns Plus: A Short Prose Reader with Argumentation (Ninth Edition) by Mary Lou
                        Conlin
                    </div>
                </div>
                <div style="margin-bottom: 30px">
                    <div>- Students will effectively express their understanding, interpretation, and analysis of
                        information.
                    </div>
                    <div>- Students will understand and use the major steps (prewriting, drafting, revision, editing) in
                        the writing process.
                    </div>
                    <div>- Students will develop a sense of writing for an audience and understand how the audience,
                        purpose, and language can influence the writer’s choices.
                    </div>
                    <div>- Students will edit using standard principles of grammar, punctuation, mechanics, and usage of
                        the English language.
                    </div>
                    <div>- Students will conduct research using credible and academic scholarly literature.</div>
                    <div>- Textbook (pdf provided): Patterns Plus: A Short Prose Reader with Argumentation (Ninth
                        Edition) by Mary Lou Conlin
                    </div>
                </div>
            </div>
            <div class="curriculum_container">
                <div class="curriculum_list_week">

                    <div @click="toggleList(11)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">What is literacy?</span>
                        <span style="font-size: 10px" v-if="showList[11]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[11]"
                        style="background-color: #F6F9FA;border-radius: 5px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Preview of upcoming classes</li>
                        <li>- Discussion: What is Literacy</li>
                        <li>- Freewrite exercise #1</li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Read: A New Literacy by
                            Thessaly La Force (The
                            New Yorker)
                            Write: Response essay
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(12)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder"> The Basics of Writing: Process and Strategies</span>
                        <span style="font-size: 10px" v-if="showList[12]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[12]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Text discussion: identify the writer’s
                            argument and reasoning
                        </li>
                        <li>- Lecture: The Basics of Writing
                        </li>
                        <li>- Response essay feedback
                        </li>
                        <li>- Literacy Autobiography
                            brainstorming
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Freewrite exercise #2
                            (Topic: what are you looking
                            forward to this summer?)
                            10-minutes
                        </li>
                        <li>Write: Literacy
                            Autobiography draft 1
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(13)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Narration</span>
                        <span style="font-size: 10px" v-if="showList[13]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[13]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- draft 1 feedback
                        </li>
                        <li>- Lecture: how to write a narrative
                            essay
                        </li>
                        <li>- Text discussion: Why Harlem is not
                            Manhattan
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Write: Literacy
                            Autobiography final draft
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(14)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Description</span>
                        <span style="font-size: 10px" v-if="showList[14]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[14]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Lecture: strategies on how to write
                            a good descriptive essay
                        </li>
                        <li>- Text discussion: Overindulgence
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Read: The Hiroshima
                            Museum by Barbara
                            Kingsolver
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(15)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Classification
and Division</span>
                        <span style="font-size: 10px" v-if="showList[15]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[15]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Final draft feedback</li>
                        <li>- Homework review</li>
                        <li>- Lecture: Classification and Division</li>
                        <li>- Brainstorm academic essay</li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Read: Eggs, Twinkies, and
                            Ethnic Stereotypes by
                            Jeanne Park and Answer
                        </li>
                        <li style="font-size: 12px;font-weight: bolder">Questions</li>
                        <li>
                            brainstorm: academic
                            essay, research potential
                            topics
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(16)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Argumentation
and Persuasion</span>
                        <span style="font-size: 10px" v-if="showList[16]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[16]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Homework review
                        </li>
                        <li>- Lecture: argumentation and
                            Persuasion
                        </li>
                        <li>- Brainstorm academic essay
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic essay: find at
                            least 3 credible sources for
                            your essay and make
                            annotations
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(17)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Fallacies in reasoning</span>
                        <span style="font-size: 10px" v-if="showList[17]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[17]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- How to write an annotated
                            bibliography
                        </li>
                        <li>- Homework review
                        </li>
                        <li>- Fallacies in reasoning
                        </li>
                        <li>- How to create an outline for your
                            essay
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic essay: create an
                            outline
                            Read: The Measure of Our
                            Success and answer
                            questions
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(18)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Cause and Effect</span>
                        <span style="font-size: 10px" v-if="showList[18]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[18]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- homework review
                        </li>
                        <li>- using cause-and-effect as a writing
                            strategy
                        </li>
                        <li>- infer implied cause-and-effect
                            relationships
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic Essay: write
                            introduction and first
                            paragraph + citations
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(19)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder"> Definition and Examples</span>
                        <span style="font-size: 10px" v-if="showList[19]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[19]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- homework review
                        </li>
                        <li>- finish cause-and-effect exercise
                            from last week
                        </li>
                        <li>- definition and examples</li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic Essay: write the
                            second paragraph and
                            conclusion + citations
                            Prepare for the final
                            conference presentation
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(20)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Final Conference</span>
                        <span style="font-size: 10px" v-if="showList[20]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[20]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- present final papers
                        </li>
                    </ul>
                </div>
            </div>

            <h4 style="margin-top: 105px">Academic Writing II</h4>
            <div style="padding: 20px;
                        background-color: #F6F9FA;
                        font-size: small;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        margin-top:20px;
                        border-radius: 5px;
                        max-width: 700px;
">
                <span style="font-weight: bolder;margin:30px 0px">Course Objectives (수업목표)</span>
                <div style="margin-bottom: 30px">
                    <div>- 투명성, 일관성 및 정교성을 중점으로 두며 고급 글쓰기 기술을 향상 시키게 됩니다.</div>
                    <div>- 다양한 학문 및 전문분야의 고급 문법, 구문 및 스타일을 master 하게 됩니다.</div>
                    <div>- 복잡한 글들의 깊이있는 해석 및 분석을 통해 비판적 사고능력을 강화 시키게 됩니다.</div>
                    <div>- 근거와 논리적 추론법에 의거하여 설득력있는 주장을 펼칠 수 있게 학습합니다.</div>
                    <div>- 정제된 최종 원고를 작성할때 필요한 개정 및 수정 기술들을 학습하게 됩니다.</div>
                    <div>- 교과서 : Patterns Plus: A Short Prose Reader with Argumentation (Ninth Edition) by Mary Lou Conlin
                    </div>
                </div>
                <div style="margin-bottom: 30px">
                    <div>- Develop advanced writing skills with a focus on clarity, coherence, and sophistication.
                    </div>
                    <div>- Master advanced grammar, syntax, and style for academic and professional contexts.
                    </div>
                    <div>- Enhance critical thinking skills through in-depth analysis and interpretation of complex texts.
                    </div>
                    <div>- Craft compelling and persuasive arguments using evidence and logical reasoning.
                    </div>
                    <div>- Improve revision and editing techniques for polished final drafts.
                    </div>
                    <div>- Textbook (pdf provided): Patterns Plus: A Short Prose Reader with Argumentation (Ninth Edition) by Mary Lou Conlin
                    </div>
                </div>
            </div>
            <div class="curriculum_container">
                <div class="curriculum_list_week">

                    <div @click="toggleList(21)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">What is literacy?</span>
                        <span style="font-size: 10px" v-if="showList[21]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[21]"
                        style="background-color: #F6F9FA;border-radius: 5px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Preview of upcoming classes</li>
                        <li>- Discussion: What is Literacy</li>
                        <li>- Freewrite exercise #1</li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Read: A New Literacy by
                            Thessaly La Force (The
                            New Yorker)
                            Write: Response essay
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(22)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Writing Strategies</span>
                        <span style="font-size: 10px" v-if="showList[22]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[22]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Review of essay structure:
                            introduction, body paragraphs,
                            conclusion.
                        </li>
                        <li>- Crafting clear and concise thesis
                            statements.
                        </li>
                        <li>- Literacy Autobiography
                            brainstorming
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Write: Literacy
                            Autobiography draft 1
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(23)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Narration</span>
                        <span style="font-size: 10px" v-if="showList[23]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[23]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- What is a “good” narrative essay?
                        </li>
                        <li>- draft 1 feedback
                        </li>
                        <li>- Text discussion: Why Harlem is not
                            Manhattan
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Write: Literacy
                            Autobiography final draft
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(24)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Research skills</span>
                        <span style="font-size: 10px" v-if="showList[24]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[24]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Advanced research methods:
                            qualitative vs. quantitative research.
                        </li>
                        <li>- Synthesizing information from
                            multiple sources.
                        </li>
                        <li>- Evaluating the credibility and bias
                            of sources.
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Read: Eggs, Twinkies, and
                            Ethnic Stereotypes by
                            Jeanne Park and Answer
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(25)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Citation</span>
                        <span style="font-size: 10px" v-if="showList[25]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[25]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Proper citation methods (MLA or
                            APA) and creating a bibliography.
                        </li>
                        <li>- Avoiding plagiarism: understanding
                            and using paraphrasing and quoting
                            techniques.
                        </li>
                        <li>- Start brainstorming final paper</li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Brainstorm: academic
                            essay, research potential
                            topics
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(26)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Argumentation
and Persuasion</span>
                        <span style="font-size: 10px" v-if="showList[26]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[26]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Advanced rhetorical devices:
                            ethos, pathos, logos.
                        </li>
                        <li>- Crafting persuasive arguments for
                            various audiences.
                        </li>

                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic essay: find at
                            least 3 credible sources for
                            your essay and make
                            annotations
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(27)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Fallacies in reasoning</span>
                        <span style="font-size: 10px" v-if="showList[27]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[27]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Fallacies in reasoning
                        </li>
                        <li>- How to write an annotated
                            bibliography
                        </li>
                        <li>- How to create an outline for your
                            essay
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic essay: create an
                            outline
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(28)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Cause and Effect</span>
                        <span style="font-size: 10px" v-if="showList[28]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[28]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- homework review
                        </li>
                        <li>- using cause-and-effect as a writing
                            strategy
                        </li>
                        <li>- infer implied cause-and-effect
                            relationships
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic Essay: write
                            introduction and two body
                            paragraphs + citations
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(29)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Revision and peer review</span>
                        <span style="font-size: 10px" v-if="showList[29]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[29]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Importance of revision: peer
                            editing, self-editing, teacher
                            feedback.
                        </li>
                        <li>- Revising essays and research
                            papers for publication.
                        </li>
                        <li>- Finalizing individual writing
                            projects.
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic Essay: write the
                            final paragraph and
                            conclusion + citations
                            Prepare for final conference
                            presentation
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">

                    <div @click="toggleList(30)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Final Conference</span>
                        <span style="font-size: 10px" v-if="showList[30]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[30]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Presentation of final projects to the
                            class.
                        </li>
                        <li style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"></li>
                        <li style="font-size: 12px;font-weight: bolder">Reading/Assignments</li>
                        <li>Academic Essay: revise</li>
                    </ul>
                </div>
            </div>

            <h3 style="margin-top: 80px">수업 일정</h3>

            <h3 style="margin: 30px 0px;text-align: center;">Interactive Academic Writing (KST)</h3>
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;max-width: 600px">
                <div style="display: grid;grid-template-rows:1.2fr 2fr 2fr;color: white;">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #E8344E;margin-bottom: 10px;font-weight: bolder;font-size: smaller;min-height: 50px">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일 및 시간</span>
                        <span style="place-self: center center">기간</span>
                        <span style="place-self: center center;padding-right: 15px">시간 및 횟수</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #FEF1F4;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">Writing for Beginners (초급)</span>
                        <span style="place-self: center center;text-align: center">토,일<br/>11:00~12:30<br/></span>
                        <span style="place-self: center center;text-align: center">1개월</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">90분<br/> 주 2회</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #FEF1F4;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">Academic Writing I (중급)</span>
                        <span style="place-self: center center;text-align: center">월,목<br/>21:00 ~ 22:30</span>
                        <span style="place-self: center center;text-align: center">1개월</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">90분<br/> 주 2회</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #FEF1F4;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder"><br/>Academic Writing II (고급)<br/><br/></span>
                        <span style="place-self: center center;text-align: center">화,금<br/>22:00 - 23:30</span>
                        <span style="place-self: center center;text-align: center">1개월</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">90분<br/> 주 2회</span>
                    </div>
                </div>
            </div>

            <h3 style="margin: 30px 0px;text-align: center">Interactive Academic Writing (PST)</h3>
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;max-width: 600px">
                <div style="display: grid;grid-template-rows:1.2fr 2fr 2fr;color: white;">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #E8344E;margin-bottom: 10px;font-weight: bolder;font-size: smaller">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일 및 시간</span>
                        <span style="place-self: center center">기간</span>
                        <span style="place-self: center center;padding-right: 15px">시간 및 횟수</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #FEF1F4;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">Writing for Beginners (초급)</span>
                        <span style="place-self: center center;text-align: center">금,토<br/>(서부)<br/>18:00~19:30<br/>(동부)<br/>21:00~22:30</span>
                        <span style="place-self: center center;text-align: center">1개월</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">90분<br/> 주 2회</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #FEF1F4;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">Academic Writing I (중급)</span>
                        <span style="place-self: center center;text-align: center">월,목<br/>(서부)<br/>04:00~05:30<br/>(동부)<br/>07:00~08:30</span>
                        <span style="place-self: center center;text-align: center">1개월</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">90분<br/> 주 2회</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #FEF1F4;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">Academic Writing II (고급)</span>
                        <span style="place-self: center center;text-align: center">화,금<br/>(서부)<br/>05:00~06:30<br/>(동부)<br/>08:00~09:30</span>
                        <span style="place-self: center center;text-align: center">1개월</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">90분<br/> 주 2회</span>
                    </div>
                </div>
            </div>

        </section>
        <!--        후기-->
        <section id="review"
                 style="display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;padding: 45px 15px;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">후기</span></div>
            </div>
<!--            <h3 style="font-size: x-large;margin-bottom: 35px">수강생 후기</h3>-->
            <div style="width: 300px;background-color: #F2F6F8;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;padding: 15px 28px;border-radius: 5px;margin-bottom: 50px">
                <h4 style="font-size: 15px;text-align: center;padding: 15px 0px">“Kay선생님의 Writing 수업은 정말로 Writing
                    능력을 향상시키는 데 큰 도움이 되었어요.” </h4>
                <img src="../../assets/avatar4.png" alt="IVYED student avatar4 img"
                     style="width: 140px;height: 140px;border-radius: 5px"/>
                <span style="font-size: small;padding-top: 10px">미국 보딩스쿨 10학년</span>
            </div>
            <div style="width: 300px;background-color: #F2F6F8;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;padding: 15px 28px;border-radius: 5px;margin-bottom: 50px">
                <h4 style="font-size: 15px;text-align: center;padding: 15px 0px">“선생님의 세심한 피드백으로 학교 과제를 잘 끝낼 수
                    있었어요.”</h4>
                <img src="../../assets/avatar3.png" alt="IVYED student avatar3 img"
                     style="width: 140px;height: 140px;border-radius: 5px"/>
                <span style="font-size: small;padding-top: 10px">미국 보딩스쿨 11학년</span>
            </div>
            <div style="width: 300px;background-color: #F2F6F8;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;padding: 15px 28px;border-radius: 5px;margin-bottom: 50px">
                <h4 style="font-size: 15px;text-align: center;padding: 15px 0px">“아이가 Writing에 자신감을 얻어서 영어로 글쓰는 것에 즐거움을
                    느끼고 있어요. 정말 감사합니다.”</h4>
                <img src="../../assets/avatar2.png" alt="IVYED student parent img"
                     style="width: 140px;height: 140px;border-radius: 5px"/>
                <span style="font-size: small;padding-top: 10px">국제학교 9학년 학부모님</span>
            </div>
        </section>
        <FloatingBootcampButton />
    </div>
</template>
<script>
    import FloatingBootcampButton from "./FloatingBootcampButton";
    export default {
        components: {FloatingBootcampButton},
        name: 'AcademicWriting',
        data() {
            // 변수 선언 및 값 할당
            return {
                formData: {
                    checkbox: '',
                    checkbox1: '',
                    checkbox2: '',
                },
                selectedItem: '',
                currentCourse: '',
                userResponse: '',
                addItemResponse: '',
                itemAdded: false,
                currentItem: '',
                showClassroomPage: true,
                showAssignmentPage: false,
                showSchedulePage: false,
                bootcampResponse: [],
                bootcampSchedule: '',
                addedToCart: false,
                showCartAddedDialog: false,
                hasSameItemAlready: false,
                popUpMessage: '',
                eightWeek: true,
                twelveWeek: false,
                showList: {
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false,
                    8: false,
                    9: false,
                    10: false,
                    11: false,
                    12: false,
                    13: false,
                    14: false,
                    15: false,
                    16: false,
                    17: false,
                    18: false,
                    19: false,
                    20: false,
                    21: false,
                    22: false,
                    23: false,
                    24: false,
                    25: false,
                    26: false,
                    27: false,
                    28: false,
                    29: false,
                    30: false,
                },
            };
        },
        methods: {
            // 중간 네비게이션 바 값들 클릭하면 스크롤 하는 메서드 1
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            // 중간 네비게이션 바 값들 클릭하면 스크롤 하는 메서드 2
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el.querySelector('.v-window__prev').querySelector('.v-btn').click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el.querySelector('.v-window__next').querySelector('.v-btn').click();
                    this.drag = false;
                    this.touch = false;
                }
            },
            // 다른 route / path 로 이동
            goToPaymentPage() {
                this.$router.push('/payment');
            },
            // 날짜 formatting 메서드
            formatDate(datetime) {
                const date = new Date(datetime);
                const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
                const formattedDate =
                    // date.getUTCFullYear() + "년 " +
                    months[date.getUTCMonth()] +
                    ' ' +
                    date.getUTCDate() +
                    '일(' +
                    this.getDayOfWeek(date.getUTCDay()) +
                    ') ' +
                    date.getUTCHours() +
                    ':' +
                    (date.getUTCMinutes() < 10 ? '0' : '') +
                    date.getUTCMinutes();

                return formattedDate;
            },
            // 한글로 요일 불러오는 헬퍼 메서드
            getDayOfWeek(day) {
                const days = ['일', '월', '화', '수', '목', '금', '토'];
                return days[day];
            },

            // 커리큘럼에서 8주 / 12주 활성/비활성화 하는 메서드
            showEightWeekCurriculum() {
                this.eightWeek = true;
                this.twelveWeek = false;
            },
            showTwelveWeekCurriculum() {
                this.eightWeek = false;
                this.twelveWeek = true;
            },

            // 장바구니 담는 메서드
            // 장바구니에 성공적으로 담길시 호출하는 메서드
            showAddedToCartMessage(message) {
                this.showCartAddedDialog = true;
                this.popUpMessage = message;
            },

            navigateToNewPage() {
                if (!this.store.loggedIn) {
                    this.$router.push('/signin');
                }

                // // 여기서 카트에 넣어야함
            },
            // 커리큘럼 토글
            toggleList(listNumber) {
                this.showList[listNumber] = !this.showList[listNumber];
            },
        },
    };
</script>
<style scoped>

    .label_place{
        padding-right: 265px;
        padding-bottom: 15px;
    }

    @media (min-width: 600px) {
        .label_place{
            padding-right: 425px;
        }
    }


    .label {
        z-index: 0;
        width: 65px;  /* 변경된 너비 */
        height: 30px; /* 변경된 높이 */
        box-sizing: content-box;
        padding-top: 8px; /* 변경된 패딩 */
        position: relative;
        background: #E8344E;
        color: white;
        font-size: 7px; /* 변경된 글꼴 크기 */
        letter-spacing: 0.15em; /* 변경된 글자 간격 */
        text-align: center;
        text-transform: uppercase;
    }

    .label:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 7px solid #eee; /* 변경된 선 높이 */
        border-left: 30px solid transparent; /* 변경된 왼쪽 삼각형 크기 */
        border-right: 30px solid transparent; /* 변경된 오른쪽 삼각형 크기 */
    }

    .curriculum_description_info {
        padding: 20px;
        background-color: #F6F9FA;
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 440px;
        margin-top: 20px;
        border-radius: 5px;
        max-width: 700px;
    }

    @media (min-width: 600px) {
        .curriculum_description_info {
            height: 350px;
        }
    }


    .notice_why_study {
        padding-left: 0px;
    }

    @media (min-width: 600px) {
        .notice_why_study {
            margin-left: -10px;
        }
    }

    @media (min-width: 600px) {
        .notice_why_study1 {
            margin-left: -15px;
        }
    }

    .teacher_info {
        font-size: small;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 5px;
    }

    @media (min-width: 600px) {
        .teacher_info {
            font-size: medium;
        }
    }

    .teacher_school_img {
        width: 100%;
        height: 80px;
        object-fit: contain
    }

    @media (min-width: 600px) {
        .teacher_school_img {
            height: 180px;
        }
    }

    .teacher_img {
        place-self: center center;
        border-radius: 15px;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    @media (min-width: 400px) {
        .teacher_img {
            width: 200px;
            height: 200px;
        }
    }

    @media (min-width: 600px) {
        .teacher_img {
            width: 300px;
            height: 300px;
        }
    }

    .curriculum_container {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 20px 25px 10px;
        margin-top: 25px;
        min-width: 350px;
    }

    @media (min-width: 380px) {
        .curriculum_container {
            min-width: 380px;
        }
    }

    @media (min-width: 400px) {
        .curriculum_container {
            min-width: 400px;
        }
    }

    @media (min-width: 600px) {
        .curriculum_container {
            min-width: 600px;
        }
    }

    @media (min-width: 700px) {
        .curriculum_container {
            min-width: 850px;
        }
    }

    .curriculum_list_week:last-child {
        border-bottom: none;
    }

    .curriculum_list_week {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-top: 8px;
        width: 100%;
        padding: 5px 5px;
    }

    *, html, ul, li {
        margin: 0;
        padding: 0;
        font-family: "Noto Sans KR";
    }

    .hover_nav {
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        cursor: pointer;
    }

    .hover_nav:hover {
        background-color: #E8344E;
        color: white;
    }

    .study_info {
        display: grid;
        grid-template-columns: 0.5fr 5fr 4.5fr;
        margin-bottom: 10px;
        gap: 5px;
    }

    .study_info span {
        font-size: small;
    }

    .curriculum_description li {
        border-radius: 5px;
        border: 1px solid #452DDD;
        padding: 30px;
    }

    .curriculum_click {
        background-color: #452DDD !important;
    }

    .curriculum_study_list li {
        font-size: small;
        color: rgba(0, 0, 0, 0.6);
        padding: 5px 5px;
    }

    .curriculum_list li {
        padding: 10px 20px;
        border-radius: 20px;
        color: white;
        background-color: #D8D3F8;
        font-weight: bolder;
        font-size: medium;
        cursor: pointer;
    }

    .target_benefit li span {
        font-size: small;
    }

    .target_benefit li h2 {
        color: #452DDD;
    }

    .target_benefit li {
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 5px;
    }

    .target_list li > span {
        padding: 0px 15px;
    }

    .target_list li {
        background-color: #F3F2FD;
        font-size: 16px;
        font-weight: bolder;
        max-width: 320px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .introduction_list li {
        background-color: white;
        border-radius: 15px;
        margin: 0px 10px;
        padding: 2px 10px;
    }

    * {
        list-style: none;
    }

    .sticky {
        background: blue;
        height: 50px;
        position: sticky;
        top: 0;
    }

    .toolbar-style-mobile {
        font-size: 14px;
        font-weight: bold;
        position: sticky;
        top: 40px;
    }

    .toolbar-style {
        font-family: S-CoreDream-6Bold;
        font-size: 18px;
        font-weight: bold;
    }

    .sticky-toolbar {
        color: white;
        z-index: 0;
        top: 64px;
        position: sticky;
        position: -webkit-sticky;
    }

</style>
