<template>
    <div class="use-case-section">
<!--        <div class="pt-5 use-case-section"></div>-->
        <SpeechBubbleTargetStudents></SpeechBubbleTargetStudents>
        <div style="background-color: white; padding-top: 80px">
            <div class="text-center pt-10 pb-8">
                <v-chip color="#A20916" class="white--text font-weight-bold">아이비에드 과외란?</v-chip>
            </div>
            <div style="font-size: 31px; line-height: 38px" class="font-weight-bold text-center">
                미국 명문대 출신 선생님과 <br/>
                언제 어디서든 1:1 유학 과외
            </div>
            <div class="py-2"></div>
            <div class="text-center font-weight-bold" style="font-size: 18px">학생의 상황과 니즈에 따라 <br/>100% 맞춤 1:1 수업으로 진행해요
            </div>
            <div class="py-2"></div>
            <div class="center-horizontal">
                <div class="hidden-sm-and-down">
                    <v-card style="background-color: #F6F6F6; border-radius: 15px; z-index: 0" class="text-center mx-9"
                            width="700px" elevation="0">
                        <!--                background-color: #F6F6F6-->
                        <div class="pa-2 text-center" style="">
                            <img style=" outline-style: solid; outline-width: thin; border-radius: 15px; outline-color: white"
                                 src="../../assets/trig_clip.gif" height="300"/>
                            <img style=" outline-style: solid; outline-width: thin; border-radius: 15px; outline-color: white"
                                 src="../../assets/ib_math.gif" height="300px"/>
                        </div>
                    </v-card>
                </div>
                <div class="hidden-md-and-up">
                    <v-card style="background-color: #F6F6F6; border-radius: 15px; z-index: 0" class="text-center mx-9" elevation="0">
                        <!--                background-color: #F6F6F6-->
                        <div class="pa-2 text-center" style="overflow: hidden">
                            <img style=" outline-style: solid; outline-width: thin; border-radius: 15px; outline-color: white"
                                 src="../../assets/trig_clip.gif" width="100%" max-height="300"/>
                            <img style=" outline-style: solid; outline-width: thin; border-radius: 15px; outline-color: white"
                                 src="../../assets/ib_math.gif" width="100%" max-height="300px"/>
                        </div>
                    </v-card>
                </div>

            </div>
            <div class="py-10"></div>
        </div>
    </div>

</template>

<script>
    // import HorizontalCarousel from "./HorizontalCarousel";
    import SpeechBubbleTargetStudents from "./SpeechBubbleTargetStudents";

    export default {
        name: "UseCase",
        components: {
            SpeechBubbleTargetStudents,
        }
    }
</script>

<style scoped>
    .iphone, .ipad {
        display: block;
        color: #fff;
        text-align: center;
        font-family: 'SF Pro Display', Helvetica Neue, Arial, sans-serif;
        letter-spacing: -0.022em;
        font-size: 2.5vmin;
        text-shadow: 0 0 0.5em #000;
        background-color: #000;
        background-size: auto 200%, cover;
        background-repeat: no-repeat, no-repeat;
        border: solid #111;
        margin-bottom: 2em;
        position: relative;
        box-shadow: 0 0.5em 2em 0.2em rgba(0, 0, 0, 0.33), 0 0 0 0.5px #000 inset;
        transition: all 0.1s linear, line-height 0s linear;
        background-position: 50% 100%, center;
        transform-origin: bottom center;
    }

    .iphone:hover, .ipad:hover {
        color: transparent;
        text-shadow: 0 0 0em transparent;
        background-position: 50% -100%, center center;
        transition: all 0.15s linear, line-height 5s linear;
        line-height: 0vmin;
    }

    .ipad {
        margin-left: 2em;
        height: 85.8vmin;
        width: 60vmin;
        line-height: 85.8vmin;
        border-width: 3vmin;
        border-radius: 4.2857142857vmin;
        background-image: linear-gradient(rgba(0, 0, 0, 0), #111), url(https://resource.mathpresso.io/class-storage/web/assets/videos/lp-tablet-video.webm);
        z-index: 100;
    }

    .use-case-section {
        background-color: #F2F2F2;
    }

    h1 {
        padding: 30px 0 8px;
        position: relative;
    }

    h1::before {
        content: "";
        position: absolute;
        left: 7%;
        bottom: 16px;
        width: 150px;
        height: 14px;
        transform: skew(-12deg) translateX(-50%);
        background: rgba(238, 111, 87, 0.5);
        z-index: -1;
    }

    .use-case-underline {
        padding: 2rem;
        max-width: 14rem;
        background-color: #f2f2f2;
        border-radius: 4px;
        max-width: 50rem;
    }

    .fancy {
        -webkit-text-decoration: hotpink double underline;
        text-decoration: hotpink double underline;
    }

    .super {
        -webkit-text-decoration: peru solid overline;
        text-decoration: peru solid overline;
        -webkit-text-decoration-skip: objects;
        text-decoration-skip: objects;
    }

    .ink {
        -webkit-text-decoration: #DE2828 solid underline;
        text-decoration: #DE2828 solid underline;
        -webkit-text-underline-position: auto;
        text-underline-position: auto;
        text-decoration-thickness: 8px;
        bottom: 10px;
        border-radius: 30px;

    }

    .auto {
        -webkit-text-decoration: slateblue solid underline;
        text-decoration: slateblue solid underline;
        -webkit-text-underline-position: auto;
        text-underline-position: auto;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>
