<template>
<!--    <HomeTest></HomeTest>-->
<!--    <HomeTestNew></HomeTestNew>-->
    <LandingPage></LandingPage>


</template>

<script>
    import info from "../../info";
    import LandingPage from "./Intro/newLanding/LandingPage";
    // import HomeTest from "./Intro/HomeTest";
    // import HomeTestNew from "./Intro/HomeTest-new";
    // import SmartMatching from "./SmartMatching";
    // import FAQ from "../views/pages/Faq";
    // import MarketingHome from "../views/pages/Marketing-Home";
    // import { Flicking } from "@egjs/vue-flicking";

    export default {
        name: "Home",
        components:
            {
                LandingPage
                // HomeTestNew,
                // HomeTest
                // MarketingHome,
                // FAQ,
                // SmartMatching,
                // Flicking: Flicking
            },
        metaInfo() {
            return {
                title: this.pageTitle,
                meta: [
                    {
                        property: 'og:title',
                        content: this.ogTitle,
                    },
                    {
                        property: 'og:description',
                        content: this.ogDescription,
                    },
                    // <meta property="og:image" content="https://i.postimg.cc/sfYFZy6z/ogpic.png" />
                    {
                        property: 'og:image',
                        content: this.ogImage,
                    }
                ],
            };
        },
        created() {
            // Fetch data from an API or perform any logic to update meta information
            this.pageTitle = 'SAT,AP,IB,미국내신 등 1%와 1:1 과외-IVYED 아이비에드';
            this.ogTitle = 'SAT,AP,IB,미국내신 등 1%와 1:1 과외-IVYED 아이비에드';
            this.ogDescription = '미국 상위 1% 명문대 선생님과 1:1 온라인 유학 과외. 미국 중/고등학교 내신관리, AP, SAT, ACT 등 모든 시험 완벽 준비';
            this.ogImage = 'https://i.postimg.cc/sfYFZy6z/ogpic.png';
        },
        data() {
            return {
                move: [],
                drag: false,
                touch: false,
                content: "",
                data: info.home,
                items: [
                    {
                        src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg"
                    },
                    {
                        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg"
                    },
                ],
                pageTitle: 'Initial Page Title',
                ogTitle: 'Initial OG Title',
                ogDescription: 'Initial OG Description',
                ogImage: 'Initial image'

            };
        },
        methods: {
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__prev")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__next")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
            }
        },
        mounted() {
            // For touch devices
            this.$refs.myCarousel.$el.addEventListener("touchmove", (e) => {
                this.drag = false;
                this.touch = true;
                this.logic(e);
            });
            // eslint-disable-next-line no-unused-vars
            window.addEventListener("touchend", (e) => {
                this.move = [];
            });

            // For non-touch devices
            this.$refs.myCarousel.$el.addEventListener("mousedown", (e) => {
                this.drag = true;
                this.touch = false;
                this.logic(e);
            });
            this.$refs.myCarousel.$el.addEventListener("mousemove", (e) => {
                this.drag ? this.logic(e) : null;
            });
            // eslint-disable-next-line no-unused-vars
            window.addEventListener("mouseup", (e) => {
                this.drag = false;
                this.touch = false;
                this.move = [];
            });


        },
    };
</script>
<style scoped lang="css">
    @import "../styles/Home.css";
</style>
