import { render, staticRenderFns } from "./NewFaq.vue?vue&type=template&id=6af68ed0&scoped=true&style=background-color%3A%20%23f6f6f6&"
import script from "./NewFaq.vue?vue&type=script&lang=js&"
export * from "./NewFaq.vue?vue&type=script&lang=js&"
import style0 from "./NewFaq.vue?vue&type=style&index=0&id=6af68ed0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af68ed0",
  null
  
)

export default component.exports