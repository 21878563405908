import axios from "axios";
import authHeader from "./auth-header";
import info from "../../info";


//prod
const API_URL = info.urls.production +'lesson/';
// const API_URL = info.urls.local +'lesson/';

// const API_URL = info.urls.local + 'lesson/';
// const API_URL = 'https://backend-ie.herokuapp.com/api/lesson/';
// https://backend-ie.herokuapp.com/api/test/all
class UserService {

    // getAllUsers() {
    //     return axios.get(API_URL, { headers: authHeader() });
    // }
    //
    // getAllUserStudents() {
    //     return axios.get(API_URL + 'students', { headers: authHeader() });
    // }
    //
    // getAllUserTeachers() {
    //     return axios.get(API_URL + 'teachers', { headers: authHeader() });
    // }
    //
    // getPublicContent() {
    //     return axios.get(API_URL + 'all');
    // }
    //
    // getUserBoard() {
    //     return axios.get(API_URL + 'user', { headers: authHeader() });
    // }
    // getTeacherBoard() {
    //     return axios.get(API_URL + 'teacher', { headers: authHeader() });
    // }
    //
    // getStudentBoard() {
    //     return axios.get(API_URL + 'student', { headers: authHeader() });
    // }
    //
    // getModeratorBoard() {
    //     return axios.get(API_URL + 'mod', { headers: authHeader() });
    // }
    //
    // getAdminBoard() {
    //     return axios.get(API_URL + 'admin', { headers: authHeader() });
    // }
    //
    updateUserForAdmin(userId, requestBody) {
        return axios.put(API_URL + "admin/user/" + userId, requestBody, {headers: authHeader()});
    }

    createEmptyLesson(requestBody) {
        return axios.post(API_URL + "admin", requestBody, {headers: authHeader()});
    }

    createNewLesson(requestBody) {
        return axios.post(API_URL + "admin/match", requestBody, {headers: authHeader()});
    }

    updateLesson(lessonId, requestBody) {
        return axios.put(API_URL + + lessonId, requestBody, {headers: authHeader()});
    }

    getStudentInfoByLesson(lessonId) {
        return axios.get(API_URL + lessonId+ "/student", {headers: authHeader()});
    }
    getTeacherInfoByLesson(lessonId) {
        return axios.get(API_URL + lessonId+ "/teacher", {headers: authHeader()});
    }

    getLessonInfoByTeacherId(teacherId) {
        return axios.get(API_URL +  "teacher/" + teacherId, {headers: authHeader()});
    }
    getLessonInfoByStudentId(studentId) {
        return axios.get(API_URL +  "student/" + studentId, {headers: authHeader()});
    }

    makeLessonAppointment(lessonId, requestBody) {
        return axios.put(API_URL + lessonId + "/appointment", requestBody, {headers: authHeader()});
    }

    updatePageCallLink(lessonId, requestBody) {
        return axios.put(API_URL + lessonId + "/whiteboard", requestBody, {headers: authHeader()});
    }

    updateFeedbackReportLink(lessonId, requestBody) {
        return axios.put(API_URL + lessonId + "/feedback/report", requestBody, {headers: authHeader()});
    }

    updateLinkToVideo(lessonId, requestBody) {
        return axios.put(API_URL + lessonId + "/video/link", requestBody, {headers: authHeader()});
    }

    updateLessonToScheduled(lessonId) {
        return axios.put(API_URL + lessonId + "/admin/schedule/finalize",{headers: authHeader()});
    }

    updateLessonToCancelled(lessonId, requestBody) {
        return axios.put(API_URL + lessonId + "/admin/cancel",requestBody,{headers: authHeader()});
    }



    getLessonInfo(lessonId) {
        return axios.get(API_URL + lessonId, {headers: authHeader()});
    }

    getAllLessons() {
        return axios.get(API_URL, {headers: authHeader()});

    }
}

export default new UserService();
