<template>
<!--
배경색 : rgba(247, 229, 247, 0.2)
강조 : #8B008B

-->
    <div>
        <section
                style="padding: 45px 15px;display: flex;justify-content: center;flex-direction: column;align-items: center;background-color: rgba(247, 229, 247, 0.2)">
            <img src="https://i.postimg.cc/4nksDZvG/maxresdefault.jpg" alt="IVYED AP Biology"
                 style="width: 100%;height: 210px;max-width: 340px;border-radius: 10px"/>
            <div style="margin: 20px 0px;display: flex;flex-direction: column;justify-content: space-evenly;height: 150px;">
                <span style="color: #8B008B;font-weight: bold">Emory University Biology 전공</span>
                <h3 style="font-size: 24px;font-weight: 800;margin-bottom: 5px">AP Biology</h3>
                <p style="color: rgba(0,0,0,0.7);font-size: smaller;">AP Biology, Biology전문 선생님과 함께 개념정리하자!
                </p>
            </div>
            <ul style="border-bottom: 1px solid rgba(0,0,0,0.1);padding: 20px 0px;border-top: 1px solid rgba(0,0,0,0.1);">
                <li class="study_info">
                    <span>💻</span><span style="font-weight: 500;place-self: start">강의 방식</span><span>100% 온라인 강의</span>
                </li>
                <li class="study_info">
                    <span>📒️</span><span style="font-weight: 500">AP Biology </span><span
                        style="color:#8B008B ">평일 + 주말반</span>
                </li>
                <li class="study_info">
                    <span>📘</span><span style="font-weight: 500">AP Biology</span><span
                        style="color:#8B008B ">주말반</span>
                </li>
            </ul>
            <v-btn to="/bootcamp/register"
                   style="background-color: #8B008B;color: white;width: 100%;margin-top: 30px;padding: 25px 0px;font-weight: 900;box-shadow: none;font-size: 16px;max-width: 380px">
                참가 신청하기
            </v-btn>
        </section>
        <v-toolbar
                style="color: rgba(0,0,0,0.5);background-color: white;top: 56px;box-shadow: none;font-weight: 500;border-bottom: 1px solid rgba(0,0,0,0.1);z-index: 99"
                class=" hidden-xl-only hidden-lg-only hidden-md-only hidden-sm-only sticky-toolbar text-center toolbar-style-mobile">
            <v-row style="display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;font-size: 14.5px;">
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center;"
                       cols="" class="hover_nav toolbar-menu"
                       @click="scrollto('lecture-introduction', { behavior: 'smooth' })">
                    강의소개
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="hover_nav toolbar-menu" @click="scrollto('teacher', { behavior: 'smooth' })">
                    선생님
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="toolbar-menu hover_nav" @click="scrollto('curriculum', { behavior: 'smooth' })">
                    커리큘럼
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="toolbar-menu hover_nav" @click="scrollto('review', { behavior: 'smooth' })">
                    후기
                </v-col>
            </v-row>
        </v-toolbar>
        <v-toolbar
                style="color: rgba(0,0,0,0.5);background-color: white;top: 56px;box-shadow: none;font-weight: 500;border-bottom: 1px solid rgba(0,0,0,0.1);z-index: 99"
                class=" hidden-xs-only hidden-xs sticky-toolbar text-center toolbar-style" sticky="" color="#494949">
            <v-row justify="center px-5">
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('lecture-introduction', { behavior: 'smooth' })">
                        강의소개
                    </div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('teacher', { behavior: 'smooth' })">선생님</div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('curriculum', { behavior: 'smooth' })">커리큘럼
                    </div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('review', { behavior: 'smooth' })">후기
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <!--        강의 소개-->
        <section id="lecture-introduction"
                 style="padding: 45px 15px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">강의소개</span></div>
            </div>

            <h3 style="margin-bottom: 50px;text-align: center">아래에 해당한다면 꼭 이 수업을 수강하세요!</h3>
            <div style="min-width: 320px;max-width: 650px">
                <div style="height: 70px;background-color: rgba(247, 229, 247, 0.2);border: 1px solid #8B008B;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 10px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr;max-width: 600px">
                    <span style="color: #8B008B;place-self: center start">✔</span>
                    <span class="notice_why_study">AP Biology 시험을 볼 예정인데, Bio에 대한 지식이 부족한 학생!</span>
                </span>
                </div>
                <div style="height: 70px;background-color: rgba(247, 229, 247, 0.2);border: 1px solid #8B008B;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 10px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr">
                    <span style="color: #8B008B;place-self: center start">✔</span>
                    <span class="notice_why_study">AP Biology에 대한 흥미가 없지만 입시를 위해서는 꼭 해야만하는 학생!</span>
                </span>
                </div>

                <div style="height: 70px;background-color: rgba(247, 229, 247, 0.2);border: 1px solid #8B008B;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 80px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr"><span
                        style="color: #8B008B;place-self: center start">✔</span>
                    <span>내용이 방대해서 어떤 것 부터 먼저해야할지 모르는 학생!</span>
                    </span>
                </div>
            </div>

            <h3 style="margin-bottom: 50px;text-align: center">AP Biology, 아직 어렵게만 느껴진다면?</h3>
            <div style="height: 460px;background-color: rgba(247, 229, 247, 0.2);border: 1px solid #8B008B;border-radius: 15px;padding: 25px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 80px;max-width: 530px">
                <h4 style="font-size: 18px;padding: 10px 0px;color: #8B008B;">AP Biology 개념완성반!</h4>
                <p style="font-size: small;color: rgba(0,0,0,0.7);line-height: 22px;display: flex;flex-direction: column;justify-content: space-evenly;height: 70%">
                    <span>- 단기간 AP Biology 탄탄한 기초 완성을 도와드립니다.</span>
                    <span>- Biology관련 경력만 4년 이상인 생물학 전공 선생님이 지난 10년간의 기출, 최근 캠벨에서 실리고 있는 연구주제들을 분석하여 중요도 순서대로 강의합니다!</span>
                    <span>- 개념을 배우면서도 시험준비를 할 수 있는 AP Biology의 종합적 학습!</span>
                    <span>- 중요히 여기는 토픽들의 개념을 정확하게 이해시키고, 문제풀이까지 강의합니다.</span>
                    <span>- 각 AP Biology 단원 별 연계 Flow 학습과 이해를 도와드립니다.</span>
                </p>
            </div>

            <h3 style="margin-bottom: 50px;text-align: center;">AP Biology 부트캠프란?</h3>
            <div style="height: 460px;background-color: rgba(247, 229, 247, 0.2);border: 1px solid #8B008B;border-radius: 15px;padding: 25px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 80px;max-width: 530px">
                <h4 style="color: #8B008B;font-size: 18px;padding: 10px 0px">정예 참가자로만 구성</h4>
                <p style="font-size: small;color: rgba(0,0,0,0.7);line-height: 22px;display: flex;flex-direction: column;justify-content: space-evenly;height: 70%">
                    <span>- 아직도 대규모 특강에 현혹되고 계신가요?</span>
                    <span>- 아이비에드 AP Biology 부트캠프에서는 시험기간까지 기초부터 탄탄하게 잡아주는 최고의 쌍방향 소통 부트캠프입니다.</span>
                    <span>- 단순한 지식 전달에 그치지 않습니다. 계속해서 질문하고 성장할 수 있게 실시간으로 교육합니다.</span>
                    <span>- 부트캠프 진행기간 동안 학교 과제까지 직접 도움받으며 실력을 쌓을 수 있습니다.</span>
                    <span>- 최소 4주간 수업과 실습으로 자신만의 실력을 갈고 닦으며 경험이 풍부한 선생님들께 도움을 받아 학생의 꿈을 보다 더 넓은 미래로 이끌어 보세요.</span>
                </p>
            </div>

            <h3>왜 이 수업을 들어야하나요?</h3>
            <ul style="margin-top: 25px;max-width: 440px;margin-bottom: 50px">
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: rgba(247, 229, 247, 0.2);color: #8B008B;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">1</span>
                        <span style="font-weight: bolder;font-size: small">교과서만 줄줄이 읽는 강의 X</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">같이 학습하는 친구들, 선생님과 함께 interactive 학습</span>
                            <span>Biology에 대한 전반적인 흥미를 향상할 수 있는 강의</span>
                        </p>
                    </div>

                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: rgba(247, 229, 247, 0.2);color: #8B008B;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">2</span><span
                            style="font-weight: bolder;font-size: small">4년간 Bio 관련 경력에서 얻은 Biology 지식을 쉽게 이해하는 노하우 제공</span>
                        <span></span>
                    </div>

                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: rgba(247, 229, 247, 0.2);color: #8B008B;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">3</span><span
                            style="font-weight: bolder;font-size: small">직접 제작한 AP Biology 학습 자료 제공</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column">
                            <span style="margin-bottom: 5px">AP Biology의 flow 정리 후 자유응답섹션 대비</span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: rgba(247, 229, 247, 0.2);color: #8B008B;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">4</span><span
                            style="font-weight: bolder;font-size: small">직접 제작한 AP Biology 학습 자료 제공</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column">
                            <span style="margin-bottom: 5px">AP Biology의 flow 정리 후 자유응답섹션 대비</span>
                        </p>
                    </div>
                </li>
            </ul>

            <h3>이 수업을 듣고나면</h3>
            <ul style="margin-top: 25px;max-width: 440px;margin-bottom: 50px">
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: rgba(247, 229, 247, 0.2);color: #8B008B;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">1</span>
                        <span style="font-weight: bolder;font-size: small">AP Biology 시험준비를 위한 탄탄한 기초 완성</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">Jane 선생님의 경험과 지식을 토대로, AP Biology 시험의 핵심 개념을 정확하게 이해하고 탄탄한 기초를 마련할 수 있을 것입니다.</span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: rgba(247, 229, 247, 0.2);color: #8B008B;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">2</span>
                        <span style="font-weight: bolder;font-size: small">개념 이해 및 문제 해결 능력 향상</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">개념을 배우면서 동시에 AP Biology 시험을 준비하는 방법을 익힐 수 있으며, 문제 풀이 능력을 향상 시킬 것 입니다. </span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: rgba(247, 229, 247, 0.2);color: #8B008B;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">3</span>
                        <span style="font-weight: bolder;font-size: small">Interactive 학습경험</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span>함께 학습하는 친구들과 선생님과의 상호작용을 통해 AP Biology에 대한 이해를 향상시키고, 공부하는 과정을 더 흥미롭게 만들 것입니다.</span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: rgba(247, 229, 247, 0.2);color: #8B008B;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">4</span>
                        <span style="font-weight: bolder;font-size: small">Biology에 대한 흥미 증가</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span>Jane 선생님의 수업을 통해 Biology에 대한 전반적인 흥미를 높일 수 있으며, 이를 통해 과목을 더 즐겁게 공부할 수 있을 것입니다.</span>
                        </p>
                    </div>
                </li>
            </ul>
        </section>
        <!--        선생님 소개-->
        <section id="teacher"
                 style="padding: 45px 15px;display: flex;flex-direction: column;align-items: center;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">선생님</span></div>
            </div>
            <!--            <h3 style="padding-bottom: 50px">선생님 소개</h3>-->
            <div style="display: grid;grid-template-columns: 1fr 1fr;max-width: 600px">
                <img src="../../assets/teacher/장지연-Jane(Blue).png"
                     class="teacher_img"
                     alt="IVYED 담당 선생님 이미지">
                <div style="display: flex;flex-direction: column;justify-content: space-between;padding:0px 20px;">
                    <h5 style="font-size: large;border-bottom: 1px solid rgba(0,0,0,0.2);padding-bottom: 15px">Jane
                        선생님</h5>
                    <img src="../../assets/school/2018-09-15105940emory.png" class="teacher_school_img"/>
                    <div class="teacher_info">Emory University Biology 전공</div>
                    <div class="teacher_info">Emory 의과대학, 서울대학교 치과대학 실험실에서 연구원</div>
                    <div class="teacher_info">Biology 관련 경력 4년, 생물학/의학 관련 논문 2편 참여</div>
                </div>
            </div>
            <div style="margin-top: 20px;background-color: #F2F6F8;border-radius: 5px;padding: 20px;font-size: 15px;max-width: 600px">
                안녕하세요, Emory University에서 Biology를 전공한 Jane입니다.<br/>
                <br/>
                Emory는 전미에서 생물학, 의학이 우수한 학교로 알려져있고, 실제로 저명하신 교수님들 밑에서 밀도있는 강의들을 들어왔습니다.<br/>
                <br/>
                그 수업들에서 GPA 3.94/4.0 이라는 우수한 성적으로 졸업하며 생물학에 대한 기초를 단단히 다졌습니다.<br/>
                <br/>
                더불어 자교 의대와 서울대학교 치대 실험실에서 연구원으로 근무하면서 생물학 지식을 실험으로 확장시키고, 이를 SCI 논문으로 작성하는 과정까지 경험했습니다.<br/>
                <br/>
                생물학에 대한 기초, 응용 모두를 아우르는 밀도있는 강의를 하겠습니다.<br/>
                <br/>


                지난 10년간의 기출, 그리고 최근 켐벨에서 실리고 있는 연구주제들을 분석하여 중요도 순서대로 가르칠 것입니다.<br/>
                <br/>
                생물학 전범위를 다 암기하는것은 불가능합니다. 어떤걸 암기해야 하고 어떤건 참고하면 되는지 제가 길잡이가 되어 시간을 절약해드겠습니다.<br/>
                <br/>
                또한 흥미를 유발하는 강의를 하겠습니다. 개인적으로 저는 정말 생물학을 좋아합니다.<br/>
                <br/>
                생물학을 통해 인간에 대해 그리고 세상에 대해 통찰하는 힘을 길렀습니다.<br/>
                <br/>
                그 재미를 학생들에게도 전달하고 싶습니다.<br/>
                <br/>
                매 수업마다 생물학의 매력을 보여줄수 있는 짧은 이야기들을 소개할 생각입니다.<br/>
                <br/>
                재미가 있어야 공부하고, 공부해야 점수가 나옵니다.<br/>
                <br/>


                여러분들이 AP Biology 5점으로 가는 길, 제가 알려드리겠습니다.
            </div>
            <h3 style="margin-top: 30px">수업 미리보기</h3>
            <iframe style="margin: 30px 0px;max-width: 560px" width="100%" height="315" src="https://www.youtube.com/embed/C-ozQVhjndk?si=HL3SsDm6986j-reF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </section>
        <!--        커리큘럼-->
        <section id="curriculum" style="padding: 45px 15px;display: flex;flex-direction: column;align-items: center;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">커리큘럼</span></div>
            </div>
                        <h3 style="margin-bottom: 15px">AP Biology 개념완성반</h3>
            <div class="curriculum_description_info">
                <span style="font-weight: bolder;margin-bottom: 5px">Course Objectives (수업목표)</span>
                <div>
                    <div>- AP Biology (생물학)의 핵심 개념에 대한 종합적인 이해를 촉진: 생명화학, 세포 생물학, 생화학, 세포 주기, 유전학, 분자 생물학, 진화 및 생태학과 관련된 기본 원리를 습득하여 응용할 수 있게 합니다</div>
                    <div>- 과학적 탐구 및 실험 기술 / 능력 습득: 실험을 설계하고 진행하며 데이터를 분석하고 유효한 결론을 도출할 수 있는 능력을 개발하며, 비판적 사고 및 과학적 조사 기술을 향상시킵니다.</div>
                    <div>- AP 생물학 시험 대비: 모의고사, 평가 및 강좌 자료를 통해 AP Biology 객관식 (MCQ) 및 주관식 (FRQ) 섹션을 철저하게 대비하게 됩니다.</div>
                    <div>- 과학 분야 기술능력 향상: 수업을 통해 과학적 관념 (ideas)을 어떻게 기술하는지 학습하여 적절한 과학 용어를 사용하여 FRQ (주관식) 섹션 대비를 하게 됩니다.</div>
                    <div>- 생물학 분야 이해도 촉진: 생물학적 개념의 상호 연결성과 현실 세계 적용을 탐구함으로써 생물학에 대한 이해도를 높이게 됩니다.</div>
                </div>
                <div>
                    <div>- Demonstrate a comprehensive understanding of the key concepts in AP Biology: Students will be able to explain and apply fundamental principles in biology, including those related to the Chemistry of Life, Cell Biology, Biochemistry, Cell Cycle, Genetics, Molecular Biology, Evolution, and Ecology.</div>
                    <div>- Apply scientific inquiry and laboratory skills: Students will develop the ability to design and conduct experiments, analyze data, and draw valid conclusions in a laboratory setting, promoting critical thinking and scientific investigation skills.</div>
                    <div>- Prepare for success on the AP Biology exam: Through practice exams, assessments, and in-depth review of course materials, students will be well-prepared to excel in the AP Biology exam, including both multiple-choice and free-response sections.</div>
                    <div>- Enhance written communication: Students will improve their ability to articulate scientific ideas clearly and concisely, particularly in response to free-response questions (FRQs), using appropriate scientific terminology.</div>
                    <div>- Foster a deeper appreciation of biology: By exploring the interconnectedness of biological concepts and their real-world applications, students will develop a lifelong interest in biology and an understanding of its significance in various aspects of life.</div>
                    <div>- These overall course objectives encompass the core goals of your one-month AP Biology bootcamp, providing a clear direction for what students should achieve during the program.</div>
                </div>
            </div>
            <div class="curriculum_container">
                <div class="curriculum_list_week">
                    <div @click="toggleList(1)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Chemistry of Life and Cell Biology</span>
                        <span style="font-size: 10px" v-if="showList[1]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[1]"
                        style="background-color: #F6F9FA;border-radius: 5px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- What is “Life”</li>
                        <li>- What is Science
                        </li>
                        <li>- Sources of energy (Carbohydrate, protein, lipid, nucleic acid)</li>
                        <li>- DNA vs RNA (Ribozyme, RNA world theory)</li>
                        <li>- Properties of Water</li>
                        <li>- Cell membrane</li>
                        <li>- Diffusion, osmosis, and transport</li>
                        <li>- Prokaryote vs eukaryote</li>
                        <li>- Cellular compartment</li>
                    </ul>
                </div>
                <div class="curriculum_list_week">
                    <div @click="toggleList(2)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Biochemistry and Cell Cycle</span>
                        <span style="font-size: 10px" v-if="showList[2]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[2]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Enzyme
                        </li>
                        <li>- Cellular respiration vs photosynthesis
                        </li>
                        <li>Feedback</li>
                        <li>Cell signaling with receptors</li>
                        <li>Cell cycle</li>
                        <li>Mitosis and meiosis</li>
                    </ul>
                </div>
                <div class="curriculum_list_week">
                    <div @click="toggleList(3)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Genetics and Molecular biology</span>
                        <span style="font-size: 10px" v-if="showList[3]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[3]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Mendel’s Law
                        </li>
                        <li>- Non-Mendel Law
                        </li>
                        <li>- Intensive problem solving session
                        </li>
                        <li>- Central dogma
                        </li>
                        <li>- Replication, transcription, translation
                        </li>
                        <li>- Mutation
                        </li>
                        <li>- Biotechnology
                        </li>
                    </ul>
                </div>
                <div class="curriculum_list_week">
                    <div @click="toggleList(4)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Evolution and Ecology + FRQ Practices</span>
                        <span style="font-size: 10px" v-if="showList[4]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[4]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- What is evolution
                        </li>
                        <li>- Darwanism
                        </li>
                        <li>- Evidences of evolution
                        </li>
                        <li>- Factors causing evolution
                        </li>
                        <li>- Ecosystem
                        </li>
                        <li>- Diversity
                        </li>
                        <li>- Extinction
                        </li>
                        <li>- FRQ Training Session
                        </li>
                    </ul>
                </div>
            </div>
            <!--
            배경색 : rgba(247, 229, 247, 0.2)
            강조 : #8B008B

            -->
            <h3 style="margin-top: 80px;margin-bottom: 30px">AP Biology 개념완성반(KST)</h3>
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;max-width: 600px">
                <div style="display: grid;grid-template-rows:1.2fr 2fr 2fr;color: white;">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #8B008B;margin-bottom: 10px;font-weight: bolder;font-size: smaller;padding: 10px 0px;min-height: 50px">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일</span>
                        <span style="place-self: center center">횟수</span>
                        <span style="place-self: center center;padding-right: 15px">시간</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: rgba(247, 229, 247, 0.2);gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP Biology<br/>(평일 + 주말)</span>
                        <span style="place-self: center center;text-align: center">수, 금, 토</span>
                        <span style="place-self: center center;text-align: center">주 3회<br/></span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">20:40 ~ 23:10</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: rgba(247, 229, 247, 0.2);gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP Biology<br/>(주말반)</span>
                        <span style="place-self: center center;text-align: center">토, 일</span>
                        <span style="place-self: center center;text-align: center">주 2회<br/></span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px;display: flex;flex-direction: column">10:00 ~ 12:00</span>
                    </div>
                </div>
            </div>

            <h3 style="margin-top: 80px;margin-bottom: 30px">AP Biology 개념완성반(PST)</h3>
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;max-width: 600px">
                <div style="display: grid;grid-template-rows:1.2fr 2fr 2fr;color: white;">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #8B008B;margin-bottom: 10px;font-weight: bolder;font-size: smaller;padding: 10px 0px;min-height: 50px">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일</span>
                        <span style="place-self: center center">횟수</span>
                        <span style="place-self: center center;padding-right: 15px">시간</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: rgba(247, 229, 247, 0.2);gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP Biology<br/>(평일 + 주말)</span>
                        <span style="place-self: center center;text-align: center">수, 금, 토</span>
                        <span style="place-self: center center;text-align: center">주 3회<br/></span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">(서부)<br/>03:40 ~ 06:10<br/>(동부)<br/>06:40 ~ 09:10</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: rgba(247, 229, 247, 0.2);gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP Biology<br/>(주말반)</span>
                        <span style="place-self: center center;text-align: center">금,토</span>
                        <span style="place-self: center center;text-align: center">주 2회</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px;display: flex;flex-direction: column">(서부)<br/>17:00 ~ 19:00<br/>(동부)<br/>20:00 ~ 22:00</span>
                    </div>
                </div>
            </div>
        </section>
        <!--        후기-->
        <section id="review"
                 style="display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;padding: 45px 15px;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">후기</span></div>
            </div>
            <!--            <h3 style="font-size: x-large;margin-bottom: 35px">수강생 후기</h3>-->
            <div style="width: 300px;background-color: #F2F6F8;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;padding: 15px 28px;border-radius: 5px;margin-bottom: 50px">
                <h4 style="font-size: 15px;text-align: center;padding: 15px 0px">“방대한 AP Bio 내용을 쉽게 설명해주셔서 Biology 수업 중 가장 좋았던 수업이였습니다.”</h4>
                <img src="../../assets/avatar2.png" alt="IVYED student avatar4 img"
                     style="width: 140px;height: 140px;border-radius: 5px"/>
                <span style="font-size: small;padding-top: 10px">미국 보딩스쿨 11학년</span>
            </div>
            <div style="width: 300px;background-color: #F2F6F8;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;padding: 15px 28px;border-radius: 5px;margin-bottom: 50px">
                <h4 style="font-size: 15px;text-align: center;padding: 15px 0px">“어떤 것을 공부하고 암기해야하는지에 대한 수업 자료 덕분에 학습, 복습을 효과적으로 할 수 있었어요”</h4>
                <img src="../../assets/avatar1.png" alt="IVYED student avatar3 img"
                     style="width: 140px;height: 140px;border-radius: 5px"/>
                <span style="font-size: small;padding-top: 10px">한국 국제학교 10학년</span>
            </div>
            <div style="width: 300px;background-color: #F2F6F8;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;padding: 15px 28px;border-radius: 5px;margin-bottom: 50px">
                <h4 style="font-size: 15px;text-align: center;padding: 15px 0px">“Biology전공으로 입시준비 중이였는데, 선생님의 AP Biology 수업을 듣고 더 Biology 전공에 대한 확신이 생겼어요”</h4>
                <img src="../../assets/avatar4.png" alt="IVYED student parent img"
                     style="width: 140px;height: 140px;border-radius: 5px"/>
                <span style="font-size: small;padding-top: 10px">한국 국제학교 11학년</span>
            </div>
        </section>
        <FloatingBootcampButton background-color="#8B008B"/>
    </div>
</template>
<script>
    import FloatingBootcampButton from "./FloatingBootcampButton";
    export default {
        components: {FloatingBootcampButton},
        name: 'APBiology',
        data() {
            // 변수 선언 및 값 할당
            return {
                formData: {
                    checkbox: '',
                    checkbox1: '',
                    checkbox2: '',
                },
                selectedItem: '',
                currentCourse: '',
                userResponse: '',
                addItemResponse: '',
                itemAdded: false,
                currentItem: '',
                showClassroomPage: true,
                showAssignmentPage: false,
                showSchedulePage: false,
                bootcampResponse: [],
                bootcampSchedule: '',
                addedToCart: false,
                showCartAddedDialog: false,
                hasSameItemAlready: false,
                popUpMessage: '',
                eightWeek: true,
                twelveWeek: false,
                showList: {
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false,
                    8: false,
                    9: false,
                    10: false,
                    11: false,
                    12: false,
                    13: false,
                    14: false,
                    15: false,
                    16: false,
                    17: false,
                    18: false,
                    19: false,
                    20: false,
                    21: false,
                    22: false,
                    23: false,
                    24: false,
                    25: false,
                    26: false,
                    27: false,
                    28: false,
                    29: false,
                    30: false,
                },
            };
        },
        methods: {
            // 중간 네비게이션 바 값들 클릭하면 스크롤 하는 메서드 1
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            // 중간 네비게이션 바 값들 클릭하면 스크롤 하는 메서드 2
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el.querySelector('.v-window__prev').querySelector('.v-btn').click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el.querySelector('.v-window__next').querySelector('.v-btn').click();
                    this.drag = false;
                    this.touch = false;
                }
            },
            // 다른 route / path 로 이동
            goToPaymentPage() {
                this.$router.push('/payment');
            },
            // 날짜 formatting 메서드
            formatDate(datetime) {
                const date = new Date(datetime);
                const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
                const formattedDate =
                    // date.getUTCFullYear() + "년 " +
                    months[date.getUTCMonth()] +
                    ' ' +
                    date.getUTCDate() +
                    '일(' +
                    this.getDayOfWeek(date.getUTCDay()) +
                    ') ' +
                    date.getUTCHours() +
                    ':' +
                    (date.getUTCMinutes() < 10 ? '0' : '') +
                    date.getUTCMinutes();

                return formattedDate;
            },
            // 한글로 요일 불러오는 헬퍼 메서드
            getDayOfWeek(day) {
                const days = ['일', '월', '화', '수', '목', '금', '토'];
                return days[day];
            },

            // 커리큘럼에서 8주 / 12주 활성/비활성화 하는 메서드
            showEightWeekCurriculum() {
                this.eightWeek = true;
                this.twelveWeek = false;
            },
            showTwelveWeekCurriculum() {
                this.eightWeek = false;
                this.twelveWeek = true;
            },

            // 장바구니 담는 메서드
            // 장바구니에 성공적으로 담길시 호출하는 메서드
            showAddedToCartMessage(message) {
                this.showCartAddedDialog = true;
                this.popUpMessage = message;
            },

            navigateToNewPage() {
                if (!this.store.loggedIn) {
                    this.$router.push('/signin');
                }

                // // 여기서 카트에 넣어야함
            },
            // 커리큘럼 토글
            toggleList(listNumber) {
                this.showList[listNumber] = !this.showList[listNumber];
            },
        },
    };
</script>
<style scoped>

    .label_place{
        padding-right: 265px;
        padding-bottom: 15px;
    }

    @media (min-width: 600px) {
        .label_place{
            padding-right: 425px;
        }
    }


    .label {
        z-index: 0;
        width: 65px;  /* 변경된 너비 */
        height: 30px; /* 변경된 높이 */
        box-sizing: content-box;
        padding-top: 8px; /* 변경된 패딩 */
        position: relative;
        background: #8B008B;
        color: white;
        font-size: 7px; /* 변경된 글꼴 크기 */
        letter-spacing: 0.15em; /* 변경된 글자 간격 */
        text-align: center;
        text-transform: uppercase;
    }

    .label:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 7px solid #eee; /* 변경된 선 높이 */
        border-left: 30px solid transparent; /* 변경된 왼쪽 삼각형 크기 */
        border-right: 30px solid transparent; /* 변경된 오른쪽 삼각형 크기 */
    }

    .curriculum_description_info {
        padding: 20px;
        background-color: #F6F9FA;
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 1100px;
        margin-top: 20px;
        border-radius: 5px;
        max-width: 700px;
    }

    @media (min-width: 600px) {
        .curriculum_description_info {
            height: 800px;
        }
    }


    .notice_why_study {
        padding-left: 0px;
    }

    @media (min-width: 600px) {
        .notice_why_study {
            margin-left: -10px;
        }
    }

    @media (min-width: 600px) {
        .notice_why_study1 {
            margin-left: -15px;
        }
    }

    .teacher_info {
        font-size: small;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 5px;
    }

    @media (min-width: 600px) {
        .teacher_info {
            font-size: medium;
        }
    }

    .teacher_school_img {
        width: 100%;
        height: 80px;
        object-fit: contain
    }

    @media (min-width: 600px) {
        .teacher_school_img {
            height: 180px;
        }
    }

    .teacher_img {
        place-self: center center;
        border-radius: 15px;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    @media (min-width: 400px) {
        .teacher_img {
            width: 200px;
            height: 200px;
        }
    }

    @media (min-width: 600px) {
        .teacher_img {
            width: 300px;
            height: 300px;
        }
    }

    .curriculum_container {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 20px 25px 10px;
        margin-top: 25px;
        min-width: 350px;
    }

    @media (min-width: 380px) {
        .curriculum_container {
            min-width: 380px;
        }
    }

    @media (min-width: 400px) {
        .curriculum_container {
            min-width: 400px;
        }
    }

    @media (min-width: 600px) {
        .curriculum_container {
            min-width: 600px;
        }
    }

    @media (min-width: 700px) {
        .curriculum_container {
            min-width: 850px;
        }
    }

    .curriculum_list_week:last-child {
        border-bottom: none;
    }

    .curriculum_list_week {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-top: 8px;
        width: 100%;
        padding: 5px 5px;
    }

    *, html, ul, li {
        margin: 0;
        padding: 0;
        font-family: "Noto Sans KR";
    }

    .hover_nav {
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        cursor: pointer;
    }

    .hover_nav:hover {
        background-color: #E8344E;
        color: white;
    }

    .study_info {
        display: grid;
        grid-template-columns: 0.5fr 5fr 4.5fr;
        margin-bottom: 10px;
        gap: 5px;
    }

    .study_info span {
        font-size: small;
    }

    .curriculum_description li {
        border-radius: 5px;
        border: 1px solid #452DDD;
        padding: 30px;
    }

    .curriculum_click {
        background-color: #452DDD !important;
    }

    .curriculum_study_list li {
        font-size: small;
        color: rgba(0, 0, 0, 0.6);
        padding: 5px 5px;
    }

    .curriculum_list li {
        padding: 10px 20px;
        border-radius: 20px;
        color: white;
        background-color: #D8D3F8;
        font-weight: bolder;
        font-size: medium;
        cursor: pointer;
    }

    .target_benefit li span {
        font-size: small;
    }

    .target_benefit li h2 {
        color: #452DDD;
    }

    .target_benefit li {
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 5px;
    }

    .target_list li > span {
        padding: 0px 15px;
    }

    .target_list li {
        background-color: #F3F2FD;
        font-size: 16px;
        font-weight: bolder;
        max-width: 320px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .introduction_list li {
        background-color: white;
        border-radius: 15px;
        margin: 0px 10px;
        padding: 2px 10px;
    }

    * {
        list-style: none;
    }

    .sticky {
        background: blue;
        height: 50px;
        position: sticky;
        top: 0;
    }

    .toolbar-style-mobile {
        font-size: 14px;
        font-weight: bold;
        position: sticky;
        top: 40px;
    }

    .toolbar-style {
        font-family: S-CoreDream-6Bold;
        font-size: 18px;
        font-weight: bold;
    }

    .sticky-toolbar {
        color: white;
        z-index: 0;
        top: 64px;
        position: sticky;
        position: -webkit-sticky;
    }

</style>
