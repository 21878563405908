<template>
    <v-container class="semi-background">

        <v-card-title class="font-weight-bold">{{currentUser.lastName}} {{currentUser.firstName}} 선생님</v-card-title>

        <div class="div-background">
            <v-btn outlined class="" large width="100%" to="/teacher/summary" text>
                <v-row class="">
                    <v-col md="1" class="text-left">
                        선생님 정보 조회
                    </v-col>
                    <v-col md="11" class="text-right">
                        <v-icon size="21px">mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>

            </v-btn>
            <v-btn outlined class="text-left" large width="100%" to="/teacher/balance" text>
                <v-row>
                    <v-col md="1" class="text-left">

                        활동 내역
                    </v-col>
                    <v-col md="11" class="text-right">
                        <v-icon size="21px">mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>
            </v-btn>
            <v-btn outlined class="text-left" large width="100%" to="/teacher/classreports" text>
                <v-row>
                    <v-col md="1" class="text-left">

                        수업일지 목록
                    </v-col>
                    <v-col md="11" class="text-right">
                        <v-icon size="21px">mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>
            </v-btn>
            <v-btn outlined class="text-left" large width="100%" to="/teacher/bankAccount" text>
                <v-row>
                    <v-col md="1" class="text-left">
                        선생님 계좌정보
                    </v-col>
                    <v-col md="11" class="text-right">
                        <v-icon size="21px">mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>
            </v-btn>
            <!--        <v-card-->
            <!--                class="mx-auto mt-5 primary&#45;&#45;text"-->
            <!--                outlined-->
            <!--                v-if="currentUser.roles[0] == 'ROLE_TEACHER'">-->
            <!--            <v-divider></v-divider>-->
            <!--            &lt;!&ndash;                <div class="ma-4" v-show="teacherContent.registerStatus == 'REGISTERED'">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <span class="text-h6 font-weight-bold" v-if="teacherContent.matchingActivated"> 매칭 비활성화 </span>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <span class="text-h6 font-weight-bold" v-if="teacherContent.matchingActivated == false"> 매칭 활성화 </span>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <p class="mt-3"> 학생 추천을 멈추고 싶으실때 매칭 비활성화를 하여주세요. <br/>&ndash;&gt;-->

            <!--            &lt;!&ndash;                    </p>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    <p>&ndash;&gt;-->
            <!--            &lt;!&ndash;                        <v-icon color="primary">mdi-information-outline</v-icon>&ndash;&gt;-->
            <!--            &lt;!&ndash;                        현재 진행중인 과외들에겐 영향이 가지 않습니다.&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </p>&ndash;&gt;-->

            <!--            &lt;!&ndash;                    <div align="" class="mt-8">&ndash;&gt;-->
            <!--            &lt;!&ndash;                        <v-dialog&ndash;&gt;-->
            <!--            &lt;!&ndash;                            v-model="dialog"&ndash;&gt;-->
            <!--            &lt;!&ndash;                            width="500"&ndash;&gt;-->
            <!--            &lt;!&ndash;                        >&ndash;&gt;-->
            <!--            &lt;!&ndash;                            <template v-slot:activator="{ on, attrs }">&ndash;&gt;-->
            <!--            &lt;!&ndash;                                <v-btn&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        v-bind="attrs"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        v-on="on"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        color="primary"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        x-large>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <span v-if="teacherContent.matchingActivated">비활성화 하기</span>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <span v-if="teacherContent.matchingActivated ==false">활성화 하기</span>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                </v-btn>&ndash;&gt;-->
            <!--            &lt;!&ndash;                            </template>&ndash;&gt;-->

            <!--            &lt;!&ndash;                            <v-card>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                <v-card-title v-if="teacherContent.matchingActivated"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                              class="accent&#45;&#45;text text-subtitle-1  font-weight-bold primary lighten-2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    IVYeD 매칭 비활성화&ndash;&gt;-->
            <!--            &lt;!&ndash;                                </v-card-title>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                <v-card-title v-if="!teacherContent.matchingActivated"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                              class="accent&#45;&#45;text text-subtitle-1  font-weight-bold primary lighten-2">&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    IVYeD 매칭 활성화&ndash;&gt;-->
            <!--            &lt;!&ndash;                                </v-card-title>&ndash;&gt;-->

            <!--            &lt;!&ndash;                                <v-card-text v-if="teacherContent.matchingActivated"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                             class="ml-1 pa-3 primary&#45;&#45;text font-weight-bold text-subtitle-1">&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    비활성화를 진행하시겠습니까?&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <br/>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                </v-card-text>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                <v-card-text v-if="!teacherContent.matchingActivated"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                             class="ml-1 pa-3 primary&#45;&#45;text font-weight-bold text-subtitle-1">&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    활성화를 진행하시겠습니까?&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <br/>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                </v-card-text>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                <v-card-text class="ml-1 pa-3 primary&#45;&#45;text">&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <p><span class="font-weight-bold text-subtitle-1"> 현재상태: </span>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        <span v-if="teacherContent.matchingActivated" class="text-decoration-underline">활성화 되어있습니다.</span>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        <span v-if="!teacherContent.matchingActivated"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                              class="text-decoration-underline">비활성화 되어있습니다. 추천이 되지 않습니다.</span>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    </p>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <br/>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <p v-if="teacherContent.matchingActivated" class="font-weight-medium">&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        비활성화를 하시면 비활성화 기간동안은 <br/> <strong>새로운</strong> 매칭 진행 (학생 추천)이 되지 않습니다.&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    </p>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <p v-if="!teacherContent.matchingActivated" class="font-weight-medium">&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        <v-icon color="primary"> mdi-information-outline</v-icon>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        <strong><span class="text-decoration-underline"> 활성화</span>를 하시면 다시 새로운 학생 추천 및&ndash;&gt;-->
            <!--            &lt;!&ndash;                                            매칭이 진행됩니다. </strong>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    </p>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                </v-card-text>&ndash;&gt;-->

            <!--            &lt;!&ndash;                                <v-divider></v-divider>&ndash;&gt;-->

            <!--            &lt;!&ndash;                                <v-card-actions>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <v-spacer></v-spacer>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                    <v-btn&ndash;&gt;-->

            <!--            &lt;!&ndash;                                            color="primary"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                            class="font-weight-bold"&ndash;&gt;-->
            <!--            &lt;!&ndash;                                            text&ndash;&gt;-->
            <!--            &lt;!&ndash;                                            large&ndash;&gt;-->
            <!--            &lt;!&ndash;                                            @click="updateTeacherMatchingActivateInfo(currentUser.id, teacherContent)">&ndash;&gt;-->

            <!--            &lt;!&ndash;                                        <span v-if="teacherContent.matchingActivated">비활성화 하기</span>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                        <span v-if="teacherContent.matchingActivated ==false">활성화 하기</span>&ndash;&gt;-->

            <!--            &lt;!&ndash;                                    </v-btn>&ndash;&gt;-->
            <!--            &lt;!&ndash;                                </v-card-actions>&ndash;&gt;-->
            <!--            &lt;!&ndash;                            </v-card>&ndash;&gt;-->
            <!--            &lt;!&ndash;                        </v-dialog>&ndash;&gt;-->
            <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->


            <!--            &lt;!&ndash;                    &lt;!&ndash;                <v-btn @click="updateTeacherMatchingActivateInfo(currentUser.id, {})">&ndash;&gt;&ndash;&gt;-->
            <!--            &lt;!&ndash;                    &lt;!&ndash;                    test&ndash;&gt;&ndash;&gt;-->
            <!--            &lt;!&ndash;                    &lt;!&ndash;                </v-btn>&ndash;&gt;&ndash;&gt;-->

            <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            <div class="ma-4">-->
            <!--                <v-card-title class="font-weight-bold">선생님 정보 조회</v-card-title>-->
            <!--                <v-divider></v-divider>-->
            <!--                <v-btn-->
            <!--                        color="primary"-->
            <!--                        class="font-weight-bold mt-5"-->
            <!--                        large-->
            <!--                        to="/teacher/summary">-->
            <!--                    선생님 정보 조회-->
            <!--                </v-btn>-->
            <!--            </div>-->
            <!--        </v-card>-->
        </div>
    </v-container>
</template>

<script>
    import info from "../../../info";
    // import StudentService from '../../services/student.service'
    import TeacherService from '../../services/teacher.service'

    export default {
        name: "EditTeacherProfile",
        components: {},
        data() {
            return {
                dialog: false,
                userContent: '',
                currentPassword: '',
                passwordMatchInfo: null,
                showPasswordCard: 1,
                // showPasswordCard
                // 0 = true
                // 1 = false
                // 2 = done
                wrongPassword: false,
                passwordData: {
                    password: "",
                    passwordConfirm: ""
                },
                countryCodes: info.countryCodes,
                showPassword: false,
                showPasswordConfirm: false,
                passwordValid: false,
                valid: false,
                kakaoId: '',
                rules: {
                    required: (value) => !!value || 'Required.',
                    passwordMatch: (v1, v2) => v1 === v2 || 'Passwords Do not Match',
                    MinPassLen: (v) => v.length >= 8 || '9자 이상의 패스워드만 허용 됩니다.',
                    passwordDifferent: (v1, v2) => v1 != v2 || '새로운 패스워드만 가능합니다..',
                },
                studentContent: "",
                teacherContent: "",
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
            // if (this.currentUser.roles[0].includes('STUDENT')) {
            //     StudentService.getStudentInfo(this.currentUser.id).then(
            //         (response) => {
            //             this.studentContent = response.data;
            //             console.log(this.studentContent.kakaoId ==null)
            //             if (this.kakaoId == null) {
            //                 this.kakaoId = this.studentContent.kakaoId;
            //
            //             }
            //         },
            //         (error) => {
            //             this.studentContent =
            //                 (error.response &&
            //                     error.response.data &&
            //                     error.response.data.message) ||
            //                 error.message ||
            //                 error.toString();
            //         }
            //     );
            // }

            if (this.currentUser.roles[0].includes('TEACHER')) {
                TeacherService.getTeacherInfo(this.currentUser.id).then(
                    (response) => {
                        this.teacherContent = response.data;
                        if (this.kakaoId == null) {
                            this.kakaoId = this.teacherContent.kakaoId;

                        }
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        if (this.teacherContent == 'Access is denied' || this.teacherContent.includes('denied') || this.teacherContent.includes('401')) {
                            this.logOut();
                        }
                    }
                );
            }

            // this.kakaoId = this.currentUser.kakaoId;
        },
        methods: {
            logOut() {
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
            },
            updateTeacherMatchingActivateInfo(userId, requestBody) {
                requestBody.matchingActivated = !requestBody.matchingActivated;
                TeacherService.updateTeacherMatchingActivateStatus(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/profile")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }

        }

    }
</script>

<style scoped>
    .semi-background {
        background-color: #F0F0F0;
        max-width: 900px;
        height: 100%;
        /*justify-content: center;*/
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .div-background {
        background-color: #fffffb;
    }
</style>
