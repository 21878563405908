<template>
    <v-layout class="floating-button-background justify-center">

        <div>
        <v-btn style="z-index: 9999" bottom class="hidden-sm-and-down" text elevation="0" href="/bootcamp/register" >
            <button class="button hidden-sm-and-down" :style="{'background-color': backgroundColor}">
                <span class="font-weight-bold">참가 신청하기</span>
            </button>
        </v-btn>
        <v-btn style="z-index: 9999;" bottom class="hidden-md-and-up" text elevation="0" href="/bootcamp/register" >
            <button class="small-button hidden-md-and-up" :style="{'background-color': backgroundColor}">
                <span class="font-weight-bold">참가 신청하기</span>
            </button>
        </v-btn>
        </div>

    </v-layout>


</template>

<script>
    export default {
        name: "FloatingBootcampButton",
        props:{
            backgroundColor:String,
        },
        computed: {
            getDefaultLanguage() {
                return window.navigator.userLanguage || window.navigator.language;
            },
        }
    }
</script>

<style scoped>
    .button-36-small {
        background-image: linear-gradient(92.88deg, #b71c1c 9.16%, #c23e3e 43.89%, #cd6060 64.72%);
        /*border-radius: 8px;*/
        /*border-style: none;*/
        /*box-sizing: border-box;*/
        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        /*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*/
        /*font-family: 'SuncheonB';*/
        /*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*/
        font-size: 20px;
        height: 1500px;
        width: 80%;
        font-weight: 500;
        /*height: 4rem;*/
        /*padding: 0 1.6rem;*/
        text-align: center;
        text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
        transition: all .5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .button-36 {
        background-image: linear-gradient(92.88deg, #b71c1c 9.16%, #c23e3e 43.89%, #cd6060 64.72%);
        /*border-radius: 8px;*/
        /*border-style: none;*/
        /*box-sizing: border-box;*/
        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        /*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*/
        /*font-family: 'SuncheonB';*/
        /*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*/
        font-size: 20px;
        height: 1500px;
        width: 400px;
        font-weight: 500;
        /*height: 4rem;*/
        padding: 0 1.6rem;
        text-align: center;
        text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
        transition: all .5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .button-36:hover {
        /*box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;*/
        box-shadow: white 0 1px 30px;
        /*rgba(256,256,256, 0.5) 0 1px 30px;*/
        transition-duration: .1s;
    }

    @media (min-width: 768px) {
        .button-36 {
            padding: 0 2.6rem;
        }
    }

    .fab-container {
        position: fixed;
        bottom: 10px;
        right: 50%;
        left: 50%;

    }

    .glow {
        color: #fff;
        text-align: center;
        animation: glow .5s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
        from {
            text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
        }

        to {
            text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
        }
    }

    .button {
        position: fixed;
        bottom: 10px;
        background-color: #E8344E;
        border-radius: 5px;
        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        font-size: 18px;
        padding: 14px 0px;
        width: 400px;
        font-weight: 600;
    }

    .small-button {
        position: fixed;
        bottom: 10px;
        background-color: #E8344E;
        border-radius: 5px;

        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        font-size: 18px;
        padding: 14px 0px;
        width: 92%;
        font-weight: 600;
    }

    .floating-button-background {
        background-color: #393130;
        z-index: 5;
    }
</style>
