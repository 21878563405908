<template>
    <v-container fluid>
        <v-card width="100%">
            <v-card-title>
                Students
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                ></v-text-field>
            </v-card-title>

            <v-data-table
                    :headers="headers"
                    :items="userStudents"
                    class="elevation-1"
                    :search="search"
            >
                <template v-slot:top>
                    <v-toolbar
                            flat
                    >
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                                v-model="dialog"
                                max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="primary"
                                        dark
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    New Item
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.username"
                                                        label="username"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <!--                                            username: '',-->
                                                <!--                                            firstName: '',-->
                                                <!--                                            email: '',-->
                                                <!--                                            phoneNumber: '',-->
                                                <!--                                            roleType: '',-->
                                                <v-text-field
                                                        v-model="editedItem.firstName"
                                                        label="first name"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.email"
                                                        label="Email"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.phoneNumber"
                                                        label="phone number"
                                                ></v-text-field>
                                            </v-col>
<!--                                            <v-col-->
<!--                                                    cols="12"-->
<!--                                                    sm="6"-->
<!--                                                    md="4"-->
<!--                                            >-->
<!--                                                <v-text-field-->
<!--                                                        v-model="editedItem.roleType"-->
<!--                                                        label="role type"-->
<!--                                                ></v-text-field>-->
<!--                                            </v-col>-->
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-checkbox
                                                        label="계약서 서명 현황"
                                                        v-model="editedItem.contractSigned"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="close"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="save"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogInfo" max-width="">
                            <v-card>
                                <strong>school</strong>: {{studentContent.school}} <br/>
                                <strong>currentGrade</strong>: {{studentContent.currentGrade}} <br/>
                                <strong>currentLocation</strong>: {{studentContent.currentLocation}} <br/>
                                <strong>expectedNumberOfLessonsPerWeek</strong>: {{studentContent.expectedNumberOfLessonsPerWeek}} <br/>
                                <strong>expectedLessonDuration</strong>: {{studentContent.expectedLessonDuration}} <br/>
                                <strong>tablet</strong>: {{studentContent.tablet}} <br/>
                                <strong>parentContact</strong>: {{studentContent.parentContact}} <br/>
                                <strong>activePaymentId</strong>: {{studentContent.activePaymentId}} <br/>
                                <strong>studentRegisterStatus</strong>: {{studentContent.studentRegisterStatus}} <br/>
                                <strong>matchingStatus</strong>: {{studentContent.matchingStatus}} <br/>
                                <strong>detailedLessonTimes</strong>: {{studentContent.detailedLessonTimes}} <br/>
                                <strong>subjectsOfInterest</strong>: {{studentContent.subjectsOfInterest}} <br/>
                                <strong>subSubjects</strong>: {{studentContent.subSubjects}} <br/>
                                <strong>availableDays</strong>: {{studentContent.availableDays}} <br/>
                                <strong>schoolType</strong>: {{studentContent.schoolType}} <br/>
                                <strong>desiredMajor</strong>: {{studentContent.desiredMajor}} <br/>
                                <strong>goalOfLesson</strong>: {{studentContent.goalOfLesson}} <br/>
                                <strong>preferredLessonStyle</strong>: {{studentContent.preferredLessonStyle}} <br/>
                                <strong>preferredLessonMaterial</strong>: {{studentContent.preferredLessonMaterial}} <br/>
                                <strong>lessonRemarks</strong>: {{studentContent.lessonRemarks}} <br/>
                                <strong>kakaoId</strong>: {{studentContent.kakaoId}} <br/>
                                <strong>sumOfRemainingClasses</strong>: {{studentContent.sumOfRemainingClasses}} <br/>
                                <strong>fullName</strong>: {{studentContent.fullName}} <br/>
                                <strong>contact</strong>: {{studentContent.contact}} <br/>
                                <strong>email</strong>: {{studentContent.email}} <br/>
                                <strong>createdAt</strong>: {{studentContent.createdAt}} <br/>
                                <strong>updatedAt</strong>: {{studentContent.updatedAt}} <br/>
                                <strong>lastPaidAt</strong>: {{studentContent.lastPaidAt}} <br/>
                                <strong>payments</strong>: {{studentContent.payments}} <br/>
                                <strong>lessons</strong>: {{studentContent.lessons}} <br/>
                            </v-card>
                        </v-dialog>

                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                            small
                            class="mr-2"

                    >
                        mdi-account-plus
                    </v-icon>
                    <v-icon
                            small
                            class="mr-2"
                            @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                            small
                            @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                    <v-icon
                            small
                            @click="showStudentInfo(item)"
                    >
                        mdi-information
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <v-btn
                            color="primary"
                            @click="initialize"
                    >
                        Reset
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>

    </v-container>
</template>

<script>
    import UserService from "../../services/user.service"
    import StudentService from "../../services/student.service"

    export default {
        name: "StudentsDataTable",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            formTitle() {
                return this.editedindex === -1 ? 'new item' : 'edit item'
            },
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }

            UserService.getAllUsers().then(
                (response) => {
                    this.users = response.data;
                },
                (error) => {
                    this.users =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            UserService.getAllUserStudents().then(
                (response) => {
                    this.userStudents = response.data;
                },
                (error) => {
                    this.userStudents =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            // add student info fetcher
            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            StudentService.getAllStudents().then(
                (response) => {
                    this.students = response.data;
                },
                (error) => {
                    this.students =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            // if()
        },
        data: () => ({
            editedIndex: -1,
            search: '',
            userStudents: [],
            studentContent: "",
            requestBody: "",
            students: [],
            dialog: false,
            dialogDelete: false,
            dialogInfo: false,
            users: [],
            headers: [
                {text: 'Actions', value: 'actions', sortable: false},
                {
                    text: 'user_id',
                    align: 'start',
                    value: 'id',
                },
                {text: 'email', value: 'email'},
                {text: 'register status', value: 'student.studentRegisterStatus'},
                {text: 'contract', value: 'contractSigned'},
                {text: 'firstname', value: 'firstName'},
                {text: 'country', value: 'student.currentLocation'},
                {text: 'phone#', value: 'phoneNumber'},
                {text: '카카오', value: 'kakaoId'},
                {text: '과목', value: 'student.subjectsOfInterest'},
                {text: '세부과목', value: 'student.subSubjects'},
                // {text: '시간', value: 'student.availableTimes'},
                {text: '잔여 수업', value: 'student.sumOfRemainingClasses'},
                {text: 'last login', value: 'lastLogin'},

            ],
            // headers: [
            //     {
            //         text: 'Dessert (100g serving)',
            //         align: 'start',
            //         sortable: false,
            //         value: 'name',
            //     },
            //     { text: 'Calories', value: 'calories' },
            //     { text: 'Fat (g)', value: 'fat' },
            //     { text: 'Carbs (g)', value: 'carbs' },
            //     { text: 'Protein (g)', value: 'protein' },
            //     { text: 'Actions', value: 'actions', sortable: false },
            // ],
            desserts: [],
            // editedIndex: -1,
            editedItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
                contractSigned: '',
            },
            defaultItem: {
                username: '',
                firstName: '',
                email: '',
                phoneNumber: '',
                roleType: '',
                contractSigned: '',
            },
        }),
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
        },

        created() {
            this.initialize()
        },
        methods: {

            updateUserForAdmin(userId, requestBody) {
                UserService.updateUserForAdmin(userId, requestBody).then(
                    (response) => {
                        console.log(response.data);
                        return response.data;
                    },
                    (error) => {
                        // this.users =
                        return (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },


            initialize() {
            },

            editItem(user) {
                // console.log("================================");
                // console.log(user.id);
                // console.log('================================');
                this.editedIndex = this.users.indexOf(user)
                this.editedItem = Object.assign({}, user)
                // updateUserForAdmin()
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.desserts.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            showStudentInfo(item) {
                this.editedIndex = this.desserts.indexOf(item)
                this.editedItem = Object.assign({}, item)
                console.log("here")
                console.log(this.editedItem.id)
                console.log("here")
                this.getUserInfoOfStudent(this.editedItem.id)
                this.dialogInfo = true
            },

            deleteItemConfirm() {
                this.desserts.splice(this.editedIndex, 1)
                this.closeDelete()
            },

            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            save() {

                // if (this.editedIndex > -1) {
                //     Object.assign(this.users[this.editedIndex], this.editedItem)
                //     this.updateUserForAdmin(this.editedItem.id, this.editedItem)
                // } else {
                //     this.users.push(this.editedItem)
                //
                //
                // }
                // this.close()
                if (this.editedIndex > -1) {
                    Object.assign(this.users[this.editedIndex], this.editedItem)

                } else {
                    console.log(this.editedItem)
                    this.updateUserForAdmin(this.editedItem.id, this.editedItem)
                    this.users.push(this.editedItem)
                }
                this.close()
            },

            getUserInfoOfStudent(userId) {

                StudentService.getStudentInfo(userId).then(
                    (response) => {
                        console.log(response.data)
                        this.studentContent = response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
                console.log("yolo")
                console.log(this.studentContent)
                console.log("yolo")
            }
        },

    }
</script>

<style scoped>

</style>
