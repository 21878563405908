<template>
    <div>
        <div class="hidden-lg-and-up slideshow-container" style="">

            <div class="my-8"></div>

            <div :class="{ 'slide': true, 'show': currentIndex === 0 }"
                 :style="slideTransitionStyle(0)" class=" center-vertical ">
                <div class="text-left px-5 slide-card ">
                    <div
                            class="center-vertical center-horizontal student-tag"
                    >
                        학생 정보
                    </div>

                    <!-- Your overflowing content goes here -->
                    <div class="text-left card-container" style="">
                        <div class="center-horizontal text-center column1">
                            <img height="120px" style="overflow: hidden" src="../../../assets/Artboard3.png" alt="IVYED Student Avatar 1">
                            <div class="text-center" style=";font-size: 16px; font-weight: bold">이00 학생</div>

                        </div>
                        <div class="center-horizontal text-center column2 pt-2 ">
                            <div class="ma-1" style="">고등학교 3학년</div>
                            <div class="ma-1" style="">한국 사립고</div>

                            <div class="ma-1" style="">주중 19시 이후 희망</div>
                            <div class="ma-1" style="">영어 / 한국어 혼용</div>
                            <div class="ma-1" style="">SAT MATH를 집중적으로 배우고 싶어요</div>
                        </div>
                        <!-- Your overflowing content goes here -->

                    </div>
                    <div class="my-5"></div>

                </div>
            </div>
            <div :class="{ 'slide': true, 'show': currentIndex === 1 }"
                 :style="slideTransitionStyle(1)" class=" center-vertical ">
                <div class="text-left  px-5 slide-card " style="background-color: white !important;">
                    <div
                            id=""
                            class="center-vertical center-horizontal student-tag"

                    >
                        학생 정보
                    </div>
                    <div class="text-left card-container pt-3" style="">
                        <div class="center-horizontal text-center column1">
                            <img height="120px" style="overflow: hidden" src="../../../assets/male-student.png" alt="IVYED Student Avatar 2">
                            <div class="text-center" style=";font-size: 16px; font-weight: bold">김00 학생</div>

                        </div>
                        <div class="center-horizontal text-center column2 pt-2">
                            <div class="ma-1" style="">10학년</div>
                            <div class="ma-1" style="">인가 국제학교</div>
                            <div class="ma-1" style="">주말 15-17시 사이 희망</div>

                            <div class="ma-1" style="">영어만 사용</div>
                            <div class="ma-1" style="">내신관리가 필요해요</div>
                        </div>

                    </div>
                    <div class="my-5"></div>


                </div>
            </div>

        </div>
        <div class="hidden-md-and-down slideshow-container-desktop" style="">

            <div class="my-8"></div>

            <div :class="{ 'slide': true, 'show': currentIndex === 0 }"
                 :style="slideTransitionStyle(0)" class=" center-vertical ">
                <div class="text-left px-5 slide-card-desktop ">
                    <div
                            class="center-vertical center-horizontal student-tag"
                    >
                        학생 정보
                    </div>

                    <!-- Your overflowing content goes here -->
                    <div class="text-left card-container pt-3" style="">
                        <div class="center-horizontal text-center column1">
                            <img height="150px" style="overflow: hidden" src="../../../assets/Artboard3.png" alt="IVYED Student Avatar 1">
                            <div class="text-center" style=";font-size: 16px; font-weight: bold">이00 학생</div>

                        </div>
                        <div class="center-horizontal text-center column2 pt-2 description-desktop" >
                            <div class="ma-1" style="">고등학교 3학년</div>
                            <div class="ma-1" style="">한국 사립고</div>

                            <div class="ma-1" style="">주중 19시 이후 희망</div>
                            <div class="ma-1" style="">영어 / 한국어 혼용</div>
                            <div class="ma-1" style="">SAT MATH를 집중적으로 배우고 싶어요</div>
                        </div>
                        <!-- Your overflowing content goes here -->

                    </div>
                    <div class="my-5"></div>

                </div>

            </div>
            <div :class="{ 'slide': true, 'show': currentIndex === 1 }"
                 :style="slideTransitionStyle(1)" class=" center-vertical ">
                <div class="text-left  px-5 slide-card-desktop" style="background-color: white !important;">
                    <div
                            class="center-vertical center-horizontal student-tag"

                    >
                        학생 정보
                    </div>
                    <div class="text-left card-container pt-3" style="">
                        <div class="center-horizontal text-center column1">
                            <img height="150px" style="overflow: hidden" src="../../../assets/male-student.png" alt="IVYED Student Avatar 2">
                            <div class="text-center" style=";font-size: 16px; font-weight: bold">김00 학생</div>

                        </div>
                        <div class="center-horizontal text-center column2 pt-2 description-desktop" >
                            <div class="ma-1" style="">10학년</div>
                            <div class="ma-1" style="">인가 국제학교</div>
                            <div class="ma-1" style="">주말 15-17시 사이 희망</div>

                            <div class="ma-1" style="">영어만 사용</div>
                            <div class="ma-1" style="">내신관리가 필요해요</div>
                        </div>

                    </div>
                    <div class="my-5"></div>



                </div>

            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "StudentTeacherSlider",
        data() {
            return {
                slides: ["Slide 1", "Slide 2"],
                currentIndex: 0,
            };
        },
        created() {
            setInterval(this.nextSlide, 5500); // Switch to the next slide every 2 seconds
        },
        methods: {
            nextSlide() {
                this.currentIndex = (this.currentIndex + 1) % this.slides.length;
            },
            slideTransitionStyle(index) {
                // const totalSlides = this.slides.length;
                const totalSlides = this.slides.length;
                const distance = 300; // Adjust this value to set the sliding distance
                const offset = ((index - this.currentIndex + totalSlides) % totalSlides) * distance;
                return {
                    transform: `translateX(-${offset}%)`,
                    transition: "transform 1.5s ease-in-out",
                };
            },
        },
    };
</script>

<style scoped>
    .description-desktop{
        font-size: 14px !important;
    }
    .vertical-line {
        width: 2px; /* Adjust the width of the vertical line */
        height: 7px; /* Adjust the height of the vertical line */
        background-color: #d2cef5; /* Change the color of the vertical line */
    }

    .short-vertical-line {
        width: 2px; /* Adjust the width of the vertical line */
        height: 2px; /* Adjust the height of the vertical line */
        background-color: red; /* Change the color of the vertical line */
    }

    .slideshow-container {
        position: relative;
        width: 100%;
        height: 300px;
        overflow: hidden; /* Ensure that only one slide is visible at a time */
    }
    .slideshow-container-desktop {
        position: relative;
        width: 100%;
        min-height: 333px;
        overflow: hidden; /* Ensure that only one slide is visible at a time */
    }

    .slide {
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
    }

    .slide-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }

    .show .slide-content {
        opacity: 1;
    }

    .slide-card {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        /*padding: 20px;*/
        /*margin-top: 30px;*/
        /*margin-left: 30px;*/
        /*margin-right: 30px;*/
        /*margin-bottom: 20px;*/
        border-radius: 10px;
        background-color: white;
        width: 90%;
        height: 210px;
        max-width: 450px;
        max-height: 250px;
        font-family: S-CoreDream-5Medium;
    }

    .slide-card-desktop {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        /*padding: 20px;*/
        /*margin-top: 30px;*/
        /*margin-left: 30px;*/
        /*margin-right: 30px;*/
        /*margin-bottom: 20px;*/
        border-radius: 10px;
        background-color: white;
        width: 450px;
        height: 250px;
        font-family: S-CoreDream-5Medium;
    }

    .slide-card-pc {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        /*padding: 20px;*/
        /*margin-top: 30px;*/
        /*margin-left: 30px;*/
        /*margin-right: 30px;*/
        /*margin-bottom: 20px;*/
        border-radius: 10px;
        background-color: white;
        width: 50%;
        height: 400px;

    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .student-tag {
        font-weight: bold;
        /*height: 13%;*/
        border-radius: 5px;
        background-color: #8388ea;
        color: white;
        height: 40px;
        width: 32%;
        /*left: 30px;*/
        left: -8%;
        top: -3%;
        /*position: absolute;*/
        position: relative;
        z-index: 1;
        font-size: 14px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .student-tag-2 {
        font-size: 18px;
        font-weight: bold;
        /*height: 13%;*/
        border-radius: 5px;
        background-color: #8388ea;
        color: white;
        height: 50px;
        width: 32%;
        /*left: 30px;*/
        left: -5%;
        top: -3%;
        /*position: absolute;*/
        position: relative;
        z-index: 1;
        /*font-size: 14px;*/
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .short-vertical-line {
        width: 2px; /* Adjust the width of the vertical line */
        height: 2px; /* Adjust the height of the vertical line */
        background-color: #beb9ee; /* Change the color of the vertical line */
    }

    .card-container {
        display: flex;
    }

    .column1 {
        flex: 1; /* Column 1 takes 1/3 of the container width */
        background-color: #ffffff;
        margin-top: -18px !important;

    }

    .column2 {
        flex: 2; /* Column 2 takes 2/3 of the container width */
        background-color: #ffffff;
        font-size: 13px;
        margin-top: -18px !important;;
    }
</style>
