<template>
    <div>
<!--        <button disabled class="button-64 browser-container hidden-xs-only" role="button">-->
<!--            <span class="">-->

<!--&lt;!&ndash;                <v-card width="100%" style="font-size: 15px; font-weight: bold; color: #5C060D;" class="mb-4 ">&ndash;&gt;-->
<!--                &lt;!&ndash;                    <div class="text-center">&ndash;&gt;-->
<!--                &lt;!&ndash;                    신청 버튼 클릭하시고 <br/>&ndash;&gt;-->
<!--                &lt;!&ndash;                    맞춤 상담 받아보세요!&ndash;&gt;-->
<!--                &lt;!&ndash;                    </div>&ndash;&gt;-->

<!--                &lt;!&ndash;                </v-card>&ndash;&gt;-->
<!--                &lt;!&ndash;                Button 64&ndash;&gt;-->
<!--                <div class="center-horizontal">-->
<!--                    <v-btn height="40px" href="tel:070-8970-5223"-->
<!--                           class="button-75" role="button">-->
<!--                        <div class="text-center">-->
<!--                        <v-icon color="white">-->
<!--                            mdi-phone-->
<!--                        </v-icon>-->
<!--                            학습상담 신청-->
<!--                        </div>-->
<!--                    </v-btn>-->
<!--                </div>-->
<!--                <div class="mt-7 mb-1" style="font-weight: bold; font-size: 16px;">-->
<!--                    빠른 전화 문의-->
<!--                </div>-->
<!--                <div class="center-horizontal font-weight-bold" style="color: #5C060D">-->
<!--                    070-8970-5223-->
<!--                </div>-->
<!--            </span>-->
<!--            &lt;!&ndash;            <div class="line mt-3"></div>&ndash;&gt;-->
<!--        </button>-->
        <ContactButtonSmall></ContactButtonSmall>
    </div>
</template>

<script>
    import ContactButtonSmall from "./ContactButtonSmall";

    export default {
        name: "ContactButton",
        components: {ContactButtonSmall}
    }
</script>

<style scoped>
    /* CSS */
    .line {
        width: 100%;
        height: 47px;
        border-bottom: 2px solid #c23e3e !important;
        position: absolute;
    }

    .browser-container {
        bottom: 170px;
        right: 26px;
        position: fixed;
        color: #00FF00;
    }

    .button-64 {
        align-items: center;
        /*primary: '#A20916',*/
        /*secondary: '#DE2828',*/
        /*accent: '#fffffb',*/
        background-image: linear-gradient(144deg, #DE2828, #A20916 50%, #fffffb);
        border: 0;
        border-radius: 8px;
        box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
        box-sizing: border-box;
        color: #000000;
        display: flex;
        font-family: Phantomsans, sans-serif;
        font-size: 20px;
        justify-content: center;
        line-height: 1em;
        max-width: 150px;
        /*min-width: 140px;*/
        padding: 3px;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        cursor: pointer;
    }

    /*.button-64:active,*/
    /*.button-64:hover {*/
    /*    outline: 0;*/
    /*}*/

    .button-64 span {
        /*background-color: rgb(5, 6, 45);*/
        background-color: white;
        padding: 16px 24px;
        border-radius: 6px;
        width: 100%;
        height: 170px;
        transition: 300ms;
    }

    /*.button-64:hover span {*/
    /*    background: none;*/
    /*}*/

    @media (min-width: 768px) {
        .button-64 {
            font-size: 24px;
            min-width: 196px;
        }
    }

    .button-75 {
        align-items: center;
        background-image: linear-gradient(135deg, #f34079 40%, #fc894d);
        border: 0;
        border-radius: 10px;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-family: "Codec cold", sans-serif;
        font-size: 16px;
        font-weight: 700;
        height: 54px;
        justify-content: center;
        letter-spacing: .4px;
        line-height: 1;
        max-width: 135px;
        width: 180px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 3px;
        text-decoration: none;
        text-transform: uppercase;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .button-75:active {
        outline: 0;
    }

    .button-75:hover {
        outline: 0;
    }

    .button-75 span {
        transition: all 200ms;
    }

    .button-75:hover span {
        transform: scale(.9);
        opacity: .75;
    }

    @media screen and (max-width: 991px) {
        .button-75 {
            font-size: 15px;
            height: 50px;
        }

        .button-75 span {
            line-height: 50px;
        }
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>
