<template>
    <div>
        <div class="text-center  pb-8" style="padding-top: 100px">
            <v-chip color="#A20916" class="white--text font-weight-bold">성적 상승 프로그램</v-chip>
        </div>
        <div class="text-center">
            <h1 style="letter-spacing: 0px; line-height: 36px">
                <span class="border-highlight">세심한 1:1</span>관리로
            </h1>
            <div></div>
            <h1>성적 향상을 이끌어요</h1>
            <div class="py-2"></div>
            <div style="font-size: 17px" class="font-weight-medium">
                3단계 학습 관리로 빈틈없는 시스템 과외
            </div>
            <div class="py-5"></div>
            <div class="center-horizontal">
                <div style="max-width: 600px; height: 25px;background-color: #A30717; color: white; width: 95%; border-radius: 5px; font-size: 13px"
                     class="font-weight-bold center-vertical">STEP 1
                </div>
                <v-card
                        class="mx-auto rounded-xl"
                        elevation="0"
                        height="200"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="130"
                                color="transparent"
                                class="mr-4 ml-1 "
                                style="outline-style: solid; outline-width: thin; outline-color: lightgray; border-radius: 15px !important;"
                        >
                            <img src="../../assets/evaluate1.png"/>

                        </v-list-item-avatar>

                        <v-list-item-content class="center-vertical">
                            <div class="font-weight-bold mt-3 text-left " style="font-size: 17px">
                                학습 성취도 진단
                                <div class="" style="padding-bottom: 15px"></div>
                                <div class="text-left"
                                     style="font-size: 14px; font-weight: normal; padding-right: 90px; line-height: 20px">
                                    아이비에드 무료 체험수업을 통해 첫 수업 시 학습 실력 점검
                                </div>

                            </div>
                            <div class="mt-1"></div>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <v-card class="mx-auto rounded-xl"
                        elevation="0"
                        height="200"
                        style="margin-top: -40px"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="130"
                                color="transparent"
                                class="mr-4 ml-1 "
                                style="outline-style: solid; outline-width: thin; outline-color: lightgray; border-radius: 15px !important;"
                        >
                            <img src="../../assets/global-education.png"/>

                        </v-list-item-avatar>

                        <v-list-item-content class="center-vertical">
                            <div class="font-weight-bold mt-3 text-left " style="font-size: 17px">
                                학습 현황 점검과 목표 설정
                                <div class="" style="padding-bottom: 15px"></div>
                                <div class="text-left"
                                     style="font-size: 14px; font-weight: normal; padding-right: 90px; line-height: 20px">
                                    현재 공부 습관 및 공부 시간 등 분석을 통한 목표 설정
                                </div>

                            </div>
                            <div class="mt-1"></div>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <v-card
                        class="mx-auto rounded-xl"
                        elevation="0"
                        height="200"
                        style="margin-top: -40px"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="130"
                                color="transparent"
                                class="mr-4 ml-1 "
                                style="outline-style: solid; outline-width: thin; outline-color: lightgray; border-radius: 15px !important;"
                        >
                            <img src="../../assets/Wavy_Edu-03_Single-11.jpg"/>

                        </v-list-item-avatar>

                        <v-list-item-content class="center-vertical">
                            <div class="font-weight-bold mt-3 text-left " style="font-size: 17px">
                                1:1 수업 로드맵 설계
                                <div class="" style="padding-bottom: 15px"></div>
                                <div class="text-left"
                                     style="font-size: 14px; font-weight: normal; padding-right: 90px; line-height: 20px">
                                    개인별 맞춤 학습,
                                    커리큘럼 제공
                                </div>

                            </div>
                            <div class="mt-1"></div>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <div style="max-width: 600px; height: 25px;background-color: #A30717; color: white; width: 95%; border-radius: 5px; font-size: 13px"
                     class="font-weight-bold center-vertical">STEP 2
                </div>
                <v-card style="max-width: 600px; background-color: #F6F6F6; border-radius: 15px; width: 95%;" class="text-center mx-9"
                        elevation="0">
                    <!--                background-color: #F6F6F6-->
                    <div class="pa-2 text-center" style="">
                        <!--                        <img style="outline-style: solid; outline-width: thin; border-radius: 15px; outline-color: white" src="../../assets/trig_clip.gif"  height="300"/>-->
                        <img style="outline-style: solid; outline-width: thin; border-radius: 15px; outline-color: white"
                             src="../../assets/ib_math.gif" width="100%" height="300px"/>
                    </div>
                </v-card>
                <div class="py-2"></div>
                <div style="max-width: 600px; border-radius: 5px; width: 95%; background-color: #F2F2F2; font-size: 14px"
                     class="pa-3 text-left">
                    학생의 성취도와 성향에 따른 1:1 맞춤 수업 <br/>
                    수업 교재도 태블릿에서 간편하게 이용 가능
                </div>
                <div class="py-5"></div>
                <div style="max-width: 600px; height: 25px;background-color: #A30717; color: white; width: 95%; border-radius: 5px; font-size: 13px"
                     class="font-weight-bold center-vertical">STEP 3
                </div>
                <div class="pl-1">
                <v-card
                        class="mx-auto rounded-xl"
                        elevation="0"
                        height="200"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="130"
                                color="transparent"
                                class="mr-4 ml-1 "
                                style="outline-style: solid; outline-width: thin; outline-color: lightgray; border-radius: 15px !important;"
                        >
                            <img src="../../assets/homeworkManagement.jpg"/>

                        </v-list-item-avatar>

                        <v-list-item-content class="center-vertical">
                            <div class="font-weight-bold mt-3 text-left " style="font-size: 17px">
                                목표 학습량과 숙제 관리
                                <div class="" style="padding-bottom: 15px"></div>
                                <div class="text-left"
                                     style="font-size: 14px; font-weight: normal; padding-right: 90px; line-height: 20px">
                                    매 수업 시 숙제 검사와 <br/>
                                    목표 학습량 진척도 피드백
                                </div>

                            </div>
                            <div class="mt-1"></div>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <v-card
                        class="mx-auto rounded-xl"
                        elevation="0"
                        height="200"
                        style="margin-top: -40px"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="130"
                                color="transparent"
                                class="mr-4 ml-1 "
                                style="outline-style: solid; outline-width: thin; outline-color: lightgray; border-radius: 15px !important;"
                        >
                            <img src="../../assets/Report.gif" style="height: 90%"/>

                        </v-list-item-avatar>

                        <v-list-item-content class="center-vertical">
                            <div class="font-weight-bold mt-3 text-left " style="font-size: 17px">
                                학생 및 학부모 리포트 발송
                                <div class="" style="padding-bottom: 15px"></div>
                                <div class="text-left"
                                     style="font-size: 14px; font-weight: normal; padding-right: 90px; line-height: 20px">
                                    수업 종료 후 숙제 및 학습 태도 리포트 발송
                                </div>

                            </div>
                            <div class="mt-1"></div>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <v-card
                        class="mx-auto rounded-xl"
                        elevation="0"
                        height="200"
                        style="margin-top: -40px"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="130"
                                color="transparent"
                                class="mr-4 ml-1 "
                                style="outline-style: solid; outline-width: thin; outline-color: lightgray; border-radius: 15px !important;"
                        >
                            <img src="../../assets/ib_math.gif" style="height: 100%"/>

                        </v-list-item-avatar>

                        <v-list-item-content class="center-vertical">
                            <div class="font-weight-bold mt-3 text-left " style="font-size: 17px">
                                수업 다시보기
                                <div class="" style="padding-bottom: 15px"></div>
                                <div class="text-left"
                                     style="font-size: 14px; font-weight: normal; padding-right: 90px; line-height: 20px">

                                    다시보기 기능으로 언제든지 복습 가능

                                </div>

                            </div>
                            <div class="mt-1"></div>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                </div>
            </div>
        </div>
        <div class="py-5"></div>
        <div style="background-color: #F6F6F6">
            <div class="text-center  pb-8" style="padding-top: 100px;">
                <v-chip color="#A20916" class="white--text font-weight-bold">24시간 상담 및 Q&A</v-chip>
            </div>
            <div class="text-center">
                <h1 style="letter-spacing: 0px; line-height: 40px">
                    <span class="border-highlight">전 세계에 있는 </span><br/>상담사 및 선생님께
                    <br/>
                    언제든 상담 및 질문 가능해요
                </h1>
                <div></div>
                <div class="py-2"></div>
                <div style="font-size: 17px" class="font-weight-medium">
                    24/7 질문, 상담, 답변받는 스마트 과외
                </div>

                <div class="py-5"></div>

                <div class="center-horizontal">
                <div class="" style="max-width: 600px;">
                    <v-card class="ma-3"
                            style=" box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; border-radius: 10px">
                        <div style="background-color: #fceded">
                            <div style="height: 300px">
                                <img src="../../assets/messaging.png" style="height: 90%; " class="pa-4">
                            </div>
                        </div>
                        <div class="pa-5">
                            <div style="font-size: 19px; font-weight: bold">언제 어디서든 실시간 질문 답변</div>
                            <div class="py-2"></div>
                            <div class="text-left"><span style="color: #A30717" content="\2713"> &#10004;  </span> 24/7
                                질문 가능한 아이비에드
                            </div>
                            <!--                            <div class="text-left"><span style="color: #A30717" content="\2713"> &#10004;  </span> 27/7 질문 가능한 아이비에드</div>-->
                        </div>
                    </v-card>
                    <div class="py-1"></div>
                    <v-card class="ma-3"
                            style="box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; border-radius: 10px">
                        <div style="background-color: #fceded">
                            <div style="height: 300px">
                                <img src="../../assets/ib_math.gif" style="height: 100%; width: 100%" class="pa-4">
                            </div>
                        </div>
                        <div class="pa-5">
                            <div style="font-size: 19px; font-weight: bold">매 수업 녹화본으로 바로 복습 가능</div>
                            <div class="py-2"></div>
                            <div class="text-left"><span style="color: #A30717" content="\2713"> &#10004;  </span> 수업 중
                                필기없이 수업에만 집중
                            </div>
                            <div class="text-left"><span style="color: #A30717" content="\2713"> &#10004;  </span> 매 수업
                                녹화본으로 언제든 복습 가능
                            </div>
                            <!--                            <div class="text-left"><span style="color: #A30717" content="\2713"> &#10004;  </span> 27/7 질문 가능한 아이비에드</div>-->
                        </div>
                    </v-card>

                </div>
                </div>
            </div>
            <div class="py-10"></div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "StudyManagement"
    }
</script>

<style scoped>
    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

</style>
