import VueRouter from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const Profile = () => import("./components/Profile.vue");
// const BoardAdmin = () => import("./components/BoardAdmin.vue");
// const BoardModerator = () => import("./components/BoardModerator.vue");
// const BoardUser = () => import("./components/BoardUser.vue");
// const BoardTeacher = () => import("./components/BoardTeacher.vue");
// const BoardStudent = () => import("./components/BoardStudent.vue");
// const NavigationBar = () => import("./components/NavigationBar");
import Main from './views/Main';
// import NavigationBar from "./components/NavigationBar";
// import Intro from "./components/Intro/Intro";
// import Review from "./views/pages/Review";
// import Tuition from "./views/pages/Tuition";
import RegisterSuccess from "./components/RegisterSuccess";
import RegisterSuccessDialog from './components/RegisterSuccessDialog'
import TeacherRegister from "./components/teacher/TeacherRegister";
import PaymentForm from "./components/Payment/PaymentForm";
import Payment from "./components/Payment/Payment";
import TeacherVerify from "./components/teacher/TeacherVerify";
import Admin from "./components/admin/Admin";
import MeetingInitiation from "./components/admin/MatchingInitiation"
import Enrollment from "./components/student/Enrollment/Enrollment";
import StudentMatchingInfoForm from "./components/student/Matching/StudentMatchingInfoForm";
import EditProfile from "./components/User/EditProfile";
import PasswordRecovery from "./components/User/PasswordRecovery";
import FinalDegree from "./components/teacher/Register/FinalDegree";
import College from "./components/teacher/Register/College";
import Highschool from "./components/teacher/Register/Highschool";
import SubjectsOfExpertise from "./components/teacher/Register/SubjectsOfExpertise";
import LessonTimes from "./components/teacher/Register/LessonTimes";
import Duration from "./components/teacher/Register/Duration";
import Experience from "./components/teacher/Register/Experience";
import AboutLecture from "./components/teacher/Register/AboutLecture";
import AboutTeacher from "./components/teacher/Register/AboutTeacher";
import TeacherSummary from "./components/teacher/Register/TeacherSummary";
import PaymentConfirmation from "./components/Payment/PaymentConfirmation";
import EditTeacherProfile from "./components/User/EditTeacherProfile";
import ViewTeacherSummary from "./components/teacher/ViewTeacherSummary";
import ScheduledLessons from "./components/admin/ScheduledLessons";
// import FaqPage from "./views/pages/FaqPage";
import TeacherBalance from "./components/teacher/Balance/TeacherBalance";
import AdminBalance from "./components/admin/AdminBalance";
import TeacherBankAccount from "./components/teacher/BankAccount/TeacherBankAccount";
import CardInfo from "./components/Payment/CardInfo";
import StudentRenew from "./components/admin/StudentRenew";
import Vue from "vue";
import VueGtag from "vue-gtag";
// import Faq from "./views/pages/Faq";
// import HomeTest from "./components/Intro/HomeTest";
// import HomeTestNew from "./components/Intro/HomeTest-new";
// import FaqPage from "./views/pages/FaqPage";
// import FreeTrialJinseoGrace from "./components/FreeTrial/FreeTrialJinseoGrace";
// import Blog from "./components/Others/Blog";
// import FreeTrialForm from "./components/FreeTrial/FreeTrialForm";
// import EAvsED from "./components/Others/BlogPosts/EAvsED";
// import APCal from "./components/Others/BlogPosts/APCal";
// import SATorNOT from "./components/Others/BlogPosts/SATorNOT";
import scheduleCall from "./components/helpers/ScheduleAppointment";
// import FreeTrialJinseoGeometry from "./components/FreeTrial/FreeTrialJinseoGeometry";
// import FreeTrialEricHannah from "./components/FreeTrial/FreeTrialEricHannah";
// import TestPaymentWall from "./components/Payment/TestPaymentWall";
// import DigitalSAT from "./components/Others/BlogPosts/DigitalSAT";
// import DigitalSAT2 from "./components/Others/BlogPosts/DigitalSAT2";
// import FreeTrialJinseoSSAT from "./components/FreeTrial/FreeTrialJinseoSSAT";
// import ECActivities from "./components/Others/BlogPosts/ECActivities";
// import AppEssayPart1 from "./components/Others/BlogPosts/AppEssayPart1";
import StudentTrialForm from "./components/student/StudentTrialForm";
// import AppEssayPart2 from "./components/Others/BlogPosts/AppEssayPart2";
// import APCSAvsP from "./components/Others/BlogPosts/APCSAvsP";
import UseCase from "./views/pages/UseCase";
import ScheduleAppointmentConfirmPage from "./components/helpers/ScheduleAppointmentConfirmPage";
import FirstPage from "./views/pages/FirstPage";
import ColumnSection from "./views/pages/ColumnSection";
import TeacherIntroNew from "./views/pages/TeacherIntroNew";
import StudyManagement from "./views/pages/StudyManagement";
// import BootcampMain from "./components/bootcamp/BootcampMain";
// import BootcampRegister from "./components/bootcamp/BootcampRegister";
// import BootcampRegisterConfirmPage from "./components/bootcamp/BootcampRegisterConfirmPage";
// import FinalBootcamp from "./components/bootcamp/FinalBootcamp";
// import ReadingClubMain from "./components/bookClub/ReadingClubMain";
// import ReadingClubRegister from "./components/bookClub/ReadingClubRegister";
// import ConsultingMain from "./components/consulting/ConsultingMain";
// import DetailedCurriculum from "./com지ponents/bootcamp/DetailedCurriculum";
// import SummerCampMain from "./components/summerCamp/SummerCampMain";
import TeacherClassReports from "./components/teacher/TeacherClassReports";
import StudentClassReports from "./components/student/StudentClassReports";
import ClassReportForm from "./components/teacher/Lessons/ClassReportForm";
// import Test from "./test/Test";
// import LandingPage from "./components/Intro/newLanding/LandingPage";
import BrandStory from "./components/Intro/newLanding/BrandStory";
import AboutIVYED from "./components/Intro/newLanding/AboutIVYED";
import NewReview from "./components/Intro/newLanding/NewReview";
// import NewFaq from "./components/Intro/newLanding/Faq/NewFaq";
import MakeTrialClass from "./components/admin/MakeTrialClass";
import NewFaq from "./components/Intro/newLanding/Faq/NewFaq";
import BootcampMain from "./components/bootcamp/BootcampMain";
// import AllBootcamp from "./components/bootcamp/AllBootcamp";
// import FinalBootcamp from "./components/bootcamp/FinalBootcamp";
// import ap_calculus_ab_ashley from "./components/bootcamp/ApCalculusAshley";
// import ApCalculusAshley from './components/bootcamp/ApCalculusAshley'
// import APChemistryShin from "./components/bootcamp/APChemistryShin";
// import BootcampRegister from "./components/bootcamp/BootcampRegister";
// import BootcampRegisterConfirmPage from "./components/bootcamp/BootcampRegisterConfirmPage";
// import ApCalculusHenry from "./components/bootcamp/ApCalculusHenry";
// import NewFaq2 from "./components/Intro/newLanding/Faq/NewFaq2";
// import NewFaq from "./components/Intro/newLanding/Faq/NewFaq";
// import NewFaq2 from "./components/Intro/newLanding/Faq/NewFaq2";
// import FreeTrialMichelle from "./components/FreeTrial/FreeTrialMichelle";
// import TestEximbay from "./components/Payment/TestEximbay";
import AllBootcamp from "./components/bootcamp/AllBootcamp";
import BootcampRegister from "./components/bootcamp/BootcampRegister";
import BootcampRegisterConfirmPage from "./components/bootcamp/BootcampRegisterConfirmPage";
import FinalBootcamp from "./components/bootcamp/FinalBootcamp";
import AcademicWriting from "./components/bootcamp/AcademicWriting";
import APBiology from "./components/bootcamp/APBiology";
import APChemistry from "./components/bootcamp/APChemistry";
import Calendar from "./components/Calendar";
import APPhysicsCConcept from "./components/bootcamp/APPhysicsCConcept";
import APPhysics1 from "./components/bootcamp/APPhysics1";
import APBiologyProblem from "./components/bootcamp/APBiologyProblem";
import ApChemistryProblem from "./components/bootcamp/ApChemistryProblem";
// import Test from "./test/Test";
// import Test2 from "./test/Test2";

const routes = [
    {
        path: "/",
        component: Main,
        // name: "home",
        // component: Home,
        children: [
            {
                path: "/",
                name: "home",
                component: Home,
            },
            // {
            // {
            //     path: "/test",
            //     name: "test",
            //     component: Test,
            // },
            // {
            //     path: "/test2",
            //     name: "test",
            //     component: Test2,
            // },
            {
                path: "/login",
                name: 'login',
                component: Login,
                meta: {
                    title : "로그인",
                }
            },
            {
                path: "/register",
                name: "register",
                component: Register,
                meta: {
                    title : "회원가입",
                }
            },
            {
                path: "/profile",
                name: "profile",
                // lazy-loaded
                component: Profile,
            },
            {
                path: "/payment",
                name: "payment",
                component: Payment,
                meta: {conditionalRoute: true}
            },
            {
                path: "/paymentForm",
                name: "paymentForm",
                component: PaymentForm,
                beforeEnter: (to, from) => {
                    console.log(to)
                    console.log(from)
                    // if(from != 'payment') {
                    //     return false;
                    // }
                },
            },
            {
                path: "/payment/confirmation",
                name: "paymentConfirmation",
                component: PaymentConfirmation,
                beforeEnter: (to, from, next) => {
                    if (from.name == 'payment') {
                        next();
                    } else {
                        next('/profile')
                    }
                },
            },
            {
                path: "/payment/card/info",
                name: "cardInfo",
                component: CardInfo,
            },
            {
                path: "student/matching/info",
                name: "matchingInfoForm",
                component: StudentMatchingInfoForm
            },
            {
                path: "/enroll",
                name: "enrollment",
                component: Enrollment
            },
            // {
            //     path: "/admin",
            //     name: "admin",
            //     // lazy-loaded
            //     component: BoardAdmin,
            // },
            // {
            //     path: "/mod",
            //     name: "moderator",
            //     // lazy-loaded
            //     component: BoardModerator,
            // },
            // {
            //     path: "/user",
            //     name: "user",
            //     // lazy-loaded
            //     component: BoardUser,
            // },
            // {
            //     path: "/teacher",
            //     name: "teacher",
            //     // lazy-loaded
            //     component: BoardTeacher,
            // },
            // {
            //     path: "/student",
            //     name: "student",
            //     // lazy-loaded
            //     component: BoardStudent,
            // },

            {
                path: "/teacher/register",
                name: "teacherRegister",
                // lazy-loaded
                component: TeacherRegister,
            },
            {
                path: "/teacher/register/finalDegree",
                name: "finalDegree",
                // lazy-loaded
                component: FinalDegree,
            },
            {
                path: "/teacher/register/college",
                name: "college",
                // lazy-loaded
                component: College,
            },
            {
                path: "/teacher/register/highschool",
                name: "highschool",
                // lazy-loaded
                component: Highschool,
            },
            {
                path: "/teacher/register/subjects",
                name: "subjectsOfExpertise",
                // lazy-loaded
                component: SubjectsOfExpertise,
            },
            {
                path: "/teacher/register/lesson/times",
                name: "lessonTimes",
                // lazy-loaded
                component: LessonTimes,
            },
            {
                path: "/teacher/register/lesson/duration",
                name: "duration",
                // lazy-loaded
                component: Duration,
            },
            {
                path: "/teacher/register/lesson/experience",
                name: "teacherExperience",
                // lazy-loaded
                component: Experience,
            },
            ///teacher/register/teacher/info

            {
                path: "/teacher/register/lecture/info",
                name: "aboutLecture",
                // lazy-loaded
                component: AboutLecture,
            },
            {
                path: "/teacher/register/teacher/info",
                name: "aboutTeacher",
                // lazy-loaded
                component: AboutTeacher,
            },
            {
                path: "/teacher/register/teacher/summary",
                name: "teacherSummary",
                // lazy-loaded
                component: TeacherSummary,
            },
            {
                path: "/teacher/balance",
                name: "teacherBalance",
                // lazy-loaded
                component: TeacherBalance,
            },

            {
                path: "/teacher/bankAccount",
                name: "teacherBankAccount",
                // lazy-loaded
                component: TeacherBankAccount,
            },
            {
                path: "/teacher/summary",
                name: "viewTeacherSummary",
                // lazy-loaded
                component: ViewTeacherSummary,
            },
            {
                path: "/teacher/verify",
                name: "teacherVerify",
                // lazy-loaded
                component: TeacherVerify,
            },

            // {
            //     path: "/faq",
            //     name: "faq",
            //     component: FaqPage,
            //     meta: {
            //         title: "FAQ"
            //     }
            // },
            {
                path: "/faq",
                name: "faq",
                component: NewFaq,
                meta: {
                    title: "FAQ"
                }
            },
            {
                path: "/bootcamp/academicwriting",
                name: "academicWriting",
                component: AcademicWriting,
                meta: {
                    title: "[IVYED] Interactive Academic Writing 겨울특강",
                    description : "[IVYED] Interactive Academic Writing 겨울특강",

                }
            },
            // {
            //     path: "/intro",
            //     name: "intro",
            //     component: Intro,
            // },
            // {
            //     path: "/test/home",
            //     name: "HomeTest",
            //     component: HomeTest
            // },
            // {
            //     path: "/review",
            //     name: "review",
            //     component: Review,
            //     meta: {
            //         title : "후기",
            //     }
            // },
            {
                path: "/usecase",
                name: "usecase",
                component: UseCase,
            },
            {
                path: "/first",
                name: "firstSection",
                component: FirstPage,
            },
            // {
            //     path: "/tuition",
            //     name: "tuition",
            //     component: Tuition,
            // },
            {
                path: "/registerSuccess",
                name: "registerSuccess",
                component: RegisterSuccess,
            },
            {
                path: "/registerSuccessDialog",
                name: "registerSuccessDialog",
                component: RegisterSuccessDialog,
            },
            {
                path: "/admin",
                name: "admin",
                component: Admin,
            },
            {
                path: "/admin/matching/initiate",
                name: "initiateMeeting",
                component: MeetingInitiation,
            },
            {
                path: "/admin/lessons/scheduled",
                name: "scheduledLessons",
                component: ScheduledLessons,
            },
            {
                path: "/admin/balance",
                name: "adminBalance",
                component: AdminBalance,
            },
            {
                path: "/admin/renew/student",
                name: "studentRenew",
                component: StudentRenew,
            },
            {
                path: "/admin/create/trialclass",
                name: "MakeTrialClass",
                component: MakeTrialClass,
            },
            {
                path: "/profile/edit",
                name: "editProfile",
                component: EditProfile
            },
            {
                path: "/profile/edit/teacher",
                name: "editTeacherProfile",
                component: EditTeacherProfile
            },
            // {
            //     path: "/timezone",
            //     name: "timezone",
            //     component: TimezoneImage
            // },
            {
                path: "/password/recover",
                name: "passwordRecovery",
                component: PasswordRecovery,
                meta: {
                    title: "비밀번호 찾기",
                }
            },
            // {
            //     path: "/trial/b2dA2",
            //     name: "FreeTrialJinseoGrace",
            //     component: FreeTrialJinseoGrace
            // },
            // {
            //     path: "/trial/a2fg3",
            //     name: "FreeTrialJinseoGeometry",
            //     component: FreeTrialJinseoGeometry
            // },
            // {
            //     path: "/trial/h93sfa",
            //     name: "FreeTrialJinseoSSAT",
            //     component: FreeTrialJinseoSSAT
            // },
            // {
            //     path: "/trial/e0g2ad",
            //     name: "FreeTrialMichelle",
            //     component: FreeTrialMichelle
            // },
            // {
            //     path: "/trial/d2efg3",
            //     name: "FreeTrialEricHannah",
            //     component: FreeTrialEricHannah
            // },
            // {
            //     path: "/blog",
            //     name: "Blog",
            //     component: Blog,
            // },
            {
                path: '/blog',
                beforeEnter(to, from, next) {
                    window.open('https://blog.naver.com/ivyed', '_blank')
                    next(false) // prevent the route from being processed
                }
            },
            // {
            //     path: "/blog/6",
            //     name: "ECActivities",
            //     component: ECActivities,
            // },
            // {
            //     path: "/blog/9",
            //     name: "APCSAvsP",
            //     component: APCSAvsP
            // },
            // {
            //     path: "/blog/8",
            //     name: "AppEssayPart2",
            //     component: AppEssayPart2,
            // },
            // {
            //     path: "/blog/7",
            //     name: "AppEssayPart1",
            //     component: AppEssayPart1,
            // },
            // {
            //     path: "/blog/5",
            //     name: "DigitalSAT2",
            //     component: DigitalSAT2,
            // },
            // {
            //     path: "/blog/4",
            //     name: "DigitalSAT",
            //     component: DigitalSAT,
            // },
            // {
            //     path: "/blog/3",
            //     name: "EAvsED",
            //     component: EAvsED,
            // },
            // {
            //     path: "/blog/2",
            //     name: "APCal",
            //     component: APCal,
            // },
            // {
            //     path: "/blog/1",
            //     name: "SATorNOT",
            //     component: SATorNOT,
            // },
            // {
            //     path: "/free-trial",
            //     name: "FreeTrialForm",
            //     component: FreeTrialForm
            // },
            {
                path: "/schedule/call",
                name: "scheduleCall",
                component: scheduleCall,
                meta: {
                    title: "상담 예약",
                }
            },
            // {
            //     path: "/test/paymentwall",
            //     name: 'TestPaymentWall',
            //     component: TestPaymentWall
            // }
            // {
            //   path: "/test/eximbay",
            //   name: "TestEximbay",
            //   component: TestEximbay
            // },
            {
                path: "/trial/register",
                name: "studentTrialForm",
                component: StudentTrialForm,
                meta: {
                    title : "체험수업 신청",
                }
            },
            // {
            //     path: "/test/home/new",
            //     name: "HomeTest-new",
            //     component: HomeTestNew
            // },
            // {
            //     path: "/test/landing",
            //     name: "Landing",
            //     component: LandingPage,
            // },
            {
                path: "/schedule/comfirmed",
                name: "appointmentConfirmed",
                component: ScheduleAppointmentConfirmPage,
            },
            {
                path: "/column",
                name: "columnSection",
                component: ColumnSection,
            },
            {
                path: "/teacher/intro",
                name: "teacherIntroSection",
                component: TeacherIntroNew
            },
            {
                path: "/management",
                name: "StudyManagement",
                component: StudyManagement
            },

            {
                path: "/bootcamp/apcsa",
                name: "BootcampMain",
                component: BootcampMain,
                meta: {
                    title: "[IVYED] AP Computer Science A 겨울특강",
                    description: "[IVYED] AP Computer Science A 겨울특강"
                },
            },
            {
                path: "/bootcamp/register",
                name: "BootcampRegister",
                component: BootcampRegister
            },
            {
                path: "/bootcamp/apcsa/register/confirmed",
                name: "BootcampRegisterConfirmPage",
                component: BootcampRegisterConfirmPage
            },
            {
                path: "/bootcamp/apcsp",
                name: "FinalBootcamp",
                component: FinalBootcamp,
                meta: {
                    title: "[IVYED] AP Computer Science Principles 겨울특강",
                    description: "[IVYED] AP Computer Science Principles 겨울특강"
                }
            },
            {
                path: "/bootcamp/apbiology",
                name: "APBiology",
                component: APBiology,
                meta: {
                    title: "[IVYED] AP Biology 겨울특강",
                    description: "[IVYED] AP Biology 겨울특강"
                }
            },
            {
                path: "/bootcamp/apchemistry",
                name: "APChemistry",
                component: APChemistry,
                meta: {
                    title: "[IVYED] AP Chemistry 겨울특강",
                    description: "[IVYED] AP Chemistry 겨울특강"
                }
            },
            {
                path: "/bootcamp/apphysicscconcept",
                name: "APPhysicsCConcept",
                component: APPhysicsCConcept,
                meta: {
                    title: "[IVYED] AP Physics C concept 겨울특강",
                    description: "[IVYED] AP Physics C concept 겨울특강"
                }
            },
            {
                path: "/bootcamp/apphysicsproblem",
                name: "APPhysics1",
                component: APPhysics1,
                meta: {
                    title: "[IVYED] AP Physics 1 문제풀이 겨울특강",
                    description: "[IVYED] AP Physics 1 문제풀이 겨울특강"
                }
            },
            {
                path: "/bootcamp/apchemistryproblem",
                name: "ApChemistryProblem",
                component: ApChemistryProblem,
                meta: {
                    title: "[IVYED] AP Chemistry 문제풀이 겨울특강",
                    description: "[IVYED] AP Chemistry 문제풀이 겨울특강"
                }
            },
            {
                path: "/bootcamp/apbiologyproblem",
                name: "APBiologyProblem",
                component: APBiologyProblem,
                meta: {
                    title: "[IVYED] AP Biology 문제풀이 겨울특강",
                    description: "[IVYED] AP Biology 문제풀이 겨울특강"
                }
            },
            {
                path: "/bootcamp/calendar",
                name: "BootcampSchedule",
                component: Calendar,
                meta: {
                    title: "[IVYED] 수업 캘린더",
                    description: "[IVYED] 수업 캘린더"
                }
            },
            {
                path: "/bootcamp",
                name: "AllBootcamp",
                component: AllBootcamp,
                meta:{
                    title:"[IVYED] 유학과목 겨울특강",
                    description : "[IVYED] 유학과목 겨울특강",
                }
            },
            {
                path: "/teacher/classreports",
                name: "TeacherClassReports",
                component: TeacherClassReports

            },
            {
                path: "/student/classreports",
                name: "StudentClassReports",
                component: StudentClassReports

            },
            {
                path: "/teacher/classreport/create",
                name: "ClassReportForm",
                component: ClassReportForm

            },

            // {
            //     path: "/test/qna",
            //     name: "test",
            //     component: Test
            //
            // },

            // {
            //     path: "/consulting",
            //     name: "ConsultingMain",
            //     component: ConsultingMain
            // },
            {
                path: '/brandstory',
                name: 'BrandStory',
                // route level code-splitting
                // this generates a separate chunk (projects.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: BrandStory,
                meta: {
                    title: '브랜드 스토리',
                },
            },
            {
                path: '/about',
                name: 'AboutIVYED',
                // route level code-splitting
                // this generates a separate chunk (projects.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: AboutIVYED,
                meta: {
                    title: 'IVYED',
                },
            },
            {
                path: '/review',
                name: 'NewReview',
                // route level code-splitting
                // this generates a separate chunk (projects.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: NewReview,
                meta: {
                    title: '후기',
                },
            }

        ],

    },
];

const router = new VueRouter({
    // history: VueRouter.install()createWebHistory(),
    routes,
    mode: 'history',
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});



Vue.use(VueGtag, {
    appName: 'IVYED-web-app',
    pageTrackerScreenviewEnabled: true,
    config: {
        id: 'G-XSHBZ4YD0F'
    },
}, router);

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });
router.beforeEach((to, from, next) => {
    // Access the meta data of the current route
    const title = to.meta.title || 'SAT,AP,IB,미국내신 등 1%와 1:1 과외-IVYED 아이비에드';
    document.title = title;
    next();
});


export default router;
