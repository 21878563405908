<template>
    <div>
        <div class="py-8"></div>
        <v-card
                max-width="400"
                class="mx-auto"
                elevation="0"
        >

            <div class="py-7"></div>
            <v-container>
                <div class="pl-2 pb-3 pt-5">
                    <div class="font-weight-bold" style="font-size: 18px">
                        [공지] 아이비리그 선생님과 1:1 비대면 과외 소개
                    </div>
                    <div style="color: darkgray" class="font-weight-medium">
                        2022.12.04 ~ 2022.12.31
                    </div>
                </div>
                <v-row dense>
                    <v-col cols="12">

                        <v-card
                                color="primary"
                                dark
                                min-height="200"
                                class="rounded-lg"
                                href="https://blog.naver.com/ivyed/222807613161"
                                target="_blank"
                                width="">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4">
                                    </div>
                                    <div class="pt-2 pl-3">
                                        <v-list-item-title class="">
                                            <div class="font-weight-medium" style="font-size: 18px">
                                                12월 한정 혜택
                                            </div>
                                        </v-list-item-title>
                                        <div class="py-1"></div>
                                        <div style="font-size: 25px; line-height: 35px" class="font-weight-bold white--text">IVYED (아이비에드) <br/> 이벤트로 비대면 1:1 유학생 과외를 무료로 제공합니다.
                                        </div>
                                    </div>
                                </v-list-item-content>

                                <!--                                <v-list-item-avatar-->
                                <!--                                        tile-->
                                <!--                                        size="120"-->
                                <!--                                        color="transparent"-->
                                <!--                                >-->
                                <!--                                    <v-img :src="'https://i.postimg.cc/hj5VQcrj/Pngtree-student-cartoon-teacher-teachers-day-3932574.png'"></v-img>-->
                                <!--                                </v-list-item-avatar>-->
                            </v-list-item>
                            <div class="text-right pr-10">
                                <img style="height: 90px" src="../../assets/graduation-hat.png"/>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col>

                    </v-col>

                </v-row>
                <div class="pl-2 pb-3 pt-5">
                    <div class="font-weight-bold" style="font-size: 18px">
                        아이비에드 미국 입시 칼럼
                    </div>
                    <div style="color: darkgray" class="font-weight-medium">
<!--                        2022.11.04 ~ 2022.12.02-->
                    </div>
                </div>
                <v-row dense>
                    <v-col cols="12">
                        <v-card
                                color="#94979B"
                                dark
                                min-height="200"
                                class="rounded-lg"
                                width=""
                                href="https://blog.naver.com/ivyed/222905477334"
                                target="_blank"
                        >

                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4">
                                    </div>
                                    <div class="pt-2 pl-3">
                                    <v-list-item-title class="">
                                        <div class="font-weight-medium" style="font-size: 18px">
                                        이건 알고 지나가자!
                                        </div>
                                    </v-list-item-title>
                                        <div class="py-1"></div>
                                        <div style="font-size: 22px; line-height: 35px" class="font-weight-bold white--text"> AP Computer Science <br/>A vs. Principles (part 1)
                                        </div>
                                    </div>
                                </v-list-item-content>

<!--                                <v-list-item-avatar-->
<!--                                        tile-->
<!--                                        size="120"-->
<!--                                        color="transparent"-->
<!--                                >-->
<!--                                    <v-img :src="'https://i.postimg.cc/hj5VQcrj/Pngtree-student-cartoon-teacher-teachers-day-3932574.png'"></v-img>-->
<!--                                </v-list-item-avatar>-->

                            </v-list-item>
                            <div class="text-right pr-8 pb-2">
                                <img style="height: 90px" src="../../assets/book-icon.png"/>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col>

                    </v-col>

                </v-row>

            </v-container>
        </v-card>
        <div class="py-15"></div>
    </div>
</template>

<script>
    export default {
        name: "ColumnSection",
        components: {}
    }
</script>

<style scoped>

</style>
