<template class="" style="background-color: #A30717">
    <div>
        <v-container v-if="!currentUser || currentUser.roles[0] != 'ROLE_STUDENT'">
            <RestrictedAcess></RestrictedAcess>
        </v-container>
        <v-container v-if="currentUser && currentUser.roles[0] == 'ROLE_STUDENT'" class="enroll-page py-10 "
                     style="padding: 0px !important;">

            <div class="py-5"></div>
            <div class="main-highlight ma-5 my-10"> 수강료 확인</div>
            <div class="py-2"></div>

            <v-expansion-panels class="mb-7" flat tile
                                style="outline-style: solid; outline-width: 1px; border-radius: 10px; outline-color: #E6F4EF;">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div class="text-left">

                            <div class="font-weight-bold" style="font-size: 18px; letter-spacing: -1px">
                                <v-icon color="primary" class="mb-1" size="28px">mdi-credit-card</v-icon>
                                클릭해서 수강료를 확인하세요!
                            </div>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <!--                    <div class="my-3 ml-6 pb-7">-->
                        <!--                        <v-chip class="mr-3" @click="oneMonth=true">-->
                        <!--                            1개월-->
                        <!--                        </v-chip>-->
                        <!--                        <v-chip class="mr-3">-->
                        <!--                            3개월-->
                        <!--                        </v-chip>-->
                        <!--                        <v-chip class="mr-3">-->
                        <!--                            12개월-->
                        <!--                        </v-chip>-->
                        <!--                    </div>-->
                        <v-card flat class="mt-2">
                            <div>
                                <span class="highlight ml-7 mr-4">주 1회</span>
                                <span class="lessonType">60분(총 4회)</span>
                                <span class="lessonPrice ml-5"> 월 439,000원</span>
                            </div>
                            <div class="my-3"></div>
                            <div>
                                <span class="highlight ml-7 mr-4">주 1회</span>
                                <span class="lessonType">90분(총 4회)</span>
                                <span class="lessonPrice ml-5"> 월 659,000원</span>
                            </div>
                            <v-divider class="my-6"></v-divider>
                            <div>
                                <span class="highlight ml-7 mr-4">주 2회</span>
                                <span class="lessonType">60분(총 8회)</span>
                                <span class="lessonPrice ml-5"> 월 799,000원</span>
                            </div>
                            <div class="my-3"></div>
                            <div>
                                <span class="highlight ml-7 mr-4">주 2회</span>
                                <span class="lessonType">90분(총 8회)</span>
                                <span class="lessonPrice ml-5"> 월 1,139,000원</span>
                            </div>
                            <v-divider class="my-6"></v-divider>
                            <div>
                                <span class="highlight ml-7 mr-4">주 3회</span>
                                <span class="lessonType">60분(총 12회)</span>
                                <span class="lessonPrice ml-5"> 월 1,139,000원</span>
                            </div>
                            <div class="my-3"></div>
                            <div>
                                <span class="highlight ml-7 mr-4">주 3회</span>
                                <span class="lessonType">90분(총 12회)</span>
                                <span class="lessonPrice ml-5"> 월 1,619,000원</span>
                            </div>
<!--                            <v-list-item-subtitle>-->
<!--                                위 가격은 학부 선생님 기준입니다. 전문 대학원 선생님들의 가격은 다른-->
<!--                            </v-list-item-subtitle>-->
                            <!--                        <v-divider class="my-6"></v-divider>-->
                            <!--                        <div class="ml-7" style="font-weight: 500; font-size: 18px;">-->
                            <!--                            월 10만원 추가 시, <span class="primary&#45;&#45;text font-weight-bold">매 수업 녹화본</span> 제공-->
                            <!--                        </div>-->

                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <div v-if="studentContent.payments && studentContent.payments.length == 0">
                <div class="py-5"></div>
                <div class="main-highlight ma-5 my-10">무료 체험수업 혜택</div>
                <div class="py-2"></div>
                <!--                <div class="py-2"></div>-->

                <!--        <v-expansion-panels class="" tile flat max="" style="" :value="0">-->
                <v-expansion-panels class="mb-7" tile flat max="" :value="0"
                                    style="outline-style: solid; outline-width: 1px; border-radius: 10px; outline-color: #E6F4EF;">
                    <v-expansion-panel>
                        <v-expansion-panel-header class="text-left">
                            <div class="ml-3">
                                <v-row class="pt-3">
                                    <div class="font-weight-bold" style="font-size: 16px; letter-spacing: -1px"><span
                                            class="text--primary">수강신청</span>을 결정하기전에
                                    </div>
                                </v-row>
                                <div class="my-6"></div>
                                <v-row class="pb-3">
                                    <div class="font-weight-bold primary--text"
                                         style="font-size: 16px; letter-spacing: -1px">
                                    <span class="text-decoration-underline"
                                          style="font-size: 18px !important;">무료 체험수업</span><span
                                            class="font-weight-bold "
                                            style="color: black !important;font-size: 16px; letter-spacing: -1px">
                                                을 통해 정말 나와 잘 맞는지 알아보세요!
                                            </span>
                                    </div>
                                </v-row>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card flat class="mt-8" style="letter-spacing: -0.5px">
                                <div class="text-decoration-underline" style="font-weight: 600;"></div>
                                <div style="font-size: 15px" class="text-center">
                                    <v-btn elevation="0" large color="" style="font-size: 16px" class="font-weight-bold"
                                           to="trial/register">체험수업 신청하기
                                    </v-btn>
                                </div>
                                <br/>
                                <div style="font-size: 14px">
                                    <!--                                            ※ 개수 제한 없이, 모든 과목에 제공-->
                                </div>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <!--                <div class="main-highlight ma-5 my-10"> 여러과목 혜택</div>-->
            <!--                <div class="py-2"></div>-->

            <!--                <v-expansion-panels class="" tile flat max="" style="" :value="0">-->
            <!--                    <v-expansion-panel>-->
            <!--                        <v-expansion-panel-header class="text-left">-->
            <!--                            <div class="ml-3">-->
            <!--                                <v-row class="pt-3">-->
            <!--                                    <div class="font-weight-bold" style="font-size: 18px; letter-spacing: -1px">여러 과목 수강하면?-->
            <!--                                    </div>-->
            <!--                                </v-row>-->
            <!--                                <div class="my-6"></div>-->
            <!--                                <v-row class="pb-3">-->
            <!--                                    <div class="font-weight-bold primary&#45;&#45;text" style="font-size: 18px; letter-spacing: -1px">-->
            <!--                                        총 횟수로 가격 책정!-->
            <!--                                    </div>-->
            <!--                                </v-row>-->
            <!--                            </div>-->

            <!--                        </v-expansion-panel-header>-->
            <!--                        <v-expansion-panel-content>-->
            <!--                            &lt;!&ndash;                    <div class="my-3 ml-6 pb-7">&ndash;&gt;-->
            <!--                            &lt;!&ndash;                        <v-chip class="mr-3" @click="oneMonth=true">&ndash;&gt;-->
            <!--                            &lt;!&ndash;                            1개월&ndash;&gt;-->
            <!--                            &lt;!&ndash;                        </v-chip>&ndash;&gt;-->
            <!--                            &lt;!&ndash;                        <v-chip class="mr-3">&ndash;&gt;-->
            <!--                            &lt;!&ndash;                            3개월&ndash;&gt;-->
            <!--                            &lt;!&ndash;                        </v-chip>&ndash;&gt;-->
            <!--                            &lt;!&ndash;                        <v-chip class="mr-3">&ndash;&gt;-->
            <!--                            &lt;!&ndash;                            12개월&ndash;&gt;-->
            <!--                            &lt;!&ndash;                        </v-chip>&ndash;&gt;-->
            <!--                            &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--                            <v-card flat class="mt-2" style="letter-spacing: -0.5px">-->
            <!--                                <span class="text-decoration-underline" style="font-weight: 600;"></span> <span-->
            <!--                                    style="font-size: 15px">(3개월 신청 시)</span>-->
            <!--                                <br/>-->
            <!--                                <div style="font-size: 14px">-->
            <!--                                    ※ 개수 제한 없이, 모든 과목에 제공-->
            <!--                                </div>-->
            <!--                            </v-card>-->
            <!--                        </v-expansion-panel-content>-->
            <!--                    </v-expansion-panel>-->
            <!--                </v-expansion-panels>-->

            <div class="py-5"></div>
            <div class="main-highlight ma-5 my-10"> 수강 신청</div>
            <div class="py-2 "></div>

            <div v-if="loading == false" class=" rounded-xl">
                <v-container v-if="!this.currentUser" class="">
                    <v-card
                            class="mx-auto primary--text"
                            width="100%"
                            flat
                            :class="{
                                // 'mt-5': $vuetify.breakpoint.lgAndUp,
                                // 'ml-5': $vuetify.breakpoint.lgAndUp,
                                // 'mb-5': $vuetify.breakpoint.lgAndUp,
                                }"
                    >
                        <v-card-title class="main-title" :class="{}">
                            수강 신청
                        </v-card-title>
                        <div class="ml-5 mr-5 mb-5">
                                <span
                                        :class="{
                                // 'text-sub': $vuetify.breakpoint.mdAndDown,
                                // 'text-h5': $vuetify.breakpoint.lgAndUp
                                }"
                                        class="black--text"
                                >
                                    수업 사항을 선택하여 주세요.
                                </span>
                        </div>
                        <v-divider></v-divider>
                        <template>
                            <v-form class="ml-5 mr-5 mt-5 mb-5"
                                    ref="form"
                                    v-model="valid">
                                    <span class="subtitle-text">
                                        수업 과목 선택
                                    </span>
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group
                                                v-model="enrollmentData.subjectsOfInterest"
                                                :rules="[rules.required, rules.listNotEmpty]"
                                                mandatory
                                        >
                                            <v-radio
                                                    label="Mathematics / Computer Science"
                                                    value="Mathematics / Computer Science"
                                            ></v-radio>
                                            <v-select
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    :items="mathSubSubjects"
                                                    v-model="enrollmentData.subSubjects"
                                                    placeholder="세부 과목을 선택해주세요."
                                                    v-if="enrollmentData.subjectsOfInterest &&
                                                enrollmentData.subjectsOfInterest.includes('Mathematics')">


                                            </v-select>
                                            <v-radio
                                                    label="Science"
                                                    value="Science"
                                            >
                                            </v-radio>
                                            <v-select
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    :items="scienceSubSubjects"
                                                    v-model="enrollmentData.subSubjects"
                                                    v-if="enrollmentData.subjectsOfInterest=='Science'"
                                                    placeholder="세부 과목을 선택해주세요.">

                                            </v-select>
                                            <v-radio
                                                    label="Reading / Writing"
                                                    value="Reading / Writing"
                                            ></v-radio>
                                            <v-select
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    :items="englishSubSubjects"
                                                    v-model="enrollmentData.subSubjects"
                                                    placeholder="세부 과목을 선택해주세요."
                                                    v-if="enrollmentData.subjectsOfInterest &&
                                                enrollmentData.subjectsOfInterest.includes('Reading')">
                                            </v-select>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-divider class="mb-2"></v-divider>
                                <span class="subtitle-text">
                                        수업 횟수
                                    </span>
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group
                                                v-model="enrollmentData.expectedNumberOfLessonsPerWeek"
                                                column
                                                :rules="[rules.required, rules.listNotEmpty]"
                                                mandatory
                                        >
                                            <v-radio
                                                    label="주 1회"
                                                    value="주 1회"
                                            ></v-radio>
                                            <v-radio
                                                    label="주 2회"
                                                    value="주 2회"
                                            ></v-radio>
                                            <v-radio
                                                    label="주 3회"
                                                    value="주 3회"
                                            ></v-radio>
                                            <v-radio
                                                    label="주 4회"
                                                    value="주 4회"
                                            ></v-radio>
                                            <v-radio
                                                    label="주 5회"
                                                    value="주 5회"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-divider class="mb-2"></v-divider>
                                <span class="subtitle-text">
                                        수업 기간 선택
                                    </span>
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group
                                                :rules="[rules.required, rules.listNotEmpty]"
                                                v-model="enrollmentData.expectedLessonDuration"
                                                column
                                                mandatory
                                        >
                                            <v-radio
                                                    label="1개월"
                                                    value="1개월"
                                            >
                                                <!--                                            <template v-slot:label>-->
                                                <!--                                                <div>1개월-->
                                                <!--                                                    &nbsp;-->
                                                <!--                                                    <span class="highlight-container"><span-->
                                                <!--                                                            class="highlight">20% 할인</span></span>-->
                                                <!--                                                </div>-->
                                                <!--                                            </template>-->
                                            </v-radio>
                                            <!--                                        <v-radio-->
                                            <!--                                                label="2개월"-->
                                            <!--                                                value="2개월"-->
                                            <!--                                        >-->
                                            <!--                                            <template v-slot:label>-->
                                            <!--                                                <div>2개월-->
                                            <!--                                                    &nbsp;-->
                                            <!--                                                    <span class="highlight-container"><span-->
                                            <!--                                                            class="highlight">28% 할인</span></span>-->
                                            <!--                                                </div>-->
                                            <!--                                            </template>-->
                                            <!--                                        </v-radio>-->
                                            <!--                                        <v-radio-->
                                            <!--                                                label="3개월"-->
                                            <!--                                                value="3개월"-->
                                            <!--                                        >-->
                                            <!--                                            <template v-slot:label>-->
                                            <!--                                                <div>3개월-->
                                            <!--                                                    &nbsp;-->
                                            <!--                                                    <span class="highlight-container"><span-->
                                            <!--                                                            class="highlight">40% 할인</span></span>-->
                                            <!--                                                </div>-->
                                            <!--                                            </template>-->
                                            <!--                                        </v-radio>-->
                                            <!--                                    <v-radio-->
                                            <!--                                            label="12개월"-->
                                            <!--                                            value="12개월"-->
                                            <!--                                    >-->
                                            <!--                                        <template v-slot:label>-->
                                            <!--                                            <div>12개월-->
                                            <!--                                                &nbsp;-->
                                            <!--                                                <span class="highlight-container"><span class="highlight">40% 할인</span></span>-->
                                            <!--                                            </div>-->
                                            <!--                                        </template>-->
                                            <!--                                    </v-radio>-->
                                        </v-radio-group>
                                    </v-col>
                                </v-row>

                            </v-form>
                        </template>
                    </v-card>
                    <hr/>
                    <v-card class="center-horizontal mx-auto mt-2 enroll-page"
                            elevation="0"
                            width="100%">
                        <v-row class="pb-5">
                            <v-col class="center-vertical  mt-4" md="">
                                <div class="smaller-desc center-horizontal" v-if="virtualPriceToPay != ''">
                                    <!--                                        <span class="text-next-to-button-01">최대 40% 할인</span>-->
                                    <!--                                <span class="highlight-container"><span class="highlight"><strong>{{selectedDiscountRate}}</strong> 할인</span></span>-->
                                    <span class="font-weight-bold">
                                            {{virtualPriceToPay}}
                                        </span>
                                    <span class="text-next-to-button-03">{{enrollmentData.expectedNumberOfLessonsPerWeek}}(60분)•{{enrollmentData.expectedLessonDuration}}</span>
                                </div>
                                <!--                            <div class="smaller-desc center-horizontal" v-if="virtualPriceToPay == ''">-->
                                <!--                                <span class=" text-next-to-button-01">최대 40% 할인</span>-->

                                <!--                                <span class="text-next-to-button-02">-->
                                <!--                                            수업 정보를 <br/>선택하세요.-->
                                <!--                                        </span>-->
                                <!--                            </div>-->
                            </v-col>
                            <v-col class="center-vertical mt-4">
                                <v-btn
                                        class=" rounded-lg"
                                        :disabled="!valid"
                                        color="secondary"
                                        @click="updateEnrollmentDataToStore"
                                        height="70px"
                                        x-large


                                >
                                    <button class="">
                                        <h3 class="">수업 결제하기</h3>
                                    </button>

                                </v-btn>

                            </v-col>
                        </v-row>
                    </v-card>

                </v-container>

                <!-- IF REGISTED as a member BUT DOES NOT HAVE ACTIVE PAYMENT but has NOT filled out the enrollment form-->
                <v-container class=""
                             v-if="this.currentUser && studentContent.activePaymentId == null && studentContent.subjectsOfInterest == null">
                    <v-card
                            class="mx-auto primary--text"
                            width="100%"
                            flat
                            :class="{
                                // 'mt-5': $vuetify.breakpoint.lgAndUp,
                                // 'ml-5': $vuetify.breakpoint.lgAndUp,
                                // 'mb-5': $vuetify.breakpoint.lgAndUp,
                                }"
                    >
                        <v-card-title class="main-title" :class="{}">
                            수강 신청
                        </v-card-title>
                        <div class="ml-5 mt-5 mr-5 mb-5">
                                <span class="black--text">
                                    아래에서 <strong>{{currentUser.firstName}}</strong>님이 원하시는 <br/> 수업 사항을 선택 후, 결제를 진행해주세요.
                                </span>

                        </div>
                        <v-divider></v-divider>

                        <template>
                            <v-form class="ml-5 mr-5 mt-5 mb-5 black--text"
                                    v-model="valid"
                                    ref="form">
                            <span class="subtitle-text">
                                수업 과목 선택
                            </span>
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group
                                                v-model="enrollmentData.subjectsOfInterest"
                                                :rules="[rules.required, rules.listNotEmpty]"
                                                class="form-input"
                                        >
                                            <v-radio
                                                    label="Mathematics / Computer Science"
                                                    value="Mathematics / Computer Science"
                                            ></v-radio>
                                            <v-select
                                                    :items="mathSubSubjects"
                                                    v-model="enrollmentData.subSubjects"
                                                    placeholder="세부 과목을 선택해주세요."
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    v-if="enrollmentData.subjectsOfInterest &&
                                                enrollmentData.subjectsOfInterest.includes('Mathematics')">


                                            </v-select>
                                            <v-radio
                                                    label="Science"
                                                    value="Science">
                                            </v-radio>
                                            <v-select
                                                    :items="scienceSubSubjects"
                                                    v-model="enrollmentData.subSubjects"
                                                    v-if="enrollmentData.subjectsOfInterest=='Science'"
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    placeholder="세부 과목을 선택해주세요.">

                                            </v-select>
                                            <v-radio
                                                    label="Reading / Writing"
                                                    value="Reading / Writing"
                                            ></v-radio>
                                            <v-select
                                                    :items="englishSubSubjects"
                                                    v-model="enrollmentData.subSubjects"
                                                    placeholder="세부 과목을 선택해주세요."
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    v-if="enrollmentData.subjectsOfInterest &&
                                                enrollmentData.subjectsOfInterest.includes('Reading')">


                                            </v-select>

                                            <!--                                    <v-radio-->
                                            <!--                                            label="English"-->
                                            <!--                                            value="English"-->
                                            <!--                                    ></v-radio>-->
                                            <!--                                    <v-select-->
                                            <!--                                            :items="englishSubSubjects"-->
                                            <!--                                            v-model="enrollmentData.subSubjects"-->
                                            <!--                                            v-if="enrollmentData.subjectsOfInterest=='English'"-->
                                            <!--                                              placeholder="세부 과목을 선택해주세요.">-->


                                            <!--                                    </v-select>-->
                                        </v-radio-group>

                                    </v-col>
                                </v-row>
                                <v-divider class="mb-2"></v-divider>
                                <span class="subtitle-text">
                                수업 횟수
                            </span>
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group
                                                :rules="[rules.required, rules.listNotEmpty]"
                                                class="form-input"
                                                v-model="enrollmentData.expectedNumberOfLessonsPerWeek"
                                                column>
                                            <v-radio
                                                    label="주 1회"
                                                    value="주 1회"
                                            ></v-radio>
                                            <v-radio
                                                    label="주 2회"
                                                    value="주 2회"
                                            ></v-radio>
                                            <v-radio
                                                    label="주 3회"
                                                    value="주 3회"
                                            ></v-radio>
                                            <v-radio
                                                    label="주 4회"
                                                    value="주 4회"
                                            ></v-radio>
                                            <v-radio
                                                    label="주 5회"
                                                    value="주 5회"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-divider class="mb-2"></v-divider>
                                <span class="subtitle-text">
                                수업 기간 선택
                            </span>
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group
                                                class="form-input"
                                                :rules="[rules.required, rules.listNotEmpty]"
                                                v-model="enrollmentData.expectedLessonDuration"
                                                column
                                                mandatory

                                        >
                                            <v-radio
                                                    label="1개월"
                                                    value="1개월"
                                            >
                                                <!--                                            <template v-slot:label>-->
                                                <!--                                                <div>1개월-->
                                                <!--                                                    &nbsp;-->
                                                <!--                                                    <span class="highlight-container"><span-->
                                                <!--                                                            class="highlight">20% 할인</span></span>-->
                                                <!--                                                </div>-->
                                                <!--                                            </template>-->

                                            </v-radio>
                                            <!--                                        <v-radio-->
                                            <!--                                                label="2개월"-->
                                            <!--                                                value="2개월"-->
                                            <!--                                        >-->
                                            <!--                                            <template v-slot:label>-->
                                            <!--                                                <div>2개월-->
                                            <!--                                                    &nbsp;-->
                                            <!--                                                    <span class="highlight-container"><span-->
                                            <!--                                                            class="highlight">28% 할인</span></span>-->
                                            <!--                                                </div>-->
                                            <!--                                            </template>-->
                                            <!--                                        </v-radio>-->

                                            <!--                                        <v-radio-->
                                            <!--                                                label="3개월"-->
                                            <!--                                                value="3개월"-->
                                            <!--                                        >-->
                                            <!--                                            <template v-slot:label>-->
                                            <!--                                                <div>3개월-->
                                            <!--                                                    &nbsp;-->
                                            <!--                                                    <span class="highlight-container"><span-->
                                            <!--                                                            class="highlight">40% 할인</span></span>-->
                                            <!--                                                </div>-->
                                            <!--                                            </template>-->

                                            <!--                                        </v-radio>-->
                                            <!--                                    <v-radio-->
                                            <!--                                            label="12개월"-->
                                            <!--                                            value="12개월"-->
                                            <!--                                    >-->
                                            <!--                                        <template v-slot:label>-->
                                            <!--                                            <div>12개월-->
                                            <!--                                                &nbsp;-->
                                            <!--                                                <span class="highlight-container"><span class="highlight">40% 할인</span></span>-->
                                            <!--                                            </div>-->
                                            <!--                                        </template>-->

                                            <!--                                    </v-radio>-->
                                        </v-radio-group>
                                    </v-col>
                                </v-row>

                            </v-form>
                        </template>
                    </v-card>
                    <hr/>
                    <v-card class="center-horizontal mx-auto enroll-page mt-2"
                            elevation="0"
                            width="100%">
                        <v-row class="pb-5">

                            <v-col class="center-vertical  mt-4" md="">
                                <div class="smaller-desc center-horizontal" v-if="virtualPriceToPay != ''">
                                    <!--                                        <span class="text-next-to-button-01">최대 40% 할인</span>-->
                                    <!--                                <span class="highlight-container"><span class="highlight"><strong>{{selectedDiscountRate}}</strong> 할인</span></span>-->
                                    <span class="font-weight-bold">
                                            {{virtualPriceToPay}}
                                        </span>
                                    <span class="text-next-to-button-03">{{enrollmentData.expectedNumberOfLessonsPerWeek}}(60분)•{{enrollmentData.expectedLessonDuration}}</span>
                                </div>
                                <!--                            <div class="smaller-desc center-horizontal" v-if="virtualPriceToPay == ''">-->
                                <!--                                <span class=" text-next-to-button-01">최대 40% 할인</span>-->

                                <!--                                <span class="text-next-to-button-02">-->
                                <!--                                            수업 정보를 <br/>선택하세요.-->
                                <!--                                        </span>-->
                                <!--                            </div>-->
                            </v-col>
                            <v-col class="center-vertical mt-4">
                                <v-btn
                                        class=" rounded-lg"
                                        :disabled="!valid"
                                        color="secondary"
                                        @click="updateEnrollmentDataAndMoveToPayment(currentUser.id, enrollmentData)"
                                        height="70px"
                                        x-large


                                >
                                    <button class="">
                                        <h3 class="">수업 결제하기</h3>
                                    </button>

                                </v-btn>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>

                <!-- 재구매 결제 고객 -->

                <v-container v-if="this.currentUser
                            && studentContent.payments.length >= 1
                            && studentContent.activePaymentId != null
                            && studentContent.sumOfRemainingClasses == 0"
                             style="outline-style: solid; outline-width: 1px; border-radius: 10px; outline-color: #E6F4EF"
                >
                    <v-card
                            class="mx-auto primary--text"
                            width="100%"
                            flat
                            :class="{
                                            // 'mt-5': $vuetify.breakpoint.lgAndUp,
                                            // 'ml-5': $vuetify.breakpoint.lgAndUp,
                                            // 'mb-5': $vuetify.breakpoint.lgAndUp,
                                            }"
                    >
                        <v-card-title class="main-title" :class="{}">
                            수강 신청
                        </v-card-title>
                        <div class="ml-5 mr-5 mb-5">
                            <div class="black--text">
                                아래에서 <strong>{{currentUser.firstName}}</strong>님이 원하시는 <br/> 수업 사항을 선택 후, 결제를 진행해주세요.
                            </div>
                        </div>
                        <!--            <h2>{{currentUser}}</h2>-->
                        <!--            <v-divider></v-divider>-->
                        <!--    <h1> {{studentContent}} </h1>-->
                        <template>
                            <v-form class="ml-5 pt-10 mr-5 mb-5"
                                    ref="form"
                                    v-model="valid"
                            >
                                <!--                    <v-text-field-->
                                <!--                            v-model="formContents.subjects"-->
                                <!--                            :counter="100"-->
                                <!--                            :rules="nameRules"-->
                                <!--                            label="어떤 과목들 도움이 필요하신가요?"-->
                                <!--                            required-->
                                <!--                    ></v-text-field>-->
                                <v-divider></v-divider>
                                <div class="subtitle-text pt-10 pb-4">
                                    수업 과목 선택
                                </div>
                                <!--                        {{this.studentContent}}-->
                                <v-row>
                                    <v-col cols="12">
                                        <v-radio-group
                                                class="form-input"

                                                v-model="studentContent.subjectsOfInterest"
                                                :rules="[rules.required, rules.listNotEmpty]"

                                        >
                                            <v-radio
                                                    label="Mathematics / Computer Science"
                                                    value="Mathematics / Computer Science"
                                            >
                                                <template v-slot:label>
                                                    <div class="black--text" style="font-weight: 500; font-size: 18px">
                                                        Mathematics / Computer Science
                                                    </div>
                                                </template>
                                            </v-radio>
                                            <v-select
                                                    :items="mathSubSubjects"
                                                    v-model="studentContent.subSubjects"
                                                    placeholder="세부 과목을 선택해주세요."
                                                    style="font-weight: 500; font-size: 18px"

                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    v-if="studentContent.subjectsOfInterest &&
                                                            studentContent.subjectsOfInterest.includes('Mathematics')">


                                            </v-select>
                                            <div class="py-2"></div>

                                            <v-radio
                                                    label="Science"
                                                    value="Science"
                                            >
                                                <template v-slot:label>
                                                    <div class="black--text" style="font-weight: 500; font-size: 18px">
                                                        Science
                                                    </div>
                                                </template>
                                            </v-radio>
                                            <v-select
                                                    :items="scienceSubSubjects"
                                                    v-model="studentContent.subSubjects"
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    style="font-weight: 500; font-size: 18px"

                                                    v-if="studentContent.subjectsOfInterest=='Science'"
                                                    placeholder="세부 과목을 선택해주세요.">

                                            </v-select>
                                            <div class="py-2"></div>

                                            <v-radio
                                                    label="Reading / Writing"
                                                    value="Reading / Writing"
                                            >
                                                <template v-slot:label>
                                                    <div class="black--text" style="font-weight: 500; font-size: 18px">
                                                        Reading / Writing
                                                    </div>
                                                </template>
                                            </v-radio>
                                            <v-select
                                                    :items="englishSubSubjects"
                                                    v-model="studentContent.subSubjects"
                                                    placeholder="세부 과목을 선택해주세요."
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    style="font-weight: 500; font-size: 18px"

                                                    v-if="studentContent.subjectsOfInterest &&
                                                            studentContent.subjectsOfInterest.includes('Reading')">


                                            </v-select>
                                            <div class="pb-7"></div>

                                            <!--                                    <v-radio-->
                                            <!--                                            label="English"-->
                                            <!--                                            value="English"-->
                                            <!--                                    ></v-radio>-->
                                            <!--                                    <v-select v-if="studentContent.subjectsOfInterest=='English'"-->
                                            <!--                                              placeholder="세부 과목을 선택해주세요.">-->


                                            <!--                                    </v-select>-->
                                        </v-radio-group>
                                        <!--                                            {{enrollmentData.lessonCategory}}-->
                                        <!--                                            {{enrollmentData.lessonSubCateogry}}-->


                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>

                                <div class="subtitle-text pt-10 pb-4">
                                    수업 횟수
                                </div>
                                <v-row class="pb-10">
                                    <v-col cols="12">
                                        <v-radio-group
                                                class="form-input"

                                                v-model="studentContent.expectedNumberOfLessonsPerWeek"
                                                :rules="[rules.required, rules.listNotEmpty]"
                                                column

                                        >
                                            <v-radio
                                                    label="주 1회"
                                                    value="160"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 1회, 60분 수업
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            439,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="주 1회, 90분 수업"
                                                    value="190"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 1회, 90분 수업
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            659,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="주 2회, 60분 수업"
                                                    value="260"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 2회, 60분 수업
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            799,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="주 2회, 150분 수업"
                                                    value="2150"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 2회, 60분, 90분 수업
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            999,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="주 2회, 90분 수업"
                                                    value="290"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 2회, 90분 수업
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            1,139,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="주 3회, 60분 수업"
                                                    value="360"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 3회, 60분 수업
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            1,139,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="주 4회, 60분 수업"
                                                    value="460"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 4회, 60분 수업
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            1,519,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="주 3회, 90분 수업"
                                                    value="390"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 3회, 90분 수업
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            1,619,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="주 3회, 120분 수업"
                                                    value="3120"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 3회, 120분 수업
                                                        </v-col>
                                                        <v-col class="text-right">
                                                            2,269,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="주 5회, 90분 수업"
                                                    value="590"
                                            >
                                                <template v-slot:label>
                                                    <v-row class="black--text"
                                                           style="font-weight: 500; font-size: 18px">
                                                        <v-col>
                                                            주 5회, 90분 수업
                                                        </v-col>
                                                        <v-col c`lass="text-right">
                                                            2,758,000원
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                            </v-radio>



                                            <!--                                        <v-radio-->
                                            <!--                                                label="주 2회"-->
                                            <!--                                                value="주 2회"-->
                                            <!--                                        ></v-radio>-->
                                            <!--                                        <v-radio-->
                                            <!--                                                label="주 3회"-->
                                            <!--                                                value="주 3회"-->
                                            <!--                                        ></v-radio>-->
                                            <!--                                        <v-radio-->
                                            <!--                                                label="주 4회"-->
                                            <!--                                                value="주 4회"-->
                                            <!--                                        ></v-radio>-->
                                            <!--                                        <v-radio-->
                                            <!--                                                label="주 5회"-->
                                            <!--                                                value="주 5회"-->
                                            <!--                                        ></v-radio>-->
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>


                                <div class="subtitle-text pt-10 pb-4">
                                    수업 기간 선택
                                </div>
                                <v-row class="pb-5">
                                    <v-col cols="12">
                                        <v-radio-group
                                                v-model="studentContent.expectedLessonDuration"
                                                :rules="[rules.required, rules.listNotEmpty]"
                                                column
                                                class="form-input"

                                        >
                                            <v-radio
                                                    label="1개월"
                                                    value="1개월"
                                            >
                                                <!--                                            <template v-slot:label>-->
                                                <!--                                                <div>1개월-->
                                                <!--                                                    &nbsp;-->
                                                <!--                                                    <span class="highlight-container"><span-->
                                                <!--                                                            class="highlight">20% 할인</span></span>-->
                                                <!--                                                </div>-->
                                                <!--                                            </template>-->

                                            </v-radio>
                                            <!--                                        <v-radio-->
                                            <!--                                                label="2개월"-->
                                            <!--                                                value="2개월"-->
                                            <!--                                        >-->
                                            <!--                                            <template v-slot:label>-->
                                            <!--                                                <div>2개월-->
                                            <!--                                                    &nbsp;-->
                                            <!--                                                    <span class="highlight-container"><span-->
                                            <!--                                                            class="highlight">28% 할인</span></span>-->
                                            <!--                                                </div>-->
                                            <!--                                            </template>-->
                                            <!--                                        </v-radio>-->

                                            <!--                                        <v-radio-->
                                            <!--                                                label="3개월"-->
                                            <!--                                                value="3개월"-->
                                            <!--                                        >-->
                                            <!--                                            <template v-slot:label>-->
                                            <!--                                                <div>3개월-->
                                            <!--                                                    &nbsp;-->
                                            <!--                                                    <span class="highlight-container"><span-->
                                            <!--                                                            class="highlight">40% 할인</span></span>-->
                                            <!--                                                </div>-->
                                            <!--                                            </template>-->

                                            <!--                                        </v-radio>-->
                                            <!--                                    <v-radio-->
                                            <!--                                            label="12개월"-->
                                            <!--                                            value="12개월"-->
                                            <!--                                    >-->
                                            <!--                                        <template v-slot:label>-->
                                            <!--                                            <div>12개월-->
                                            <!--                                                &nbsp;-->
                                            <!--                                                <span class="highlight-container"><span class="highlight">40% 할인</span></span>-->
                                            <!--                                            </div>-->
                                            <!--                                        </template>-->

                                            <!--                                    </v-radio>-->
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </template>
                    </v-card>

                    <v-divider style="color: black !important;"></v-divider>

                    <v-card class="center-horizontal mx-auto enroll-page pt-7"
                            elevation="0"
                            width="100%">
                        <v-row class="pb-5">
                            <v-col class="center-vertical  mt-4" md="">
                                <div class="smaller-desc center-horizontal" v-if="virtualPriceToPayForEditing != ''">
                                    <!--                                        <span class="text-next-to-button-01">최대 40% 할인</span>-->
                                    <!--                                <span class="highlight-container"><span class="highlight"><strong>{{selectedDiscountRateForEditing}}</strong> 할인</span></span>-->
                                    <div class="font-weight-bold" style="font-size: 20px">
                                        {{virtualPriceToPayForEditing}}
                                    </div>
                                    <!--                                <div class="text-next-to-button-03">-->
                                    <!--                                    {{studentContent.expectedNumberOfLessonsPerWeek}}(60분)•{{studentContent.expectedLessonDuration}}-->
                                    <!--                                </div>-->
                                    <!--                                -->
                                    <div style="font-size: 17px">
                                        <div v-if="this.studentContent.expectedNumberOfLessonsPerWeek && !this.studentContent.expectedNumberOfLessonsPerWeek.includes('주')"
                                             class="text-next-to-button-03">{{"주 " +
                                            (this.studentContent.expectedNumberOfLessonsPerWeek).charAt(0) + "회, " +
                                            (this.studentContent.expectedNumberOfLessonsPerWeek).substring(1,4)+"분 수업"}}
                                            •
                                            {{studentContent.expectedLessonDuration}}
                                        </div>
                                        <div v-if="this.studentContent.expectedNumberOfLessonsPerWeek && this.studentContent.expectedNumberOfLessonsPerWeek.includes('주')"
                                             class="text-next-to-button-03">
                                            {{this.studentContent.expectedNumberOfLessonsPerWeek}} •
                                            {{studentContent.expectedLessonDuration}}
                                        </div>
                                    </div>

                                </div>
                                <!--                            <div class="smaller-desc center-horizontal" v-if="virtualPriceToPayForEditing == ''">-->
                                <!--                                <span class=" text-next-to-button-01">최대 28% 할인</span>-->

                                <!--                                <span class="text-next-to-button-02">-->
                                <!--                                            수업 정보를 <br/>선택하세요.-->
                                <!--                                        </span>-->
                                <!--                            </div>-->
                            </v-col>

                            <v-col class="center-vertical mt-4">
                                <v-btn
                                        class=" rounded-lg"
                                        :disabled="!valid"
                                        color="secondary"
                                        @click="updateEnrollmentDataAndMoveToPayment(currentUser.id, studentContent)"
                                        height="70px"
                                        x-large
                                >
                                    <button class="">
                                        <h3 class="">수업 결제하기</h3>
                                    </button>

                                </v-btn>

                            </v-col>
                        </v-row>
                    </v-card>

                </v-container>

                <!--            {{studentContent.sumOfRemainingClasses}}-->
                <!-- IF REGISTED as a member BUT DOES NOT HAVE ACTIVE PAYMENT but has filled out the enrollment form-->
                <div v-if="this.currentUser
        && studentContent.activePaymentId == null
        && studentContent.subjectsOfInterest != null"
                     style="outline-style: solid; outline-width: 1px; border-radius: 10px; outline-color: #E6F4EF"
                >
                    <v-card
                            class="mx-auto primary--text px-2"
                            width="100%"
                            flat
                            style="margin: 0px !important; letter-spacing: -1px !important;"
                            :class="{
                                // 'mt-5': $vuetify.breakpoint.lgAndUp,
                                // 'ml-5': $vuetify.breakpoint.lgAndUp,
                                // 'mb-5': $vuetify.breakpoint.lgAndUp,
                                }"
                    >
                        <v-card-title class="main-title " :class="{}">
                            수강 신청
                        </v-card-title>
                        <div class="ml-5 mr-5 mb-5">
                            <div class="black--text">
                                아래에서 <strong>{{currentUser.firstName}}</strong>님이 원하시는 <br/> 수업 사항을 선택 후, 결제를 진행해주세요.
                            </div>
                        </div>
                        <!--            <h2>{{currentUser}}</h2>-->
                        <!--            <v-divider></v-divider>-->
                        <!--    <h1> {{studentContent}} </h1>-->

                        <template>
                            <v-form class="ml-5 pt-10 mr-5 mb-5"
                                    ref="form"
                                    v-model="valid"
                            >
                                <v-divider></v-divider>
                                <div class="subtitle-text pt-10 pb-4">
                                    수업 과목 선택
                                </div>
                                <v-row class="">
                                    <v-col cols="12">
                                        <v-radio-group
                                                class="form-input"

                                                v-model="studentContent.subjectsOfInterest"
                                                :rules="[rules.required, rules.listNotEmpty]"

                                        >
                                            <v-radio
                                                    label=""
                                                    value="Winter Camp"
                                            >
                                                <template v-slot:label>
                                                    <div class="black--text" style="font-weight: 500; font-size: 18px">
                                                        2023년 겨울특강

                                                    </div>
                                                </template>
                                            </v-radio>
                                            <div class="py-2"></div>
                                            <v-radio
                                                    label="Mathematics / Computer Science"
                                                    value="Mathematics / Computer Science"
                                            >
                                                <template v-slot:label>
                                                    <div class="black--text" style="font-weight: 500; font-size: 18px">
                                                        Mathematics / Computer Science
                                                    </div>
                                                </template>
                                            </v-radio>
                                            <v-select
                                                    :items="mathSubSubjects"
                                                    v-model="studentContent.subSubjects"
                                                    placeholder="세부 과목을 선택해주세요."
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    style="font-weight: 500; font-size: 18px"
                                                    v-if="studentContent.subjectsOfInterest &&
                                                studentContent.subjectsOfInterest.includes('Mathematics')">


                                            </v-select>
                                            <div class="py-2"></div>

                                            <v-radio
                                                    label="Science"
                                                    value="Science"
                                            >
                                                <template v-slot:label>
                                                    <div class="black--text" style="font-weight: 500; font-size: 18px">
                                                        Science
                                                    </div>
                                                </template>
                                            </v-radio>
                                            <v-select
                                                    :items="scienceSubSubjects"
                                                    v-model="studentContent.subSubjects"
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    style="font-weight: 500; font-size: 18px"
                                                    v-if="studentContent.subjectsOfInterest=='Science'"
                                                    placeholder="세부 과목을 선택해주세요.">

                                            </v-select>
                                            <div class="py-2"></div>

                                            <v-radio
                                                    label="Reading / Writing"
                                                    value="Reading / Writing"
                                            >
                                                <template v-slot:label>
                                                    <div class="black--text" style="font-weight: 500; font-size: 18px">
                                                        Reading / Writing
                                                    </div>
                                                </template>
                                            </v-radio>
                                            <v-select
                                                    :items="englishSubSubjects"
                                                    v-model="studentContent.subSubjects"
                                                    placeholder="세부 과목을 선택해주세요."
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    style="font-weight: 500; font-size: 18px"
                                                    v-if="studentContent.subjectsOfInterest &&
                                                studentContent.subjectsOfInterest.includes('Reading')">
                                            </v-select>
                                            <div class="pb-7"></div>

                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                                <div v-if="studentContent.subjectsOfInterest != 'Winter Camp'">
                                    <!--                            <div class="py-2 semi-background" style="margin: -20px !important;"></div>-->
                                    <v-divider></v-divider>
                                    <!--                            {{studentContent.subjectsOfInterest}}-->

                                    <div class="subtitle-text pt-10 pb-4">
                                        수업 횟수
                                    </div>
                                    <v-switch
                                            v-model="expertTeacher"
                                            :label="`전문대학원 이상 선생님 (Law School / Medical School / Master's / PhD)`"
                                    ></v-switch>
                                    <v-row class="pb-10" v-if="expertTeacher">
                                        <v-col cols="12">
                                            <v-radio-group
                                                    class="form-input"
                                                    v-model="studentContent.expectedNumberOfLessonsPerWeek"
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    column
                                            >
                                                <v-radio
                                                        label="주 1회, 60분 수업"
                                                        value="1602"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 1회, 60분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                800,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 1회, 90분 수업"
                                                        value="1902"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 1회, 90분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                1,200,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 2회, 60분 수업"
                                                        value="2602"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 2회, 60분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                1,550,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 2회, 90분 수업"
                                                        value="2902"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 2회, 90분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                2,320,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 3회, 60분 수업"
                                                        value="3602"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 3회, 60분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                2,320,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 3회, 60분 수업"
                                                        value="3902"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 3회, 90분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                3,480,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 3회, 60분 수업"
                                                        value="4602"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 4회, 60분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                3,100,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>

                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pb-10" v-if="!expertTeacher">
                                        <v-col cols="12">
                                            <v-radio-group
                                                    class="form-input"
                                                    v-model="studentContent.expectedNumberOfLessonsPerWeek"
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    column
                                            >
                                                <v-radio
                                                        label="주 1회, 60분 수업"
                                                        value="160"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 1회, 60분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                439,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 1회, 90분 수업"
                                                        value="190"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 1회, 90분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                659,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 2회, 60분 수업"
                                                        value="260"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 2회, 60분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                799,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 2회, 150분 수업"
                                                        value="2150"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 2회, 60분, 90분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                999,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 2회, 90분 수업"
                                                        value="290"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 2회, 90분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                1,139,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 3회, 60분 수업"
                                                        value="360"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 3회, 60분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                1,139,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>

                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 3회, 90분 수업"
                                                        value="390"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 3회, 90분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                1,659,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 3회, 120분 수업"
                                                        value="3120"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 3회, 120분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                2,269,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-radio>
                                                <div class="py-2"></div>
                                                <v-radio
                                                        label="주 5회, 90분 수업"
                                                        value="590"
                                                >
                                                    <template v-slot:label>
                                                        <v-row class="black--text"
                                                               style="font-weight: 500; font-size: 18px">
                                                            <v-col>
                                                                주 5회, 90분 수업
                                                            </v-col>
                                                            <v-col class="text-right">
                                                                2,758,000원
                                                            </v-col>
                                                        </v-row>
                                                    </template>
                                                </v-radio>


                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>

                                    <div class="subtitle-text pt-10 pb-4">
                                        수업 기간 선택
                                    </div>
                                    <v-row class="pb-5">
                                        <v-col cols="12">
                                            <v-radio-group
                                                    v-model="studentContent.expectedLessonDuration"
                                                    :rules="[rules.required, rules.listNotEmpty]"
                                                    column
                                                    class="form-input"
                                            >
                                                <v-radio
                                                        label="1개월"
                                                        value="1개월"
                                                >
                                                    <template v-slot:label>
                                                        <div class="black--text"
                                                             style="font-weight: 500; font-size: 18px">
                                                            1개월
                                                        </div>
                                                    </template>
                                                </v-radio>

                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-form>
                        </template>
                    </v-card>
                    <!--            <v-card class="center-horizontal mx-auto mt-2 enroll-page"-->
                    <!--                <div class="py-2 semi-background" style="margin: -20px !important;"></div>-->
                    <!--                <div class="py-5 semi-background" style="margin: -20px !important;"></div>-->
                    <v-divider style="color: black !important;"></v-divider>
                    <v-card class="center-horizontal mx-auto enroll-page pt-7"
                            elevation="0"
                            width="100%">
                        <v-row class="pb-5">
                            <v-col class="center-vertical  mt-4" md="">
                                <div v-if="studentContent.subjectsOfInterest == 'Winter Camp'">
<!--                                    <div class="pt-2 text-center font-weight-medium"-->
<!--                                         style="font-family: RixYeoljeongdo_Regular">-->
<!--                                        <div style="color: black; -webkit-text-stroke: 0.5px gray;">-->
<!--                                            AP CSA-->
<!--                                        </div>-->
<!--&lt;!&ndash;                                        <div class="waviy">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <span style="&#45;&#45;i:1">부</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <span style="&#45;&#45;i:2">트</span>&ndash;&gt;-->

<!--&lt;!&ndash;                                            <span style="&#45;&#45;i:3">캠</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <span style="&#45;&#45;i:4">프</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                            &lt;!&ndash;                    <span style="&#45;&#45;i:5">C</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                            &lt;!&ndash;                    <span style="&#45;&#45;i:6">A</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                            &lt;!&ndash;                    <span style="&#45;&#45;i:7">M</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                            &lt;!&ndash;                    <span style="&#45;&#45;i:8">P</span>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                            &lt;!&ndash;                    <span style="&#45;&#45;i:9">프</span>&ndash;&gt;&ndash;&gt;-->

<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--                                    </div>-->

                                </div>
                                <div class="smaller-desc center-horizontal" v-if="virtualPriceToPayForEditing != ''">
                                    <!--                                        <span class="text-next-to-button-01">최대 40% 할인</span>-->
                                    <!--                                <span class="highlight-container"><span class="highlight"><strong>{{selectedDiscountRateForEditing}}</strong> 할인</span></span>-->
                                    <div class="font-weight-bold" style="font-size: 20px">
                                        {{virtualPriceToPayForEditing}}
                                    </div>

                                    <div style="font-size: 17px">
                                        <div v-if="this.studentContent.expectedNumberOfLessonsPerWeek && !this.studentContent.expectedNumberOfLessonsPerWeek.includes('주')"
                                             class="text-next-to-button-03">{{"주 " +
                                            (this.studentContent.expectedNumberOfLessonsPerWeek).charAt(0) + "회, " +
                                            (this.studentContent.expectedNumberOfLessonsPerWeek).substring(1,4)+"분 수업"}}
                                            •
                                            {{studentContent.expectedLessonDuration}}
                                        </div>
                                        <div v-if="this.studentContent.expectedNumberOfLessonsPerWeek && this.studentContent.expectedNumberOfLessonsPerWeek.includes('주')"
                                             class="text-next-to-button-03">
                                            {{this.studentContent.expectedNumberOfLessonsPerWeek}} •
                                            {{studentContent.expectedLessonDuration}}
                                        </div>
                                    </div>
                                </div>
                                <!--                            <div class="smaller-desc center-horizontal" v-if="virtualPriceToPayForEditing == ''">-->
                                <!--                                <span class=" text-next-to-button-01">최대 28% 할인</span>-->

                                <!--                                <span class="text-next-to-button-02">-->
                                <!--                                            수업 정보를 <br/>선택하세요.-->
                                <!--                                        </span>-->
                                <!--                            </div>-->
                            </v-col>

                            <v-col class="center-vertical mt-4">
                                <v-btn
                                        class=" rounded-lg"
                                        :disabled="!valid"
                                        color="secondary"
                                        @click="updateEnrollmentDataAndMoveToPayment(currentUser.id, studentContent)"
                                        height="70px"
                                        x-large
                                >
                                    <button class="">
                                        <h3 class="">수업 결제하기</h3>
                                    </button>

                                </v-btn>

                            </v-col>
                        </v-row>
                    </v-card>

                </div>
            </div>
            <div v-show="loading" class="center">
                <v-progress-circular
                        :size="60"
                        :width="7"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </div>

        </v-container>
    </div>
</template>

<script>
    import StudentService from "../../../services/student.service"
    import info from "../../../../info";
    import RestrictedAcess from "../../helpers/RestrictedAcess";

    export default {
        name: "Enrollment",
        components: {RestrictedAcess},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            virtualPriceToPayForEditing() {
                if (this.studentContent.subSubjects && this.studentContent.subSubjects.length > 0 &&
                    this.studentContent.expectedNumberOfLessonsPerWeek != ''
                    && this.studentContent.expectedLessonDuration != '') {

                    // console.log("this.studentContent.expectedLessonDuration");
                    // console.log(this.studentContent.expectedLessonDuration);
                    // console.log("this.studentContent.expectedLessonDuration");
                    let numberOfMonths = 0
                    if (this.studentContent.expectedLessonDuration) {
                        numberOfMonths = this.studentContent.expectedLessonDuration.match(/\d/g).join("");
                    }
                    let numberPerWeek = 0
                    if (this.studentContent.expectedNumberOfLessonsPerWeek) {
                        numberPerWeek = this.studentContent.expectedNumberOfLessonsPerWeek.match(/\d/g).join("");
                        console.log(numberPerWeek)
                        console.log("hi")

                    }
                    // console.log(numberOfMonths)
                    let totalNumberOfLessons = numberOfMonths * numberPerWeek * 4;
                    let subSubject = this.studentContent.subSubjects;
                    let mainSubject = this.studentContent.subjectsOfInterest;

                    console.log(mainSubject);

                    // console.log(numberPerWeek);
                    // console.log(totalNumberOfLessons);
                    // console.log(subSubject);


                    if (subSubject == 'AP CS Principles' || subSubject == 'AP CS A' || subSubject == 'CS & Programming'
                        || subSubject == 'Multi-variate Calculus'
                        || subSubject == 'AP Physics C Mech' || subSubject == 'AP Physics C E&M') {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 100000
                    } else if (subSubject == 'Test') {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 1000
                    } else if (subSubject == 'AP Calc AB' || subSubject == 'AP Calc BC' || subSubject == 'AP Statistics'
                        || subSubject == 'AP Physics 1' || subSubject == 'AP Physics 2'
                        || subSubject == 'AP Chemistry' || subSubject == 'AP Environmental Science'
                        || subSubject == 'AP Biology') {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 100000;
                    } else if (subSubject == 'Calculus' || subSubject == 'Statistics' || subSubject == 'AP Statistics') {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 100000;
                    } else {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 100000;
                    }
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.totalPrice = totalNumberOfLessons * this.virtualPrice;
                    let perMonthPrice = (this.totalPrice / numberOfMonths).toLocaleString();

                    if (numberPerWeek == 1602) {
                        perMonthPrice = 800000
                    }
                    if (numberPerWeek == 1902) {
                        perMonthPrice = 1200000
                    }
                    if (numberPerWeek == 2602) {
                        perMonthPrice = 1550000
                    }
                    if (numberPerWeek == 2902) {
                        perMonthPrice = 2320000
                    }
                    if (numberPerWeek == 3602) {
                        perMonthPrice = 2320000
                    }
                    if (numberPerWeek == 3902) {
                        perMonthPrice = 3480000
                    }
                    if (numberPerWeek == 4602) {
                        perMonthPrice = 3100000
                    } else if (numberPerWeek == 460) {
                        perMonthPrice = 1519000
                    }


                    if (numberPerWeek == 160) {
                        perMonthPrice = 439000
                    } else if (numberPerWeek == 190) {
                        perMonthPrice = 659000
                    } else if (numberPerWeek == 260) {
                        perMonthPrice = 799000
                    } else if (numberPerWeek == 290) {
                        perMonthPrice = 1139000
                    } else if (numberPerWeek == 360) {
                        perMonthPrice = 1139000
                    } else if (numberPerWeek == 390) {
                        perMonthPrice = 1659000
                    }  else if (numberPerWeek == 3120) {
                        perMonthPrice = 2269000
                    } else if (this.studentContent.subjectsOfInterest == 'Winter Camp') {
                        let perMonthPrice = 480000
                        return perMonthPrice.toLocaleString() + "원/월"
                    } else if (numberPerWeek == 2150) {
                        perMonthPrice = 999000
                    } else if (numberPerWeek == 590) {
                        perMonthPrice = 2758000
                    }

                    return perMonthPrice.toLocaleString() + "원/월";
                }


                return "";

            },

            selectedDiscountRate() {
                if (this.enrollmentData.expectedLessonDuration.includes('1개월')) {
                    return '20%';
                } else if (this.enrollmentData.expectedLessonDuration.includes('3')) {
                    return '40%'
                } else if (this.enrollmentData.expectedLessonDuration.includes('12')) {
                    return '40%'
                } else if (this.enrollmentData.expectedLessonDuration.includes('2개월')) {
                    return '28%'
                }
                return '';
            },

            selectedDiscountRateForEditing() {
                if (this.studentContent.expectedLessonDuration.includes('1개월')) {
                    return '20%';
                } else if (this.studentContent.expectedLessonDuration.includes('3')) {
                    return '40%'
                } else if (this.studentContent.expectedLessonDuration.includes('12')) {
                    return '40%'
                } else if (this.studentContent.expectedLessonDuration.includes('2개월')) {
                    return '28%'
                }
                return '';
            },

            virtualPriceToPay() {

                if (this.enrollmentData.subSubjects.length > 0 &&
                    this.enrollmentData.expectedNumberOfLessonsPerWeek != ''
                    && this.enrollmentData.expectedLessonDuration != '') {

                    let numberOfMonths = this.enrollmentData.expectedLessonDuration.match(/\d/g).join("");
                    let numberPerWeek = this.enrollmentData.expectedNumberOfLessonsPerWeek.match(/\d/g).join("");
                    let totalNumberOfLessons = numberOfMonths * numberPerWeek * 4;
                    let subSubject = this.enrollmentData.subSubjects;


                    if (subSubject == 'AP CS Principles' || subSubject == 'AP CS A' || subSubject == 'CS & Programming'
                        || subSubject == 'Multi-variate Calculus'
                        || subSubject == 'AP Physics C Mech' || subSubject == 'AP Physics C E&M') {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 100000
                    } else if (subSubject == 'Test') {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 1000
                    } else if (subSubject == 'AP Calc AB' || subSubject == 'AP Calc BC' || subSubject == 'AP Statistics'
                        || subSubject == 'AP Physics 1' || subSubject == 'AP Physics 2'
                        || subSubject == 'AP Chemistry' || subSubject == 'AP Environmental Science'
                        || subSubject == 'AP Biology') {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 100000;
                    } else if (subSubject == 'Calculus' || subSubject == 'Statistics' || subSubject == 'AP Statistics') {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 100000;
                    } else {
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.virtualPrice = 100000;
                    }

                    // if (numberOfMonths == 1) {
                    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    //     this.virtualPrice = 200000
                    // } else if (numberOfMonths == 3) {
                    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    //     this.virtualPrice = 150000
                    // } else if (numberOfMonths == 12) {
                    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    //     this.virtualPrice = 150000
                    // } else if (numberOfMonths == 2) {
                    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    //     this.virtualPrice = 180000
                    // }
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.totalPrice = totalNumberOfLessons * this.virtualPrice;
                    let perMonthPrice = (this.totalPrice / numberOfMonths).toLocaleString();



                    return perMonthPrice + "원/월";
                }
                return "";
            },
            isTeacher() {
                // if teacher enters this page -> lead to profile page
                // if student has remaining lessons -> profile
                return this.currentUser.roles[0] == 'ROLE_TEACHER';
            },
        },
        created() {
            if (this.isTeacher) {
                this.$router.push("/profile")
            }
        },
        mounted() {


            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    this.studentContent.subjectsOfInterest = this.removeEmptyStringFromList(this.studentContent.subjectsOfInterest)[0];
                    this.studentContent.availableDays = this.removeEmptyStringFromList(this.studentContent.availableDays);
                    // console.log("what is this?")
                    // console.log(typeof this.studentContent.availableDays)
                    // console.log("what is this?")
                    this.studentContent.availableTimes = this.removeEmptyStringFromList(this.studentContent.availableTimes);
                    this.studentContent.goalOfLesson = this.removeEmptyStringFromList(this.studentContent.goalOfLesson);
                    this.studentContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.studentContent.preferredLessonMaterial);
                    this.studentContent.preferredLessonStyle = this.removeEmptyStringFromList(this.studentContent.preferredLessonStyle);
                    this.studentContent.expectedNumberOfLessonsPerWeek = this.studentContent.expectedNumberOfLessonsPerWeek + "";
                    // console.log("here")
                    // console.log(this.studentContent.expectedNumberOfLessonsPerWeek)
                    // console.log("here")
                    // this.studentContent.expectedNumberOfLessonsPerWeek = "주 " + (this.studentContent.expectedNumberOfLessonsPerWeek).charAt(0) + "회, " + (this.studentContent.expectedNumberOfLessonsPerWeek).substring(1,3)+"분 수업";
                    // console.log("here2")
                    // console.log(this.studentContent.expectedNumberOfLessonsPerWeek)
                    // console.log("here2")
                    this.studentContent.expectedLessonDuration = this.studentContent.expectedLessonDuration + "개월";
                    this.studentContent.tablet = this.removeEmptyStringFromList(this.studentContent.tablet);
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        methods: {
            updateEnrollmentDataToStore() {
                this.$store.state.enrollmentData = this.enrollmentData;
                this.$router.push("/payment");
            },

            updateEnrollmentDataAndMoveToPayment(userId, requestBody) {

                // console.log(userId)
                this.loading = true;
                console.log(requestBody)
                if (requestBody.subjectsOfInterest == 'Winter Camp') {
                    // console.log("Hello")
                    requestBody.expectedNumberOfLessonsPerWeek = "2";
                    requestBody.expectedLessonDuration = "1";
                    requestBody.subSubjects = "Academic Writing I";
                }
                this.updateStudent(userId, requestBody);
                this.$store.state.enrollmentData = this.enrollmentData;
            },

            isEnrollmentDataEmpty() {
                if (Object.keys(this.$store.state.enrollmentData).length > 0) {
                    return false;
                }
                return true;
            },

            removeEmptyStringFromList(input) {
                // console.log("HERE-HERE")
                // console.log(input)
                // console.log(input[0] == "")
                // console.log(input[0])
                // console.log("HERE-HERE")
                if (typeof input[0] != "undefined" && input[0] != "") {
                    // console.log("HERE-HERE222")
                    return input.filter(element => {
                        return element !== '';
                    });
                }

            },

            removeStringFromString(input) {
                return input.replace(/\D/g, '');
            },

            updateStudent(userId, request) {
                let requestBody = {
                    subjectsOfInterest: [],
                    subSubjects: [],
                    expectedNumberOfLessonsPerWeek: "",
                    expectedLessonDuration: "",
                    tablet: "",
                };
                requestBody.expectedNumberOfLessonsPerWeek = this.removeStringFromString(request.expectedNumberOfLessonsPerWeek);
                requestBody.expectedLessonDuration = this.removeStringFromString(request.expectedLessonDuration);
                requestBody.subjectsOfInterest.push(request.subjectsOfInterest)
                requestBody.subSubjects = request.subSubjects;

                StudentService.updateStudent(userId, requestBody).then(
                    (response) => {
                        this.studentContent = response.data;
                        this.loading = false;
                        this.$router.push("/payment");
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log("error")
                        console.log(error.response)
                        console.log("error")

                    }
                )

            },


            moveToProfile() {
                // this.$store.dispatch('auth/logout');
                this.$router.push('/');
            }
        },
        data: () => (
            {
                loading: false,
                selectedDiscount: '',
                expertTeacher: false,
                rules: {
                    required: (value) => !!value || '',
                    listNotEmpty: (value) => value != null && value.length > 0 || '',
                    stringNotEmpty: (value) => value === '' || ''
                },
                virtualPrice: 0,
                totalPrice: 0,
                formContents: {
                    subjects: [],
                    country: "",
                    frequency: "",
                    duration: "",
                    payableItem: [''],
                    agree: false,
                    tablet: "",

                },
                oneMonth: false,
                twoMonths: false,
                twelveMonths: true,

                enrollmentData: {
                    subjectsOfInterest: [],
                    subSubjects: "Robotics",
                    tablet: "",
                    expectedNumberOfLessonsPerWeek: [],
                    expectedLessonDuration: "",
                },

                temp: false,
                tempVar: "",
                currentLocation: "",
                currentLocationDifferent: false,
                items: info.teacherRegister.subjectsOfExpertise,
                mainSubjects: info.mainSubjects,
                mathSubSubjects: info.mathSubSubjects,
                scienceSubSubjects: info.scienceSubSubjects,
                englishSubSubjects: info.englishSubSubjects,
                studentContent: {
                    subjectsOfInterest: [],
                    subSubjects: [],
                    expectedNumberOfLessonsPerWeek: "",
                    expectedNumberOfLessonDuration: "",
                    tablet: "",
                },
                valid: false,
                name: '',
                nameRules: [
                    v => !!v || '필수 입력',
                    v => (v && v.length <= 100) || 'must be less than 100 characters',
                ],
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                select: null,
                frequencyItems: [
                    '1회',
                    '2회',
                    '3회',
                    '5회',
                ],
                checkbox: false,
                checkbox1: true,
                checkbox2: false,
            }
        )
    }
</script>

<style scoped>
    .button {
        background: none repeat scroll 0 0 #FF0000;
        border: medium none;
        color: #FFFFFF;
        height: 100px;
        /*left: 74%;*/
        position: fixed;
        /*top: 100px;*/
        width: 100px;
    }

    .smaller-desc {
        font-size: 1.0em;
    }

    .small-desc-background {
        background-color: #00BCD4;
    }

    button hr {
        margin: 2px;
    }

    .main-title {
        padding-top: 30px;
        font-weight: bold;
        font-size: 30px;
        letter-spacing: -1px;
    }

    .enroll-page {
        /*background-color: #F0F0F0;*/
        max-width: 900px;
        height: 100%;
    }

    .subtitle-text {
        color: black;
        font-weight: bold;
        font-size: 24px;
    }

    .button {
        position: fixed;
        bottom: 10px;
        /*left: 50%;*/
        /*margin-left: -104.5px; !*104.5px is half of the button's width*!*/
        /*background-color: #DE2828;*/
        background-image: linear-gradient(92.88deg, #DE2828 9.16%, #cd6060 64.72%);
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        /*background-image: linear-gradient(92.88deg, #DE2828 9.16%, #c23e3e 43.89%, #cd6060 64.72%);*/
        border-radius: 15px;
        /*border-radius: 8px;*/
        /*border-style: none;*/
        /*box-sizing: border-box;*/
        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        /*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*/
        /*font-family: 'SuncheonB';*/
        /*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*/
        font-size: 15px;
        height: 75px;
        width: 400px;
        font-weight: 500;
    }

    .justify-left {
        text-align: left;
    }

    .centered-element {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .discount-text {
        font-size: 16px;
        color: red;
        font-weight: bold;
    }

    /*.container {*/
    /*    line-height: 1.4;*/
    /*    text-align: center;*/
    /*    padding: 44px;*/
    /*    color: #333;*/
    /*}*/

    /*h1 {*/
    /*    font-size: 50px;*/
    /*}*/

    /*p {*/
    /*    font-size: 18px;*/
    /*}*/
    /*p small {*/
    /*    font-size: 80%;*/
    /*    color: #666;*/
    /*}*/

    .highlight-container, .highlight {
        position: relative;
        /*font-weight: bold;*/
        color: red;
    }

    .highlight-container {
        display: inline-block;
    }

    .highlight-container:before {
        content: " ";
        display: block;
        height: 90%;
        width: 100%;
        margin-left: -3px;
        margin-right: -3px;
        position: absolute;

        background: #FEDFDD;
        transform: rotate(2deg);
        top: -1px;
        left: -1px;
        border-radius: 20% 25% 20% 24%;
        padding: 10px 3px 3px 10px;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .text-next-to-button-01 {
        font-weight: bold;
        color: gray;
        font-size: 18px;
    }

    .text-next-to-button-02 {
        color: gray;
        font-size: 16px;
    }

    .text-next-to-button-03 {
        color: black;
        font-size: 14px;
    }

    .anchor {
        position: fixed;
        bottom: 0;
        /*padding: 0 !important;*/
        /*margin: 0 !important;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        /*width: 800px !important;*/
    }

    .semi-background {
        background-color: #F0F0F0;
        /*max-width: 1000px;*/
        /*justify-content: center;*/
    }

    .form-input >>> .v-input__slot::after {
        border-color: black !important;
    }

    .form-input >>> .v-input__slot::before {
        border-color: black !important;
    }

    .form-input >>> .error--text {
        color: #626262 !important;
    }

    .form-input >>> input {
        caret-color: black !important;
    }

    .center {
        padding: 300px 0;
        /*border: 3px solid green;*/
        text-align: center;
    }

    .highlight {
        display: inline;
        padding: .25em 0;
        background: #F2F2F2;
        color: #A20916;
        box-shadow: .5em 0 0 #F2F2F2, -.5em 0 0 #F2F2F2;
        font-weight: bold;
    }

    .main-highlight {
        display: inline;
        padding: .25em 0;
        background: #A20916;
        color: #ffffff;
        box-shadow: .5em 0 0 #A20916, -.5em 0 0 #A20916;
        font-weight: bold;
        font-size: 17px;
        border-radius: 5px;
        letter-spacing: -1px !important;
    }

    .lessonType {
        font-size: 17px;
    }

    .lessonPrice {
        font-weight: bold;
        font-size: 17px;
    }

    .waviy {

        /*background: #FFFF00;*/
        /*background: linear-gradient(to right, #FFFF00 0%, #FFFFFF 100%);*/
        /*-webkit-background-clip: text;*/
        /*-webkit-text-fill-color: transparent;*/

        -webkit-text-stroke: 0.5px black;
        position: relative;
        -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, .2));
        font-size: 17px;

    }

    .waviy span {

        /*font-family: 'Alfa Slab One', cursive;*/
        font-family: LINESeedKR-Bd;
        font-weight: bold;

        letter-spacing: 0px;
        position: relative;
        display: inline-block;
        color: black;
        text-transform: uppercase;
        animation: waviy 1.5s infinite;
        animation-delay: calc(.1s * var(--i));

    }

    @keyframes waviy {
        0%, 40%, 100% {
            transform: translateY(0)
        }
        20% {
            transform: translateY(-3px)
        }
    }

</style>
