<template>
    <div class="center-horizontal">
        <v-card max-width="500px" flat class="">
            <div class="pa-8" style="background-color: #2F2F2F; color: white; border-radius: 0px">

                <div class="py-4"></div>
                <div class="" style="letter-spacing: 0px; font-size: 20px; font-weight: bold">
                    신청서 작성
                </div>
                <div class="py-1"></div>
                <div style="font-size: 14px">
                    IVYED Bootcamp로 AP 5점 및 내신관리 모두 이루세요.
                    <br/>
                </div>
                <div class="py-5"></div>


            </div>

            <v-divider class="mx-5"></v-divider>
            <div class="py-6">

            </div>
            <template style="background-color: #f9f9f9">
                <!--            <div class="font-weight-bold pt-8 px-8 pb-3">-->
                <!--                개인정보 확인-->
                <!--            </div>-->
                <v-form v-model="valid" class="mx-5">
                    <v-container>
                        <v-row>

                            <v-col
                                   style="background-color: rgba(0,0,0,0.03);border-radius: 5px;margin: 50px 0px">
                                <v-col cols="12" @click="toggleMoney"
                                       md="12" style="font-size: 18px; color: #757575; font-weight: bold;width: 100%;display: flex;justify-content: space-between;cursor: pointer;align-items: center">
                                    <span>
                                    💵 수업료
                                        </span>
                                    <span v-if="!hide" style="font-size: 12px">▼</span>
                                    <span v-if="hide" style="font-size: 12px">▲</span>
                                </v-col>
                                <v-col v-if="hide">
                                    <v-col cols="12"
                                           md="12" style="font-size: 18px; color: #757575; font-weight: bold;">
                                        1과목 수강시
                                    </v-col>
                                    <v-col cols="12"
                                           md="12" style="font-size: 18px; color: #757575; font-weight: bold;">
                                        주 2회 90분 480,000 / 월
                                    </v-col>
                                    <v-col cols="12"
                                           md="12" style="font-size: 18px; color: #757575; font-weight: bold;">
                                        주 3회 90분 720,000 / 월
                                    </v-col>
                                    <v-col cols="12"
                                           md="12" style="font-size: 18px; color: #757575; font-weight: bold;">
                                        주 2회 120분 640,000 / 월
                                    </v-col>
                                    <v-col cols="12"
                                           md="12" style="font-size: 18px; color: #757575; font-weight: bold;margin-top: 35px">
                                        2과목 이상 수강시
                                    </v-col>
                                    <v-col cols="12"
                                           md="12" style="font-size: 18px; color: #757575; font-weight: bold;">
                                        주 2회 90분 360,000 / 월
                                    </v-col>
                                    <v-col cols="12"
                                           md="12" style="font-size: 18px; color: #757575; font-weight: bold;">
                                        주 3회 90분 540,000 / 월
                                    </v-col>
                                    <v-col cols="12"
                                           md="12" style="font-size: 18px; color: #757575; font-weight: bold;">
                                        주 2회 120분 480,000 / 월
                                    </v-col>
                                </v-col>
                            </v-col>
                            <v-col
                                    cols="12"
                                    md="12"
                            >
                                <div style="font-size: 14px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    이름
                                </div>
                                <v-text-field
                                        dense
                                        outlined
                                        label="이름"
                                        v-model="parentName"
                                        :rules="[rules.required]"
                                        hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12"
                                    md="12"
                            >
                                <div style="font-size: 14px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    휴대전화번호
                                </div>
                                <v-row no-gutters justify="center" align="center" class=" text-h4">
                                    <v-col
                                            cols="12"
                                            md="4"
                                            sm="4"
                                    >
                                        <v-autocomplete
                                                outlined
                                                label="국가코드"
                                                required
                                                :rules="[rules.required]"

                                                :items="countryCodes"
                                                v-model="parentCountryCode"
                                                class="font-weight-thin"
                                                dense
                                                :messages="['국가 코드']"
                                                auto-select-first

                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            md="8"
                                            sm="8"
                                    >
                                        <v-text-field
                                                outlined
                                                label="전화번호 / Phone Number"
                                                required
                                                :rules="[rules.required]"
                                                v-model="parentPhone"
                                                dense
                                                :messages="['(\'-\' 없이 번호만 입력)']"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col
                                            cols="12"
                                    >
                                        <div style="font-size: 14px; color: #757575; font-weight: bold"
                                             class="">
                                            이메일
                                        </div>
                                        <v-text-field
                                                outlined
                                                label="이메일"
                                                required
                                                :rules="[rules.required]"
                                                v-model="email"
                                                dense
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col
                                    cols="12"
                                    md="12"
                                    style="margin-top: -20px"
                            >
                                <div style="font-size: 14px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    학년
                                </div>
                                <v-select
                                        dense
                                        outlined
                                        required
                                        label="2023년도 기준 학년"
                                        :items="grades"
                                        :rules="[rules.required]"
                                        v-model="studentGrade"
                                ></v-select>
                            </v-col>
                            <v-col
                                    cols="12"
                                    md="12"
                                    style="margin-top: -20px"
                            >
                                <div style="font-size: 14px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    신청 프로그램
                                </div>
                                <v-select
                                        dense
                                        outlined
                                        required
                                        label="AP CSP & Python 부트캠프"
                                        :items="programs"
                                        :rules="[rules.required]"
                                        v-model="selectedProgram"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <div style="font-size: 14px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    Bootcamp 신청 이유
                                </div>
                                <v-textarea
                                        outlined
                                        :rules="[rules.required]"
                                        v-model="applyReason"
                                >

                                </v-textarea>
                            </v-col>
                            <v-col
                                    cols="12"
                                    md="12"
                                    style="margin-top: -20px"
                            >
                                <div style="font-size: 14px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    어떻게 IVYED를 알게 되셨나요?
                                </div>
                                <v-select
                                        dense
                                        outlined
                                        required
                                        placeholder="구글 광고"
                                        :items="channels"
                                        :rules="[rules.required]"
                                        v-model="channel"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <div class="my-7"></div>
                        <div style="font-size: 13px">
                            * 한 반 7명 제한으로 위 신청서를 토대로 운영팀에서 개별 연락 드리고 있습니다.
                        </div>
                        <div class="my-7"></div>

                        <v-btn :disabled="!valid" large width="100%" @click="sendEmailAndMoveToSuccessPage"
                               class="small-button rounded-lg" height="50px" text elevation="0"

                        >
                            <h2 style="font-weight: bold; color: white; letter-spacing: -1px !important;">
                                신청서 제출
                            </h2>
                        </v-btn>
                        <v-snackbar
                                v-model="snackbar"
                                class="text-center"
                                top
                        >
                            {{ text }}

                            <template v-slot:action="{ attrs }">
                                <v-btn
                                        color="pink"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar = false"
                                >
                                    Close
                                </v-btn>
                            </template>
                        </v-snackbar>

                    </v-container>
                </v-form>
            </template>
        </v-card>
        <div class="py-7"></div>
    </div>
</template>

<script>
    import info from "../../../info";
    import config from "../../../config";
    import emailjs from "emailjs-com";

    export default {
        name: "BootcampRegister",
        data() {
            return {
                hide: false,
                countryCodes: info.countryCodes,
                programs: info.bootCampPrograms,
                grades: info.newGradesList,
                channels: info.channelList,
                parentName: '',
                email: '',
                parentCountryCode: '',
                parentPhone: '',
                parentLocationAndTime: '',
                studentGrade: '',
                selectedProgram: '',
                program: '',
                applyReason: '',
                channel: '',
                snackbar: false,
                text: `예약이 접수 되었습니다.`,
                valid: false,

                rules: {
                    required: (value) => !!value || '필수 입력',
                    email: (value) => {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || '이메일을 올바르게 입력해주세요.';
                    },
                    isValidBirthdate: (value) => (value.length == 8) || '생년월일은 8자리 숫자로 입력 해주세요',
                    // isValidPhoneNumber: (value) => (value.length == 11) || '전화번호는 \'-\' 없이 숫자로 입력 해주세요',
                    isValidPhoneNumber: (value) => (value.match(/^[0-9]+$/)) || '전화번호는 \'-\' 없이 숫자로 입력 해주세요',
                    // isValidBirthdate: (value) => (value.length == 8) || '생년월일은 8자리 숫자로 입력 해주세요',
                    // isValidPhoneNumber: (value) => isNaN(value) || '핸드폰 번호는 '-' 없이 11자리 숫자로 입력 해주세요',
                    isAYear: (value) => (value >= 19400101 && value <= 20251231 && value.length == 8) || '생일은 8자리 숫자 및 1940 - 2022년만 유효합니다. ',
                    hourlyRate: (value) => (value >= 10 && value <= 2000) || 'Must Be between 10 and 2000',
                    biggerthanzero: (value) => value > 0 || 'Can not work less than 1 month',
                    overviewLength: (v) =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        (v.length >= 50 && v.length <= 5000) || 'Min 50 characters, Max 5000 characters',
                    emptyArray: (v) => v.length > 0 || 'Min One Skill is needed',
                    passwordMatch: (v1, v2) => v1 === v2 || '비밀번호가 일치하지 않습니다.',
                    MinPassLen: (v) => v.length >= 8 || '8자 이상의 비밀번호만 허용 됩니다.',
                },
            }
        },
        methods: {
            goBackOnePage() {
                this.$router.push("/");
            },
            sendEmail() {
                this.email = "이름: " + this.parentName + ", " + "휴대전화: " + this.parentCountryCode + " " + this.parentPhone + ", " + " 희망 상담시간: " + this.parentLocationAndTime + ", 학년: " + this.studentGrade + ", 유입경로: " + this.channel + ", 이메일: " + this.email + ", 신청 프로그램: " + this.selectedProgram + ", 신청 이유: " + this.applyReason;

                var obj = {
                    user_email: this.parentName,
                    from_name: "Bootcamp 신청 from " + this.parentName + ": " + this.parentCountryCode + " " + this.parentPhone,
                    message_html: this.email,
                    to_name: "IVYED",
                };

                emailjs
                    .send(
                        config.emailjs.serviceID,
                        config.emailjs.templateID,
                        obj,
                        config.emailjs.userID
                    )
                    .then(
                        // eslint-disable-next-line no-unused-vars
                        (result) => {
                            this.snackbar = true;
                            // this.$router.push('/profile')
                            this.email = "";
                            this.text = "";
                            this.name = "";
                        },
                        // eslint-disable-next-line no-unused-vars
                        (error) => {
                            this.showSnackbar = true;
                            this.snackbarMessage = "Oops! Something went wrong.";
                            this.snackbarColor = "#64808E";
                        }
                    );
            },
            sendEmailAndMoveToSuccessPage() {
                this.sendEmail();
                // this.$router
                this.$router.push("/bootcamp/apcsa/register/confirmed");
            },
            toggleMoney() {
                this.hide = !this.hide;
            }

        }
    }
</script>

<style scoped>

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .small-button {
        /*box-shadow: 1px 1px 1px 1px;*/
        /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
        /*box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;*/
        /*box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;*/
        /*box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;*/
        /*position: fixed;*/
        /*bottom: 10px;*/
        /*left: 50%;*/
        /*margin-left: -104.5px; !*104.5px is half of the button's width*!*/
        background-image: linear-gradient(92.88deg, #DE2828 9.16%, #cd6060 64.72%);
        /*background-color: #DE2828;*/
        /*border-radius: 15px;*/
        /*!*border-radius: 8px;*!*/
        /*!*border-style: none;*!*/
        /*!*box-sizing: border-box;*!*/
        /*color: #FFFFFF;*/
        /*cursor: pointer;*/
        /*!*flex-shrink: 0;*!*/
        /*!*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*!*/
        /*!*font-family: 'SuncheonB';*!*/
        /*!*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*!*/
        /*font-size: 15px;*/
        /*height: 50px;*/
        /*width: 120%px;*/
        /*!*width: 100%;*!*/
        /*font-weight: 500;*/

    }


</style>
