<template>
    <div>
        <v-footer class="hidden-sm-and-down" height="300px" color="#393130"
                  padless>
            <v-card
                    flat
                    tile
                    class="white--text text-center"
                    color="#393130"
                    width="100%"
            >
                <v-card-text>
                    <v-btn
                            v-for="item in iconsAndLinks"
                            :key="item.link"
                            class="mx-4 white--text"
                            icon
                            :href="item.link"
                            target="_blank"
                    >
                        <v-icon size="24px">
                            {{ item.icon }}
                        </v-icon>
                    </v-btn>
                </v-card-text>
                <v-card-text class="white--text font-weight-bold" style="font-size: 20px">
                    <a href="https://www.ivyed.org" target="_blank" class="white--text">IVY<span style="font-size: 18px">ED, </span>International</a>

                </v-card-text>


                <v-card-text class="white--text pt-0">
                    <strong>(주) 투인피니티</strong><br/>
                    고객센터: 070-8970-5223 | 이메일: support@ivyed.co.kr | 카카오ID: ivyed
                    <br/>
                    대표: 김주남 | 사업자번호 : 870-87-02276 <br/> 주소: 서울특별시 서초구 강남대로 97길 26

                    <!--                    서울특별시 서초구 강남대로 61길 10, 607호 (서초동, 센터프라자)-->
                    <br/>
                    통신판매업신고: 2022-서울 서초-1617
                    <br/>

                    <br/>
                    <a class="link" target="_blank" :href="policies.termsOfUse">이용약관</a>
                    | <a class="link" target="_blank" :href="policies.personalInfoHandling">개인정보 처리방침</a>
                    | <a class="link" target="_blank" :href="policies.refundTerms">환불규정</a>
                    <br/>
                    <!--                    <br/>-->
                    <br/>


                    Copyright © 2022 ToInfinity All Rights Reserved.
                    <br/>
<!--                    COPYRIGHT 2022 IvyEd, All Rights Reserved-->

                </v-card-text>

                <v-divider></v-divider>

                <!--            <v-card-text class="white&#45;&#45;text">-->
                <!--                {{ new Date().getFullYear() }} — <strong>IVYeD</strong>-->
                <!--            </v-card-text>-->
            </v-card>
        </v-footer>

        <v-footer class="hidden-md-and-up secondary lighten-1 white--text"
                  padless
                  height="350px"
        >
            <v-card
                    flat
                    tile
                    color="#393130"
                    class="white--text"
                    width="100%"
                    height="100%"
            >
                <v-card-text class="text-center">
                    <v-btn
                            v-for="item in iconsAndLinks"
                            :key="item.link"
                            class="mx-4 white--text"
                            icon
                            :href="item.link"
                            target="_blank"
                    >
                        <v-icon size="24px" color="item.color">
                            {{ item.icon }}
                        </v-icon>
                    </v-btn>
                </v-card-text>
                <v-card-text class="white--text font-weight-bold" style="font-size: 20px">
                    <a href="https://www.ivyed.org" target="_blank" class="white--text">IVY<span style="font-size: 18px">ED, </span>International</a>

                </v-card-text>

                <v-card-text class="white--text pt-0">
                    <strong>(주) 투인피니티</strong><br/>
                    고객센터: 070-8970-5223 <br/>이메일: support@ivyed.co.kr | 카카오ID: ivyed
                    <br/>
                    대표: 김주남 | 사업자번호 : 870-87-02276 <br/> 주소: 서울특별시 서초구 강남대로 97길 26
                    <br/>
                    통신판매업신고: 2022-서울 서초-1617
                    <br/>
                    <br/>

                    <a class="link" target="_blank"
                       :href="policies.termsOfUse">이용약관</a>
                    | <a class="link" target="_blank" :href="policies.personalInfoHandling">개인정보 처리방침</a>
                    | <a class="link" :href="policies.refundTerms">환불규정</a>
                    <br/>
                    <br/>
                    <!--                    전화번호: 070-8970-5223-->
                    <!--                    <br/>-->
                    <!--                    통신판매업신고: 2022-00 00-1111-->
                    <!--                    <br/>-->
                    Copyright © 2023 ToInfinity All Rights Reserved.
                </v-card-text>
            </v-card>
        </v-footer>
        <!--    <div class="footer">-->
        <!--        <p>Footer</p>-->
        <!--    </div>-->
    </div>
</template>


<script>
    import info from "../../../info";

    export default {
        name: "Footer",
        data: () => ({
            iconsAndLinks: [
                // 색갈들 바꾸
                {icon: '$vuetify.icons.kakao', link: 'https://pf.kakao.com/_NxlSSb/chat'},
                {icon: '$vuetify.icons.youtube', link: 'https://www.youtube.com/@IVYED'},
                {icon: '$vuetify.icons.naverBlog', link: 'https://bit.ly/3yZxqFx'},
                {icon: '$vuetify.icons.instagram', link: 'https://www.instagram.com/ivyed.official/'},
                // {icon: '$vuetify.icons.notion', link: 'https://ivyed.notion.site/262e0e113d9046128c5f8eadf4669b13'},
                // {icon: '$vuetify.icons.medium', link: 'https://bit.ly/3bXsidz'},
                // https://ivyed.notion.site/IVYED-Info-4e12dd5af08641269ea09f8b43a3a461

            ],
            icons: [
                'mdi-facebook',
                'mdi-twitter',
                'mdi-linkedin',
                'mdi-instagram',
                '$vuetify.icons.kakaoChannel',
                '$vuetify.icons.notion',
            ],
            items: [
                'default',
                'absolute',
                'fixed',
            ],
            padless: false,
            variant: 'default',
            policies: info.policies,
        }),
        computed: {
            localAttrs() {
                const attrs = {}

                if (this.variant === 'default') {
                    attrs.absolute = false
                    attrs.fixed = false
                } else {
                    attrs[this.variant] = true
                }
                return attrs
            },
        },
    }
</script>


<style scoped>
    .footer {
        /*position: fixed;*/
        /*left: 0;*/
        /*bottom: 0;*/
        width: 100%;
        /*background-color: #DE2828;*/
        /*color: white;*/
        text-align: center;
    }

    .link {
        color: #fffffb;
        /*color: blue;*/
        text-decoration: none;

    }
</style>
