<template>
    <div style="font-family: 'Montserrat'">
        <v-app-bar v-if="getDefaultLanguage.includes('ko')" app elevation="0" color="accent"
                   class="nav-background-color nav-shape ">
            <!--        color="primary">-->
            <v-toolbr-title class="hidden-sm-and-down ">
                <div class="">
                    <a href="/" class="">
                        <NavBarLogo></NavBarLogo>
                    </a>
                </div>
            </v-toolbr-title>

            <v-fade-transition mode="out-in" :appear="true" :duration="200">
                <!--PRE-LOGIN -->



                <v-toolbar-items v-if="!currentUser" class="hidden-sm-and-down browser-spacing">
<!--                    <div class="waviy" @click="goToBootcampPage"-->
<!--                         style="cursor: pointer;padding-top: 18px; padding-right: 10px;font-size: 20px">-->

<!--                        <span style="&#45;&#45;i:1">W</span>-->
<!--                        <span style="&#45;&#45;i:2">I</span>-->
<!--                        <span style="&#45;&#45;i:3">N</span>-->
<!--                        <span style="&#45;&#45;i:4">T</span>-->
<!--                        <span style="&#45;&#45;i:5">E</span>-->
<!--                        <span style="&#45;&#45;i:6">R</span>-->
<!--                        <span style="margin-right: 5px"> </span>-->
<!--                        <span style="&#45;&#45;i:7">부</span>-->
<!--                        <span style="&#45;&#45;i:8">트</span>-->
<!--                        <span style="&#45;&#45;i:9">캠</span>-->
<!--                        <span style="&#45;&#45;i:10">프</span>-->

<!--                    </div>-->
                    <v-btn text v-for="item in preLogin" :key="item.to"
                           :to="item.to" class="nav-bar-text-browser">{{item.title}}
                    </v-btn>
                </v-toolbar-items>

            </v-fade-transition>

            <!--POST LOGIN-->
            <v-toolbar-items v-if="currentUser" class="hidden-sm-and-down browser-spacing">
<!--                <div class="waviy" @click="goToBootcampPage"-->
<!--                     style="cursor: pointer;padding-top: 18px; padding-right: 10px;font-size: 20px">-->
<!--                    &lt;!&ndash;                    <span style="&#45;&#45;i:1">S</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <span style="&#45;&#45;i:1">U</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <span style="&#45;&#45;i:1">M</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <span style="&#45;&#45;i:1">M</span>&ndash;&gt;-->
<!--                    <span style="&#45;&#45;i:1">W</span>-->
<!--                    <span style="&#45;&#45;i:2">I</span>-->
<!--                    <span style="&#45;&#45;i:3">N</span>-->
<!--                    <span style="&#45;&#45;i:4">T</span>-->
<!--                    <span style="&#45;&#45;i:5">E</span>-->
<!--                    <span style="&#45;&#45;i:6">R</span>-->
<!--                    <span style="margin-right: 5px"> </span>-->
<!--                    <span style="&#45;&#45;i:7">부</span>-->
<!--                    <span style="&#45;&#45;i:8">트</span>-->
<!--                    <span style="&#45;&#45;i:9">캠</span>-->
<!--                    <span style="&#45;&#45;i:10">프</span>-->
<!--                    &lt;!&ndash;                    <span style="&#45;&#45;i:5">C</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <span style="&#45;&#45;i:6">A</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <span style="&#45;&#45;i:7">M</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <span style="&#45;&#45;i:8">P</span>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <span style="&#45;&#45;i:9">프</span>&ndash;&gt;-->

<!--                </div>-->
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="nav-bar-text-browser" @click.stop="drawer = !drawer"
                               dark
                               v-bind="attrs"
                               v-on="on">
                            <!--                            {{currentUser.username.substr(0, currentUser.username.indexOf('@'))}}-->
                            <v-avatar color="primary">{{currentUser.username.substr(0, 1)}}</v-avatar>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                                v-for="(item, index) in postLogin"
                                :key="index"
                                link
                                :to="item.to"
                        >

                            <v-list-item-title>
                                {{ item.title }}

                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                                v-if="showTeacherInfo"
                                link
                                :to="'/profile/edit/teacher'"

                        >
                            <v-list-item-title>선생님 정보</v-list-item-title>

                        </v-list-item>
                        <v-list-item
                                link
                                :to="'/'"
                                @click="logOut()"
                        >
                            <v-list-item-title>로그아웃</v-list-item-title>

                        </v-list-item>
                    </v-list>
                </v-menu>

                <!--                <v-btn text x-large color="primary" class="font-weight-bold">-->
                <!--                    <v-avatar color="accent">-->
                <!--                        <span class="primary&#45;&#45;text text-h5">{{currentUser.username.substr(0, 1)}}</span>-->
                <!--                    </v-avatar>-->
                <!--                </v-btn>-->
                <!--            <v-btn text x-large color="primary" class="font-weight-bold"-->
                <!--                   :to="'/profile'">-->
                <!--                프로필-->
                <!--            </v-btn>-->
                <v-btn v-if="isAdmin" text x-large color="primary" class="font-weight-bold"
                       :to="'/admin'">
                    Admin
                </v-btn>

                <!--            <v-btn text x-large color="primary" @click.prevent="logOut" class="font-weight-bold"-->
                <!--                   :to="'/'">-->
                <!--                로그아웃-->
                <!--            </v-btn>-->
            </v-toolbar-items>
            <!--        <v-btn elevation="0" class="hidden-sm-and-down"><v-icon color="primary">mdi-web</v-icon></v-btn>-->


            <!------------------------------------------------>
            <!-----------M O B I L E -------->
            <!------------------------------------------------>

            <v-toolbar-title class="hidden-md-and-up ">
                <a href="/">
                    <div class="mobile-logo-text">
                        <!--                        <span class="logo-title-name glow">IVYeD</span>-->
                        <!--                        <span class="logo-title-dot pblue">.</span>-->
                        <!--                            <img class="logo-pic"-->
                        <!--                                 :src="require('../assets/IVYED(us font).png')" height="150"/>-->
                        <!--                        <img class="logo-pic"-->
                        <!--                             :src="require('../assets/IVYED(highfont).png')" height="150"/>-->
                        <NavBarLogo></NavBarLogo>

                    </div>
                    <!--                    <v-img src="../assets/IvyEd-cornell-color-mobile.png"-->
                    <!--                           :width="100"></v-img>-->

                </a>
            </v-toolbar-title>
            <v-spacer class=""></v-spacer>

            <!-------------------------------->
            <!---------PRE LOGIN    ---------->
            <!-------------------------------->

            <div v-if="!currentUser" class="d-flex">
<!--                <div @click="goToBootcampPage" class="waviy hidden-md-and-up"-->
<!--                     style="padding-top: 12px; cursor: pointer">-->

<!--                    <span style="&#45;&#45;i:1">W</span>-->
<!--                    <span style="&#45;&#45;i:2">I</span>-->
<!--                    <span style="&#45;&#45;i:3">N</span>-->
<!--                    <span style="&#45;&#45;i:4">T</span>-->
<!--                    <span style="&#45;&#45;i:5">E</span>-->
<!--                    <span style="&#45;&#45;i:6">R</span>-->
<!--                    <span style="margin-right: 5px"> </span>-->
<!--                    <span style="&#45;&#45;i:7">부</span>-->
<!--                    <span style="&#45;&#45;i:8">트</span>-->
<!--                    <span style="&#45;&#45;i:9">캠</span>-->
<!--                    <span style="&#45;&#45;i:10">프</span>-->
<!--                </div>-->
                <v-menu offset-y v-if="!currentUser">
                    <template v-slot:activator="{ on, attrs }">
                        <v-app-bar-nav-icon
                                color="white"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="hidden-md-and-up"></v-app-bar-nav-icon>
                    </template>
                    <v-list>
                        <v-list-item
                                v-for="(item, index) in mobileMenu"
                                :key="index" :to="item.to"
                        >
                            <v-list-item-title class="black--text">{{ item.title }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                                v-for="(item, index) in preLogin"
                                :key="index" :to="item.to"
                        >
                            <v-list-item-title class="black--text">{{ item.title }}</v-list-item-title>
                        </v-list-item>
                        <!--                    <v-list-item href="https://www.ivyed.org/">-->
                        <!--                        <v-list-item-title class="black&#45;&#45;text"><v-icon>mdi-web</v-icon> ivyed.org </v-list-item-title>-->
                        <!--                    </v-list-item>-->
                    </v-list>
                </v-menu>
            </div>
            <!-------------------------------->
            <!--------- POST LOGIN  ---------->
            <!-------------------------------->
            <v-toolbar-items v-if="currentUser" class="hidden-md-and-up button-group">

                <v-btn text v-for="item in mobileMenu" :key="item.to" class="nav-bar-text-mobile"
                       :to="item.to">{{item.title}}
                </v-btn>

                <v-btn v-if="isAdmin" text class="nav-bar-text-mobile"
                       :to="'/admin'">
                    Admin
                </v-btn>

<!--                                <div @click="goToBootcampPage" class="waviy hidden-md-and-up" style="cursor:pointer;;padding-top: 15px">-->

<!--                                    <span style="&#45;&#45;i:1">W</span>-->
<!--                                    <span style="&#45;&#45;i:2">I</span>-->
<!--                                    <span style="&#45;&#45;i:3">N</span>-->
<!--                                    <span style="&#45;&#45;i:4">T</span>-->
<!--                                    <span style="&#45;&#45;i:5">E</span>-->
<!--                                    <span style="&#45;&#45;i:6">R</span>-->
<!--                                    <span style="margin-right: 5px"> </span>-->
<!--                                    <span style="&#45;&#45;i:7">부</span>-->
<!--                                    <span style="&#45;&#45;i:8">트</span>-->
<!--                                    <span style="&#45;&#45;i:9">캠</span>-->
<!--                                    <span style="&#45;&#45;i:10">프</span>-->
<!--                                </div>-->
                <v-menu offset-y max-width="400px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="nav-bar-text-mobile" @click.stop="drawer = !drawer"
                               dark
                               v-bind="attrs"
                               v-on="on">
                            <!--                            {{currentUser.username.substr(0, currentUser.username.indexOf('@'))}}-->
                            <v-avatar color="primary">{{currentUser.username.substr(0, 1)}}</v-avatar>

                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                                v-for="(item, index) in postLogin"
                                :key="index"
                                link
                                :to="item.to"
                                color="primary"
                        >

                            <v-list-item-title>
                                {{ item.title }}

                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                                v-if="showTeacherInfo"
                                link
                                :to="'/profile/edit/teacher'"

                        >선생님 정보
                        </v-list-item>
                        <v-list-item
                                link
                                :to="'/'"
                                @click.prevent="logOut()"
                        >
                            <v-list-item-title>로그아웃</v-list-item-title>

                        </v-list-item>
                    </v-list>
                </v-menu>

            </v-toolbar-items>
        </v-app-bar>

        <v-app-bar v-if="getDefaultLanguage.includes('en')" app elevation="0" color="accent"
                   class="nav-background-color nav-shape ">
            <!--        color="primary">-->
            <v-toolbr-title class="hidden-sm-and-down ">
                <div class="">
                    <a href="/" class="">
                        <NavBarLogo></NavBarLogo>
                    </a>
                </div>
            </v-toolbr-title>

            <v-fade-transition mode="out-in" :appear="true" :duration="200">

                <!--PRE-LOGIN -->

                <v-toolbar-items v-if="!currentUser" class="hidden-sm-and-down browser-spacing">
                    <!--                    <v-btn text v-for="item in menu" :key="item.to"-->
                    <!--                           :to="item.to" class="nav-bar-text-browser">{{item.title}}-->
                    <!--                    </v-btn>-->
                    <!--                    <v-btn text-->
                    <!--                           :href="'/blog'" class="nav-bar-text-browser "> <span class="">COMMUNITY</span>-->
                    <!--                    </v-btn>-->
                    <!--                    <v-btn text-->
                    <!--                           :href="faqLink" class="nav-bar-text-browser">FAQ-->
                    <!--                    </v-btn>-->
                    <!--                    <v-btn text-->
                    <!--                           :to="'/enroll'" class="nav-bar-text-browser">수강신청-->
                    <!--                    </v-btn>-->
                    <!--                    <v-btn text v-if="!currentUser" x-small-->
                    <!--                           :href="'/summer'" class="nav-bar-text-mobile">-->

                    <!--                        <div class="pt-2 text-center font-weight-medium" style="font-family: RixYeoljeongdo_Regular">-->
                    <!--                            <div style="color: #f4efea;-->
                    <!--	-webkit-text-stroke: 0.5px gray;-->
                    <!--">-->
                    <!--                                2023-->
                    <!--                            </div>-->
                    <!--                            <div class="waviy">-->
                    <!--                                <span style="&#45;&#45;i:1">썸</span>-->
                    <!--                                <span style="&#45;&#45;i:2">머</span>-->

                    <!--                                <span style="&#45;&#45;i:3">캠</span>-->
                    <!--                                <span style="&#45;&#45;i:4">프</span>-->
                    <!--                                &lt;!&ndash;                    <span style="&#45;&#45;i:5">C</span>&ndash;&gt;-->
                    <!--                                &lt;!&ndash;                    <span style="&#45;&#45;i:6">A</span>&ndash;&gt;-->
                    <!--                                &lt;!&ndash;                    <span style="&#45;&#45;i:7">M</span>&ndash;&gt;-->
                    <!--                                &lt;!&ndash;                    <span style="&#45;&#45;i:8">P</span>&ndash;&gt;-->
                    <!--                                &lt;!&ndash;                    <span style="&#45;&#45;i:9">프</span>&ndash;&gt;-->

                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </v-btn>-->
<!--                                        <div class="waviy" @click="goToBootcampPage" style="cursor: pointer;padding-top: 18px; padding-right: 10px;font-size: 20px">-->


<!--                                            <span style="&#45;&#45;i:1">W</span>-->
<!--                                            <span style="&#45;&#45;i:2">I</span>-->
<!--                                            <span style="&#45;&#45;i:3">N</span>-->
<!--                                            <span style="&#45;&#45;i:4">T</span>-->
<!--                                            <span style="&#45;&#45;i:5">E</span>-->
<!--                                            <span style="&#45;&#45;i:6">R</span>-->
<!--                                            <span style="margin-right: 5px"> </span>-->
<!--                                            <span style="&#45;&#45;i:7">B</span>-->
<!--                                            <span style="&#45;&#45;i:8">O</span>-->
<!--                                            <span style="&#45;&#45;i:9">O</span>-->
<!--                                            <span style="&#45;&#45;i:10">T</span>-->
<!--                                            <span style="&#45;&#45;i:11">C</span>-->
<!--                                            <span style="&#45;&#45;i:12">A</span>-->
<!--                                            <span style="&#45;&#45;i:13">M</span>-->
<!--                                            <span style="&#45;&#45;i:14">P</span>-->

<!--                                        </div>-->
                    <v-btn text v-for="item in englishPreLogin" :key="item.to"
                           :to="item.to" class="nav-bar-text-browser">{{item.title}}
                    </v-btn>
                </v-toolbar-items>

            </v-fade-transition>

            <!--POST LOGIN-->
            <v-toolbar-items v-if="currentUser" class="hidden-sm-and-down browser-spacing">

                <!--                <v-btn text-->
                <!--                       :href="'/blog'" class="nav-bar-text-browser">COMMUNITY-->
                <!--                </v-btn>-->
                <!--                <v-btn text v-for="item in menu" :key="item.to"-->
                <!--                       :href="faqLink" class="nav-bar-text-browser">FAQ-->
                <!--                </v-btn>-->
                <!--                {{currentUser.roles[0] == 'ROLE_TEACHER'}}-->
                <!--                <v-btn text v-if="showPriceInfo"-->
                <!--                       :to="'/enroll'" class="nav-bar-text-browser">수강신청-->
                <!--                </v-btn>-->
                <!--                title: '가격안내', to: '/enroll'-->
                <!--                    need to show USER INFO & LOGOUT OPTION-->
                <!--            <v-btn text x-large color="primary" class="font-weight-bold">-->
                <!--                <v-icon> mdi-account</v-icon>-->
                <!--            </v-btn>-->
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="nav-bar-text-browser" @click.stop="drawer = !drawer"
                               dark
                               v-bind="attrs"
                               v-on="on">
                            <!--                            {{currentUser.username.substr(0, currentUser.username.indexOf('@'))}}-->
                            <v-avatar color="primary">{{currentUser.username.substr(0, 1)}}</v-avatar>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                                v-for="(item, index) in englishPostLogin"
                                :key="index"
                                link
                                :to="item.to"
                        >

                            <v-list-item-title>
                                {{ item.title }}

                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                                v-if="showTeacherInfo"
                                link
                                :to="'/profile/edit/teacher'"

                        >
                            <v-list-item-title>선생님 정보</v-list-item-title>

                        </v-list-item>
                        <v-list-item
                                link
                                :to="'/'"
                                @click="logOut()"
                        >
                            <v-list-item-title>LOG OUT</v-list-item-title>

                        </v-list-item>
                    </v-list>
                </v-menu>

                <!--                <v-btn text x-large color="primary" class="font-weight-bold">-->
                <!--                    <v-avatar color="accent">-->
                <!--                        <span class="primary&#45;&#45;text text-h5">{{currentUser.username.substr(0, 1)}}</span>-->
                <!--                    </v-avatar>-->
                <!--                </v-btn>-->
                <!--            <v-btn text x-large color="primary" class="font-weight-bold"-->
                <!--                   :to="'/profile'">-->
                <!--                프로필-->
                <!--            </v-btn>-->
                <v-btn v-if="isAdmin" text x-large color="primary" class="font-weight-bold"
                       :to="'/admin'">
                    Admin
                </v-btn>

                <!--            <v-btn text x-large color="primary" @click.prevent="logOut" class="font-weight-bold"-->
                <!--                   :to="'/'">-->
                <!--                로그아웃-->
                <!--            </v-btn>-->
            </v-toolbar-items>
            <!--        <v-btn elevation="0" class="hidden-sm-and-down"><v-icon color="primary">mdi-web</v-icon></v-btn>-->


            <!------------------------------------------------>
            <!-----------M O B I L E -------->
            <!------------------------------------------------>

            <v-toolbar-title class="hidden-md-and-up ">
                <a href="/">
                    <div class="mobile-logo-text">
                        <!--                        <span class="logo-title-name glow">IVYeD</span>-->
                        <!--                        <span class="logo-title-dot pblue">.</span>-->
                        <!--                            <img class="logo-pic"-->
                        <!--                                 :src="require('../assets/IVYED(us font).png')" height="150"/>-->
                        <!--                        <img class="logo-pic"-->
                        <!--                             :src="require('../assets/IVYED(highfont).png')" height="150"/>-->
                        <NavBarLogo></NavBarLogo>

                    </div>
                    <!--                    <v-img src="../assets/IvyEd-cornell-color-mobile.png"-->
                    <!--                           :width="100"></v-img>-->

                </a>
            </v-toolbar-title>
            <v-spacer class=""></v-spacer>
            <!--            <v-btn text v-if="!currentUser" x-small-->
            <!--                   :href="'/summer'" class="nav-bar-text-mobile hidden-md-and-up">-->

            <!--                <div class="pt-2 text-center font-weight-medium" style="font-family: RixYeoljeongdo_Regular">-->
            <!--                    <div style="color: #f4efea;-->
            <!--	-webkit-text-stroke: 0.5px gray;-->
            <!--">-->
            <!--                        2023-->
            <!--                    </div>-->
            <!--                    <div class="waviy">-->
            <!--                        <span style="&#45;&#45;i:1">썸</span>-->
            <!--                        <span style="&#45;&#45;i:2">머</span>-->

            <!--                        <span style="&#45;&#45;i:3">캠</span>-->
            <!--                        <span style="&#45;&#45;i:4">프</span>-->
            <!--                        &lt;!&ndash;                    <span style="&#45;&#45;i:5">C</span>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                    <span style="&#45;&#45;i:6">A</span>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                    <span style="&#45;&#45;i:7">M</span>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                    <span style="&#45;&#45;i:8">P</span>&ndash;&gt;-->
            <!--                        &lt;!&ndash;                    <span style="&#45;&#45;i:9">프</span>&ndash;&gt;-->

            <!--                    </div>-->
            <!--                </div>-->
            <!--            </v-btn>-->

            <!-------------------------------->
            <!---------PRE LOGIN    ---------->
            <!-------------------------------->

            <!--            <v-btn text v-if="!currentUser" x-small-->
            <!--                   :href="'/blog'" class="nav-bar-text-mobile hidden-md-and-up" >COMMUNITY-->
            <!--            </v-btn>-->
            <!--            <v-btn text v-if="!currentUser" x-small-->
            <!--                   :href="faqLink" class="nav-bar-text-mobile hidden-md-and-up">FAQ-->
            <!--            </v-btn>-->
            <!--            <v-btn text v-if="!currentUser"-->
            <!--                   :to="'/enroll'" class="nav-bar-text-mobile hidden-md-and-up">수강신청-->
            <!--            </v-btn>-->
<!--                        <div class="waviy hidden-sm-and-up" @click="goToBootcampPage" style="cursor: pointer;padding-top: 0px; padding-right: 10px;font-size: 16px">-->

<!--                            <span style="&#45;&#45;i:1">W</span>-->
<!--                            <span style="&#45;&#45;i:2">I</span>-->
<!--                            <span style="&#45;&#45;i:3">N</span>-->
<!--                            <span style="&#45;&#45;i:4">T</span>-->
<!--                            <span style="&#45;&#45;i:5">E</span>-->
<!--                            <span style="&#45;&#45;i:6">R</span>-->
<!--                            <span style="margin-right: 5px"> </span>-->
<!--                            <span style="&#45;&#45;i:7">B</span>-->
<!--                            <span style="&#45;&#45;i:8">O</span>-->
<!--                            <span style="&#45;&#45;i:9">O</span>-->
<!--                            <span style="&#45;&#45;i:10">T</span>-->
<!--                            <span style="&#45;&#45;i:11">C</span>-->
<!--                            <span style="&#45;&#45;i:12">A</span>-->
<!--                            <span style="&#45;&#45;i:13">M</span>-->
<!--                            <span style="&#45;&#45;i:14">P</span>-->

<!--                        </div>-->
            <v-menu offset-y v-if="!currentUser">
                <template v-slot:activator="{ on, attrs }">
                    <v-app-bar-nav-icon
                            color="white"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="hidden-md-and-up"></v-app-bar-nav-icon>
                </template>
                <v-list>
                    <v-list-item
                            v-for="(item, index) in mobileMenu"
                            :key="index" :to="item.to"
                    >
                        <v-list-item-title class="black--text">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                            v-for="(item, index) in englishPreLogin"
                            :key="index" :to="item.to"
                    >
                        <v-list-item-title class="black--text">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                    <!--                    <v-list-item href="https://www.ivyed.org/">-->
                    <!--                        <v-list-item-title class="black&#45;&#45;text"><v-icon>mdi-web</v-icon> Int'l </v-list-item-title>-->
                    <!--                    </v-list-item>-->
                </v-list>
            </v-menu>

            <!-------------------------------->
            <!--------- POST LOGIN  ---------->
            <!-------------------------------->
            <v-toolbar-items v-if="currentUser" class="hidden-md-and-up button-group">

                <v-btn text v-for="item in mobileMenu" :key="item.to" class="nav-bar-text-mobile"
                       :to="item.to">{{item.title}}
                </v-btn>
                <!--                <v-btn text  class="nav-bar-text-mobile"-->
                <!--                       :to="'/enroll'">{{item.title}}-->
                <!--                </v-btn>-->
                <!--                <v-btn text x-small-->
                <!--                       :href="'/blog'" class="nav-bar-text-mobile">COMMUNITY-->
                <!--                </v-btn>-->
                <!--                <v-btn text x-small-->
                <!--                       :href="faqLink" class="nav-bar-text-mobile">FAQ-->
                <!--                </v-btn>-->
                <!--                                {{currentUser.roles[0]}}-->
                <!--                <v-btn text v-if="showPriceInfo"-->
                <!--                       :to="'/enroll'" class="nav-bar-text-mobile">수강신청-->
                <!--                </v-btn>-->
                <!--                    need to show USER INFO & LOGOUT OPTION-->
                <!--                <v-btn text class="nav-bar-text-mobile"-->
                <!--                       :to="'/profile'">-->
                <!--                    프로필-->
                <!--                </v-btn>-->
                <v-btn v-if="isAdmin" text class="nav-bar-text-mobile"
                       :to="'/admin'">
                    Admin
                </v-btn>

                <!--                <v-btn text small @click.prevent="logOut" class="nav-bar-text-mobile"-->
                <!--                       :to="'/'">-->
                <!--                    로그아웃-->
                <!--                </v-btn>-->
                <v-menu offset-y max-width="400px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="nav-bar-text-mobile" @click.stop="drawer = !drawer"
                               dark
                               v-bind="attrs"
                               v-on="on">
                            <!--                            {{currentUser.username.substr(0, currentUser.username.indexOf('@'))}}-->
                            <v-avatar color="primary">{{currentUser.username.substr(0, 1)}}</v-avatar>

                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                                v-for="(item, index) in englishPostLogin"
                                :key="index"
                                link
                                :to="item.to"
                                color="primary"
                        >

                            <v-list-item-title>
                                {{ item.title }}

                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                                v-if="showTeacherInfo"
                                link
                                :to="'/profile/edit/teacher'"

                        >선생님 정보
                        </v-list-item>
                        <v-list-item
                                link
                                :to="'/'"
                                @click.prevent="logOut()"
                        >
                            <v-list-item-title>LOG OUT</v-list-item-title>

                        </v-list-item>
                    </v-list>
                </v-menu>

            </v-toolbar-items>
            <!--            {{currentUser}}-->
        </v-app-bar>

    </div>
</template>
<script>
    // import Logo from "./helpers/Logo";
    import StudentService from '../services/student.service'
    import TeacherService from '../services/teacher.service'
    import NavBarLogo from "./helpers/NavBarLogo";
    // import info from "../../info";

    export default {
        components: {NavBarLogo},
        // components: {Logo},
        data: () => ({
            // faqLink: info.policies.faq,
            faqLink: '/faq',
            drawer: false,
            items: [{title: 'EN', icon: 'mdi-web', to: 'https://www.ivyed.org'},
                // {title: 'Account', icon: 'mdi-account-box'},
                // {title: 'Admin', icon: 'mdi-gavel'},
            ],
            group: null,
            menu: [
                // {title: '소개', to: '/intro'},
                // {title: '가격안내', to: '/enroll'},
                // { title: '후기', to: '/review' },
                {title: 'FAQ', to: 'https://www.notion.so/IVYED-FAQ-41a2f96f62724ecebfb35d4338df14e9'},
            ],
            mobileMenu: [
                // {title: '소개', to: '/intro'},
                // {title: '가격안내', to: '/enroll'},
                // { title: '후기', to: '/review' },
                // {title: 'FAQ', to: '/faq'},
            ],
            preLogin: [
                // {title: '커뮤니티', to: '/blog'},
                {title: '브랜드 스토리', to: '/brandstory'},
                {title: 'IVYED', to: '/about'},
                {title: 'FAQ', to: '/faq'},
                {title: '로그인', to: '/login'},
                // {title: '회원가입', to: '/register'},
            ],
            englishPreLogin: [
                // {title: 'COMMUNITY', to: '/blog'},
                {title: 'BRAND STORY', to: '/brandstory'},
                {title: 'IVYED', to: '/about'},
                {title: 'FAQ', to: '/faq'},
                {title: 'LOGIN', to: '/login'},
                // {title: 'SIGN UP', to: '/register'},

            ],
            postLogin: [
                {title: '수업', to: '/profile'},
                {title: '회원정보 수정', to: '/profile/edit'},
                // {title: '커뮤니티', to: '/blog'},
            ],
            englishPostLogin: [
                {title: 'MY CLASS', to: '/profile'},
                {title: 'MY PAGE', to: '/profile/edit'},
                // {title: 'COMMUNITY', to: '/blog'},
            ],
            studentContent: {},
            teacherContent: {},
        }),
        watch: {
            group() {
                this.drawer = false
            }
        },
        mounted() {
            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    // this.setLessonStatuses(this.teacherContent)
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        computed: {
            //이걸로 로그인 상태 확인 할 수 있음.

            getDefaultLanguage() {
                return window.navigator.userLanguage || window.navigator.language;
            },
            currentUser() {
                return this.$store.state.auth.user;
            },
            showAdminBoard() {
                if (this.currentUser && this.currentUser['roles']) {
                    return this.currentUser['roles'].includes('ROLE_ADMIN');
                }

                return false;
            },
            showPriceInfo() {
                if (this.currentUser.roles[0] == 'ROLE_TEACHER' || (this.studentContent.studentRegisterStatus == 'REGISTERED')) {
                    return false;
                }
                return true;
            },
            showTeacherInfo() {
                if (this.teacherContent.registerStatus == 'REGISTERED' && this.currentUser.roles[0] == 'ROLE_TEACHER') {
                    return true;
                }
                return false;
            },
            showModeratorBoard() {
                if (this.currentUser && this.currentUser['roles']) {
                    return this.currentUser['roles'].includes('ROLE_MODERATOR');
                }

                return false;
            },
            isAdmin() {
                return this.currentUser.roles.includes("ROLE_ADMIN")
            },

            isTeacher() {
                return this.currentUser.roles.includes("ROLE_TEACHER")
            }
        },
        methods: {
            logOut() {
                this.$store.dispatch('auth/logout');
                this.$router.push('/');
            },
            goToBootcampPage() {
                this.$router.push('/bootcamp')
            }

        }
    }
</script>
<style scoped>
    .nav-text {
        color: white;
    }

    .nav-background-color {
        /*background-color: #b71c1c;*/
        background: linear-gradient(110deg, #A20916 70%, #DE2828 70%);
    }

    .nav-shape {
        border-radius: 4px;
        box-shadow: 0px -2px 2px rgba(34, 34, 34, 0.6);
    }


    .nav-bar-text-mobile {
        /*font-weight-bold text-h6*/
        font-size: 14px;
        font-weight: bold;
        color: #fffffb;
        transition: 0.5s all;
        /*margin-left: 1px;*/
        /*padding: 0;*/
        /*margin: 0;*/
    }

    .mobile-btn {
        background-color: transparent;
    }

    .nav-bar-text-browser {
        /*font-weight-bold text-h6*/
        font-size: 18px !important;
        font-family: 'Noto Sans KR', sans-serif; /* Use the imported font family */
        font-weight: 500; /* You can adjust the font weight here */
        color: #fffffb !important;

        transition: 0.5s all;
        /*margin-left: 1px;*/
    }

    .nav-link {
        font-weight: 500;
    }

    .nav-link:hover {
        background-color: rgba(160, 159, 159, 0.336);
    }

    nav {
        border-bottom: 1px solid rgba(160, 159, 159, 0.336);
        position: fixed !important;
    }


    a {
        text-decoration: none;
    }

    .logo-div:hover .logo-title-name {
        letter-spacing: 0px;
        transition: 0.5s all;
        position: relative;
        left: 0%;
    }

    .logo-title-name {
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 1px;
        transition: 0.5s all;
        color: #fffffb;
    }

    .logo-title-dot {
        font-size: 15px;
        font-weight: 700;
        color: #fffffb;
    }

    .top-banner {
        margin-bottom: 0px;
        text-align: center;
        background-color: #ea4c4c;
        height: 50;
    }

    .top-banner a, .top-banner span {
        display: inline-block;
    }

    .top-banner a {
        width: 100%;
        padding: 15px 0;
        text-decoration: none;
        color: #A20916;
        color: rgba(255, 255, 255, .8);
    }

    .top-banner span {
        width: 50px;
        heigth: 28px;
        line-height: 28px;
        background-color: #a1c45a;
        border-radius: 20px;
    }

    .top-banner a:hover {
        color: #3c4043;
    }

    /*.navigation-bar {*/
    /*    position: fixed;*/
    /*    width: 100%;*/
    /*    z-index: 1;*/

    /*}*/

    .button-group {
        position: fixed;
        right: 0%;
        /*top: px;*/
    }

    .browser-spacing {
        position: fixed;
        right: 1%;
    }

    .hamburger-btn {
        position: fixed;
        right: 1%;
    }

    .logo-pic {
        margin-top: 5px;
        height: 30px;

    }

    .logo-text {
        /*accent--text font-weight-bold text-h5*/
        color: #fffffb;
        font-weight: bold;
        font-size: 30px;
        /*font-family: 'source-sans-pro-black';*/
    }

    .mobile-logo-text {
        /*accent--text font-weight-bold text-h5*/
        color: #fffffb;
        font-weight: normal;
        font-size: 25px;
        letter-spacing: 0;
        /*font-family: "Source Sans Pro";*/
    }

    .emphasis-4 {
        text-emphasis: 'N' lime;
    }

    /*@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');*/
    /** {*/
    /*    padding: 0;*/
    /*    margin: 0;*/
    /*    box-sizing: border-box;*/
    /*}*/

    body {
        background-color: #151719;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }

    .waviy {

        /*background: #FFFF00;*/
        /*background: linear-gradient(to right, #FFFF00 0%, #FFFFFF 100%);*/
        /*-webkit-background-clip: text;*/
        /*-webkit-text-fill-color: transparent;*/

        -webkit-text-stroke: 0.5px gray;
        position: relative;
        -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, .2));
        font-size: 17px;

    }

    .waviy span {

        /*font-family: 'Alfa Slab One', cursive;*/
        font-family: LINESeedKR-Bd;
        font-weight: bold;

        letter-spacing: 0px;
        position: relative;
        display: inline-block;
        color: #f4efea;
        text-transform: uppercase;
        animation: waviy 1.5s infinite;
        animation-delay: calc(.1s * var(--i));

    }

    @keyframes waviy {
        0%, 40%, 100% {
            transform: translateY(0)
        }
        20% {
            transform: translateY(-3px)
        }
    }
</style>
