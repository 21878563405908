<template>
    <div>
        <div class="container reveal">

            <div class="text-container">
<!--                <div class="text-center" style=" overflow: auto; background-color: #f7f7f7">-->
<!--                    <div style="background-color: #f6f6f6">-->

                        <div style="" class="text-center center-horizontal" >
                            <!--                <div class="py-10"></div>-->

                            <div class="px-5 pt-5 mx-10  center-horizontal" style="  border-radius: 8px; width: 300px">
                                <h3 class="py-4" style="font-size: 18px"><span style="color: #A20916; font-weight: bold">IVYED</span>만의 <br/>철저한 선생님 검증
                                    단계 </h3>


                            </div>

<!--                        </div>-->

<!--                    </div>-->

                </div>


            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "TeacherVerify1",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>


    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
</style>

