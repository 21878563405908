<template>
    <div>
        <div class="container reveal">

            <div class="text-container">
                <div class="text-center" style=" overflow: auto; background-color: #f7f7f7">
                    <div>
                        <StudentTeacherSlider></StudentTeacherSlider>


                        <div class="center-horizontal">

                            <div class="short-vertical-line-3"></div>
                            <div class="short-vertical-line-3 "></div>
                            <div class="py-1"></div>
                            <div class="short-vertical-line-3 "></div>
                            <div class="short-vertical-line-3 "></div>
                            <div class="py-1"></div>
                            <div class="short-vertical-line-3 "></div>
                            <div class="short-vertical-line-3 "></div>
                            <div class="py-1"></div>
                            <div style="border-radius: 50px; background-color: #5b58f6; width: 22px; height: 22px" class="center-horizontal center-vertical">
                                <v-icon color="white" style="font-size: 16px">mdi-check-bold</v-icon>
                            </div>
                            <div class="py-1"></div>

                            <div class="short-vertical-line-3 "></div>
                            <div class="short-vertical-line-3 "></div>
                            <div class="py-1"></div>
                            <div class="short-vertical-line-3 "></div>
                            <div class="short-vertical-line-3 "></div>
                            <div class="py-1"></div>
                            <div class="short-vertical-line-3 "></div>
                            <div class="short-vertical-line-3 "></div>
                        </div>
                        <TeacherSlider></TeacherSlider>
                        <!--                <div class="py-7"></div>-->
                        <!--                <div class="py-7"></div>-->
                        <!--                <div class="py-7"></div>-->
                        <!--                <div class="py-7"></div>-->
                        <!--                <div class="py-7"></div>-->

                    </div>
                </div>


            </div>
        </div>

    </div>
</template>

<script>
    import StudentTeacherSlider from "../StudentTeacherSlider";
    import TeacherSlider from "../TeacherSlider";
    export default {
        name: "TeacherSection3",
        components: {TeacherSlider, StudentTeacherSlider},
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>


    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }

    .cards-section {
        /*max-width: 90%;*/
    }

    .transparent-dark-background {
        background-color: rgba(162, 9, 22, 0.5);

        /*background-color: rgba(0, 0, 0, 0.5); !* Here, '0.5' represents the alpha value (50% opacity) *!*/
        /*padding: 20px;*/
        /*padding-top: 20px;*/
        /*margin-top: 20px;*/
        /*height: 450px;*/
        color: white; /* For better visibility of the text, set the text color to white */
    }

    .box-with-shadow {
        /*width: 200px;*/
        height: 100px;
        /*background-color: #f1f1f1;*/
        border-radius: 3px;
        /*box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);*/
        box-shadow: 0px 1px 6px rgba(128, 128, 128, 0.3);

    }

    .box-shadow-for-cards {
        /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
        /*box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;*/
        /*box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;*/
        /*box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;*/
        /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;*/
        /*box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;*/
        border-radius: 50px;
        /*width: 80%;*/
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }

    /* Keyframes animation definition */
    @keyframes slideVertical {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-100%); /* Adjust this value based on your content height */
        }
    }

    /* Animation for the sliding cards */
    .sliding-card {
        animation: slideVertical 5s infinite; /* Adjust the animation duration as needed */
    }

    .contact-section {
        /*background-color: #f5f5ff;*/
        background-color: #f2f3f8;
    }

    /* Keyframes animation definition */
    @keyframes slideIn {
        0% {
            opacity: 0;
            transform: translateY(50px); /* Adjust this value based on your animation preferences */
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    /* Parent container for the sliding cards */
    .sliding-cards-container {
        height: 400px; /* Adjust the height based on how many cards you want to show at a time */
        overflow: hidden;
    }

    /* Animation for the sliding cards */
    .box-shadow-for-cards {
        animation: slideIn 1s ease forwards;
    }

    /* Add delays to each card to make them appear one at a time */
    .box-shadow-for-cards:nth-child(2) {
        animation-delay: 1s;
    }

    .box-shadow-for-cards:nth-child(3) {
        animation-delay: 2s;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .vertical-line {
        width: 2px; /* Adjust the width of the vertical line */
        height: 7px; /* Adjust the height of the vertical line */
        background-color: white; /* Change the color of the vertical line */
    }

    .short-vertical-line {
        width: 2px; /* Adjust the width of the vertical line */
        height: 2px; /* Adjust the height of the vertical line */
        background-color: white; /* Change the color of the vertical line */
    }

    .short-vertical-line-3 {
        width: 2px; /* Adjust the width of the vertical line */
        height: 2px; /* Adjust the height of the vertical line */
        background-color: #ecced04d; /* Change the color of the vertical line */
    }

    .vertical-line-2 {
        width: 2px; /* Adjust the width of the vertical line */
        height: 7px; /* Adjust the height of the vertical line */
        background-color: #ecced07d; /* Change the color of the vertical line */
    }

    .short-vertical-line-2 {
        width: 2px; /* Adjust the width of the vertical line */
        height: 2px; /* Adjust the height of the vertical line */
        background-color: #ecced07d; /* Change the color of the vertical line */
    }

    .box-2 {
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    .box-3 {
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    }

    .parent-div {
        position: relative;
        /*width: 400px; !* Set the width of the parent div as needed *!*/
        /*height: 300px; !* Set the height of the parent div as needed *!*/
        background-color: #f1f1f1; /* Optional background color for visualization */
        overflow: auto; /* Allow content to overflow and provide scrollbar if needed */
    }

    .fixed-div {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
        border-radius: 8px;

        position: absolute;
        top: 0px;
        left: 0px;
        width: 100px; /* Set the width of the fixed div as needed */
        height: 40px; /* Set the height of the fixed div as needed */
        background-color: #ff0000; /* Set the background color for the fixed div */
        z-index: 2; /* Ensure the fixed div appears above the overflowing content */
    }

    .fixed-div-right {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
        border-radius: 8px;

        position: absolute;
        top: 0px;
        right: 0px;
        width: 100px; /* Set the width of the fixed div as needed */
        height: 40px; /* Set the height of the fixed div as needed */
        background-color: #ff0000; /* Set the background color for the fixed div */
        z-index: 2;
        /*
           }

           .overflowing-content {
               width: 600px; /* Set the width of the overflowing content as needed */
        height: 800px; /* Set the height of the overflowing content as needed */
        background-color: #cccccc; /* Set the background color for the overflowing content */
        /* Add content to the overflowing div as needed */
    }

    .circle-with-checkmark {
        position: relative;
        width: 40px;
        height: 40px;
        /*border: 2px solid #ecced04d; !* Circle border color *!*/
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced04d; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark::before {
        content: "\003F"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: #A20916; /* Checkmark color (white in this case) */
        font-weight: bold;
    }

    .text-gradient-3 {
        background: linear-gradient(to right, #A20916, #FF4E50);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }

    .perfect-circle {
        width: 50px;
        height: 50px;
        border-radius: 50%; /* This makes the div a perfect circle */
        overflow: hidden;
        background-color: #e4c5c8;
    }

    .circle-with-checkmark-2 {
        position: relative;
        width: 20px;
        height: 20px;
        border: 2px solid #5b58f6; /* Circle border color */
        border-radius: 50%; /* Make it a circle */
        background-color: #5b58f6; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark-2::before {
        content: "\2714"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 13px;
        color: #ffffff !important; /* Checkmark color (white in this case) */
    }
    .teacher-verify-circle {
        border-radius: 50%; width: 25px; height: 25px; color: #A20916; font-weight: bold; font-size: 13px;
    }
    .teacher-verify-small-card{
        width: 200px;border-radius: 7px; background-color: white
    }
</style>

