<template>
    <div class="body-div" style="overflow: hidden">
        <!--H tag + alt tag 쓰기-->
        <v-app-bar
                class="hidden-lg-and-up"
                style="position: fixed; background-color: black; z-index: 2; overflow:hidden;"
                elevation="0"
        >


            <v-row align="center" justify="" class="px-3">

                <v-btn @click="scrollTo('ivyed', { behavior: 'smooth' })" x-small text>
                    <div style="font-family: Noto Sans KR;letter-spacing: -0.5px; font-size: 15px; color: white">IVYED
                    </div>
                </v-btn>
                <v-btn @click="scrollTo('teacher', { behavior: 'smooth' })" x-small text>
                    <div style="letter-spacing: -0.5px; font-size: 15px; color: white">선생님</div>
                </v-btn>
                <v-btn @click="scrollTo('how-to', { behavior: 'smooth' })" x-small text>
                    <div style="letter-spacing: -0.5px; font-size: 15px; color: white">이용방법</div>
                </v-btn>
                <v-btn @click="scrollTo('review-section', { behavior: 'smooth' })" x-small text>
                    <div style="letter-spacing: -0.5px; font-size: 15px; color: white">후기</div>
                </v-btn>
                <v-btn @click="scrollTo('trial-class', { behavior: 'smooth' })" x-small text>
                    <div style="letter-spacing: -0.5px; font-size: 15px; color: white">체험수업</div>
                </v-btn>
            </v-row>
        </v-app-bar>

<!--        <div class="text-center pop-up-window">-->
<!--            <v-dialog-->
<!--                    v-model="dialog"-->
<!--                    max-width="400"-->
<!--            >-->


<!--                <div class="bootcamp-modal">-->
<!--                    <div class="text-right pa-2">-->
<!--                        <v-icon color="black" @click="dialog=false">mdi-close</v-icon>-->
<!--                    </div>-->
<!--                    <div class="py-1"></div>-->
<!--                    <div class="py-1"></div>-->
<!--                    <div style="" class="center-vertical model-top">-->
<!--                        &lt;!&ndash;                        <div class="py-8"></div>&ndash;&gt;-->
<!--                        <div style="font-family: SBAggroB; color: white; font-size: 20px" class="text-center">-->
<!--                            <div class="pt-2 text-center font-weight-medium" style="">-->
<!--                                <div style="color: #f4efea; ">-->
<!--                                    &lt;!&ndash;                                    <div class="py-2"></div>&ndash;&gt;-->
<!--                                    <div style="font-size: 15px; color: #A20916" class="">-->
<!--                                        겨울방학에 끝내는 IVYED Bootcamp-->
<!--                                    </div>-->
<!--&lt;!&ndash;                                    <div class="" style="color: #2a1e21">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <span style="font-size: 34px">IVY</span><span style="font-size: 30px">ED</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                    <div class="py-1"></div>-->
<!--                                    <div class="popup-title-text" style="font-size: 30px; color: #2a1e21">2023</div>-->

<!--                                </div>-->
<!--                                <div class="waviy "-->
<!--                                     style="color: #8a4048 !important;font-family: RixYeoljeongdo_Regular; font-size: 28px; ">-->
<!--                                    <span style="&#45;&#45;i:1; color: #8a4048">W</span>-->
<!--                                    <span style="&#45;&#45;i:2; color: #8a4048">I</span>-->

<!--                                    <span style="&#45;&#45;i:3; color: #8a4048">N</span>-->
<!--                                    <span style="&#45;&#45;i:4; color: #8a4048">T</span>-->
<!--                                    <span style="&#45;&#45;i:5; color: #8a4048">E</span>-->
<!--                                    <span style="&#45;&#45;i:6; color: #8a4048">R</span>-->
<!--                                    &lt;!&ndash;                                            <span style="&#45;&#45;i:5">E</span>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                            <span style="&#45;&#45;i:6">R</span>&ndash;&gt;-->
<!--                                    <span style="&#45;&#45;i:7" class="px-1"></span>-->
<!--                                    <span style="&#45;&#45;i:8; color: #8a4048">B</span>-->
<!--                                    <span style="&#45;&#45;i:9; color: #8a4048">O</span>-->
<!--                                    <span style="&#45;&#45;i:10; color: #8a4048">O</span>-->
<!--                                    <span style="&#45;&#45;i:11; color: #8a4048">T</span>-->
<!--                                    <span style="&#45;&#45;i:12; color: #8a4048">C</span>-->
<!--                                    <span style="&#45;&#45;i:13; color: #8a4048">A</span>-->
<!--                                    <span style="&#45;&#45;i:14; color: #8a4048">M</span>-->
<!--                                    <span style="&#45;&#45;i:15; color: #8a4048">P</span>-->
<!--                                    &lt;!&ndash;                    <span style="&#45;&#45;i:5">C</span>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                    <span style="&#45;&#45;i:6">A</span>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                    <span style="&#45;&#45;i:7">M</span>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                    <span style="&#45;&#45;i:8">P</span>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                    <span style="&#45;&#45;i:9">프</span>&ndash;&gt;-->

<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div>-->

<!--                            <div class="center-horizontal">-->
<!--                            <img style="z-index: 0;" width="70%" src="https://i.postimg.cc/ZKKvbzdc/Christmas-character-with-lettering-on-transparent-background-PNG.png" alt="time running out">-->
<!--                            </div>-->
<!--                            <div class=" text-center center-horizontal center-vertical float-end" style="background-color: #000000;border-radius: 80px; height: 80px; width: 80px; font-family: S-CoreDream-6Bold; margin-top: -20px; margin-right: 20px; color: white; z-index: 2">-->

<!--                                선착순<br/>신청-->

<!--                            </div>-->
<!--&lt;!&ndash;                            <v-icon>mdi-book-outline</v-icon>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class="" style="padding-top: 25px"></div>-->
<!--                        <div class="center-horizontal">-->
<!--&lt;!&ndash;                        <div @click="goToBootcampPage" class="text-center py-3 font-weight-bold" style="font-family: S-CoreDream-7ExtraBold; cursor:pointer; font-size: 17px;border: 3px solid black; border-radius: 35px; width: 300px; background-color: #f5e51b">더 알아보기</div>&ndash;&gt;-->
<!--                        <div @click="goToBootcampPage" class="text-center py-3 font-weight-bold" style="font-family: S-CoreDream-7ExtraBold; cursor:pointer; font-size: 17px;border: 3px solid #378b2a; border-radius: 35px; width: 300px; background-color: #378b2a; color: white">더 알아보기</div>-->
<!--                        </div>-->

<!--                        <div class="py-4"></div>-->

<!--                    </div>-->


<!--                </div>-->
<!--            </v-dialog>-->
<!--        </div>-->
        <!--        <v-app-bar class="hidden-md-and-down"-->
        <!--                   style="position: fixed; background-color: rgba(173, 57, 70, 1); z-index: 2; overflow:hidden;"-->
        <!--                   elevation="0"-->
        <!--        >-->
        <v-app-bar class="hidden-md-and-down"
                   style="position: fixed; background-color: black; z-index: 2; overflow:hidden;"
                   elevation="0"
        >
            <v-row align="center" class="px-3">

                <v-btn @click="scrollTo('ivyed', { behavior: 'smooth' })" x-small text>
                    <div style="font-family: Noto Sans KR;letter-spacing: -0.5px; font-size: 18px; color: white">IVYED
                    </div>
                </v-btn>
                <div class="px-2"></div>
                <v-btn @click="scrollTo('teacher', { behavior: 'smooth' })" x-small text>
                    <div style="letter-spacing: -0.5px; font-size: 18px; color: white">선생님</div>
                </v-btn>
                <div class="px-2"></div>
                <v-btn @click="scrollTo('how-to', { behavior: 'smooth' })" x-small text>
                    <div style="letter-spacing: -0.5px; font-size: 18px; color: white">이용방법</div>
                </v-btn>
                <div class="px-2"></div>
                <v-btn @click="scrollTo('review-section', { behavior: 'smooth' })" x-small text>
                    <div style="letter-spacing: -0.5px; font-size: 18px; color: white">후기</div>
                </v-btn>
                <div class="px-2"></div>
                <v-btn @click="scrollTo('trial-class', { behavior: 'smooth' })" x-small text>
                    <div style="letter-spacing: -0.5px; font-size: 18px; color: white">체험수업</div>
                </v-btn>
            </v-row>
        </v-app-bar>

        <div style="background-color: #f3eaeb;">


            <div class="top-section hidden-md-and-up" style="">

                <div style="padding-top: 56px"></div>
                <img src="../../../assets/smartTutoring1.png" alt="IVYED Smart Tutoring Mobile" width="100%">

            </div>
            <div class="top-section hidden-sm-and-down" style="min-height: 900px">

                <div class="py-8"></div>
                <div class="center-horizontal">

                    <img src="../../../assets/smartTutoring2PC.png" alt="IVYED Smart Tutoring PC" style="">
                </div>
            </div>
        </div>
        <div style="background-color: #f3eaeb">
            <div id="ivyed" class="pb-8"></div>
            <div class="pb-8"></div>
            <div class="pb-10"></div>
            <div class="center-horizontal">
                <div class="py-3"></div>
                <div class="py-10 hidden-md-and-down"></div>
                <Potential></Potential>
            </div>
            <div class="center-horizontal">
                <div class="py-8 hidden-md-and-down"></div>


                <Potential2></Potential2>

            </div>
        </div>
        <div class="">
            <div class="py-8 hidden-md-and-down"></div>

            <GradesMustGoUp></GradesMustGoUp>
        </div>
        <div class="center-horizontal">
            <GradesMustGoUp2></GradesMustGoUp2>
            <GradesMustGoUp3></GradesMustGoUp3>
        </div>

        <div class="hidden-md-and-down py-9"></div>
        <div style="background-color: white">
            <AnywhereAlways></AnywhereAlways>
            <AnywhereAlways2></AnywhereAlways2>
            <AnywhereAlways3></AnywhereAlways3>
            <AnywhereAlways4></AnywhereAlways4>
            <AnywhereAlways5></AnywhereAlways5>
            <AnywhereAlways6></AnywhereAlways6>
        </div>


        <div id="teacher" class="py-8"></div>
        <div class="py-8"></div>
        <div style="background-color: #f7f7f7">
            <TeacherSection1></TeacherSection1>

            <TeacherSection2></TeacherSection2>
            <div>
                <TeacherSection3></TeacherSection3>
            </div>
        </div>
        <div style="background-color: #f6f6f6" class="center-horizontal">

            <AboutIvyedTitleMobile style=""></AboutIvyedTitleMobile>
            <div style="">
                <div class="pa-5 ma-10 center-horizontal hidden-lg-and-up"
                     style="background-color: #ecced04d;  border-radius: 8px; width: 300px;">
                    <TeacherVerify1></TeacherVerify1>
                    <TeacherVerify2></TeacherVerify2>
                    <VerticalTeacherLine></VerticalTeacherLine>
                    <TeacherVerify3></TeacherVerify3>
                    <VerticalTeacherLine></VerticalTeacherLine>
                    <TeacherVerify4></TeacherVerify4>
                    <VerticalTeacherLine></VerticalTeacherLine>
                    <TeacherVerify5></TeacherVerify5>
                    <TeacherVerify6></TeacherVerify6>
                </div>
            </div>
            <TeacherVerifyDesktop></TeacherVerifyDesktop>
            <div style="overflow: hidden">
                <div class="py-5"></div>
                <div class="hidden-md-and-down py-5"></div>

                <TeacherCarousel1></TeacherCarousel1>
                <div class="py-2"></div>
                <div class="hidden-md-and-down py-3"></div>

                <div class="hidden-lg-and-up" style="max-width: 330px;overflow: hidden">

                    <TeacherCarousel2></TeacherCarousel2>
                </div>
                <div class="hidden-md-and-down" style="">
                    <TeacherCarousel2></TeacherCarousel2>
                </div>
                <div class="py-2"></div>
                <TeacherCarousel3></TeacherCarousel3>
                <div class="py-7"></div>
            </div>
        </div>


        <div id="how-to" class="center-horizontal">
            <div>
                <div class="py-15"></div>
                <HowToUse1></HowToUse1>
                <div class="py-5"></div>

                <HowToUse2></HowToUse2>
                <div class="py-5"></div>
                <div class="hidden-lg-and-up">
                    <HowToUse3></HowToUse3>
                    <VerticalHowToUseLine class=""></VerticalHowToUseLine>
                    <HowToUse4></HowToUse4>
                    <VerticalHowToUseLine class=""></VerticalHowToUseLine>
                    <HowToUse5></HowToUse5>
                    <VerticalHowToUseLine class=""></VerticalHowToUseLine>
                    <HowToUse6></HowToUse6>
                </div>
            </div>
            <div class="hidden-md-and-down pt-7 pb-15">

                <HowToUseDesktop></HowToUseDesktop>
            </div>
        </div>


        <!--        f6f6f6-->
        <div id="review-section" class="pb-15"></div>
        <div style="background-color: #f6f6f6">
            <div style="overflow: hidden">
                <div class="py-10"></div>
                <Review1></Review1>
                <ReviewCarousel></ReviewCarousel>
            </div>

            <div class="py-10"></div>
            <div class="center-horizontal hidden-lg-and-up">
                <a href="/review" class="custom-button">
                    이용자 후기 더보기 &gt;
                </a>
            </div>
            <div class="center-horizontal hidden-md-and-down">
                <a href="/review" class="custom-button-desktop">
                    이용자 후기 더보기 &gt;
                </a>
            </div>

        </div>
        <div class="py-10" style="background-color: #f6f6f6"></div>


        <div id="trial-class">
            <div class="hidden-lg-and-up contact-section text-center">
                <div class="py-10"></div>
                <div style="font-size: 19px; color: #A20916"> 소비자 선택 1위 유학 과외. <span
                        style="font-size: 22px; font-weight: bold; color: #DE2828">IVY</span><span
                        style="color: #DE2828;font-size: 19px; font-weight: bold">ED</span></div>
                <h2 style="font-size: 28px; line-height: 50px; color: #A20916">체험해보고 결정하세요!</h2>
                <div class="py-1" style="font-size: 12px; color: #A20916"> 무료 체험 수업 &nbsp;| &nbsp; 실시간 질문 답변 &nbsp;|
                    &nbsp; 선생님 무제한 교체&nbsp;

                </div>

                <div class="py-4"></div>
                <v-btn elevation="0" to="/schedule/call"
                       style="letter-spacing: 0px;font-size: 20px; color: white;font-weight: bold; border-radius: 30px; background-color: #A20916; height: 60px; width: 260px; ">
                    전문 상담 신청하기 >
                </v-btn>
                <div
                        style="font-family: rixdongnimgothic-pro, sans-serif; font-size: 24px">
                    <!--                    Glited-->
                </div>

                <div class="py-12"></div>
            </div>
            <div class="hidden-md-and-down contact-section text-center">
                <div class="py-10"></div>
                <div class="py-12"></div>
                <div style="font-size: 24px; color: #A20916"> 소비자 선택 1위 유학 과외. <span
                        style="font-size: 24px; font-weight: bold; color: #DE2828">IVY</span><span
                        style="color: #DE2828;font-size: 21px; font-weight: bold">ED</span></div>
                <div class="py-1"></div>
                <h2 style="font-size: 34px; line-height: 50px; color: #A20916">체험해보고 결정하세요!</h2>
                <div class="py-1"></div>
                <div class="py-1" style="font-size: 18px; color: #A20916"> 무료 체험 수업 &nbsp;| &nbsp; 실시간 질문 답변 &nbsp;|
                    &nbsp;
                    선생님 무제한 교체&nbsp;

                </div>

                <div class="py-4"></div>
                <v-btn elevation="0" to="/schedule/call"
                       style="letter-spacing: 0px;font-size: 20px; color: white;font-weight: bold; border-radius: 30px; background-color: #A20916; height: 60px; width: 260px;">
                    전문 상담 신청하기 >
                </v-btn>

                <div class="py-12"></div>
                <div class="py-10"></div>
            </div>

        </div>
    </div>
</template>

<script>
    // import SchoolCarousel from "../../../views/pages/SchoolCarousel";
    // import VerticalTeacherCarousel from "../../../views/pages/VerticalTeacherCarousel";
    // import StudentTeacherSlider from "./StudentTeacherSlider";
    // import TeacherSlider from "./TeacherSlider";
    // import VerticalCarouselReview from "./VerticalCarouselReview";
    // import FiveStars from "./FiveStars";
    // import MobileCarousel from "./MobileCarousel";
    // import TestCarousel from "./TestCarousel";
    import Potential from "./Sections/Potential";
    import Potential2 from "./Sections/Potential2";
    import GradesMustGoUp from "./Sections/GradesMustGoUp";
    import GradesMustGoUp2 from "./Sections/GradesMustGoUp2";
    import GradesMustGoUp3 from "./Sections/GradesMustGoUp3";

    import AnywhereAlways from "./Sections/AnywhereAlways";
    import AnywhereAlways2 from "./Sections/AnywhereAlways2";
    import AnywhereAlways3 from "./Sections/AnywhereAlways3";
    import AnywhereAlways4 from "./Sections/AnywhereAlways4";
    import AnywhereAlways5 from "./Sections/AnywhereAlways5";
    import TeacherSection1 from "./TeacherSections/TeacherSection1";
    import TeacherSection2 from "./TeacherSections/TeacherSection2";
    import TeacherSection3 from "./TeacherSections/TeacherSection3";
    import TeacherVerify1 from "./TeacherSections/TeacherVerify/TeacherVerify1";
    import TeacherVerify2 from "./TeacherSections/TeacherVerify/TeacherVerify2";
    import TeacherVerify3 from "./TeacherSections/TeacherVerify/TeacherVerify3";
    import TeacherVerify4 from "./TeacherSections/TeacherVerify/TeacherVerify4";
    import TeacherVerify5 from "./TeacherSections/TeacherVerify/TeacherVerify5";
    import TeacherVerify6 from "./TeacherSections/TeacherVerify/TeacherVerify6";
    import VerticalTeacherLine from "./TeacherSections/TeacherVerify/VerticalTeacherLine";
    import TeacherCarousel1 from "./TeacherSections/TeacherCarousel/TeacherCarousel1";
    import TeacherCarousel2 from "./TeacherSections/TeacherCarousel/TeacherCarousel2";
    import TeacherCarousel3 from "./TeacherSections/TeacherCarousel/TeacherCarousel3";
    import HowToUse1 from "./Sections/HowToUseSection/HowToUse1";
    import HowToUse2 from "./Sections/HowToUseSection/HowToUse2";
    import HowToUse3 from "./Sections/HowToUseSection/HowToUse3";
    import VerticalHowToUseLine from "./Sections/HowToUseSection/VerticalHowToUseLine";
    import HowToUse4 from "./Sections/HowToUseSection/HowToUse4";
    import HowToUse5 from "./Sections/HowToUseSection/HowToUse5";
    import HowToUse6 from "./Sections/HowToUseSection/HowToUse6";
    import Review1 from "./Reviews/Review1";
    import ReviewCarousel from "./Reviews/ReviewCarousel";
    import AnywhereAlways6 from "./Sections/HowToUseSection/AnywhereAlways6";
    import TeacherVerifyDesktop from "./TeacherSections/TeacherVerifyDesktop/TeacherVerifyDesktop";
    import HowToUseDesktop from "./Sections/HowToUseSection/HowToUseDesktop";
    import AboutIvyedTitleMobile from "./Sections/AboutIVYED/AboutIvyedTitleMobile";
    // import TestCarousel from "./TestCarousel";

    export default {
        name: "LandingPage",
        components: {
            AboutIvyedTitleMobile,
            HowToUseDesktop,
            TeacherVerifyDesktop,
            AnywhereAlways6,
            ReviewCarousel,
            Review1,
            GradesMustGoUp3,
            HowToUse6,
            HowToUse5,
            HowToUse4,
            VerticalHowToUseLine,
            HowToUse3,
            HowToUse2,
            HowToUse1,
            TeacherCarousel3,
            TeacherCarousel2,
            TeacherCarousel1,
            VerticalTeacherLine,
            TeacherVerify6,
            TeacherVerify5,
            TeacherVerify4,
            TeacherVerify3,
            TeacherVerify2,
            TeacherVerify1,
            TeacherSection3,
            TeacherSection2,
            TeacherSection1,
            AnywhereAlways5,
            AnywhereAlways4,
            AnywhereAlways3,
            AnywhereAlways2,
            AnywhereAlways,
            GradesMustGoUp2,
            GradesMustGoUp,
            Potential2,
            Potential,
            // TestCarousel,
            // TestCarousel,
            // MobileCarousel,
            // TeacherSlider,
            // StudentTeacherSlider,
            // SchoolCarousel
        },
        data() {
            return {
                isFixed: false,
                drag: false,
                touch: false,
                content: "",
                // data: info.home,
                dialog: true,
            };
        },

        mounted() {
            // Add event listener for scroll event
            window.addEventListener('scroll', this.handleScroll);
            // Call handleScroll once to initialize the app bar position
            this.handleScroll();
            const carouselSlides = document.querySelectorAll('.carousel-slide');

            function carouselAnimation() {
                carouselSlides.forEach((slide, index) => {
                    setTimeout(() => {
                        slide.style.animation = 'none';
                        setTimeout(() => {
                            slide.style.animation = 'slideIn 5s infinite'; /* Adjust the animation duration as needed */
                        }, 100);
                    }, index * 1000); /* Adjust the slide delay (e.g., 1000ms = 1 second) */
                });
            }

            carouselAnimation();
        },
        methods: {
            goToBootcampPage() {
                this.$router.push('/bootcamp');
            }
            ,
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__prev")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__next")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
            },
            handleScroll() {
                const scrollPosition = window.scrollY;
                const appBarTop = this.$refs.appBar.getBoundingClientRect().top;
                const breakpoint = appBarTop - 10;

                this.isFixed = scrollPosition > breakpoint;
            },
            scrollTo(target, options) {
                document.getElementById(target).scrollIntoView(options);
            },
        },
        beforeDestroy() {
            // Remove event listener when component is destroyed
            window.removeEventListener('scroll', this.handleScroll);
        },
        computed: {
            appBarStyle() {
                // Customize the style of the app bar here
                return {
                    backgroundColor: '#1B2025',
                    zIndex: 2,
                    overflow: 'auto',
                    position: 'sticky',
                    top: '0',
                    // Add more styles as needed
                };
            },
        },
    }
</script>

<style scoped>
    /* Define styles for the app bar placeholder */
    .app-bar-placeholder {
        height: 64px; /* Adjust to match the height of your app bar */
    }

    /* Define styles for the fixed app bar */
    .app-bar-fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        /* Add more styles as needed */
    }

    .top-section {
        /*background-image: url("../../../assets/IVYED 버튼.png");*/
        /*background-image: url("../../../assets/graduation-gfcc9af869_1280.jpg");*/
        /*background-image: url("../../../assets/smartTutoring1.png");*/
        /*background-size: 100% 700px;*/
        /*background-size: contain;*/
        /*background-repeat: no-repeat;*/
        /*background-color: #eceeef;*/
        /*max-width: 100%;*/
        /*background: linear-gradient(to bottom, #c0c2c0, #ffffff);*/
        background-color: #f3eaeb;
        /*style="background-color: #f3eaeb"*/
        /* You can adjust the percentage value as needed */

        /*height: 800px;*/
    }

    .cards-section {
        /*max-width: 90%;*/
    }

    .transparent-dark-background {
        background-color: rgba(162, 9, 22, 0.5);

        /*background-color: rgba(0, 0, 0, 0.5); !* Here, '0.5' represents the alpha value (50% opacity) *!*/
        /*padding: 20px;*/
        /*padding-top: 20px;*/
        /*margin-top: 20px;*/
        /*height: 450px;*/
        color: white; /* For better visibility of the text, set the text color to white */
    }

    .box-with-shadow {
        /*width: 200px;*/
        height: 100px;
        /*background-color: #f1f1f1;*/
        border-radius: 3px;
        /*box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.3);*/
        box-shadow: 0px 1px 6px rgba(128, 128, 128, 0.3);

    }

    .box-shadow-for-cards {
        /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
        /*box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;*/
        /*box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;*/
        /*box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;*/
        /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;*/
        /*box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;*/
        border-radius: 50px;
        /*width: 80%;*/
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }

    /* Keyframes animation definition */
    @keyframes slideVertical {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-100%); /* Adjust this value based on your content height */
        }
    }

    /* Animation for the sliding cards */
    .sliding-card {
        animation: slideVertical 5s infinite; /* Adjust the animation duration as needed */
    }

    .contact-section {
        /*background-color: #f5f5ff;*/
        background-color: #faf0f1;
    }

    /* Keyframes animation definition */
    @keyframes slideIn {
        0% {
            opacity: 0;
            transform: translateY(50px); /* Adjust this value based on your animation preferences */
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    /* Parent container for the sliding cards */
    .sliding-cards-container {
        height: 400px; /* Adjust the height based on how many cards you want to show at a time */
        overflow: hidden;
    }

    /* Animation for the sliding cards */
    .box-shadow-for-cards {
        animation: slideIn 1s ease forwards;
    }

    /* Add delays to each card to make them appear one at a time */
    .box-shadow-for-cards:nth-child(2) {
        animation-delay: 1s;
    }

    .box-shadow-for-cards:nth-child(3) {
        animation-delay: 2s;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .vertical-line {
        width: 2px; /* Adjust the width of the vertical line */
        height: 7px; /* Adjust the height of the vertical line */
        background-color: white; /* Change the color of the vertical line */
    }

    .short-vertical-line {
        width: 2px; /* Adjust the width of the vertical line */
        height: 2px; /* Adjust the height of the vertical line */
        background-color: white; /* Change the color of the vertical line */
    }

    .short-vertical-line-3 {
        width: 2px; /* Adjust the width of the vertical line */
        height: 2px; /* Adjust the height of the vertical line */
        background-color: #ecced04d; /* Change the color of the vertical line */
    }

    .vertical-line-2 {
        width: 2px; /* Adjust the width of the vertical line */
        height: 7px; /* Adjust the height of the vertical line */
        background-color: #ecced07d; /* Change the color of the vertical line */
    }

    .short-vertical-line-2 {
        width: 2px; /* Adjust the width of the vertical line */
        height: 2px; /* Adjust the height of the vertical line */
        background-color: #ecced07d; /* Change the color of the vertical line */
    }

    .box-2 {
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    .box-3 {
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    }

    .parent-div {
        position: relative;
        /*width: 400px; !* Set the width of the parent div as needed *!*/
        /*height: 300px; !* Set the height of the parent div as needed *!*/
        background-color: #f1f1f1; /* Optional background color for visualization */
        overflow: auto; /* Allow content to overflow and provide scrollbar if needed */
    }

    .fixed-div {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
        border-radius: 8px;

        position: absolute;
        top: 0px;
        left: 0px;
        width: 100px; /* Set the width of the fixed div as needed */
        height: 40px; /* Set the height of the fixed div as needed */
        background-color: #ff0000; /* Set the background color for the fixed div */
        z-index: 2; /* Ensure the fixed div appears above the overflowing content */
    }

    .fixed-div-right {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
        border-radius: 8px;

        position: absolute;
        top: 0px;
        right: 0px;
        width: 100px; /* Set the width of the fixed div as needed */
        height: 40px; /* Set the height of the fixed div as needed */
        background-color: #ff0000; /* Set the background color for the fixed div */
        z-index: 2;
        /*
           }

           .overflowing-content {
               width: 600px; /* Set the width of the overflowing content as needed */
        height: 800px; /* Set the height of the overflowing content as needed */
        background-color: #cccccc; /* Set the background color for the overflowing content */
        /* Add content to the overflowing div as needed */
    }

    .circle-with-checkmark {
        position: relative;
        width: 40px;
        height: 40px;
        /*border: 2px solid #ecced04d; !* Circle border color *!*/
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced04d; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark::before {
        content: "\003F"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: #A20916; /* Checkmark color (white in this case) */
        font-weight: bold;
    }

    .text-gradient-3 {
        background: linear-gradient(to right, #A20916, #FF4E50);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }

    .perfect-circle {
        width: 50px;
        height: 50px;
        border-radius: 50%; /* This makes the div a perfect circle */
        overflow: hidden;
        background-color: #e4c5c8;
    }

    .circle-with-checkmark-2 {
        position: relative;
        width: 20px;
        height: 20px;
        border: 2px solid #5b58f6; /* Circle border color */
        border-radius: 50%; /* Make it a circle */
        background-color: #5b58f6; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark-2::before {
        content: "\2714"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 13px;
        color: #ffffff !important; /* Checkmark color (white in this case) */
    }

    .circle-with-checkmark-three {
        position: relative;
        width: 60px;
        height: 60px;
        border: 2px solid #ecced0; /* Circle border color */
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced0; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark-three::before {
        content: "\2714"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 22px;
        color: #A20916 !important; /* Checkmark color (white in this case) */
    }

    .circle-with-checkmark-four {
        position: relative;
        width: 40px;
        height: 40px;
        border: 2px solid #ecced0; /* Circle border color */
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced0; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark-four::before {
        /*content: "\2714"; !* Checkmark unicode character *!*/
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*font-size: 34px;*/
        /*color: #A20916 !important; !* Checkmark color (white in this case) *!*/
    }


    .teacher-verify-circle {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: #A20916;
        font-weight: bold;
        font-size: 13px;
    }


    .teacher-verify-circle-desktop {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        color: #A20916;
        font-weight: bold;
        font-size: 13px;
    }

    .teacher-verify-small-card {
        width: 200px;
        border-radius: 7px;
        background-color: white
    }

    .body-div {
        /*background-color: rgba(162, 9, 22, 0.2);*/
        /*font-family: "Toss Product Sans" !important;*/
    }

    .custom-button {
        font-size: 17px;
        color: #A20916;
        font-weight: normal;
        border: 1px solid #A20916; /* Outline border with same color as text */
        border-radius: 30px;
        background-color: transparent;
        height: 55px;
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        cursor: pointer;
    }

    .custom-button-desktop {
        font-size: 22px;
        color: #A20916;
        font-weight: normal;
        border: 1px solid #A20916; /* Outline border with same color as text */
        border-radius: 30px;
        background-color: transparent;
        height: 65px;
        width: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        cursor: pointer;
    }

    .custom-underline {
        padding: 30px 0 8px;
        position: relative;
    }

    .custom-underline::before {
        content: "";
        position: absolute;
        left: 7%;
        bottom: 16px;
        width: 150px;
        height: 14px;
        transform: skew(-12deg) translateX(-50%);
        background: rgba(238, 111, 87, 0.5);
        z-index: -1;
    }

    .horizontal-line {
        width: 7px; /* Adjust the width of the horizontal line */
        height: 2px; /* Adjust the height of the horizontal line */
        background-color: white; /* Change the color of the horizontal line */
        margin-top: 67px;
    }

    .horizontal-line-two {
        width: 7px; /* Adjust the width of the horizontal line */
        height: 2px; /* Adjust the height of the horizontal line */
        background-color: #faf0f1; /* Change the color of the horizontal line */
        margin-top: 125px;
    }

    mark {
        display: inline-block;
        line-height: 0em;
        padding-bottom: 0.7em;
        /*background-color: #f3eaeb;*/
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .pop-up-window {
        /*background: linear-gradient(-18.7686deg, #FF94CA 0%, #AAC7FF 40%,#AAC7FF 52.017%,#AAC7FF 60%, #7FFF87 100%);*/
    }

    .bootcamp-modal {
        /*font-family: SBAggroB;*/
        min-height: 500px;
        border-radius: 15px !important;
        /*background-color: #171e26;*/
        background-color: #f4c8ac;
        max-width: 400px;
        /*background: linear-gradient(-89.7686deg, #FF94CA 0%, #AAC7FF 40%, #AAC7FF 52.017%, #AAC7FF 60%, #7FFF87 100%);*/

        background-size: 100% 16%;
    }

    .waviy {

        /*background: #FFFF00;*/
        /*background: linear-gradient(to right, #FFFF00 0%, #FFFFFF 100%);*/
        /*-webkit-background-clip: text;*/
        /*-webkit-text-fill-color: transparent;*/

        -webkit-text-stroke: 0.5px gray;
        position: relative;
        -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, .2));
        font-size: 17px;

    }

    .waviy span {

        /*font-family: 'Alfa Slab One', cursive;*/
        font-family: LINESeedKR-Bd;
        font-weight: bold;

        letter-spacing: 0px;
        position: relative;
        display: inline-block;
        color: #f4efea;
        text-transform: uppercase;
        animation: waviy 1.5s infinite;
        animation-delay: calc(.1s * var(--i));

    }

    @keyframes waviy {
        0%, 40%, 100% {
            transform: translateY(0)
        }
        20% {
            transform: translateY(-3px)
        }
    }
    .popup-title-text{
        color: transparent;
        /*background-image: linear-gradient(to right, #A20916, white, red);*/
        background-image: linear-gradient(to right, #780912, #A20916, #DE2828, #fff9e0, #f6f5f5);
        -webkit-background-clip: text;
        background-clip: text;
    }
    /*.glow {*/
    /*    !*font-size: 14px;*!*/
    /*    color: #fff;*/
    /*    text-align: center;*/
    /*    animation: glow 400ms ease-in-out infinite alternate;*/
    /*}*/

    /*@-webkit-keyframes glow {*/
    /*    from {*/
    /*        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;*/
    /*    }*/

    /*    to {*/
    /*        text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;*/
    /*    }*/
    /*}*/
    /*.logo-1 {*/
    /*    color: white;*/
    /*    animation: neon 3s infinite;*/
    /*}*/

    /*@keyframes neon {*/
    /*    0% {*/
    /*        text-shadow: -1px -1px 1px var(--shadow-color-light), -1px 1px 1px var(--shadow-color-light), 1px -1px 1px var(--shadow-color-light), 1px 1px 1px var(--shadow-color-light),*/
    /*        0 0 3px var(--shadow-color-light), 0 0 10px var(--shadow-color-light), 0 0 20px var(--shadow-color-light),*/
    /*        0 0 30px var(--shadow-color), 0 0 40px var(--shadow-color), 0 0 50px var(--shadow-color), 0 0 70px var(--shadow-color), 0 0 100px var(--shadow-color), 0 0 200px var(--shadow-color);*/
    /*    }*/
    /*    50% {*/
    /*        text-shadow: -1px -1px 1px var(--shadow-color-light), -1px 1px 1px var(--shadow-color-light), 1px -1px 1px var(--shadow-color-light), 1px 1px 1px var(--shadow-color-light),*/
    /*        0 0 5px var(--shadow-color-light), 0 0 15px var(--shadow-color-light), 0 0 25px var(--shadow-color-light),*/
    /*        0 0 40px var(--shadow-color), 0 0 50px var(--shadow-color), 0 0 60px var(--shadow-color), 0 0 80px var(--shadow-color), 0 0 110px var(--shadow-color), 0 0 210px var(--shadow-color);*/
    /*    }*/
    /*    100% {*/
    /*        text-shadow: -1px -1px 1px var(--shadow-color-light), -1px 1px 1px var(--shadow-color-light), 1px -1px 1px var(--shadow-color-light), 1px 1px 1px var(--shadow-color-light),*/
    /*        0 0 3px var(--shadow-color-light), 0 0 10px var(--shadow-color-light), 0 0 20px var(--shadow-color-light),*/
    /*        0 0 30px var(--shadow-color), 0 0 40px var(--shadow-color), 0 0 50px var(--shadow-color), 0 0 70px var(--shadow-color), 0 0 100px var(--shadow-color), 0 0 200px var(--shadow-color);*/
    /*    }*/
    /*}*/
    /*.neon {*/
    /*    color: #fff;*/
    /*    text-shadow:*/
    /*            0 0 5px #fff,*/
    /*            0 0 10px #fff,*/
    /*            0 0 20px #fff,*/
    /*            0 0 40px #0ff,*/
    /*            0 0 80px #0ff,*/
    /*            0 0 90px #0ff,*/
    /*            0 0 100px #0ff,*/
    /*            0 0 150px #0ff;*/
    /*}*/

    .neon {
        color: #fff;
        text-shadow:
                0 0 5px #fff,
                0 0 10px #fff,
                0 0 20px #fff,
                0 0 40px #0ff,
                0 0 80px #0ff,
                0 0 90px #0ff,
                0 0 100px #0ff,
                0 0 150px #0ff;
        animation: glow 500ms infinite alternate;
    }

    @keyframes glow {
        0% {
            text-shadow:
                    0 0 5px #fff,
                    0 0 10px #fff,
                    0 0 20px #fff,
                    0 0 40px #0ff,
                    0 0 80px #0ff,
                    0 0 90px #0ff,
                    0 0 100px #0ff,
                    0 0 150px #0ff;
        }
        100% {
            text-shadow:
                    0 0 10px #fff,
                    0 0 20px #fff,
                    0 0 30px #fff,
                    0 0 60px #0ff,
                    0 0 120px #0ff,
                    0 0 130px #0ff,
                    0 0 140px #0ff,
                    0 0 200px #0ff;
        }
    }
</style>
