<template>
    <div style="background-color: #F6F6F6; overflow: hidden">
        <div class="py-7"></div>
        <div class="pa-7" style="overflow:hidden;">

            <div class="text-center pb-8 font-weight-bold" style="font-size: 32px; letter-spacing: -2px">
                이런 학생들에게 <br/> 아이비에드가 꼭 필요해요
            </div>
            <div class="center-horizontally" >
                <section class="discussion" style="max-width: 800px">
                    <div class="bubble sender last pa-4 font-weight-medium"
                         style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;;">AP 과제를 혼자 했더니 시간이 너무 오래걸려요 😢
                    </div>

                    <div class="bubble recipient first pa-3"
                         style="color: white; box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">학교 선생님 수업만 들어서 이해가 잘 안가요
                        😞
                    </div>

                    <div class="bubble sender last pa-4 font-weight-medium"
                         style="box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;;">영어 (English Literature) 는 정말 잘 하는 사람을
                        구분하지도, 찾지도 못하겠어요 😟
                    </div>

                    <div class="bubble recipient first pa-3"
                         style="color: white; box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;">SAT
                        공부를 미리 하고 싶어서 오래 봐줄 사람이 필요해요 😣
                    </div>


                </section>
            </div>
            <div class="pb-10"></div>
        </div>
        <div class="" style="padding: 70px"></div>
        <v-row>
            <v-col cols="12">
                <div style="width: 100%; height: 100px; background-color: #181818; border-top-left-radius: 110px; border-top-right-radius: 120px; ; border-bottom: 0; ; overflow: hidden"></div>
            </v-col>

        </v-row>
        <div class="py-6"></div>
        <div style="background-color: #181818" class="text-center font-weight-bold">

            <!--                                <div style="width: 100%;  height: 600px;background: white;-->
            <!--                    -moz-border-radius: 100px / 50px;-->
            <!--                    -webkit-border-radius: 100px / 178px;-->
            <!--                    border-radius: 694px / 208px;-->
            <!--                    z-index: 100;-->
            <!--                    position: relative;-->
            <!--                    bottom: 55px;-->
            <!--                left: -100px">-->
            <!--                                </div>-->

            <div style="letter-spacing: -2px; font-size: 30px; color: white; margin-top: -60px" class="px-15">
                내 잠재력을 깨워줄 <br/>스마트 유학 과외를 만나보세요
            </div>
            <div class="center-horizontal">
                <div style="max-width: 750px" class="hidden-sm-and-down">
                    <v-img style="" src="../../assets/vennDiagram.png"/>
                    <!--                <v-img :src="'https://i.postimg.cc/Pqrd9SVj/1669910147286.png'"></v-img>-->
                </div>
                <div style="max-width: 470px" class="hidden-md-and-up">
                    <v-img style="" src="../../assets/vennDiagram.png"/>
                    <!--                <v-img :src="'https://i.postimg.cc/Pqrd9SVj/1669910147286.png'"></v-img>-->
                </div>

            </div>
            <!--            <div class="circle" style="margin-left: 149px"></div>-->
            <!--   mobile     -->
            <div class="hidden-md-and-up">
                <v-card class="ml-7 mr-1 text-left pa-2 font-weight-bold rounded-lg" color="#1F1F1F" elevation="10">
                    <div style="color: white; font-size: 18px"><span style="color: #C2922C; font-size: 21px">① </span>
                        실력이 보장된
                    </div>
                    <div style="color: #C2922C; font-size: 18px" class="ml-7">세계 최고 수준의 선생님</div>
                    <div class="py-2"></div>
                    <div style="font-size: 15px" class="ml-7 font-weight-medium">
                        <div style="color: white"> 6단계로 검증된 미국 아이비리그 및</div>
                        <div style="color: white">최고 명문대 출신으로만 구성된 선생님</div>
                    </div>
                    <div class="pb-2"></div>
                </v-card>
                <div class="py-1"></div>
                <v-card class="ml-7 mr-1 text-left pa-2 font-weight-bold rounded-lg" color="#1F1F1F" elevation="10">
                    <div style="color: white; font-size: 18px"><span style="color: #C2922C; font-size: 21px">② </span>
                        1:1 초밀착
                    </div>
                    <div style="color: #C2922C; font-size: 18px" class="ml-7">성적 향상 프로그램</div>
                    <div class="py-2"></div>
                    <div style="font-size: 15px" class="ml-7 font-weight-medium">
                        <div style="color: white">학습 진도와 수준, 성향, 성격에 맞춘</div>
                        <div style="color: white">개인별 학습 플랜 제공</div>
                    </div>
                    <div class="pb-2"></div>
                </v-card>
                <div class="py-1"></div>
                <v-card class="ml-7 mr-1 text-left pa-2 font-weight-bold rounded-lg" color="#1F1F1F" elevation="10">
                    <div style="color: white; font-size: 18px"><span style="color: #C2922C; font-size: 21px">③ </span>
                        모르는 것은 알 때까지 Q&A
                    </div>
                    <div style="color: #C2922C; font-size: 18px" class="ml-7">24/7 Q&A</div>
                    <div class="py-2"></div>
                    <div style="font-size: 15px" class="ml-7 font-weight-medium">
                        <div style="color: white">수업 후에도 미국 최상위권 명문대 선생님과</div>
                        <div style="color: white">1:1 로 질문 답변</div>
                    </div>
                    <div class="pb-2"></div>
                </v-card>
                <div class="py-5"></div>
                <div class="">
                    <div class="text-left"
                         style="margin-bottom: -40px !important; color: gray;content: '\201C'; font-size: 50px; font-weight: bold; padding-left: 50px">
                        &#8220;
                    </div>
                    <div style="font-size: 20px; color: white" class="font-weight-bold">
                        성적이 오를 수 밖에 없는<br/>
                        아이비에드만의 스마트 과외
                    </div>
                    <div style="color: gray; content: '\201D'; font-size: 50px; font-weight: bold; padding-right: 40px; margin-top: -20px"
                         class="text-right">&#8221;
                    </div>
                </div>
                <div class="py-7"></div>
            </div>

            <!--   desktop    -->
            <div class="hidden-sm-and-down ">
                <div class="center-horizontal">
                <v-card class="ml-7 mr-1 text-left pa-2 font-weight-bold rounded-lg" color="#1F1F1F" style="width: 600px" elevation="10">
                    <div style="color: white; font-size: 18px;" >
                        <span style="color: #C2922C; font-size: 21px">① </span>
                        실력이 보장된
                    </div>
                    <div style="color: #C2922C; font-size: 18px" class="ml-7">세계 최고 수준의 선생님</div>
                    <div class="py-2"></div>
                    <div style="font-size: 15px" class="ml-7 font-weight-medium">
                        <div style="color: white"> 6단계로 검증된 미국 아이비리그 및</div>
                        <div style="color: white">최고 명문대 출신으로만 구성된 선생님</div>
                    </div>
                    <div class="pb-2"></div>
                </v-card>
                <div class="py-1"></div>
                <v-card class="ml-7 mr-1 text-left pa-2 font-weight-bold rounded-lg" color="#1F1F1F" style="width: 600px" elevation="10">
                    <div style="color: white; font-size: 18px"><span style="color: #C2922C; font-size: 21px">② </span>
                        1:1 초밀착
                    </div>
                    <div style="color: #C2922C; font-size: 18px" class="ml-7">성적 향상 프로그램</div>
                    <div class="py-2"></div>
                    <div style="font-size: 15px" class="ml-7 font-weight-medium">
                        <div style="color: white">학습 진도와 수준, 성향, 성격에 맞춘</div>
                        <div style="color: white">개인별 학습 플랜 제공</div>
                    </div>
                    <div class="pb-2"></div>
                </v-card>
                <div class="py-1"></div>
                <v-card class="ml-7 mr-1 text-left pa-2 font-weight-bold rounded-lg" color="#1F1F1F" style="width: 600px;" elevation="10">
                    <div style="color: white; font-size: 18px"><span style="color: #C2922C; font-size: 21px">③ </span>
                        모르는 것은 알 때까지 Q&A
                    </div>
                    <div style="color: #C2922C; font-size: 18px" class="ml-7">24/7 Q&A</div>
                    <div class="py-2"></div>
                    <div style="font-size: 15px" class="ml-7 font-weight-medium">
                        <div style="color: white">수업 후에도 미국 최상위권 명문대 선생님과</div>
                        <div style="color: white">1:1 로 질문 답변</div>
                    </div>
                    <div class="pb-2"></div>
                </v-card>
                <div class="py-5"></div>
                </div>
                <div class="center-horizontal">
                <div class="" style="width: 500px">
                    <div class="text-left"
                         style="margin-bottom: -40px !important; color: gray;content: '\201C'; font-size: 50px; font-weight: bold; padding-left: 50px">
                        &#8220;
                    </div>
                    <div style="font-size: 25px; color: white" class="font-weight-bold">
                        성적이 오를 수 밖에 없는<br/>
                        아이비에드만의 스마트 과외
                    </div>
                    <div style="color: gray; content: '\201D'; font-size: 50px; font-weight: bold; padding-right: 40px; margin-top: -20px"
                         class="text-right">&#8221;
                    </div>
                </div>
                </div>
                <div class="py-7"></div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "SpeechBubbleTargetStudents",
        components: {}
    }
</script>

<style scoped>
    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .circle {
        background: #48413D;
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }

    html {
        font-family: 'PT Sans', Georgia, serif;
    }

    .discussion {
        /*max-width: 600px;*/
        margin: 0 auto;

        display: flex;
        flex-flow: column wrap;
    }

    .discussion > .bubble {
        border-radius: 1em;
        padding: 0.25em 0.75em;
        margin: 0.0625em;
        max-width: 50%;
    }

    .discussion > .bubble.sender {
        align-self: flex-start;
        background-color: white;
        color: black;
    }

    .discussion > .bubble.recipient {
        align-self: flex-end;
        background-color: #1F1F1F;
    }

    .discussion > .bubble.sender.first {
        border-bottom-left-radius: 0.1em;
    }

    .discussion > .bubble.sender.last {
        border-top-left-radius: 0.1em;
    }

    .discussion > .bubble.sender.middle {
        border-bottom-left-radius: 0.1em;
        border-top-left-radius: 0.1em;
    }

    .discussion > .bubble.recipient.first {
        border-bottom-right-radius: 0.1em;
    }

    .discussion > .bubble.recipient.last {
        border-top-right-radius: 0.1em;
    }

    .discussion > .bubble.recipient.middle {
        border-bottom-right-radius: 0.1em;
        border-top-right-radius: 0.1em;
    }
</style>
