<template>
    <div>
        <v-card
                class="mx-auto primary--text pa-2 ma-2 hidden-sm-and-down"
                max-width="750"
                elevation="0"
                color=""
                height="1000"
        >
            <v-card-title class="justify-center mt-15"
            >
                <!--                    <h1>상품 정보</h1>-->
                <span
                        :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                >

            </span>
            </v-card-title>
            <v-card outlined class="rounded-lg ma-5 pa-5 hidden-sm-and-down">
                <v-card-title class="text-h6 pa-3 black--text justify-center font-weight-bold primary--text">
                    Bootcamp 신청이 완료 되었습니다!

                </v-card-title>
                <div class="text-center mt-8">
                    <strong class="text-decoration-underline">영업일 기준 1~2일내로</strong> 연락 드리도록 하겠습니다.
                    <p class=" text-h8">
                        <br/>
                        바로 상담을 원하시면 <br/>
                        카카오톡 혹은 전화로 문의 주세요!
                    </p>

                </div>
                <div class="text-center">
                    <v-btn x-large :href="'https://pf.kakao.com/_NxlSSb/chat'" width="250px" color="#ffe812"
                           class="rounded-xl text-center my-5 black--text">
                        <v-icon color="#ffe812" size="40">$vuetify.icons.kakao
                        </v-icon> &nbsp; 톡상담
                    </v-btn>
                    <br/>
                    <v-btn target="_blank" x-large width="250px" :href="'tel:070-8970-5223'" color="" elevation="2"
                           class="rounded-xl text-center my-5 black--text">
                        <v-icon color="" size="40">mdi-face-agent
                        </v-icon> &nbsp; 전화상담
                    </v-btn>
                    <br/>
                    <v-btn target="_blank" x-large width="250px" to="/" color="secondary" elevation="2"
                           class="rounded-xl text-center my-5 black--text">
                        <div class="white--text">
                            <v-icon color="" size="40">mdi-home-outline
                            </v-icon> &nbsp; 홈으로
                        </div>
                    </v-btn>
                    <br/>

                </div>

            </v-card>

        </v-card>

        <v-card
                class="mx-auto ma-10 pa-5 py-15 primary--text hidden-md-and-up"
                max-width="700"
                flat
        >
            <v-card-title class=" justify-center" style="font-size: 22px">

                <span class="text-center font-weight-bold"> Bootcamp <br/> 신청이 완료 되었습니다!</span>
            </v-card-title>

            <!--                    <v-list-item-title class="text-h6 mb-1">-->
            <!--                        입력하신 이메일로 인증 후 로그인 하실 수 있습니다.-->
            <!--                    </v-list-item-title>-->
            <!--                    <v-list-item-title class="text-h7 mb-1">-->
            <!--                        로그인 후에 아이비에드를 이용해주세요.-->
            <!--                    </v-list-item-title>-->
            <div class="text-center black--text" style="font-size: 14px">
                신청서 검토 후 <br/>
                <strong class="text-decoration-underline">영업일 기준 1~2일내로</strong> 연락 드리도록 하겠습니다.
                <div class="py-2"></div>
                <p class=" text-h8">
                    바로 상담을 원하시면 <br/>
                    카카오톡 혹은 전화로 문의 주세요!
                </p>
                <div class="justify-center">
                    <v-btn x-large :href="'https://pf.kakao.com/_NxlSSb/chat'" width="250px" color="#ffe812"
                           class="rounded-xl text-center my-5 black--text">
                        <v-icon color="#ffe812" size="40">$vuetify.icons.kakao
                        </v-icon> &nbsp; 톡상담
                    </v-btn>
                    <br/>
                    <v-btn target="_blank" x-large width="250px" :href="'tel:070-8970-5223'" color="" elevation="2"
                           class="rounded-xl text-center my-5 black--text">
                        <v-icon color="" size="40">mdi-face-agent
                        </v-icon> &nbsp; 전화상담
                    </v-btn>
                    <br/>
                    <v-btn target="_blank" x-large width="250px" to="/" color="secondary" elevation="2"
                           class="rounded-xl text-center my-5 black--text">
                        <div class="white--text">
                            <v-icon color="" size="40">mdi-home-outline
                            </v-icon> &nbsp; 홈으로
                        </div>
                    </v-btn>
                    <br/>

                </div>
            </div>
            <!--            <div class="text-center">-->
            <!--                <v-btn to="/login"-->
            <!--                       x-large-->
            <!--                       color="secondary"-->
            <!--                       class="font-weight-bold"-->
            <!--                       width="40%"-->
            <!--                >-->
            <!--                    로그인-->
            <!--                </v-btn>-->
            <!--            </div>-->
        </v-card>
        <!-- 전환페이지 설정 -->
        <script type="application/javascript" defer src="//wcs.naver.net/wcslog.js"></script>
        <script type="application/javascript">
            var _nasa = {};
            if (window.wcs) _nasa["cnv"] = wcs.cnv("4", "1"); // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
        </script>
        <!-- 공통 적용 스크립트 , 모든 페이지에 노출되도록 설치. 단 전환페이지 설정값보다 항상 하단에 위치해야함 -->
        <script type="application/javascript" src="//wcs.naver.net/wcslog.js"></script>
        <script type="application/javascript">
            if (!wcs_add) var wcs_add = {};
            wcs_add["wa"] = "s_3a5c096786f8";
            if (!_nasa) var _nasa = {};
            if (window.wcs) {
                wcs.inflow();
                wcs_do(_nasa);
            }
        </script>


    </div>

</template>

<script>
    export default {
        name: "BootcampRegisterConfirmPage",
        created() {
        }
    }
</script>

<style scoped>

</style>
