export const apChemistryProblemData ={
    bootcamp:[{
        classId: 2, class: {
            imgUrl: "https://i.postimg.cc/nLcmjGxp/maxresdefault.jpg",
            imgAlt: "IVYED AP Chemistry",
            title: "AP Chemistry, 오직 5점 달성을 위한 문제풀이반",
            subheading: "Purdue Electronic Engineering 전공",
            description: "AP Chemistry 문제풀이 마스터하고 5점 받자!",
            lectureStyle: "100% 온라인 강의",
            program: "단기반 신청 가능",
            necessity: ["AP Chemistry 개념숙지는 다 됐는데, 문제푸는것에 어려움을 겪고 있나요?", "AP Chemistry의 5점을 무난하게 얻는 문제풀이법을 배우고싶나요?", "어려운 문제만 수업을 받아보고싶나요?"],
            introduction: {
                title: "AP Chemistry 문제풀이가 어렵다면?",
                items: ["- 단기간으로 AP Chemistry 5점을 달성하는 반", "- 실제 연구와 직접 관련 과목을 가르치고 있는 선생님의 문제풀이 강의", "- 오직, 고급과정만을 바라는 학생들을 위한 AP Chemistry 5점 문제풀이 학습", "- 개념을 응용해서 문제를 풀어 변별력있는 문제에 대비할 수 있는 능력 향상"]
            },
            whyTakeThisCourse: [{
                title: "오직, 고급과정이 필요한 학생들을 위한 강의",
                info: ["기초부터 차근차근X, 문제로 시작해서 문제로 끝나는 강의"]
            }, {
                title: "실제 연구를 수행하고, 교육경력만 15년차인 선생님의 강의를 통한 직접적인 지도",
                info: []
            }, {title: "직접 제작한 AP Chemistry 문제풀이 학습 자료 제공", info: []}, {
                title: "AP Chemistry의 어려운 문제 유형 대비, 유형별 실습 기회",
                info: []
            }],
            afterTakingCourse: [{
                title: "AP Chemistry 5점을 위한 문제 해결 능력 향상",
                info: ["고급 수준의 문제풀이 기술을 습득해 문제 유형별 대비를 할 수 있을 것입니다."]
            }, {
                title: "Chemistry의 어려운 주제에 대한 해결",
                info: ["어려운 파트에서 문제 쉽게 해결이 가능할 것입니다."]
            }, {
                title: "AP Chemistry 공부에 쏟는 시간을 줄일 수 있습니다.",
                info: ["효과적인 AP Chemistry 학습이 가능할 것입니다."]
            }, {title: "안정적인 AP Chemistry 5점", info: []}],
            teacher: {
                userId: 124,
                name: "Mr. Shin",
                imgUrl: "https://i.postimg.cc/SNnvtwqv/yt-picture.png",
                schoolLogoImgUrl: "https://i.postimg.cc/SQDg3Bzy/purdue-university4128-logowik-com.webp",
                experience: ["Purdue Electronic Engineering 전공 (박사)", "교육경력 13년", "Purdue에서 연구, Lab class instructor"],
                description: "미국에서 대학/석사를 졸업하고 지금 현재는 Purdue 대학교에서 전자공학과 박사과정을 하고 있습니다. \n오전에는 학부생들을 가르치거나 연구실에서 논문,연구를 하고 오후에는 도움이 필요한 학생들을 가르치고 있습니다. \nAP 과목들을 가르친 경력은 13년차이고 현재도 AP Chem 과목과 이공계열 희망 학생들을 많이 지도하고 있습니다. \n미국에서 거주하고 미국 고등학교 학생들과 매일 대화하고 피드백을 받으며 최신 AP 성향과 다년간 많은 자료들을 가지고 수업해오고 있습니다. \n제가 매일 수업 하면서 느끼는 점은 학생들은 매일 하루하루 너무 바쁜 하루를 보내고 있습니다 제 클래스를 들으면서 학생들이 필요한 내용들만 꼭 알고 문제푸는 요령을 익혀서 시간과 노력대비 최대한의 효율로 공부를 진행했으면 좋겠습니다. \n제 수업이 특별한 이유는 일단 너무 재밌습니다. 제 교육철학은 학생들이 배우면서 즐거움을 느끼는 것입니다. \n학생들이 즐겁게 배우고 성실히 과제를 임한다면 꼭 좋은 성적이 따라올 것이라 믿습니다. \n한달이라는 시간은 짧지만, 남은 기간동안 어떻게 공부해야하는지도 제가 수업할때 꼭 여러분께 인지시켜서 지도하겠습니다.",
                videoUrl: "https://www.youtube.com/embed/-iO6b7EB7xw?si=B9hxiGML5FH0p8ty"
            },
            course: [{
                schedule: {
                    courseName: "AP Chemistry (KST)",
                    days: "월, 목",
                    time: "19:30 ~ 21:30",
                    minutes: "120분",
                    frequency: "주 2회",
                    timezone: "KST",
                },
                objectives: {
                    korean: ["- 학생들에게 화학의 핵심 개념을 이해시키고 실제 응용에 중점을 둡니다.", "- 학생들은 질량 분광법, 전자배치, 주기율표와 핵 전하 등에 대한 이해를 향상시킵니다.", "- 화학 반응, 용액, 열역학 등 다양한 주제를 다룰 수 있게 됩니다.", "- 학생들은 AP 화학 시험에대비하고, 미래의 화학 및 관련 분야에서 공부에 강력한 기초를\n다지게 됩니다.", ""],
                    english: ["- We focus on helping students understand the core concepts of chemistry and emphasize\npractical applications.", "- Students will enhance their understanding of mass spectrometry, electron configuration,\nperiodic table, and nuclear charge.", "- They will be able to cover various topics such as chemical reactions, solutions, and\nthermodynamics.", "- Students will prepare for the AP Chemistry exam and build a strong foundation for studying\nchemistry and related fields in the future.", ""]
                },
                curriculum: [{
                    name: "Mass Spectrometry & Electromagnetic Radiation",
                    item: {content: [""]}
                }, {
                    name: "Electron Configuration",
                    item: {content: [""]}
                }, {
                    name: "The Periodic Table & Effective Nuclear Charge",
                    item: {content: [""]}
                }, {
                    name: "Periodic Table Effective Nuclear Charge",
                    item: {content: [""]}
                }, {
                    name: "Periodic Trends & Photoelectron Spectroscopy",
                    item: {content: [""]}
                }, {name: "Ionic Bonding", item: {content: [""]}}, {
                    name: "Covalent Bonding",
                    item: {content: [""]}
                }, {name: "Advanced Bonding", item: {content: [""]}}, {
                    name: "Intermolecular Forces & Solids",
                    item: {content: [""]}
                }, {name: "Mole Calculations", item: {content: [""]}}, {
                    name: "Solutions, Mixtures & Separations",
                    item: {content: [""]}
                }, {name: "Chemical Reactions", item: {content: [""]}}, {
                    name: "Stoichiometry",
                    item: {content: [""]}
                }, {name: "Gases", item: {content: [""]}}, {
                    name: "Thermochemistry",
                    item: {content: [""]}
                }, {name: "Kinetics", item: {content: [""]}}, {
                    name: "Equilibrium",
                    item: {content: [""]}
                }, {name: "Acid & Bases", item: {content: [""]}}, {
                    name: "Buffers & Titrations",
                    item: {content: [""]}
                }, {name: "Ksp & Solubility", item: {content: [""]}}, {
                    name: "Thermodynamics",
                    item: {content: [""]}
                }, {name: "Electrochemistry", item: {content: [""]}}, {name: "Review", item: {content: ["", ""]}}]
            }, {
                schedule: {
                    courseName: "AP Chemistry (PST)",
                    days: "월, 목",
                    time: "19:30 ~ 21:30",
                    minutes: "120분",
                    frequency: "주 2회",
                    timezone: "PST",
                }, objectives: {}, curriculum: []
            }],
            review: [{
                person: "미국 보딩스쿨 11학년",
                experience: "“수업 시간 이외에도 선생님께서 추후 관리를 해주신 게 많이 도움이 되었어요”",
                personImgUrl: "https://i.postimg.cc/7Yr3LDJ1/avatar4.png"
            }, {
                person: "두바이 보딩스쿨 10학년",
                experience: "“귀찮을 수도 있으셨을 것 같은데 자주 질문해도 친절하게 대답해 주셨어요”",
                personImgUrl: "https://i.postimg.cc/4dbjWxL1/avatar1.png"
            }, {
                person: "한국 국제학교 11학년",
                experience: "“선생님과 같이했던 문제를 풀고 모르는 문제는 해설해 주시는, 특히 모르는 문제 비슷한 문제들을 엄청 자료로 주셔서 많은 도움이 되었어요”",
                personImgUrl: "https://i.postimg.cc/gc8hQ5tx/avatar2.png"
            }]
        }
    }]
}
