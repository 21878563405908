<template>
    <div>


        <!--모바일-->
        <div class="hidden-sm-and-up top-section " style="">
            <div class="">
                <div class="pt-3 "></div>
                <!--            <div class="pt-15 "></div>-->
                <!--            <div class="pt-15 "></div>-->
                <div class=" transparent-dark-background-mobile mx-5 mt-4">
                    <div class="py-10">
                        <h2 style="letter-spacing: 0px; font-size: 24px; " class="">
                            <span style="">IVYED</span>는 유학 시장의 정보 <br/> 불균형이나 비대칭을 활용해 <br/>
                            이득을 추구하지 않습니다.


                            <br/>
                            <br/>
                            우리는 학생들의 <br/>성공을 추구합니다.


                        </h2>
                        <h2 class=" font-weight-bold" style="font-size: 30px">___</h2>
                        <div class="pt-2"></div>

                        <div class="" style="font-size: 15px">
                            IVYED는 ‘유학 시장의 정보 불균형과 비대칭을 통해 거짓된 교육을 제공하지 않고 진실 된 유학 교육을 제공한다’라는 이념 아래, 학생들의 성공을 추구하기 위해
                            검증된
                            선생님들을 유학 교육이 필요한 아이들에게 제공하고 있습니다.
                            <div class="py-1"></div>
                            <div>IVYED는 정직한 유학 교육을 제공하고 있습니다.</div>

                        </div>
                    </div>
                </div>


                <div class="py-2"></div>
            </div>
        </div>

        <!-- 아이패드 이상-->
        <div class="hidden-xs-only top-section-2" style="width: 100%">


            <v-row>
                <v-col cols="1" class="">
                </v-col>
                <v-col class="">
                    <!--                    <div class="background-container">-->
                    <div class=" center-vertical transparent-dark-background" style="max-width: 400px; ">
                        <div class="pt-3 "></div>
                        <div class="py-10">
                            <h2 style="letter-spacing: 0px; font-size: 25px" class="">
                                IVYED는 유학 시장의 <br/> 정보 불균형이나 비대칭을 활용해<br/> 이득을 추구하지 않습니다.

                                <br/>
                                <br/>
                                우리는 학생들의 성공을 추구합니다.
                            </h2>
                            <h2 class=" font-weight-bold" style="font-size: 30px">___</h2>
                            <div class="pt-2"></div>

                            <div class="" style="font-size: 16px">
                                IVYED는 ‘유학 시장의 정보 불균형과 비대칭을 통해 거짓된 교육을 제공하지 않고 진실 된 유학 교육을 제공한다’라는 이념 아래, 학생들의 성공을 추구하기
                                위해 검증된
                                선생님들을 유학 교육이 필요한 아이들에게 제공하고 있습니다.
                                <div class="py-2"></div>
                                <div>IVYED는 정직한 유학 교육을 제공하고 있습니다.</div>

                            </div>
                        </div>
                        <div class="py-2"></div>

                        <!--                    </div>-->
                    </div>
                </v-col>
            </v-row>


        </div>

        <BrandStory1></BrandStory1>
        <BrandStory2></BrandStory2>
        <BrandStory3></BrandStory3>

    </div>
</template>

<script>

    import BrandStory1 from "./BrandStory/BrandStory1";
    import BrandStory2 from "./BrandStory/BrandStory2";
    import BrandStory3 from "./BrandStory/BrandStory3";

    export default {
        name: "BrandStory",
        components: {
            BrandStory3,
            BrandStory2,
            BrandStory1
        }
    }
</script>

<style scoped>
    .first-picture {
        background-image: url("../../../assets/brand/1moblie.png");

    }

    .top-section {
        /*background-image: url("../../../assets/IVYED 버튼.png");*/
        /*background-image: url("../../../assets/graduation-gfcc9af869_1280.jpg");*/
        background-image: url("../../../assets/BrandStoryMobileBg.png");
        background-size: 100%;
        /*background-size: contain;*/
        /*background-color: #f6f7f7;*/
        /*width: 100%;*/
        background-repeat: no-repeat;
        /*max-width: 100%;*/
        /* You can adjust the percentage value as needed */

        /*height: 800px;*/
    }

    /*.top-section-2 {*/
    /*    !*height: 700px;*!*/

    /*    !*background-image: url("../../../assets/IVYED 버튼.png");*!*/
    /*    !*background-image: url("../../../assets/graduation-gfcc9af869_1280.jpg");*!*/
    /*    background-image: url("../../../assets/브랜드 스토리 데스크탑 버전 1.png");*/
    /*    background-size: 100%;*/
    /*    !*background-size: contain;*!*/
    /*    !*background-color: #f6f7f7;*!*/
    /*    !*width: 100%;*!*/
    /*    background-repeat: no-repeat;*/
    /*    !*max-width: 100%;*!*/
    /*    !* You can adjust the percentage value as needed *!*/

    /*    !*height: 800px;*!*/
    /*}*/

    /*.transparent-dark-background {*/
    /*    background-color: rgba(162, 9, 22, 0.6);*/

    /*    !*background-color: rgba(0, 0, 0, 0.5); !* Here, '0.5' represents the alpha value (50% opacity) *!*!*/
    /*    !*padding: 20px;*!*/
    /*    !*padding-top: 20px;*!*/
    /*    !*margin-top: 20px;*!*/
    /*    !*height: 450px;*!*/
    /*    color: white; !* For better visibility of the text, set the text color to white *!*/
    /*}*/
    .top-section-2 {
        background-image: url("../../../assets/브랜드 스토리 데스크탑 버전 1.png");
        background-size: cover; /* Cover the entire container */
        background-repeat: no-repeat;
        height: 600px;
    }

    /*.background-container {*/
    /*    position: relative;*/
    /*    padding-top: 100%; !* 4:3 aspect ratio, adjust as needed *!*/
    /*}*/

    .transparent-dark-background {
        /*position: absolute;*/
        /*top: 0;*/
        /*left: 0;*/
        /*right: 0;*/
        /*bottom: 0;*/
        height: 600px;
        background-color: rgba(162, 9, 22, 0.6);
        /*padding: 5%;*/
        padding-left: 22px;
        padding-right: 22px;
        color: white;

        /* Use relative font size for the inner content */
        font-size: 2vw; /* Adjust as needed */
    }

    .transparent-dark-background-mobile {
        /*position: absolute;*/
        /*top: 0;*/
        /*left: 0;*/
        /*right: 0;*/
        /*bottom: 0;*/
        height: 500px;
        background-color: rgba(162, 9, 22, 0.6);
        /*padding: 5%;*/
        padding-left: 22px;
        padding-right: 22px;
        color: white;

        /* Use relative font size for the inner content */
        font-size: 2vw; /* Adjust as needed */
    }

    .each-title {
        font-size: 22px !important;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .intro {
        display: flex;
        background: #fff3e2;
        justify-content: space-evenly;
        padding-top: 80px;
        height: 500px;
    }

    .introDescription {
        font-size: 30px;
        color: #632b2b;
        padding-top: 120px;
    }

    .introDescription p {
        font-size: 60px;
        color: #632b2b;
        font-weight: 900;
    }

    .guide1 {
        display: flex;
        background: #ffd4a9;
        justify-content: space-evenly;
        padding-top: 80px;
        height: 500px;
        overflow: hidden;
    }

    .guide1Description h1 {
        font-size: 48px;
        color: #632b2b;
        padding: 120px 0px 20px 0px;
    }

    .guide1Description div {
        background: #fff3e2;
        color: #632b2b;
        font-size: 20px;
        padding: 10px;
        border-radius: 10px;
    }

    .guide2 {
        display: flex;
        /*background: #fff3e2;*/
        justify-content: space-evenly;
        /*padding-top:80px;*/
        /*height: 500px;*/
        overflow: hidden;
    }

    .guide2Description h1 {
        font-size: 48px;
        /*color:#632b2b;*/
        /*padding: 120px 0px 20px 0px;*/
    }

    .guide2Description div {
        /*background:#ffd4a9 ;*/
        /*color:#632b2b;*/
        /*font-size:20px;*/
        /*padding:10px;*/
        /*border-radius:10px;*/
    }

    footer {
        background: #ffd4a9;
        color: #632b2b;
        padding: 10px;
        text-align: right;
        font-weight: 900;
    }

    /* responsiveness */
    @media screen and (max-width: 500px) {
        .intro {
            display: block;
            padding-top: 10px;
            height: auto;
        }

        .introImage {
            width: 100%;
        }

        .introDescription {
            padding: 30px 0px 0px 20px;
        }

        .guide1 {
            display: block;
            padding-top: 10px;
            height: auto;
        }

        .guide1Image {
            width: 100%;
        }

        .guide1Description {
            padding: 20px;
        }

        .guide1Description h1 {
            padding: 30px 0px;
        }

        .guide2 {
            display: block;
            /*padding-top:10px;*/
            height: auto;
        }

        .guide2Image {
            width: 100%;
        }

        .guide2Description {
            padding: 20px;
        }

        .guide2Description h1 {
            padding: 30px 0px;
        }
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
