<template>
    <div>
        <div class="reveal">

            <div class="hidden-md-and-down mt-15">
                <div class="mt-15"></div>

                <div class="center-horizontal">
                    <div class="circle-with-checkmark-three text-center center-vertical">
                        <v-icon large color="primary" class="font-weight-bold">mdi-check</v-icon>
                    </div>
                    <div class="py-2"></div>
                    <div class="px-4 pt-4 text-gradient-3" style="font-size: 34px;  font-weight: bold">
                        무분별한 유학생 선생님이 아니에요!
                    </div>
                    <div class="py-2"></div>
                    <div class="px-4 pt-1 font-weight-bold text-center" style="font-size: 22px;  font-weight: normal">
                        IVYED의 검증을 통해 <div style="" class="font-weight-bold"> 잘 가르치는 선생님만 연결해드려요.</div>
                    </div>
                    <div class="py-3"></div>
                    <div class="py-3"></div>

                </div>

                <div class="pa-7" style="background-color: #f3eaeb; border-radius: 10px; width: 1200px; height: 320px">
                    <div class="px-5 mx-10  center-horizontal" style="  border-radius: 8px; ">
                        <h3 class="py-4" style="font-size: 25px"><span
                                style="color: #A20916; font-weight: bold">IVYED</span>만의 철저한 선생님 검증
                            단계 </h3>
                    </div>
                    <div class="py-5"></div>
                    <div class="center-horizontal">
                        <v-row class="">
                            <div style="background-color: white; width: 180px; border-radius: 15px"
                                 class="pa-3 ml-4 mb-10 text-center ">
                                <div class="pa-3 center-horizontal ">
                                    <div style="padding-top: 8px"></div>
                                    <div class="teacher-verify-circle-desktop  center-vertical"
                                         style="background-color: rgba(162, 9, 22, 0.2); ">
                                        1
                                    </div>
                                    <div class="py-2"></div>
                                    <div style="font-size: 16px">지원서류 <span style="font-weight: bold">평가</span></div>
                                </div>
                            </div>

                            <span class="horizontal-line"></span>
                            <span class="px-1"></span>
                            <span class="horizontal-line"></span>
                            <span class="px-1"></span>
                            <span class="horizontal-line"></span>
                            <div style="background-color: white; width: 180px; border-radius: 15px"
                                 class="pa-3  mb-10 text-center">
                                <div class="pa-3 center-horizontal">
                                    <div style="padding-top: 8px"></div>

                                    <div class="center-horizontal center-vertical teacher-verify-circle-desktop"
                                         style=" background-color: rgba(162, 9, 22, 0.3); ">
                                        2
                                    </div>
                                    <div class="py-2"></div>
                                    <div style="font-size: 16px">영어 능력 <span style="font-weight: bold">평가</span></div>
                                    <div class="py-2"></div>
                                </div>
                            </div>
                            <span class="horizontal-line"></span>
                            <span class="px-1"></span>
                            <span class="horizontal-line"></span>
                            <span class="px-1"></span>
                            <span class="horizontal-line"></span>
                            <div style="background-color: white; width: 180px; border-radius: 15px"
                                 class="pa-3  mb-10 text-center">
                                <div class="pa-3 center-horizontal">
                                    <div style="padding-top: 8px"></div>

                                    <div class="center-horizontal center-vertical teacher-verify-circle-desktop"
                                         style="background-color: rgba(162, 9, 22, 0.4);">
                                        3
                                    </div>
                                    <div class="py-2"></div>
                                    <div style="font-size: 16px">시범 <span style="font-weight: bold">수업</span></div>
                                    <div class="py-2"></div>
                                </div>
                            </div>
                            <span class="horizontal-line"></span>
                            <span class="px-1"></span>
                            <span class="horizontal-line"></span>
                            <span class="px-1"></span>
                            <span class="horizontal-line"></span>
                            <div style="background-color: white; width: 180px; border-radius: 15px"
                                 class="pa-3  mb-10 text-center">
                                <div class="pa-3 center-horizontal">
                                    <div style="padding-top: 8px"></div>

                                    <div class="teacher-verify-circle-desktop center-vertical "
                                         style="  background-color: rgba(162, 9, 22, 0.6); color: white !important;">
                                        4
                                    </div>
                                    <div class="py-2"></div>
                                    <div style="font-size: 16px">교육 <span style="font-weight: bold">이수</span></div>
                                    <div class="py-2"></div>
                                </div>

                            </div>
                            <span class="horizontal-line"></span>
                            <span class="px-1"></span>
                            <span class="horizontal-line"></span>
                            <span class="px-1"></span>
                            <span class="horizontal-line"></span>
                            <div style="background-color: white; width: 180px; border-radius: 15px"
                                 class="pa-3 mb-10 mr-4 text-center">
                                <div class="pa-3 center-horizontal">
                                    <div style="padding-top: 8px"></div>

                                    <div class="center-horizontal center-vertical teacher-verify-circle-desktop"
                                         style="background-color: rgba(162, 9, 22, 0.9); color: white !important;">
                                        5
                                    </div>
                                    <div class="py-2"></div>
                                    <div style="font-size: 16px">최종 <span style="font-weight: bold">합격</span></div>
                                    <div class="py-2"></div>
                                </div>
                            </div>
                        </v-row>
                    </div>
                    <div class="py-3"></div>

                </div>
            </div>


        </div>
<!--        <div class="center-horizontal">-->
<!--            <div class="vertical-line ">-->
<!--            </div>-->
<!--            <div style="height: 5px"></div>-->
<!--        </div>-->
<!--        <div class="center-horizontal">-->
<!--            <div class="vertical-line ">-->
<!--            </div>-->
<!--            <div style="height: 5px"></div>-->
<!--        </div>-->
<!--        <div class="center-horizontal">-->
<!--            <div class="vertical-line ">-->
<!--            </div>-->
<!--            <div style="height: 5px"></div>-->
<!--        </div>-->
<!--        <div class="center-horizontal">-->
<!--            <div class="short-vertical-line ">-->
<!--            </div>-->
<!--        </div>-->

    </div>
</template>

<script>
    export default {
        name: "TeacherVerifyDesktop",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>


    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
    .border-highlight {
        border: 4px dashed red;
        padding: 0.01em 0.15em;
    }
    .circle-with-checkmark::before {
        content: "\003F"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: #A20916; /* Checkmark color (white in this case) */
        font-weight: bold;
    }
    .circle-with-checkmark {
        position: relative;
        width: 40px;
        height: 40px;
        /*border: 2px solid #ecced04d; !* Circle border color *!*/
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced04d; /* Circle background color (white in this case) */
    }
    .circle-with-checkmark-desktop::before {
        content: "\003F"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: TheJamsil5Bold;
        font-size: 22px;
        color: #A20916; /* Checkmark color (white in this case) */
        font-weight: bold;
    }
    .circle-with-checkmark-desktop {
        position: relative;
        width: 60px;
        height: 60px;
        /*border: 2px solid #ecced04d; !* Circle border color *!*/
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced04d; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark-three {
        position: relative;
        width: 60px;
        height: 60px;
        border: 2px solid #ecced0; /* Circle border color */
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced0; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark-three::before {
        /*content: "\2714"; !* Checkmark unicode character *!*/
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 22px;
        color: #A20916 !important; /* Checkmark color (white in this case) */
    }



    .teacher-verify-circle-desktop {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        color: #A20916;
        font-weight: bold;
        font-size: 13px;
    }
    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

</style>

