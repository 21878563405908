<template>
    <div>
            <div class="container reveal">

                <div class="text-container hidden-lg-and-up">
                    <div style="  background-color: transparent" class="text-center font-weight-bold">
                        <!--            <div class="py-15"></div>-->
                        <v-card elevation="0" class="mx-2" style="border-radius: 15px;outline: 0px solid #ecced0;background-color: transparent">
                            <div class="center-horizontal ">
                                <div class="py-7"></div>
                                <div>
                                    <img src="../../../../assets/potential1.png" alt="Grows Potential " width="80%" >
                                </div>
                                <!--                <div style="max-width: 750px" class="hidden-md-and-down">-->
                                <!--                    <v-img style="" src="../../../assets/potential1.png"/>-->
                                <!--                    &lt;!&ndash;                <v-img :src="'https://i.postimg.cc/Pqrd9SVj/1669910147286.png'"></v-img>&ndash;&gt;-->
                                <!--                </div>-->
                                <!--                <div style="max-width: 270px" class="hidden-lg-and-up">-->
                                <!--                    <v-img style="" height="50%" src="../../../assets/potential1.png"/>-->
                                <!--                    &lt;!&ndash;                <v-img :src="'https://i.postimg.cc/Pqrd9SVj/1669910147286.png'"></v-img>&ndash;&gt;-->
                                <!--                </div>-->

                            </div>

                            <div class="py-14"></div>

                            <!--                <div class="py-6"></div>-->
                            <!--                <div class="py-10"></div>-->

                        </v-card>


                    </div>

                </div>
                <div class="text-container hidden-md-and-down">

                    <div style="  background-color: transparent" class="text-center font-weight-bold">
                        <!--            <div class="py-15"></div>-->
                        <v-card elevation="0" class="mx-2" style="border-radius: 15px;outline: 0px solid #ecced0;background-color: transparent">
                            <div class="center-horizontal ">
                                <div>
                                    <img src="../../../../assets/potential1.png" alt="Grows Potential " width="80%" >
                                </div>
                                <!--                <div style="max-width: 750px" class="hidden-md-and-down">-->
                                <!--                    <v-img style="" src="../../../assets/potential1.png"/>-->
                                <!--                    &lt;!&ndash;                <v-img :src="'https://i.postimg.cc/Pqrd9SVj/1669910147286.png'"></v-img>&ndash;&gt;-->
                                <!--                </div>-->
                                <!--                <div style="max-width: 270px" class="hidden-lg-and-up">-->
                                <!--                    <v-img style="" height="50%" src="../../../assets/potential1.png"/>-->
                                <!--                    &lt;!&ndash;                <v-img :src="'https://i.postimg.cc/Pqrd9SVj/1669910147286.png'"></v-img>&ndash;&gt;-->
                                <!--                </div>-->

                            </div>

                            <div class="py-14"></div>

                            <!--                <div class="py-6"></div>-->
                            <!--                <div class="py-10"></div>-->

                        </v-card>


                    </div>

                </div>
            </div>

    </div>
</template>

<script>
    export default {
        name: "Potential2",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>
    @import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /*font-family: "Asap", sans-serif;*/
    }

    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
</style>

