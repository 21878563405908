<template>

    <div class="py-10 " style="">
        <div class="hidden-md-and-up">
        <v-card color="#993333" style="border-radius: 13px"  class="ma-5 text-center white--text">
            <div class="py-3"></div>
            <div class="font-weight-bold  " style="font-size: 22px; ">01</div>
            <div class="white--text text-center px-5 font-weight-bold" > 다년간의 노하우로<br/> SAT, AP, IB 유학 과목 GPA 완벽 대비</div>
            <div class="py-3"></div>
            <div class="px-5">내신 관리의 모든 것을 <br/> <strong>한꺼번에 그러나 컴팩트하게</strong> 끝냅니다.
                <div class="py-3">학교 수업에 맞춰서 퀴즈나 과제 대비 위주로 철저하게 학점을 관리하며 체계적인 준비가 필요한 SAT까지 풀 커버합니다.</div></div>
            <div class="py-3"></div>
        </v-card>
        <div>
            <v-card color="#993333" style="border-radius: 13px" class="ma-5 text-center white--text">
                <div class="py-3"></div>
                <div class="font-weight-bold  " style="font-size: 22px; ">02</div>
                <div class="white--text text-center px-5 font-weight-bold" >원어민 수준의 영어로 수업을 진행</div>
                <div class="py-3"></div>
                <div class="px-5">의외로 많은 시간이 투자되는 영어 공부.<br/> 낭비가 정리되고, 효과는 극대화됩니다.

                    <div class="py-3">학생 환경에 맞춰 원어민 수준의 영어로 수업이 진행되며 필요시 한국어로 보조 설명하며 진행합니다.</div></div>
                <div class="py-3"></div>
            </v-card>
        </div>
        <div>
            <v-card color="#993333" style="border-radius: 13px" class="ma-5 text-center white--text">
                <div class="py-3"></div>
                <div class="font-weight-bold  " style="font-size: 22px; ">03</div>
                <div class="white--text text-center px-5 font-weight-bold" >최상위권 입시에 필요한 확실하고 검증된 정보만 공유</div>
                <div class="py-3"></div>
                <div class="px-5">매일 조금씩 내 의지를 갉아먹던<br/> 입시에 대한 막연함이 완전히 제거됩니다.

                    <div class="py-3"> 아이비리그 출신에게 직접 경험을 토대로한 입시 원서, 교양 활동, 자소서 등 학교 생활에 대해 물어보세요.</div></div>
                <div class="py-3"></div>
            </v-card>
        </div>
        </div>
        <div class="hidden-sm-and-down center-horizontal" >
            <v-card color="#993333" style="border-radius: 13px" width="850px" class="ma-5 text-center white--text">
                <div class="py-3"></div>
                <div class="font-weight-bold  " style="font-size: 22px; ">01</div>
                <div class="white--text text-center px-5 font-weight-bold" > 다년간의 노하우로<br/> SAT, AP, IB 유학 과목 GPA 완벽 대비</div>
                <div class="py-3"></div>
                <div class="px-5">내신 관리의 모든 것을 <br/> <strong>한꺼번에 그러나 컴팩트하게</strong> 끝냅니다.
                    <div class="py-3">학교 수업에 맞춰서 퀴즈나 과제 대비 위주로 철저하게 학점을 관리하며 체계적인 준비가 필요한 SAT까지 풀 커버합니다.</div></div>
                <div class="py-3"></div>
            </v-card>
            <div>
                <v-card color="#993333" style="border-radius: 13px" width="850px" class="ma-5 text-center white--text">
                    <div class="py-3"></div>
                    <div class="font-weight-bold  " style="font-size: 22px; ">02</div>
                    <div class="white--text text-center px-5 font-weight-bold" >원어민 수준의 영어로 수업을 진행</div>
                    <div class="py-3"></div>
                    <div class="px-5">의외로 많은 시간이 투자되는 영어 공부.<br/> 낭비가 정리되고, 효과는 극대화됩니다.

                        <div class="py-3">학생 환경에 맞춰 원어민 수준의 영어로 수업이 진행되며 필요시 한국어로 보조 설명하며 진행합니다.</div></div>
                    <div class="py-3"></div>
                </v-card>
            </div>
            <div>
                <v-card color="#993333" style="border-radius: 13px" width="850" class="ma-5 text-center white--text">
                    <div class="py-3"></div>
                    <div class="font-weight-bold  " style="font-size: 22px; ">03</div>
                    <div class="white--text text-center px-5 font-weight-bold" >최상위권 입시에 필요한 확실하고 검증된 정보만 공유</div>
                    <div class="py-3"></div>
                    <div class="px-5">매일 조금씩 내 의지를 갉아먹던<br/> 입시에 대한 막연함이 완전히 제거됩니다.

                        <div class="py-3"> 아이비리그 출신에게 직접 경험을 토대로한 입시 원서, 교양 활동, 자소서 등 학교 생활에 대해 물어보세요.</div></div>
                    <div class="py-3"></div>
                </v-card>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "ThreePointIntro"
    }
</script>

<style scoped>
    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
