<template>
    <div>
        <v-app-bar style="position: fixed; background-color: #000000; z-index: 2; overflow:auto;" elevation="0">
            <v-btn @click="scrollto('what-is-bootcamp-section', {behavior: 'smooth'})" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">부트캠프</div>
            </v-btn>
            <v-btn @click="scrollto('program-section')" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">프로그램</div>
            </v-btn>
            <v-btn @click="scrollto('review-section')" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">후기</div>
            </v-btn>

            <v-btn @click="scrollto('curriculum-section')" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">커리큘럼</div>
            </v-btn>
            <!--            <v-btn @click="scrollto('smart-matching-section')" elevation="0" x-small text><div style="letter-spacing: -0.5px; font-size: 15px; color: white">매칭</div></v-btn>-->
            <v-btn @click="scrollto('sign-up-section')" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">신청</div>
            </v-btn>
        </v-app-bar>
        <div class="bootcamp-first text-center">
            <div class="py-5"></div>
            <!--            <div class="py-10"></div>-->
            <div class="pa-7 ">
                <div style="font-size: 17px; color: white;"
                     class="font-weight-bold">
                    <!--                    학기가 시작하기전!-->

                </div>
                <div class="center-horizontal">
                    <img width="300px" src="../../assets/APCSP/python.png">
                </div>
                <!--                <div class="py-5"></div>-->
                <h1 style="color: white" class="text-center">
                    <span style="font-size: 25px; font-weight: bold; font-family: LINESeedKR-Bd; line-height: -1px">
<!--                        <span style="color: #3774aa">파이</span>-->
                        <!--                        <span style="color: #f8ca3e">썬</span>-->
                        파이썬으로 공부하는 <br/>AP Computer Science Principles
                        <!--                        <div style="color: #BCFF07;" class="text-decoration-underline">시험 최종 문제풀이반</div>을 개설하였습니다!-->
                    </span>
                </h1>
                <div class="py-4"></div>
                <div class="center-horizontal white--text">
                    Python 프로그래밍 언어를 배우며 <br/>
                    공부하는 국내최초 AP CSP bootcamp
                </div>
                <div class="py-5"></div>
                <div class="px-5 py-3" style="background-color: rgb(255,255,255, 0.3); border-radius: 12px">
                    <v-row style="color: white; font-weight: bold; font-size: 18px">
                        <v-col>개념정리반</v-col>
                        <v-col>문제풀이반</v-col>
                    </v-row>
                    <v-row style="color: white;  font-size: 16px">
                        <v-col>학교수업 이해가 어려워 따라가기 힘들었던 학생들을 위한 개념정리반!</v-col>
                        <v-col>개념정리보다는 실전 문제 위주로 실력을 향상 시키고 싶은 학생들을 위한 문제풀이반!</v-col>
                    </v-row>
                </div>
                <div class="py-5"></div>
            </div>
            <div class="py-4"></div>
            <div>
                <v-card style=";" class="mx-15" flat>
                    <div style="background-color: #8820d2; color: white; font-family: LINESeedKR-Bd; font-size: 17px"
                         class="pa-3 font-weight-bold">
                        겨울방학이 끝날때까지 필요한 만큼
                    </div>
                    <div class="pa-3" style="font-family: LINESeedKR-Bd; font-size: 17px">
                        <div style="color: #13148D;">CS에 진심인 인원 한정!</div>
                        <div style="color: darkgrey; font-size: 13px; font-weight: normal">(영업일 기준 3일 이내 결과 발표)</div>
                        <div style="padding: 4px"></div>

                        <div class="center-horizontal">
                            <div style="font-size: 15px; color: #363636" class="text-left">
                                <!--                            지 원 마 감 | 23.03.27 (월) 18:00 <br/>-->
                                <!--                            <v-row>-->
                                <!--                                <v-col cols="6"></v-col>-->
                                <!--                                <v-col cols=""></v-col>-->
                                <!--                            </v-row>-->
                                <div class="text-decoration-underline">수 강 기 간</div>
                                <div>1기: 12/10 ~ 1/10</div>
                                <div>2기: 1/11 ~ 2/8</div>
                                <div class="py-1"></div>
                                <div class="text-decoration-underline">수 강 일 정</div>
                                23.12.10~ <br/>
                                <div class="py-1"></div>
                                <div class="text-decoration-underline">
                                    모 집 인 원
                                </div>
                                7명 <br/>
                            </div>
                        </div>
                        <div class="py-3"></div>
                        <v-btn color="#8820d2" elevation="0" class="rounded-lg" to="/bootcamp/register"
                               style="font-size: 14px; letter-spacing: 0px; color: white" x-large width="180px">
                            지원하기
                        </v-btn>

                    </div>

                </v-card>

            </div>
            <div class="py-10"></div>
            <div style="background-color: #e6e8eb; color: #FC434B" class="hidden-md-and-up px-10 pt-10 pb-10 text-left"
                 id="what-is-bootcamp-section">
                <div class="py-5"></div>
                <div class="hidden-md-and-up">
                    <div class=" font-weight-bold" style="font-size: 14px; color: #000018">
                        AP CSP 부트캠프란?
                    </div>
                    <div class="py-1"></div>
                    <div class=" text-h6 font-weight-bold" style="color: #030a1f">
                        정예 참가자로만 구성
                    </div>
                    <div class="py-1"></div>
                    <div class="" style="font-size: 13px; color: #030a1f">
                        아직도 대규모 특강에 현혹되고 계신가요? <br/> 아이비에드 AP CSP 부트캠프에서는 컴퓨터공학 대학과정 기초부터 Python (파이썬) 프로그래밍 언어를 배우며 기초를
                        탄탄하게 잡아주는
                        최고의 쌍방향 소통 부트캠프입니다.
                        <div style="padding-bottom: 8px"></div>
                        단순한 지식 전달에 그치지 않습니다. 계속해서 질문하고 성장할 수 있게 실시간으로 교육합니다.
                        <div style="padding-bottom: 8px"></div>
                        부트캠프 진행기간 동안 Principles 개념을 넘어 실제 파이썬 프로그래밍 활용능력까지 쌓을 수 있게 실시간으로 교육합니다.
                        <div style="padding-bottom: 8px"></div>
                        최소 4주간 수업과 실습으로 자신만의 실력을 갈고 닦으며 경험이 풍부한 선생님들께 도움을 받아 학생의 꿈을 보다 더 넓은 미래로 이끌어 보세요.
                    </div>
                    <div class="py-3"></div>
                    <div class=" text-h6 font-weight-bold" style="color: #030a1f">
                        대상
                    </div>
                    <div style="color: #030a1f !important;">
                        <div>
                            <v-icon color="" size="19px">mdi-check-bold</v-icon>
                            <span class="" style="font-size: 13px"> AP CSP를 미리 끝내고 싶으신 분</span>
                        </div>
                        <div>
                            <v-icon color="" size="19px">mdi-check-bold</v-icon>
                            <span class="" style="font-size: 13px"> 인강과 독학으로 준비하니 막막하신 분</span>
                        </div>
                        <div>
                            <v-icon color="" size="19px">mdi-check-bold</v-icon>
                            <span class=""
                                  style="font-size: 13px"> 컬리지보드에 제출해야하는 프로젝트를 미리 끝내고 싶으신 분</span>
                        </div>
                        <div>
                            <v-icon color="" size="19px">mdi-check-bold</v-icon>
                            <span class=""
                                  style="font-size: 13px">실제 프로그래밍 실력을 향상하고 싶은 분</span>
                        </div>
                    </div>
                </div>
                <!--                <div class="center-horizontal ">-->
                <!--                    <div class="hidden-sm-and-down px-10" style="max-width: 800px">-->
                <!--                        <div class="secondary&#45;&#45;text font-weight-bold" style="font-size: 14px">-->
                <!--                            AP CSA 부트캠프란?-->
                <!--                        </div>-->
                <!--                        <div class="py-1"></div>-->
                <!--                        <div class="white&#45;&#45;text text-h6 font-weight-bold" style="">-->
                <!--                            정예 참가자로만 구성-->
                <!--                        </div>-->
                <!--                        <div class="py-1"></div>-->
                <!--                        <div class="white&#45;&#45;text" style="font-size: 13px">-->
                <!--                            아직도 대규모 특강에 현혹되고 계신가요? <br/> 아이비에드 AP CSA 부트캠프에서는 시험기간까지 컴퓨터공학 대학과정 기초부터 프로그래밍 과제 실습까지 탄탄하게-->
                <!--                            잡아주는 최고의 쌍방향-->
                <!--                            소통-->
                <!--                            부트캠프입니다.-->
                <!--                            <div style="padding-bottom: 8px"></div>-->
                <!--                            단순한 지식 전달에 그치지 않습니다. 계속해서 질문하고 성장할 수 있게 실시간으로 교육합니다.-->
                <!--                            <div style="padding-bottom: 8px"></div>-->
                <!--                            부트캠프 진행기간 동안 학교 과제까지 직접 도움받으며 실력을 쌓을 수 있습니다.-->
                <!--                            <div style="padding-bottom: 8px"></div>-->
                <!--                            최소 4주간 수업과 실습으로 자신만의 실력을 갈고 닦으며 경험이 풍부한 선생님들께 도움을 받아 학생의 꿈을 보다 더 넓은 미래로 이끌어 보세요.-->
                <!--                        </div>-->
                <!--                        <div class="py-3"></div>-->
                <!--                        <div class="white&#45;&#45;text text-h6 font-weight-bold" style="">-->
                <!--                            대상-->
                <!--                        </div>-->
                <!--                        <div>-->
                <!--                            <div>-->
                <!--                                <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>-->
                <!--                                <span class="white&#45;&#45;text" style="font-size: 13px"> 학교 수업만으로는 어려운 분</span>-->
                <!--                            </div>-->
                <!--                            <div>-->
                <!--                                <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>-->
                <!--                                <span class="white&#45;&#45;text" style="font-size: 13px"> 인강과 독학으로 준비하니 막막하신 분</span>-->
                <!--                            </div>-->
                <!--                            <div>-->
                <!--                                <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>-->
                <!--                                <span class="white&#45;&#45;text"-->
                <!--                                      style="font-size: 13px"> 과제수행 시간이 너무 오래 걸리시는 분</span>-->
                <!--                            </div>-->
                <!--                            <div>-->
                <!--                                <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>-->
                <!--                                <span class="white&#45;&#45;text"-->
                <!--                                      style="font-size: 13px"> 프로그래밍 실력을 향상하고 싶은 분</span>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
<!--                <div style="background-color: #e6e8eb" class="py-8"></div>-->
            </div>

            <!-- video -->
            <div style="max-height: 700px; background-color: #1c2022">
                <div class="py-5"></div>
                <h1 style="color: #fff; ">강의 미리보기</h1>
                <div class="py-5"></div>
                <iframe class="hidden-sm-and-up" width="100%" height="315"
                        src="https://www.youtube.com/embed/N4zNNi933Vk?si=mOxzPli0_xvuWC8D" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                <iframe class="hidden-xs-only" width="50%" height="315"
                        src="https://www.youtube.com/embed/N4zNNi933Vk?si=mOxzPli0_xvuWC8D" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                <div class="py-5"></div>
            </div>


            <div style="background-color: #1d2022" class="px-10 pt-10 pb-10 text-left">
                <div class="py-8"></div>

                <div class="hidden-md-and-up">
                    <div class="secondary--text font-weight-bold" style="font-size: 14px">
                        차별점
                    </div>
                    <div class="py-1"></div>
                    <div class="white--text text-h6 font-weight-bold" style="">
                        아이비에드 부트캠프는 이렇게 다릅니다.
                    </div>
                    <div class="" style="font-size: 13px; color: #ffcccb">
                        <div style="padding-bottom: 8px"></div>
                        단기 속성반으로 보장할 수 없는 점수를 약속하지 않습니다. 실시간 수업과 시험문제 풀이 및 컬리지보드에 향후 제출해야하는 프로젝트를 Python 및 타언어로 미리 끝냄으로
                        AP CSP 대비뿐 아닌 Python의 이해도도 함께 키워갑니다.
                        <div style="padding-bottom: 8px"></div>

                    </div>
                    <div class="py-3"></div>
                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold text-center" style="font-size: 16px">
                            AP CSP 시험 30%를 차지하는 프로젝트를 부트캠프내 완료
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            AP CSP에서 30%나 차지하는 프로젝트를 모르고 수업을 듣는 경우가 많습니다. 하여 부트캠프 기간동안 해당 프로젝트를 아이비리그 선생님과 완벽하게 끝냅니다.
                        </div>
                    </div>
                    <div class="py-2"></div>
                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold text-center" style="font-size: 16px">
                            전세계에서 가장 많이 쓰이는 Python 프로그래밍 언어 학습
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            AP CSP를 가르치는 곳 중 국내최초로 Python을 사용하며 수업을 진행합니다. AP CSP 시험대비 뿐 아닌 부트캠프가 끝난 후 실제로 Python언어로 나만의
                            프로그램을 만들 수 있는 능력을 습득합니다.
                        </div>
                    </div>
                    <div class="py-2"></div>

                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold text-center" style="font-size: 16px">
                            온라인 스터디 진행 (매주 과제 및 시험)
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            책과 강의는 있는데 혼자서 공부하기 막막하신 분들에게 추천합니다. 매주 공부의 루틴을 자동적으로 잡아주는 과제와 시험이 있으며 스스로 공부한다면 놓치기 쉬운 부분과
                            복습
                            점검까지 철저하게 지도하고 있습니다.
                        </div>
                    </div>
                    <div class="py-2"></div>
                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold text-center" style="font-size: 16px">
                            아이비리그 출신 선생님과 함께 하는 질의응답 기회
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            Computer science 는 혼자서 공부한다면 검색하고 찾아봐야할 시간이 사소한 것이라도 몇시간씩 그냥 지나가는 반면, 처음부터 체계적인 전문 선생님께 지도를
                            받으면
                            구글링하며 헤매는 시간을 100 시간에서 단 몇시간으로 절약할 수 있는 과목입니다. 최고의 선생님들께 도움을 받으며 시간을 확실하게 챙기세요.
                        </div>
                    </div>
                    <div class="py-2"></div>
                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold text-center" style="font-size: 16px">
                            기간 제한 없는 학습 지원 프로그램
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            아이비에드는 학생들이 시험을 볼때까지 함께합니다. 학습이 끝난 후에도 시험 기간 동안 무제한 복습 과제와 강의를 제공해드리고 있으며. 코스가 체계적으로 구성이 되어
                            있기
                            때문에 한번 결제하면 추가적인 금액없이 이용하실 수 있습니다. 또한 다년간 AP computer science 대비를 위한 모든것이 들어 있는 교재와 PDF 자료를
                            제공해드리고 있습니다.
                        </div>
                    </div>
                    <div class="py-2"></div>
                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold text-center" style="font-size: 16px">
                            부트캠프 참여자 한정 수업 녹화본 인강 무기한 제공
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            부트캠프 진행기간 및 끝난 후에도 매수업 이후 당일날 수업 녹화본 (인강)이 참여 학생들 한정으로 무기한 제공되어 언제든 헷갈렸던 부분을 복습할 수 있도록 환경을
                            만들었습니다.
                        </div>
                    </div>

                </div>
                <div class="center-horizontal">
                    <div class="hidden-sm-and-down" style="max-width: 720px">
                        <div class="secondary--text font-weight-bold" style="font-size: 14px">
                            차별점
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text text-h6 font-weight-bold" style="">
                            아이비에드 부트캠프는 이렇게 다릅니다.
                        </div>
                        <div class="" style="font-size: 13px; color: #ffcccb">
                            <div style="padding-bottom: 8px"></div>
                            단기 속성반으로 보장할 수 없는 점수를 약속하지 않습니다. 실시간 수업과 과제 도우미 및 시험문제 풀이로 AP CSA 대비뿐 아닌 Java의 이해도도 함께
                            키워갑니다.
                            <div style="padding-bottom: 8px"></div>

                        </div>
                        <div class="py-3"></div>
                        <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                             class="pa-3">
                            <div class=" font-weight-bold" style="font-size: 16px">
                                1. 온라인 스터디 진행 (매주 과제 및 시험)
                            </div>
                            <div class="py-2"></div>
                            <div style="font-size: 14px">
                                책과 강의는 있는데 혼자서 공부하기 막막하신 분들에게 추천합니다. 매주 공부의 루틴을 자동적으로 잡아주는 과제와 시험이 있으며 스스로 공부한다면 놓치기 쉬운
                                부분과
                                복습
                                점검까지 철저하게 지도하고 있습니다.
                            </div>
                        </div>
                        <div class="py-2"></div>
                        <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                             class="pa-3">
                            <div class=" font-weight-bold" style="font-size: 16px">
                                2. 아이비리그 출신 선생님과 함께 하는 질의응답 기회
                            </div>
                            <div class="py-2"></div>
                            <div style="font-size: 14px">
                                Computer science 는 혼자서 공부한다면 검색하고 찾아봐야할 시간이 사소한 것이라도 몇시간씩 그냥 지나가는 반면, 처음부터 체계적인 전문 선생님께
                                지도를
                                받으면
                                구글링하며 헤매는 시간을 100 시간에서 단 몇시간으로 절약할 수 있는 과목입니다. 최고의 선생님들께 도움을 받으며 시간을 확실하게 챙기세요.
                            </div>
                        </div>
                        <div class="py-2"></div>
                        <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                             class="pa-3">
                            <div class=" font-weight-bold" style="font-size: 16px">
                                3. 기간 제한 없는 학습 지원 프로그램
                            </div>
                            <div class="py-2"></div>
                            <div style="font-size: 14px">
                                아이비에드는 학생들이 시험을 볼때까지 함께합니다. 학습이 끝난 후에도 시험 기간 동안 무제한 복습 과제와 강의를 제공해드리고 있으며. 코스가 체계적으로 구성이
                                되어
                                있기
                                때문에 한번 결제하면 추가적인 금액없이 이용하실 수 있습니다. 또한 다년간 AP computer science 대비를 위한 모든것이 들어 있는 교재와 PDF
                                자료를
                                제공해드리고 있습니다.
                            </div>
                        </div>
                        <div class="py-2"></div>
                        <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                             class="pa-3">
                            <div class=" font-weight-bold" style="font-size: 16px">
                                4. 부트캠프 참여자 한정 수업 녹화본 인강 무기한 제공
                            </div>
                            <div class="py-2"></div>
                            <div style="font-size: 14px">
                                부트캠프 진행기간 및 끝난 후에도 매수업 이후 당일날 수업 녹화본 (인강)이 참여 학생들 한정으로 무기한 제공되어 언제든 헷갈렸던 부분을 복습할 수 있도록
                                환경을
                                만들었습니다.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="py-8"></div>
            </div>
            <div style="background-color: white" class="py-5"></div>
            <div style="background-color: white !important;" id="program-section">

                <div class="py-10"></div>
                <h2 class="px-12 font-weight-bold">
                    알고 계셨나요,
                    <div class="py-1"></div>
                    AP CSP는 두 파트로 나뉘어져 있다는걸?
                </h2>
                <div class="py-1"></div>
                <div class="px-12">

                </div>
                <div class="py-5"></div>
                <div class="px-8 pb-8" style="border-radius: 22px">
                    <div style="border-radius: 8px; background-color: #faf6ff">
                        <div class="text-left pa-3 font-weight-bold">
                            <v-chip color="#9a1dd0" class="ma-2" small outlined>Part 1</v-chip>
                            <div class="pa-3" style="font-size: 20px">
                                70문항 객관식
                            </div>

                            <div class="font-weight-regular pa-3" style="font-size: 15px">
                                <div>
                                    컴퓨터 과학의 기본 개념과 원리, 프로그래밍, 데이터 분석, 컴퓨터 시스템, 인터넷과 네트워크 등과 관련된 문제를 다루며, 학생들의 이론적 지식을
                                    평가하는 파트입니다.
                                </div>
                                <div class="py-2"></div>

                                이 파트에서는 객관식 문제가 대부분이며, 각 문제마다 4개의 선택지 중에서 하나를 선택하는 방식으로 구성됩니다. 시간 제한은 1시간 30분입니다.
                            </div>
                            <div class="py-4"></div>
                            <div class="text-center">
                                <img width="90%" style="border-radius: 4px" src="../../assets/APCSP/APCSPMCexample.png">
                            </div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div style="border-radius: 8px; background-color: #faf6ff">
                        <div class="text-left pa-3 font-weight-bold">
                            <v-chip color="#9a1dd0" class="ma-2" small outlined>Part 2</v-chip>
                            <div class="pa-3" style="font-size: 20px">
                                CREATE PERFORMANCE TASK
                            </div>
                            <div class="font-weight-regular pa-3" style="font-size: 15px">
                                <div>
                                    <!--                                    <div class="font-weight-bold">-->
                                    <!--                                        컴퓨터 시스템과 프로그래밍 (14 문항)-->
                                    <!--                                    </div>-->
                                    Create Performance Task 파트는 학생들이 주제를 선택하고, 그 주제에 대한 컴퓨터 프로그램을 개발하고 문서화하는 과정을 평가하는
                                    파트입니다. 학생들은 문제 해결 능력과 프로그래밍 능력, 문서화 능력을 모두 요구하는 이 파트에서, 주어진 문제를 해결하는 과정에서 생기는 어려움을
                                    극복하는 능력을 기를 수 있습니다.
                                </div>
                                <div class="py-4"></div>
                                <div class="text-center">
                                    <img style="border-radius: 4px;" width="90%" src="../../assets/APCSP/APCSPCPT.png">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div class="py-7" style="background-color: white"></div>
            <div style="background-color: black; color: white" class="pa-4">
                <div class="py-7"></div>
                <div class="pa-4 font-weight-bold" style="font-size: 30px; letter-spacing: -1px">
                    <span style="font-family: Menlo" class="center-horizontal"><img width="200px"
                                                                                    src="../../assets/APCSP/python.png"></span>
                    <br/>
                    <div style="font-family: Menlo">AP Computer Science Principles</div>
                    <div class="pt-4"></div>
                    부트캠프로 <br/>
                    Python + CSP
                    <br/>한번에 모두 마스터하세요.
                    <div class="py-5"></div>
                    <div class="center-horizontal">
                        <div style="font-family: Menlo !important; font-size: 16px" class="text-left">
                            <span style="color: #cc832d; font-family: Menlo !important">def</span> <span
                                style="color: #f9cc70;font-family: Menlo !important">ap_csp_bootcamp():</span>
                            <div class="pl-5" style="font-family: Menlo !important">
                                <span style="color: #cc832d;font-family: Menlo !important">if</span>(with_ivyed):
                            </div>
                            <div class="pl-10" style="font-family: Menlo !important">
                                ap_csp_score = <span style="color: #9493cf; font-family: Menlo !important">5</span><br/>
                                learn_python = <span style="color: #9493cf; font-family: Menlo !important"> true </span>
                            </div>
                            <div class="pl-5" style="font-family: Menlo !important;">
                                <span style="color: #cc832d; font-family: Menlo !important">else</span>:
                            </div>
                            <div class="pl-10">
                                ap_csp_score = <span style="color: #9493cf; font-family: Menlo !important">?</span><br/>
                                learn_python = <span
                                    style="color: #9493cf; font-family: Menlo !important"> false </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-5"></div>
                <div class="primary--text">
                    <!--                    더이상 파이썬 학원 따로 AP CSP 학원 따로 다니지 않으셔도 됩니다.-->
                </div>
            </div>
            <div class="py-7" style="background-color: black"></div>
            <div style="background-color: #54156e; color: white">
                <div class="py-9"></div>
                <div class="px-12">
                    <h1 style="font-family: LINESeedKR-Bd; letter-spacing: 0px">
                        AP CS는 다른 과목과 달리 어렸을때 부터 습득하지 않은 기초들이 필요합니다.
                    </h1>
                </div>
                <div class="py-7"></div>
                <div class="center-horizontal" style=";">
                    <v-card color="#9929c8" height="100px" width="300px" class="center-horizontal center-vertical"
                            style="border-radius: 20px">
                        <v-card class="pa-4" height="90px" width="290px"
                                style="font-family: LINESeedKR-Bd; font-size: 15px">
                            학교 선생님 설명만으로 도저히 수업을 따라가지도, 숙제를 제대로 하지도 못하겠어요..😢
                        </v-card>
                    </v-card>
                    <div class="py-2"></div>

                    <v-card color="#9929c8" height="100px" width="300px" class="center-horizontal center-vertical"
                            style="border-radius: 20px">
                        <v-card class="pa-4" height="90px" width="290px"
                                style="font-family: LINESeedKR-Bd; font-size: 15px">
                            수학처럼 딱 이해가가서 문제를 풀고 싶은데 계속 찍다시피 문제를 풀게 되어요. 😢
                        </v-card>
                    </v-card>
                    <div class="py-2"></div>

                    <v-card color="#9929c8" height="100px" width="300px" class="center-horizontal center-vertical"
                            style="border-radius: 20px">
                        <v-card class="pa-4" height="90px" width="290px"
                                style="font-family: LINESeedKR-Bd; font-size: 15px">
                            <!--                            <div class="pa-4" >-->
                            프로그래밍을 제대로 해본적이 없는데 Create Performance Task 해와야 한대요.. :(
                            <!--                            </div>-->
                        </v-card>
                    </v-card>
                    <div class="py-2"></div>

                    <v-card color="#9929c8" height="100px" width="300px" class="center-horizontal center-vertical"
                            style="border-radius: 20px">
                        <v-card class="pa-4" height="90px" width="290px"
                                style="font-family: LINESeedKR-Bd; font-size: 15px">
                            Written Response에 뭘 어떻게 적어야할지 도저히 감이 안와요..
                        </v-card>
                    </v-card>
                    <div class="py-2"></div>

                    <div class="py-9"></div>
                </div>
            </div>
            <div style="background-color: #f3f3f3; color: #1a1732; font-family: LINESeedKR-Bd; letter-spacing: 0px"
                 class="pa-3">
                <div class="py-7"></div>
                <h3 style="">
                    학기가 시작하고 준비하면 늦어집니다.
                </h3>
                <div class="py-1"></div>
                <h2 class="px-12 font-weight-bold" style="font-size: 28px">
                    아이비에드가 보유한 문제 및 기출문제들 모두 함께 풀며 설명 듣기!
                </h2>
                <div class="py-3"></div>
                <div class="center-horizontal">
                    <v-row class="text-left ">

                        <div style="border-radius: 12px; background-color: #faf6ff;
                             height: 100%;"
                             class="mx-5">

                            <v-chip outlined
                                    color="#a43bd4" text-color="#a43bd4" class="ma-5 ">STEP 1
                            </v-chip>
                            <div class="px-6 text-center" style="font-family: LINESeedKR-Bd; font-size: 18px">
                                매수업마다 배운걸 토대로 문제를 풀며 나의 약한 부분을 파악하고 개선합니다.
                            </div>
                            <div class="py-1"></div>
                            <div class="text-center pa-5">
                                <img height="100%" width="100%" src="../../assets/gettingQuestionsWrong2.png"/>
                            </div>
                        </div>

                    </v-row>

                    <div class="py-6"></div>
                    <v-row class="text-left">
                        <div style="border-radius: 12px; background-color: #faf6ff;
                             height: 500px;"
                             class="mx-5">
                            <v-chip outlined
                                    color="#a43bd4" text-color="#a43bd4" class="ma-5 ">STEP 2
                            </v-chip>
                            <div class="px-6 text-center" style="font-family: LINESeedKR-Bd; font-size: 18px">
                                선생님이 준비한 문제들 및 학생들이 설명을 원하는 문제들을 하나하나 이해가 갈때까지 설명 및 문제풀이 합니다.
                            </div>
                            <div class="py-3"></div>
                            <div class="text-center">
                                <img height="50%" width="50%" src="../../assets/apcsppsets.png"/>
                            </div>
                        </div>
                    </v-row>
                    <div class="py-6"></div>
                    <v-row class="text-left">
                        <div style="border-radius: 12px; background-color: #faf6ff;
                             height: 100%;"
                             class="mx-5">
                            <v-chip outlined
                                    color="#a43bd4" text-color="#a43bd4" class="ma-5 ">STEP 3
                            </v-chip>
                            <div class="px-6 text-center" style="font-family: LINESeedKR-Bd; font-size: 18px">
                                수업이 끝나더라도 언제든 선생님께 저희 메신저를 사용해 질문할 수 있습니다. 겨울특강반은 무엇보다 시간을 정말 중요하게 생각하여 최대한 실시간으로 질문들의
                                답을 드리고 있습니다.
                            </div>
                            <div class="py-0"></div>
                            <div class="text-center pa-8">
                                <img height="100%" width="100%" src="../../assets/openChat.png"/>
                            </div>
                        </div>

                    </v-row>

                    <div class="py-12"></div>

                    <div style="background-color: #fff" id="curriculum-section">
                        <CSPCurriculum />
                    </div>

                </div>


            </div>
<!--            <CalenderP />-->
            <div style="background-color: #1D2022;display: flex;justify-content: center;flex-direction: column;align-items: center;width: 100vw;padding: 100px 0px">
                <h2 style="text-align: center;color: white;margin-bottom: 25px">AP CSP KST</h2>
                <div style="display: grid;grid-template-rows:1fr 1fr 1fr;color: white;width: inherit; max-width: 600px">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #1E2836;margin-bottom: 10px;font-weight: bolder;font-size: smaller;height: 50px">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일</span>
                        <span style="place-self: center center;text-align: center">시간</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #64748B;gap: 3px;margin-bottom: 10px;font-size: 14px">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP CSP<br/>[개념정리반]</span>
                        <span style="place-self: center center;text-align: center">화, 목</span>
                        <span style="place-self: center center;text-align: center">19:30~21:00</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #64748B;gap: 3px;margin-bottom: 10px;font-size: 14px">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP CSP<br/>[문제풀이반]</span>
                        <span style="place-self: center center;text-align: center">화, 목</span>
                        <span style="place-self: center center;text-align: center">21:00~22:30</span>
                    </div>
                </div>
                <h2 style="text-align: center;color: white;margin: 40px 0px">AP CSP PST</h2>
                <div style="display: grid;grid-template-rows:1fr 1fr 1fr;color: white;width: inherit; max-width: 600px">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #1E2836;margin-bottom: 10px;font-weight: bolder;font-size: smaller">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일</span>
                        <span style="place-self: center center;text-align: center">시간</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #64748B;gap: 3px;margin-bottom: 10px;font-size: 14px">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP CSP<br/>[개념정리반]</span>
                        <span style="place-self: center center;text-align: center">화, 목</span>
                        <span style="place-self: center center;text-align: center">(서부)<br/>02:30~04:00<br/>(동부)<br/>05:30~07:00</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #64748B;gap: 3px;margin-bottom: 10px;font-size: 14px">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP CSP<br/>[문제풀이반]</span>
                        <span style="place-self: center center;text-align: center">화, 목</span>
                        <span style="place-self: center center;text-align: center">(서부)<br/>04:00~05:30<br/>(동부)<br/>07:00~08:30</span>
                    </div>
                </div>
            </div>

            <div class="for-whom">
                <div class="py-12"></div>
                <div class="px-5">

                    <div style="font-size: 25px; font-weight: bold">
                        겨울방학에 <br/> <span class="text-decoration-underline">Create Performance Task</span><br/> 끝내기!
                    </div>
                    <div class="py-3"></div>
                    <div style="font-size: 18px; font-weight: bold" class="pt-1">
                        AP CSP 총점수의 30%를 차지하는 프로그래밍 프로젝트를
                        겨울방학에 미리 끝내 놓으세요!
                    </div>
                    <div class="py-2"></div>
                    <div style="font-size: 18px; font-weight: bold" class="pt-1">
                        학기중 수업을 들으며 총 점수의 30%나 차지하는 프로그래밍 프로젝트를 제대로 하지 못하는 상황을 원천 차단해드립니다.
                    </div>

                    <div class="py-2"></div>
                    <div class="center-horizontal">
                        <v-card width="350px" class="rounded-lg ">
                            <!--                                학기중 수업을 들으며 총 점수의 30%나 차지하는 프로그래밍 프로젝트를 제대로 하지 못하는 상황을 원천 차단해드립니다.-->
                            <img width="100%" src="../../assets/APCSP/CPTsample1.png">
                            <img width="100%" src="../../assets/APCSP/CPTsample1-1.png">
                        </v-card>
                        <div class="py-2"></div>
                        <v-card width="350px" class="rounded-lg ">
                            <!--                                학기중 수업을 들으며 총 점수의 30%나 차지하는 프로그래밍 프로젝트를 제대로 하지 못하는 상황을 원천 차단해드립니다.-->
                            <img width="100%" src="../../assets/APCSP/CPTsample3.png">
                            <img width="100%" src="../../assets/APCSP/CPTsample2-1.png">
                        </v-card>
                    </div>
                </div>
                <div class="py-7"></div>

            </div>
            <div style="background-color: #f4f8f9; color: #000000 " class="px-10 pt-10 pb-10 text-left">
                <div class="py-10"></div>

                <div class="hidden-md-and-up">
                    <div class="secondary--text font-weight-bold" style="font-size: 14px">
                        교육 방식
                    </div>
                    <div class="py-1"></div>
                    <div class=" text-h6 font-weight-bold" style="">
                        아무런 준비 없이 와도 괜찮습니다.
                    </div>
                    <div class="py-1"></div>
                    <div class="" style="font-size: 13px">
                        프로그래밍 경험과 상관없이 치열한 의지만 있다면, <br/>
                        누구나 AP CSP 만점과 Python에 능숙해 질 수 있는 시스템.
                    </div>
                    <div class="py-3"></div>
                    <v-card class="rounded-lg" style="background-color: #ffffff;">
                        <div class="py-4 px-4"
                             style="color: #FC434B; font-size: 12px; font-weight: bold">
                            Point 1
                        </div>
                        <div class="px-4" style="color: #1C1E1F;  font-weight: bold">
                            누구나 시작할 수 있습니다.
                            사전 지식이 없어도 괜찮습니다.
                        </div>
                        <div class="py-1" style="background-color: white"></div>
                        <div class="px-4" style=" font-size: 13px">
                            • 합류 즉시, 사전스터디 자료를 제공<br/>
                            • 아이비리그 선생님과 주 단위 기술 멘토링
                        </div>
                        <div class="py-2" style="background-color: whtie"></div>
                    </v-card>
                    <div class="py-2"></div>
                    <v-card class="rounded-lg">
                        <div class="py-4 px-4"
                             style="color: #FC434B;  font-size: 12px; font-weight: bold">
                            Point 2
                        </div>
                        <div class="px-4" style="color: #1C1E1F; font-weight: bold">
                            자기주도적으로 학습하되 <br/>
                            올바른 방향으로 성장할 수 있도록.
                        </div>
                        <div class="py-1" style=""></div>
                        <div class="px-4" style="color: #1C1E1F;  font-size: 13px">
                            • 방향성 제공과 밀착 상담으로 올바른 성장 지원<br/>
                            • 담임 선생님과 언제든 상담 가능
                        </div>
                        <div class="py-2" style="background-color: white"></div>
                    </v-card>
                    <div class="py-2"></div>
                    <v-card class="rounded-lg">
                        <div class="py-4 px-4"
                             style="color: #FC434B;  font-size: 12px; font-weight: bold">
                            Point 3
                        </div>
                        <div class="px-4" style="color: #1C1E1F;  font-weight: bold">
                            최적의 속도로 달릴 수 있게 <br/>
                            수준별 학습 로드맵을 제공합니다.
                        </div>
                        <div class="py-1" style=""></div>
                        <div class="px-4" style="color: #1C1E1F;  font-size: 13px">
                            • <br/>
                            • 수준에 따른 맞춤 학습과 지원 제공
                        </div>
                        <div class="py-2" style=""></div>
                    </v-card>
                </div>
                <div class="center-horizontal">
                    <div class="hidden-sm-and-down" style=" max-width: 750px">
                        <div class="secondary--text font-weight-bold" style="font-size: 14px">
                            교육 방식
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text text-h6 font-weight-bold" style="">
                            아무런 준비 없이 와도 괜찮습니다.
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text" style="font-size: 13px">
                            프로그래밍 경험과 상관없이 치열한 의지만 있다면, <br/>
                            누구나 AP CSP 만점과 Java에 능숙해 질 수 있는 시스템.
                        </div>
                        <div class="py-3"></div>
                        <v-card class="rounded-lg">
                            <div class="py-4 px-4"
                                 style="color: #FC434B; background-color: #1C1E1F; font-size: 12px; font-weight: bold">
                                Point 1
                            </div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-weight: bold">
                                누구나 시작할 수 있습니다.
                                사전 지식이 없어도 괜찮습니다.
                            </div>
                            <div class="py-1" style="background-color: #1C1E1F"></div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-size: 13px">
                                • 합류 즉시, 사전스터디 자료를 제공<br/>
                                • 아이비리그 선생님과 주 단위 기술 멘토링
                            </div>
                            <div class="py-2" style="background-color: #1C1E1F"></div>
                        </v-card>
                        <div class="py-2"></div>
                        <v-card class="rounded-lg">
                            <div class="py-4 px-4"
                                 style="color: #FC434B; background-color: #1C1E1F; font-size: 12px; font-weight: bold">
                                Point 2
                            </div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-weight: bold">
                                자기주도적으로 학습하되 <br/>
                                올바른 방향으로 성장할 수 있도록.
                            </div>
                            <div class="py-1" style="background-color: #1C1E1F"></div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-size: 13px">
                                • 방향성 제공과 밀착 상담으로 올바른 성장 지원<br/>
                                • 담임 선생님과 언제든 상담 가능
                            </div>
                            <div class="py-2" style="background-color: #1C1E1F"></div>
                        </v-card>
                        <div class="py-2"></div>
                        <v-card class="rounded-lg">
                            <div class="py-4 px-4"
                                 style="color: #FC434B; background-color: #1C1E1F; font-size: 12px; font-weight: bold">
                                Point 3
                            </div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-weight: bold">
                                최적의 속도로 달릴 수 있게 <br/>
                                수준별 학습 로드맵을 제공합니다.
                            </div>
                            <div class="py-1" style="background-color: #1C1E1F"></div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-size: 13px">
                                • <br/>
                                • 수준에 따른 맞춤 학습과 지원 제공
                            </div>
                            <div class="py-2" style="background-color: #1C1E1F"></div>
                        </v-card>
                    </div>
                    <div class="py-10"></div>
                </div>
            </div>

            <div class="pa-10 pt-15 text-center" style="background-color: #1c1e20" id="review-section">
                <div class="py-5"></div>
                <div class="white--text" style="font-size: 20px; font-weight: bold">
                    AP CS Principles, <br/>
                    모두 숫자로 증명했습니다.
                </div>
                <div class="py-2"></div>
                <div class="white--text" style="font-size: 14px">
                    매 기수 98% 이상 AP 5점 뿐 아니라,<br/>
                    실제 프로그래밍을 <br/>할 수 있는 능력을 키워가고 있습니다.
                </div>
                <div class="py-5">
                    <div style="color: #EF0006; font-size: 50px; " class="font-weight-medium"> 97.8%</div>
                    <div class="white--text font-weight-bold" style="font-size: 15px">2개월 내 5점 취득</div>
                    <!--                    <div style="color: lightgray; font-size: 11px"> 3개월 내 취업률 81.4%</div>-->
                </div>
                <div class="py-5">
                    <div style="color: #EF0006; font-size: 50px; " class="font-weight-medium"> 97.9%</div>
                    <div class="white--text font-weight-bold" style="font-size: 15px">Python 언어 프로그래밍 능력</div>
                    <!--                    <div style="color: lightgray; font-size: 11px">수료 후 1년 기준</div>-->
                </div>
                <div class="py-5">
                    <div style="color: #EF0006; font-size: 50px; " class="font-weight-medium"> 98%</div>
                    <div class="white--text font-weight-bold" style="font-size: 15px">수업 만족도</div>
                    <!--                    <div style="color: lightgray; font-size: 11px">아이비에드 수료 2년차 기준</div>-->
                </div>
                <div class="py-5">
                    <div style="color: #EF0006; font-size: 50px; " class="font-weight-medium"> 98.4%</div>
                    <div class="white--text font-weight-bold" style="font-size: 15px">재수강률</div>
                    <!--                    <div style="color: lightgray; font-size: 11px">~10기 기준</div>-->
                </div>
                <div class="py-7"></div>
                <!--                <div style="color: lightgray; font-size: 11px">~10기 기준</div>-->

            </div>

        </div>
        <div style="background-color: #F4F8F9; color: #FC434B " class="px-10 pt-10 pb-10 text-left">
            <div class="py-8"></div>
            <div class="hidden-md-and-up">
                <div class="secondary--text font-weight-bold" style="font-size: 14px">
                    수업 후기
                </div>
                <div class="py-1"></div>
                <div class="black--text text-h6 font-weight-bold" style="">
                    성적만 높은게 아닙니다. <br/>
                    입시 경쟁력 및 프로그래밍 실력도 길러줍니다.
                </div>
                <div class="py-3"></div>
                <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                    "진짜 AP CSP 부트캠프 너무 좋았어요! 프로그래밍을 아무것도 몰랐는데, 강의 내용이 정말 쉬워서 따라가기도 편했고, 선생님도 항상 친절하게 알려주셔서 재밌게 배울 수
                    있었어요. 특히 프로젝트 과제를 하면서 내가 직접 만든 것을 보면서 신난 기억이 많이 남습니다! 그 결과 5점을 받을 수 있었습니다!"
                    <br/>
                    <br/>
                    <div class="text-center">
                        <img height="100px" src="../../assets/APCSP/student1.png">
                    </div>
                    <div style="color: #2c3e50; font-size: 12px; font-weight: bold" class="text-center">
                        국내 국제학교 10학년
                    </div>

                </v-card>
                <div class="py-3"></div>
                <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                    "AP CSP 부트캠프를 수강하고 5점을 받았습니다! 이 부트캠프는 프로그래밍 경험이 없는 사람도 따라갈 수 있도록 구성되어 있었고, 강의 내용이 매우 직관적이어서 이해하기
                    쉬웠습니다. 특히, 수업에 참여하며 여러 가지 프로그래밍 언어를 배울 수 있었던 것이 큰 장점이었습니다."
                    <br/>
                    <br/>
                    <div class="text-center">
                        <img height="100px" src="../../assets/APCSP/student2.png">
                        <div style="color: #2c3e50; font-size: 12px; font-weight: bold">
                            미국 중부 보딩스쿨 10학년
                        </div>
                    </div>
                </v-card>
                <div class="py-3"></div>
                <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                    "Python 학원을 다닐까 했는데, 여기서 Python과 AP CSP를 동시에 받을 수 있다고 하여 듣게 되었는데, 진짜로 파이썬을 사용하며 수업이 진행되어 파이썬 실력도 키울수
                    있었어요!"
                    <br/>
                    <br/>
                    <div class="text-center">
                        <img height="100px" src="../../assets/APCSP/student3.png">
                    </div>
                    <div style="color: #2c3e50; font-size: 12px; font-weight: bold" class="text-center">
                        중국 국제학교 10학년
                    </div>
                </v-card>
            </div>
            <div class="center-horizontal">
                <div class="hidden-sm-and-down" style="max-width: 730px">
                    <div class="secondary--text font-weight-bold" style="font-size: 14px">
                        수업 후기
                    </div>
                    <div class="py-1"></div>
                    <div class="black--text text-h6 font-weight-bold" style="">
                        성적만 높은게 아닙니다. <br/>
                        입시 경쟁력 및 프로그래밍 실력도 길러줍니다.
                    </div>
                    <div class="py-3"></div>
                    <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                        "솔직히 주변에서 유학 준비를 걱정하는 시선이 많았는데 과외받으면서 좋은 성적도 받고 코딩에 흥미를 느껴 전공에 확신이 생겼어요"
                        <br/>
                        <br/>
                        <div class="text-center">
                            <img height="100px" src="../../assets/avatar2.png">
                        </div>
                        <div style="color: #2c3e50; font-size: 12px; font-weight: bold" class="text-center">
                            국내 국제학교 11학년
                        </div>

                    </v-card>
                    <div class="py-3"></div>
                    <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                        "프로그래밍을 하면서 스스로 문제해결능력을 기르게 되어 자연스럽게 포트폴리오를 만들고 그게 제 입시 준비의 초석이 되었습니다"
                        <br/>
                        <br/>
                        <div class="text-center">
                            <img height="100px" src="../../assets/avatar3.png">
                            <div style="color: #2c3e50; font-size: 12px; font-weight: bold">
                                미국 동부 보딩스쿨 11학년
                            </div>
                        </div>
                    </v-card>
                    <div class="py-3"></div>
                    <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                        "AP CSA 에서 5점을 받아 그 성적으로 이번 겨울 방학 리서치 인턴에 합격했습니다!"
                        <br/>
                        <br/>
                        <div class="text-center">
                            <img height="100px" src="../../assets/avatar4.png">
                        </div>
                        <div style="color: #2c3e50; font-size: 12px; font-weight: bold" class="text-center">
                            중국 국제학교 10학년
                        </div>
                    </v-card>
                </div>
            </div>
            <div class="py-10"></div>
        </div>

        <!--                <div class="center-horizontal">-->
        <!--                    <div class="hidden-sm-and-down" style="max-width: 720px ">-->
        <!--                        <div class="py-4"></div>-->
        <!--                        <v-tabs-->
        <!--                                class=""-->
        <!--                                align-with-title-->

        <!--                        >-->
        <!--                            <v-tabs-slider color=""></v-tabs-slider>-->

        <!--                            &lt;!&ndash;                                <v-tab class="font-weight-bold ">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                    4주 과정&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                </v-tab>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                <v-tab class="font-weight-bold ">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                    6주 과정&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                </v-tab>&ndash;&gt;-->
        <!--                            <v-tab class="font-weight-bold ">-->
        <!--                                8주 과정-->
        <!--                            </v-tab>-->
        <!--                            <div class="py-3"></div>-->
        <!--                            &lt;!&ndash;                                <v-tab-item>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                    <v-expansion-panels class=" " flat>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">1  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • AP CSA 는 어떤 과목이고 어떤 시험인가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Java 는 어떤 프로그래밍 언어인가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Java를 실행 할 수 있는 환경 구축하기&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 주석 (Comments)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 데이터 타입 (Data types in Java)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 연산자 (Operators in Java)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 입출력 (Input / Output)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 조건문 (Control Structures)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Errors and Exceptions in Java&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 1차원 배열 (One-dimensional Array)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Array vs. ArrayList in Java&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 2차원 배열 (Two-dimensional Array)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">2  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Classes and Objects</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Object(객체)란 무엇이고 어떻게 사용 되는가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Object vs. Primitive data types&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Wrapper Class 에 대한 이해&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Java 에서 제공하는 다양한 클래스들 알아보기&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Objects vs. Classes&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Java에서의 함수 (Methods) 작성법 및 이해&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Method Overloading&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Scope 개념 및 이해&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Reference vs. Primitive Data Types&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">3  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • AP CSA 의 필수문제 Object Oriented Program Design&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Software Development&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Program Analysis&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Class Inheritance (상속) 개념 및 이해&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Super Class vs. Sub Class&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Polymorphism&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Dynamic Binding&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Type Compatibility&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Google 과 Youtube 를 상속개념으로 구현 해보기&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            <span style="font-size: 11px; font-weight: bold">4  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                                style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            • 1차원 배열 (One-dimensional Array)&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            • Array vs. ArrayList in Java&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            • 2차원 배열 (Two-dimensional Array)&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            • 숙제 (Reading & MCP & Programming)&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            <span style="font-size: 11px; font-weight: bold">5  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                                style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            • AP CSA 의 필수문제 Object Oriented Program Design&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            • Software Development&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            • Program Analysis&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                            • 숙제 (Reading & MCP & Programming)&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">4  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Algorithms</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Recursive Methods&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Recursive 함수의 기본 형태&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • How to write recursive methods&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Recursion 을 사용하는 이유&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Recursion vs. Iteration&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • What are Algorithms?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Sorting&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Searching&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">9  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">10  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->


        <!--                            &lt;!&ndash;                                    </v-expansion-panels>&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                </v-tab-item>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                <v-tab-item>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                    <v-expansion-panels class="pa-3 " flat>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">1  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • AP CSA 는 어떤 과목이고 어떤 시험인가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Java 는 어떤 프로그래밍 언어인가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Java를 실행 할 수 있는 환경 구축하기&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 주석 (Comments)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 데이터 타입 (Data types in Java)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 연산자 (Operators in Java)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 입출력 (Input / Output)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 조건문 (Control Structures)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Errors and Exceptions in Java&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">2  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Classes and Objects</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Object(객체)란 무엇이고 어떻게 사용 되는가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Object vs. Primitive data types&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Wrapper Class 에 대한 이해&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Java 에서 제공하는 다양한 클래스들 알아보기&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Objects vs. Classes&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Java에서의 함수 (Methods) 작성법 및 이해&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Method Overloading&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Scope 개념 및 이해&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Reference vs. Primitive Data Types&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">3  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Class Inheritance (상속) 개념 및 이해&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Super Class vs. Sub Class&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Polymorphism&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Dynamic Binding&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Type Compatibility&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Google 과 Youtube 를 상속개념으로 구현 해보기&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">4  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 1차원 배열 (One-dimensional Array)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Array vs. ArrayList in Java&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 2차원 배열 (Two-dimensional Array)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">5  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • AP CSA 의 필수문제 Object Oriented Program Design&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Software Development&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Program Analysis&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        <v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <span style="font-size: 11px; font-weight: bold">6  </span><span&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                    style="font-size: 13px; font-weight: bold">Algorithms</span>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Recursive Methods&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Recursive 함수의 기본 형태&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • How to write recursive methods&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Recursion 을 사용하는 이유&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Recursion vs. Iteration&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • What are Algorithms?&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Sorting&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • Searching&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                                • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        </v-expansion-panel>&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">9  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">10  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->


        <!--                            &lt;!&ndash;                                    </v-expansion-panels>&ndash;&gt;-->

        <!--                            &lt;!&ndash;                                </v-tab-item>&ndash;&gt;-->
        <!--                            <v-tab-item>-->
        <!--                                <v-expansion-panels class="pa-3 " flat>-->
        <!--                                    <v-expansion-panel>-->
        <!--                                        <v-expansion-panel-header class="text-left">-->
        <!--                                            <span style="font-size: 11px; font-weight: bold">1  </span><span-->
        <!--                                                style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>-->
        <!--                                        </v-expansion-panel-header>-->
        <!--                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
        <!--                                            • AP CSA 는 어떤 과목이고 어떤 시험인가?-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Java 는 어떤 프로그래밍 언어인가?-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Java를 실행 할 수 있는 환경 구축하기-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 주석 (Comments)-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 데이터 타입 (Data types in Java)-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 연산자 (Operators in Java)-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 입출력 (Input / Output)-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 조건문 (Control Structures)-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Errors and Exceptions in Java-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 숙제 (Reading & MCP & Programming)-->
        <!--                                        </v-expansion-panel-content>-->
        <!--                                    </v-expansion-panel>-->
        <!--                                    <v-expansion-panel>-->
        <!--                                        <v-expansion-panel-header>-->
        <!--                                            <span style="font-size: 11px; font-weight: bold">2  </span><span-->
        <!--                                                style="font-size: 13px; font-weight: bold">Java의 Classes</span>-->
        <!--                                        </v-expansion-panel-header>-->
        <!--                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
        <!--                                            • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Object(객체)란 무엇이고 어떻게 사용 되는가?-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Object vs. Primitive data types-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Wrapper Class 에 대한 이해-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Java 에서 제공하는 다양한 클래스들 알아보기-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 숙제 (Reading & MCP & Programming)-->
        <!--                                        </v-expansion-panel-content>-->
        <!--                                    </v-expansion-panel>-->
        <!--                                    <v-expansion-panel>-->
        <!--                                        <v-expansion-panel-header>-->
        <!--                                            <span style="font-size: 11px; font-weight: bold">3  </span><span-->
        <!--                                                style="font-size: 13px; font-weight: bold">Classes and Objects</span>-->
        <!--                                        </v-expansion-panel-header>-->
        <!--                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">-->

        <!--                                            • Objects vs. Classes-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Java에서의 함수 (Methods) 작성법 및 이해-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Method Overloading-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Scope 개념 및 이해-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Reference vs. Primitive Data Types-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 숙제 (Reading & MCP & Programming)-->
        <!--                                        </v-expansion-panel-content>-->
        <!--                                    </v-expansion-panel>-->
        <!--                                    <v-expansion-panel>-->
        <!--                                        <v-expansion-panel-header>-->
        <!--                                            <span style="font-size: 11px; font-weight: bold">4  </span><span-->
        <!--                                                style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>-->
        <!--                                        </v-expansion-panel-header>-->
        <!--                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
        <!--                                            • Class Inheritance (상속) 개념 및 이해-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Super Class vs. Sub Class-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Polymorphism-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Dynamic Binding-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Type Compatibility-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Google 과 Youtube 를 상속개념으로 구현 해보기-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 숙제 (Reading & MCP & Programming)-->
        <!--                                        </v-expansion-panel-content>-->
        <!--                                    </v-expansion-panel>-->

        <!--                                    <v-expansion-panel>-->
        <!--                                        <v-expansion-panel-header>-->
        <!--                                            <span style="font-size: 11px; font-weight: bold">5  </span><span-->
        <!--                                                style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>-->
        <!--                                        </v-expansion-panel-header>-->
        <!--                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
        <!--                                            • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 1차원 배열 (One-dimensional Array)-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Array vs. ArrayList in Java-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 2차원 배열 (Two-dimensional Array)-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 숙제 (Reading & MCP & Programming)-->
        <!--                                        </v-expansion-panel-content>-->
        <!--                                    </v-expansion-panel>-->
        <!--                                    <v-expansion-panel>-->
        <!--                                        <v-expansion-panel-header>-->
        <!--                                            <span style="font-size: 11px; font-weight: bold">6  </span><span-->
        <!--                                                style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>-->
        <!--                                        </v-expansion-panel-header>-->
        <!--                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
        <!--                                            • AP CSA 의 필수문제 Object Oriented Program Design-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Software Development-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Program Analysis-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 숙제 (Reading & MCP & Programming)-->
        <!--                                        </v-expansion-panel-content>-->
        <!--                                    </v-expansion-panel>-->
        <!--                                    <v-expansion-panel>-->
        <!--                                        <v-expansion-panel-header>-->
        <!--                                            <span style="font-size: 11px; font-weight: bold">7  </span><span-->
        <!--                                                style="font-size: 13px; font-weight: bold">Recursion</span>-->
        <!--                                        </v-expansion-panel-header>-->
        <!--                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
        <!--                                            • Recursive Methods-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Recursive 함수의 기본 형태-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • How to write recursive methods-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Recursion 을 사용하는 이유-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Recursion vs. Iteration-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 숙제 (Reading & MCP & Programming)-->
        <!--                                        </v-expansion-panel-content>-->
        <!--                                    </v-expansion-panel>-->

        <!--                                    <v-expansion-panel>-->
        <!--                                        <v-expansion-panel-header>-->
        <!--                                            <span style="font-size: 11px; font-weight: bold">8  </span><span-->
        <!--                                                style="font-size: 13px; font-weight: bold">Sorting and Searching</span>-->
        <!--                                        </v-expansion-panel-header>-->
        <!--                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
        <!--                                            • What are Algorithms?-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Sorting-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            &nbsp;&nbsp;&nbsp;&nbsp;• Selection Sorts-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            &nbsp;&nbsp;&nbsp;&nbsp;• Insertion Sorts-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            &nbsp;&nbsp;&nbsp;&nbsp;• Merge Sorts-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            &nbsp;&nbsp;&nbsp;&nbsp;• Quick Sorts-->

        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • Searching-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            &nbsp;&nbsp;&nbsp;&nbsp;• Linear Search-->
        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            &nbsp;&nbsp;&nbsp;&nbsp;• Binary Search-->

        <!--                                            <div style="padding-bottom: 8px"></div>-->
        <!--                                            • 숙제 (Reading & MCP & Programming)-->
        <!--                                        </v-expansion-panel-content>-->
        <!--                                    </v-expansion-panel>-->

        <!--                                    &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8  </span><span&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

        <!--                                    &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">9  </span><span&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

        <!--                                    &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">10  </span><span&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->


        <!--                                </v-expansion-panels>-->

        <!--                            </v-tab-item>-->


        <!--                        </v-tabs>-->
        <!--                        &lt;!&ndash;                        <v-expansion-panels class="pa-3 " flat>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-header class="text-left">&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">1  </span><span&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • AP CSA 는 어떤 과목이고 어떤 시험인가?&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Java 는 어떤 프로그래밍 언어인가?&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Java를 실행 할 수 있는 환경 구축하기&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 주석 (Comments)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 데이터 타입 (Data types in Java)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 연산자 (Operators in Java)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 입출력 (Input / Output)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 조건문 (Control Structures)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Errors and Exceptions in Java&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;&ndash;&gt;-->

        <!--                        &lt;!&ndash;&lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2  </span><span&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Classes and Objects</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Object(객체)란 무엇이고 어떻게 사용 되는가?&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Object vs. Primitive data types&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Wrapper Class 에 대한 이해&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Java 에서 제공하는 다양한 클래스들 알아보기&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Objects vs. Classes&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Java에서의 함수 (Methods) 작성법 및 이해&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Method Overloading&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Scope 개념 및 이해&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Reference vs. Primitive Data Types&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">3  </span><span&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Class Inheritance (상속) 개념 및 이해&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Super Class vs. Sub Class&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Polymorphism&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Dynamic Binding&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Type Compatibility&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Google 과 Youtube 를 상속개념으로 구현 해보기&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

        <!--                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">4  </span><span&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 1차원 배열 (One-dimensional Array)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Array vs. ArrayList in Java&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 2차원 배열 (Two-dimensional Array)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">5  </span><span&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • AP CSA 의 필수문제 Object Oriented Program Design&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Software Development&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Program Analysis&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

        <!--                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">6  </span><span&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Algorithms</span>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Recursive Methods&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Recursive 함수의 기본 형태&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • How to write recursive methods&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Recursion 을 사용하는 이유&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Recursion vs. Iteration&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • What are Algorithms?&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Sorting&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    &nbsp;&nbsp;&nbsp;&nbsp; • Sorting&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • Searching&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
        <!--                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

        <!--                        &lt;!&ndash;&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->

        <!--                        &lt;!&ndash;&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">9  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->

        <!--                        &lt;!&ndash;&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">10  </span><span&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;&ndash;&gt;-->
        <!--                        &lt;!&ndash;&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;&ndash;&gt;-->


        <!--                        &lt;!&ndash;                        </v-expansion-panels>&ndash;&gt;-->
        <!--                        <div class="pt-10"></div>-->
        <!--                        <div class="py-4">-->
        <!--                            <v-btn to="/bootcamp/apcsa/curriculum" color="white" x-large width="80%"-->
        <!--                                   style="letter-spacing: 0.2px; font-weight: bold; border: 1px solid #1F383A "-->
        <!--                                   elevation="0"> 커리큘럼 자세히 보기 >-->
        <!--                            </v-btn>-->
        <!--                        </div>-->
        <!--                        <div class="py-10"></div>-->
        <!--                        <div>-->
        <!--                            <div style="font-size: 20px; font-weight: bold " class="text-left pl-5">-->
        <!--                                수업이 끝나면 <br/>-->
        <!--                                과제와 퀴즈 걱정도 끝납니다.-->
        <!--                            </div>-->

        <!--                            <div class="py-1"></div>-->
        <!--                            <div style="color: #6E767C; font-size: 14px" class="text-left pl-5">-->
        <!--                                개념과 시험대비만 하는 것이 아닌, <br/> 학교 AP CSA 숙제 및 퀴즈 대비도 <br/> 강사진이 함께 도와주고 있습니다.-->
        <!--                            </div>-->
        <!--                            <div class="py-3"></div>-->
        <!--                            &lt;!&ndash;                            <div class="pa-2 ma-2"&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                 style="height: 200px; border-radius: 10px; border-style: solid; border-width: thin">&ndash;&gt;-->
        <!--                            <img style="border-radius: 10px;  max-height: 100%; width: 90%"-->
        <!--                                 src="../../assets/cshomework1.png">-->
        <!--                            <img style="border-radius: 10px; max-height: 100%; width: 90%"-->
        <!--                                 src="../../assets/cshomework2.png">-->
        <!--                            <img style="border-radius: 10px; max-height: 100%; width: 90%"-->
        <!--                                 src="../../assets/cshomework3.png">-->
        <!--                            <div class="py-10"></div>-->
        <!--                            &lt;!&ndash;                            </div>&ndash;&gt;-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->

        <!--            </div>-->
        <!--            <div class="py-10"></div>-->
        <!--        </div>-->
        <!--        <div style="background-color: #111212; color: #FC434B " class="px-10 pt-10 pb-10 text-left">-->
        <!--            <div class="py-10"></div>-->
        <!--            <div class="hidden-md-and-up">-->
        <!--                <div class="secondary&#45;&#45;text font-weight-bold" style="font-size: 14px">-->
        <!--                    학습 지원-->
        <!--                </div>-->
        <!--                <div class="py-1"></div>-->
        <!--                <div class="white&#45;&#45;text text-h6 font-weight-bold" style="">-->
        <!--                    수업으로 끝나지 않습니다<br/>-->
        <!--                    최종 목표인 시험까지 함께 합니다.-->
        <!--                </div>-->
        <!--                <div class="py-3"></div>-->
        <!--                <div class="white&#45;&#45;text" style="font-size: 13px">-->
        <!--                    <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">-->
        <!--                        <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">1. 수강기간 제한 없는-->
        <!--                            인강 복습 지원-->
        <!--                            지원-->
        <!--                        </div>-->
        <!--                        <div style="color: black;" class="pa-3 text-center">-->
        <!--                            <img style="max-height: 100%; width: 90%"-->
        <!--                                 src="../../assets/apcs1.png">-->
        <!--                        </div>-->
        <!--                        <div style="color: black; font-size: 13px" class="pa-3 text-center">-->
        <!--                            모든 수업들은 녹화되어 인강 형태로 <br/> 제공 되어, 혹시라도 헷갈렸던 수업 내용이 <br/> 있다면 언제든 복습 가능합니다.-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="py-3"></div>-->
        <!--                <div class="white&#45;&#45;text" style="font-size: 13px">-->
        <!--                    <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">-->
        <!--                        <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">2. 과목 관련 항시 질답-->
        <!--                            제공-->
        <!--                        </div>-->
        <!--                        <div style="color: black;" class="pa-3 text-center">-->
        <!--                            <img style="max-height: 100%; width: 90%" src="../../assets/apcs2.jpeg">-->
        <!--                        </div>-->
        <!--                        <div style="color: black; font-size: 13px" class="pa-3 text-center">-->
        <!--                            학교 숙제부터 문제 풀이 등 <br/> AP CSA에 관한 질답 항시 제공-->
        <!--                        </div>-->
        <!--                    </div>-->

        <!--                </div>-->
        <!--                <div class="py-3"></div>-->
        <!--                <div class="white&#45;&#45;text" style="font-size: 13px">-->
        <!--                    <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">-->
        <!--                        <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">3. 그룹 스터디 제공-->
        <!--                        </div>-->
        <!--                        <div style="color: black;" class="pa-3 text-center">-->
        <!--                            <img style="max-height: 100%; width: 90%" src="../../assets/apcs3.jpeg">-->
        <!--                        </div>-->
        <!--                        <div style="color: black; font-size: 13px" class="pa-3 text-center">-->
        <!--                            혼자서는 아무래도 어려운 과목인 만큼 <br/> 수강생들과 조별로 주기적인 스터디그룹 제공-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--            <div class="center-horizontal">-->
        <!--                <div class="hidden-sm-and-down" style="max-width: 750px">-->
        <!--                    <div class="secondary&#45;&#45;text font-weight-bold" style="font-size: 14px">-->
        <!--                        학습 지원-->
        <!--                    </div>-->
        <!--                    <div class="py-1"></div>-->
        <!--                    <div class="white&#45;&#45;text text-h6 font-weight-bold" style="">-->
        <!--                        수업으로 끝나지 않습니다<br/>-->
        <!--                        최종 목표인 시험까지 함께 합니다.-->
        <!--                    </div>-->
        <!--                    <div class="py-3"></div>-->
        <!--                    <div class="white&#45;&#45;text" style="font-size: 13px">-->
        <!--                        <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">-->
        <!--                            <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">1. 기간 없는-->
        <!--                                인강 복습-->
        <!--                                지원-->
        <!--                            </div>-->
        <!--                            <div style="color: black;" class="pa-3 text-center">-->
        <!--                                <img style="max-height: 100%; width: 90%"-->
        <!--                                     src="../../assets/record-videos-coursifyme.jpeg">-->
        <!--                            </div>-->
        <!--                            <div style="color: black; font-size: 13px" class="pa-3 text-center">-->
        <!--                                모든 수업들은 녹화되어 인강 형태로 <br/> 제공 되어, 혹시라도 헷갈렸던 수업 내용이 <br/> 있다면 언제든 복습 가능합니다.-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="py-3"></div>-->
        <!--                    <div class="white&#45;&#45;text" style="font-size: 13px">-->
        <!--                        <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">-->
        <!--                            <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">2. 과목 관련-->
        <!--                                항시 질답 제공-->
        <!--                            </div>-->
        <!--                            <div style="color: black;" class="pa-3 text-center">-->
        <!--                                <img style="max-height: 100%; width: 90%" src="../../assets/consulting.jpg">-->
        <!--                            </div>-->
        <!--                            <div style="color: black; font-size: 13px" class="pa-3 text-center">-->
        <!--                                학교 숙제부터 문제 풀이 등 <br/> AP CSA에 관한 질답 항시 제공-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                    </div>-->
        <!--                    <div class="py-3"></div>-->
        <!--                    <div class="white&#45;&#45;text" style="font-size: 13px">-->
        <!--                        <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">-->
        <!--                            <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">3. 그룹 스터디-->
        <!--                                제공-->
        <!--                            </div>-->
        <!--                            <div style="color: black;" class="pa-3 text-center">-->
        <!--                                <img style="max-height: 100%; width: 90%" src="../../assets/study-group.jpeg">-->
        <!--                            </div>-->
        <!--                            <div style="color: black; font-size: 13px" class="pa-3 text-center">-->
        <!--                                혼자서는 아무래도 어려운 과목인 만큼 <br/> 수강생들과 조별로 주기적인 스터디그룹 제공-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--            <div class="py-10"></div>-->
        <!--        </div>-->


        <div class="text-center" style="background-color: #1D2022; color: white" id="sign-up-section">
            <div class="py-10"></div>

            <div style="color: white; font-size: 20px; font-weight: bold">
                모집합니다.
            </div>
            <div class="py-1"></div>
            <div style=" font-size: 15px; color: #AFB5BA">
                학교수업과 독학으로 <br/> 힘들었던 Computer Science <br/> 부트캠프 여정을 시작하세요.
            </div>
            <div class="py-3"></div>
            <div style="border-radius: 8px;   border: 1px solid #ccc; background-color: white; color: black"
                 class="mx-5">
                <div class="font-weight-bold pa-4" style="font-size: 15px">6기 모집 중</div>
                <div style="font-size: 12px; " class="">
                    <!--                    <v-row>-->
                    <!--                        <v-col>신청 접수</v-col>-->
                    <!--                        <v-col>02/14까지</v-col>-->
                    <!--                        <v-col></v-col>-->
                    <!--                    </v-row>-->
                    <div class="font-weight-bold">신청 접수</div>
                    <div>2주 후 마감</div>
                    <div class="py-3"></div>
                    <div class="font-weight-bold">수업 기간</div>
                    <div class="">2023년 12/10일부터</div>
                    <!--                </div>-->
                    <!--                <div style="font-size: 12px; " class="pl-3">-->
                    <!--                    <v-row>-->
                    <!--                        <v-col>수업 기간</v-col>-->
                    <!--                        <v-col>02/18 ~ 04/31</v-col>-->
                    <!--                        <v-col></v-col>-->
                    <!--                    </v-row>-->
                    <!--                </div>-->
                    <div class="py-3"></div>
                    <v-btn width="90%" to="/bootcamp/register" color="primary">
                        6기 참가 신청하기
                    </v-btn>
                    <div class="py-3"></div>
                </div>
            </div>

            <div class="py-2"></div>
            <!--            <v-card elevation="0" class="text-left mx-2" outlined>-->
            <!--                <div class="font-weight-bold pa-4 " style="font-size: 14px">진행 방법</div>-->
            <!--                <v-row style="font-size: 12px" class="px-4">-->
            <!--                    <v-col>-->
            <!--                        <v-icon size="18px">mdi-clock-outline</v-icon>&nbsp;&nbsp;주 1회 4시간반 수업-->
            <!--                    </v-col>-->
            <!--                    <v-col>-->
            <!--                        <v-icon size="18px">mdi-sail-boat</v-icon>-->
            <!--                        최대 10명-->
            <!--                    </v-col>-->
            <!--                </v-row>-->
            <!--                <v-row style="font-size: 12px; margin-top: 0px !important;" class="px-4">-->
            <!--                    <v-col>-->
            <!--                        <v-icon size="18px">mdi-calendar-multiselect-outline</v-icon>&nbsp;&nbsp;주말-->
            <!--                    </v-col>-->
            <!--                    <v-col>-->
            <!--                        <v-icon size="18px">mdi-monitor-dashboard</v-icon>&nbsp;&nbsp;Online Live-->
            <!--                    </v-col>-->
            <!--                </v-row>-->
            <!--                <div class="py-2"></div>-->
            <!--                <div class="font-weight-bold pa-4 " style="font-size: 14px">지원자격</div>-->

            <!--                <div style="font-size: 12px" class="px-4">-->
            <!--                    <div style="padding-bottom: 8px"></div>-->
            <!--                    - 문제해결 의지가 있는 학생<br/>-->
            <!--                    <div style="padding-bottom: 8px"></div>-->
            <!--                    - 프로그래밍 경험에 상관없이 배우고자 하는 학생<br/>-->
            <!--                    <div style="padding-bottom: 8px"></div>-->
            <!--                    - CS 인턴 및 관련활동을 준비중인 학생<br/>-->
            <!--                    <div style="padding-bottom: 8px"></div>-->
            <!--                    - 현재 AP CSA 수업을 듣거나 시험을 앞두고 있는 학생-->
            <!--                </div>-->
            <!--                <div>-->
            <!--                    <div class="font-weight-bold pa-4 " style="font-size: 14px">-->
            <!--                        &lt;!&ndash;                            상세 일정&ndash;&gt;-->
            <!--                    </div>-->
            <!--&lt;!&ndash;                    <Timeline></Timeline>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                    <v-timeline style="font-size: 12px; margin-left: -240px" class="px-4">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <v-timeline-item small>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="font-weight-bold">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                지원서 접수&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                간단한 정보와 지원 동기를 작성합니다.&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        </v-timeline-item>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <v-timeline-item small right class="">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="font-weight-bold">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                온라인 상담 및 인터뷰&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                아이비에드 상담사와 1:1 상담 인터뷰를 진행합니다.&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        </v-timeline-item>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <v-timeline-item small>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="font-weight-bold">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                합격 & 사전준비&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                최종 합격 후, 사전 강의, 사전스터디, 토이프로젝트에 참여 하면서 항해를 준비합니다.&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        </v-timeline-item>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        <v-timeline-item small right>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div class="font-weight-bold">&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                부트캠프 시작&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            <div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                                6주간의 여정을 시작합니다.&ndash;&gt;-->
            <!--                    &lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                        </v-timeline-item>&ndash;&gt;-->
            <!--                    &lt;!&ndash;                    </v-timeline>&ndash;&gt;-->
            <!--                </div>-->

            <!--            </v-card>-->
            <div class="py-10"></div>
        </div>

    </div>

</template>

<script>

    import CSPCurriculum from "../CSPCurriculum";
    export default {
        name: "FinalBootcamp",
        components:{ CSPCurriculum},
        methods: {
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__prev")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__next")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
            }
        }
    }
</script>

<style scoped>
    *{
        font-family: "Noto Sans KR";
    }
    .bootcamp-first {
        /*background-image: url("../../assets/APCSP/apcsp_background.png");*/
        /*background-size: cover; !* scales the image to cover the entire element *!*/
        /*background-size: contain; !* scales the image to fit within the element *!*/
        background-color: #313131;
        /*background-size: 100% auto; !* scales the image to fit the width of the element *!*/
        /*background-size: 500px 300px; !* sets the width and height of the image *!*/
        /*!*background-size: 50% 50%; !* sets the width and height as percentages of the element size *!*!*/

    }

    .for-whom {
        background-image: url("../../assets/entry-course-bg-m.png");
        color: white;
        background-color: #160d0c;
    }

    .circle-sketch-highlight {
        position: relative;
        left: 0.5em;
        font-family: Libre Franklin;
        font-size: 32px;
        font-weight: 500;
    }

    .circle-sketch-highlight:before {
        content: "";
        z-index: -1;
        left: -0.5em;
        top: -0.1em;
        border-width: 2px;
        border-style: solid;
        border-color: #ef8c22;
        position: absolute;
        border-right-color: transparent;
        width: 100%;
        height: 1em;
        transform: rotate(2deg);
        opacity: 0.7;
        border-radius: 50%;
        padding: 0.1em 0.25em;
    }

    .circle-sketch-highlight:after {
        content: "";
        z-index: -1;
        left: -0.5em;
        top: 0.1em;
        padding: 0.1em 0.25em;
        border-width: 2px;
        border-style: solid;
        border-color: #ef8c22;
        border-left-color: transparent;
        border-top-color: transparent;
        position: absolute;
        width: 100%;
        height: 1em;
        transform: rotate(-1deg);
        opacity: 0.7;
        border-radius: 50%;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>
