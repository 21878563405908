<template>
    <div>
<!--        {{lessonContent}}-->

        <div class="py-7"></div>

        <div v-for="(eachLesson,i) in sortedLessonContent" :key="i" class="">
<!--{{eachLesson.status}}-->
            <div v-if="eachLesson.status != 'CANCELLED'" class="pa-3">
            <v-card class="rounded-lg" style="  outline: 1px solid #dedede;" elevation="0">
                <div style="background-color: #1d2022; color: white; " class="text-center pa-3">
                    <span class="font-weight-bold">{{eachLesson.teacherName}}</span> - {{eachLesson.teacherEmail}}
                </div>
                <div class="pa-3" v-if="eachLesson.reportLink">
                    <v-row>
                        <v-col cols="3" class="text-center font-weight-bold">과거<br/> 수업일지</v-col>
                        <v-col cols="8" class="center-vertical text-center"><v-btn text outlined target="_blank" :href="eachLesson.reportLink">바로가기</v-btn></v-col>
                    </v-row>
                </div>
                <v-divider></v-divider>
                <div v-for="(eachClass, i) in sortedClasses(eachLesson.classes)" :key="i" class="pa-3">
<!--                    {{eachClass}}-->

                    <v-row>
                        <v-col cols="3" class="font-weight-bold text-center">수업일시</v-col>
                        <v-col cols="9">[{{eachClass.timezone}}] {{eachClass.classTimeAt.slice(0, -3)}} ~ <span v-if="eachClass.classEndedAt"> {{eachClass.classEndedAt.slice(0, -3)}}</span></v-col>
                    </v-row>
<!--                    <v-divider></v-divider>-->
                    <v-row>
                        <v-col cols="3" class="font-weight-bold text-center">수업진행 <br/>내용 및<br/> 이해도</v-col>
                        <v-col cols="9">{{eachClass.classReport}}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" class="font-weight-bold text-center">숙제</v-col>
                        <v-col cols="9">{{eachClass.homework}}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" class="font-weight-bold text-center">수업 점수</v-col>
                        <v-col cols="9">{{eachClass.lessonScore}}/3점</v-col>
                    </v-row>
                    <div class="py-3"></div>
                    <v-divider class="py-3"></v-divider>

                </div>
            </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    import LessonService from '../../services/lesson.service'

    export default {
        name: "StudentClassReports",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            sortedLessonContent() {
                return this.lessonContent.slice().sort((a, b) => {
                    return a.id - b.id;
                });
            },
            sortedClasses() {
                return (classes) => {
                    if (!Array.isArray(classes)) {
                        return [];
                    }
                    const filteredClasses = classes.filter((c) => {
                        const classTime = new Date(c.classTimeAt);
                        const targetDate = new Date('2023-05-13');
                        return classTime > targetDate;
                    });
                    return filteredClasses.slice().sort((a, b) => {
                        return a.classTimeAt.localeCompare(b.classTimeAt);
                    });
                };
            }
        },
        mounted() {
            LessonService.getLessonInfoByStudentId(this.currentUser.id).then(
                (response) => {
                    this.lessonContent = response.data;
                },
                (error) => {
                    this.lessonContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data() {
            return {
                lessonContent: [],
            }
        },
    }
</script>

<style scoped>
    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
