<template>
    <div>
        <div class="text-center font-weight-bold pa-4 main-bg"
             style="font-size: 35px; line-height: 40px; letter-spacing: -1px;
             color: white; font-family: LINESeedKR-Bd;   text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);">
            <!--            해외 유학생 대상<br/>-->
            <div class="py-16"></div>
            <div class="animate-charcter py-2">겨울특강<br/>Bootcamp</div>
            <div class="pb-6" style="font-size: 24px">
                <div>
                    12월 10일 ~ 1월 10일
                </div>
                <div>
                    1월 11일 ~ 2월 8일
                </div>
            </div>
            <v-btn to="/bootcamp/calendar" style="box-shadow: none;padding: 24px 20px;background-color: rgba(0, 0, 255,0.4);color: white;font-size: 20px;border-radius: 50px">일정 확인하기</v-btn>
            <div class="py-16"></div>
        </div>

        <!-- PC -->
        <div style="background-color: black" class="pa-4 hidden-xs-only">
            <div class="py-8"></div>
            <div class="px-4 pt-4 text-left font-weight-bold"
                 style="font-size: 23px; font-family: LINESeedKR-Bd; color: white ">
                혼자 하기 힘든 내신관리, <br/> 내게 맞는 코스를
                찾아보세요
            </div>
            <div class="px-4 text-left" style="font-size: 14px; color: white"> 국내 최초 미 명문대 출신 선생님과 함께하는 AP 부트캠프</div>
            <div class="py-4"></div>

            <div class="pa-4 d-flex flex-wrap">

                <!--  /bootcamp/academicwriting   -->
                <div>
                    <div class="" @click="moveToAcademicWriting"
                         style="cursor: pointer ;min-height: 283px ;max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px;"
                                   src="https://i.postimg.cc/tC3dVsxF/66067470-2426644867399125-2551228964873961472-n.jpg"
                                   alt="IVYED Interactive Academic Writing"
                                   max-height="190"
                                   width="100%"></v-img>
                        </div>
                        <div class="pt-4 pl-4" style="font-family: S-CoreDream-6Bold ">
                            Interactive Academic Writing
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            U of Chicago 석사 선생님
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>

                <!--  /bootcamp/apcsa   -->
                <div>
                    <div class="" @click="moveToAPCoumputerScienceA"
                         style="cursor: pointer ;min-height: 283px ;max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px;"
                                   src="https://i.postimg.cc/3JvSdtnq/apcsa.jpg" alt="IVYED AP Computer Science A"
                                   max-height="190"
                                   width="100%"></v-img>
                        </div>
                        <div class="pt-4 pl-4" style="font-family: S-CoreDream-6Bold ">
                            AP Computer Science A
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            아이비리그 Cornell University 졸업 (CS 전문가)
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>

                <!--  /bootcamp/apcsp    -->
                <div>
                    <div class="" @click="moveToAPCoumputerScienceP"
                         style="min-height: 283px ;cursor: pointer ;max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/7ZPKmqqF/apcsp.png" alt="IVYED AP Computer Science P"
                                   max-height="190"
                                   width="100%"></v-img>
                        </div>
                        <div class="pt-4 pl-4" style="font-family: S-CoreDream-6Bold ">
                            AP Computer Science P
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            아이비리그 Cornell University 졸업 (CS 전문가)
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>

                <!-- biology -->
                <div>
                    <div @click="moveToAPBiology" class=""
                         style="min-height: 283px ;cursor: pointer ;max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/4nksDZvG/maxresdefault.jpg" alt="IVYED AP Biology"
                                   max-height="190"
                                   width="100%"></v-img>
                        </div>
                        <div class="pt-4 pl-4" style="font-family: S-CoreDream-6Bold;">
                            AP Biology - 개념완성반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            Emory University Biology 전공 선생님
                        </div>
                    </div>
                </div>

                <div class="ma-2 "></div>
                <!--AP Chemistry-->
                <div>
                    <div @click="moveToAPChemPage" class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   :src="'https://i.postimg.cc/cHB2Qwkt/02.jpg'" alt="IVYED AP Chemistry"
                                   max-height="190"
                                   width="100%"></v-img>
                        </div>

                        <div class="pt-4 pl-4" style="font-family: S-CoreDream-6Bold;">
                            AP Chemistry - 개념완성반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            Emory University Biology 전공 선생님
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>
                <!-- AP physics C -->

                <div>
                    <div @click="moveToAPPhysicsCConceptPage" class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px;"
                                   src="https://i.postimg.cc/L5LzPcSX/maxresdefault.jpg" alt="IVYED AP physics C"
                                   max-height="190"
                            ></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;">
                            AP Physics C 개념마스터
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            교육경력 15년차 미국 국제학교 선생님
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>
                <!-- AP physics 1 problem -->
                <div>
                    <div @click="moveToAPPhysicsC1" class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px;"
                                   src="https://i.postimg.cc/1RWQv9RZ/ph-900x643.jpg" alt="IVYED AP physics 1"
                                   max-height="190"
                            ></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;">
                            AP Physics 1, 실전대비 문제풀이반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            교육경력 15년차 미국 국제학교 선생님
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>

                <!-- AP Chem 문제풀이 -->
                <div>
                    <div @click="moveToAPChemProblem" class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px;"
                                   src="https://i.postimg.cc/nLcmjGxp/maxresdefault.jpg" alt="IVYED AP physics 1"
                                   max-height="190"
                            ></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;">
                            AP Chemistry, 실전대비 문제풀이반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            교육경력 13년차 공대 박사 선생님
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>
                <!-- AP Bio 문제풀이 -->
                <div>
                    <div @click="moveToAPBioProblem" class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px;"
                                   src="https://i.postimg.cc/Pr0QkTfk/large-6a775d51-c16c-4c5d-aa14-089c2ea5b501.png" alt="IVYED AP physics 1"
                                   max-height="190"
                            ></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;">
                            AP Biology, 실전대비 문제풀이반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            교육경력 13년차 공대 박사 선생님
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>

                <!-- AP Calculus -->

                <div>
                    <div class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/W4JXBLkt/Adobe-Stock-164451366-scaled-pai56atu3x017pzdmigyhypuxh5mg2krsk19pmwy2e.jpg"
                                   alt="IVYED AP Calculus"
                                   max-height="190"
                                   width="100%"></v-img>
                        </div>

                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            AP Calculus
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>

                <!-- AP History -->

                <div>
                    <div class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal;min-height:320px;">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/wvtBn9yf/AP-USHistory-1200x628-1.png"
                                   alt="IVYED AP History"
                            ></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            AP U.S. History
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>
                <!-- AP World History-->

                <div>
                    <div class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal;min-height:320px;">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/sXSrpxZr/290756382-orig.png" alt="IVYED AP World History"
                            ></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            AP World History
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>
                <!-- SAT -->

                <div>
                    <div class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px;"
                                   src="https://i.postimg.cc/J7NDbcJG/SAT.jpg" alt="IVYED SAT"
                                   max-height="190"
                            ></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            SAT
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>

                <!-- book Club -->

                <div>
                    <div class=""
                         style="min-height: 283px ;cursor: pointer; max-height: 283px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 350px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/LXTSsXY6/hand-drawn-book-club-illustration-23-2149381725.png"
                                   alt="IVYED book Club"
                                   max-height="190"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            Book Club
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>
            </div>
            <div class="py-8"></div>
        </div>

        <!-- Mobile -->
        <div style="background-color: black" class="pa-4 hidden-sm-and-up ">
            <div class="py-8"></div>
            <div class="px-4 pt-4 text-left font-weight-bold"
                 style="font-size: 23px; font-family: LINESeedKR-Bd; color: white ">
                혼자 하기 힘든 내신관리, <br/> 내게 맞는 코스를
                찾아보세요
            </div>
            <div class="px-4 text-left" style="font-size: 14px; color: white"> 국내 최초 미 명문대 출신 선생님과 함께하는 AP 부트캠프</div>
            <div class="py-4"></div>

            <div class="pa-4 d-flex flex-wrap ">

                <!--  /bootcamp/academicwriting  -->
                <div>
                    <div class="" @click="moveToAcademicWriting"
                         style="cursor: pointer ;min-height: 240px;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/tC3dVsxF/66067470-2426644867399125-2551228964873961472-n.jpg"
                                   alt="IVYED Interactive Academic Writing"
                                   max-height="190"
                                   width="100%"></v-img>
                        </div>
                        <div class="pt-4 pl-2" style="font-family: S-CoreDream-6Bold ">
                            Interactive Academic Writing
                        </div>
                        <div class="px-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            U of Chicago 석사 선생님
                        </div>
                    </div>
                </div>
                <div class="ma-2 "></div>
                <!-- AP Computer Science A -->
                <div class="ma-2 "></div>
                <div>
                    <div class="" @click="moveToAPCoumputerScienceA"
                         style="cursor: pointer ;min-height: 240px;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/3JvSdtnq/apcsa.jpg" alt="IVYED AP Computer Science A"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2" style="font-family: S-CoreDream-6Bold ">
                            AP Computer Science A
                        </div>
                        <div class="px-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            아이비리그 Cornell University 졸업 (CS 전문가)
                        </div>
                    </div>
                </div>
                <!-- AP Computer Science P -->
                <div class="ma-2 "></div>
                <div>
                    <div class="" @click="moveToAPCoumputerScienceP"
                         style="cursor: pointer ;min-height: 240px;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/7ZPKmqqF/apcsp.png" alt="IVYED AP Computer Science P"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2" style="font-family: S-CoreDream-6Bold ">
                            AP Computer Science P
                        </div>
                        <div class="px-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            아이비리그 Cornell University 졸업 (CS 전문가)
                        </div>
                    </div>
                </div>

                <!--       Biology         -->
                <div class="ma-2 "></div>
                <div>
                    <div @click="moveToAPBiology" class=""
                         style="min-height: 240px;;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/4nksDZvG/maxresdefault.jpg" alt="IVYED AP Biology"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2" style="font-family: S-CoreDream-6Bold;">
                            AP Biology - 개념완성반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            Emory University Biology 전공 선생님
                        </div>
                    </div>
                </div>
                <!--AP Chemistry-->
                <div class="ma-2"></div>
                <div>
                    <!--                    <div class="" @click="moveToAPChemPage" style="cursor:pointer; max-height: 223px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">-->
                    <div @click="moveToAPChemPage" class=""
                         style="min-height: 240px;;cursor:pointer; max-height: 223px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="">
                            <v-img class="" style="border-radius: 15px"
                                   :src="'https://i.postimg.cc/cHB2Qwkt/02.jpg'" alt="IVYED AP Chemistry"
                                   max-height="120"
                                   width="100%"></v-img>
                        </div>
                        <div class="pt-4 pl-2" style="font-family: S-CoreDream-6Bold;">
                            AP Chemistry - 개념완성반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            Emory University Biology 전공 선생님
                        </div>
                    </div>
                </div>
                <!-- AP physics C -->
                <div class="ma-2 "></div>
                <div>
                    <div @click="moveToAPPhysicsCConceptPage" class=""
                         style="min-height: 240px;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/L5LzPcSX/maxresdefault.jpg" alt="IVYED AP physics C"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;">
                            AP Physics C 개념마스터 (Only English)
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            교육경력 15년차 미국 국제학교 선생님
                        </div>
                    </div>
                </div>
                <!-- AP physics 1 Problem -->
                <div class="ma-2 "></div>
                <div>
                    <div @click="moveToAPPhysicsC1" class=""
                         style="min-height: 240px;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/1RWQv9RZ/ph-900x643.jpg" alt="IVYED AP physics 1"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;">
                            AP Physics 1, 실전대비 문제풀이반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            교육경력 15년차 미국 국제학교 선생님
                        </div>
                    </div>
                </div>

                <!-- AP Chem Problem -->
                <div class="ma-2 "></div>
                <div>
                    <div @click="moveToAPChemProblem" class=""
                         style="min-height: 240px;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/nLcmjGxp/maxresdefault.jpg" alt="IVYED AP physics 1"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;">
                            AP Chemistry, 실전대비 문제풀이반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            교육경력 13년차 공대 박사 선생님
                        </div>
                    </div>
                </div>
                <!-- AP Bio Problem -->
                <div class="ma-2 "></div>
                <div>
                    <div @click="moveToAPBioProblem" class=""
                         style="min-height: 240px;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/Pr0QkTfk/large-6a775d51-c16c-4c5d-aa14-089c2ea5b501.png" alt="IVYED AP physics 1"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;">
                            AP Biology, 실전대비 문제풀이반
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            교육경력 13년차 공대 박사 선생님
                        </div>
                    </div>
                </div>
                <!-- AP Calculus -->
                <div class="ma-2 "></div>

                <div>
                    <div class=""
                         style="min-height: 240px;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <!--                      <div class="" @click="moveToAPCalculusHenry" style="cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">  -->
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/W4JXBLkt/Adobe-Stock-164451366-scaled-pai56atu3x017pzdmigyhypuxh5mg2krsk19pmwy2e.jpg"
                                   alt="IVYED AP Chemistry"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            AP Calculus
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>
                <!-- AP History -->
                <div class="ma-2 "></div>
                <div>
                    <div class=""
                         style="min-height: 240px;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/wvtBn9yf/AP-USHistory-1200x628-1.png"
                                   alt="IVYED AP History"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            AP U.S. History
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>

                <!-- AP World History -->
                <div class="ma-2 "></div>
                <div>
                    <div class=""
                         style="min-height: 240px;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/sXSrpxZr/290756382-orig.png" alt="IVYED AP World History"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            AP World History
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>
                <!-- SAT -->
                <div class="ma-2 "></div>
                <div>
                    <div class=""
                         style="min-height: 240px;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/J7NDbcJG/SAT.jpg" alt="IVYED SAT"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            SAT
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>

                <!-- book Club -->
                <div class="ma-2 "></div>
                <div>
                    <div class=""
                         style="min-height: 240px;cursor: pointer ;max-height: 310px ;border: 1px solid #f6f6f6; border-radius: 15px; background-color: #f6f6f6; max-width: 250px; margin-bottom: 20px">
                        <div class="center-horizontal">
                            <v-img class="pa-1" style="border-radius: 15px"
                                   src="https://i.postimg.cc/LXTSsXY6/hand-drawn-book-club-illustration-23-2149381725.png"
                                   alt="IVYED book Club"
                                   max-height="140"></v-img>
                        </div>
                        <div class="pt-4 pl-2"
                             style="font-family: S-CoreDream-6Bold;text-decoration: line-through;color: grey ">
                            Book Club
                        </div>
                        <div class="pl-4 pb-3" style="font-family: S-CoreDream-5Medium; font-size: 13px">
                            향후 개강 예정
                        </div>
                    </div>
                </div>


            </div>


            <div class="py-8"></div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "AllBootcamp",
        data: ()=> ({

        }),
        methods: {
            moveToBootcamp() {
                this.$router.push("/bootcamp/apcsa");
            },
            moveToCSPBootcamp() {
                this.$router.push("/bootcamp/apcsp");
            },
            moveToBookClub() {
                this.$router.push("/bookclub");

            },
            moveToAPCalculusAshley() {
                this.$router.push('/bootcamp/apcalcash');
            },
            moveToAPCalculusHenry() {
                this.$router.push('/bootcamp/apcalchenry');
            },
            moveToAPChemPage() {
                this.$router.push('/bootcamp/apchemistry');
            },
            moveToAPCoumputerScienceA() {
                this.$router.push('/bootcamp/apcsa');
            },
            moveToAPCoumputerScienceP() {
                this.$router.push('/bootcamp/apcsp');
            },
            moveToAcademicWriting() {
                this.$router.push('/bootcamp/academicwriting');
            },
            moveToAPBiology() {
                this.$router.push('/bootcamp/apbiology');
            },
            moveToAPPhysicsCConceptPage(){
                this.$router.push('/bootcamp/apphysicscconcept');
            },
            moveToAPPhysicsC1(){
                this.$router.push('/bootcamp/apphysicsproblem');
            },
            moveToAPChemProblem(){
                this.$router.push('/bootcamp/apchemistryproblem');
            },
            moveToAPBioProblem(){
                this.$router.push('/bootcamp/apbiologyproblem');
            }
        }
    }
</script>

<style scoped>

    .summerCampFont {
        background: #121FCF;
        background: linear-gradient(to right, #121FCF 0%, #CF1512 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .animate-charcter {
        text-transform: uppercase;
        background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                #ff1361 67%,
                #fff800 100%
        );
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: textclip 5s linear infinite;
        display: inline-block;
        font-size: 35px;
    }

    @keyframes textclip {
        to {
            background-position: 200% center;
        }
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-bg {
        /*background: linear-gradient(45deg, #7FFF87 0%, #AAC7FF 50%, #FF94CA 100%);*/
        /*background: linear-gradient(135deg, #7FFF87 0%, #AAC7FF 50%, #FF94CA 100%);*/
        /*background: linear-gradient(-18.7686deg, #FF94CA 0%, #AAC7FF 60%, #7FFF87 100%);*/
        background: linear-gradient(-18.7686deg, #FF94CA 0%, #AAC7FF 40%, #AAC7FF 52.017%, #AAC7FF 60%, #7FFF87 100%);

        /*background: linear-gradient(-18.7686deg, #7FFF87 0%, #AAC7FF 33%, #FF94CA 67%, #FF94CA 100%);*/
        /*background: linear-gradient(-18.7686deg, #7FFF87 0%, #AAC7FF 50%, #FF94CA 100%);*/


    }
</style>
