<template>
    <div class="slider-container">
        <div class="slider" style="">
            <div class="slide-track">
<!--            <div class="">-->

                <div class="slide">
                    <div>
                        <div style=" "
                                class="review-card-new">
                            <div class="">
                                <FiveStarIcons></FiveStarIcons>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                10학년 김O미 학생 <br/> 수강기간 7개월
                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                SAT Reading / Math
                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                9학년 끝난 여름방학에 SAT 학원 두달 다녀봤는데 성적이 오르지 않아 상담 받아보게 됐어요. SAT는 절대 두달안에 점수 낼 수 없다고 말씀 해주시고 천천히
                                1년동안 준비해야 한다고 해서 과외 시작했어요. 1년 동안 매주 단어외우고 문제풀고 모르는거 배우고 하다보니 11학년 들어가는 여름방학때 점수가 1500점이
                                넘었어요!!

                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FiveStarIcons></FiveStarIcons>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                11학년 <br/> 수강기간 8개월
                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                AP Calculus
                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                칸 아카데미 인강 수강으로 기본 개념을 잡고 문제를 푸는 형태로 공부를 하고 있었는데요. 복습을 해봐도 도저히 답이 나오질 않았습니다. 이대로 포기해야 되나
                                싶었지만 과외를 시작하고 역시나 혼자 끙끙 앓았던 시간이 아까울 정도로 간단한 문제들이었더라구요.. 순간 허탈해지면서 동시에 안도감이 밀려왔습니다.


                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FourPointFiveStars></FourPointFiveStars>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                국제학교 학생 보호자 <br/>수강기간 12개월

                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                IB Math HL

                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                아이가 IB Math HL을 어려워하여 어떻게 도움을 줄 수 있을까 하다 아이비에드를 찾게 되었습니다. 어려운 수학 과목이었지만, 아이비에드의 과외를 통해
                                수학에 대한 자신감을 키울 수 있었습니다. 복잡한 수학 문제와 이론을 꾸준한 학습과 연습을 통해 이해하고, IB Math HL 시험에서 높은 점수를 받아
                                아이가 자신감을 얻게 되었어요.
                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FourPointFiveStars></FourPointFiveStars>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                보딩스쿨 학생 보호자<br/> 수강기간 9개월


                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                AP Biology


                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                우리 애가 AP Biology를 준비하면서 아이비에드의 도움을 받았습니다. <br/> 생명과학의 복잡한 주제를 명확하게 이해하고 실험 및 문제 풀이를 연습하는
                                과정에서 점차 자신감을 얻게 되었습니다. 아이비에드의 과외 덕분에 AP Biology 시험에서 좋은 성적을 거두어 대학진학에 도움이 되었습니다.

                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FourPointFiveStars></FourPointFiveStars>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                김O섭 <br/> 11학년



                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                AP Computer Science A

                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                학교 선생님 수업 설명이 너무 부족해서 시작하게 됐어요. 동안 너무 힘들었는데, 과외수업을 듣고 시작하고 난 뒤 부터 드디어 Java에 대해 이해하기 시작하고
                                퀴즈도 만점 받고 있어요. 수업시간 외에도 질문에 언제나 답해주시고 추가 관리까지 해주셔서 감사해요.
                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FiveStarIcons></FiveStarIcons>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                8학년 <br/>Day school
                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                English (Writing)
                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                안녕하세요! 8학년 학생입니다. 8학년부터 학교 수업에서 Essay를 쓰게되면서부터 항상 A받던 English 수업 성적이 확 떨어졌어요. 아이비에드
                                과외선생님한테 sentence structure 등 구조적인 거 배우고, 같이 에세이 첨삭해주시고, 책도 같이 읽고 하다보니 writing 점수가 올랐어요.

                            </div>
                        </div>

                    </div>

                </div>

                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FiveStarIcons></FiveStarIcons>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                10학년 김O미 학생 <br/> 수강기간 7개월
                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                SAT Reading / Math
                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                9학년 끝난 여름방학에 SAT 학원 두달 다녀봤는데 성적이 오르지 않아 상담 받아보게 됐어요. SAT는 절대 두달안에 점수 낼 수 없다고 말씀 해주시고 천천히
                                1년동안 준비해야 한다고 해서 과외 시작했어요. 1년 동안 매주 단어외우고 문제풀고 모르는거 배우고 하다보니 11학년 들어가는 여름방학때 점수가 1500점이
                                넘었어요!!

                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FiveStarIcons></FiveStarIcons>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                11학년 <br/> 수강기간 8개월
                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                AP Calculus
                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                칸 아카데미 인강 수강으로 기본 개념을 잡고 문제를 푸는 형태로 공부를 하고 있었는데요. 복습을 해봐도 도저히 답이 나오질 않았습니다. 이대로 포기해야 되나
                                싶었지만 과외를 시작하고 역시나 혼자 끙끙 앓았던 시간이 아까울 정도로 간단한 문제들이었더라구요.. 순간 허탈해지면서 동시에 안도감이 밀려왔습니다.


                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FourPointFiveStars></FourPointFiveStars>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                국제학교 학생 보호자 <br/>수강기간 12개월

                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                IB Math HL

                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                아이가 IB Math HL을 어려워하여 어떻게 도움을 줄 수 있을까 하다 아이비에드를 찾게 되었습니다. 어려운 수학 과목이었지만, 아이비에드의 과외를 통해
                                수학에 대한 자신감을 키울 수 있었습니다. 복잡한 수학 문제와 이론을 꾸준한 학습과 연습을 통해 이해하할 수 있었습니다.
                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FourPointFiveStars></FourPointFiveStars>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                보딩스쿨 학생 보호자<br/> 수강기간 9개월


                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                AP Biology


                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                우리 애가 AP Biology를 준비하면서 아이비에드의 도움을 받았습니다. <br/> 생명과학의 복잡한 주제를 명확하게 이해하고 실험 및 문제 풀이를 연습하는
                                과정에서 점차 자신감을 얻게 되었습니다. 아이비에드의 과외 덕분에 AP Biology 시험에서 좋은 성적을 거두어 대학진학에 도움이 되었습니다.

                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FourPointFiveStars></FourPointFiveStars>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                김O섭 <br/> 11학년



                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                AP Computer Science A

                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                학교 선생님 수업 설명이 너무 부족해서 시작하게 됐어요. 동안 너무 힘들었는데, 과외수업을 듣고 시작하고 난 뒤 부터 드디어 Java에 대해 이해하기 시작하고
                                퀴즈도 만점 받고 있어요. 수업시간 외에도 질문에 언제나 답해주시고 추가 관리까지 해주셔서 감사해요.
                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FiveStarIcons></FiveStarIcons>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                8학년 <br/>Day school
                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                English (Writing)
                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                안녕하세요! 8학년 학생입니다. 8학년부터 학교 수업에서 Essay를 쓰게되면서부터 항상 A받던 English 수업 성적이 확 떨어졌어요. 아이비에드
                                과외선생님한테 sentence structure 등 구조적인 거 배우고, 같이 에세이 첨삭해주시고, 책도 같이 읽고 하다보니 writing 점수가 올랐어요.

                            </div>
                        </div>

                    </div>

                </div>

                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FiveStarIcons></FiveStarIcons>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                10학년 김O미 보호자 <br/> 수강기간 7개월
                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                아이가 공부에 흥미를 느끼게 되었어요.
                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                9학년 끝난 여름방학에 SAT 학원 두달 다녀봤는데 성적이 오르지 않아 상담 받아보게 됐어요. SAT는 절대 두달안에 점수 낼 수 없다고 말씀 해주시고 천천히
                                1년동안
                                준비해야 한다고 해서 과외 시작했어요. 1년 동안 매주 단어외우고 문제풀고 모르는거 배우고 하다보니 11학년 들어가는 여름방학때 점수가 1500점이
                                넘었어요!!

                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FiveStarIcons></FiveStarIcons>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                11학년 <br/> 수강기간 8개월
                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                AP Calculus
                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                칸 아카데미 인강 수강으로 기본 개념을 잡고 문제를 푸는 형태로 공부를 하고 있었는데요. 복습을 해봐도 도저히 답이 나오질 않았습니다. 이대로 포기해야 되나
                                싶었지만 과외를 시작하고 역시나 혼자 끙끙 앓았던 시간이 아까울 정도로 간단한 문제들이었더라구요.. 순간 허탈해지면서 동시에 안도감이 밀려왔습니다.


                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FourPointFiveStars></FourPointFiveStars>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                국제학교 학생 보호자 <br/>수강기간 12개월

                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                IB Math HL

                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                아이가 IB Math HL을 어려워하여 어떻게 도움을 줄 수 있을까 하다 아이비에드를 찾게 되었습니다. 어려운 수학 과목이었지만, 아이비에드의 과외를 통해
                                수학에 대한 자신감을 키울 수 있었습니다. 복잡한 수학 문제와 이론을 꾸준한 학습과 연습을 통해 이해할 수 있었습니다.
                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FourPointFiveStars></FourPointFiveStars>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                보딩스쿨 학생 보호자<br/> 수강기간 9개월


                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                AP Biology


                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                우리 애가 AP Biology를 준비하면서 아이비에드의 도움을 받았습니다. <br/> 생명과학의 복잡한 주제를 명확하게 이해하고 실험 및 문제 풀이를 연습하는
                                과정에서 점차 자신감을 얻게 되었습니다. 아이비에드의 과외 덕분에 AP Biology 시험에서 좋은 성적을 거두어 대학진학에 도움이 되었습니다.

                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FourPointFiveStars></FourPointFiveStars>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                김O섭 <br/> 11학년



                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                AP Computer Science A

                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                학교 선생님 수업 설명이 너무 부족해서 시작하게 됐어요. 동안 너무 힘들었는데, 과외수업을 듣고 시작하고 난 뒤 부터 드디어 Java에 대해 이해하기 시작하고
                                퀴즈도 만점 받고 있어요. 수업시간 외에도 질문에 언제나 답해주시고 추가 관리까지 해주셔서 감사해요.
                            </div>
                        </div>

                    </div>

                </div>
                <div class="slide">
                    <div>
                        <div style=" "
                             class="review-card-new">
                            <div class="">
                                <FiveStarIcons></FiveStarIcons>
                            </div>
                            <div class="py-2"></div>
                            <div class="text-center" style="color: #b7b7b7; font-size: 13px">
                                8학년 <br/>Day school
                            </div>
                            <div class="py-5"></div>
                            <div class="text-center" style="font-size: 24px; font-weight: bold">
                                English (Writing)
                            </div>
                            <div class="py-2"></div>
                            <div class="">
                                안녕하세요! 8학년 학생입니다. 8학년부터 학교 수업에서 Essay를 쓰게되면서부터 항상 A받던 English 수업 성적이 확 떨어졌어요. 아이비에드
                                과외선생님한테 sentence structure 등 구조적인 거 배우고, 같이 에세이 첨삭해주시고, 책도 같이 읽고 하다보니 writing 점수가 올랐어요.

                            </div>
                        </div>

                    </div>

                </div>



            </div>
        </div>
    </div>
</template>

<script>

    // import FiveStars from "../FiveStars";

    import FiveStarIcons from "../FiveStarIcons";
    import FourPointFiveStars from "../FourPointFiveStars";
    export default {

        name: "ReviewCarousel",
        components: {
            FourPointFiveStars,
            FiveStarIcons
            // FiveStars
        }
    }
</script>

<style scoped>
    .slider-container {
        overflow: hidden;

        max-width: 100%; /* Adjust the maximum width as needed */
        margin: 0 auto; /* Center the container */
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        77% {
            transform: translateX(calc(-250px * 7));
        }
    }

    .slider {
        /*box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);*/
        /*height: 100px;*/
        margin: auto;
        overflow: hidden;
        position: relative;
        /*width: 960px;*/
    }

    .slider::before, .slider::after {
        /*background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 77%);*/
        content: "";
        /*height: 100px;*/
        position: absolute;
        /*width: 200px;*/
        z-index: 2;
    }

    .slider::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    .slider::before {
        left: 0;
        top: 0;
    }

    .slider .slide-track {
        animation: scroll 160s linear infinite;
        display: flex;
        width: calc(250px * 14);
    }

    .slider .slide {
        /*background-color: #F6F6F6;*/
        /*height: 100px;*/
        width: 310px;
    }

    .circle {
        background: transparent;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        /*box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;*/
    }

    .review-card {
        height: 700px;
    }

    .slide {
        width: 100%;
        height: 100%;
        text-align: center;
        /*font-size: 24px;*/
        display: flex;
        align-items: center;
        justify-content: center;
        /*position: absolute;*/
    }
    .review-card-new {
        background-color: #f9f9f9 !important;
        height: 600px;
        width: 300px;
        margin-right: 14px;
        margin-left: 14px;
        /*display: flex;*/
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 70px 30px;
        border-radius: 22px;
    }
</style>
