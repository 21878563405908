<template>
    <div>
        <div class="py-5"></div>

        <div class="container ">

                <div class="hidden-md-and-up text-center">
                    <div class="center-horizontal">
                        <div class="circle-with-checkmark"></div>
                    </div>
                </div>

                <div class="hidden-md-and-down text-center">
                    <div class="center-horizontal">
                        <div class="circle-with-checkmark-desktop"></div>
                    </div>
                </div>

                <div class="text-container hidden-lg-and-up">
                    <div>
                        <div class="py-3"></div>

                        <div class="text-center font-weight-bold" >
                            <div style="letter-spacing: 0px; font-size: 30px !important;"><span style="font-size: 30px; color: #A30717">IVY</span><span style="color: #A30717; font-size: 30px">ED</span> 경험해보니</div>
                            <div style="letter-spacing: 0px; font-size: 30px !important;"><span style="">정말 달랐어요</span></div>
                            <div class="py-1"></div>
<!--                            <div style="color: #838397; font-size: 15px; font-weight: normal">-->
<!--                                상위 1%의 과외를 받아보세요!-->
<!--                            </div>-->
                        </div>

                    </div>
<!--                    <div class="py-10"></div>-->

                </div>
                <div class="text-container hidden-md-and-down">
                    <div class="py-5"></div>
                    <div>
                        <div class="text-center font-weight-bold" >
                            <div style="letter-spacing: 0px; font-size: 34px !important;"><span style="font-size: 34px; color: #A30717">IVY</span><span style="color: #A30717; font-size: 31px">ED</span> 경험해보니</div>
                            <div style="letter-spacing: 0px; font-size: 34px !important;"><span style="">정말 달랐어요</span></div>
                            <div class="py-1"></div>
                            <!--                            <div style="color: #838397; font-size: 15px; font-weight: normal">-->
                            <!--                                상위 1%의 과외를 받아보세요!-->
                            <!--                            </div>-->
                        </div>

                    </div>
                    <!--                    <div class="py-10"></div>-->

                </div>

                <!--                <div class=" hidden-md-and-down text-center">-->
<!--                    <div>-->
<!--                        <div class="py-5"></div>-->
<!--                        <div class="">-->
<!--                            <div style="letter-spacing: -1px; font-size: 25px !important;">성적이 오를 수 밖에 없는</div>-->
<!--                            <div style="letter-spacing: -1px; font-size: 25px !important;">IVYED만의 유학 과외</div>-->
<!--                            <div class="py-1"></div>-->
<!--                            <div style="color: #838397; font-size: 15px; font-weight: normal">-->
<!--                                상위 1%의 과외를 받아보세요!-->
<!--                            </div>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                                        <div class="py-10"></div>-->

<!--                </div>-->

            </div>

    </div>
</template>

<script>
    export default {
        name: "ReviewPage1",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>

    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
    .circle-with-checkmark::before {
        content: "\0021"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: #A20916; /* Checkmark color (white in this case) */
        font-weight: bold;
    }
    .circle-with-checkmark {
        position: relative;
        width: 40px;
        height: 40px;
        /*border: 2px solid #ecced04d; !* Circle border color *!*/
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced04d; /* Circle background color (white in this case) */
    }
    .circle-with-checkmark-desktop::before {
        content: "\0021"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: TheJamsil5Bold;
        font-size: 22px;
        color: #A20916; /* Checkmark color (white in this case) */
        font-weight: bold;
    }
    .circle-with-checkmark-desktop {
        position: relative;
        width: 60px;
        height: 60px;
        /*border: 2px solid #ecced04d; !* Circle border color *!*/
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced04d; /* Circle background color (white in this case) */
    }
</style>

