<template>
    <div>
        <v-icon color="#ffc107">mdi-star</v-icon>
        <v-icon color="#ffc107">mdi-star</v-icon>
        <v-icon color="#ffc107">mdi-star</v-icon>
        <v-icon color="#ffc107">mdi-star</v-icon>
        <v-icon color="#ffc107">mdi-star</v-icon>
    </div>
</template>

<script>
    export default {
        name: "FiveStarIcons"
    }
</script>

<style scoped>

</style>
