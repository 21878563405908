<template>
    <div>


        <div style="background-color: #f6f6f6;width: 100%" class="hidden-lg-and-up center-horizontal text-center">

            <div class="py-10"></div>
            <div class="pa-5" style="font-family: S-CoreDream-7ExtraBold; font-size: 24px">
                아래와 같은 고민을 했다면,
            </div>
            <div class="py-4"></div>
            <img src="../../../assets/chatExample.png" alt="IVYED Chat" style="max-width: 450px" width="80%">
            <div class="py-4"></div>
            <div class="pa-5" style="font-family: S-CoreDream-7ExtraBold; font-size: 24px">
                IVYED를 이용해보세요!
            </div>
            <div class="py-10"></div>
        </div>
        <div style="background-color: #f6f6f6;width: 100%" class="hidden-md-and-down center-horizontal text-center">

            <div class="py-10"></div>
            <div class="pa-5" style="font-family: S-CoreDream-7ExtraBold; font-size: 34px">
                아래와 같은 고민을 했다면,

            </div>
            <div class="py-8"></div>
            <img src="../../../assets/chatExample.png" alt="IVYED Chat" style="max-width: 550px" width="80%">
            <div class="py-8"></div>
            <div class="pa-5" style="font-family: S-CoreDream-7ExtraBold; font-size: 34px">
                IVYED를 이용해보세요!
            </div>
            <div class="py-10"></div>
        </div>

        <div class="second-review-section">
            <div class="center-horizontal" style="">
                <div class="" style="width: 100%; ">
                    <div style="" class="center-horizontal">
                        <AboutIvyed1></AboutIvyed1>
                    </div>
                    <div class="center-horizontal">
                        <div>
                            <div class="py-7"></div>
                            <AboutIvyed2></AboutIvyed2>
                            <div class="py-2"></div>
                            <AboutIvyed3></AboutIvyed3>
                            <div class="py-2"></div>
                            <AboutIvyed4></AboutIvyed4>
                        </div>

                    </div>
                </div>
            </div>
            <div class="py-10"></div>
        </div>
        <div style="background-color: #F6F6F6">
            <div class="py-10"></div>
            <AboutIvyed5></AboutIvyed5>

            <div class="py-5"></div>
            <div class="center-horizontal text-center">
                <div class="" style="max-width: 600px;">
                    <AboutIvyed6></AboutIvyed6>
                    <div class="py-1"></div>
                    <AboutIvyed7></AboutIvyed7>

                </div>
                <div class="py-10"></div>
            </div>


        </div>
    </div>
</template>

<script>
    import AboutIvyed1 from "./Sections/AboutIVYED/AboutIvyed1";
    import AboutIvyed2 from "./Sections/AboutIVYED/AboutIvyed2";
    import AboutIvyed3 from "./Sections/AboutIVYED/AboutIvyed3";
    import AboutIvyed4 from "./Sections/AboutIVYED/AboutIvyed4";
    import AboutIvyed5 from "./Sections/AboutIVYED/AboutIvyed5";
    import AboutIvyed6 from "./Sections/AboutIVYED/AboutIvyed6";
    import AboutIvyed7 from "./Sections/AboutIVYED/AboutIvyed7";

    export default {
        name: "AboutIVYED",
        components: {AboutIvyed7, AboutIvyed6, AboutIvyed5, AboutIvyed4, AboutIvyed3, AboutIvyed2, AboutIvyed1}
    }
</script>

<style scoped>

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .second-review-section {
        /*background-image: linear-gradient(92.88deg, #DE2828 9.16%, #FF6E6E 64.72%);*/
        /*box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;*/
        height: 100%;
        background-color: white !important;
        /*height: calc(100vh - 70px);*/
        /*width: 100%;*/
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        object-fit: contain;
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        /*padding-left: 30px;*/
        justify-content: center;
        align-items: normal;
    }
</style>
