export const apbiologyProblemData = {
    bootcamp:[{
        classId: 1, class: {
            imgUrl: "https://i.postimg.cc/Pr0QkTfk/large-6a775d51-c16c-4c5d-aa14-089c2ea5b501.png",
            imgAlt: "IVYED AP Biology",
            title: "AP Biology, 오직 5점 달성을 위한 문제풀이반",
            subheading: "Biochemistry, Genetics 집중",
            description: "Biology 고급 과정 마스터하고 5점 받자!",
            lectureStyle: "100% 온라인 강의",
            program: "단기반 신청 가능",
            necessity: ["AP Biology 개념숙지는 다 됐는데, 문제푸는것에 어려움을 겪고 있나요?", "Biology에서 Biochemistry, Genetics 문제를 계속 틀려서 5점을 못받고있나요?", "AP Biology의 5점을 무난하게 얻는 문제풀이법을 배우고싶나요?", "어려운 문제만 수업을 받아보고싶나요?"],
            introduction: {
                title: "AP Biology 문제풀이가 어렵다면?",
                items: ["- 단기간 AP Biology 5점 완성", "- 실제 연구도 많이 하시고, 직접 관련 과목을 가르치고 있는 선생님의 문제풀이 강의", "- 오직, 고급과정만을 바라는 학생들을 위한 AP Biology 5점 문제풀이 학습", "- Biology에서 어려운 부분인 Biochemistry, Genetics를 특히 비중있게 다루는 강의", "- 문제 유형별 대비"]
            },
            whyTakeThisCourse: [{
                title: "오직, 고급과정이 필요한 학생들을 위한 강의",
                info: ["기초부터 차근차근X, 문제로 시작해서 문제로 끝나는 강의"]
            }, {
                title: "실제 연구를 수행하고, 교육경력만 13년차인 선생님의 강의를 통한 직접적인 지도",
                info: []
            }, {title: "직접 제작한 AP Biology 문제풀이 학습 자료 제공", info: []}, {
                title: "AP Biology의 어려운 문제 유형 대비, 유형별 실습 기회",
                info: []
            }],
            afterTakingCourse: [{
                title: "AP Biology 5점을 위한 문제 해결 능력 향상",
                info: ["고급 수준의 문제풀이 기술을 습득해 문제 유형별 대비를 할 수 있을 것입니다."]
            }, {
                title: "Biochemistry와 Genetics와 같은 어려운 주제에 대한 해결",
                info: ["어려운 파트에서 문제 쉽게 해결이 가능할 것입니다."]
            }, {
                title: "AP Biology 공부에 쏟는 시간을 줄일 수 있습니다.",
                info: ["효과적인 AP Biology 학습이 가능할 것입니다."]
            }, {title: "안정적인 AP Biology 5점", info: []}],
            teacher: {
                userId: 124,
                name: "Mr. Shin",
                imgUrl: "https://i.postimg.cc/SNnvtwqv/yt-picture.png",
                schoolLogoImgUrl: "https://i.postimg.cc/SQDg3Bzy/purdue-university4128-logowik-com.webp",
                experience: ["Purdue Electronic Engineering 전공 (박사)", "교육경력 13년", "Purdue에서 연구, Lab class instructor"],
                description: "미국에서 대학/석사를 졸업하고 지금 현재는 Purdue 대학교에서 전자공학과 박사과정을 하고 있습니다. \n오전에는 학부생들을 가르치거나 연구실에서 논문,연구를 하고 오후에는 도움이 필요한 학생들을 가르치고 있습니다. \nAP Biology 를 가르친 경력은 13년차이고 지금현재도 고등과학을 많이 지도하고 있습니다. \n미국에서 거주하고 미국 고등학교 학생들과 매일 대화하고 피드백을 받으며 최신 AP 성향과 다년간 많은 자료들을 가지고 수업해오고 있습니다. \n제가 매일 수업 하면서 느끼는 점은 학생들은 매일 하루하루 너무 바쁜 하루를 보내고 있습니다. \n제수업을 통해서 학생들이 꼭 필요한 내용을 알고 문제푸는 요령을 익혀서 시간과 노력대비 최대한 효율을 가져 갔으면 좋겠습니다. \n이 클래스는 학생들이 가장 헷갈려 하는 문제풀이반 + Biochem/Genetics 파트 집중 문제 풀이 과정입니다. \n제가 수년간 가르쳐온 Chem 과목과 접목하여 학생들이 손쉽게 이해하는 노하우를 써서 학생들이 문제풀이에 어려움이 없도록 잘 가르치겠습니다. \n제수업이 또 특별한 이유는 너무 재미있습니다. 제 교육 철학은 학생들이 배울때 즐거움을 느낄수 있도록 하는 것입니다. \n학생들의 흥미를 유발하는 수업내용과 지루하지 않는 수업방식으로 학생들이 배우는 데 재미를 느끼게 하는데 노력을 많이 하는 편입니다. \n짧은 특강시간만큼 특강외에 어떻게 시간을 활용하여 시험을 준비하는지도 잘 인지시켜 좋은 성적을 나올수 있게 지도하겠습니다.\nAP Biology 문제풀이과정, 힘들겠지만 저를 믿고 신청해보세요!",
                videoUrl: "https://www.youtube.com/embed/-kLePt6iMEQ?si=hS9PGHbp2dBhFaKK"
            },
            course: [{
                schedule: {
                    courseName: "AP \bBiology 문제풀이반 (KST) ",
                    days: "화, 금",
                    time: "19:30 ~ 21:30",
                    timezone: "KST",
                    duration: "KST",
                    minutes: "120분",
                    frequency: "주 2회",
                    shortDetails: ""
                },
                objectives: {
                    korean: ["-생명과 화합물, 세포 구조, 유전자 조절, 대사 과정, 진화, 생태학 등 다양한 생물학적 주제를문제를 통해 공부합니다.", "-어려운 챕터인 생화학 및 유전 구조에 대한 이해를 증진시킵니다.", "-화학적 계산 및 생명과학의 응용 분야에서 문제를 해결하는 능력을 향상시킵니다.", ""],
                    english: ["- We study various biological topics such as life and compounds, cell structure, gene regulation,\nmetabolic processes, evolution, and ecology through problem-solving.", "- We enhance our understanding of challenging chapters such as biochemistry and genetic\nstructure.", "- We improve our ability to solve problems in chemical calculations and the application of life\nsciences.", ""]
                },
                curriculum: [{name: "Foundation of Chemistry", item: {content: []}}, {
                    name: "Origins of life",
                    item: {content: []}
                }, {name: "Chemistry of life", item: {content: []}}, {
                    name: "Prokayotes",
                    item: {content: []}
                }, {name: "Eukaryotes", item: {content: []}}, {
                    name: "Scientific Methods",
                    item: {content: []}
                }, {name: "Membranes & Water Potential", item: {content: []}}, {
                    name: "Enzymes",
                    item: {content: []}
                }, {name: "Photosynthesis", item: {content: []}}, {
                    name: "Cellular Respiration",
                    item: {content: []}
                }, {name: "Cell Signaling", item: {content: []}}, {
                    name: "DNA & RNA Structure, Central Dogma",
                    item: {content: []}
                }, {name: "Regulating Gene Expression", item: {content: []}}, {
                    name: "Mitosis & Meiosis",
                    item: {content: []}
                }, {name: "Heredity", item: {content: []}}, {
                    name: "Biotechology",
                    item: {content: []}
                }, {name: "Stoichiometry", item: {content: []}}, {
                    name: "Evolution",
                    item: {content: []}
                }, {name: "Ecology", item: {content: []}}]
            }, {
                schedule: {
                    courseName: "AP \bBiology 문제풀이반 (PST)",
                    days: "화, 금",
                    time: "19:30 ~ 21:30",
                    timezone: "PST",
                    minutes: "120분",
                    frequency: "주 2회",
                    shortDetails: ""
                }, objectives: {}, curriculum: []
            }],
            review: [{
                person: "한국 국제학교 11학년",
                experience: "“제가 들었던 수업중에 가장 재밌게 AP Biology 수업을 들었던 것 같아요, 재밌었던 만큼 흥미도 많이 늘어서 Bio 관련 시험도 잘 봤어요!”",
                personImgUrl: "https://i.postimg.cc/4dbjWxL1/avatar1.png"
            }, {
                person: "미국 보딩스쿨 12학년",
                experience: "“급하게 AP Biology 5점이 필요했는데, 덕분에 시험에서 5점 받았어요.”",
                personImgUrl: "https://i.postimg.cc/gc8hQ5tx/avatar2.png"
            }, {
                person: "미국 보딩스쿨 11학년",
                experience: "“빨리 AP Biology 5점 맞고 다른 과목 공부해야했었는데, 선생님을 만나고 해결되었어요 감사합니다.”",
                personImgUrl: "https://i.postimg.cc/7Yr3LDJ1/avatar4.png"
            }]
        }
    }]
}