<template>
    <div class="center-horizontal">
        <v-card width="500px" flat class="">
            <div class="text-right">
                <v-btn
                        class="text-right ma-2"
                        icon
                        small
                        dark
                        color="primary"
                        @click="goBackOnePage"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="mx-5 pb-5 mt-3">
                <h2 class="black--text ">
                    무료체험 신청
                </h2>
                <div class="py-1"></div>
                <div style="font-size: 15px; ">
                    IVYED 과외가 처음이시거나 잘 맞을지 궁금하세요?
                    <br/>
                    <div class="mt-2">
                        아래 내용을 작성해주시면 <strong>체험 수업이 무료로 진행</strong>됩니다.
                    </div>
                </div>
            </div>
            <v-divider class="mx-5 py-5"></v-divider>
            <template>
                <v-form v-model="valid" class="mx-5">
                    <v-container>
                        <v-row>
                            <v-col
                                    cols="12"
                                    md="12"
                            >
                                <div style="font-size: 16px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    수업 과목/시험 선택
                                </div>
                                <v-text-field
                                        dense
                                        outlined
                                        label="체험 수업을 원하시는 과목을 작성하여 주세요."
                                        v-model="trialSubject"
                                        :rules="[rules.required]"
                                        hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <div class="py-4"></div>
                        <v-divider class="py-6"></v-divider>
                        <v-row>
                            <v-col
                                    cols="12"
                                    md="12"
                            >
                                <div style="font-size: 16px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    선호하는 선생님의 전공 계열
                                </div>
                                <v-row no-gutters align="center" style="margin-top: -10px !important;" class=" text-h4">
                                    <v-radio-group
                                            :rules="[rules.required]"
                                            v-model="preferredMajor"
                                            mandatory
                                            row

                                    >
                                        <v-radio
                                                label="무관"
                                                value="무관"
                                        ></v-radio>
                                        <v-radio
                                                label="이공계열"
                                                value="이공계"
                                        ></v-radio>
                                        <v-radio
                                                label="문과계열"
                                                value="문과"
                                        ></v-radio>

                                    </v-radio-group>
                                </v-row>
                            </v-col>
                        </v-row>
                        <div class="py-2"></div>
                        <v-divider class="py-6"></v-divider>

                        <v-row>
                            <v-col
                                    cols="12"
                                    md="12"
                                    style="margin-top: -20px"
                            >
                                <div style="font-size: 16px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    희망하는 체험수업 일시
                                </div>
                                <div style="font-size: 12px; font-weight: bold" class="secondary--text">
                                    * 정규수업 시작시 시간은 변경 가능합니다. <br/>
                                    * 가능한 많은 일정을 작성하여 주세요.
                                </div>
                                <v-text-field
                                        dense
                                        outlined
                                        placeholder="CA, USA / 월, 목 저녁 7~10시"
                                        v-model="trialLocationAndTime"
                                        required
                                        :rules="[rules.required]"
                                        :messages="['\'거주지역 / 시간\'']"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <div class="py-2"></div>
                        <v-divider class="py-6"></v-divider>
                        <v-row>
                            <v-col
                                    cols="12"
                                    md="12"
                                    style="margin-top: -20px"
                            >
                                <div style="font-size: 16px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    수업시 선생님과 함께 학습하고 싶은 교재나 자료가 있으신가요?
                                </div>
                                <v-radio-group
                                        v-model="preferredMaterial"
                                        mandatory
                                        row
                                        :rules="[rules.required]"
                                >

                                    <v-radio
                                            label="네"
                                            value="있어요"
                                    ></v-radio>
                                    <v-radio
                                            label="아니오"
                                            value="없어요"
                                    ></v-radio>
                                </v-radio-group>

                                <div v-if="preferredMaterial=='있어요'">
                                    <div style="font-size: 12px; font-weight: bold" class="secondary--text ">
                                        * 체험신청서 작성 후 운영팀에 전달 해주세요.
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <div class="py-2"></div>
                        <v-divider class="py-6"></v-divider>
                        <v-row>
                            <v-col
                                    cols="12"
                                    md="12"
                                    style="margin-top: -20px"
                            >
                                <div style="font-size: 16px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    수업 진행 언어
                                </div>
                                <v-radio-group
                                        v-model="preferredLanguage"
                                        mandatory
                                        row

                                >

                                    <v-radio
                                            label="영어"
                                            value="english"
                                    ></v-radio>
                                    <v-radio
                                            label="한국어"
                                            value="korean"
                                    ></v-radio>
                                    <v-radio
                                            label="영어/한국어"
                                            value="konglish"
                                    ></v-radio>
                                </v-radio-group>


                            </v-col>
                        </v-row>
                        <div class="py-2"></div>
                        <v-divider class="py-6"></v-divider>

                        <v-row>
                            <v-col
                                    cols="12"
                                    md="12"
                                    style="margin-top: -20px"
                            >
                                <div style="font-size: 16px; color: #757575; font-weight: bold"
                                     class="mb-2">
                                    (선택) 이외 필요사항을 작성 해주세요.
                                </div>
                                <div>
                                    <v-textarea
                                            dense
                                            outlined
                                            placeholder="예) 여자/남자 선생님을 선호해요! "
                                            v-model="etc"
                                            required
                                    ></v-textarea>
                                </div>
                            </v-col>
                        </v-row>
                        <div class="my-7"></div>

                        <v-btn :disabled="!valid" large width="100%" @click="sendEmail" class="small-button rounded-lg"
                               height="50px" text elevation="0"

                        >
                            <!--                    <v-btn large width="100%" @click="snackbar=true" class="small-button rounded-lg" height="50px" text elevation="0" >-->
                            <h2 style="font-weight: bold; color: white; letter-spacing: -1px !important;">
                                체험수업 신청하기
                            </h2>
                        </v-btn>
                        <div class="pb-8"></div>
                        <v-snackbar
                                v-model="snackbar"
                                class="text-center"
                                top
                        >
                            {{ text }}

                            <template v-slot:action="{ attrs }">
                                <v-btn
                                        color="pink"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar = false"
                                >
                                    Close
                                </v-btn>
                            </template>
                        </v-snackbar>

                    </v-container>
                </v-form>
            </template>
        </v-card>
    </div>
</template>

<script>
    import info from "../../../info";
    import config from "../../../config";
    import emailjs from "emailjs-com";

    export default {
        name: "StudentTrialForm",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        data() {
            return {
                countryCodes: info.countryCodes,
                grades: info.newGradesList,
                trialSubject: '',
                parentCountryCode: '',
                parentPhone: '',
                trialLocationAndTime: '',
                studentGrade: '',
                snackbar: false,
                text: `예약이 접수 되었습니다.`,
                valid: false,
                preferredMajor: '',
                preferredMaterial: '',
                preferredLanguage: '',
                etc: '',


                rules: {
                    required: (value) => !!value || '필수 입력',
                    email: (value) => {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || '이메일을 올바르게 입력해주세요.';
                    },
                    isValidBirthdate: (value) => (value.length == 8) || '생년월일은 8자리 숫자로 입력 해주세요',
                    // isValidPhoneNumber: (value) => (value.length == 11) || '전화번호는 \'-\' 없이 숫자로 입력 해주세요',
                    isValidPhoneNumber: (value) => (value.match(/^[0-9]+$/)) || '전화번호는 \'-\' 없이 숫자로 입력 해주세요',
                    // isValidBirthdate: (value) => (value.length == 8) || '생년월일은 8자리 숫자로 입력 해주세요',
                    // isValidPhoneNumber: (value) => isNaN(value) || '핸드폰 번호는 '-' 없이 11자리 숫자로 입력 해주세요',
                    isAYear: (value) => (value >= 19400101 && value <= 20251231 && value.length == 8) || '생일은 8자리 숫자 및 1940 - 2022년만 유효합니다. ',
                    hourlyRate: (value) => (value >= 10 && value <= 2000) || 'Must Be between 10 and 2000',
                    biggerthanzero: (value) => value > 0 || 'Can not work less than 1 month',
                    overviewLength: (v) =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        (v.length >= 50 && v.length <= 5000) || 'Min 50 characters, Max 5000 characters',
                    emptyArray: (v) => v.length > 0 || 'Min One Skill is needed',
                    passwordMatch: (v1, v2) => v1 === v2 || '비밀번호가 일치하지 않습니다.',
                    MinPassLen: (v) => v.length >= 8 || '8자 이상의 비밀번호만 허용 됩니다.',
                },
            }
        },
        methods: {
            goBackOnePage() {
                this.$router.go(-1);
            },
            sendEmail() {
                this.email =
                    "학부모님/학생 이름: " + this.currentUser.lastName + this.currentUser.firstName + ", \n"
                    + "휴대전화: " + this.currentUser.countryCode + this.currentUser.phoneNumber + "\n"
                    + "이메일: " + this.currentUser.email + "\n"
                    + "학생번호: " + this.currentUser.id + "\n"
                    + "수업 과목/시험 선택: " + this.trialSubject + "\n"
                + "선생님 전공 계열: " + this.preferredMajor + "\n"
                + "희망 체험수업 일시: " + this.trialLocationAndTime + "\n"
                + "희망 교재 여부: " + this.preferredMaterial + "\n"
                + "희망 언어: " + this.preferredLanguage + "\n"
                + "etc: " + this.etc + "\n"

                var obj = {
                    user_email: "체험 신청 from " + this.currentUser.email,
                    from_name: this.currentUser.lastName + this.currentUser.firstName + ": " + this.currentUser.countryCode + " " + this.currentUser.phoneNumber,
                    message_html: this.email,
                    studentName: this.currentUser.lastName + this.currentUser.firstName + ", \n",
                    phone: this.currentUser.countryCode + this.currentUser.phoneNumber + "\n",
                    email: this.currentUser.email + "\n",
                    studentId: this.currentUser.id + "\n",
                    trialSubject: this.trialSubject + "\n",
                    preferredMajor:  this.preferredMajor + "\n",
                    trialLocationAndTime: this.trialLocationAndTime + "\n",
                    preferredMaterial: this.preferredMaterial + "\n",
                    preferredLanguage: this.preferredLanguage + "\n",
                    etc: this.etc,
                    to_name: "IVYED",
                };

                emailjs
                    .send(
                        config.emailjs.serviceID,
                        'template_tpkpeq2',
                        obj,
                        config.emailjs.userID
                    )
                    .then(
                        // eslint-disable-next-line no-unused-vars
                        (result) => {
                            this.snackbar = true;
                            this.$router.go(-1)
                            this.email = "";
                            this.text = "";
                            this.name = "";
                        },
                        // eslint-disable-next-line no-unused-vars
                        (error) => {
                            this.showSnackbar = true;
                            this.snackbarMessage = "Oops! Something went wrong.";
                            this.snackbarColor = "#64808E";
                        }
                    );
            }

        }
    }
</script>

<style scoped>

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .small-button {
        /*box-shadow: 1px 1px 1px 1px;*/
        /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
        /*box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;*/
        /*box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;*/
        /*box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;*/
        /*position: fixed;*/
        /*bottom: 10px;*/
        /*left: 50%;*/
        /*margin-left: -104.5px; !*104.5px is half of the button's width*!*/
        background-image: linear-gradient(92.88deg, #DE2828 9.16%, #cd6060 64.72%);
        /*background-color: #DE2828;*/
        /*border-radius: 15px;*/
        /*!*border-radius: 8px;*!*/
        /*!*border-style: none;*!*/
        /*!*box-sizing: border-box;*!*/
        /*color: #FFFFFF;*/
        /*cursor: pointer;*/
        /*!*flex-shrink: 0;*!*/
        /*!*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*!*/
        /*!*font-family: 'SuncheonB';*!*/
        /*!*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*!*/
        /*font-size: 15px;*/
        /*height: 50px;*/
        /*width: 120%px;*/
        /*!*width: 100%;*!*/
        /*font-weight: 500;*/
    }


</style>
