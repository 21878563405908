<template>
    <v-container class="center-horizontal" fluid>
        <!--    <link rel='stylesheet' href='https://use.fontawesome.com/releases/v5.5.0/css/all.css' integrity='sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU' crossorigin='anonymous'>-->

        <!--    <div class="frame">-->
        <v-card color="#0E0E0E" class="text-center center-horizontal px-5 rounded-xl mt-14" flat height="650" width="500px" outlined>
            <div style="color: white; font-weight: bold; font-size: 25px" class="pb-2">
                나에게 딱 맞는 선생님을 <br/> 만날 수 있어요!
            </div>
            <div class="text-center white--text">
                수준, 목표, 취향에 딱 맞는 선생님 추천 후 매칭
            </div>
            <div class="py-8"></div>

            <div class="carousel center-horizontal" style="">
                <v-card-subtitle class="carousel-subtitle">University</v-card-subtitle>
                <!--                <v-card class="rounded-xl" color="#0E0E0E" style="border: solid #A20916" outlined>-->
                <v-carousel
                        cycle
                        height=""
                        hide-delimiter-background
                        :show-arrows=false
                        vertical
                        hide-delimiters
                        interval="1700"
                >
                    <div class="border-gradient border-gradient-green" style="width: 250px; height: 50px;">

                        <v-carousel-item class="" style="overflow: hidden">
                            <div class="white--text">Harvard</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Stanford</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Yale</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Columbia</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">MIT</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Cornell</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">UPenn</div>
                        </v-carousel-item>
                    </div>


                </v-carousel>
            </div>
            <div class="py-5"></div>


            <div class="carousel center-horizontal" style="">
                <v-card-subtitle class="carousel-subtitle">Major</v-card-subtitle>
                <!--                <v-card class="rounded-xl" color="#0E0E0E" style="border: solid #A20916" outlined>-->
                <v-carousel
                        cycle
                        height=""
                        hide-delimiter-background
                        show-arrows-on-hover
                        :show-arrows=false
                        vertical
                        hide-delimiters
                        interval="1700"
                >
                    <div class="border-gradient border-gradient-green" style="width: 250px; height: 50px">

                        <v-carousel-item class="" style="overflow: hidden">
                            <div class="white--text">Business</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Computer Science</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Mathematics</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Biology</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Computer Science</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Operations Research</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">Business</div>
                        </v-carousel-item>
                    </div>


                </v-carousel>
            </div>
            <div class="py-5"></div>

            <div class="carousel center-horizontal" style="">
                <v-card-subtitle class="carousel-subtitle">High School</v-card-subtitle>
                <!--                <v-card class="rounded-xl" color="#0E0E0E" style="border: solid #A20916" outlined>-->
                <v-carousel
                        cycle
                        height=""
                        hide-delimiter-background
                        :show-arrows=false
                        vertical
                        hide-delimiters
                        interval="1700"
                >
                    <div class="border-gradient border-gradient-green" style="width: 250px; height: 50px">

                        <v-carousel-item class="" style="overflow: hidden">
                            <div class="white--text">국내 국제학교</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">미국 보딩스쿨</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">미국 공립</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">미국 보딩스쿨</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">국내 국제학교</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">국내 국제학교</div>
                        </v-carousel-item>

                        <v-carousel-item class="white--text">
                            <div class="white--text">싱가폴 국제학교</div>
                        </v-carousel-item>


                    </div>


                </v-carousel>
            </div>

        </v-card>
        <!--    </div>-->
    </v-container>

    <!--    <div>-->
    <!--    <v-carousel-->
    <!--            cycle-->
    <!--            height="400"-->
    <!--            hide-delimiter-background-->
    <!--            :show-arrows=false-->
    <!--            vertical-->
    <!--            hide-delimiters-->
    <!--            interval="3000"-->
    <!--    >-->

    <!--        <v-carousel-item-->
    <!--                v-for="(slide, i) in slides"-->
    <!--                :key="i"-->
    <!--        >-->

    <!--                <v-row-->
    <!--                        class="fill-height"-->
    <!--                        align="center"-->
    <!--                        justify="center"-->
    <!--                >-->
    <!--                    <div class="">-->
    <!--                        {{ slide }} Slide-->
    <!--                    </div>-->
    <!--                </v-row>-->
    <!--        </v-carousel-item>-->
    <!--    </v-carousel>-->
    <!--</div>-->

</template>

<script>
    // import $ from 'jquery'
    export default {
        name: "VerticalTeacherCarousel",
        data() {
            return {
                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4',
                    'deep-purple accent-4',
                    'deep-purple accent-4',
                ],
                slides: [
                    'First',
                    'Second',
                    'Third',
                    'Fourth',
                    'Fifth',
                    'Fifth',
                    'Fifth',
                ],
            }
        },
        // mounted() {
        //     var x = 0,
        //         container = $('.container'),
        //         items = container.find('li'),
        //         containerHeight = 0,
        //         numberVisible = 5,
        //         intervalSec = 1700;
        //
        //     if(!container.find('li:first').hasClass("first")){
        //         container.find('li:first').addClass("first");
        //     }
        //
        //     items.each(function(){
        //         if(x < numberVisible){
        //             containerHeight = containerHeight + $(this).outerHeight();
        //             x++;
        //         }
        //     });
        //
        //     container.css({ height: containerHeight, overflow: "hidden" });
        //
        //     // eslint-disable-next-line no-unused-vars
        //     function vertCycle() {
        //         var firstItem = container.find('li.first').html();
        //
        //         container.append('<li>'+firstItem+'</li>');
        //         firstItem = '';
        //         // eslint-disable-next-line no-undef
        //         container.find('li.first').animate({ marginTop: "-50px" }, 600, function(){  $(this).remove(); container.find('li:first').addClass("first"); });
        //     }
        //
        //     if(intervalSec < 700){
        //         intervalSec = 700;
        //     }
        //
        //     var init = setInterval("vertCycle()",intervalSec);
        //
        //     container.hover(function(){
        //         clearInterval(init);
        //     }, function(){
        //         init = setInterval("vertCycle()",intervalSec);
        //     });
        // }
    }
</script>

<style scoped>
    html,
    body {
        font-family: 'Droid Serif', serif;
    }

    h1 {
        font-size: 60px;
        text-align: center;
    }

    .content-slider {
        width: 100%;
        height: 360px;
    }

    .slider {
        height: 320px;
        width: 680px;
        margin: 40px auto 0;
        overflow: visible;
        position: relative;
    }

    .mask {
        overflow: hidden;
        height: 320px;
    }

    .slider ul {
        margin: 0;
        padding: 0;
        position: relative;
    }

    .slider li {
        width: 680px;
        height: 320px;
        position: absolute;
        top: -325px;
        list-style: none;
    }

    .slider .quote {
        font-size: 10px;
        font-style: italic;
    }

    .slider .source {
        font-size: 10px;
        text-align: right;
    }

    .slider li.anim1 {
        animation: cycle 15s linear infinite;
    }

    .slider li.anim2 {
        animation: cycle2 15s linear infinite;
    }

    .slider li.anim3 {
        animation: cycle3 15s linear infinite;
    }

    .slider li.anim4 {
        animation: cycle4 15s linear infinite;
    }

    .slider li.anim5 {
        animation: cycle5 15s linear infinite;
    }

    .slider li.anim6 {
        animation: cycle6 15s linear infinite;
    }

    .slider:hover li {
        animation-play-state: paused;
    }

    @keyframes cycle {
        0% {
            top: 0px;
        }
        4% {
            top: 0px;
        }
        16% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        20% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
        21% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        50% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        92% {
            top: -325px;
            opacity: 0;
            z-index: 0;
        }
        96% {
            top: -325px;
            opacity: 0;
        }
        100% {
            top: 0px;
            opacity: 1;
        }
    }

    @keyframes cycle2 {
        0% {
            top: -325px;
            opacity: 0;
        }
        16% {
            top: -325px;
            opacity: 0;
        }
        20% {
            top: 0px;
            opacity: 1;
        }
        24% {
            top: 0px;
            opacity: 1;
        }
        36% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        40% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
        41% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        100% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
    }

    @keyframes cycle3 {
        0% {
            top: -325px;
            opacity: 0;
        }
        36% {
            top: -325px;
            opacity: 0;
        }
        40% {
            top: 0px;
            opacity: 1;
        }
        44% {
            top: 0px;
            opacity: 1;
        }
        56% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        60% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
        61% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        100% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
    }

    @keyframes cycle4 {
        0% {
            top: -325px;
            opacity: 0;
        }
        56% {
            top: -325px;
            opacity: 0;
        }
        60% {
            top: 0px;
            opacity: 1;
        }
        64% {
            top: 0px;
            opacity: 1;
        }
        76% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        80% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
        81% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        100% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
    }

    @keyframes cycle5 {
        0% {
            top: -325px;
            opacity: 0;
        }
        76% {
            top: -325px;
            opacity: 0;
        }
        80% {
            top: 0px;
            opacity: 1;
        }
        84% {
            top: 0px;
            opacity: 1;
        }
        96% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        100% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
    }

    @keyframes cycle6 {
        0% {
            top: -325px;
            opacity: 0;
        }
        76% {
            top: -325px;
            opacity: 0;
        }
        80% {
            top: 0px;
            opacity: 1;
        }
        84% {
            top: 0px;
            opacity: 1;
        }
        96% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        100% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
    }

    .border-gradient {
        border-radius: 25px !important;
        border: 3px solid;
        border-image-slice: 1;
        font-size: 18px;
        font-weight: bold;
        /*border-width: 1px;*/
        /*-webkit-border-radius: 14px;*/
        /*-moz-border-radius: 14px;*/

    }

    .border-gradient-green {
        border-image-source: linear-gradient(to right, #F3E5E5 0%, #CF1512 100%);
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .carousel {
        /*position: relative;*/
        /*width: 100%;*/
        /*text-align: center;*/
        font-size: 15px;
        line-height: 45px;
        /*height: 45px;*/
    }

    .carousel-subtitle {
        font-size: 24px;
        font-weight: bold;
        background: #F3E5E5;
        background: linear-gradient(to right, #F3E5E5 0%, #CF1512 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>

