<template>
    <div style="background-color: #eeecec">
        <!--        <section>-->
        <!--            <h1>Scroll Down to Reveal Elements &#8595;</h1>-->
        <!--        </section>-->
        <div class="py-8"></div>

        <div class="container reveal">

            <div class="pa-3">
                <img class="hidden-lg-and-up" style="border-radius: 8px" height="100%" width="100%"
                     src="../../../../assets/brand/3moblie.png" alt="In IVYED we trust">
                <img class="hidden-md-and-down" style="border-radius: 8px" height="100%" width="100%"
                     src="../../../../assets/brand/3PC.png" alt="In IVYED we trust">
            </div>        <!--                <h2>Caption</h2>-->
            <div class="hidden-md-and-down text-container" style="color: black">
                <div class="px-4">
                    <div class="py-3"></div>
                    <div class="" style="font-size: 30px; font-family: S-CoreDream-6Bold">
                        <span style="color: #A20916">IVYED</span>가<br/>약속드리겠습니다.
                    </div>
                    <div style="font-size: 28px; font-weight: bold">
                        ___
                    </div>
                    <div class="py-3"></div>

                    <div style="font-size: 18px">
                        LA에서 유학을 하고 있는 IVYED의 첫번째 고객은 아직까지 IVYED를 서비스를 이용하고 있습니다. 저희의 진심과 전문성 등을 믿고 계속 이용해주시고 계십니다.
                        앞으로도 IVYED는 독립적이고 정직한 유학 교육을 제공하며 IVYED를 위해서가 아닌, 우리의 이익은 학생들의 성공이라는 것을 잊지않고 계속해서 실질적으로 도움이
                        되는 교육을 제공하겠습니다.
                    </div>
                    <div class="py-4"></div>
                </div>


            </div>
            <div class="hidden-lg-and-up text-container" style="color: black">
                <div class="px-4">
                    <div class="py-3"></div>
                    <div class="" style="font-size: 22px; font-family: S-CoreDream-6Bold">
                        <span style="color: #A20916">IVYED</span>가<br/> 약속드리겠습니다.
                    </div>
                    <div style="font-size: 28px; font-weight: bold">
                        ___
                    </div>
                    <div class="py-3"></div>

                    <div style="">
                        LA에서 유학을 하고 있는 IVYED의 첫번째 고객은 아직까지 IVYED를 서비스를 이용하고 있습니다. 저희의 진심과 전문성 등을 믿고 계속 이용해주시고 계십니다.
                        앞으로도 IVYED는 독립적이고 정직한 유학 교육을 제공하며 IVYED를 위해서가 아닌, 우리의 이익은 학생들의 성공이라는 것을 잊지않고 계속해서 실질적으로 도움이
                        되는 교육을 제공하겠습니다.
                    </div>
                    <div class="py-4"></div>
                </div>


            </div>

        </div>
        <div class="py-5"></div>

        <!-- Add more sections as needed -->
    </div>
</template>

<script>
    export default {
        name: "BrandStory3",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>
    @import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /*font-family: "Asap", sans-serif;*/
    }

    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #e0ffff;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
</style>

