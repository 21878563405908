<template>
    <body>
    <input style="margin-top: -100px" type="radio" name="position" />
    <input style="margin-top: -100px" type="radio" name="position" checked/>
    <input style="margin-top: -100px" type="radio" name="position"/>
    <input style="margin-top: -100px" type="radio" name="position"/>
<!--    <input style="margin-top: -100px" type="radio" name="position"/>-->
    <main id="carousel">
        <div class="item">
            <div style="color: white; font-size: 15px" class="pa-5 font-weight-bold">
                #Early합격 #3.9GPA #SAT1590
            </div>
            <div style="color: white; font-size: 16px" class="px-5 font-weight-bold">
                “과목별 특성에 맞는 학습법을 제시합니다.“
            </div>
            <div style="color: white; font-size: 14px" class="px-5 pt-1">
                AP 과목들은 대체로 난이도가 높고 공부할 양도 많아서 혼자서 준비하기엔 무리가 있습니다. 게다가 학교마다 요구하는 조건 및 유형이 달라서 자신이 목표로 하는 학교나 학과에 맞는 시험준비를 해야 하는데요. 전공자 선생님께 1:1 수업을 받으면 훨씬 더 효율적이고 전략적으로 대비할 수 있어서 좋습니다.
            </div>

            <div style="bottom: 60px; left: 20px; position: absolute">
                <div style="color: white" class="font-weight-bold">Cornell University</div>
                <div style="color: white; font-size: 13px">문과계열 | J 선생님</div>
            </div>
<!--            <div style="bottom: 30px; right: 30px; position: absolute">-->
<!--                <img height="100px" src="../../assets/teacher-avatar.png"/>-->
<!--            </div>-->
        </div>
        <div class="item">
            <div style="color: white; font-size: 15px" class="pa-5 font-weight-bold">
                #Princeton #Yale #모두합격
            </div>
            <div style="color: white; font-size: 16px" class="px-5 font-weight-bold">
                “오답노트를 적극 활용합니다.“
            </div>
            <div style="color: white; font-size: 14px" class="px-5 pt-1">
                틀린 문제를 반복해서 풀어봄으로써 실수를 줄이고 부족한 부분을 보완할 수 있습니다.  틀린 문제를 그냥 지나치면 절대 안됩니다. 반복학습이야말로 성적 향상의 지름길이기 때문이다. 마지막으로 복습 위주의 학습 계획을 세우되 자투리 시간을 잘 활용하라고 당부하는 편입니다. 학교 쉬는 시간이나 점심시간 또는 학원 이동 시간 등 틈틈이 남는 시간을 최대한 활용한다면 보다 효율적으로 공부할 수 있기 때문입니다.
            </div>

            <div style="bottom: 40px; left: 20px; position: absolute">
                <div style="color: white" class="font-weight-bold">Yale University</div>
                <div style="color: white; font-size: 13px">이과계열 | K 선생님</div>
            </div>
        </div>
        <div class="item">
            <div style="color: white; font-size: 15px" class="pa-5 font-weight-bold">
                #Stanford #AP8과목 #5점
            </div>
            <div style="color: white; font-size: 16px" class="px-5 font-weight-bold">
                “저의 과외 비법은 학습 동기 유발입니다.”
            </div>
            <div style="color: white; font-size: 14px" class="px-5 pt-1">
                제가 가르치는 과목은 수학인데 아무래도 다른 과목에 비해 상대적으로 재미가 없다 보니 학생들이 쉽게 흥미를 잃습니다. 따라서 일단 호기심을 자극합니다. 가령 실생활 사례를 통해 개념을 설명한다거나 다양한 시청각 자료를 활용하여 이해를 돕는 식입니다. 이때 주의할 점은 반드시 적절한 보상이 뒤따라야 한다는 것입니다. 그래야 지속적으로 의욕을 갖고 참여할 수 있습니다.
            </div>

            <div style="bottom: 40px; left: 20px; position: absolute">
                <div style="color: white" class="font-weight-bold">Stanford University</div>
                <div style="color: white; font-size: 13px">공과대학 | C 선생님</div>
            </div>
<!--            <div style="bottom: 30px; right: 30px; position: absolute">-->
<!--                <img height="100px" src="../../assets/teacher-avatar.png"/>-->
<!--            </div>-->
        </div>
        <div class="item">
            <div style="color: white; font-size: 15px" class="pa-5 font-weight-bold">
                #Columbia #CSMajor #Math
            </div>
            <div style="color: white; font-size: 16px" class="px-5 font-weight-bold">
                “무작정 공부하라고 강요하기보다는 작은 목표라도 세워주고 달성했을 때 칭찬과 격려를 아끼지 않습니다”
            </div>
            <div style="color: white; font-size: 14px" class="px-5 pt-1">
                저는 학생들에게 가장 먼저 시간에 따른 구체적인 계획표를 작성해줍니다. 이때 실현 가능한 범위 내에서 현실적으로 세워주고 이뤘을 때 마다 격려를 해줍니다. 그래야 실천 의지가 높아지고 의욕이 생기기 때문입니다.
            </div>

            <div style="bottom: 60px; left: 20px; position: absolute">
                <div style="color: white" class="font-weight-bold">Columbia University</div>
                <div style="color: white; font-size: 13px">문과계열 | L 선생님</div>
            </div>
<!--            <div style="bottom: 30px; right: 30px; position: absolute">-->
<!--                <img height="100px" src="../../assets/teacher-avatar.png"/>-->
<!--            </div>-->
        </div>
<!--        <div class="item"></div>-->
    </main>
    </body>
</template>

<script>
    export default {
        name: "TeacherCarousel"
    }
</script>

<style scoped>
    body {
        height: 600px;
        margin: 0;
        display: grid;
        grid-template-rows: 500px 100px;
        grid-template-columns: 1fr 30px 30px 30px 30px 30px 1fr;
        align-items: center;
        justify-items: center;
    }

    main#carousel {
        grid-row: 1 / 2;
        grid-column: 1 / 8;
        width: 100vw;
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transform-style: preserve-3d;
        perspective: 600px;
        --items: 5;
        --middle: 3;
        --position: 1;
        pointer-events: none;
    }

    div.item {
        position: absolute;
        width: 300px;
        height: 400px;
        background-color: coral;
        --r: calc(var(--position) - var(--offset));
        --abs: max(calc(var(--r) * -1), var(--r));
        transition: all 0.25s linear;
        transform: rotateY(calc(-10deg * var(--r))) translateX(calc(-300px * var(--r)));
        z-index: calc((var(--position) - var(--abs)));
        border-radius: 20px;

    }

    div.item:nth-of-type(1) {
        --offset: 1;
        background-color: #000000;
    }

    div.item:nth-of-type(2) {
        --offset: 2;
        background-color: #A30717;
    }

    div.item:nth-of-type(3) {
        --offset: 3;
        background-color: #ff9770;
    }

    div.item:nth-of-type(4) {
        --offset: 4;
        background-color: #ffd670;
    }

    div.item:nth-of-type(5) {
        --offset: 5;
        background-color: #e9ff70;
    }

    input:nth-of-type(1) {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }

    input:nth-of-type(1):checked ~ main#carousel {
        --position: 1;
    }

    input:nth-of-type(2) {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }

    input:nth-of-type(2):checked ~ main#carousel {
        --position: 2;
    }

    input:nth-of-type(3) {
        grid-column: 4 /5;
        grid-row: 2 / 3;
    }

    input:nth-of-type(3):checked ~ main#carousel {
        --position: 3;
    }

    input:nth-of-type(4) {
        grid-column: 5 / 6;
        grid-row: 2 / 3;
    }

    input:nth-of-type(4):checked ~ main#carousel {
        --position: 4;
    }

    input:nth-of-type(5) {
        grid-column: 6 / 7;
        grid-row: 2 / 3;
    }

    input:nth-of-type(5):checked ~ main#carousel {
        --position: 5;
    }

</style>
