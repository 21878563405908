<template>
<!--
text
default : black
강조 파란색 : #3498db
덜 강조 파란색 : #2980b9
배경색 : #EBF4FA
-->
    <div>
        <section
                style="padding: 45px 15px;display: flex;justify-content: center;flex-direction: column;align-items: center;background-color: #EBF4FA">
            <img src="https://i.postimg.cc/cHB2Qwkt/02.jpg" alt="IVYED AP Chemistry"
                 style="width: 100%;height: 210px;max-width: 340px;border-radius: 10px;object-fit: cover"/>
            <div style="margin: 20px 0px;display: flex;flex-direction: column;justify-content: space-evenly;height: 150px;">
                <span style="color: #2980b9;font-weight: bold">Emory University Biology 전공</span>
                <h3 style="font-size: 24px;font-weight: 800;margin-bottom: 5px;">AP Chemistry</h3>
                <p style="color: rgba(0,0,0,0.7);font-size: smaller;">AP Chemistry, 고득점을 향한 AP Chemistry 개념완성반
                </p>
            </div>
            <ul style="border-bottom: 1px solid rgba(0,0,0,0.1);padding: 20px 0px;border-top: 1px solid rgba(0,0,0,0.1);">
                <li class="study_info">
                    <span>💻</span><span style="font-weight: 500;place-self: start">강의 방식</span><span>100% 온라인 강의</span>
                </li>
                <li class="study_info">
                    <span>📒️</span><span style="font-weight: 500">AP Chemistry 개념완성</span><span
                        style="color:#2980b9 ">4주 과정</span>
                </li>
            </ul>
            <v-btn to="/bootcamp/register"
                   style="background-color: #3498db;color: white;width: 100%;margin-top: 30px;padding: 25px 0px;font-weight: 900;box-shadow: none;font-size: 16px;max-width: 380px">
                참가 신청하기
            </v-btn>
        </section>
        <v-toolbar
                style="color: rgba(0,0,0,0.5);background-color: white;top: 56px;box-shadow: none;font-weight: 500;border-bottom: 1px solid rgba(0,0,0,0.1);z-index: 99"
                class=" hidden-xl-only hidden-lg-only hidden-md-only hidden-sm-only sticky-toolbar text-center toolbar-style-mobile">
            <v-row style="display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;font-size: 14.5px;">
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center;"
                       cols="" class="hover_nav toolbar-menu"
                       @click="scrollto('lecture-introduction', { behavior: 'smooth' })">
                    강의소개
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="hover_nav toolbar-menu" @click="scrollto('teacher', { behavior: 'smooth' })">
                    선생님
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="toolbar-menu hover_nav" @click="scrollto('curriculum', { behavior: 'smooth' })">
                    커리큘럼
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="toolbar-menu hover_nav" @click="scrollto('review', { behavior: 'smooth' })">
                    후기
                </v-col>
            </v-row>
        </v-toolbar>
        <v-toolbar
                style="color: rgba(0,0,0,0.5);background-color: white;top: 56px;box-shadow: none;font-weight: 500;border-bottom: 1px solid rgba(0,0,0,0.1);z-index: 99"
                class=" hidden-xs-only hidden-xs sticky-toolbar text-center toolbar-style" sticky="" color="#494949">
            <v-row justify="center px-5">
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('lecture-introduction', { behavior: 'smooth' })">
                        강의소개
                    </div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('teacher', { behavior: 'smooth' })">선생님</div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('curriculum', { behavior: 'smooth' })">커리큘럼
                    </div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('review', { behavior: 'smooth' })">후기
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <!--        강의 소개-->
        <section id="lecture-introduction"
                 style="padding: 45px 15px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">강의소개</span></div>
            </div>

            <h3 style="margin-bottom: 50px;text-align: center">아래에 해당한다면 꼭 이 수업을 수강하세요!</h3>
            <div style="min-width: 320px;max-width: 650px">
                <div style="height: 70px;background-color: #EBF4FA;border: 1px solid #3498db;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 10px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr;max-width: 600px">
                    <span style="color: #3498db;place-self: center start">✔</span>
                    <span class="notice_why_study">AP Chemistry 시험을 볼 예정인데, Chem에 대한 지식이 부족한 학생!</span>
                </span>
                </div>
                <div style="height: 70px;background-color: #EBF4FA;border: 1px solid #3498db;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 10px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr">
                    <span style="color: #3498db;place-self: center start">✔</span>
                    <span class="notice_why_study">AP Chemistry에 대한 흥미가 없지만 입시를 위해서는 꼭 해야만하는 학생!</span>
                </span>
                </div>

                <div style="height: 70px;background-color: #EBF4FA;border: 1px solid #3498db;border-radius: 15px;padding: 30px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 80px;width: 100%">
                <span style="font-weight: 600;font-size: smaller;display: grid;grid-template-columns: 1fr 9fr"><span
                        style="color: #3498db;place-self: center start">✔</span>
                    <span>내용이 방대해서 어떤 것 부터 먼저해야할지 모르는 학생!</span>
                    </span>
                </div>
            </div>

            <h3 style="margin-bottom: 50px;text-align: center">AP Chemistry, 아직 어렵게만 느껴진다면?</h3>
            <div style="height: 460px;background-color: #EBF4FA;border: 1px solid #3498db;border-radius: 15px;padding: 25px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 80px;max-width: 530px">
                <h4 style="font-size: 18px;padding: 10px 0px;color: #3498db;">AP Chemistry 개념완성반!</h4>
                <p style="font-size: small;color: rgba(0,0,0,0.7);line-height: 22px;display: flex;flex-direction: column;justify-content: space-evenly;height: 70%">
                    <span>- 단기간 AP Chemistry 탄탄한 기초 완성을 도와드립니다.</span>
                    <span>- 실제 대학에서 일반화학, 유기화학 등 Chem관련 과목 A학점을 받은 선생님의 기초완성 강의!</span>
                    <span>- 개념을 배우면서도 시험준비를 할 수 있는 AP Chemistry의 종합적 학습을 할 수 있습니다.</span>
                    <span>- 중요히 여기는 토픽들의 개념을 정확하게 이해시키고, 문제풀이까지 강의와 유형별 대비 수업까지!</span>
                    <span>- AP Chemistry의 어려운 파트또한 집중 학습합니다!</span>
                </p>
            </div>

            <h3 style="margin-bottom: 50px;text-align: center;">AP Chemistry 부트캠프란?</h3>
            <div style="height: 460px;background-color: #EBF4FA;border: 1px solid #3498db;border-radius: 15px;padding: 25px;display: flex;flex-direction: column;justify-content: space-evenly;align-items: start;margin-bottom: 80px;max-width: 530px">
                <h4 style="color: #3498db;font-size: 18px;padding: 10px 0px">정예 참가자로만 구성</h4>
                <p style="font-size: small;color: rgba(0,0,0,0.7);line-height: 22px;display: flex;flex-direction: column;justify-content: space-evenly;height: 70%">
                    <span>- 아직도 대규모 특강에 현혹되고 계신가요?</span>
                    <span>- 아이비에드 AP Chemistry 부트캠프에서는 시험기간까지 기초부터 탄탄하게 잡아주는 최고의 쌍방향 소통 부트캠프입니다.</span>
                    <span>- 단순한 지식 전달에 그치지 않습니다. 계속해서 질문하고 성장할 수 있게 실시간으로 교육합니다.</span>
                    <span>- 부트캠프 진행기간 동안 학교 과제까지 직접 도움받으며 실력을 쌓을 수 있습니다.</span>
                    <span>- 최소 4주간 수업과 실습으로 자신만의 실력을 갈고 닦으며 경험이 풍부한 선생님들께 도움을 받아 학생의 꿈을 보다 더 넓은 미래로 이끌어 보세요.</span>
                </p>
            </div>

            <h3>왜 이 수업을 들어야하나요?</h3>
            <ul style="margin-top: 25px;max-width: 440px;margin-bottom: 50px">
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #EBF4FA;color: #3498db;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">1</span>
                        <span style="font-weight: bolder;font-size: small">교과서만 읽는 강의 X</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">연습문제와 숙제를 적절히 배정하여 자기주도/interactive 학습 유도</span>
                        </p>
                    </div>

                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #EBF4FA;color: #3498db;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">2</span><span
                            style="font-weight: bolder;font-size: small">AP Chemistry 교육 경력에서 얻은 Chemistry 지식을 쉽게 이해하는 노하우 제공</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">프로토콜식 문제풀이 교육</span>
                        </p>
                    </div>

                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #EBF4FA;color: #3498db;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">3</span><span
                            style="font-weight: bolder;font-size: small">직접 제작한 AP Chemistry 학습 자료 제공</span>
                        <span></span>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #EBF4FA;color: #3498db;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">4</span><span
                            style="font-weight: bolder;font-size: small">AP Chemistry의 flow 정리 후 자유응답섹션 대비</span>
                        <span></span>
                    </div>
                </li>
            </ul>

            <h3>이 수업을 듣고나면</h3>
            <ul style="margin-top: 25px;max-width: 440px;margin-bottom: 50px">
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #EBF4FA;color: #3498db;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">1</span>
                        <span style="font-weight: bolder;font-size: small">AP Chemistry 시험준비를 위한 탄탄한 기초 완성</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">Jane 선생님의 경험과 지식을 토대로, AP Chemistry 시험의 핵심 개념을 정확하게 이해하고 탄탄한 기초를 마련할 수 있을 것입니다.</span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #EBF4FA;color: #3498db;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">2</span>
                        <span style="font-weight: bolder;font-size: small">개념 이해 및 문제 해결 능력 향상</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span style="margin-bottom: 5px">개념을 배우면서 동시에 AP Chemistry 시험을 준비하는 방법을 익힐 수 있으며, 문제 풀이 능력을 향상 시킬 것 입니다.</span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #EBF4FA;color: #3498db;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">3</span>
                        <span style="font-weight: bolder;font-size: small">Interactive 학습경험</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span>함께 학습하는 친구들과 선생님과의 상호작용을 통해 AP Chemistry에 대한 이해를 향상시키고, 적절한 연습문제와 숙제를 통해 공부하는 과정을 더 흥미롭게 만들 것입니다.</span>
                        </p>
                    </div>
                </li>
                <li style="margin-bottom: 20px">
                    <div style="margin-bottom: 5px;display: grid;grid-template-columns: 1fr 9fr"><span
                            style="place-self: center center;padding: 3px 10px;background-color: #EBF4FA;color: #3498db;font-weight: bolder;border-radius: 5px;font-size: small;margin-right: 10px">4</span>
                        <span style="font-weight: bolder;font-size: small">Chemistry에 대한 흥미 증가</span>
                        <span></span>
                        <p style="font-size: 14px;color: rgba(0,0,0,0.6);display: flex;flex-direction: column;margin-top: 5px">
                            <span>Jane 선생님의 수업을 통해 Chemistry에 대한 전반적인 흥미를 높일 수 있으며, 이를 통해 과목을 더 즐겁게 공부할 수 있을 것입니다.</span>
                        </p>
                    </div>
                </li>
            </ul>

        </section>
        <!--        선생님 소개-->
        <section id="teacher"
                 style="padding: 45px 15px;display: flex;flex-direction: column;align-items: center;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">선생님</span></div>
            </div>
            <!--            <h3 style="padding-bottom: 50px">선생님 소개</h3>-->
            <div style="display: grid;grid-template-columns: 1fr 1fr;max-width: 600px">
                <img src="../../assets/teacher/장지연-Jane(Blue).png"
                     class="teacher_img"
                     alt="IVYED 담당 선생님 이미지">
                <div style="display: flex;flex-direction: column;justify-content: space-between;padding:0px 20px;">
                    <h5 style="font-size: large;border-bottom: 1px solid rgba(0,0,0,0.2);padding-bottom: 15px">Jane
                        선생님</h5>
                    <img src="../../assets/school/2018-09-15105940emory.png" class="teacher_school_img"/>
                    <div class="teacher_info">Emory University Biology 전공</div>
                    <div class="teacher_info">Emory 의과대학, 서울대학교 치과대학 실험실에서 연구원</div>
                    <div class="teacher_info">Biology 관련 경력 4년, 생물학/의학 관련 논문 2편 참여</div>
                </div>
            </div>
            <div style="margin-top: 20px;background-color: #F2F6F8;border-radius: 5px;padding: 20px;font-size: 15px;max-width: 600px">
                안녕하세요, Emory University에서 Biology (Pre-med)를 전공한 Jane입니다.<br/>
                <br/>
                Emory는 전미에서 자연과학이 우수한 학교로 알려져있고, 실제로 저명하신 교수님들 밑에서 밀도있는 강의들을 들어왔습니다.<br/>
                <br/>
                그 수업들에서 GPA 3.94/4.0 이라는 우수한 성적으로 졸업하며 화학에 대한 기초를 단단히 다졌습니다.<br/>
                <br/>
                특히, 일반화학, 유기화학, 실험 과목을 모두 이수하였으며 모두 A학점을 받았습니다.<br/>
                <br/>
                더불어, 고등학교 시절 학교에서 화학을 듣지 않은 상태에서 독학을 통해 SAT II 화학 만점을 받아보기도 했습니다.<br/>
                <br/>


                여러분들이 AP Chemistry 5점으로 가는 길, 제가 알려드리겠습니다.
            </div>
            <h3 style="margin-top: 30px">수업 미리보기</h3>
            <iframe style="margin: 30px 0px;max-width: 560px" width="100%" height="315" src="https://www.youtube.com/embed/XOur5drKpS8?si=wu2UeIFLS-mKoKtY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </section>
        <!--        커리큘럼-->
        <section id="curriculum" style="padding: 45px 15px;display: flex;flex-direction: column;align-items: center;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">커리큘럼</span></div>
            </div>
            <h4>AP Chemistry 개념완성</h4>
            <div class="curriculum_container">
                <div class="curriculum_list_week">
                    <div @click="toggleList(1)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Chemical Properties</span>
                        <span style="font-size: 10px" v-if="showList[1]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[1]"
                        style="background-color: #F6F9FA;border-radius: 5px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- What is Chemistry</li>
                        <li>- Terminology
                        </li>
                        <li>- Atom, molecule, mixture</li>
                        <li>- Moles and mass</li>
                        <li>- How to read periodic table</li>
                        <li>- Periodic trends</li>
                        <li>- Electron</li>
                        <li>- Ion</li>
                        <li>- Chemical bonds</li>
                        <li>- Forces</li>
                        <li>- Lewis structure</li>
                        <li>- VSPER</li>
                        <li>- Solid, liquid, gas</li>
                        <li>- Kinetic molecular theory</li>
                        <li>- Etc. anything should be memorized</li>
                    </ul>
                </div>
                <div class="curriculum_list_week">
                    <div @click="toggleList(2)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Introduction to reaction and kinetics</span>
                        <span style="font-size: 10px" v-if="showList[2]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[2]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Equations
                        </li>
                        <li>- Stoichemistry</li>
                        <li>- Types of reaction</li>
                        <li>- Reaction rate</li>
                        <li>- Rate law</li>
                        <li>- Reaction mechanism</li>
                        <li>- Catalysis</li>
                    </ul>
                </div>
                <div class="curriculum_list_week">
                    <div @click="toggleList(3)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Equilibrium and Acid/base</span>
                        <span style="font-size: 10px" v-if="showList[3]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[3]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- What is equilibrium
                        </li>
                        <li>- Equ. Constant</li>
                        <li>- Le Chatelier</li>
                        <li>- Solubility</li>
                        <li>- Definition of acid and base</li>
                        <li>- Strong and weak acid</li>
                        <li>- Buffer</li>
                        <li>- pH and pKa</li>
                    </ul>
                </div>
                <div class="curriculum_list_week">
                    <div @click="toggleList(4)"
                         style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;cursor: pointer;">
                        <span style="font-size: 15px;font-weight: bolder">Thermodynamics and application</span>
                        <span style="font-size: 10px" v-if="showList[4]">▲</span>
                        <span style="font-size: 10px" v-else>▼</span>
                    </div>
                    <ul v-if="showList[4]"
                        style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                        class="curriculum_study_list">
                        <li>- Heat
                        </li>
                        <li>- Endo and exothermic</li>
                        <li>- Heat capacity</li>
                        <li>- Enthalpy</li>
                        <li>- Hess law</li>
                        <li>- Entropy</li>
                        <li>- Gibbs law</li>
                        <li>- Galvanic</li>
                        <li>- Electrolysis</li>
                    </ul>
                </div>
            </div>

            <!--
text
default : black
강조 파란색 : #3498db
덜 강조 파란색 : #2980b9
배경색 : #EBF4FA
-->

            <h3 style="margin-top: 80px;margin-bottom: 30px">수업 일정</h3>
            <h3 style="margin: 30px 0px;text-align: center">AP Chemistry 개념완성반 (KST)</h3>
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;max-width: 600px">
                <div style="display: grid;grid-template-rows:1.2fr 2fr 2fr;color: white;">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #3498db;margin-bottom: 10px;font-weight: bolder;font-size: smaller;padding: 10px 0px;min-height: 50px">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일</span>
                        <span style="place-self: center center">횟수</span>
                        <span style="place-self: center center;padding-right: 15px">시간</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #EBF4FA;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP Chemistry<br/> (평일반)</span>
                        <span style="place-self: center center;text-align: center">월, 화, 목</span>
                        <span style="place-self: center center;text-align: center">주 3회<br/> 90분</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">19:00 ~ 20:30</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #EBF4FA;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP Chemistry<br/> (주말반)</span>
                        <span style="place-self: center center;text-align: center">토, 일</span>
                        <span style="place-self: center center;text-align: center">주 2회<br/> 120분</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px;display: flex;flex-direction: column">
                         <span style="margin-bottom: 5px">8:30 ~ 10:30</span>
                        </span>
                    </div>
                </div>
            </div>

            <h3 style="margin: 30px 0px;text-align: center">AP Chemistry 개념완성반 (PST)</h3>
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;max-width: 600px">
                <div style="display: grid;grid-template-rows:1.2fr 2fr 2fr;color: white;">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #3498db;margin-bottom: 10px;font-weight: bolder;font-size: smaller;padding: 10px 0px;min-height: 50px">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일</span>
                        <span style="place-self: center center">횟수</span>
                        <span style="place-self: center center;padding-right: 15px">시간</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #EBF4FA;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP Chemistry<br/> (평일반)</span>
                        <span style="place-self: center center;text-align: center">월, 화, 목</span>
                        <span style="place-self: center center;text-align: center">주 3회<br/> 90분</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px">(서부)<br/>02:00 ~ 03:30<br/>(동부)<br/>05:00 ~ 06:30</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;background-color: #EBF4FA;gap: 3px;margin-bottom: 10px;font-size: 14px;color: black">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP Chemistry<br/> (주말반)</span>
                        <span style="place-self: center center;text-align: center">금,토</span>
                        <span style="place-self: center center;text-align: center">주 2회<br/> 120분</span>
                        <span style="place-self: center center;text-align: center;padding-right: 15px;display: flex;flex-direction: column">
                         <span style="margin-bottom: 5px">(서부)<br/>15:30 ~ 17:30<br/>(동부)<br/>18:30 ~ 20:30</span>
                        </span>
                    </div>
                </div>
            </div>

        </section>
        <!--        후기-->
        <section id="review"
                 style="display: flex;flex-direction: column;justify-content: space-evenly;align-items: center;padding: 45px 15px;">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">후기</span></div>
            </div>
            <!--            <h3 style="font-size: x-large;margin-bottom: 35px">수강생 후기</h3>-->
            <div style="width: 300px;background-color: #F2F6F8;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;padding: 15px 28px;border-radius: 5px;margin-bottom: 50px">
                <h4 style="font-size: 15px;text-align: center;padding: 15px 0px">“AP Chemistry 개념과 함께 문제풀이까지 수업해주셔서 빨리 고득점에 도달할 수 있었습니다.”</h4>
                <img src="../../assets/avatar4.png" alt="IVYED student avatar4 img"
                     style="width: 140px;height: 140px;border-radius: 5px"/>
                <span style="font-size: small;padding-top: 10px">캐나다 보딩스쿨 10학년</span>
            </div>
            <div style="width: 300px;background-color: #F2F6F8;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;padding: 15px 28px;border-radius: 5px;margin-bottom: 50px">
                <h4 style="font-size: 15px;text-align: center;padding: 15px 0px">“미리 AP Chemistry를 준비하고 있다가 알게된 선생님이였는데, 덕분에 모의고사에서 항상 5점을 받고 있습니다.”</h4>
                <img src="../../assets/avatar1.png" alt="IVYED student avatar3 img"
                     style="width: 140px;height: 140px;border-radius: 5px"/>
                <span style="font-size: small;padding-top: 10px">미국 보딩스쿨 10학년</span>
            </div>
            <div style="width: 300px;background-color: #F2F6F8;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;padding: 15px 28px;border-radius: 5px;margin-bottom: 50px">
                <h4 style="font-size: 15px;text-align: center;padding: 15px 0px">“노하우, 정리해주신 연습문제들 덕분에 문제푸는 실력이 많이 늘었습니다.”</h4>
                <img src="../../assets/avatar3.png" alt="IVYED student avatar1 img"
                     style="width: 140px;height: 140px;border-radius: 5px"/>
                <span style="font-size: small;padding-top: 10px">미국 보딩스쿨 11학년</span>
            </div>
        </section>
        <FloatingBootcampButton background-color="#3498db"/>
    </div>
</template>
<script>
    import FloatingBootcampButton from "./FloatingBootcampButton";
    export default {
        components: {FloatingBootcampButton},
        name: 'APChemistry',
        data() {
            // 변수 선언 및 값 할당
            return {
                formData: {
                    checkbox: '',
                    checkbox1: '',
                    checkbox2: '',
                },
                selectedItem: '',
                currentCourse: '',
                userResponse: '',
                addItemResponse: '',
                itemAdded: false,
                currentItem: '',
                showClassroomPage: true,
                showAssignmentPage: false,
                showSchedulePage: false,
                bootcampResponse: [],
                bootcampSchedule: '',
                addedToCart: false,
                showCartAddedDialog: false,
                hasSameItemAlready: false,
                popUpMessage: '',
                eightWeek: true,
                twelveWeek: false,
                showList: {
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false,
                    8: false,
                    9: false,
                    10: false,
                    11: false,
                    12: false,
                    13: false,
                    14: false,
                    15: false,
                    16: false,
                    17: false,
                    18: false,
                    19: false,
                    20: false,
                    21: false,
                    22: false,
                    23: false,
                    24: false,
                    25: false,
                    26: false,
                    27: false,
                    28: false,
                    29: false,
                    30: false,
                },
            };
        },
        methods: {
            // 중간 네비게이션 바 값들 클릭하면 스크롤 하는 메서드 1
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            // 중간 네비게이션 바 값들 클릭하면 스크롤 하는 메서드 2
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el.querySelector('.v-window__prev').querySelector('.v-btn').click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el.querySelector('.v-window__next').querySelector('.v-btn').click();
                    this.drag = false;
                    this.touch = false;
                }
            },
            // 다른 route / path 로 이동
            goToPaymentPage() {
                this.$router.push('/payment');
            },
            // 날짜 formatting 메서드
            formatDate(datetime) {
                const date = new Date(datetime);
                const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
                const formattedDate =
                    // date.getUTCFullYear() + "년 " +
                    months[date.getUTCMonth()] +
                    ' ' +
                    date.getUTCDate() +
                    '일(' +
                    this.getDayOfWeek(date.getUTCDay()) +
                    ') ' +
                    date.getUTCHours() +
                    ':' +
                    (date.getUTCMinutes() < 10 ? '0' : '') +
                    date.getUTCMinutes();

                return formattedDate;
            },
            // 한글로 요일 불러오는 헬퍼 메서드
            getDayOfWeek(day) {
                const days = ['일', '월', '화', '수', '목', '금', '토'];
                return days[day];
            },

            // 커리큘럼에서 8주 / 12주 활성/비활성화 하는 메서드
            showEightWeekCurriculum() {
                this.eightWeek = true;
                this.twelveWeek = false;
            },
            showTwelveWeekCurriculum() {
                this.eightWeek = false;
                this.twelveWeek = true;
            },

            // 장바구니 담는 메서드
            // 장바구니에 성공적으로 담길시 호출하는 메서드
            showAddedToCartMessage(message) {
                this.showCartAddedDialog = true;
                this.popUpMessage = message;
            },

            navigateToNewPage() {
                if (!this.store.loggedIn) {
                    this.$router.push('/signin');
                }

                // // 여기서 카트에 넣어야함
            },
            // 커리큘럼 토글
            toggleList(listNumber) {
                this.showList[listNumber] = !this.showList[listNumber];
            },
        },
    };
</script>
<style scoped>

    .label_place{
        padding-right: 265px;
        padding-bottom: 15px;
    }

    @media (min-width: 600px) {
        .label_place{
            padding-right: 425px;
        }
    }


    .label {
        z-index: 0;
        width: 65px;  /* 변경된 너비 */
        height: 30px; /* 변경된 높이 */
        box-sizing: content-box;
        padding-top: 8px; /* 변경된 패딩 */
        position: relative;
        background: #3498db;
        color: white;
        font-size: 7px; /* 변경된 글꼴 크기 */
        letter-spacing: 0.15em; /* 변경된 글자 간격 */
        text-align: center;
        text-transform: uppercase;
    }

    .label:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 7px solid #eee; /* 변경된 선 높이 */
        border-left: 30px solid transparent; /* 변경된 왼쪽 삼각형 크기 */
        border-right: 30px solid transparent; /* 변경된 오른쪽 삼각형 크기 */
    }

    .curriculum_description_info {
        padding: 20px;
        background-color: #F6F9FA;
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 440px;
        margin-top: 20px;
        border-radius: 5px;
        max-width: 700px;
    }

    @media (min-width: 600px) {
        .curriculum_description_info {
            height: 350px;
        }
    }


    .notice_why_study {
        padding-left: 0px;
    }

    @media (min-width: 600px) {
        .notice_why_study {
            margin-left: -10px;
        }
    }

    @media (min-width: 600px) {
        .notice_why_study1 {
            margin-left: -15px;
        }
    }

    .teacher_info {
        font-size: x-small;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 5px;
    }

    @media (min-width: 600px) {
        .teacher_info {
            font-size: small;
        }
    }

    .teacher_school_img {
        width: 100%;
        height: 80px;
        object-fit: contain;
        place-self: center center;
    }

    @media (min-width: 600px) {
        .teacher_school_img {
            height: 180px;
        }
    }

    .teacher_img {
        place-self: center center;
        border-radius: 15px;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    @media (min-width: 400px) {
        .teacher_img {
            width: 200px;
            height: 200px;
        }
    }

    @media (min-width: 600px) {
        .teacher_img {
            width: 300px;
            height: 300px;
        }
    }

    .curriculum_container {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 20px 25px 10px;
        margin-top: 25px;
        min-width: 350px;
    }

    @media (min-width: 380px) {
        .curriculum_container {
            min-width: 380px;
        }
    }

    @media (min-width: 400px) {
        .curriculum_container {
            min-width: 400px;
        }
    }

    @media (min-width: 600px) {
        .curriculum_container {
            min-width: 600px;
        }
    }

    @media (min-width: 700px) {
        .curriculum_container {
            min-width: 850px;
        }
    }

    .curriculum_list_week:last-child {
        border-bottom: none;
    }

    .curriculum_list_week {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-top: 8px;
        width: 100%;
        padding: 5px 5px;
    }

    *, html, ul, li {
        margin: 0;
        padding: 0;
        font-family: "Noto Sans KR";
    }

    .hover_nav {
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        cursor: pointer;
    }

    .hover_nav:hover {
        background-color: #E8344E;
        color: white;
    }

    .study_info {
        display: grid;
        grid-template-columns: 0.5fr 5fr 4.5fr;
        margin-bottom: 10px;
        gap: 5px;
    }

    .study_info span {
        font-size: small;
    }

    .curriculum_description li {
        border-radius: 5px;
        border: 1px solid #452DDD;
        padding: 30px;
    }

    .curriculum_click {
        background-color: #452DDD !important;
    }

    .curriculum_study_list li {
        font-size: small;
        color: rgba(0, 0, 0, 0.6);
        padding: 5px 5px;
    }

    .curriculum_list li {
        padding: 10px 20px;
        border-radius: 20px;
        color: white;
        background-color: #D8D3F8;
        font-weight: bolder;
        font-size: medium;
        cursor: pointer;
    }

    .target_benefit li span {
        font-size: small;
    }

    .target_benefit li h2 {
        color: #452DDD;
    }

    .target_benefit li {
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 5px;
    }

    .target_list li > span {
        padding: 0px 15px;
    }

    .target_list li {
        background-color: #F3F2FD;
        font-size: 16px;
        font-weight: bolder;
        max-width: 320px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .introduction_list li {
        background-color: white;
        border-radius: 15px;
        margin: 0px 10px;
        padding: 2px 10px;
    }

    * {
        list-style: none;
    }

    .sticky {
        background: blue;
        height: 50px;
        position: sticky;
        top: 0;
    }

    .toolbar-style-mobile {
        font-size: 14px;
        font-weight: bold;
        position: sticky;
        top: 40px;
    }

    .toolbar-style {
        font-family: S-CoreDream-6Bold;
        font-size: 18px;
        font-weight: bold;
    }

    .sticky-toolbar {
        color: white;
        z-index: 0;
        top: 64px;
        position: sticky;
        position: -webkit-sticky;
    }

</style>
