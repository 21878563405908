<template style="background-color: #f6f6f6">
    <div class="faq-page">
<!--        <p class="justify-center sub-main-01  py-10 ">자주 묻는 질문</p>-->
        <div class="py-12"></div>

        <div class="faq-title-text text-center hidden-xs-only" style="font-size: 36px;font-weight: bold"> 자주 묻는 질문</div>
        <div class="faq-title-text-mobile text-center hidden-sm-and-up" style="font-size: 36px;font-weight: bold"> 자주 묻는 질문</div>
        <div class="py-7"></div>
        <div class="faq-container px-8">
            <nav class="accordion arrows">
                <!--        <header class="box">-->
                <!--            <label for="acc-close" class="box-title">Accordion menu</label>-->
                <!--        </header>-->
                <h1 class="ma-3">학생</h1>

                <input type="radio" name="accordion" id="cb1"/>
                <section class="box">
                    <label class="box-title" for="cb1"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 수업이 가능한 과목은 어떤것인가요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        <div class="py-3">
                            해외 (미국, 영국, 및 국제학교) 교과과정 수업들 모두 제공하고 있습니다.
                        </div>
                        <div>
                            - Digital SAT/SAT
                        </div>
                        <div>
                            - ACT
                        </div>
                        <div>
                            - AP 전과목
                        </div>
                        <div>
                            - IB 전과목
                        </div>
                        <div> - GPA 내신관리
                        </div>
                        <div> - 기타 과목
                        </div>
                        <div> - 멘토링, 컨설팅
                        </div>
                    </div>
                </section>
                <input type="radio" name="accordion" id="cb2"/>
                <section class="box">
                    <label class="box-title" for="cb2"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> 선생님은 어떤 분들인가요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        <div> IVYED 과외 선생님들은 Harvard, Stanford, Yale, Cornell 등 미국 상위 1% 대학교 출신의 재학, 휴학, 졸업하신 선생님들로 이루어져
                            있으며 대학원 출신 선생님까지 있습니다.
                            <div class="py-2"></div>
                            미국 상위 1% 대학교라는 최소 조건 외에도 과외 경력과 교습능력 등을 자체 검증 시스템을 통해서 선생님들을 선발하고 있습니다.
                        </div>

                    </div>
                </section>
                <input type="radio" name="accordion" id="cb3"/>
                <section class="box">
                    <label class="box-title" for="cb3"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> 체험수업은 어떻게 신청하나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content ">
                        아이비에드 홈페이지에서 회원가입/로그인 후 체험수업 신청이 가능합니다.
                        <div class="py-2"></div>
                        자세한 신청 방법은 아래의 노션을 확인해주세요.
                        <div class="py-2"></div>
                        <a href="https://ivyed.notion.site/IVYED-f97adf3d1e764e8b9284e4af0d949793" target="_blank">체험수업
                            신청방법</a>
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb4"/>
                <section class="box">
                    <label class="box-title" for="cb4"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> 수업은 어떻게 진행되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content ">
                        IVYED는 온라인 실시간 1:1 과외 형식으로, 학생과 선생님들이 화면에 서로 필기할 수 있는 IVYED만의 온라인 강의실을 사용해서 수업이 진행되고 있습니다.
                        <div class="py-2"></div>
                        자세한 수업 진행방식은 아래 링크를 통해 확인해주세요.
                        <div class="py-2"></div>
                        <a href="https://blog.naver.com/ivyed/223153932262" target="_blank">수업 진행방법 알아보기</a>
                    </div>
                </section>
                <!--                <div class="py-5"></div>-->

                <!--                <h1 class="ma-3">수업방식</h1>-->
                <input type="radio" name="accordion" id="cb5"/>
                <section class="box">
                    <label class="box-title" for="cb5"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 어떤 자료로 수업이 진행되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        학생분의 부족한 부분에 맞춰 선생님께서 준비한 자료, 학생분의 학교 자료나 프린트물 및 일반 교재로 과외가 진행됩니다
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb6"/>
                <section class="box">
                    <label class="box-title" for="cb6"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span > <span style="">횟수/스케쥴/선생님을 바꿀 수 있나요?</span></span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        IVYED는 학생의 사정에따라 주 수업횟수, 스케쥴, 선생님 모두 변경이 가능합니다.
                    </div>

                </section>

                <div class="py-5"></div>

                <h1 class="ma-3">결제/환불</h1>

                <input type="radio" name="accordion" id="cb7"/>
                <section class="box">
                    <label class="box-title" for="cb7"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 결제는 어떻게 할 수 있나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        IVYED 홈페이지의 마이페이지-수업에서 카드 등록 후 결제가 가능합니다.
                        <div class="py-2">카드 이외에도 계좌이체 결제도 가능합니다.</div>

                    </div>

                </section>

                <input type="radio" name="accordion" id="cb8"/>
                <section class="box">
                    <label class="box-title" for="cb8"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 환불 규정은 어떻게 되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        <div>
                            교습 시작 전: 이미 납부한 교습비 등의 전액 환불

                        </div>
                        <div class="py-2"></div>
                        <div>
                            교습 시작 후:<br/>
                            <div class="pl-2">
                                -수강기간이 1개월(30일) 이내인 강좌의 경우
                                <br/>
                                ->수강시작일로부터 ⅓ 경과 전인 경우: 환불액 = 이미 납부한 교습비의 ⅔에 해당하는 금액
                                <br/>
                                ->수강시작일로부터 ½ 경과 전인 경우: 환불액 = 이미 납부한 교습비의 ½에 해당하는 금액
                                <br/>
                                ->수강시작일로부터 ½ 경과 후: 반환하지 않음
                                <br/>
                            </div>
                        </div>
                        <div class="py-2"></div>
                        <div class="pl-2">
                            -수강기간이 1개월(30일)을 초과하는 강좌의 경우
                            <br/>
                            <div class="pl-3">->환불 접수 발생월의 반환대상 교습비 등 (교습기간이 1개월 이내인 경우의 기준에 따라 산출한 금액)과 나머지 월의 교습비 등의
                                전액을 합산한 금액
                            </div>
                        </div>
                        <div class="py-3"></div>
                        <div class="font-italic">
                            ** 학생이 당일 취소한 과외건은 환불 대상에 포함하지 않습니다.
                            <br/>
                            ** 수강률은 당월 수강한 강의 수/전체 강의 수'를 의미합니다.
                            <br/>
                            <br/>

                            더 자세한 내용은 이용약관 제4장 청약철회 및 환불 내용을 참고부탁드립니다


                        </div>
                    </div>

                </section>

                <div class="py-5"></div>
                <h1 class="ma-3">선생님</h1>
                <input type="radio" name="accordion" id="cb9"/>
                <section class="box">
                    <label class="box-title" for="cb9"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 선생님 지원은 어떻게 하나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        IVYED 홈페이지에서 회원 가입하고 신청서를 제출하시면 됩니다.
                        <br/>
                        <br/>

                        자세한 선생님 지원 내용은 아래 노션을 확인해주세요.
                        <br/>
                        <br/>
                        <a href="https://ivyed.notion.site/262e0e113d9046128c5f8eadf4669b13" target="_blank">선생님
                            지원방법</a>
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb10"/>
                <section class="box">
                    <label class="box-title" for="cb10"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 선생님 지원 자격은 어떻게 되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        U.S. News Ranking 기준 상위 1% 대학교에 재학, 졸업하셔야 합니다.
                        <br/>
                        <br/>
                        편입이나 자퇴 등의 경우에는 제외됩니다.
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb11"/>
                <section class="box">
                    <label class="box-title" for="cb11"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 학생 계정으로 잘못 가입했어요.</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        학생으로 잘못 가입하신 경우에는 IVYED 카카오톡 채널로 요청주시면 됩니다.
                    </div>

                </section>

<!--                <input type="radio" name="accordion" id="cb12"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb12"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile-2': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> 수업 횟수, 스케쥴, 선생님을 중간에 바꿀 수 있나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        🙆🏻‍ IVYED는 학생의 사정에따라 주 수업횟수, 스케쥴, 선생님 모두 변경이 가능합니다!-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        수업횟수나 스케쥴은 매치된 선생님과 조율이 가능합니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        혹 선생님과 조율이 안되거나 다른 사유로 선생님 변경이 필요할 시 IVYED 카카오 채널로 요청하시면 다른 선생님과 매칭해드립니다.-->
<!--                    </div>-->

<!--                </section>-->

<!--                <input type="radio" name="accordion" id="cb13"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb13"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> 사정이 생겨 수업을 못할 땐 어떡하나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        수업 요일과 시간을 부득이한 개인 사정으로 인해 변경해야 할 때는 담당 선생님과 사전 조율 후 일정을 미뤄 진행하실 수 있습니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        별도 이야기 없이 잦은 변경이 이루어질 경우, 페널티가 부과될 수 있으니 반드시 미리 조율 후 일정 변경해 주시기 바랍니다!-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        특히 수업 당일에 취소하거나 변경을 요청하는 경우에는 수업이 차감될 수 있으므로 최소한 1일 전에 선생님께 말씀해 주세요!😀-->
<!--                    </div>-->

<!--                </section>-->
<!--                <input type="radio" name="accordion" id="cb14"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb14"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> 여러 과목을 함께 수강할 수 있나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        🙆🏻‍️ 물론 가능합니다! 카톡 상담채널로 개별 요청해주세요!-->
<!--                    </div>-->

<!--                </section>-->

<!--                <input type="radio" name="accordion" id="cb15"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb15"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> 수업 가능한 과목은 어떻게 되나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        IVYED는 확실한 케어와 최상의 수업을 보장하기 위해 현재 수학&과학 계열 과목 수업에만 집중하고있습니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        학생들이 많이 어려워하는 Computer Science, Calculus, Physics 등의 advanced 과정 부터 중/고 일반 과정까지 포괄적으로 수업을-->
<!--                        제공해드립니다! 😊-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        빠른 시일내로 과목을 확장하여 서비스 제공할 수 있도록 노력하겠습니다!-->
<!--                    </div>-->

<!--                </section>-->
<!--                <div class="py-5"></div>-->

<!--                <h1 class="ma-3">선생님</h1>-->
<!--                <input type="radio" name="accordion" id="cb16"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb16"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> 선생님 매칭은 어떻게 되나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        선생님 매칭은 '무료 체험수업 신청서' 후 제출이 완료되면 시작됩니다!-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        회원가입 후 '무료 체험수업 신청서'를 작성할 때, 원하는 선생님 스타일과 학생의 정보 (재학중인 학교, 희망 전공 계열, 선호 수업시간대 등)을 꼼꼼히 작성해주시면-->
<!--                        학생에게 맞는 최적의 선생님을 매칭해 드리고있습니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        선생님 매칭은 신청서 제출 후 영업일 기준 3일 정도 소요됩니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        단, 수업 가능 시간대가 너무 적거나 매칭이 어려운 조건으로 요청하실 경우 매칭기간이 좀 더 소요될 수 있으며, 이 경우 사전에 안내를 드리고 있습니다.-->
<!--                    </div>-->

<!--                </section>-->

<!--                <input type="radio" name="accordion" id="cb17"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb17"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> IVYED 선생님은 어떤 학교 출신이신가요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        IVYED의 과외 선생님들은 Harvard, Stanford, Yale, MIT 등 미국 상위 1% 대학교 출신의 재학, 휴학, 졸업생들로 이루어져 있습니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        이 최소 조건 외에도 과외 경력과 교습능력 등을 꼼꼼히 평가받으신 분들로 구성되어 있습니다.-->
<!--                        <br/>-->
<!--                        <br/>-->

<!--                        상위 1% 대학 목록은 USNews Rank 기준으로 선정됩니다.-->
<!--                    </div>-->

<!--                </section>-->

<!--                <input type="radio" name="accordion" id="cb18"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb18"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span>                 IVYED 선생님들은 모두 재학생인가요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        IVYED의 과외 선생님들은 Harvard, Stanford, Yale, MIT 등 미국 상위 1% 대학교 출신의 재학, 휴학, 졸업하신 선생님들로 이루어져 있습니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        이 최소 조건 외에도 과외 경력과 교습능력 등을 꼼꼼히 평가받으신 분들로 구성되어 있습니다.-->
<!--                        <br/>-->
<!--                        <br/>-->

<!--                        재학생 선생님은 입시를 최근에 경험하셔서 입시 관련 상담까지 일부 도와드릴 수 있는 장점이 있고, 졸업생 선생님은 좀 더 교수 경험이 풍부하다는 장점이 있습니다.😊-->
<!--                    </div>-->

<!--                </section>-->

<!--                <input type="radio" name="accordion" id="cb19"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb19"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> 선생님을 중간에 바꿀 수 있나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        🙆🏻 ‍️IVYED는 학생이 <br/>'학생정보 입력 및 수업 신청서'에 적어주신 내용을 반영하여 선생님을 매칭해 드리고 있습니다!-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        매칭된 선생님과 수업 진행 중, 선생님 변경이 필요하신 경우 월 1회까지 선생님 변경 요청이 가능합니다.-->
<!--                        <br/>-->
<!--                        <br/>-->

<!--                        단, 재매칭이 진행되는 동안 기존 수업은 진행되지 않습니다.-->
<!--                        <br/>-->
<!--                        <br/>-->

<!--                        IVYED는 횟수제 수업으로 새로운 선생님 매칭 이후 남은 수업을 진행해 주시면 됩니다!😀-->
<!--                    </div>-->

<!--                </section>-->
<!--                <div class="py-5"></div>-->

<!--                <h1 class="ma-3">결제/환불</h1>-->
<!--                <input type="radio" name="accordion" id="cb20"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb20"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> 환불 규정은 어떻게 되나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        <strong>- 교습 시작 전:</strong> 이미 납부한 교습비 등의 전액 환불-->
<!--                        <br/>-->
<!--                        <strong>- 교습 시작 후:</strong>-->
<!--                        <br/>-->
<!--                        <v-card flat class="ml-5">-->
<!--                            1. 수강기간이 1개월(30일) 이내인 강좌의 경우-->
<!--                            <br/>-->
<!--                            <v-card flat class="ml-7">-->
<!--                                - 수강시작일로부터 ⅓ 경과 전인 경우: 환불액 = 이미 납부한 교습비의 ⅔에 해당하는 금액-->
<!--                                <br/>-->
<!--                                - 수강시작일로부터 ½ 경과 전인 경우: 환불액 = 이미 납부한 교습비의 ½에 해당하는 금액-->
<!--                                <br/>-->
<!--                                - 수강시작일로부터 ½ 경과 후: 반환하지 않음-->
<!--                                <br/>-->
<!--                            </v-card>-->

<!--                            <br/>-->
<!--                            <br/>-->

<!--                            2. 수강기간이 1개월(30일)을 초과하는 강좌의 경우-->
<!--                            <br/>-->
<!--                            <v-card flat class="ml-7">-->

<!--                                - 환불 접수 발생월의 반환대상 교습비 등 (교습기간이 1개월 이내인 경우의 기준에 따라 산출한 금액)과 나머지 월의 교습비 등의 전액을 합산한 금액-->
<!--                            </v-card>-->
<!--                        </v-card>-->
<!--                        <br/>-->
<!--                        ** 학생이 당일 취소한 과외건은 환불 대상에 포함하지 않는다.-->
<!--                        <br/>-->
<!--                        ** 수강률은 당월 수강한 강의 수/전체 강의 수'를 의미한다.-->
<!--                        <br/>-->
<!--                        <br/>-->

<!--                        더 자세한 내용은 이용약관 제4장 청약철회 및 환불 내용을 참고부탁드립니다.-->
<!--                    </div>-->

<!--                </section>-->
<!--                <input type="radio" name="accordion" id="cb21"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb21"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> 환불 절차는 어떻게 되나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        수업료 환불 요청은 IVYED 카카오채널로 연락주시면 5영업일 이내에 교습비를 환불합니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        환불 수업료는 체크카드를 이용해주신 경우에는 취소 후 바로 환급되고, 신용카드의 경우에는 카드사에 따라 평균 5~7일 정도 소요됩니다.-->
<!--                        <br/>-->
<!--                        <br/>-->

<!--                        이미 카드값이 빠져나간 이후라면 그 다음달 카드값(결제대금)에 취소된 금액이 마이너스 되어 청구되는 점 참고부탁드립니다.-->
<!--                    </div>-->

<!--                </section>-->
<!--                <div class="py-5"></div>-->

<!--                <h1 class="ma-3">선생님 지원</h1>-->
<!--                <input type="radio" name="accordion" id="cb22"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb22"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> [선생님 지원] 선생님 지원 자격은 어떻게 되나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        Harvard, Stanford, Yale, MIT 등 미국 상위 1% 대학교 출신의 재학, 휴학, 졸업한 선생님들만 활동이 가능합니다. 타 대학 선생님들을 모시지 못 하는-->
<!--                        점 양해 부탁드립니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        타 대학 편입, 자퇴 등의 경우에는 역시 활동이 불가하신 점 양해부탁드립니다.-->
<!--                    </div>-->

<!--                </section>-->
<!--                <input type="radio" name="accordion" id="cb23"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb23"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> [선생님 지원] 해외에서도 선생님 활동이 가능한가요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        🙆🏻‍️IVYED는 유학 전문 과외 특성상 해외 거주 중인 선생님들도 활동이 가능합니다!-->
<!--                    </div>-->

<!--                </section>-->

<!--                <input type="radio" name="accordion" id="cb24"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb24"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> [선생님 지원] 선생님 활동 세부규정이 궁금해요.</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        선생님 활동 시의 세부규정에 대해서는 선생님 지원을 모두 완료하시면 안내받으실 수 있습니다.-->
<!--                        <br/>-->
<!--                        <br/>-->

<!--                        현재 활동중이시라면 IVYED 카카오톡 채널로 문의주시거나, 이전에 안내받으신 선생님 가이드에서 확인하실 수 있습니다!-->
<!--                    </div>-->

<!--                </section>-->

<!--                <input type="radio" name="accordion" id="cb25"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb25"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> [선생님 지원] 학생계정으로 잘못 가입했어요.</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        학생으로 잘못 가입하신 경우에 IVYED 카카오톡 채널로 요청주시면 선생님 계정으로 변경할 수 있도록 안내해드리겠습니다.-->
<!--                    </div>-->

<!--                </section>-->

<!--                <input type="radio" name="accordion" id="cb26"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb26"><span class="question-text"-->
<!--                                                              :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> [선생님 지원] 선생님 지원은 어떻게 하나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        IVYED 홈페이지에서 회원 가입하고 신청서 제출하시면 지원 끝!-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        1~2 영업일 내로 바로 연락드립니다! 😊-->
<!--                        <br/>-->
<!--                        <br/>-->

<!--                        IVYED에 선생님 지원 관련 추가 문의사항은 IVYED 카카오 채널이나-->
<!--                        하단 링크를 클릭하세요!-->
<!--                        <br/>-->
<!--                        <a href="https://www.notion.so/262e0e113d9046128c5f8eadf4669b13">👉선생님 모집 페이지 바로가기</a>-->

<!--                    </div>-->

<!--                </section>-->
            </nav>

        </div>
<div class="py-7" style="background-color: #f6f6f6"></div>
    </div>
</template>

<script>
    // import FaqTemplate from "./FaqTemplate";
    // import FaqTitle from "./FaqTitle";
    export default {
        name: "NewFaq",
        components: {}
    }
</script>

<style scoped>
    .faq-page {
        background: #f6f6f6 !important;
    }

    /*body {*/
    /*    height: calc(100% - 20px);*/
    /*    width: calc(100% - 20px);*/
    /*    margin: 0;*/
    /*    padding: 10px;*/
    /*    display: flex;*/
    /*    background: #f2f2f2;*/
    /*    color: rgba(0, 0, 0, .87);*/
    /*    font-family: 'Roboto', sans-serif;*/
    /*}*/

    .accordion {
        margin: auto;
        max-width: 600px;
    }

    .accordion input {
        display: none;
    }

    .box {
        /*border-radius: 1;*/
        vertical-align: center;
        position: relative;
        background: white;
        /*height: 52px;*/
        margin-bottom: 20px;
        transition: all .15s ease-in-out;
    }

    .box::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        vertical-align: center;
        box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
    }

    header.box {
        /*background: #00BCD4;*/
        z-index: 100;
        cursor: initial;
        box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px -2px rgba(0, 0, 0, .12), 0 2px 4px -4px rgba(0, 0, 0, .24);
    }

    header .box-title {
        margin: 0;
        font-weight: normal;
        font-size: 16pt;
        /*color: white;*/
        cursor: initial;
    }

    .box-title {
        /*width: calc(100% - 40px);*/
        height: 64px;
        line-height: 52px;
        /*vertical-align: center;*/
        padding: 0 20px;
        /*display: inline-block;*/
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .box-content {
        /*width: calc(100% - 40px);*/
        padding: 0px 50px 50px 50px;
        font-size: 12pt;
        /*color: rgba(0,0,0,.54);*/
        display: none;
    }

    .box-close {
        position: absolute;
        height: 64px;
        width: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        display: none;
    }

    input:checked + .box {
        height: auto;
        margin: 16px 0;
        box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
    }

    input:checked + .box .box-title {
        /*border-bottom: 1px solid rgba(0, 0, 0, .18);*/
    }

    input:checked + .box .box-content,
    input:checked + .box .box-close {
        display: inline-block;
    }

    .arrows section .box-title {
        padding-left: 44px;
        width: calc(100% - 64px);
    }

    .arrows section .box-title:before {
        position: absolute;
        display: block;
        content: '\203a';
        /*vertical-align: center;*/
        font-size: 18pt;
        left: 20px;
        top: -2px;
        transition: transform .15s ease-in-out;
        /*color: rgba(0, 0, 0, .54);*/
    }

    input:checked + section.box .box-title:before {
        transform: rotate(90deg);
    }

    .question-text {
        font-weight: bold;
    }

    .question-text-mobile {
        font-weight: bold;
        font-size: 14px;
    }

    .question-text-mobile-2 {
        font-weight: bold;
        font-size: 13px;
    }

    .faq-container {
        height: 100%;
        background: #f6f6f6 !important;

    }
    .gradient-text {
        /*background: linear-gradient(to right, #A20916, #FF4E50);*/
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }
    .faq-title-text{
        color: transparent;
        /*background-image: linear-gradient(to right, #A20916, white, red);*/
        background-image: linear-gradient(to right, #780912, #A20916, #DE2828, #fff9e0, #f6f5f5);
        -webkit-background-clip: text;
        background-clip: text;
    }
    .faq-title-text-mobile{
        color: transparent;
        /*background-image: linear-gradient(to right, #A20916, white, red);*/
        background-image: linear-gradient(to right, #780912, #A20916, #DE2828, #fff9e0);
        -webkit-background-clip: text;
        background-clip: text;
    }
</style>
