<template>
    <div style="background-color: #F6F6F6">

        <div class="text-center  pb-8" style="padding-top: 100px">
            <v-chip color="#A20916" class="white--text font-weight-bold">최고의 선생</v-chip>
        </div>
        <div class="text-center">
            <h1 style="letter-spacing: 0px; line-height: 36px">
                <span class="border-highlight">검증된</span>선생님이
            </h1>
            <div></div>
            <h1>기다리고 있어요</h1>
            <div class="py-2"></div>
            <div style="font-size: 17px" class="font-weight-bold">
                최고의 선생님 600명*을 보유한 시스템 과외
            </div>
        </div>
        <div class="py-5"></div>
        <SchoolCarousel style="background-color: transparent !important; max-width: 95%"></SchoolCarousel>
        <div class="py-5"></div>
        <div class="center-horizontal">
            <v-card width="90%" class=" rounded-lg px-5 py-5" max-width="800px"
                    style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;">
                <v-row>
                    <v-col cols="6" style="">
                        <div style="color: #A30717; font-size: 25px;" class="font-weight-bold text-center">100%</div>
                        <div style="color: #000000; font-size: 20px;" class="font-weight-bold text-center">명문대</div>
                        <div style="font-size: 14px" class="text-center">아이비리그 및 상위 1% 대학 등</div>

                    </v-col>
                    <v-col cols="1">
                        <div style="border-left: 1px dotted darkgray; height: 100%;"></div>
                    </v-col>
                    <v-col cols="5" style="">
                        <div style="color: #A30717; font-size: 25px;" class="font-weight-bold text-center">100%</div>
                        <div style="color: #000000; font-size: 20px;" class="font-weight-bold text-center">경력자</div>
                        <div style="font-size: 14px" class="text-center">학생을 가르쳐본 경험</div>

                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div class="py-8"></div>
        <div>
            <TeacherCarousel></TeacherCarousel>
        </div>
        <div>
            <div class="text-center font-weight-bold" style="font-size: 23px">
                객관적인 검증 절차로<br/> 6단계로 나누어 평가해요
            </div>
            <div class="center-horizontal">
                <div class="ma-4 text-center pb-9"
                     style="box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; height: 350px; border-radius: 25px; height: 100%; max-width: 800px">
                    <div class="font-weight-bold py-6" style="font-size: 18px; letter-spacing: -1px">유학업계 유일 <span
                            style="color: #2200cc">화상면접</span> 진행!
                    </div>
                    <div class=" text-center">
                        <img style="border-radius: 25px" height="200px" src="../../assets/video-call.png">
                    </div>
                    <div class="py-2"></div>
                    <v-row>

                        <v-col style=";" cols="4">❶ 서류평가</v-col>
                        <v-col cols="4">❷ 학력/경력</v-col>
                        <v-col cols="4">❸ 화상면접</v-col>
                        <v-col style="line-height: 0px" cols="4">❹ 시범수업</v-col>
                        <v-col style="line-height: 0px" cols="4">❺ 교육이수</v-col>
                        <v-col style="line-height: 0px" cols="4">❻ 온보딩</v-col>
                    </v-row>

                </div>
            </div>
        </div>
        <div class="py-10"></div>
        <VerticalTeacherCarousel></VerticalTeacherCarousel>
        <div class="py-10"></div>

    </div>
</template>

<script>
    import SchoolCarousel from "./SchoolCarousel";
    import TeacherCarousel from "./TeacherCarousel";
    import VerticalTeacherCarousel from "./VerticalTeacherCarousel";

    export default {
        name: "TeacherIntroNew",
        components: {
            VerticalTeacherCarousel,
            TeacherCarousel, SchoolCarousel}
    }
</script>

<style scoped>


    .ink {
        -webkit-text-decoration: #DE2828 solid underline;
        text-decoration: #DE2828 solid underline;
        -webkit-text-underline-position: auto;
        text-underline-position: auto;
        text-decoration-thickness: 8px;
        margin-bottom: 5px !important;
        border-radius: 30px;
    }

    .highlight {
        display: inline;
        padding: .25em 0;
        background: #F2F2F2;
        color: #A20916;
        box-shadow: .5em 0 0 #F2F2F2, -.5em 0 0 #F2F2F2;
        font-weight: bold;
    }

    .main-highlight {
        display: inline;
        padding: .25em 0;
        background: #A20916;
        color: #ffffff;
        box-shadow: .5em 0 0 #A20916, -.5em 0 0 #A20916;
        font-weight: bold;
        font-size: 17px;
        border-radius: 5px;
        letter-spacing: -1px !important;
    }

    .highlight-red {
        border-radius: 1em 0 1em 0;
        background-image: linear-gradient(
                -100deg,
                rgba(255, 20, 0, 0.2),
                rgba(255, 20, 0, 0.7) 95%,
                rgba(255, 20, 0, 0.1)
        );
    }

    .highlight-yellow {
        border-radius: 1em 0 1em 0;
        background-image: linear-gradient(
                -100deg,
                rgba(255, 224, 0, 0.2),
                rgba(255, 224, 0, 0.7) 95%,
                rgba(255, 224, 0, 0.1)
        );
    }

    .highlight-green {
        border-radius: 1em 0 1em 0;
        background-image: linear-gradient(
                -100deg,
                rgba(0, 255, 20, 0.2),
                rgba(0, 255, 20, 0.7) 95%,
                rgba(0, 255, 20, 0.1)
        );
    }

    .highlight-blue {
        border-radius: 1em 0 1em 0;
        background-image: linear-gradient(
                -100deg,
                rgba(0, 20, 255, 0.2),
                rgba(0, 20, 255, 0.7) 95%,
                rgba(0, 20, 255, 0.1)
        );
    }

    .border-highlight {
        border: 4px dashed red;
        padding: 0.01em 0.15em;
    }

    .circle-sketch-highlight {
        position: relative;
        left: 0.5em;
        font-family: Libre Franklin;
        font-size: 32px;
        font-weight: 500;
    }

    .circle-sketch-highlight:before {
        content: "";
        z-index: -1;
        left: -0.5em;
        top: -0.1em;
        border-width: 2px;
        border-style: solid;
        border-color: #ef8c22;
        position: absolute;
        border-right-color: transparent;
        width: 100%;
        height: 1em;
        transform: rotate(2deg);
        opacity: 0.7;
        border-radius: 50%;
        padding: 0.1em 0.25em;
    }

    .circle-sketch-highlight:after {
        content: "";
        z-index: -1;
        left: -0.5em;
        top: 0.1em;
        padding: 0.1em 0.25em;
        border-width: 2px;
        border-style: solid;
        border-color: #ef8c22;
        border-left-color: transparent;
        border-top-color: transparent;
        position: absolute;
        width: 100%;
        height: 1em;
        transform: rotate(-1deg);
        opacity: 0.7;
        border-radius: 50%;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
