<template>
    <div>
        <div class="reveal">

            <div class="text-center">
                <div style="height: 150px ;width: 250px;background-color: #ecced04d; border-radius: 8px; "
                     class="mx-15 center-horizontal pt-3 px-3 pb-3">
                    <img height="40px" src="../../../../../assets/regularClass.png" alt="regular class">

                    <div class="py-2"></div>
                    <div style="font-size: 13px; font-weight: bold">
                        정규수업
                    </div>
                    <div class="px-5 py-1 text-center" style="color: #909194; font-size: 13px;">
                        <!--                        “궁금하신 점 뭐든 물어보세요!”<br/>-->
                        학생의 요구에 따라 유동적인<br/>
                        정규수업 진행

                    </div>
                </div>

            </div>


        </div>
<!--        <div class="center-horizontal">-->
<!--            <div class="vertical-line ">-->
<!--            </div>-->
<!--            <div style="height: 5px"></div>-->
<!--        </div>-->
<!--        <div class="center-horizontal">-->
<!--            <div class="vertical-line ">-->
<!--            </div>-->
<!--            <div style="height: 5px"></div>-->
<!--        </div>-->
<!--        <div class="center-horizontal">-->
<!--            <div class="vertical-line ">-->
<!--            </div>-->
<!--            <div style="height: 5px"></div>-->
<!--        </div>-->
<!--        <div class="center-horizontal">-->
<!--            <div class="short-vertical-line ">-->
<!--            </div>-->
<!--        </div>-->

    </div>
</template>

<script>
    export default {
        name: "HowToUse6",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>


    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
    .border-highlight {
        border: 4px dashed red;
        padding: 0.01em 0.15em;
    }

</style>

