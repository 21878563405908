<template>
    <div>
            <div class="container reveal">

                <div class="text-container">
                    <div style="background-color: #ffffff; padding-top: 10px">
                        <div class="center-horizontal">
                            <div class="py-2"></div>

                            <div class="hidden-md-and-down">
                                <v-card style="background-color: #ffffff; border-radius: 15px; z-index: 0"
                                        class="text-center mx-9"
                                         elevation="0">
                                    <!--                background-color: #F6F6F6-->
                                    <div class="pa-2 text-center" style="">
                                        <img style="height: 500px; outline-style: solid; outline-width: thin; border-radius: 15px; outline-color: white"
                                             src="../../../../assets/ib_math.gif" alt="IVYED real-time class example 2" height="300px"/>
                                    </div>
                                </v-card>

                            </div>
                            <div class="hidden-lg-and-up">
                                <v-card style="background-color: #ffffff; border-radius: 15px; z-index: 0"
                                        class="text-center mx-9" elevation="0">
                                    <!--                background-color: #F6F6F6-->
                                    <div class=" text-center" style="overflow: hidden">
                                        <img style=" outline-style: solid; outline-width: thin; border-radius: 15px; outline-color: white"
                                             src="../../../../assets/ib_math.gif" alt="IVYED real-time class example 2" width="100%"/>
                                    </div>
                                </v-card>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

    </div>
</template>

<script>
    export default {
        name: "AnywhereAlways4",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>


    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
</style>

