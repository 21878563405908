<template>
    <div>
        <div class="reveal">
            <div class="pa-7" style="background-color: #ffffff; border-radius: 28px; min-width: 800px">

                <v-row>
                    <div style="height: 250px ; width: 250px;background-color: #ecced04d; border-radius: 8px; "
                         class=" center-horizontal pt-3 px-3 pb-3">
                        <img class="pl-2" height="50px" src="../../../../../assets/requestConsulting.png" alt="Request Consulting">
                        <div class="mt-1"></div>
                        <div class="py-4"></div>

                        <div style="font-size: 15px; font-weight: bold">
                            상담 신청
                        </div>
                        <div class="px-5 py-1 text-center" style="color: #909194; font-size: 14px">
                            <!--                        “궁금하신 점 뭐든 물어보세요!”<br/>-->
                            IVYED 하단 상담 신청 탭 또는<br/>
                            카카오톡 채널으로 연락


                        </div>

                    </div>
                    <span class="horizontal-line-two"></span>
                    <span class="px-1"></span>
                    <span class="horizontal-line-two"></span>
                    <span class="px-1"></span>
                    <span class="horizontal-line-two"></span>
                    <div style="height: 250px ; width: 250px;background-color: #ecced04d; border-radius: 8px; "
                         class=" center-horizontal pt-3 px-3 pb-3">
                        <div class="py-3"></div>
                        <img height="55px" src="../../../../../assets/trailClass.png" alt="IVYED Trial Class">
                        <div class="py-4 "></div>

                        <div style="font-size: 15px; font-weight: bold">
                            체험수업
                        </div>
                        <div class="px-5 py-1 text-center" style="font-size: 14px;color: #909194">
                            <!--                        “궁금하신 점 뭐든 물어보세요!”<br/>-->
                            상담내용을 바탕으로 <br/>선생님 매칭,<br/>
                            무료 체험 수업 제공

                        </div>

                    </div>
                    <span class="horizontal-line-two"></span>
                    <span class="px-1"></span>
                    <span class="horizontal-line-two"></span>
                    <span class="px-1"></span>
                    <span class="horizontal-line-two"></span>
                    <div style="height: 250px ;width: 250px;background-color: #ecced04d; border-radius: 8px; "
                         class=" center-horizontal pt-3 px-3 pb-3">
                        <img height="50px" src="../../../../../assets/creditCard.png" alt="Trial Class">
                        <div class="py-4 "></div>

                        <div style="font-size: 15px; font-weight: bold">
                            결제
                        </div>
                        <div class="px-5 py-1 text-center" style="color: #909194; font-size: 14px;">
                            <!--                        “궁금하신 점 뭐든 물어보세요!”<br/>-->
                            홈페이지 마이페이지를 통해<br/>
                            결제 진행

                        </div>
                    </div>
                    <span class="horizontal-line-two"></span>
                    <span class="px-1"></span>
                    <span class="horizontal-line-two"></span>
                    <span class="px-1"></span>
                    <span class="horizontal-line-two"></span>
                    <div style="height: 250px ;width: 250px;background-color: #ecced04d; border-radius: 8px; "
                         class=" center-horizontal pt-3 px-3 pb-3">
                        <img height="55px" src="../../../../../assets/regularClass.png" alt="Regular Class">

                        <div class="py-4"></div>
                        <div style="font-size: 15px; font-weight: bold">
                            정규수업
                        </div>
                        <div class="px-5 py-1 text-center" style="color: #909194; font-size: 14px;">
                            <!--                        “궁금하신 점 뭐든 물어보세요!”<br/>-->
                            학생의 요구에 따라 유동적인<br/>
                            정규수업 진행

                        </div>
                    </div>

                </v-row>
            </div>

        </div>
<!--        <div class="center-horizontal">-->
<!--            <div class="vertical-line ">-->
<!--            </div>-->
<!--            <div style="height: 5px"></div>-->
<!--        </div>-->
<!--        <div class="center-horizontal">-->
<!--            <div class="vertical-line ">-->
<!--            </div>-->
<!--            <div style="height: 5px"></div>-->
<!--        </div>-->
<!--        <div class="center-horizontal">-->
<!--            <div class="vertical-line ">-->
<!--            </div>-->
<!--            <div style="height: 5px"></div>-->
<!--        </div>-->
<!--        <div class="center-horizontal">-->
<!--            <div class="short-vertical-line ">-->
<!--            </div>-->
<!--        </div>-->

    </div>
</template>

<script>
    export default {
        name: "HowToUseDesktop",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>


    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
    .border-highlight {
        border: 4px dashed red;
        padding: 0.01em 0.15em;
    }
    .circle-with-checkmark::before {
        content: "\003F"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: #A20916; /* Checkmark color (white in this case) */
        font-weight: bold;
    }
    .circle-with-checkmark {
        position: relative;
        width: 40px;
        height: 40px;
        /*border: 2px solid #ecced04d; !* Circle border color *!*/
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced04d; /* Circle background color (white in this case) */
    }
    .circle-with-checkmark-desktop::before {
        content: "\003F"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: TheJamsil5Bold;
        font-size: 22px;
        color: #A20916; /* Checkmark color (white in this case) */
        font-weight: bold;
    }
    .circle-with-checkmark-desktop {
        position: relative;
        width: 60px;
        height: 60px;
        /*border: 2px solid #ecced04d; !* Circle border color *!*/
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced04d; /* Circle background color (white in this case) */
    }
    .horizontal-line-two {
        width: 7px; /* Adjust the width of the horizontal line */
        height: 2px; /* Adjust the height of the horizontal line */
        background-color: #faf0f1; /* Change the color of the horizontal line */
        margin-top: 125px;
    }
</style>

