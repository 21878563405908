<template>
    <div style="display: flex;flex-direction: column;align-items: center;padding: 20px 0px">
        <iframe src="https://v2-embednotion.com/Calendar-92368fff42454472b5587a65b81c1409?pvs=4" style="width: 100%; height: 100vh; border: 0; padding: 0"></iframe>


<!--        <iframe src="https://iframe.embednpages.com/Ac48lqOD9Dvuwm7eezuI" style="width: 100%; height: 100vh; border: 0; padding: 0"></iframe>-->
<!--        <h3 style="">수업 일정</h3>-->
<!--        <div class="curriculum_schedule">-->
<!--            <div class="curriculum_schedule_gird">-->
<!--                <div class="curriculum_schedule_gird_title">-->
<!--                    <span>수업</span>-->
<!--                    <span>요일</span>-->
<!--                    <span>횟수</span>-->
<!--                    <span>시간</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content">-->
<!--                    <span>AP Biology (평일 + 주말)</span>-->
<!--                    <span>수, 금, 토</span>-->
<!--                    <span>주 3회 / 150분</span>-->
<!--                    <span>(KST)<br/> 20:40 ~ 23:10</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content">-->
<!--                    <span>AP Biology (주말반)</span>-->
<!--                    <span>토, 일</span>-->
<!--                    <span>주 2회 / 120분</span>-->
<!--                    <span>(KST)<br/> 10:00 ~ 12:00<br/><br/>(PST)<br/>17:00 ~ 19:00</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content" style="background-color: rgba(247, 229, 247, 0.2)">-->
<!--                    <span>AP Chemistry (평일반)</span>-->
<!--                    <span>월, 화, 목</span>-->
<!--                    <span>주 3회 / 90분</span>-->
<!--                    <span>(KST)<br/> 19:00 ~ 20:30</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content" style="background-color: rgba(247, 229, 247, 0.2)">-->
<!--                    <span>AP Chemistry (주말반)</span>-->
<!--                    <span>토, 일</span>-->
<!--                    <span>주 2회 / 120분</span>-->
<!--                    <span>(KST)<br/> 8:30 ~ 10:30<br/><br/>(PST)<br/>15:30 ~ 17:30</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content">-->
<!--                    <span>AP CSA [개념정리반]</span>-->
<!--                    <span>월, 수, 금</span>-->
<!--                    <span>주 3회 / 90분</span>-->
<!--                    <span>(KST)<br/> 8:30 ~ 10:30</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content">-->
<!--                    <span>AP CSA [문제풀이반]</span>-->
<!--                    <span>월, 수, 금</span>-->
<!--                    <span>주 3회 / 90분</span>-->
<!--                    <span>(KST)<br/> 21:00 ~ 22:30</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content" style="background-color: #EBF4FA">-->
<!--                    <span>AP CSP[개념정리반]</span>-->
<!--                    <span>화, 목</span>-->
<!--                    <span>주 2회 / 90분</span>-->
<!--                    <span>(KST)<br/> 19:30 ~ 21:00</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content" style="background-color: #EBF4FA">-->
<!--                    <span>AP CSP[문제풀이반]</span>-->
<!--                    <span>화, 목</span>-->
<!--                    <span>주 2회 / 90분</span>-->
<!--                    <span>(KST)<br/> 21:00 ~ 22:30</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content">-->
<!--                    <span>Writing for Beginners (초급)</span>-->
<!--                    <span>토, 일</span>-->
<!--                    <span>주 2회 / 90분</span>-->
<!--                    <span>(KST)<br/> 11:00 ~ 12:30</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content">-->
<!--                    <span>Academic Writing I(중급)</span>-->
<!--                    <span>월, 목</span>-->
<!--                    <span>주 2회 / 90분</span>-->
<!--                    <span>(KST)<br/> 21:00 ~ 22:30</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content">-->
<!--                    <span>Academic Writing II(고급)</span>-->
<!--                    <span>화, 금</span>-->
<!--                    <span>주 2회 / 90분</span>-->
<!--                    <span>(KST)<br/> 22:00 ~ 23:30</span>-->
<!--                </div>-->
<!--                <div class="curriculum_schedule_gird_content" style="background-color: rgba(255, 245, 225,0.2)">-->
<!--                    <span>AP Physics C 개념마스터 (Only English)</span>-->
<!--                    <span>화, 금</span>-->
<!--                    <span>주 2회 / 90분</span>-->
<!--                    <span>-->
<!--                        PST (서부)<br/> 19:00 ~ 20:30<br/><br/>-->
<!--                        PST (동부)<br/> 22:00 ~ 23:30-->
<!--                    </span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

    </div>
</template>

<script>
    export default {
        name: 'BootcampSchedule',
        data: () => ({})
    };
</script>

<style lang="scss" scoped>
    $accent-color: #E8344E;
    $accent-background: #FFF6F8;
    .curriculum_schedule {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 20px;
    }

    .curriculum_schedule_gird {
        display: grid;
        grid-template-rows:1.2fr;
        grid-auto-rows: 2fr;
        width: inherit;
    }

    .curriculum_schedule_gird_title {
        min-height: 50px;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        color: white;
        background-color: $accent-color;
        margin-bottom: 10px;
        font-weight: bolder;
        font-size: smaller;

        span {
            place-self: center center;
            text-align: center;
        }

        span:first-child {
            padding-left: 15px;
        }
    }

    .curriculum_schedule_gird_content {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        background-color: $accent-background;
        margin-bottom: 10px;
        font-size: 12px;
        color: black;

        span {
            place-self: center center;
            text-align: center;
        }

        span:first-child {
            font-weight: bolder;
            padding-left: 15px;
        }
    }

    @media (min-width: 800px) {
        .curriculum_schedule{
            width: 80%;
        }
    }
</style>
