<template>
<img src="../../assets/카톡.png">
</template>

<script>
    export default {
        name: "KakaoIcon"
    }
</script>

<style scoped>

</style>
