<template>
    <div>
        <div class=" reveal">
            <div class="center-horizontal hidden-lg-and-up py-4" style="background-color: transparent">
                <div class="pt-7"></div>
                <div class="circle-with-checkmark-four center-vertical text-center">
                    <v-icon color="primary">mdi-check</v-icon>
                </div>
                <div class="py-2"></div>

                <div class="pa-4 text-center text-gradient-3" style="font-size: 28px; font-weight: bold">
                    무분별한 유학생 <br/> 선생님이 아니에요!
                </div>
                <div class="text-center">
                    <div class="px-4 font-weight-bold " style="font-size: 19px;  font-weight: normal">
                        IVYED의 검증을 통해 <div style="" class="font-weight-bold">잘 가르치는 선생님만 연결해드려요.</div>
                    </div>
                </div>
            </div>

        </div>
        </div>
</template>

<script>
    export default {
        name: "AboutIvyedTitleMobile",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>

    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
    .circle-with-checkmark-four {
        position: relative;
        width: 40px;
        height: 40px;
        border: 2px solid #ecced0; /* Circle border color */
        border-radius: 50%; /* Make it a circle */
        background-color: #ecced0; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark-four::before {
        /*content: "\2714"; !* Checkmark unicode character *!*/
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /*font-size: 34px;*/
        /*color: #A20916 !important; !* Checkmark color (white in this case) *!*/
    }

</style>

