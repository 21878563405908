<template>

    <div>
        <v-app-bar style="position: fixed; background-color: #1B2025; z-index: 2; overflow:auto;" elevation="0">
            <v-btn @click="scrollto('what-is-bootcamp-section', {behavior: 'smooth'})" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">부트캠프</div>
            </v-btn>
            <v-btn @click="scrollto('review-section')" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">후기</div>
            </v-btn>
            <v-btn @click="scrollto('program-section')" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">프로그램</div>
            </v-btn>
            <v-btn @click="scrollto('curriculum-section')" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">커리큘럼</div>
            </v-btn>
            <!--            <v-btn @click="scrollto('smart-matching-section')" elevation="0" x-small text><div style="letter-spacing: -0.5px; font-size: 15px; color: white">매칭</div></v-btn>-->
            <v-btn @click="scrollto('sign-up-section')" elevation="0" x-small text>
                <div style="letter-spacing: -0.5px; font-size: 15px; color: white">신청</div>
            </v-btn>
        </v-app-bar>

        <div class="bootcamp-first text-center">
            <div style="height: 100px"></div>
            <div class="py-8"></div>
            <div style="font-size: 22px; color: white;" class="px-10 pt-10 pb-3">
                <br/>
                AP Computer Science A <br/> 완벽대비 <br/><span class="font-weight-bold">Bootcamp</span>
            </div>
            <div class="py-1"></div>
            <div style="color: white; font-size: 15px" class="px-10 pb-10 hidden-md-and-up">
                <div class="font-weight-bold">국내 최초 아이비리그</div>
                미국 Big Tech 출신 선생님이 <br/> <span class="font-weight-medium">직접 밀착 지도하는 부트캠프</span><br/>
            </div>
            <div style="color: white; font-size: 15px" class="px-10 pb-10 hidden-sm-and-down">
                <span class="font-weight-bold"> 국내 최초 아이비리그 </span> 컴퓨터 공학 학/석사 및 미국 Big Tech 출신 선생님이 직접 밀착 지도하는 <br/>
                <span class="">AP Computer Science A</span> 부트캠프로
                성적과 입시를 한번에 잡으세요.
            </div>
            <div class="py-5"></div>
            <div class="px-5 mx-5 py-3" style="background-color: rgb(255,255,255, 0.3); border-radius: 12px">
                <v-row style="color: white; font-weight: bold; font-size: 18px">
                    <v-col>개념정리반</v-col>
                    <v-col>문제풀이반</v-col>
                </v-row>
                <v-row style="color: white;  font-size: 16px">
                    <v-col>학교수업 이해가 어려워 따라가기 힘들었던 학생들을 위한 개념정리반!</v-col>
                    <v-col>개념정리보다는 실전 문제 위주로 실력을 향상 시키고 싶은 학생들을 위한 문제풀이반!</v-col>
                </v-row>
            </div>
            <div class="py-5"></div>
            <div>
                <v-btn to="/bootcamp/register" elevation="0" color="primary">지원하기</v-btn>
            </div>
            <!--            <div class="pt-6 center-horizontal" style="padding-bottom: 22px">-->
            <!--                &lt;!&ndash;                <v-btn color="primary" to="/bootcamp/register" x-large>참가 신청하기 (모집 마감)</v-btn>&ndash;&gt;-->
            <!--                <v-card flat max-width="50%" class="pa-8 font-weight-bold"-->
            <!--                        style="background-color: #A30717; color: white;">-->
            <!--                    2023년 2월에 시작하는 부트캠프는 모집이 모두 마감 되었습니다.-->
            <!--                    <div>-->
            <!--                        관련 문의는 IVYED로 연락 부탁 드립니다.<span style="font-size: 20px"> 😊</span>-->
            <!--                    </div>-->
            <!--                </v-card>-->
            <!--            </div>-->
            <div class="" style="padding-bottom: 100px">
                <!--                <v-btn to="/bootcamp/apcsa/curriculum" color="white" x-large-->
                <!--                       style="letter-spacing: 0.2px; font-weight: bold; border: 1px solid #1F383A " elevation="0"> 커리큘럼 자세히 보기 >-->
                <!--                </v-btn>-->
            </div>
            <div class="pb-7">
                <v-icon color="grey">mdi-chevron-down</v-icon>
            </div>

            <div style="background-color: #111212; color: #FC434B" class="px-10 pt-10 pb-10 text-left"
                 id="what-is-bootcamp-section">
                <div class="py-5"></div>
                <div class="hidden-md-and-up">
                    <div class="secondary--text font-weight-bold" style="font-size: 14px">
                        AP CSA 부트캠프란?
                    </div>
                    <div class="py-1"></div>
                    <div class="white--text text-h6 font-weight-bold" style="">
                        정예 참가자로만 구성
                    </div>
                    <div class="py-1"></div>
                    <div class="white--text" style="font-size: 13px">
                        아직도 대규모 특강에 현혹되고 계신가요? <br/> 아이비에드 AP CSA 부트캠프에서는 시험기간까지 컴퓨터공학 대학과정 기초부터 프로그래밍 과제 실습까지 탄탄하게 잡아주는
                        최고의 쌍방향
                        소통
                        부트캠프입니다.
                        <div style="padding-bottom: 8px"></div>
                        단순한 지식 전달에 그치지 않습니다. 계속해서 질문하고 성장할 수 있게 실시간으로 교육합니다.
                        <div style="padding-bottom: 8px"></div>
                        부트캠프 진행기간 동안 학교 과제까지 직접 도움받으며 실력을 쌓을 수 있습니다.
                        <div style="padding-bottom: 8px"></div>
                        최소 4주간 수업과 실습으로 자신만의 실력을 갈고 닦으며 경험이 풍부한 선생님들께 도움을 받아 학생의 꿈을 보다 더 넓은 미래로 이끌어 보세요.
                    </div>
                    <div class="py-3"></div>
                    <div class="white--text text-h6 font-weight-bold" style="">
                        대상
                    </div>
                    <div>
                        <div>
                            <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>
                            <span class="white--text" style="font-size: 13px"> 겨울방학에 미리 AP CSA를 끝내고 싶으신 분</span>
                        </div>
                        <div>
                            <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>
                            <span class="white--text" style="font-size: 13px"> Java로 프로그래밍을 할 수 있게 되고 싶으신 분</span>
                        </div>
                        <div>
                            <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>
                            <span class="white--text"
                                  style="font-size: 13px">AP CSA 수업을 듣지 않고도 시험을 보고싶으신 분</span>
                        </div>
                        <div>
                            <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>
                            <span class="white--text"
                                  style="font-size: 13px"> 프로그래밍 실력을 향상하고 싶은 분</span>
                        </div>
                    </div>
                </div>

                <div class="center-horizontal ">
                    <div class="hidden-sm-and-down px-10" style="max-width: 800px">
                        <div class="secondary--text font-weight-bold" style="font-size: 14px">
                            AP CSA 부트캠프란?
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text text-h6 font-weight-bold" style="">
                            정예 참가자로만 구성
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text" style="font-size: 13px">
                            아직도 대규모 특강에 현혹되고 계신가요? <br/> 아이비에드 AP CSA 부트캠프에서는 시험기간까지 컴퓨터공학 대학과정 기초부터 프로그래밍 과제 실습까지 탄탄하게
                            잡아주는 최고의 쌍방향
                            소통
                            부트캠프입니다.
                            <div style="padding-bottom: 8px"></div>
                            단순한 지식 전달에 그치지 않습니다. 계속해서 질문하고 성장할 수 있게 실시간으로 교육합니다.
                            <div style="padding-bottom: 8px"></div>
                            부트캠프 진행기간 동안 학교 과제까지 직접 도움받으며 실력을 쌓을 수 있습니다.
                            <div style="padding-bottom: 8px"></div>
                            최소 4주간 수업과 실습으로 자신만의 실력을 갈고 닦으며 경험이 풍부한 선생님들께 도움을 받아 학생의 꿈을 보다 더 넓은 미래로 이끌어 보세요.
                        </div>
                        <div class="py-3"></div>
                        <div class="white--text text-h6 font-weight-bold" style="">
                            대상
                        </div>
                        <div>
                            <div>
                                <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>
                                <span class="white--text" style="font-size: 13px"> 겨울방학에 미리 AP CSA를 끝내고 싶으신 분</span>
                            </div>
                            <div>
                                <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>
                                <span class="white--text"
                                      style="font-size: 13px">Java를 제대로 배워 혼자 프로그래밍을 할 수 있게 되고 싶으신 분</span>
                            </div>
                            <div>
                                <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>
                                <span class="white--text"
                                      style="font-size: 13px">굳이 AP CSA 수업을 듣지 않고도 시험을 보고싶으신 분</span>
                            </div>
                            <div>
                                <v-icon color="secondary" size="19px">mdi-check-bold</v-icon>
                                <span class="white--text"
                                      style="font-size: 13px"> 프로그래밍 실력을 향상하고 싶은 분</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-8"></div>
            </div>

            <!-- video -->
            <div style="max-height: 700px; background-color: #111212">
                <h1 style="color: #fff; ">강의 미리보기</h1>
                <div class="py-5"></div>
                <iframe class="hidden-sm-and-up" width="100%" height="315"
                        src="https://www.youtube.com/embed/_PqE5TJbtmo?si=Wt2a-t1VPUxIrNEd" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                <iframe class="hidden-xs-only" width="50%" height="315"
                        src="https://www.youtube.com/embed/_PqE5TJbtmo?si=Wt2a-t1VPUxIrNEd" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                <div class="py-5"></div>
            </div>

            <div style="background-color: #1D2022; color: #FC434B; " class="px-10 pt-10 pb-10 text-left">
                <div class="py-8"></div>
                <div class="hidden-md-and-up">
                    <div class="secondary--text font-weight-bold" style="font-size: 14px">
                        추천사
                    </div>
                    <div class="py-1"></div>
                    <div class="white--text text-h6 font-weight-bold" style="">
                        학생들은 말합니다.<br/>
                        검증된 선생님들의 수업은 다르다고.
                    </div>
                    <div class="py-1"></div>
                    <div class="white--text" style="font-size: 13px">
                        아이비리그와 미국 최상위권 명문대 선생님들에게 멘토링받으며 과제를 진행하세요.
                    </div>
                    <div class="py-3"></div>
                    <div class="text-center">
                        <v-card class="rounded-lg" style="background-color: white">
                            <div class="py-2"></div>
                            <v-list-item-avatar
                                    tile
                                    size="80"
                                    color="grey"
                            >
                                <img src="../../assets/studentAvatarGirl.png">
                            </v-list-item-avatar>
                            <div style="font-size: 16px; font-weight: normal" class="pa-5 font-weight-bold">
                                혼자서 시작조차 못했던 AP Computer Science A 공부를 아이비에드를 통해 정말 쉽게 시작했어요.
                            </div>
                            <div class="py-1"></div>
                            <div style="color: gray; font-size: 15px">
                                미국 보딩스쿨 재학중
                            </div>
                            <div class="py-3"></div>
                        </v-card>
                    </div>
                </div>
                <div class="center-horizontal">
                    <div class="hidden-sm-and-down " style="max-width: 800px">
                        <div class="secondary--text font-weight-bold" style="font-size: 14px">
                            추천사
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text text-h6 font-weight-bold" style="">
                            학생들은 말합니다.<br/>
                            검증된 선생님들의 수업은 다르다고.
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text" style="font-size: 13px">
                            아이비리그와 미국 최상위권 명문대 선생님들에게 멘토링받으며 과제를 진행하세요.
                        </div>
                        <div class="py-3"></div>
                        <div class="text-center">
                            <v-card class="rounded-lg" style="background-color: white">
                                <div class="py-2"></div>
                                <v-list-item-avatar
                                        tile
                                        size="80"
                                        color="grey"
                                >
                                    <img src="../../assets/studentAvatarGirl.png">
                                </v-list-item-avatar>
                                <div style="font-size: 16px; font-weight: normal" class="pa-5 font-weight-bold">
                                    혼자서 시작조차 못했던 AP Computer Science A 공부를 아이비에드를 통해 정말 쉽게 시작했어요.
                                </div>
                                <div class="py-1"></div>
                                <div style="color: gray; font-size: 15px">
                                    미국 보딩스쿨 재학중
                                </div>
                                <div class="py-3"></div>
                            </v-card>
                        </div>
                    </div>
                </div>
                <div class="py-8"></div>

            </div>
            <div style="background-color: #26090A; color: #130909 " class="px-10 pt-10 pb-10 text-left">
                <div class="py-8"></div>

                <div class="hidden-md-and-up">
                    <div class="secondary--text font-weight-bold" style="font-size: 14px">
                        차별점
                    </div>
                    <div class="py-1"></div>
                    <div class="white--text text-h6 font-weight-bold" style="">
                        아이비에드 부트캠프는 이렇게 다릅니다.
                    </div>
                    <div class="" style="font-size: 13px; color: #ffcccb">
                        <div style="padding-bottom: 8px"></div>
                        단기 속성반으로 보장할 수 없는 점수를 약속하지 않습니다. 실시간 수업과 과제 도우미 및 시험문제 풀이로 AP CSA 대비뿐 아닌 Java의 이해도도 함께 키워갑니다.
                        <div style="padding-bottom: 8px"></div>

                    </div>
                    <div class="py-3"></div>
                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold" style="font-size: 16px">
                            1. 온라인 스터디 진행 (매주 과제 및 시험)
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            책과 강의는 있는데 혼자서 공부하기 막막하신 분들에게 추천합니다. 매주 공부의 루틴을 자동적으로 잡아주는 과제와 시험이 있으며 스스로 공부한다면 놓치기 쉬운 부분과
                            복습
                            점검까지 철저하게 지도하고 있습니다.
                        </div>
                    </div>
                    <div class="py-2"></div>
                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold" style="font-size: 16px">
                            2. 아이비리그 출신 선생님과 함께 하는 질의응답 기회
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            Computer science 는 혼자서 공부한다면 검색하고 찾아봐야할 시간이 사소한 것이라도 몇시간씩 그냥 지나가는 반면, 처음부터 체계적인 전문 선생님께 지도를
                            받으면
                            구글링하며 헤매는 시간을 100 시간에서 단 몇시간으로 절약할 수 있는 과목입니다. 최고의 선생님들께 도움을 받으며 시간을 확실하게 챙기세요.
                        </div>
                    </div>
                    <div class="py-2"></div>
                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold" style="font-size: 16px">
                            3. 기간 제한 없는 학습 지원 프로그램
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            아이비에드는 학생들이 시험을 볼때까지 함께합니다. 학습이 끝난 후에도 시험 기간 동안 무제한 복습 과제와 강의를 제공해드리고 있으며. 코스가 체계적으로 구성이 되어
                            있기
                            때문에 한번 결제하면 추가적인 금액없이 이용하실 수 있습니다. 또한 다년간 AP computer science 대비를 위한 모든것이 들어 있는 교재와 PDF 자료를
                            제공해드리고 있습니다.
                        </div>
                    </div>
                    <div class="py-2"></div>
                    <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                         class="pa-3">
                        <div class=" font-weight-bold" style="font-size: 16px">
                            4. 부트캠프 참여자 한정 수업 녹화본 인강 무기한 제공
                        </div>
                        <div class="py-2"></div>
                        <div style="font-size: 14px">
                            부트캠프 진행기간 및 끝난 후에도 매수업 이후 당일날 수업 녹화본 (인강)이 참여 학생들 한정으로 무기한 제공되어 언제든 헷갈렸던 부분을 복습할 수 있도록 환경을
                            만들었습니다.
                        </div>
                    </div>

                </div>
                <div class="center-horizontal">
                    <div class="hidden-sm-and-down" style="max-width: 720px">
                        <div class="secondary--text font-weight-bold" style="font-size: 14px">
                            차별점
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text text-h6 font-weight-bold" style="">
                            아이비에드 부트캠프는 이렇게 다릅니다.
                        </div>
                        <div class="" style="font-size: 13px; color: #ffcccb">
                            <div style="padding-bottom: 8px"></div>
                            단기 속성반으로 보장할 수 없는 점수를 약속하지 않습니다. 실시간 수업과 과제 도우미 및 시험문제 풀이로 AP CSA 대비뿐 아닌 Java의 이해도도 함께
                            키워갑니다.
                            <div style="padding-bottom: 8px"></div>

                        </div>
                        <div class="py-3"></div>
                        <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                             class="pa-3">
                            <div class=" font-weight-bold" style="font-size: 16px">
                                1. 온라인 스터디 진행 (매주 과제 및 시험)
                            </div>
                            <div class="py-2"></div>
                            <div style="font-size: 14px">
                                책과 강의는 있는데 혼자서 공부하기 막막하신 분들에게 추천합니다. 매주 공부의 루틴을 자동적으로 잡아주는 과제와 시험이 있으며 스스로 공부한다면 놓치기 쉬운
                                부분과
                                복습
                                점검까지 철저하게 지도하고 있습니다.
                            </div>
                        </div>
                        <div class="py-2"></div>
                        <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                             class="pa-3">
                            <div class=" font-weight-bold" style="font-size: 16px">
                                2. 아이비리그 출신 선생님과 함께 하는 질의응답 기회
                            </div>
                            <div class="py-2"></div>
                            <div style="font-size: 14px">
                                Computer science 는 혼자서 공부한다면 검색하고 찾아봐야할 시간이 사소한 것이라도 몇시간씩 그냥 지나가는 반면, 처음부터 체계적인 전문 선생님께
                                지도를
                                받으면
                                구글링하며 헤매는 시간을 100 시간에서 단 몇시간으로 절약할 수 있는 과목입니다. 최고의 선생님들께 도움을 받으며 시간을 확실하게 챙기세요.
                            </div>
                        </div>
                        <div class="py-2"></div>
                        <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                             class="pa-3">
                            <div class=" font-weight-bold" style="font-size: 16px">
                                3. 기간 제한 없는 학습 지원 프로그램
                            </div>
                            <div class="py-2"></div>
                            <div style="font-size: 14px">
                                아이비에드는 학생들이 시험을 볼때까지 함께합니다. 학습이 끝난 후에도 시험 기간 동안 무제한 복습 과제와 강의를 제공해드리고 있으며. 코스가 체계적으로 구성이
                                되어
                                있기
                                때문에 한번 결제하면 추가적인 금액없이 이용하실 수 있습니다. 또한 다년간 AP computer science 대비를 위한 모든것이 들어 있는 교재와 PDF
                                자료를
                                제공해드리고 있습니다.
                            </div>
                        </div>
                        <div class="py-2"></div>
                        <div style="border-color: white !important; border-style: solid; border-width: thin; border-radius: 7px; color: white"
                             class="pa-3">
                            <div class=" font-weight-bold" style="font-size: 16px">
                                4. 부트캠프 참여자 한정 수업 녹화본 인강 무기한 제공
                            </div>
                            <div class="py-2"></div>
                            <div style="font-size: 14px">
                                부트캠프 진행기간 및 끝난 후에도 매수업 이후 당일날 수업 녹화본 (인강)이 참여 학생들 한정으로 무기한 제공되어 언제든 헷갈렸던 부분을 복습할 수 있도록
                                환경을
                                만들었습니다.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="py-8"></div>

            </div>
            <div class="pa-10 pt-15 text-center" style="background-color: #111212" id="review-section">
                <div class="py-5"></div>
                <div class="white--text" style="font-size: 20px; font-weight: bold">
                    AP CS A & Principles, <br/>
                    숫자로 증명했습니다.
                </div>
                <div class="py-2"></div>
                <div class="white--text" style="font-size: 14px">
                    매 기수 95% 이상 AP 5점 뿐 아니라,<br/>
                    실제 Java로 프로그래밍을 <br/>할 수 있는 능력을 키워가고 있습니다.
                </div>
                <div class="py-5">
                    <div style="color: #EF0006; font-size: 50px; " class="font-weight-medium"> 95.8%</div>
                    <div class="white--text font-weight-bold" style="font-size: 15px">2개월 내 5점 취득</div>
                    <!--                    <div style="color: lightgray; font-size: 11px"> 3개월 내 취업률 81.4%</div>-->
                </div>
                <div class="py-5">
                    <div style="color: #EF0006; font-size: 50px; " class="font-weight-medium"> 94.9%</div>
                    <div class="white--text font-weight-bold" style="font-size: 15px">Java 언어 프로그래밍 능력</div>
                    <!--                    <div style="color: lightgray; font-size: 11px">수료 후 1년 기준</div>-->
                </div>
                <div class="py-5">
                    <div style="color: #EF0006; font-size: 50px; " class="font-weight-medium"> 98%</div>
                    <div class="white--text font-weight-bold" style="font-size: 15px">수업 만족도</div>
                    <!--                    <div style="color: lightgray; font-size: 11px">아이비에드 수료 2년차 기준</div>-->
                </div>
                <div class="py-5">
                    <div style="color: #EF0006; font-size: 50px; " class="font-weight-medium"> 97.4%</div>
                    <div class="white--text font-weight-bold" style="font-size: 15px">재수강률</div>
                    <!--                    <div style="color: lightgray; font-size: 11px">~10기 기준</div>-->
                </div>
                <div class="py-7"></div>
                <!--                <div style="color: lightgray; font-size: 11px">~10기 기준</div>-->

            </div>

            <div style="background-color: #F4F8F9; color: #FC434B " class="px-10 pt-10 pb-10 text-left">
                <div class="py-8"></div>
                <div class="hidden-md-and-up">
                    <div class="secondary--text font-weight-bold" style="font-size: 14px">
                        수업 후기
                    </div>
                    <div class="py-1"></div>
                    <div class="black--text text-h6 font-weight-bold" style="">
                        성적만 높은게 아닙니다. <br/>
                        입시 경쟁력 및 프로그래밍 실력도 길러줍니다.
                    </div>
                    <div class="py-3"></div>
                    <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                        "솔직히 주변에서 유학 준비를 걱정하는 시선이 많았는데 과외받으면서 좋은 성적도 받고 코딩에 흥미를 느껴 전공에 확신이 생겼어요"
                        <br/>
                        <br/>
                        <div class="text-center">
                            <img height="100px" src="../../assets/avatar2.png">
                        </div>
                        <div style="color: #2c3e50; font-size: 12px; font-weight: bold" class="text-center">
                            국내 국제학교 11학년
                        </div>

                    </v-card>
                    <div class="py-3"></div>
                    <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                        "프로그래밍을 하면서 스스로 문제해결능력을 기르게 되어 자연스럽게 포트폴리오를 만들고 그게 제 입시 준비의 초석이 되었습니다"
                        <br/>
                        <br/>
                        <div class="text-center">
                            <img height="100px" src="../../assets/avatar3.png">
                            <div style="color: #2c3e50; font-size: 12px; font-weight: bold">
                                미국 동부 보딩스쿨 11학년
                            </div>
                        </div>
                    </v-card>
                    <div class="py-3"></div>
                    <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                        "AP CSA 에서 5점을 받아 그 성적으로 이번 겨울 방학 리서치 인턴에 합격했습니다!"
                        <br/>
                        <br/>
                        <div class="text-center">
                            <img height="100px" src="../../assets/avatar4.png">
                        </div>
                        <div style="color: #2c3e50; font-size: 12px; font-weight: bold" class="text-center">
                            중국 국제학교 10학년
                        </div>
                    </v-card>
                </div>
                <div class="center-horizontal">
                    <div class="hidden-sm-and-down" style="max-width: 730px">
                        <div class="secondary--text font-weight-bold" style="font-size: 14px">
                            수업 후기
                        </div>
                        <div class="py-1"></div>
                        <div class="black--text text-h6 font-weight-bold" style="">
                            성적만 높은게 아닙니다. <br/>
                            입시 경쟁력 및 프로그래밍 실력도 길러줍니다.
                        </div>
                        <div class="py-3"></div>
                        <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                            "솔직히 주변에서 유학 준비를 걱정하는 시선이 많았는데 과외받으면서 좋은 성적도 받고 코딩에 흥미를 느껴 전공에 확신이 생겼어요"
                            <br/>
                            <br/>
                            <div class="text-center">
                                <img height="100px" src="../../assets/avatar2.png">
                            </div>
                            <div style="color: #2c3e50; font-size: 12px; font-weight: bold" class="text-center">
                                국내 국제학교 11학년
                            </div>

                        </v-card>
                        <div class="py-3"></div>
                        <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                            "프로그래밍을 하면서 스스로 문제해결능력을 기르게 되어 자연스럽게 포트폴리오를 만들고 그게 제 입시 준비의 초석이 되었습니다"
                            <br/>
                            <br/>
                            <div class="text-center">
                                <img height="100px" src="../../assets/avatar3.png">
                                <div style="color: #2c3e50; font-size: 12px; font-weight: bold">
                                    미국 동부 보딩스쿨 11학년
                                </div>
                            </div>
                        </v-card>
                        <div class="py-3"></div>
                        <v-card elevation="6" style="font-size: 14px" class="pa-4 rounded-lg">
                            "AP CSA 에서 5점을 받아 그 성적으로 이번 겨울 방학 리서치 인턴에 합격했습니다!"
                            <br/>
                            <br/>
                            <div class="text-center">
                                <img height="100px" src="../../assets/avatar4.png">
                            </div>
                            <div style="color: #2c3e50; font-size: 12px; font-weight: bold" class="text-center">
                                중국 국제학교 10학년
                            </div>
                        </v-card>
                    </div>
                </div>
                <div class="py-10"></div>
            </div>
            <div style="background-color: #111212; color: #FC434B " class="px-10 pt-10 pb-10 text-left"
                 id="program-section">
                <div class="py-10"></div>

                <div class="hidden-md-and-up">
                    <div class="secondary--text font-weight-bold" style="font-size: 14px">
                        교육 방식
                    </div>
                    <div class="py-1"></div>
                    <div class="white--text text-h6 font-weight-bold" style="">
                        아무런 준비 없이 와도 괜찮습니다.
                    </div>
                    <div class="py-1"></div>
                    <div class="white--text" style="font-size: 13px">
                        프로그래밍 경험과 상관없이 치열한 의지만 있다면, <br/>
                        누구나 AP CSA 만점과 Java에 능숙해 질 수 있는 시스템.
                    </div>
                    <div class="py-3"></div>
                    <v-card class="rounded-lg">
                        <div class="py-4 px-4"
                             style="color: #FC434B; background-color: #1C1E1F; font-size: 12px; font-weight: bold">
                            Point 1
                        </div>
                        <div class="px-4" style="color: white; background-color: #1C1E1F; font-weight: bold">
                            누구나 시작할 수 있습니다.
                            사전 지식이 없어도 괜찮습니다.
                        </div>
                        <div class="py-1" style="background-color: #1C1E1F"></div>
                        <div class="px-4" style="color: white; background-color: #1C1E1F; font-size: 13px">
                            • 합류 즉시, 사전스터디 자료를 제공<br/>
                            • 아이비리그 선생님과 주 단위 기술 멘토링
                        </div>
                        <div class="py-2" style="background-color: #1C1E1F"></div>
                    </v-card>
                    <div class="py-2"></div>
                    <v-card class="rounded-lg">
                        <div class="py-4 px-4"
                             style="color: #FC434B; background-color: #1C1E1F; font-size: 12px; font-weight: bold">
                            Point 2
                        </div>
                        <div class="px-4" style="color: white; background-color: #1C1E1F; font-weight: bold">
                            자기주도적으로 학습하되 <br/>
                            올바른 방향으로 성장할 수 있도록.
                        </div>
                        <div class="py-1" style="background-color: #1C1E1F"></div>
                        <div class="px-4" style="color: white; background-color: #1C1E1F; font-size: 13px">
                            • 방향성 제공과 밀착 상담으로 올바른 성장 지원<br/>
                            • 담임 선생님과 언제든 상담 가능
                        </div>
                        <div class="py-2" style="background-color: #1C1E1F"></div>
                    </v-card>
                    <div class="py-2"></div>
                    <v-card class="rounded-lg">
                        <div class="py-4 px-4"
                             style="color: #FC434B; background-color: #1C1E1F; font-size: 12px; font-weight: bold">
                            Point 3
                        </div>
                        <div class="px-4" style="color: white; background-color: #1C1E1F; font-weight: bold">
                            최적의 속도로 달릴 수 있게 <br/>
                            수준별 학습 로드맵을 제공합니다.
                        </div>
                        <div class="py-1" style="background-color: #1C1E1F"></div>
                        <div class="px-4" style="color: white; background-color: #1C1E1F; font-size: 13px">
                            • 4주반 분반 제도<br/>
                            • 수준에 따른 맞춤 학습과 지원 제공
                        </div>
                        <div class="py-2" style="background-color: #1C1E1F"></div>
                    </v-card>
                </div>
                <div class="center-horizontal">
                    <div class="hidden-sm-and-down" style="width: 750px; max-width: 750px">
                        <div class="secondary--text font-weight-bold" style="font-size: 14px">
                            교육 방식
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text text-h6 font-weight-bold" style="">
                            아무런 준비 없이 와도 괜찮습니다.
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text" style="font-size: 13px">
                            프로그래밍 경험과 상관없이 치열한 의지만 있다면, <br/>
                            누구나 AP CSA 만점과 Java에 능숙해 질 수 있는 시스템.
                        </div>
                        <div class="py-3"></div>
                        <v-card class="rounded-lg">
                            <div class="py-4 px-4"
                                 style="color: #FC434B; background-color: #1C1E1F; font-size: 12px; font-weight: bold">
                                Point 1
                            </div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-weight: bold">
                                누구나 시작할 수 있습니다.
                                사전 지식이 없어도 괜찮습니다.
                            </div>
                            <div class="py-1" style="background-color: #1C1E1F"></div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-size: 13px">
                                • 합류 즉시, 사전스터디 자료를 제공<br/>
                                • 아이비리그 선생님과 주 단위 기술 멘토링
                            </div>
                            <div class="py-2" style="background-color: #1C1E1F"></div>
                        </v-card>
                        <div class="py-2"></div>
                        <v-card class="rounded-lg">
                            <div class="py-4 px-4"
                                 style="color: #FC434B; background-color: #1C1E1F; font-size: 12px; font-weight: bold">
                                Point 2
                            </div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-weight: bold">
                                자기주도적으로 학습하되 <br/>
                                올바른 방향으로 성장할 수 있도록.
                            </div>
                            <div class="py-1" style="background-color: #1C1E1F"></div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-size: 13px">
                                • 방향성 제공과 밀착 상담으로 올바른 성장 지원<br/>
                                • 담임 선생님과 언제든 상담 가능
                            </div>
                            <div class="py-2" style="background-color: #1C1E1F"></div>
                        </v-card>
                        <div class="py-2"></div>
                        <v-card class="rounded-lg">
                            <div class="py-4 px-4"
                                 style="color: #FC434B; background-color: #1C1E1F; font-size: 12px; font-weight: bold">
                                Point 3
                            </div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-weight: bold">
                                최적의 속도로 달릴 수 있게 <br/>
                                수준별 학습 로드맵을 제공합니다.
                            </div>
                            <div class="py-1" style="background-color: #1C1E1F"></div>
                            <div class="px-4" style="color: white; background-color: #1C1E1F; font-size: 13px">
                                • 4주반 분반 제도<br/>
                                • 수준에 따른 맞춤 학습과 지원 제공
                            </div>
                            <div class="py-2" style="background-color: #1C1E1F"></div>
                        </v-card>
                    </div>
                </div>
            </div>
            <div class="pa-10" style="background-color: white">
                <div class="py-8"></div>
                <div style="color: #FC434B" class="font-weight-bold">멘토진</div>
                <div style="font-size: 20px; font-weight: bold">최고의 멘토들과 함께합니다.</div>
                <div class="py-4"></div>
                <SchoolCarousel></SchoolCarousel>
                <div class="py-8"></div>
                <!--                <div class="hidden-md-and-up">-->
                <!--                    <img src="../../assets/mentoring.jpeg" style="max-height: 100%; width: 100%">-->
                <!--                </div>-->
                <!--                <div class="hidden-sm-and-down">-->
                <!--                    <img src="../../assets/mentoring.jpeg" style="max-height: 50%; width: 50%">-->
                <!--                </div>-->

                <div class="hidden-md-and-up">
                    <img src="../../assets/mentors.png" style="max-height: 100%; width: 100%">
                </div>
                <div class="hidden-sm-and-down">
                    <img src="../../assets/mentors.png" style="max-height: 50%; width: 50%">
                </div>
                <div style="color: #6E767C; font-size: 13px">
                    아이비리그 컴퓨터 공학 학/석사 및 <br/> 실리콘밸리에서 실제 개발자 경험이 있는 <br/>강사진들이 함께하고 있습니다.
                </div>
                <div class="py-8"></div>
            </div>
            <div style="background-color: #EAF0F3" id="curriculum-section">
                <div class="py-10"></div>
                <div style="font-size: 20px; font-weight: bold">상세 커리큘럼</div>
                <div class="py-2"></div>
                <div style="color: #6E767C; font-size: 15px">
                    아이비리그 Computer Science 전공 <br/> 재학생 선배들이 인정한 커리큘럼
                </div>
                <div class="py-2"></div>
                <div>
                    <span>
                        <v-icon color="red" size="18">mdi-check</v-icon><span
                            style="font-size: 12px">  4주 과정</span> &nbsp;
                        <v-icon color="red" size="18">mdi-check</v-icon><span style="font-size: 12px"> 언제든 참여 가능</span>&nbsp;
                        <v-icon color="red" size="18">mdi-check</v-icon><span style="font-size: 12px"> 주말 / 주중반</span>
                    </span>
                    <div class="hidden-md-and-up">
                        <div class="py-4"></div>
                        <v-tabs
                                class=""
                                align-with-title

                        >
                            <v-tabs-slider color=""></v-tabs-slider>

                            <!--                                <v-tab class="font-weight-bold ">-->
                            <!--                                    4주 과정-->
                            <!--                                </v-tab>-->
                            <!--                                <v-tab class="font-weight-bold ">-->
                            <!--                                    6주 과정-->
                            <!--                                </v-tab>-->
                            <v-tab class="font-weight-bold ">
                                <!--                                    8주 과정-->
                                커리큘럼
                            </v-tab>
                            <div class="py-3"></div>
                            <!--                                <v-tab-item>-->
                            <!--                                    <v-expansion-panels class=" " flat>-->
                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header class="text-left">-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">1.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • AP CSA 는 어떤 과목이고 어떤 시험인가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Java 는 어떤 프로그래밍 언어인가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Java를 실행 할 수 있는 환경 구축하기-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 주석 (Comments)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 데이터 타입 (Data types in Java)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 연산자 (Operators in Java)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 입출력 (Input / Output)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 조건문 (Control Structures)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Errors and Exceptions in Java-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 1차원 배열 (One-dimensional Array)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Array vs. ArrayList in Java-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 2차원 배열 (Two-dimensional Array)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->
                            <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2.</span><span&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->

                            <!--                                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header>-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">2.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Classes and Objects</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Object(객체)란 무엇이고 어떻게 사용 되는가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Object vs. Primitive data types-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Wrapper Class 에 대한 이해-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Java 에서 제공하는 다양한 클래스들 알아보기-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Objects vs. Classes-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Java에서의 함수 (Methods) 작성법 및 이해-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Method Overloading-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Scope 개념 및 이해-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Reference vs. Primitive Data Types-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->
                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header>-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">3.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • AP CSA 의 필수문제 Object Oriented Program Design-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Software Development-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Program Analysis-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Class Inheritance (상속) 개념 및 이해-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Super Class vs. Sub Class-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Polymorphism-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Dynamic Binding-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Type Compatibility-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Google 과 Youtube 를 상속개념으로 구현 해보기-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->

                            <!--                                        &lt;!&ndash;                                    <v-expansion-panel>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        <v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            <span style="font-size: 11px; font-weight: bold">4.</span><span&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                                style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        </v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            • 1차원 배열 (One-dimensional Array)&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            • Array vs. ArrayList in Java&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            • 2차원 배열 (Two-dimensional Array)&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        </v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    </v-expansion-panel>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    <v-expansion-panel>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        <v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            <span style="font-size: 11px; font-weight: bold">5.</span><span&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                                style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        </v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            • AP CSA 의 필수문제 Object Oriented Program Design&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            • Software Development&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            • Program Analysis&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                            • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        </v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    </v-expansion-panel>&ndash;&gt;-->

                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header>-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">4.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Algorithms</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • Recursive Methods-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Recursive 함수의 기본 형태-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • How to write recursive methods-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Recursion 을 사용하는 이유-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Recursion vs. Iteration-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • What are Algorithms?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Sorting-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Searching-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->

                            <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8.</span><span&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                            <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">9.</span><span&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                            <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">10.</span><span&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->


                            <!--                                    </v-expansion-panels>-->

                            <!--                                </v-tab-item>-->
                            <!--                                <v-tab-item>-->
                            <!--                                    <v-expansion-panels class="pa-3 " flat>-->
                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header class="text-left">-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">1.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • AP CSA 는 어떤 과목이고 어떤 시험인가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Java 는 어떤 프로그래밍 언어인가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Java를 실행 할 수 있는 환경 구축하기-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 주석 (Comments)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 데이터 타입 (Data types in Java)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 연산자 (Operators in Java)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 입출력 (Input / Output)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 조건문 (Control Structures)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Errors and Exceptions in Java-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->
                            <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2.</span><span&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->

                            <!--                                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header>-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">2.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Classes and Objects</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Object(객체)란 무엇이고 어떻게 사용 되는가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Object vs. Primitive data types-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Wrapper Class 에 대한 이해-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Java 에서 제공하는 다양한 클래스들 알아보기-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Objects vs. Classes-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Java에서의 함수 (Methods) 작성법 및 이해-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Method Overloading-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Scope 개념 및 이해-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Reference vs. Primitive Data Types-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->
                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header>-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">3.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • Class Inheritance (상속) 개념 및 이해-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Super Class vs. Sub Class-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Polymorphism-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Dynamic Binding-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Type Compatibility-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Google 과 Youtube 를 상속개념으로 구현 해보기-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->


                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header>-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">4.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 1차원 배열 (One-dimensional Array)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Array vs. ArrayList in Java-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 2차원 배열 (Two-dimensional Array)-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->
                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header>-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">5.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • AP CSA 의 필수문제 Object Oriented Program Design-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Software Development-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Program Analysis-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->
                            <!--                                        <v-expansion-panel>-->
                            <!--                                            <v-expansion-panel-header>-->
                            <!--                                                <span style="font-size: 11px; font-weight: bold">6.</span><span-->
                            <!--                                                    style="font-size: 13px; font-weight: bold">Algorithms</span>-->
                            <!--                                            </v-expansion-panel-header>-->
                            <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                                • Recursive Methods-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Recursive 함수의 기본 형태-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • How to write recursive methods-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Recursion 을 사용하는 이유-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Recursion vs. Iteration-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • What are Algorithms?-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Sorting-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • Searching-->
                            <!--                                                <div style="padding-bottom: 8px"></div>-->
                            <!--                                                • 숙제 (Reading & MCP & Programming)-->
                            <!--                                            </v-expansion-panel-content>-->
                            <!--                                        </v-expansion-panel>-->
                            <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8.</span><span&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
                            <v-tab-item>
                                <v-expansion-panels class="pa-3 " flat>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="text-left">
                                            <!--                                                <span style="font-size: 11px; font-weight: bold">1.</span>-->
                                            <span
                                                    style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                            • AP CSA 는 어떤 과목이고 어떤 시험인가?
                                            <div style="padding-bottom: 8px"></div>
                                            • Java 는 어떤 프로그래밍 언어인가?
                                            <div style="padding-bottom: 8px"></div>
                                            • Java를 실행 할 수 있는 환경 구축하기
                                            <div style="padding-bottom: 8px"></div>
                                            • 주석 (Comments)
                                            <div style="padding-bottom: 8px"></div>
                                            • 데이터 타입 (Data types in Java)
                                            <div style="padding-bottom: 8px"></div>
                                            • 연산자 (Operators in Java)
                                            <div style="padding-bottom: 8px"></div>
                                            • 입출력 (Input / Output)
                                            <div style="padding-bottom: 8px"></div>
                                            • 조건문 (Control Structures)
                                            <div style="padding-bottom: 8px"></div>
                                            • Errors and Exceptions in Java
                                            <div style="padding-bottom: 8px"></div>
                                            • 숙제 (Reading & MCP & Programming)
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <!--                                                <span style="font-size: 11px; font-weight: bold">2.</span>-->
                                            <span
                                                    style="font-size: 13px; font-weight: bold">Java의 Classes</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                            • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?
                                            <div style="padding-bottom: 8px"></div>
                                            • Object(객체)란 무엇이고 어떻게 사용 되는가?
                                            <div style="padding-bottom: 8px"></div>
                                            • Object vs. Primitive data types
                                            <div style="padding-bottom: 8px"></div>
                                            • Wrapper Class 에 대한 이해
                                            <div style="padding-bottom: 8px"></div>
                                            • Java 에서 제공하는 다양한 클래스들 알아보기
                                            <div style="padding-bottom: 8px"></div>
                                            • 숙제 (Reading & MCP & Programming)
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <!--                                                <span style="font-size: 11px; font-weight: bold">3.</span>-->
                                            <span
                                                    style="font-size: 13px; font-weight: bold">Classes and Objects</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">

                                            • Objects vs. Classes
                                            <div style="padding-bottom: 8px"></div>
                                            • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?
                                            <div style="padding-bottom: 8px"></div>
                                            • Java에서의 함수 (Methods) 작성법 및 이해
                                            <div style="padding-bottom: 8px"></div>
                                            • Method Overloading
                                            <div style="padding-bottom: 8px"></div>
                                            • Scope 개념 및 이해
                                            <div style="padding-bottom: 8px"></div>
                                            • Reference vs. Primitive Data Types
                                            <div style="padding-bottom: 8px"></div>
                                            • 숙제 (Reading & MCP & Programming)
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <!--                                                <span style="font-size: 11px; font-weight: bold">4.</span>-->
                                            <span
                                                    style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                            • Class Inheritance (상속) 개념 및 이해
                                            <div style="padding-bottom: 8px"></div>
                                            • Super Class vs. Sub Class
                                            <div style="padding-bottom: 8px"></div>
                                            • Polymorphism
                                            <div style="padding-bottom: 8px"></div>
                                            • Dynamic Binding
                                            <div style="padding-bottom: 8px"></div>
                                            • Type Compatibility
                                            <div style="padding-bottom: 8px"></div>
                                            • Google 과 Youtube 를 상속개념으로 구현 해보기
                                            <div style="padding-bottom: 8px"></div>
                                            • 숙제 (Reading & MCP & Programming)
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <!--                                                <span style="font-size: 11px; font-weight: bold">5.</span>-->
                                            <span
                                                    style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                            • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?
                                            <div style="padding-bottom: 8px"></div>
                                            • 1차원 배열 (One-dimensional Array)
                                            <div style="padding-bottom: 8px"></div>
                                            • Array vs. ArrayList in Java
                                            <div style="padding-bottom: 8px"></div>
                                            • 2차원 배열 (Two-dimensional Array)
                                            <div style="padding-bottom: 8px"></div>
                                            • 숙제 (Reading & MCP & Programming)
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <!--                                                <span style="font-size: 11px; font-weight: bold">6.</span>-->
                                            <span
                                                    style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                            • AP CSA 의 필수문제 Object Oriented Program Design
                                            <div style="padding-bottom: 8px"></div>
                                            • Software Development
                                            <div style="padding-bottom: 8px"></div>
                                            • Program Analysis
                                            <div style="padding-bottom: 8px"></div>
                                            • 숙제 (Reading & MCP & Programming)
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <!--                                                <span style="font-size: 11px; font-weight: bold">7.</span>-->
                                            <span
                                                    style="font-size: 13px; font-weight: bold">Recursion</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                            • Recursive Methods
                                            <div style="padding-bottom: 8px"></div>
                                            • Recursive 함수의 기본 형태
                                            <div style="padding-bottom: 8px"></div>
                                            • How to write recursive methods
                                            <div style="padding-bottom: 8px"></div>
                                            • Recursion 을 사용하는 이유
                                            <div style="padding-bottom: 8px"></div>
                                            • Recursion vs. Iteration
                                            <div style="padding-bottom: 8px"></div>
                                            • 숙제 (Reading & MCP & Programming)
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            <!--                                                <span style="font-size: 11px; font-weight: bold">8.</span>-->
                                            <span
                                                    style="font-size: 13px; font-weight: bold">Sorting and Searching</span>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                            • What are Algorithms?
                                            <div style="padding-bottom: 8px"></div>
                                            • Sorting
                                            <div style="padding-bottom: 8px"></div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;• Selection Sorts
                                            <div style="padding-bottom: 8px"></div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;• Insertion Sorts
                                            <div style="padding-bottom: 8px"></div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;• Merge Sorts
                                            <div style="padding-bottom: 8px"></div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;• Quick Sorts

                                            <div style="padding-bottom: 8px"></div>
                                            • Searching
                                            <div style="padding-bottom: 8px"></div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;• Linear Search
                                            <div style="padding-bottom: 8px"></div>
                                            &nbsp;&nbsp;&nbsp;&nbsp;• Binary Search

                                            <div style="padding-bottom: 8px"></div>
                                            • 숙제 (Reading & MCP & Programming)
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <!--                            <v-expansion-panel>-->
                                    <!--                                <v-expansion-panel-header>-->
                                    <!--                                    <span style="font-size: 11px; font-weight: bold">8.</span><span-->
                                    <!--                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>-->
                                    <!--                                </v-expansion-panel-header>-->
                                    <!--                                <v-expansion-panel-content>-->
                                    <!--                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor-->
                                    <!--                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud-->
                                    <!--                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.-->
                                    <!--                                </v-expansion-panel-content>-->
                                    <!--                            </v-expansion-panel>-->

                                    <!--                            <v-expansion-panel>-->
                                    <!--                                <v-expansion-panel-header>-->
                                    <!--                                    <span style="font-size: 11px; font-weight: bold">9.</span><span-->
                                    <!--                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>-->
                                    <!--                                </v-expansion-panel-header>-->
                                    <!--                                <v-expansion-panel-content>-->
                                    <!--                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor-->
                                    <!--                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud-->
                                    <!--                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.-->
                                    <!--                                </v-expansion-panel-content>-->
                                    <!--                            </v-expansion-panel>-->

                                    <!--                            <v-expansion-panel>-->
                                    <!--                                <v-expansion-panel-header>-->
                                    <!--                                    <span style="font-size: 11px; font-weight: bold">10.</span><span-->
                                    <!--                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>-->
                                    <!--                                </v-expansion-panel-header>-->
                                    <!--                                <v-expansion-panel-content>-->
                                    <!--                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor-->
                                    <!--                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud-->
                                    <!--                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.-->
                                    <!--                                </v-expansion-panel-content>-->
                                    <!--                            </v-expansion-panel>-->


                                </v-expansion-panels>

                            </v-tab-item>


                        </v-tabs>

                        <!--                        <v-expansion-panels class="pa-3 " flat>-->
                        <!--                            <v-expansion-panel>-->
                        <!--                                <v-expansion-panel-header class="text-left">-->
                        <!--                                    <span style="font-size: 11px; font-weight: bold">1.</span><span-->
                        <!--                                        style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>-->
                        <!--                                </v-expansion-panel-header>-->
                        <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                        <!--                                    • AP CSA 는 어떤 과목이고 어떤 시험인가?-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Java 는 어떤 프로그래밍 언어인가?-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Java를 실행 할 수 있는 환경 구축하기-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 주석 (Comments)-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 데이터 타입 (Data types in Java)-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 연산자 (Operators in Java)-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 입출력 (Input / Output)-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 조건문 (Control Structures)-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Errors and Exceptions in Java-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 숙제 (Reading & MCP & Programming)-->
                        <!--                                </v-expansion-panel-content>-->
                        <!--                            </v-expansion-panel>-->
                        <!--&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2.</span><span&ndash;&gt;-->
                        <!--&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->

                        <!--&lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                        <!--&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
                        <!--                            <v-expansion-panel>-->
                        <!--                                <v-expansion-panel-header>-->
                        <!--                                    <span style="font-size: 11px; font-weight: bold">2.</span><span-->
                        <!--                                        style="font-size: 13px; font-weight: bold">Classes and Objects</span>-->
                        <!--                                </v-expansion-panel-header>-->
                        <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                        <!--                                    • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Object(객체)란 무엇이고 어떻게 사용 되는가?-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Object vs. Primitive data types-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Wrapper Class 에 대한 이해-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Java 에서 제공하는 다양한 클래스들 알아보기-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Objects vs. Classes-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Java에서의 함수 (Methods) 작성법 및 이해-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Method Overloading-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Scope 개념 및 이해-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Reference vs. Primitive Data Types-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 숙제 (Reading & MCP & Programming)-->
                        <!--                                </v-expansion-panel-content>-->
                        <!--                            </v-expansion-panel>-->
                        <!--                            <v-expansion-panel>-->
                        <!--                                <v-expansion-panel-header>-->
                        <!--                                    <span style="font-size: 11px; font-weight: bold">3.</span><span-->
                        <!--                                        style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>-->
                        <!--                                </v-expansion-panel-header>-->
                        <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                        <!--                                    • Class Inheritance (상속) 개념 및 이해-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Super Class vs. Sub Class-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Polymorphism-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Dynamic Binding-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Type Compatibility-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Google 과 Youtube 를 상속개념으로 구현 해보기-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 숙제 (Reading & MCP & Programming)-->
                        <!--                                </v-expansion-panel-content>-->
                        <!--                            </v-expansion-panel>-->

                        <!--                            <v-expansion-panel>-->
                        <!--                                <v-expansion-panel-header>-->
                        <!--                                    <span style="font-size: 11px; font-weight: bold">4.</span><span-->
                        <!--                                        style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>-->
                        <!--                                </v-expansion-panel-header>-->
                        <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                        <!--                                    • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 1차원 배열 (One-dimensional Array)-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Array vs. ArrayList in Java-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 2차원 배열 (Two-dimensional Array)-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 숙제 (Reading & MCP & Programming)-->
                        <!--                                </v-expansion-panel-content>-->
                        <!--                            </v-expansion-panel>-->
                        <!--                            <v-expansion-panel>-->
                        <!--                                <v-expansion-panel-header>-->
                        <!--                                    <span style="font-size: 11px; font-weight: bold">5.</span><span-->
                        <!--                                        style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>-->
                        <!--                                </v-expansion-panel-header>-->
                        <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                        <!--                                    • AP CSA 의 필수문제 Object Oriented Program Design-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Software Development-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Program Analysis-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 숙제 (Reading & MCP & Programming)-->
                        <!--                                </v-expansion-panel-content>-->
                        <!--                            </v-expansion-panel>-->

                        <!--                            <v-expansion-panel>-->
                        <!--                                <v-expansion-panel-header>-->
                        <!--                                    <span style="font-size: 11px; font-weight: bold">6.</span><span-->
                        <!--                                        style="font-size: 13px; font-weight: bold">Algorithms</span>-->
                        <!--                                </v-expansion-panel-header>-->
                        <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                        <!--                                    • Recursive Methods-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Recursive 함수의 기본 형태-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • How to write recursive methods-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Recursion 을 사용하는 이유-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Recursion vs. Iteration-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • What are Algorithms?-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • Sorting-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--&lt;!&ndash;                                    &nbsp;&nbsp;&nbsp;&nbsp; • Sorting&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                        <!--                                    • Searching-->
                        <!--                                    <div style="padding-bottom: 8px"></div>-->
                        <!--                                    • 숙제 (Reading & MCP & Programming)-->
                        <!--                                </v-expansion-panel-content>-->
                        <!--                            </v-expansion-panel>-->

                        <!--&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8.</span><span&ndash;&gt;-->
                        <!--&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                        <!--&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                        <!--&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">9.</span><span&ndash;&gt;-->
                        <!--&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                        <!--&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                        <!--&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">10.</span><span&ndash;&gt;-->
                        <!--&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                        <!--&lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                        <!--&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                        <!--&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->


                        <!--                        </v-expansion-panels>-->
                        <div class="pt-10"></div>
                        <!--                        <div class="py-4">-->
                        <!--                            <v-btn to="/bootcamp/apcsa/curriculum" color="white" x-large width="80%"-->
                        <!--                                   style="letter-spacing: 0.2px; font-weight: bold; border: 1px solid #1F383A "-->
                        <!--                                   elevation="0"> 커리큘럼 자세히 보기 >-->
                        <!--                            </v-btn>-->
                        <!--                        </div>-->
                        <div class="py-10"></div>
                        <div>


                            <div style="font-size: 20px; font-weight: bold " class="text-left pl-5">
                                수업이 끝나면 <br/>
                                과제와 퀴즈 걱정도 끝납니다.
                            </div>

                            <div class="py-1"></div>
                            <div style="color: #6E767C; font-size: 14px" class="text-left pl-5">
                                개념과 시험대비만 하는 것이 아닌, <br/> 학교 AP CSA 숙제 및 퀴즈 대비도 <br/> 강사진이 함께 도와주고 있습니다.
                            </div>
                            <div class="py-3"></div>
                            <!--                            <div class="pa-2 ma-2"-->
                            <!--                                 style="height: 200px; border-radius: 10px; border-style: solid; border-width: thin">-->
                            <img style="border-radius: 10px;  max-height: 100%; width: 90%"
                                 src="../../assets/수업이끝나면1.png">
                            <div class="py-10"></div>
                            <img style="border-radius: 10px; max-height: 100%; width: 90%"
                                 src="../../assets/수업이끝나면3.gif">
                            <div class="py-10"></div>
                            <img style="border-radius: 10px; max-height: 100%; width: 90%"
                                 src="../../assets/수업이끝나면2.png">
                            <!--                            </div>-->
                        </div>
                    </div>
                    <div class="center-horizontal">
                        <div class="hidden-sm-and-down" style="max-width: 720px ">
                            <div class="py-4"></div>
                            <v-tabs
                                    class=""
                                    align-with-title

                            >
                                <v-tabs-slider color=""></v-tabs-slider>

                                <!--                                <v-tab class="font-weight-bold ">-->
                                <!--                                    4주 과정-->
                                <!--                                </v-tab>-->
                                <!--                                <v-tab class="font-weight-bold ">-->
                                <!--                                    6주 과정-->
                                <!--                                </v-tab>-->
                                <v-tab class="font-weight-bold ">
                                    <!--                                    8주 과정-->
                                    커리큘럼
                                </v-tab>
                                <div class="py-3"></div>
                                <!--                                <v-tab-item>-->
                                <!--                                    <v-expansion-panels class=" " flat>-->
                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header class="text-left">-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">1.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • AP CSA 는 어떤 과목이고 어떤 시험인가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Java 는 어떤 프로그래밍 언어인가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Java를 실행 할 수 있는 환경 구축하기-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 주석 (Comments)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 데이터 타입 (Data types in Java)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 연산자 (Operators in Java)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 입출력 (Input / Output)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 조건문 (Control Structures)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Errors and Exceptions in Java-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 1차원 배열 (One-dimensional Array)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Array vs. ArrayList in Java-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 2차원 배열 (Two-dimensional Array)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->
                                <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->

                                <!--                                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header>-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">2.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Classes and Objects</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Object(객체)란 무엇이고 어떻게 사용 되는가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Object vs. Primitive data types-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Wrapper Class 에 대한 이해-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Java 에서 제공하는 다양한 클래스들 알아보기-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Objects vs. Classes-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Java에서의 함수 (Methods) 작성법 및 이해-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Method Overloading-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Scope 개념 및 이해-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Reference vs. Primitive Data Types-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->
                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header>-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">3.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • AP CSA 의 필수문제 Object Oriented Program Design-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Software Development-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Program Analysis-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Class Inheritance (상속) 개념 및 이해-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Super Class vs. Sub Class-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Polymorphism-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Dynamic Binding-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Type Compatibility-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Google 과 Youtube 를 상속개념으로 구현 해보기-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->

                                <!--                                        &lt;!&ndash;                                    <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            <span style="font-size: 11px; font-weight: bold">4.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                                style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            • 1차원 배열 (One-dimensional Array)&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            • Array vs. ArrayList in Java&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            • 2차원 배열 (Two-dimensional Array)&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    </v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            <span style="font-size: 11px; font-weight: bold">5.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                                style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            • AP CSA 의 필수문제 Object Oriented Program Design&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            • Software Development&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            • Program Analysis&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                            • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    </v-expansion-panel>&ndash;&gt;-->

                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header>-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">4.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Algorithms</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • Recursive Methods-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Recursive 함수의 기본 형태-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • How to write recursive methods-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Recursion 을 사용하는 이유-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Recursion vs. Iteration-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • What are Algorithms?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Sorting-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Searching-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->

                                <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                                <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">9.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                                <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">10.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->


                                <!--                                    </v-expansion-panels>-->

                                <!--                                </v-tab-item>-->
                                <!--                                <v-tab-item>-->
                                <!--                                    <v-expansion-panels class="pa-3 " flat>-->
                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header class="text-left">-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">1.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • AP CSA 는 어떤 과목이고 어떤 시험인가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Java 는 어떤 프로그래밍 언어인가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Java를 실행 할 수 있는 환경 구축하기-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 주석 (Comments)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 데이터 타입 (Data types in Java)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 연산자 (Operators in Java)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 입출력 (Input / Output)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 조건문 (Control Structures)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Errors and Exceptions in Java-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->
                                <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->

                                <!--                                        &lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header>-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">2.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Classes and Objects</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Object(객체)란 무엇이고 어떻게 사용 되는가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Object vs. Primitive data types-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Wrapper Class 에 대한 이해-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Java 에서 제공하는 다양한 클래스들 알아보기-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Objects vs. Classes-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Java에서의 함수 (Methods) 작성법 및 이해-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Method Overloading-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Scope 개념 및 이해-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Reference vs. Primitive Data Types-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->
                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header>-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">3.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • Class Inheritance (상속) 개념 및 이해-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Super Class vs. Sub Class-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Polymorphism-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Dynamic Binding-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Type Compatibility-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Google 과 Youtube 를 상속개념으로 구현 해보기-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->

                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header>-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">4.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 1차원 배열 (One-dimensional Array)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Array vs. ArrayList in Java-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 2차원 배열 (Two-dimensional Array)-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->
                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header>-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">5.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • AP CSA 의 필수문제 Object Oriented Program Design-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Software Development-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Program Analysis-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->

                                <!--                                        <v-expansion-panel>-->
                                <!--                                            <v-expansion-panel-header>-->
                                <!--                                                <span style="font-size: 11px; font-weight: bold">6.</span><span-->
                                <!--                                                    style="font-size: 13px; font-weight: bold">Algorithms</span>-->
                                <!--                                            </v-expansion-panel-header>-->
                                <!--                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                                <!--                                                • Recursive Methods-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Recursive 함수의 기본 형태-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • How to write recursive methods-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Recursion 을 사용하는 이유-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Recursion vs. Iteration-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • What are Algorithms?-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Sorting-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • Searching-->
                                <!--                                                <div style="padding-bottom: 8px"></div>-->
                                <!--                                                • 숙제 (Reading & MCP & Programming)-->
                                <!--                                            </v-expansion-panel-content>-->
                                <!--                                        </v-expansion-panel>-->

                                <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                                <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">9.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                                <!--                                        &lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">10.</span><span&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                                <!--                                        &lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->


                                <!--                                    </v-expansion-panels>-->

                                <!--                                </v-tab-item>-->
                                <v-tab-item>
                                    <v-expansion-panels class="pa-3 " flat>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="text-left">
                                                <!--                                                <span style="font-size: 11px; font-weight: bold">1.</span>-->
                                                <span
                                                        style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                                • AP CSA 는 어떤 과목이고 어떤 시험인가?
                                                <div style="padding-bottom: 8px"></div>
                                                • Java 는 어떤 프로그래밍 언어인가?
                                                <div style="padding-bottom: 8px"></div>
                                                • Java를 실행 할 수 있는 환경 구축하기
                                                <div style="padding-bottom: 8px"></div>
                                                • 주석 (Comments)
                                                <div style="padding-bottom: 8px"></div>
                                                • 데이터 타입 (Data types in Java)
                                                <div style="padding-bottom: 8px"></div>
                                                • 연산자 (Operators in Java)
                                                <div style="padding-bottom: 8px"></div>
                                                • 입출력 (Input / Output)
                                                <div style="padding-bottom: 8px"></div>
                                                • 조건문 (Control Structures)
                                                <div style="padding-bottom: 8px"></div>
                                                • Errors and Exceptions in Java
                                                <div style="padding-bottom: 8px"></div>
                                                • 숙제 (Reading & MCP & Programming)
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <!--                                                <span style="font-size: 11px; font-weight: bold">2.</span>-->
                                                <span
                                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                                • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?
                                                <div style="padding-bottom: 8px"></div>
                                                • Object(객체)란 무엇이고 어떻게 사용 되는가?
                                                <div style="padding-bottom: 8px"></div>
                                                • Object vs. Primitive data types
                                                <div style="padding-bottom: 8px"></div>
                                                • Wrapper Class 에 대한 이해
                                                <div style="padding-bottom: 8px"></div>
                                                • Java 에서 제공하는 다양한 클래스들 알아보기
                                                <div style="padding-bottom: 8px"></div>
                                                • 숙제 (Reading & MCP & Programming)
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <!--                                                <span style="font-size: 11px; font-weight: bold">3.</span>-->
                                                <span
                                                        style="font-size: 13px; font-weight: bold">Classes and Objects</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">

                                                • Objects vs. Classes
                                                <div style="padding-bottom: 8px"></div>
                                                • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?
                                                <div style="padding-bottom: 8px"></div>
                                                • Java에서의 함수 (Methods) 작성법 및 이해
                                                <div style="padding-bottom: 8px"></div>
                                                • Method Overloading
                                                <div style="padding-bottom: 8px"></div>
                                                • Scope 개념 및 이해
                                                <div style="padding-bottom: 8px"></div>
                                                • Reference vs. Primitive Data Types
                                                <div style="padding-bottom: 8px"></div>
                                                • 숙제 (Reading & MCP & Programming)
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <!--                                                <span style="font-size: 11px; font-weight: bold">4.</span>-->
                                                <span
                                                        style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                                • Class Inheritance (상속) 개념 및 이해
                                                <div style="padding-bottom: 8px"></div>
                                                • Super Class vs. Sub Class
                                                <div style="padding-bottom: 8px"></div>
                                                • Polymorphism
                                                <div style="padding-bottom: 8px"></div>
                                                • Dynamic Binding
                                                <div style="padding-bottom: 8px"></div>
                                                • Type Compatibility
                                                <div style="padding-bottom: 8px"></div>
                                                • Google 과 Youtube 를 상속개념으로 구현 해보기
                                                <div style="padding-bottom: 8px"></div>
                                                • 숙제 (Reading & MCP & Programming)
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <!--                                                <span style="font-size: 11px; font-weight: bold">5.</span>-->
                                                <span
                                                        style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                                • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?
                                                <div style="padding-bottom: 8px"></div>
                                                • 1차원 배열 (One-dimensional Array)
                                                <div style="padding-bottom: 8px"></div>
                                                • Array vs. ArrayList in Java
                                                <div style="padding-bottom: 8px"></div>
                                                • 2차원 배열 (Two-dimensional Array)
                                                <div style="padding-bottom: 8px"></div>
                                                • 숙제 (Reading & MCP & Programming)
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <!--                                                <span style="font-size: 11px; font-weight: bold">6.</span>-->
                                                <span
                                                        style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                                • AP CSA 의 필수문제 Object Oriented Program Design
                                                <div style="padding-bottom: 8px"></div>
                                                • Software Development
                                                <div style="padding-bottom: 8px"></div>
                                                • Program Analysis
                                                <div style="padding-bottom: 8px"></div>
                                                • 숙제 (Reading & MCP & Programming)
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <!--                                                <span style="font-size: 11px; font-weight: bold">7.</span>-->
                                                <span
                                                        style="font-size: 13px; font-weight: bold">Recursion</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                                • Recursive Methods
                                                <div style="padding-bottom: 8px"></div>
                                                • Recursive 함수의 기본 형태
                                                <div style="padding-bottom: 8px"></div>
                                                • How to write recursive methods
                                                <div style="padding-bottom: 8px"></div>
                                                • Recursion 을 사용하는 이유
                                                <div style="padding-bottom: 8px"></div>
                                                • Recursion vs. Iteration
                                                <div style="padding-bottom: 8px"></div>
                                                • 숙제 (Reading & MCP & Programming)
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <!--                                                <span style="font-size: 11px; font-weight: bold">8.</span>-->
                                                <span
                                                        style="font-size: 13px; font-weight: bold">Sorting and Searching</span>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content style="font-size: 13px" class="text-left">
                                                • What are Algorithms?
                                                <div style="padding-bottom: 8px"></div>
                                                • Sorting
                                                <div style="padding-bottom: 8px"></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;• Selection Sorts
                                                <div style="padding-bottom: 8px"></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;• Insertion Sorts
                                                <div style="padding-bottom: 8px"></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;• Merge Sorts
                                                <div style="padding-bottom: 8px"></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;• Quick Sorts

                                                <div style="padding-bottom: 8px"></div>
                                                • Searching
                                                <div style="padding-bottom: 8px"></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;• Linear Search
                                                <div style="padding-bottom: 8px"></div>
                                                &nbsp;&nbsp;&nbsp;&nbsp;• Binary Search

                                                <div style="padding-bottom: 8px"></div>
                                                • 숙제 (Reading & MCP & Programming)
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <!--                            <v-expansion-panel>-->
                                        <!--                                <v-expansion-panel-header>-->
                                        <!--                                    <span style="font-size: 11px; font-weight: bold">8.</span><span-->
                                        <!--                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>-->
                                        <!--                                </v-expansion-panel-header>-->
                                        <!--                                <v-expansion-panel-content>-->
                                        <!--                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor-->
                                        <!--                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud-->
                                        <!--                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.-->
                                        <!--                                </v-expansion-panel-content>-->
                                        <!--                            </v-expansion-panel>-->

                                        <!--                            <v-expansion-panel>-->
                                        <!--                                <v-expansion-panel-header>-->
                                        <!--                                    <span style="font-size: 11px; font-weight: bold">9.</span><span-->
                                        <!--                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>-->
                                        <!--                                </v-expansion-panel-header>-->
                                        <!--                                <v-expansion-panel-content>-->
                                        <!--                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor-->
                                        <!--                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud-->
                                        <!--                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.-->
                                        <!--                                </v-expansion-panel-content>-->
                                        <!--                            </v-expansion-panel>-->

                                        <!--                            <v-expansion-panel>-->
                                        <!--                                <v-expansion-panel-header>-->
                                        <!--                                    <span style="font-size: 11px; font-weight: bold">10.</span><span-->
                                        <!--                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>-->
                                        <!--                                </v-expansion-panel-header>-->
                                        <!--                                <v-expansion-panel-content>-->
                                        <!--                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor-->
                                        <!--                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud-->
                                        <!--                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.-->
                                        <!--                                </v-expansion-panel-content>-->
                                        <!--                            </v-expansion-panel>-->


                                    </v-expansion-panels>

                                </v-tab-item>


                            </v-tabs>
                            <!--                        <v-expansion-panels class="pa-3 " flat>-->
                            <!--                            <v-expansion-panel>-->
                            <!--                                <v-expansion-panel-header class="text-left">-->
                            <!--                                    <span style="font-size: 11px; font-weight: bold">1.</span><span-->
                            <!--                                        style="font-size: 13px; font-weight: bold">Intro to AP CSA and Java</span>-->
                            <!--                                </v-expansion-panel-header>-->
                            <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                    • AP CSA 는 어떤 과목이고 어떤 시험인가?-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Java 는 어떤 프로그래밍 언어인가?-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Java를 실행 할 수 있는 환경 구축하기-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 주석 (Comments)-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 데이터 타입 (Data types in Java)-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 연산자 (Operators in Java)-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 입출력 (Input / Output)-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 조건문 (Control Structures)-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Errors and Exceptions in Java-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 숙제 (Reading & MCP & Programming)-->
                            <!--                                </v-expansion-panel-content>-->
                            <!--                            </v-expansion-panel>-->
                            <!--&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">2.</span><span&ndash;&gt;-->
                            <!--&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Java의 Classes</span>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <v-expansion-panel-content style="font-size: 13px" class="text-left">&ndash;&gt;-->

                            <!--&lt;!&ndash;                                    • 숙제 (Reading & MCP & Programming)&ndash;&gt;-->
                            <!--&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->
                            <!--                            <v-expansion-panel>-->
                            <!--                                <v-expansion-panel-header>-->
                            <!--                                    <span style="font-size: 11px; font-weight: bold">2.</span><span-->
                            <!--                                        style="font-size: 13px; font-weight: bold">Classes and Objects</span>-->
                            <!--                                </v-expansion-panel-header>-->
                            <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                    • 객체지향 프로그래밍 (Object-oriented Programming) 에서 Class가 무엇인가?-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Object(객체)란 무엇이고 어떻게 사용 되는가?-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Object vs. Primitive data types-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Wrapper Class 에 대한 이해-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Java 에서 제공하는 다양한 클래스들 알아보기-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Objects vs. Classes-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 접근제한자 (Access Modifiers) 는 무엇이고 어떻게 사용하는가?-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Java에서의 함수 (Methods) 작성법 및 이해-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Method Overloading-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Scope 개념 및 이해-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Reference vs. Primitive Data Types-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 숙제 (Reading & MCP & Programming)-->
                            <!--                                </v-expansion-panel-content>-->
                            <!--                            </v-expansion-panel>-->
                            <!--                            <v-expansion-panel>-->
                            <!--                                <v-expansion-panel-header>-->
                            <!--                                    <span style="font-size: 11px; font-weight: bold">3.</span><span-->
                            <!--                                        style="font-size: 13px; font-weight: bold">Inheritance and Polymorphism</span>-->
                            <!--                                </v-expansion-panel-header>-->
                            <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                    • Class Inheritance (상속) 개념 및 이해-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Super Class vs. Sub Class-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Polymorphism-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Dynamic Binding-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Type Compatibility-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Google 과 Youtube 를 상속개념으로 구현 해보기-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 숙제 (Reading & MCP & Programming)-->
                            <!--                                </v-expansion-panel-content>-->
                            <!--                            </v-expansion-panel>-->

                            <!--                            <v-expansion-panel>-->
                            <!--                                <v-expansion-panel-header>-->
                            <!--                                    <span style="font-size: 11px; font-weight: bold">4.</span><span-->
                            <!--                                        style="font-size: 13px; font-weight: bold">Array and ArrayLists</span>-->
                            <!--                                </v-expansion-panel-header>-->
                            <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                    • 배열 (Array)는 왜 필요하며 어떻게 사용하는가?-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 1차원 배열 (One-dimensional Array)-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Array vs. ArrayList in Java-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 2차원 배열 (Two-dimensional Array)-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 숙제 (Reading & MCP & Programming)-->
                            <!--                                </v-expansion-panel-content>-->
                            <!--                            </v-expansion-panel>-->
                            <!--                            <v-expansion-panel>-->
                            <!--                                <v-expansion-panel-header>-->
                            <!--                                    <span style="font-size: 11px; font-weight: bold">5.</span><span-->
                            <!--                                        style="font-size: 13px; font-weight: bold">Object Oriented Program Design</span>-->
                            <!--                                </v-expansion-panel-header>-->
                            <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                    • AP CSA 의 필수문제 Object Oriented Program Design-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Software Development-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Program Analysis-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 숙제 (Reading & MCP & Programming)-->
                            <!--                                </v-expansion-panel-content>-->
                            <!--                            </v-expansion-panel>-->

                            <!--                            <v-expansion-panel>-->
                            <!--                                <v-expansion-panel-header>-->
                            <!--                                    <span style="font-size: 11px; font-weight: bold">6.</span><span-->
                            <!--                                        style="font-size: 13px; font-weight: bold">Algorithms</span>-->
                            <!--                                </v-expansion-panel-header>-->
                            <!--                                <v-expansion-panel-content style="font-size: 13px" class="text-left">-->
                            <!--                                    • Recursive Methods-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Recursive 함수의 기본 형태-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • How to write recursive methods-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Recursion 을 사용하는 이유-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Recursion vs. Iteration-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • What are Algorithms?-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • Sorting-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--&lt;!&ndash;                                    &nbsp;&nbsp;&nbsp;&nbsp; • Sorting&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    <div style="padding-bottom: 8px"></div>&ndash;&gt;-->
                            <!--                                    • Searching-->
                            <!--                                    <div style="padding-bottom: 8px"></div>-->
                            <!--                                    • 숙제 (Reading & MCP & Programming)-->
                            <!--                                </v-expansion-panel-content>-->
                            <!--                            </v-expansion-panel>-->

                            <!--&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">8.</span><span&ndash;&gt;-->
                            <!--&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 8: 2D Array</span>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                            <!--&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                            <!--&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">9.</span><span&ndash;&gt;-->
                            <!--&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 9: Inheritance</span>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                            <!--&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->

                            <!--&lt;!&ndash;                            <v-expansion-panel>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <v-expansion-panel-header>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    <span style="font-size: 11px; font-weight: bold">10.</span><span&ndash;&gt;-->
                            <!--&lt;!&ndash;                                        style="font-size: 13px; font-weight: bold">Unit 10: Recursion</span>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                </v-expansion-panel-header>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                <v-expansion-panel-content>&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud&ndash;&gt;-->
                            <!--&lt;!&ndash;                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&ndash;&gt;-->
                            <!--&lt;!&ndash;                                </v-expansion-panel-content>&ndash;&gt;-->
                            <!--&lt;!&ndash;                            </v-expansion-panel>&ndash;&gt;-->


                            <!--                        </v-expansion-panels>-->
                            <div class="pt-10"></div>
                            <!--                            <div class="py-4">-->
                            <!--                                <v-btn to="/bootcamp/apcsa/curriculum" color="white" x-large width="80%"-->
                            <!--                                       style="letter-spacing: 0.2px; font-weight: bold; border: 1px solid #1F383A "-->
                            <!--                                       elevation="0"> 커리큘럼 자세히 보기 >-->
                            <!--                                </v-btn>-->
                            <!--                            </div>-->
                            <div class="py-10"></div>
                            <div>
                                <div style="font-size: 20px; font-weight: bold " class="text-left pl-5">
                                    수업이 끝나면 <br/>
                                    과제와 퀴즈 걱정도 끝납니다.
                                </div>

                                <div class="py-1"></div>
                                <div style="color: #6E767C; font-size: 14px" class="text-left pl-5">
                                    개념과 시험대비만 하는 것이 아닌, <br/> 학교 AP CSA 숙제 및 퀴즈 대비도 <br/> 강사진이 함께 도와주고 있습니다.
                                </div>
                                <div class="py-3"></div>
                                <!--                            <div class="pa-2 ma-2"-->
                                <!--                                 style="height: 200px; border-radius: 10px; border-style: solid; border-width: thin">-->
                                <img style="border-radius: 10px;  max-height: 100%; width: 90%"
                                     src="../../assets/cshomework1.png">
                                <img style="border-radius: 10px; max-height: 100%; width: 90%"
                                     src="../../assets/cshomework2.png">
                                <img style="border-radius: 10px; max-height: 100%; width: 90%"
                                     src="../../assets/cshomework3.png">
                                <div class="py-10"></div>
                                <!--                            </div>-->
                            </div>
                        </div>
                    </div>

                </div>
                <div class="py-10"></div>
            </div>

            <!-- calender -->
            <div style="background-color: #1D2022;display: flex;justify-content: center;flex-direction: column;align-items: center;width: 100vw;padding: 100px 0px">
                <h2 style="text-align: center;color: white;margin-bottom: 25px">AP CSA KST</h2>
                <div style="display: grid;grid-template-rows:1fr 1fr 1fr;color: white;width: inherit; max-width: 600px">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #1E2836;margin-bottom: 10px;font-weight: bolder;font-size: smaller;height: 50px">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일</span>
                        <span style="place-self: center center;text-align: center">시간</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #64748B;gap: 3px;margin-bottom: 10px;font-size: 14px">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP CSA<br/>[개념정리반]</span>
                        <span style="place-self: center center;text-align: center">월, 수, 금</span>
                        <span style="place-self: center center;text-align: center">KST<br/>19:30~21:00</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #64748B;gap: 3px;margin-bottom: 10px;font-size: 14px">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP CSA<br/>[문제풀이반]</span>
                        <span style="place-self: center center;text-align: center">월, 수, 금</span>
                        <span style="place-self: center center;text-align: center">KST<br/>21:00~22:30</span>
                    </div>
                </div>
                <h2 style="text-align: center;color: white;margin: 40px 0px">AP CSA PST</h2>
                <div style="display: grid;grid-template-rows:1fr 1fr 1fr;color: white;width: inherit; max-width: 600px">
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #1E2836;margin-bottom: 10px;font-weight: bolder;font-size: smaller">
                        <span style="place-self: center center;padding-left: 0px">과목명</span>
                        <span style="place-self: center center;text-align: center">요일</span>
                        <span style="place-self: center center;text-align: center">시간</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #64748B;gap: 3px;margin-bottom: 10px;font-size: 14px">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP CSA<br/>[개념정리반]</span>
                        <span style="place-self: center center;text-align: center">월, 수, 금</span>
                        <span style="place-self: center center;text-align: center">(서부)<br/>02:30~04:00<br/>(동부)<br/>05:30~07:00</span>
                    </div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr;background-color: #64748B;gap: 3px;margin-bottom: 10px;font-size: 14px">
                        <span style="place-self: center center;text-align: center;padding-left: 15px;font-weight: bolder">AP CSA<br/>[문제풀이반]</span>
                        <span style="place-self: center center;text-align: center">월, 수, 금</span>
                        <span style="place-self: center center;text-align: center">(서부)<br/>04:00~05:30<br/>(동부)<br/>07:00~08:30</span>
                    </div>
                </div>
            </div>

            <div style="background-color: #111212; color: #FC434B " class="px-10 pt-10 pb-10 text-left">
                <div class="py-10"></div>
                <div class="hidden-md-and-up">
                    <div class="secondary--text font-weight-bold" style="font-size: 14px">
                        학습 지원
                    </div>
                    <div class="py-1"></div>
                    <div class="white--text text-h6 font-weight-bold" style="">
                        수업으로 끝나지 않습니다<br/>
                        최종 목표인 시험까지 함께 합니다.
                    </div>
                    <div class="py-3"></div>
                    <div class="white--text" style="font-size: 13px">
                        <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">
                            <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">1. 수강기간 제한 없는
                                인강 복습 지원
                                지원
                            </div>
                            <div style="color: black;" class="pa-3 text-center">
                                <img style="max-height: 100%; width: 90%"
                                     src="../../assets/apcs1.png">
                            </div>
                            <div style="color: black; font-size: 13px" class="pa-3 text-center">
                                모든 수업들은 녹화되어 인강 형태로 <br/> 제공 되어, 혹시라도 헷갈렸던 수업 내용이 <br/> 있다면 언제든 복습 가능합니다.
                            </div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="white--text" style="font-size: 13px">
                        <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">
                            <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">2. 과목 관련 항시 질답
                                제공
                            </div>
                            <div style="color: black;" class="pa-3 text-center">
                                <img style="max-height: 100%; width: 90%" src="../../assets/apcs2.jpeg">
                            </div>
                            <div style="color: black; font-size: 13px" class="pa-3 text-center">
                                학교 숙제부터 문제 풀이 등 <br/> AP CSA에 관한 질답 항시 제공
                            </div>
                        </div>

                    </div>
                    <div class="py-3"></div>
                    <div class="white--text" style="font-size: 13px">
                        <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">
                            <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">3. 그룹 스터디 제공
                            </div>
                            <div style="color: black;" class="pa-3 text-center">
                                <img style="max-height: 100%; width: 90%" src="../../assets/apcs3.jpeg">
                            </div>
                            <div style="color: black; font-size: 13px" class="pa-3 text-center">
                                혼자서는 아무래도 어려운 과목인 만큼 <br/> 수강생들과 조별로 주기적인 스터디그룹 제공
                            </div>
                        </div>
                    </div>
                </div>
                <div class="center-horizontal">
                    <div class="hidden-sm-and-down" style="max-width: 750px">
                        <div class="secondary--text font-weight-bold" style="font-size: 14px">
                            학습 지원
                        </div>
                        <div class="py-1"></div>
                        <div class="white--text text-h6 font-weight-bold" style="">
                            수업으로 끝나지 않습니다<br/>
                            최종 목표인 시험까지 함께 합니다.
                        </div>
                        <div class="py-3"></div>
                        <div class="white--text" style="font-size: 13px">
                            <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">
                                <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">1. 기간 없는
                                    인강 복습
                                    지원
                                </div>
                                <div style="color: black;" class="pa-3 text-center">
                                    <img style="max-height: 100%; width: 90%"
                                         src="../../assets/record-videos-coursifyme.jpeg">
                                </div>
                                <div style="color: black; font-size: 13px" class="pa-3 text-center">
                                    모든 수업들은 녹화되어 인강 형태로 <br/> 제공 되어, 혹시라도 헷갈렸던 수업 내용이 <br/> 있다면 언제든 복습 가능합니다.
                                </div>
                            </div>
                        </div>
                        <div class="py-3"></div>
                        <div class="white--text" style="font-size: 13px">
                            <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">
                                <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">2. 과목 관련
                                    항시 질답 제공
                                </div>
                                <div style="color: black;" class="pa-3 text-center">
                                    <img style="max-height: 100%; width: 90%" src="../../assets/consulting.jpg">
                                </div>
                                <div style="color: black; font-size: 13px" class="pa-3 text-center">
                                    학교 숙제부터 문제 풀이 등 <br/> AP CSA에 관한 질답 항시 제공
                                </div>
                            </div>

                        </div>
                        <div class="py-3"></div>
                        <div class="white--text" style="font-size: 13px">
                            <div style="border-style: solid; border-width: thin; border-radius: 6px; background-color: white;">
                                <div style="color: black; font-size: 15px" class="px-5 pt-5 font-weight-bold">3. 그룹 스터디
                                    제공
                                </div>
                                <div style="color: black;" class="pa-3 text-center">
                                    <img style="max-height: 100%; width: 90%" src="../../assets/study-group.jpeg">
                                </div>
                                <div style="color: black; font-size: 13px" class="pa-3 text-center">
                                    혼자서는 아무래도 어려운 과목인 만큼 <br/> 수강생들과 조별로 주기적인 스터디그룹 제공
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-10"></div>
            </div>
            <div class="pa-10" style="background-color: #1D2022" id="sign-up-section">
                <div class="hidden-md-and-up">
                    <div style="color: white; font-size: 20px; font-weight: bold">
                        모집합니다.
                    </div>
                    <div class="py-1"></div>
                    <div style="color: #AFB5BA; font-size: 15px">
                        학교수업과 독학으로 <br/> 힘들었던 Computer Science <br/> 부트캠프 여정을 시작하세요.
                    </div>
                    <div class="py-3"></div>
                    <v-card elevation="0">
                        <div class="font-weight-bold pa-4" style="font-size: 15px">7기 모집 중</div>
                        <div style="font-size: 12px; " class="pl-3">
                            <v-row>
                                <v-col>신청 접수</v-col>
                                <v-col>2주뒤 마감</v-col>
                                <v-col></v-col>
                            </v-row>
                        </div>
                        <div style="font-size: 12px; " class="pl-3">
                            <v-row>
                                <v-col>수업 기간</v-col>
                                <v-col>1기: 12/10 ~ 1/10 <br/>2기: 1/11 ~ 2/8</v-col>
                                <v-col></v-col>
                            </v-row>
                        </div>
                        <div class="py-3"></div>
                        <v-btn width="90%" to="/bootcamp/register" color="primary">
                            7기 참가 신청하기
                        </v-btn>
                        <div class="py-3"></div>
                    </v-card>
                    <div class="py-2"></div>
                    <v-card elevation="0" class="text-left">
                        <!--                        <div class="font-weight-bold pa-4 " style="font-size: 14px">진행 방법</div>-->
                        <!--                        <v-row style="font-size: 12px" class="px-4">-->
                        <!--                            <v-col>-->
                        <!--                                <v-icon size="18px">mdi-clock-outline</v-icon>&nbsp;&nbsp;주 1회 4시간반 수업-->
                        <!--                            </v-col>-->
                        <!--                            <v-col>-->
                        <!--                                <v-icon size="18px">mdi-sail-boat</v-icon>-->
                        <!--                                최대 7명-->
                        <!--                            </v-col>-->
                        <!--                        </v-row>-->
                        <!--                        <v-row style="font-size: 12px; margin-top: 0px !important;" class="px-4">-->
                        <!--                            <v-col>-->
                        <!--                                <v-icon size="18px">mdi-calendar-multiselect-outline</v-icon>&nbsp;&nbsp;주말-->
                        <!--                            </v-col>-->
                        <!--                            <v-col>-->
                        <!--                                <v-icon size="18px">mdi-monitor-dashboard</v-icon>&nbsp;&nbsp;Online Live-->
                        <!--                            </v-col>-->
                        <!--                        </v-row>-->
                        <div class="py-2"></div>
                        <div class="font-weight-bold pa-4 " style="font-size: 14px">지원자격</div>

                        <div style="font-size: 12px" class="px-4">
                            <div style="padding-bottom: 8px"></div>
                            - 문제해결 의지가 있는 학생<br/>
                            <div style="padding-bottom: 8px"></div>
                            - 프로그래밍 경험에 상관없이 배우고자 하는 학생<br/>
                            <div style="padding-bottom: 8px"></div>
                            - CS 인턴 및 관련활동을 준비중인 학생<br/>
                            <div style="padding-bottom: 8px"></div>
                            - 현재 AP CSA 수업을 듣거나 시험을 앞두고 있는 학생
                        </div>
                        <div class="py-2"></div>
                        <div>
                            <div class="font-weight-bold pa-4 " style="font-size: 14px">상세 일정</div>
                            <Timeline></Timeline>
                            <!--                    <v-timeline style="font-size: 12px; margin-left: -240px" class="px-4">-->
                            <!--                        <v-timeline-item small>-->
                            <!--                            <div class="font-weight-bold">-->
                            <!--                                지원서 접수-->
                            <!--                            </div>-->
                            <!--                            <div>-->
                            <!--                                간단한 정보와 지원 동기를 작성합니다.-->
                            <!--                            </div>-->
                            <!--                        </v-timeline-item>-->
                            <!--                        <v-timeline-item small right class="">-->
                            <!--                            <div class="font-weight-bold">-->
                            <!--                                온라인 상담 및 인터뷰-->
                            <!--                            </div>-->
                            <!--                            <div>-->
                            <!--                                아이비에드 상담사와 1:1 상담 인터뷰를 진행합니다.-->
                            <!--                            </div>-->
                            <!--                        </v-timeline-item>-->
                            <!--                        <v-timeline-item small>-->
                            <!--                            <div class="font-weight-bold">-->
                            <!--                                합격 & 사전준비-->
                            <!--                            </div>-->
                            <!--                            <div>-->
                            <!--                                최종 합격 후, 사전 강의, 사전스터디, 토이프로젝트에 참여 하면서 항해를 준비합니다.-->
                            <!--                            </div>-->
                            <!--                        </v-timeline-item>-->
                            <!--                        <v-timeline-item small right>-->
                            <!--                            <div class="font-weight-bold">-->
                            <!--                                부트캠프 시작-->
                            <!--                            </div>-->
                            <!--                            <div>-->
                            <!--                                6주간의 여정을 시작합니다.-->
                            <!--                            </div>-->
                            <!--                        </v-timeline-item>-->
                            <!--                    </v-timeline>-->
                        </div>

                    </v-card>
                </div>
                <div class="center-horizontal">
                    <div class="hidden-sm-and-down" style="max-width: 750px">
                        <div class="py-10"></div>

                        <div style="color: white; font-size: 20px; font-weight: bold">
                            모집합니다.
                        </div>
                        <div class="py-1"></div>
                        <div style="color: #AFB5BA; font-size: 15px">
                            학교수업과 독학으로 <br/> 힘들었던 Computer Science <br/> 부트캠프 여정을 시작하세요.
                        </div>
                        <div class="py-3"></div>
                        <v-card elevation="0">
                            <div class="font-weight-bold pa-4" style="font-size: 15px">7기 모집 중</div>
                            <div style="font-size: 12px; " class="pl-3">
                                <v-row>
                                    <v-col>신청 접수</v-col>
                                    <v-col>2주뒤 마감</v-col>
                                    <v-col></v-col>
                                </v-row>
                            </div>
                            <div style="font-size: 12px; " class="pl-3">
                                <v-row>
                                    <v-col>수업 기간</v-col>
                                    <v-col>1기: 12/10 ~ 1/10 <br/>2기: 1/11 ~ 2/8</v-col>

                                    <v-col></v-col>
                                </v-row>
                            </div>
                            <div class="py-3"></div>
                            <v-btn width="90%" to="/bootcamp/register" color="primary">
                                7기 참가 신청하기
                            </v-btn>
                            <div class="py-3"></div>
                        </v-card>
                        <div class="py-2"></div>
                        <v-card elevation="0" class="text-left">
                            <!--                            <div class="font-weight-bold pa-4 " style="font-size: 14px">진행 방법</div>-->
                            <!--                            <v-row style="font-size: 12px" class="px-4">-->
                            <!--                                <v-col>-->
                            <!--                                    <v-icon size="18px">mdi-clock-outline</v-icon>&nbsp;&nbsp;주 1회 4시간반 수업-->
                            <!--                                </v-col>-->
                            <!--                                <v-col>-->
                            <!--                                    <v-icon size="18px">mdi-sail-boat</v-icon>-->
                            <!--                                    최대 10명-->
                            <!--                                </v-col>-->
                            <!--                            </v-row>-->
                            <!--                            <v-row style="font-size: 12px; margin-top: 0px !important;" class="px-4">-->
                            <!--                                <v-col>-->
                            <!--                                    <v-icon size="18px">mdi-calendar-multiselect-outline</v-icon>&nbsp;&nbsp;주중/주말-->
                            <!--                                </v-col>-->
                            <!--                                <v-col>-->
                            <!--                                    <v-icon size="18px">mdi-monitor-dashboard</v-icon>&nbsp;&nbsp;Online Live-->
                            <!--                                </v-col>-->
                            <!--                            </v-row>-->
                            <div class="py-2"></div>
                            <div class="font-weight-bold pa-4 " style="font-size: 14px">지원자격</div>

                            <div style="font-size: 12px" class="px-4">
                                <div style="padding-bottom: 8px"></div>
                                - 문제해결 의지가 있는 학생<br/>
                                <div style="padding-bottom: 8px"></div>
                                - 프로그래밍 경험에 상관없이 배우고자 하는 학생<br/>
                                <div style="padding-bottom: 8px"></div>
                                - CS 인턴 및 관련활동을 준비중인 학생<br/>
                                <div style="padding-bottom: 8px"></div>
                                - 현재 AP CSA 수업을 듣거나 시험을 앞두고 있는 학생
                            </div>
                            <div class="py-2"></div>
                            <div>
                                <div class="font-weight-bold pa-4 " style="font-size: 14px">상세 일정</div>
                                <Timeline></Timeline>
                                <!--                    <v-timeline style="font-size: 12px; margin-left: -240px" class="px-4">-->
                                <!--                        <v-timeline-item small>-->
                                <!--                            <div class="font-weight-bold">-->
                                <!--                                지원서 접수-->
                                <!--                            </div>-->
                                <!--                            <div>-->
                                <!--                                간단한 정보와 지원 동기를 작성합니다.-->
                                <!--                            </div>-->
                                <!--                        </v-timeline-item>-->
                                <!--                        <v-timeline-item small right class="">-->
                                <!--                            <div class="font-weight-bold">-->
                                <!--                                온라인 상담 및 인터뷰-->
                                <!--                            </div>-->
                                <!--                            <div>-->
                                <!--                                아이비에드 상담사와 1:1 상담 인터뷰를 진행합니다.-->
                                <!--                            </div>-->
                                <!--                        </v-timeline-item>-->
                                <!--                        <v-timeline-item small>-->
                                <!--                            <div class="font-weight-bold">-->
                                <!--                                합격 & 사전준비-->
                                <!--                            </div>-->
                                <!--                            <div>-->
                                <!--                                최종 합격 후, 사전 강의, 사전스터디, 토이프로젝트에 참여 하면서 항해를 준비합니다.-->
                                <!--                            </div>-->
                                <!--                        </v-timeline-item>-->
                                <!--                        <v-timeline-item small right>-->
                                <!--                            <div class="font-weight-bold">-->
                                <!--                                부트캠프 시작-->
                                <!--                            </div>-->
                                <!--                            <div>-->
                                <!--                                6주간의 여정을 시작합니다.-->
                                <!--                            </div>-->
                                <!--                        </v-timeline-item>-->
                                <!--                    </v-timeline>-->
                            </div>

                        </v-card>
                    </div>
                </div>
                <div class="py-10"></div>

            </div>

        </div>
    </div>

</template>

<script>
    import Timeline from "./Timeline";
    import SchoolCarousel from "../../views/pages/SchoolCarousel";

    export default {
        name: "BootcampMain",
        components: {SchoolCarousel, Timeline},
        data() {
            return {
                fourWeek: true,
                sixWeek: false,
                eightWeek: false,


            }
        },
        methods: {
            moveToBootcamp() {
                this.dialog = false;
                this.$router.push("/bootcamp/apcsa");
            },
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__prev")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__next")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
            }
        }
    }
</script>

<style scoped>
    *{
        font-family: "Noto Sans KR";
    }
    .bootcamp-first {
        background-image: url("../../assets/bootcamp-main.png");
    }

    .bootcamp-modal {
        min-height: 500px;
        border-radius: 15px !important;
        /*background-color: #1F383A;*/
        background-image: url("../../assets/waves-bg.jpg");
        background-size: 100% 100%;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .model-top {
        /*height: 100px;*/
        /*background-color: #1F383A;*/
        color: #eeeeee;
        font-weight: bold;
        font-size: 18px;
        /*font-family: "Google Sans", Roboto, Arial, sans-serif;*/
    }

    .learn-more-btn {

    /*background: rgb(34,193,195);*/
    /*background: -moz-linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,45,97,1) 100%);*/
    /*background: -webkit-linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,45,97,1) 100%);*/
    /*background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,45,97,1) 100%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#22c1c3",endColorstr="#fd2d61",GradientType=1);*/
    <!--
    HTML !

    -->

    /* CSS */
    .button-1 {
        background-color: #EA4C89;
        border-radius: 8px;
        border-style: none;
        box-sizing: border-box;
        color: #FFFFFF;
        cursor: pointer;
        display: inline-block;
        font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        line-height: 20px;
        list-style: none;
        margin: 0;
        outline: none;
        padding: 10px 16px;
        position: relative;
        text-align: center;
        text-decoration: none;
        transition: color 100ms;
        vertical-align: baseline;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .button-1:hover,
    .button-1:focus {
        background-color: #F082AC;
    }

    }

</style>
