<template>
    <!--    <div style="background-color: rgba(59, 59, 59, 0.9)">-->
    <div style="background-color: #f6f5f5">
        <!--        <section>-->
        <!--            <h1>Scroll Down to Reveal Elements &#8595;</h1>-->
        <!--        </section>-->

        <div class="container reveal">
            <!--                <h2>Caption</h2>-->
            <div class="py-8"></div>
            <div class="pa-3">

                <img class="hidden-lg-and-up" style="border-radius: 8px" height="100%" width="100%"
                     src="../../../../assets/brand/2moblie.png" alt="Thumbs Up">
                <img class="hidden-md-and-down" style="border-radius: 8px" height="100%" width="100%"
                     src="../../../../assets/brand/2PC.png" alt="Thumbs Up">
            </div>
            <div class="hidden-md-and-down text-container" style="color: black">
                <div class="px-4">
                    <div class="py-5"></div>
                    <div class="" style="font-size: 30px; font-family: S-CoreDream-6Bold">
                        <span style="color: #A20916;  font-family: S-CoreDream-6Bold">IVYED</span>는 가격 이상의 <br/>가치를 가진
                        <span style="color: #A20916;  font-family: S-CoreDream-6Bold">교육</span>을 제공하는 <br/><span
                            style="color: #A20916; font-family: S-CoreDream-6Bold">노력</span>을 하고 있습니다.
                    </div>
                    <div style="font-size: 28px; font-weight: bold">
                        ___
                    </div>
                    <div class="py-3"></div>
                    <div style="font-size: 18px">
                        <div>
                            유학의 특성상 한국에서 얻을 수 있는 정보는 제한적이고 판단하기 어렵습니다.
                        </div>
                        <div class="py-3"></div>
                        <div>
                            그래서 고객들과 이야기 하다보면, 잘못된 정보를 많이 가지고 있는 것을 확인 할 수 있었습니다.
                        </div>
                        <div class="py-3"></div>
                        <div>
                            소비자들이 검증하기 어려운 유학시장에서는 실제 유학을 경험한 사람들이 아닌데, 유학 전문가로 행세하는 경우도 다분하고, 영어실력과 전문성이 부족한데 단순히
                            ‘유학생'이라고
                            해서 높은 가격을 지불하고 과외를 진행하고 있는 현상도 확인을 할 수 있었습니다.

                        </div>
                        <div class="py-3"></div>
                        <div>그래서, 저희는 유학 시장에서 불균형과 비대칭을 바로 잡기 위해서 직접 유학을
                            경험하신 미국 상위 1% 대학에 다니고 있는 유학생 분들을 찾아다녔습니다.
                            저희의 자체 검증 과정을 통해 유창한 영어실력은 물론이고, 각 유학 과목에
                            전문성과 경험을 가진 선생님과 유학 과정에서 어려움을 겪고 있는 학생들을
                            연결하여 학생들에게 이익이 되는 교육을 제공하고 있습니다.

                        </div>
                    </div>

                </div>


            </div>
            <div class="hidden-lg-and-up text-container" style="color: black">
                <div class="px-4">
                    <div class="py-5"></div>
                    <div class="" style="font-size: 22px; font-family: S-CoreDream-6Bold">
                        <span style="color: #A20916;  font-family: S-CoreDream-6Bold">IVYED</span>는 가격 이상의 <br/>가치를 가진
                        <span style="color: #A20916;  font-family: S-CoreDream-6Bold">교육</span>을 제공하는 <br/><span
                            style="color: #A20916; font-family: S-CoreDream-6Bold">노력</span>을 하고 있습니다.
                    </div>
                    <div style="font-size: 28px; font-weight: bold">
                        ___
                    </div>
                    <div class="py-3"></div>
                    <div style="">
                        <div>
                            유학의 특성상 한국에서 얻을 수 있는 정보는 제한적이고 판단하기 어렵습니다.
                        </div>
                        <div class="py-3"></div>
                        <div>
                            그래서 고객들과 이야기 하다보면, 잘못된 정보를 많이 가지고 있는 것을 확인 할 수 있었습니다.
                        </div>
                        <div class="py-3"></div>
                        <div>
                            소비자들이 검증하기 어려운 유학시장에서는 실제 유학을 경험한 사람들이 아닌데, 유학 전문가로 행세하는 경우도 다분하고, 영어실력과 전문성이 부족한데 단순히
                            ‘유학생'이라고
                            해서 높은 가격을 지불하고 과외를 진행하고 있는 현상도 확인을 할 수 있었습니다.

                        </div>
                        <div class="py-3"></div>
                        <div>그래서, 저희는 유학 시장에서 불균형과 비대칭을 바로 잡기 위해서 직접 유학을
                            경험하신 미국 상위 1% 대학에 다니고 있는 유학생 분들을 찾아다녔습니다.
                            저희의 자체 검증 과정을 통해 유창한 영어실력은 물론이고, 각 유학 과목에
                            전문성과 경험을 가진 선생님과 유학 과정에서 어려움을 겪고 있는 학생들을
                            연결하여 학생들에게 이익이 되는 교육을 제공하고 있습니다.

                        </div>
                    </div>

                </div>


            </div>
        </div>
        <div class="py-5"></div>

        <!-- Add more sections as needed -->
    </div>
</template>

<script>
    export default {
        name: "BrandStory2",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>
    @import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /*font-family: "Asap", sans-serif;*/
    }

    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #e0ffff;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
</style>

