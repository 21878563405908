<template>
    <div style="background-color: #f6f7f7">
        <!--        <section>-->
        <!--            <h1>Scroll Down to Reveal Elements &#8595;</h1>-->
        <!--        </section>-->

        <div class="container reveal">
            <!--                <h2>Caption</h2>-->
            <div class="py-7"></div>
            <div class="pa-3">
                <img class="hidden-lg-and-up" style="border-radius: 8px" height="100%" width="100%"
                     src="../../../../assets/brand/1moblie.png" alt="Balloons">
                <img class="hidden-md-and-down" style="border-radius: 8px" height="100%" width="100%"
                     src="../../../../assets/brand/1PC.png" alt="Balloons">
            </div>
            <div class="hidden-md-and-down text-container">
                <div class="px-4">
                    <div style="color: #a99e92; font-size: 22px" class="py-3">브랜드 철학</div>
                    <!--                    <div class="py-3"></div>-->
                    <div class="" style="font-size: 30px; font-family: S-CoreDream-6Bold">
                        유학 시장의 부조리함을 겪었던 <br/>경험에서 <span style="color: #A30717">IVYED</span>를 <span style="color: #A30717">출시</span>했습니다.
                    </div>
                    <!--                                            <div class="py-3"></div>-->
                    <div style="font-size: 28px; font-weight: bold">
                        ___
                    </div>
                    <div class="py-3"></div>
                    <div style="font-size: 18px">
                        <div>
                            유학을 가고 싶었던 12살의 꼬마아이는, 무작정 유학원에 등록하고 유학원이 하는 말을 들으며 꼭두각시처럼 유학원이 말하는 대로 준비하면서 미국 유학을 갔습니다.
                            미국유학을
                            갔으니 성공적이였을까요?
                        </div>
                        <div class="py-3"></div>
                        <div>
                            아니요. 미국으로 학교를 다닐 수 있었지만, 미국 학교를 가고나서 보니 12살 꼬마아이를 위한 유학과정을 준비한게 아니라, 등록한 유학원과 관련된 미국 유학원과
                            학교에
                            등록하게 하여 서로 수익을 챙길 수 있도록 12살 꼬마아이를 이용한 것이었습니다.
                        </div>
                        <div class="py-3"></div>
                        <div>
                            한국에서 유학시장은 정보의 불균형과 비대칭이 큽니다. 적정가라는 것도 존재하지만 유학이라는 특성상 가격들이 자연스레 높게 책정되어있고 소비자들은 유학 시장에서
                            부르는게
                            값이고, 당연히 내야한다고 생각하는 돈이 되버렸습니다.
                        </div>
                        <div class="py-3"></div>
                        <div>
                            또한 유학 시장은 높은 가격에 맞는 서비스를 제공받지도 못하면서도 소비자들이 이용당하고 있습니다.

                        </div>
                    </div>

                </div>

            </div>
            <div class="hidden-lg-and-up text-container">
                <div class="px-4">
                    <div style="color: #a99e92; font-size: 18px" class="py-3">브랜드 철학</div>
                    <!--                    <div class="py-3"></div>-->
                    <div class="" style="font-size: 22px; font-family: S-CoreDream-6Bold">
                        유학 시장의 부조리함을 겪었던 <br/>경험에서 <span style="color: #A30717">IVYED</span>를 <span style="color: #A30717">출시</span>했습니다.
                    </div>
                    <!--                                            <div class="py-3"></div>-->
                    <div style="font-size: 28px; font-weight: bold">
                        ___
                    </div>
                    <div class="py-3"></div>
                    <div style="">
                        <div>
                            유학을 가고 싶었던 12살의 꼬마아이는, 무작정 유학원에 등록하고 유학원이 하는 말을 들으며 꼭두각시처럼 유학원이 말하는 대로 준비하면서 미국 유학을 갔습니다.
                            미국유학을
                            갔으니 성공적이였을까요?
                        </div>
                        <div class="py-3"></div>
                        <div>
                            아니요. 미국으로 학교를 다닐 수 있었지만, 미국 학교를 가고나서 보니 12살 꼬마아이를 위한 유학과정을 준비한게 아니라, 등록한 유학원과 관련된 미국 유학원과
                            학교에
                            등록하게 하여 서로 수익을 챙길 수 있도록 12살 꼬마아이를 이용한 것이었습니다.
                        </div>
                        <div class="py-3"></div>
                        <div>
                            한국에서 유학시장은 정보의 불균형과 비대칭이 큽니다. 적정가라는 것도 존재하지만 유학이라는 특성상 가격들이 자연스레 높게 책정되어있고 소비자들은 유학 시장에서
                            부르는게
                            값이고, 당연히 내야한다고 생각하는 돈이 되버렸습니다.
                        </div>
                        <div class="py-3"></div>
                        <div>
                            또한 유학 시장은 높은 가격에 맞는 서비스를 제공받지도 못하면서도 소비자들이 이용당하고 있습니다.

                        </div>
                    </div>

                </div>

            </div>

        </div>
        <!-- Add more sections as needed -->
        <div class="py-5"></div>
    </div>
</template>

<script>
    export default {
        name: "BrandStory1",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>
    @import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /*font-family: "Asap", sans-serif;*/
    }

    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    /*.each-title {*/
    /*    font-size: 10px;*/
    /*    text-align: left !important;*/
    /*}*/
    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #e0ffff;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
</style>
