export const apPhysics1Data = {
    bootcamp:[{
        classId: 0, class: {
            imgUrl: "https://i.postimg.cc/1RWQv9RZ/ph-900x643.jpg",
            imgAlt: "IVYED AP Physics 1, 실전대비 문제풀이반",
            title: "AP Physics 1, 실전대비 문제풀이반",
            subheading: "미국 국제학교 선생님 출신",
            description: "AP Physics 1, 교육경력 20년차 선생님과 함께 마스터",
            lectureStyle: "100% 온라인 강의",
            program: "단기반 신청 가능",
            necessity: ["AP Physics 1, 단기간에 5점을 취득하고 싶은 학생!", "Physics 1 개념은 어느정도 적립이 되었는데, 문제 응용력이 약한 학생!", "문제풀이를 통해 어려운 파트를 학습해보고 싶은 학생!"],
            introduction: {
                title: "AP Physics 1 문제풀이가 걱정이라면?",
                items: ["- AP Physics 1 고득점 완성", "- 실제 Physics 과목 교육을 해오신 선생님의 Physics 1타 강의", "- AP Physics 1의 문제풀이 학습으로 문제 응용력 향상", "- 단기간 집중 학습으로 5점 완성"]
            },
            whyTakeThisCourse: [{
                title: "교육경력 20년차 베테랑 선생님과 Physics 1 완벽 고득점 달성",
                info: ["20년간 가르치면서 생긴 노하우, 효율적인 Physics 1 공부"]
            }, {
                title: "AP Physics 교육 경력에서 얻은 Physics 지식을 응용할 수 있는 방법 학습",
                info: []
            }, {title: "노하우로 작성된 AP Physics 1 학습 자료 제공", info: []}, {title: "AP Physics 1 개념 완벽 이해", info: []}],
            afterTakingCourse: [{
                title: "AP Physics 1의 5점 달성",
                info: ["개념의 문제 응용력을 향상시켜 단기간 5점 달성"]
            }, {title: "안정적인 5점 완성", info: ["불안정한 5점으로 더 이상 걱정 X"]}, {
                title: "Physics 1를 영어로 해결하는 능력까지 겸비",
                info: ["Only English 과정으로, 영어로 나오는 AP 시험을 당황하지 않게 연습 가능"]
            }, {
                title: "Physics에 대한 흥미 증가",
                info: ["실제 학생들을 가르쳤던 경험을 토대로 수업에 집중할 수 있게 만드는 선생님의 즐거운 Physics 1강의로 Physics에 대한 흥미 증가"]
            }],
            teacher: {
                userId: 124,
                name: "Jean",
                imgUrl: "https://i.postimg.cc/kgBFXtr2/jin.png",
                schoolLogoImgUrl: "https://i.postimg.cc/prJCMYM7/columbia-shield.jpg",
                experience: ["Columbia 석사", "미국 국제학교 교사 5년", "과외/강의 15년", "미국 암전문병원 연구원"],
                description: "안녕하세요. 거의 해외에서만 20년 이상 거주하다 보니 영어보다 한국어가 더 어려워진 Jean 선생님입니다.\n소개 글이 어색할 수 있습니다.\n저를 간단히 소개하자면 학창 시절부터 개인 과외 없이 혼자서 공부하면서 그래도 Columbia 석사를 마지막으로 졸업했고, 대학교를 다니면서도 교육에 대한 열정이 가득하여 15년 동안 중학생, 고등학생 대상으로 가르쳐왔습니다.그러다 운이 좋게 미국 국제 학교에서 이공계열 과목 수업을 5년 정도 맡아왔습니다.\n지금은 국제 학교를 나와 미국 암 전문병원 연구원에서 일을 하고 있습니다.AP Physics관련 과목들은 저에게 가장 자신있는 과목입니다.\n저의 경험과 실력으로 AP Physics C 다 이해시켜드리겠습니다. ",
                videoUrl: "https://www.youtube.com/embed/K-MkErHx4MA?si=GqnqGpTrTS6F_iwQ"
            },
            course: [{
                schedule: {
                    courseName: "AP Physics 1 실전대비 문제풀이반 PST (서부)",
                    days: "월, 목",
                    time: "19:00 ~ 20:30 ",
                    timezone: "PST (서부)",
                    duration: "1개월",
                    minutes: "90분",
                    frequency: "주 2회",
                    shortDetails: ""
                },
                objectives: {
                    korean: ["- 학생들의 물리학 기본 개념, 역학, 파동 및 전기 등에 대한 이해를 향상시킵니다.", "- 문제 해결 능력을 개발하고 물리 현상을 분석하고 해석할 수 있는 능력을 키웁니다.", "- 학생들이 역학, 에너지 보존, 운동학을 포함한 물리학의 주요 원리와 방정식을 익힐 수 있도록 합니다.", "- 학생들이 수학 개념을 이용하여 현실 세계 물리 문제를 해결하고 그 해결 방법을 설명할 수 있도록 합니다.", "- 이론적 지식을 강화하고 실용적 기술을 개발하기 위한 실험 및 실험실 작업 기회를 제공합니다.", "- 학생들이 AP 물리학 I 시험을 준비하도록 하여 예제 문제와 시험 형식의 평가를 통해 연습합니다.", "- 과학적 방법과 실험의 역할에 대한 깊은 이해를 증진시킵니다.", "- 비교적 형식적이고 명확한 방식으로 과학적 개념을 전달하고 이해할 수 있는 능력을 촉진합니다.", "- 물리학과 일상 생활, 기술 및 다른 과학 분야와의 관련성을 개발합니다."],
                    english: ["- Enhance students’ understanding of fundamental concepts in physics, such as mechanics, waves, and electricity.", "- Develop problem-solving skills and the ability to analyze and interpret physical phenomena.", "- Familiarize students with the key principles and equations in physics, including Newton’s laws, conservation of energy, and kinematics.", "- Enable students to apply mathematical concepts to solve real-world physics problems and explain their solutions.", "- Provide opportunities for hands-on experiments and laboratory work to reinforce theoretical knowledge and develop practical skills.", "- Prepare students for the AP Physics I exam through practice with sample questions and exam-style assessments.", "- Foster a deep appreciation for the scientific method and the role of experimentation in physics.", "- Encourage critical thinking and the ability to communicate scientific concepts effectively.", "- Develop an understanding of the connections between physics and everyday life, technology, and other scientific disciplines."]
                },
                curriculum: [{
                    name: "Vectors",
                    item: {content: ["- Coordinate Systems and Frames of Reference", "- Vectors", " - Addition of Vectors", "- Subtraction of Vectors", "- Addition Methods Using the Components of Vectors", "- Practice Exercises "]}
                }, {
                    name: "Kinematics 47",
                    item: {content: ["- Average and Instantaneous Motion", "- Acceleration", "- Accelerated Motion Due to Gravity", "- Graphical Analysis of Motion", "- Relative Motion", "- Horizontally Launched Projectiles", "- Projectiles Launched at an Angle", "- Uniform Circular Motion", "- Practice Exercises "]}
                }, {
                    name: "Forces and Newton's Laws of Motion",
                    item: {content: ["- Forces", "- Newton's Laws of Motion", "- Static Applications of Newton's Laws", "- Free-Body Diagrams", "- How to Draw Effective Free-Body Diagrams", "- Dynamic Applications of Newton's Laws", "- Central Forces", "- Friction", "- Practice Exercises"]}
                }, {
                    name: "Energy",
                    item: {content: ["- Work", "- Power", "- Kinetic Energy and the Work-Energy Theorem", "- Potential Energy and Conservative Forces", "- Conservation of Energy and Systems", "- Practice Exercises "]}
                }, {
                    name: "Gravitation",
                    item: {content: ["- Newton's Law of Universal Gravitation", "- Gravitational Energy", "- Practice Exercises "]}
                }, {
                    name: "Impacts and Linear Momentum ",
                    item: {content: ["- Internal and External Forces", "- Impact Forces and Momentum Changes", "- The Law of Conservation of Linear Momentum", "- Elastic Collisions", "- Inelastic Collisions", "- Center of Mass", "- Practice Exercises "]}
                }, {
                    name: "Rotational Motion",
                    item: {content: ["-Parallel Forces and Moments", "- Torque", "- More Static Equilibrium Problems Using Forces and Torques", "- Moment of Inertia", "- Angular Kinematics", "- Energy Considerations for Rolling Objects ", "- Angular Momentum", "- Practice Exercises  "]}
                }, {
                    name: "Oscillatory Motion ",
                    item: {content: ["- Simple Harmonic Motion: A Mass on a Spring", "- Simple Harmonic Motion: A Simple Pendulum", "- The Dynamics of Simple Harmonic Motion", ""]}
                }]
            }, {
                schedule: {
                    courseName: "AP Physics 1 실전대비 문제풀이반 EST (동부)",
                    days: "월, 목",
                    time: "22:00 ~ 23:30",
                    timezone: "EST (동부)",
                    duration: "1개월",
                    minutes: "90분",
                    frequency: "주 2회",
                    shortDetails: ""
                }, objectives: {}, curriculum: []
            },{
                schedule: {
                    courseName: "AP Physics 1 실전대비 문제풀이반 KST",
                    days: "화, 금",
                    time: "12:00 ~ 13:30",
                    timezone: "KST",
                    duration: "1개월",
                    minutes: "90분",
                    frequency: "주 2회",
                    shortDetails: ""
                }, objectives: {}, curriculum: []
            }],
            review: [{
                person: "미국 보딩 스쿨 11학년",
                experience: "“학교에서 아무리 집중해서 들어도 Physics 1 개념이 이해 안 됐는데 Jean 선생님의 강의로 개념이 다 이해가 되었어요!”",
                personImgUrl: "https://i.postimg.cc/7Yr3LDJ1/avatar4.png"
            }, {
                person: "한국 국제학교 11학년",
                experience: "“개념만 다루시는 게 아니라 문제 푸는 연습도 같이 해주셔서 AP 시험 볼 때 놓치는 것 없이 다 풀 수 있었어요.”",
                personImgUrl: "https://i.postimg.cc/gc8hQ5tx/avatar2.png"
            }, {
                person: "미국 국제 학교 11학년",
                experience: "“Physics가 너무 어려워서 다른 과목들까지 자신감이 없어졌는데 Jean 선생님을 만나고 나서 자신감이 생겼어요.”",
                personImgUrl: "https://i.postimg.cc/4dbjWxL1/avatar1.png"
            }]
        }
    }]
}
