<template>
    <div>
        <div class="main-section">
            <div class="pt-6 text-center font-weight-medium" style="font-family: 'NEXON Lv1 Gothic OTF Light'; color: white">국내 1위 스마트 유학 과외<br/> 상위 1% 의 디테일을 만나다</div>
            <div class="pt-1 text-center font-weight-medium text-gradient" style="font-family: 'NEXON Lv1 Gothic OTF Bold'; color: white">아이비리그<br/><span class="text-gradient-2"> 프리미엄 유학 과외</span></div>

        </div>
        <ThreePointIntro></ThreePointIntro>
        <div class="pb-5"></div>
    </div>
</template>

<script>
    // import $ from 'jquery'
    import ThreePointIntro from "./ThreePointIntro";
    export default {
        name: "FirstPage",
        components: {ThreePointIntro},
        data() {
            return {
                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4',
                    'deep-purple accent-4',
                    'deep-purple accent-4',
                ],
                slides: [
                    'First',
                    'Second',
                    'Third',
                    'Fourth',
                    'Fifth',
                    'Fifth',
                    'Fifth',
                ],
            }
        },
        // mounted() {
        //     var x = 0,
        //         container = $('.container'),
        //         items = container.find('li'),
        //         containerHeight = 0,
        //         numberVisible = 5,
        //         intervalSec = 1700;
        //
        //     if(!container.find('li:first').hasClass("first")){
        //         container.find('li:first').addClass("first");
        //     }
        //
        //     items.each(function(){
        //         if(x < numberVisible){
        //             containerHeight = containerHeight + $(this).outerHeight();
        //             x++;
        //         }
        //     });
        //
        //     container.css({ height: containerHeight, overflow: "hidden" });
        //
        //     // eslint-disable-next-line no-unused-vars
        //     function vertCycle() {
        //         var firstItem = container.find('li.first').html();
        //
        //         container.append('<li>'+firstItem+'</li>');
        //         firstItem = '';
        //         // eslint-disable-next-line no-undef
        //         container.find('li.first').animate({ marginTop: "-50px" }, 600, function(){  $(this).remove(); container.find('li:first').addClass("first"); });
        //     }
        //
        //     if(intervalSec < 700){
        //         intervalSec = 700;
        //     }
        //
        //     var init = setInterval("vertCycle()",intervalSec);
        //
        //     container.hover(function(){
        //         clearInterval(init);
        //     }, function(){
        //         init = setInterval("vertCycle()",intervalSec);
        //     });
        // }
    }
</script>

<style scoped>
    .main-section{
        background: url(../../assets/ivy_black.jpg) center center/cover no-repeat;
        height: 700px;

    }
    .vl {
        border-left: 1px solid black;
        height: 20px;
        margin-left: 50px;
    }

    html,
    body {
        font-family: 'Droid Serif', serif;
    }

    h1 {
        font-size: 60px;
        text-align: center;
    }

    .content-slider {
        width: 100%;
        height: 360px;
    }

    .slider {
        height: 320px;
        width: 680px;
        margin: 40px auto 0;
        overflow: visible;
        position: relative;
    }

    .mask {
        overflow: hidden;
        height: 320px;
    }

    .slider ul {
        margin: 0;
        padding: 0;
        position: relative;
    }

    .slider li {
        width: 680px;
        height: 320px;
        position: absolute;
        top: -325px;
        list-style: none;
    }

    .slider .quote {
        font-size: 10px;
        font-style: italic;
    }

    .slider .source {
        font-size: 10px;
        text-align: right;
    }

    .slider li.anim1 {
        animation: cycle 15s linear infinite;
    }

    .slider li.anim2 {
        animation: cycle2 15s linear infinite;
    }

    .slider li.anim3 {
        animation: cycle3 15s linear infinite;
    }

    .slider li.anim4 {
        animation: cycle4 15s linear infinite;
    }

    .slider li.anim5 {
        animation: cycle5 15s linear infinite;
    }

    .slider li.anim6 {
        animation: cycle6 15s linear infinite;
    }

    .slider:hover li {
        animation-play-state: paused;
    }

    @keyframes cycle {
        0% {
            top: 0px;
        }
        4% {
            top: 0px;
        }
        16% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        20% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
        21% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        50% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        92% {
            top: -325px;
            opacity: 0;
            z-index: 0;
        }
        96% {
            top: -325px;
            opacity: 0;
        }
        100% {
            top: 0px;
            opacity: 1;
        }
    }

    @keyframes cycle2 {
        0% {
            top: -325px;
            opacity: 0;
        }
        16% {
            top: -325px;
            opacity: 0;
        }
        20% {
            top: 0px;
            opacity: 1;
        }
        24% {
            top: 0px;
            opacity: 1;
        }
        36% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        40% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
        41% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        100% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
    }

    @keyframes cycle3 {
        0% {
            top: -325px;
            opacity: 0;
        }
        36% {
            top: -325px;
            opacity: 0;
        }
        40% {
            top: 0px;
            opacity: 1;
        }
        44% {
            top: 0px;
            opacity: 1;
        }
        56% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        60% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
        61% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        100% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
    }

    @keyframes cycle4 {
        0% {
            top: -325px;
            opacity: 0;
        }
        56% {
            top: -325px;
            opacity: 0;
        }
        60% {
            top: 0px;
            opacity: 1;
        }
        64% {
            top: 0px;
            opacity: 1;
        }
        76% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        80% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
        81% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
        100% {
            top: -325px;
            opacity: 0;
            z-index: -1;
        }
    }

    @keyframes cycle5 {
        0% {
            top: -325px;
            opacity: 0;
        }
        76% {
            top: -325px;
            opacity: 0;
        }
        80% {
            top: 0px;
            opacity: 1;
        }
        84% {
            top: 0px;
            opacity: 1;
        }
        96% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        100% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
    }

    @keyframes cycle6 {
        0% {
            top: -325px;
            opacity: 0;
        }
        76% {
            top: -325px;
            opacity: 0;
        }
        80% {
            top: 0px;
            opacity: 1;
        }
        84% {
            top: 0px;
            opacity: 1;
        }
        96% {
            top: 0px;
            opacity: 1;
            z-index: 0;
        }
        100% {
            top: 325px;
            opacity: 0;
            z-index: 0;
        }
    }

    .border-gradient {
        border-radius: 25px !important;
        border: 3px solid;
        border-image-slice: 1;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        /*border-width: 1px;*/
        /*-webkit-border-radius: 14px;*/
        /*-moz-border-radius: 14px;*/

    }

    .border-gradient-picture {
        border-radius: 15px !important;
        /*border: 3px solid;*/
        border-image-slice: 1;
        font-size: 15px;
        text-align: center;
        /*border-width: 1px;*/
        /*-webkit-border-radius: 14px;*/
        /*-moz-border-radius: 14px;*/

    }

    .border-gradient-green {
        border-image-source: linear-gradient(to right, #F3E5E5 0%, #CF1512 100%);
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .carousel {
        /*position: relative;*/
        /*width: 100%;*/
        /*text-align: center;*/
        font-size: 15px;
        line-height: 45px;
        /*height: 45px;*/
    }

    .carousel-subtitle {
        font-size: 24px;
        font-weight: bold;
        background: #F3E5E5;
        background: linear-gradient(to right, #F3E5E5 0%, #CF1512 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .text-gradient {
        font-size: 26px;
        background: -webkit-linear-gradient(#eee, #c23e3e);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .text-gradient-2 {
        font-size: 32px;
        background: -webkit-linear-gradient(#eee, #CF1512);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>
