<template>
    <div>
        <div class=" text-center px-5" >
            <v-row class=" text-center">
                <v-col class=" ">
                    <div class="" style="">
                        <div class="py-13"></div>
                    </div>

                    <div class="reveal">
                        <div class="box-shadow-for-cards hidden-lg-and-up" style="border-radius: 7px">
                            <!--                        <div class="py-15"></div>-->
                            <div class="py-4"></div>
                            <div style="">
                                <img style="max-width: 60px" src="../../../../assets/teacherStudentSearch.png" alt="Match Teachers and Students As Wanted">
                            </div>
                            <div class="py-4"></div>
                            <div class="px-7" style="font-size: 13px;  font-family: S-CoreDream-5Medium">
                                나에게 맞는 선생님 추천으로 체계적인 1:1 학습 제공
                            </div>
                            <!--                        <div style="font-size: 14px; color: #7a7a90">-->
                            <!--                            2007년 온라인 영어 <br/>-->
                            <!--                            5개 부문-->
                            <!--                        </div>-->
                            <div class="py-4"></div>
                        </div>
                        <div class="center-vertical box-shadow-for-cards-desktop hidden-md-and-down" style="border-radius: 7px">
                            <!--                        <div class="py-15"></div>-->
                            <div class="py-4"></div>
                            <div style="">
                                <img style="max-width: 100px" src="../../../../assets/teacherStudentSearch.png" alt="Match Teachers and Students As Wanted">
                            </div>
                            <div class="py-3"></div>
                            <div class="px-7" style="font-size: 15px;  font-family: S-CoreDream-5Medium">
                                나에게 맞는 선생님 추천으로 체계적인 1:1 학습 제공
                            </div>
                            <!--                        <div style="font-size: 14px; color: #7a7a90">-->
                            <!--                            2007년 온라인 영어 <br/>-->
                            <!--                            5개 부문-->
                            <!--                        </div>-->
                            <div class="py-4"></div>
                        </div>

                    </div>
                    <div class="py-3"></div>
                    <div class="reveal">

                        <div class="box-shadow-for-cards hidden-lg-and-up" style=" border-radius: 7px">
                            <div class="py-4"></div>
                            <div style="">
                                <img style="width: 60px" src="../../../../assets/share.png" alt="Teachers share their knowledge and experience">
                            </div>
                            <div class="py-4"></div>
                            <div class="px-7" style="font-size: 13px; font-family: S-CoreDream-5Medium">
                                미국 대학 입시에 필요한 확실하고 검증된 정보 공유
                            </div>

                            <div class="py-4"></div>
                        </div>
                        <div class="center-vertical box-shadow-for-cards-desktop hidden-md-and-down" style=" border-radius: 7px">
                            <div class="py-5"></div>
                            <div style="">
                                <img style="width: 100px" src="../../../../assets/share.png" alt="Teachers share their knowledge and experience">
                            </div>
                            <div class="py-5"></div>
                            <div class="px-7" style="font-size: 15px; font-family: S-CoreDream-5Medium">
                                미국 대학 입시에 필요한 확실하고 검증된 정보 공유
                            </div>

                            <div class="py-4"></div>
                        </div>

                    </div>
                </v-col>
                <v-col>
                    <div class="reveal" >

                        <div class="box-shadow-for-cards hidden-lg-and-up" style=" border-radius: 7px">
                            <div class="py-4"></div>
                            <div style="">
                                <img style="max-width: 60px" src="../../../../assets/native.png" alt="Teachers are native">
                            </div>
                            <div class="py-4"></div>
                            <div class="px-7" style="font-size: 13px; font-family: S-CoreDream-5Medium">
                                원어민 수준의 영어로 SAT, ACT, AP, IB 유학 과목 완벽 대비
                            </div>
                            <div class="py-4"></div>
                        </div>
                        <div class="center-vertical box-shadow-for-cards-desktop hidden-md-and-down" style=" border-radius: 7px">
                            <div class="py-6"></div>
                            <div style="">
                                <img style="max-width: 100px" src="../../../../assets/native.png" alt="Teachers are native">
                            </div>
                            <div class="py-4"></div>
                            <div class="px-7" style="font-size: 15px; font-family: S-CoreDream-5Medium">
                                원어민 수준의 영어로 SAT, ACT, AP, IB 유학 과목 완벽 대비
                            </div>
                            <div class="py-4"></div>
                        </div>

                    </div>
                    <div class="reveal" >
                        <div class="py-3"></div>
                        <div class="box-shadow-for-cards hidden-lg-and-up" style=" border-radius: 7px">
                            <div class="py-4"></div>
                            <div style="">
                                <img style="max-width: 60px" src="../../../../assets/teacher3d.png" alt="Guaranteed teachers">
                            </div>
                            <div class="py-4"></div>
                            <div class="px-7" style="font-size: 13px; font-family: S-CoreDream-5Medium">
                                실력이 보장된 세계 최고 명문대 출신으로만 구성된 선생님
                            </div>
                            <div class="py-4"></div>
                        </div>
                        <div class="center-vertical box-shadow-for-cards-desktop hidden-md-and-down" style=" border-radius: 7px">
                            <div class="py-4"></div>
                            <div style="">
                                <img style="max-width: 100px" src="../../../../assets/teacher3d.png" alt="Guaranteed teachers">
                            </div>
                            <div class="py-3"></div>
                            <div class="px-7" style="font-size: 15px; font-family: S-CoreDream-5Medium">
                                실력이 보장된 세계 최고 명문대 출신으로만 구성된 선생님
                            </div>
                            <div class="py-4"></div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>

        <div class="py-10"></div>
    </div>

</template>

<script>
    export default {
        name: "GradesMustGoUp2",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>


    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }

    .box-shadow-for-cards-desktop{
        border-radius: 50px;
        height: 300px;
        width: 250px;
        /*width: 80%;*/
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }
    .box-shadow-for-cards {
        /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
        /*box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;*/
        /*box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;*/
        /*box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;*/
        /*box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;*/
        /*box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;*/
        border-radius: 50px;
        height: 240px;
        width: 150px;
        /*width: 80%;*/
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>

