<template>
    <div class="pa-5 my-16" style="border-radius: 8px">
        <div class="py-10"></div>
        <div style="font-size: 24px; font-weight: bold; margin-bottom: 10px;">상세 커리큘럼</div>
        <div style="color: #6E767C; font-size: 18px; margin-bottom: 20px;">
            아이비리그 Computer Science 전공 <br/> 재학생 선배들이 인정한 커리큘럼
        </div>
        <div style="font-size: 18px;">
            <v-icon color="red" size="18">mdi-check</v-icon> <span style="margin-right: 15px;">8주 과정</span>
            <v-icon color="red" size="18">mdi-check</v-icon> <span style="margin-right: 15px;">언제든 참여 가능</span>
        </div>
        <div class="py-2"></div>
        <table class="curriculum-table">
            <thead>
            <tr>
                <th>단원</th>
                <th>내용</th>
            </tr>
            </thead>
            <tbody>
            <!-- Big Idea 1 -->
            <tr>
                <td>Creative Development</td>
                <td>
                    <ul style="list-style: none;display: flex;flex-direction: column;align-items: start">
                        <li>Creativity in Computer Science</li>
                        <li>Computing Innovations</li>
                        <li>Collaboration</li>
                        <li>Development Process</li>
                    </ul>
                </td>
            </tr>
            <!-- Big Idea 2 -->
            <tr>
                <td>Data</td>
                <td>
                    <ul style="list-style: none;display: flex;flex-direction: column;align-items: start">
                        <li>Number Systems</li>
                        <li>Binary Number Conversions</li>
                        <li>How Binary Numbers Can Be Interpreted</li>
                        <li>Data Compression: Lossless and Lossy Processing</li>
                        <li>Data to Get Information Metadata</li>
                    </ul>
                </td>
            </tr>
            <!-- Big Idea 3 -->
            <tr>
                <td>Algorithms and Programming</td>
                <td>
                    <ul style="list-style: none;display: flex;flex-direction: column;align-items: start">
                        <li>Algorithms: The Building Blocks of Programming</li>
                        <li>Foundations of Computer Programming</li>
                        <li>Data Types</li>
                        <li>Program Statements</li>
                        <li>Combining Algorithms Lists</li>
                        <li>Searching</li>
                        <li>Procedures Simulations</li>
                        <li>Analyzing Algorithms</li>
                    </ul>
                </td>
            </tr>
            <!-- Big Idea 4 -->
            <tr>
                <td>Computing Systems and Networks</td>
                <td>
                    <ul style="list-style: none;display: flex;flex-direction: column;align-items: start">
                        <li>Computing Systems</li>
                        <li>How the Internet Works</li>
                        <li>Types of Processing</li>
                    </ul>
                </td>
            </tr>
            <!-- Big Idea 5 -->
            <tr>
                <td>Impact of Computing</td>
                <td>
                    <ul style="list-style: none;display: flex;flex-direction: column;align-items: start">
                        <li>New Ways to Communicate and Interact</li>
                        <li>Access to Information</li>
                        <li>Legal and Ethical Concerns</li>
                        <li>Personally Identifiable Information (PII)</li>
                        <li>Protecting Our Data</li>
                    </ul>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "APCSPCurriculum"
    }
</script>

<style scoped>

    .curriculum-table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #ccc;
        margin-top: 20px;
    }

    .curriculum-table th, .curriculum-table td {
        border: 1px solid #ccc;
        padding: 10px;
        font-size: 16px;
    }

    .curriculum-table th {
        background-color: #f0f0f0;
        font-weight: bold;
        text-align: center;
    }

    ul {
        list-style-type: disc;
        margin-left: 20px;
    }
</style>
