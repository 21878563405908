<template>
    <div>
        <div v-show="!loading">
            <div class="py-7"></div>
            <div class="text-center px-5">☑️ 오른쪽 화살표를 클릭하여 학생 별 수업일지를 확인하세요!</div>
            <div class="py-5"></div>
            <v-expansion-panels class="no-elevation" flat style="box-shadow: none !important;">
                <v-expansion-panel v-for="(eachLesson,i) in sortedLessonContent" :key="i" class="no-elevation"
                                   style="box-shadow: none !important;">

                    <div class="px-2 pt-2" style="box-shadow: none !important;" v-if="eachLesson.status != 'CANCELLED'">
                        <v-expansion-panel-header class=" pa-3 " style="background-color: #e0e0e0">
                            <div class="text-center">
                                {{eachLesson.studentName}} - {{eachLesson.studentEmail}}
                            </div>
                        </v-expansion-panel-header>

                        <div>
                            <div v-if="eachLesson.reportLink" class="pa-3">
                                <v-row>
                                    <v-col cols="3" class="font-weight-bold text-center">과거 <br/> 수업일지</v-col>
                                    <v-col cols="8">
                                        <v-btn class="center-vertical text-center " outlined target="_blank"
                                               color="black" text
                                               :href="eachLesson.reportLink"> 바로가기
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <!--                        <div class="pa-3">-->
                            <!--                            <v-row>-->
                            <!--                                <v-col cols="3" class="text-center font-weight-bold">과거<br/> 수업일지</v-col>-->
                            <!--                                <v-col cols="8" class="center-vertical text-center"><v-btn text outlined target="_blank" :href="eachLesson.reportLink">바로가기</v-btn></v-col>-->
                            <!--                            </v-row>-->
                            <!--                        </div>-->
                            <v-expansion-panel-content
                                    style=" margin-left: -25px !important; margin-right: -25px !important; padding: 0 !important; box-shadow: none !important"
                                    class=" "
                                    v-for="(eachClass, i) in sortedClasses(eachLesson.classes)" :key="i">
                                <div class="">
                                    <div class="text-center pa-2" style="background-color: #f2f2f2">
                                        ({{eachClass.timezone}})
<!--                                        &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->
<!--                                        {{eachClass}}-->
<!--                                        &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->
<!--                                        {{eachClass.classTimeAt}} ~ {{eachClass.classEndedAt}}-->
<!--                                        &#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->
                                        {{addHours(eachClass.classTimeAt)}} ~ {{addHours(eachClass.classEndedAt)}}
                                    </div>
                                    <v-row class="pa-3">
                                        <v-col cols="3" class="font-weight-bold">수업 정산</v-col>
                                        <v-col style=" word-wrap: break-word; color: darkred" class="font-weight-bold"
                                               cols="8" v-if="eachClass.status == 'FINISHED'">정산 전
                                        </v-col>
                                        <v-col style=" word-wrap: break-word; color: darkgreen" class="font-weight-bold"
                                               cols="8" v-if="eachClass.status == 'PAID'">정산 완료
                                        </v-col>
                                        <!--                            <v-col style=" word-wrap: break-word;" cols="8" >{{eachClass.status}}</v-col>-->
                                    </v-row>
                                    <v-row class="pa-3">
                                        <v-col cols="3" class="font-weight-bold">총 수업시간</v-col>
                                        <v-col style=" word-wrap: break-word;" cols="8">{{eachClass.numberOfHours}}시간 진행
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row class="pa-3">
                                        <v-col cols="3" class="font-weight-bold">수업 점수</v-col>
                                        <v-col style=" word-wrap: break-word;" cols="8">{{eachClass.lessonScore}}/3점
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>

                                    <v-row class="pa-3">
                                        <v-col cols="3" class="font-weight-bold">진행 내용 <br/>및<br/> 이해도</v-col>
                                        <v-col style=" word-wrap: break-word;" cols="8">{{eachClass.classReport}}
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row class="pa-3">
                                        <v-col cols="3" class="font-weight-bold">숙제</v-col>
                                        <v-col style=" word-wrap: break-word;" cols="8">{{eachClass.homework}}</v-col>
                                    </v-row>
                                    <!--                <v-divider class="mb-3"></v-divider>-->
                                </div>
                            </v-expansion-panel-content>
                        </div>
                    </div>
                </v-expansion-panel>

                <div class="my-15"></div>
            </v-expansion-panels>
            <div class="py-5"></div>
        </div>
        <div v-show="loading" class="center">
            <LoadingAnimation
                    :size="60"
                    :width="7"
                    color="primary"
                    indeterminate
            ></LoadingAnimation>
        </div>


    </div>
</template>

<script>

    import LessonService from '../../services/lesson.service'
    import LoadingAnimation from "../helpers/LoadingAnimation";
    import moment from 'moment-timezone';

    export default {
        name: "TeacherClassReports",
        components: {LoadingAnimation},
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            sortedLessonContent() {
                return this.lessonContent.slice().sort((a, b) => {
                    return a.id - b.id;
                });
            },
            sortedClasses() {
                return (classes) => {
                    if (!Array.isArray(classes)) {
                        return [];
                    }
                    const filteredClasses = classes.filter((c) => {
                        const classTime = new Date(c.classTimeAt);
                        const targetDate = new Date('2023-05-13');
                        return classTime > targetDate;
                    });
                    return filteredClasses.slice().sort((a, b) => {
                        return a.classTimeAt.localeCompare(b.classTimeAt);
                    });
                };
            }
        },
        mounted() {
            this.loading = true;
            LessonService.getLessonInfoByTeacherId(this.currentUser.id).then(
                (response) => {
                    this.lessonContent = response.data;
                    this.loading= false;
                },
                (error) => {
                    this.lessonContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data() {
            return {
                lessonContent: [],
                loading: false,
            }
        },
        methods: {
            // convertToKST(timezone, timestamp) {
            //     const kstMoment = moment.tz(timestamp, 'YYYY-MM-DD HH:mm:ss.SSS', timezone).tz('Asia/Seoul');
            //     return kstMoment.format('YYYY-MM-DD HH:mm:ss');
            // }
            convertToKST(timezone, timestamp) {
                let timezoneMapping = {
                    KST: 'Asia/Seoul',
                    PST: 'America/Los_Angeles',
                    EST: 'America/New_York',
                    CET: 'Europe/Paris',
                    SGT: 'Asia/Singapore'
                };

                // console.log("-----------")
                // console.log(timezone)
                // console.log(this.addHours(timestamp))
                // console.log(timestamp)
                // console.log("-----------")

                const kstMoment = moment.tz(timestamp, 'YYYY-MM-DD HH:mm:ss.SSS', timezoneMapping[timezone]);
                return kstMoment.format('YYYY-MM-DD HH:mm:ss');
            },
            addHours(timestamp) {
                const date = new Date(timestamp);
                date.setHours(date.getHours() + 9);

                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');

                const updatedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                return updatedTimestamp;
            }

        }

    }
</script>

<style scoped>
    .no-elevation .v-expansion-panel,
    .no-elevation .v-expansion-panel__container {
        box-shadow: none !important;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .center {
        padding: 300px 0;
        /*border: 3px solid green;*/
        text-align: center;
    }
</style>
