<template>
    <div>
        <div class="container reveal">

            <div class="text-container">
                <div style="background-color: #ffffff; padding-top: 10px">
                    <div style="font-size: 18px; line-height: 35px; font-weight: bold"
                         class="hidden-lg-and-up text-center px-5">
                        실시간으로 선생님, 학생이 필기가 가능해서 효과적인 학습을 할 수 있어요
                        <!--                            <span class="underlined underline-overflow">맞춤 1:1 수업</span>을 받아보세요!-->
                    </div>
                    <div style="font-size: 22px; line-height: 35px; font-weight: bold;"
                         class="hidden-md-and-down text-center px-5">
                        실시간으로 선생님, 학생이 필기가 가능해서 효과적인 학습을 할 수 있어요
                        <!--                            <span class="underlined underline-overflow">맞춤 1:1 수업</span>을 받아보세요!-->
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "AnywhereAlways6",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>


    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        padding-bottom: -10px !important; /* Remove padding from the section element */
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        /*margin-top: 10px;*/
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        /*margin-bottom: 10px;*/
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }

    .fancy {
        color: #6202ED;

    ::after {
        content: '';
        position: absolute;
        bottom: -0.125rem;
        left: -0.5rem;
        right: -0.5rem;
        height: 0.75rem;
        z-index: -1;
        background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/664131/underline.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    }
    /*.fancy:after {*/
    /*    --deco-height: 0.3125em;*/
    /*    content: "";*/
    /*    position: absolute;*/
    /*    left: 0;*/
    /*    right: 0;*/
    /*    bottom: calc(var(--deco-height) * -0.625);*/
    /*    height: var(--deco-height);*/
    /*    background-image: url("data:image/svg+xml,%3Csvg width='100' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='%2300FDCF' stroke-width='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");*/
    /*    background-size: auto 100%;*/
    /*    background-repeat: round;*/
    /*    background-position: 0em;*/


    /*}*/
    .underlined {
        /*   background: red; */
        position: relative;
    }

    .underline-mask:after {
        content: '';
        position: absolute;
        top: 95%;
        width: 150%;
        aspect-ratio: 3 / 1;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 50%;
        border: 6px solid hsl(280 80% 50%);
        /* Use a conic gradient mask to hide and show the bits you want */
        --spread: 140deg;
        --start: 290deg;
        mask: conic-gradient(from var(--start), white 0 var(--spread), transparent var(--spread));
    }


    .underline-overflow {
        display: inline-block;
        /* A little extra padding and overflow hidden   */
        overflow: hidden;
        padding-bottom: clamp(1rem, 2vmin, 2rem);
    }

    .underline-overflow:after {
        content: '';
        position: absolute;
        top: 100%;
        height: 150%;
        aspect-ratio: 2.5 / 1;
        left: 50%;
        transform: translate(-50%, -10%);
        border-radius: 50%;
        border: 6px solid hsl(10 80% 50%);
    }

    .underline-clip:after {
        content: '';
        position: absolute;
        top: 95%;
        width: 150%;
        aspect-ratio: 3 / 1;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 50%;
        border: 6px solid hsl(130 80% 50%);
        /* Use a clip-path to hide and show the bits you want */
        clip-path: polygon(0 0, 50% 50%, 100% 0);
    }
</style>

