<template>
    <div>
        <section class="title">
            <img :src="server.imgUrl" :alt="server.imgAlt" class="title_img"/>
            <div class="title_description">
                <span>{{server.subheading}}</span>
                <h3>{{server.title}}</h3>
                <p>{{server.description}}</p>
            </div>
            <ul class="title_class_style">
                <li>
                    <span>💻</span>
                    <span>강의 방식</span>
                    <span>{{server.lectureStyle}}</span>
                </li>
                <li v-for="(item, index) in server.course" :key="index">
                    <span>{{ index % 3 === 0 ? '📒' : (index % 3 === 1 ? '📘' : '📕') }}</span>
                    <span>{{item.schedule.courseName}}</span>
                    <span>{{item.schedule.shortDetails}}</span>
                </li>
                <li>
                    <span>⏰</span>
                    <span>과정</span>
                    <span>{{server.program}}</span>
                </li>
            </ul>
            <v-btn to="/bootcamp/register" class="title_btn">참가 신청하기</v-btn>
        </section>
        <v-toolbar
                style="color: rgba(0,0,0,0.5);background-color: white;top: 56px;box-shadow: none;font-weight: 500;border-bottom: 1px solid rgba(0,0,0,0.1);z-index: 99"
                class=" hidden-xl-only hidden-lg-only hidden-md-only hidden-sm-only sticky-toolbar text-center toolbar-style-mobile">
            <v-row style="display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;font-size: 14.5px;">
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center;"
                       cols="" class="hover_nav toolbar-menu"
                       @click="scrollto('lecture-introduction', { behavior: 'smooth' })">
                    강의소개
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="hover_nav toolbar-menu" @click="scrollto('teacher', { behavior: 'smooth' })">
                    선생님
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="toolbar-menu hover_nav" @click="scrollto('curriculum', { behavior: 'smooth' })">
                    커리큘럼
                </v-col>
                <v-col style="place-self: center;white-space: nowrap;display: flex;justify-content: center;align-items: center"
                       cols="" class="toolbar-menu hover_nav" @click="scrollto('review', { behavior: 'smooth' })">
                    후기
                </v-col>
            </v-row>
        </v-toolbar>
        <v-toolbar
                style="color: rgba(0,0,0,0.5);background-color: white;top: 56px;box-shadow: none;font-weight: 500;border-bottom: 1px solid rgba(0,0,0,0.1);z-index: 99"
                class=" hidden-xs-only hidden-xs sticky-toolbar text-center toolbar-style" sticky="" color="#494949">
            <v-row justify="center px-5">
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('lecture-introduction', { behavior: 'smooth' })">
                        강의소개
                    </div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('teacher', { behavior: 'smooth' })">선생님</div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('curriculum', { behavior: 'smooth' })">커리큘럼
                    </div>
                </v-col>
                <v-col cols="" class="hover_nav">
                    <div class="toolbar-menu" @click="scrollto('review', { behavior: 'smooth' })">후기
                    </div>
                </v-col>
            </v-row>
        </v-toolbar>
        <!--        강의 소개-->
        <section id="lecture-introduction" class="description">
            <div class="label_place">
                <div class="label"><span
                        style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">강의소개</span></div>
            </div>

            <h3 class="description_title">아래에 해당한다면 꼭 이 수업을 수강하세요!</h3>
            <div class="description_necessity">
                <div class="description_necessity_wrapper" v-for="(item,index) in server.necessity" :key="index">
                    <span>✔</span>
                    <span>{{item}}</span>
                </div>
            </div>

            <h3 class="description_introduction_title">{{server.introduction.title}}</h3>
            <div class="description_introduction">
                <h4 class="description_introduction_subtitle">{{server.title}}!</h4>
                <div class="description_introduction_items">
                    <span v-for="(item,index) in server.introduction.items" :key="index">{{item}}</span>
                </div>
            </div>

            <h3 class="description_introduction_title">{{server.title}}<br/>부트캠프란?</h3>
            <div class="description_introduction">
                <h4 class="description_introduction_subtitle">정예 참가자로만 구성</h4>
                <div class="description_introduction_items">
                    <span>- 아직도 대규모 특강에 현혹되고 계신가요?</span>
                    <span>- 아이비에드 {{server.title}} 부트캠프에서는 시험기간까지 기초부터 탄탄하게 잡아주는 최고의 쌍방향 소통 부트캠프입니다.</span>
                    <span>- 단순한 지식 전달에 그치지 않습니다. 계속해서 질문하고 성장할 수 있게 실시간으로 교육합니다.</span>
                    <span>- 부트캠프 진행기간 동안 학교 과제까지 직접 도움받으며 실력을 쌓을 수 있습니다.</span>
                    <span>- 최소 4주간 수업과 실습으로 자신만의 실력을 갈고 닦으며 경험이 풍부한 선생님들께 도움을 받아 학생의 꿈을 보다 더 넓은 미래로 이끌어 보세요.</span>
                </div>
            </div>

            <h3>왜 이 수업을 들어야하나요?</h3>
            <ul class="description_whyTakeThisCourse">
                <li class="description_whyTakeThisCourse_list" v-for="(item,index) in server.whyTakeThisCourse"
                    :key="index">
                    <span class="description_whyTakeThisCourse_number">{{index + 1}}</span>
                    <span class="description_whyTakeThisCourse_title">{{item.title}}</span>
                    <span></span>
                    <p class="description_whyTakeThisCourse_sub">
                        <span v-for="(info, infoIndex) in item.info" :key="infoIndex">{{info}}</span>
                    </p>
                </li>
            </ul>

            <h3>이 수업을 듣고나면</h3>
            <ul class="description_whyTakeThisCourse">
                <li class="description_whyTakeThisCourse_list" v-for="(item,index) in server.afterTakingCourse"
                    :key="index">
                    <span class="description_whyTakeThisCourse_number">{{index + 1}}</span>
                    <span class="description_whyTakeThisCourse_title">{{item.title}}</span>
                    <span></span>
                    <p class="description_whyTakeThisCourse_sub">
                        <span v-for="(info, infoIndex) in item.info" :key="infoIndex">{{info}}</span>
                    </p>
                </li>
            </ul>

        </section>

        <!--        선생님 소개-->
        <section id="teacher" class="teacher">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">선생님</span>
                </div>
            </div>

            <div class="teacher_description">
                <img :src="server.teacher.imgUrl"
                     class="teacher_img"
                     alt="IVYED 담당 선생님 이미지">
                <div style="display: flex;flex-direction: column;justify-content: space-between;padding:0px 20px;">
                    <h5 class="teacher_description_name">{{server.teacher.name}} 선생님</h5>
                    <img :src="server.teacher.schoolLogoImgUrl" class="teacher_school_img" alt="IVYED 담당 선생님 학교 이미지"/>
                    <div v-for="(item,index) in server.teacher.experience" :key="index" class="teacher_info">{{item}}
                    </div>
                </div>
            </div>

            <div class="teacher_about" v-html="server.teacher.description.split('.').join('.<br /><br />')"/>
            <h3 style="margin-top: 30px">수업 미리보기</h3>
            <iframe style="margin: 30px 0px;max-width: 560px" width="100%" height="330"
                    :src="server.teacher.videoUrl" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
        </section>

        <!--        커리큘럼-->
        <section id="curriculum" class="curriculum">
            <div class="label_place">
                <div class="label"><span
                        style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">커리큘럼</span></div>
            </div>

            <div v-for="(course, courseIndex) in server.course" :key="courseIndex" class="curriculum_wrapper">

                <h4 v-if="course.curriculum.length > 0" style="margin: 20px 0px">{{course.schedule.courseName}}</h4>
                <div v-if="course.objectives.english" class="curriculum_description_info">
                    <span style="margin: 10px 0px">Course Objectives (수업목표)</span>
                    <ul style="margin-bottom: 30px">
                        <li v-for="(koreanItem, index) in course.objectives.korean" :key="index">
                            {{koreanItem}}
                        </li>
                    </ul>
                    <ul v-for="(englishItem, index) in course.objectives.english" :key="index">
                        <li>{{englishItem}}</li>
                    </ul>
                </div>

                <div v-if="course.curriculum.length > 0" class="curriculum_container">
                    <div v-for="(curriculum, index) in course.curriculum.filter(item => item.name !== '')" :key="index" class="curriculum_list_week">
                        <div
                             style="display: flex;justify-content: space-between;align-items: center;margin: 8px 0px;">
                            <span style="font-size: 15px;font-weight: bolder">{{curriculum.name}}</span>
                        </div>
                        <ul v-if="showList[courseIndex] && showList[courseIndex][index]"
                            style="background-color: #F6F9FA;border-radius: 5px;margin-top: 10px;display: flex;flex-direction: column;align-items: start;padding: 20px 15px;"
                            class="curriculum_study_list">
                            <li v-for="(content,index) in curriculum.item.content" :key="index">{{content}}</li>
                            <li v-if="curriculum.item.homework && curriculum.item.homework.length > 0"
                                style="width: 100%;border-bottom: 1px solid rgba(0,0,0,0.2)"/>
                            <li v-if="curriculum.item.homework && curriculum.item.homework.length > 0"
                                style="font-size: 12px;font-weight: bolder">
                                Reading/Assignments
                            </li>
                            <div v-if="curriculum.item.homework">
                                <li v-for="(homework, index) in curriculum.item.homework" :key="index">
                                    {{homework}}
                                </li>
                            </div>

                            <li v-if="curriculum.item.questions && curriculum.item.questions.length > 0"
                                style="font-size: 12px;font-weight: bolder">
                                Questions
                            </li>
                            <div v-if="curriculum.item.questions">
                                <li v-for="(question, index) in curriculum.item.questions" :key="index">
                                    {{question}}
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>

            </div>

            <h3 style="margin-top: 80px">수업 일정</h3>
            <h3 style="margin: 30px 0px;text-align: center">{{server.title}}</h3>
            <div class="curriculum_schedule">
                <div class="curriculum_schedule_gird">
                    <div class="curriculum_schedule_gird_title">
                        <span>과목명</span>
                        <span>요일</span>
                        <span>횟수</span>
                        <span>시간</span>
                    </div>
                    <div v-for="(value,index) in server.course" :key="index" class="curriculum_schedule_gird_content">
                        <span>{{value.schedule.courseName}}</span>
                        <span>{{value.schedule.days}}</span>
                        <span>{{value.schedule.frequency}}</span>
                        <span>{{value.schedule.timezone}}<br/>{{value.schedule.time}}</span>
                    </div>
                </div>
            </div>

        </section>

        <!--        후기-->
        <section id="review" class="review">
            <div class="label_place">
                <div class="label"><span style="font-family: TAEBAEKfont;font-size: 12px;font-weight: bolder">후기</span>
                </div>
            </div>

            <div v-for="(item, index) in server.review" :key="index" class="review_wrapper">
                <h4>{{item.experience}}</h4>
                <img :src="item.personImgUrl" alt="IVYED student img"/>
                <span>{{item.person}}</span>
            </div>
        </section>
        <FloatingBootcampButton background-color="#5c940d"/>
    </div>
</template>
<script>
    import {apbiologyProblemData} from "../../mock/apbiologyproblemData";
    import FloatingBootcampButton from "../bootcamp/FloatingBootcampButton";

    export default {
        components: {FloatingBootcampButton},
        name: 'DefaultTemplate',
        data() {
            // 변수 선언 및 값 할당
            return {
                server: apbiologyProblemData.bootcamp[0].class,
                showList: {},
                // temp : kayoung,
            };
        },
        methods: {
            // 중간 네비게이션 바 값들 클릭하면 스크롤 하는 메서드 1
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            // 중간 네비게이션 바 값들 클릭하면 스크롤 하는 메서드 2
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el.querySelector('.v-window__prev').querySelector('.v-btn').click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el.querySelector('.v-window__next').querySelector('.v-btn').click();
                    this.drag = false;
                    this.touch = false;
                }
            },
            // 날짜 formatting 메서드
            formatDate(datetime) {
                const date = new Date(datetime);
                const months = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
                const formattedDate =
                    // date.getUTCFullYear() + "년 " +
                    months[date.getUTCMonth()] +
                    ' ' +
                    date.getUTCDate() +
                    '일(' +
                    this.getDayOfWeek(date.getUTCDay()) +
                    ') ' +
                    date.getUTCHours() +
                    ':' +
                    (date.getUTCMinutes() < 10 ? '0' : '') +
                    date.getUTCMinutes();

                return formattedDate;
            },
            // 한글로 요일 불러오는 헬퍼 메서드
            getDayOfWeek(day) {
                const days = ['일', '월', '화', '수', '목', '금', '토'];
                return days[day];
            },

            navigateToNewPage() {
                if (!this.store.loggedIn) {
                    this.$router.push('/signin');
                }

                // // 여기서 카트에 넣어야함
            },
            // 커리큘럼 토글
            toggleList(courseIndex, curriculumIndex) {
                if (!this.showList[courseIndex]) {
                    this.$set(this.showList, courseIndex, {});
                }
                this.$set(this.showList[courseIndex], curriculumIndex, !this.showList[courseIndex][curriculumIndex]);
            },
        },
    };
</script>
<style lang="scss" scoped>

    $primary-color: #3498db;
    $accent-color: #5c940d;
    $accent-background: rgba(92,148,13,0.01);
    $accent-number-background: #f4fce3;

    *, html, ul, li {
        margin: 0;
        padding: 0;
        font-family: "Noto Sans KR";
        list-style: none;
    }

    .label {
        z-index: 0;
        width: 65px; /* 변경된 너비 */
        height: 30px; /* 변경된 높이 */
        box-sizing: content-box;
        padding-top: 8px; /* 변경된 패딩 */
        position: relative;
        background: $accent-color;
        color: white;
        font-size: 7px; /* 변경된 글꼴 크기 */
        letter-spacing: 0.15em; /* 변경된 글자 간격 */
        text-align: center;
        text-transform: uppercase;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-bottom: 7px solid #eee; /* 변경된 선 높이 */
            border-left: 30px solid transparent; /* 변경된 왼쪽 삼각형 크기 */
            border-right: 30px solid transparent; /* 변경된 오른쪽 삼각형 크기 */
        }
    }

    .label_place {
        padding-right: 265px;
        padding-bottom: 15px;
    }

    @media (min-width: 600px) {
        .label_place {
            padding-right: 425px;
        }
    }

    .title {
        padding: 45px 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: $accent-background;

        .title_img {
            width: 100%;
            height: 210px;
            max-width: 340px;
            border-radius: 10px;
            object-fit: contain;
        }

        .title_description {
            max-width: 340px;
            margin: 20px 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            span {
                color: $accent-color;
                font-weight: bold;
            }

            h3 {
                font-size: 24px;
                font-weight: 800;
                margin-bottom: 5px;
            }

            p {
                color: rgba(0, 0, 0, 0.7);
                font-size: smaller;
            }
        }

        .title_class_style {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 20px 0px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            li {
                display: grid;
                grid-template-columns: 0.5fr 5fr 4.5fr;
                margin-bottom: 10px;
                gap: 5px;

                span {
                    font-size: small;
                    font-weight: 500;
                }

                span:nth-child(2) {
                    place-self: start;
                }

                span:nth-child(3) {
                    color: $accent-color;
                }

                &:first-child {
                    span:nth-child(3) {
                        color: black;
                    }
                }

            }
        }

        .title_btn {
            background-color: $accent-color !important;
            color: white !important;
            width: 100% !important;
            margin-top: 30px !important;
            padding: 25px 0px !important;
            font-weight: 900 !important;
            box-shadow: none !important;
            font-size: 16px !important;
            max-width: 380px !important;
        }
    }

    .description {
        padding: 45px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .description_title {
            margin-bottom: 50px;
            text-align: center;
        }

        .description_necessity {
            min-width: 320px;
            max-width: 650px;
            margin-bottom: 60px;

            .description_necessity_wrapper {
                min-height: 50px;
                background-color: $accent-background;
                border: 1px solid $accent-color;
                border-radius: 15px;
                padding: 25px 15px;
                margin-bottom: 10px;
                width: 100%;
                font-weight: 600;
                font-size: smaller;
                display: grid;
                grid-template-columns: 1fr 9fr;

                span:first-child {
                    color: $accent-color;
                    place-self: center center;
                }
            }
        }

        .description_introduction_title {
            margin-bottom: 50px;
            text-align: center;
        }

        .description_introduction {
            background-color: $accent-background;
            border: 1px solid $accent-color;
            border-radius: 15px;
            padding: 40px 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: start;
            margin-bottom: 80px;
            max-width: 530px;

            .description_introduction_subtitle {
                font-size: 18px;
                padding: 10px 0px;
                color: $accent-color;
            }

            .description_introduction_items {
                font-size: small;
                color: rgba(0, 0, 0, 0.7);
                line-height: 22px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 70%;

                span {
                    margin: 15px 0px;
                }
            }
        }
    }

    @media (min-width: 800px) {
        .description_introduction{
            min-width: 530px;
        }
        .description_necessity{
            min-width: 530px;
        }
    }
    .description_whyTakeThisCourse {
        margin-top: 25px;
        max-width: 600px;
        margin-bottom: 50px;
    }
    @media (min-width: 800px) {
        .description_whyTakeThisCourse{
            min-width: 600px;
        }
    }

    .description_whyTakeThisCourse_list {
        margin: 25px 0px;
        display: grid;
        grid-template-columns: 1fr 9fr;
    }

    .description_whyTakeThisCourse_number {
        place-self: center center;
        padding: 3px 10px;
        background-color: $accent-number-background;
        color: $accent-color;
        font-weight: bolder;
        border-radius: 5px;
        font-size: small;
        margin-right: 10px;
    }

    .description_whyTakeThisCourse_title {
        font-weight: bolder;
        font-size: small;
    }

    .description_whyTakeThisCourse_sub {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        margin-top: 5px;

        span {
            margin-bottom: 5px;
        }

        span:last-child {
            margin-bottom: 0px;
        }
    }

    .teacher {
        padding: 45px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .teacher_description {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 600px;
    }

    .teacher_description_name {
        font-size: large;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 15px;
    }

    .teacher_about {
        margin-top: 20px;
        background-color: #F2F6F8;
        border-radius: 5px;
        padding: 20px;
        font-size: 15px;
        max-width: 600px;
    }

    .curriculum_schedule {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 600px;
    }

    .curriculum_schedule_gird {
        display: grid;
        grid-template-rows:1.2fr;
        grid-auto-rows: 2fr;
    }

    .curriculum_schedule_gird_title {
        min-height: 50px;
        display: grid;
        grid-template-columns: 1.2fr 1fr 1fr 1.2fr;
        color: white;
        background-color: $accent-color;
        margin-bottom: 10px;
        font-weight: bolder;
        font-size: smaller;

        span {
            place-self: center center;
            text-align: center;
        }

        span:first-child {
            padding-left: 15px;
        }
    }

    .curriculum_schedule_gird_content {
        display: grid;
        grid-template-columns: 1.2fr 1fr 1fr 1.2fr;
        background-color: $accent-background;
        gap: 3px;
        margin-bottom: 10px;
        font-size: 14px;
        color: black;

        span {
            place-self: center center;
            text-align: center;
        }

        span:first-child {
            font-weight: bolder;
            padding-left: 15px;
        }
    }

    .curriculum {
        padding: 45px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .curriculum_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .curriculum_description_info {
        padding: 30px 20px;
        background-color: #F6F9FA;
        font-size: small;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-top: 20px;
        border-radius: 5px;
        max-width: 700px;

        span {
            font-weight: bolder;
            margin-bottom: 5px;
        }

        li {
            margin-bottom: 5px;
        }
    }

    @media (min-width: 1000px) {
        .curriculum_description_info {
            min-width: 700px;
        }
    }

    .review {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 45px 15px;
    }

    .review_wrapper {
        width: 300px;
        background-color: #F2F6F8;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 15px 28px;
        border-radius: 5px;
        margin-bottom: 50px;

        h4 {
            font-size: 15px;
            text-align: center;
            padding: 15px 0px;
        }

        img {
            width: 140px;
            height: 140px;
            border-radius: 5px;
        }

        span {
            font-size: small;
            padding-top: 10px;
        }
    }


    .teacher_info {
        font-size: small;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 5px;
    }

    @media (min-width: 600px) {
        .teacher_info {
            font-size: medium;
        }
    }

    .teacher_school_img {
        width: 100%;
        height: 80px;
        object-fit: contain
    }

    @media (min-width: 600px) {
        .teacher_school_img {
            height: 180px;
        }
    }

    .teacher_img {
        place-self: center center;
        border-radius: 15px;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    @media (min-width: 400px) {
        .teacher_img {
            width: 200px;
            height: 200px;
        }
    }

    @media (min-width: 600px) {
        .teacher_img {
            width: 300px;
            height: 300px;
        }
    }

    .curriculum_container {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 20px 25px 10px;
        margin-top: 25px;
        min-width: 350px;
    }

    @media (min-width: 380px) {
        .curriculum_container {
            min-width: 380px;
        }
    }

    @media (min-width: 400px) {
        .curriculum_container {
            min-width: 400px;
        }
    }

    @media (min-width: 600px) {
        .curriculum_container {
            min-width: 600px;
        }
    }

    @media (min-width: 700px) {
        .curriculum_container {
            min-width: 850px;
        }
    }

    .curriculum_list_week:last-child {
        border-bottom: none;
    }

    .curriculum_list_week {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-top: 8px;
        width: 100%;
        padding: 5px 5px;
    }


    .hover_nav {
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        cursor: pointer;
    }

    .hover_nav:hover {
        background-color: #E8344E;
        color: white;
    }


    .curriculum_description li {
        border-radius: 5px;
        border: 1px solid #452DDD;
        padding: 30px;
    }

    .curriculum_click {
        background-color: #452DDD !important;
    }

    .curriculum_study_list li {
        font-size: small;
        color: rgba(0, 0, 0, 0.6);
        padding: 5px 5px;
    }

    .curriculum_list li {
        padding: 10px 20px;
        border-radius: 20px;
        color: white;
        background-color: #D8D3F8;
        font-weight: bolder;
        font-size: medium;
        cursor: pointer;
    }

    .target_benefit li span {
        font-size: small;
    }

    .target_benefit li h2 {
        color: #452DDD;
    }

    .target_benefit li {
        padding: 20px;
        background-color: white;
        margin-bottom: 20px;
        border-radius: 5px;
    }

    .target_list li > span {
        padding: 0px 15px;
    }

    .target_list li {
        background-color: #F3F2FD;
        font-size: 16px;
        font-weight: bolder;
        max-width: 320px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .introduction_list li {
        background-color: white;
        border-radius: 15px;
        margin: 0px 10px;
        padding: 2px 10px;
    }


    .sticky {
        background: blue;
        height: 50px;
        position: sticky;
        top: 0;
    }

    .toolbar-style-mobile {
        font-size: 14px;
        font-weight: bold;
        position: sticky;
        top: 40px;
    }

    .toolbar-style {
        font-family: S-CoreDream-6Bold;
        font-size: 18px;
        font-weight: bold;
    }

    .sticky-toolbar {
        color: white;
        z-index: 0;
        top: 64px;
        position: sticky;
        position: -webkit-sticky;
    }

</style>