<template>
    <div style="overflow: hidden; width: 100%">
        <div class="pa-8 second-review-section">
            <ReviewPage1></ReviewPage1>
            <div class="py-2"></div>
            <ReviewPage2></ReviewPage2>
            <div class="py-15"></div>
        </div>
        <div class="third-review-section" style="padding-top: 80px; padding-bottom: 80px">
            <div class="py-4"></div>
            <!--            <div class="px-5 text-center" style="color: #DE2828; font-family: HallymGothic-Regular; font-size: 15px">-->
            <!--                뭘 해도 힘들었던 공부, 아이비에드가 해결해줬어요!-->
            <!--            </div>-->
            <ReviewPage3></ReviewPage3>
            <div class="py-1"></div>
            <!--            <div class="black&#45;&#45;text text-center font-weight-bold" style="font-size: 32px;"> 아이비에드 <br/> Best & Real 후기-->
            <!--            </div>-->
            <div class="py-2"></div>
            <ReviewPage4></ReviewPage4>
            <div class="py-2" style="width: 100%"></div>
            <ReviewPage5></ReviewPage5>
            <ReviewPage6></ReviewPage6>
            <ReviewPage7></ReviewPage7>
            <div class="my-6"></div>
        </div>
        <div class="fourth-review-section  text-center" style="background-color: #f6f6f6">
            <div class="py-10"></div>

            <div>
                <ReviewPage8></ReviewPage8>
                <div class="py-2"></div>
                <ReviewPage9></ReviewPage9>
                <div class="py-5"></div>
            </div>
        </div>
        <div class="hidden-lg-and-up text-center" style="background-color: #f6f6f6">
            <div>
                <div class="ml-1 center-horizontal">
                    <!--                    <span class="black--text center-vertical pa-5">과목</span>-->
                    <v-chip-group
                            mandatory
                            class=""
                            column
                            show-arrows
                    >
                        <div>
                            <v-chip

                                    label
                                    style="height: 40px; width: 95px; padding-left: 33px; "
                                    class="rounded-xl "
                                    @click="this.showAllReviews"
                                    :outlined="selectedTab !== 'all'"
                                    color="primary"
                            >
                                전체
                            </v-chip>
                            <v-chip
                                    label
                                    style="height: 40px; width: 95px; padding-left: 35px"
                                    :outlined="selectedTab !== 'sat'"

                                    @click="this.showSATOnly"
                                    color="primary"
                                    class="rounded-xl "
                            >
                                SAT
                            </v-chip>
                            <v-chip
                                    :outlined="selectedTab !== 'act'"

                                    style="height: 40px; width: 95px; padding-left: 34px"

                                    class="rounded-xl "
                                    @click="this.showActReview"


                                    color="primary">
                                ACT
                            </v-chip>
                            <v-chip
                                    label
                                    style="height: 40px; width: 95px; padding-left: 37px;"
                                    :outlined="selectedTab !== 'ap'"


                                    class="rounded-xl "

                                    @click="this.showAPOnly"

                                    color="primary">
                                AP
                            </v-chip>
                            <v-chip
                                    label
                                    :outlined="selectedTab !== 'ib'"


                                    class="rounded-xl pl-10"
                                    style="height: 40px; width: 95px"

                                    @click="this.showIBOnly"

                                    color="primary">
                                IB
                            </v-chip>
                            <v-chip
                                    label
                                    :outlined="selectedTab !== 'gpa'"


                                    style="height: 40px; width: 95px; padding-left: 22px"

                                    class="rounded-xl"
                                    @click="this.showGpaOnly"

                                    color="primary">
                                내신관리
                            </v-chip>
                            <v-chip
                                    label
                                    style="height: 40px; width: 95px"
                                    :outlined="selectedTab !== 'essay'"
                                    class="rounded-xl pl-7"
                                    @click="this.showEssayOnly"

                                    color="primary">
                                Essay
                            </v-chip>
                        </div>
                    </v-chip-group>
                </div>
                <div class="py-4"></div>


                <div style="background-color: #f6f6f6" class="center-horizontal pt-4">
                    <div style="">
                        <div v-if="!allReviews">
                            <div class=" px-4 pt-4 ma-4" v-if=" this.essayReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black; "
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium"> English (Writing)
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">미국 유학생활 2년 차인데 아직도
                                        영어실력이
                                        부족해서
                                        고생중입니다. 그러다가 유학 관련 과외를 찾아봤었는데 그러다가 알게 된 곳이 바로 아이비에드였습니다. 여기서 제 영어 writing이나 전반적인
                                        영어
                                        과목에
                                        대한 상담 신청을 하니 친절하게 전화상담을 해주셨고 저의 상황에 맞는 선생님을 매칭해 주셨습니다. 무엇보다 화상수업이어서 유동적으로 스케줄을
                                        조절 할
                                        수
                                        있었고 코로나 상관없이 고정적으로 수업을 들을 수 있어서 좋았습니다. 실제로 수업을 들어보니 어려운 개념도 쉽게 설명해 주셔서 이해도 잘되고
                                        진도도
                                        혼자
                                        할때보다 확실히 많이 나갈 수 있었습니다. 덕분에 시험 준비하는데 큰 도움이 되고 있습니다.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.satReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black; "
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium"> SAT Reading
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                        긴 문장을 읽고 핵심 아이디어를 파악하는 기술과 어려운 어휘를 이해하는 방법을 배우는 과정에서 점차 능력이 향상 됐어요! SAT Reading
                                        너무 처음에 자신이 없어 힘들었는데 선생님이 차분하게 설명해주시고 격려해주셔서 자신감 얻고 원하는 점수 겟 했습니다! 감사합니다🙏
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium"> AP Chemistry
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP Chemistry 과외후기입니다.
                                        저는 현재
                                        미국
                                        고등학교 재학중이고 코로나때문에 학교수업도 온라인으로 진행되고있어서 혼자서 공부하기엔 무리가 있어서 수업을 받게되었습니다. 특히 Lab의
                                        중요성을
                                        강조해주셔서
                                        중요한 부분에 중점적으로 수업을 해주셨고 모르는 문제나 이해안가는부분들을 그때마다 설명해주셔서 너무 좋았어요! 특히 선생님께서 정말 친절하시고
                                        학생입장에서
                                        배려많이해주시는게 느껴져서 좋았습니다 :)
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.satReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium"> SAT (Reading / Math)
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">9학년 끝난 여름방학에 SAT 학원 두달
                                        다녀봤는데
                                        성적이 오르지 않아 상담 받아보게 됐어요. SAT는 절대 두달안에 점수 낼 수 없다고 말씀 해주시고 천천히 1년동안 준비해야 한다고 해서 과외
                                        시작했어요.
                                        1년 동안 매주 단어외우고 문제풀고 모르는거 배우고 하다보니 11학년 들어가는 여름방학때 점수가 1500점이 넘었어요! 너무 감사해요❣️
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.essayReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">Application Essay
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">이번에 Early Decision 지원한
                                        학생이에요.
                                        학교 성적이나 SAT 점수는 자신이 있었는데, Application Essay를 어떻게 써야할지 도저히 감이 안오더라구요. 그래서 아이비에드에서
                                        과외
                                        받기
                                        시작했어요. Application Essay의 목적이나 구조 하나하나 다 배우고 스토리라인 짜는거부터 최종 첨삭까지 도와주셔서 이번에 잘 끝내게
                                        됐어요. 너무
                                        감사합니다!
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.satReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black; "
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium"> SAT Reading
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                        처음에는 온라인 강의 보면서 혼자 공부하려고 했는데 아무래도 한계가 있더라구요. 그러다가 주변에서 SAT과외 추천 받아서 시작했어요. 역시
                                        전문가는 다르더라구요. 제 문제점이랑 보완할 점 정확히 파악하셔서 커리큘럼 짜주시고 수업 방식도 학생에게 맞춰서 해주시니 성적이 오를 수 밖에
                                        없어요.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>

                            <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium"> AP Chemistry
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">작년 이맘때쯤 미국 대학 입시를 앞두고
                                        AP
                                        Chemistry 공부를 하고 있었습니다. 혼자서는 도저히 진도가 나가지 않아서 학원을 알아보던 중 우연히 블로그 글을 보게 되었습니다. 상담을
                                        했는데
                                        다른
                                        곳과 다르다는 것을 확실히 느꼈습니다. 먼저 레벨 테스트를 받았고 예상대로 기초가 부족하다는 진단이 나왔고 저에게 맞는 맞춤형 커리큘럼을
                                        짜주셨습니다.
                                        확실히
                                        커리큘럼을 짜주시는것부터가 다른 곳과 차이가 큽니다.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">AP Psychology
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">2월 중순부터 3월 말까지 진행된 ap
                                        psychology 과외 수업 관련 후기입니다. 온라인 미팅 방식으로 주2회씩 총 8번 이루어진 수업이었어요. 저의 경우 심리학 전공자로서 기본
                                        이론
                                        베이스만
                                        가지고 있던 상황이었습니다. 따라서 전문 강사님께 확실한 개념 정리 및 문제풀이 스킬을 배우고 싶었어요. 실제로 AP 시험 준비 뿐만 아니라
                                        대학교
                                        학점관리
                                        차원에서도 도움이 될만한 강의라고 생각했기 때문이죠. 다행히도 선생님께서 커버를 잘 해주셨고 덕분에 체계적으로 공부할 수 있었습니다. 만약 학교
                                        수업만으로는
                                        부족함을 느끼시는 분이라면 해당 과목만큼은 과외 수강을 통해 보완하는것을 추천드려요.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.satReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black; "
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium"> SAT Reading
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                        제가 원하던 대학에 합격할 수 있었던 가장 큰 이유는 바로 SAT 시험 준비였어요. 공부해야 하는 과목과 범위가 방대하다 보니 혼자서는 도저히
                                        엄두가 안나더라구요. 처음에는 학원 수업을 들었는데 실력있는 선생님 찾기도 어렵고 내성적인 성격 탓에 질문하기도 어려웠어요. 그러다가
                                        아이비에드를 알게 돼서 퀄리티 높은 수업뿐만 직접 모르는 부분이나 문제 푸는 요령 등을 물어볼 수 있고 일대일 상담 후 맞춤 커리큘럼대로
                                        진행돼서 좋았어요. 무엇보다 모의고사 시스템이 잘 갖춰져있어서 실전 감각 익히기에 도움이 됐어요. 실제로 두 달만에 목표했던 점수 이상 받을 수
                                        있었어요.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>

                            <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">AP Computer Science A
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP 컴퓨터사이언스 과목 시험 준비를 위해
                                        올해
                                        5월부터
                                        온라인과외를 시작했습니다. 솔직히 수학처럼 하면 되겠지 생각했는데 첫 학교 수업부터 멘붕이었습니다. 다행히 과외 선생님이 전공자다 보니 실력이
                                        여태
                                        다른
                                        선생님과는 정말 남달랐어요. 현재 저는 10회차 수업중 7회차 진행중이며 2번의 모의시험도 치뤘습니다. 남은 3회차도 너무 기대 됩니다.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">AP Computer Science A
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP Computer Science A
                                        시험
                                        준비생입니다. 한 번도 접해보지 않은 과목이기에 혼자 공부하기엔 무리가 있을 것 같아 선생님을 찾게 되었습니다. 그러던 중 우연히 블로그 글을
                                        보고
                                        연락드리게
                                        된 쌤! 첫 수업 후 정말 너무너무 만족스러웠습니다. 우선 꼼꼼한 설명 + 깔끔한 정리 자료 그리고 무엇보다 학생과의 소통 능력이 뛰어나신 것
                                        같습니다.
                                        아직 더 수업을 들어봐야 알겠지만 현재로서는 대만족입니다! 앞으로도 잘 부탁드립니다 :)
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">AP Computer Science Principles
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">학원으로 수학/통계학 쪽으로는 많이
                                        배웠지만
                                        컴퓨터
                                        공학쪽으로는 거의 배우지 않아서 걱정이 많았습니다. 그러다가 인터넷 검색을 통해 아이비에드를 알게 되었고 수강 후기 및 샘플 강의를 듣고 나서
                                        신뢰가
                                        가서
                                        신청하게 되었습니다. 일단 다른 수업과는 달리 1:1 맞춤형 수업이라는 점이 마음에 들었습니다. 아무래도 대형강의보다는 개인 과외나 소규모
                                        그룹수업이
                                        좀 더
                                        집중도 잘되고 효율적이지 않을까 싶었거든요. 또 시간대도 다양해서 원하는 시간대로 선택할 수 있다는 점도 좋았습니다. 아무튼 이러한 장점들
                                        덕분에
                                        지금까지
                                        꾸준히 다니고 있습니다. 확실히 전공자에게 직접 지도를 받다 보니 부족한 부분을 빠르게 보완할 수 있었고 단기간에 성적 상승효과를 볼 수
                                        있었습니다.
                                    </div>
                                    <div class="py-2"></div>
                                </div>
                            </div>


                            <div class=" px-4 pt-4 ma-4" v-if=" this.actReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">ACT
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;"> ACT 시험 대비 과외를 받으면서 학습
                                        방법과 시험 전략에 대한 이해도가 크게 향상되었어요. 선생님의 지도 덕분에 어떤 문제를 어떤 순서로 풀어나가야 하는지를 배울 수 있었고, 이것이
                                        실전에서 큰 도움이 되었습니다.
                                        ACT 시험 대비 과외를 받으면서 얻은 가장 큰 이점은 개인 맞춤형 지도를 받을 수 있다는 것이었어요. 선생님은 내 학습 스타일을 고려하여 학습
                                        계획을 세우고, 어려움을 겪을 때마다 도움을 주셨어요. 이러한 지원 덕분에 더 좋은 결과를 얻을 수 있었습니다.

                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>

                            <div class=" px-4 pt-4 ma-4" v-if=" this.actReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">ACT
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;"> ACT 시험 대비 과외를 받으면서 학습
                                        방법과 시험 전략에 대한 이해도가 크게 향상되었어요. 선생님의 지도 덕분에 어떤 문제를 어떤 순서로 풀어나가야 하는지를 배울 수 있었고, 이것이
                                        실전에서 큰 도움이 되었습니다.
                                        ACT 시험의 어려운 부분 중 하나는 빠른 판단력과 분석력이에요. 선생님과 매주 다양한 유형의 문제를 풀어보면서 문제풀이 능력을 키울 수 있도록
                                        도와주셨어요. 처음엔 낯선 문제가 나오면 당황했는데, 계속 문제풀이 하면서 설명 듣다보니 어렵지 않게 느껴지게 됐습니다!

                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>

                            <div class=" px-4 pt-4 ma-4" v-if=" this.actReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">ACT
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;"> ACT 시험 대비 과외를 받으면서 학습
                                        방법과 시험 전략에 대한 이해도가 크게 향상되었어요. 선생님의 지도 덕분에 어떤 문제를 어떤 순서로 풀어나가야 하는지를 배울 수 있었고, 이것이
                                        실전에서 큰 도움이 되었습니다.

                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>

                            <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">AP Calculus
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">미국 유학 준비중인 학생입니다. 현재
                                        GPA
                                        관리하면서
                                        SAT 공부도 병행하고 있는데요. 수학 과목 같은 경우 한국어로도 어려운 미적분학을 영어로 배워야 하는 상황이기 때문에 미리 선행학습을 하지
                                        않으면
                                        수업
                                        진도를 따라가기 어려울 것 같았어요. 그래서 학기 시작하기 전 겨울방학 동안 개념이라도 좀 잡아 놓으려고 학원을 알아보다가 우연히 상담 받아보니
                                        일대일
                                        맞춤형
                                        수업 방식이라서 집중력 있게 배울 수 있을 것 같고 또 실제로 수강했던 학생들의 만족도가 높길래 여기서 배우기로 결정했어요. 한 달정도 다녔는데
                                        확실히
                                        실력이
                                        늘긴 하더라고요. 앞으로도 꾸준히 다니면서 부족한 부분 채워나가야겠습니다.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.ibReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">IB Math HL, 12th grade
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                        IB Math HL 과목은 항상 어려웠는데, 아이비에드의 수업을 통해 개인 맞춤형 학습 계획을 세울 수 있었습니다. 선생님의 지도 덕분에 수학
                                        실력이 크게 향상되었고, 시험에서도 좋은 성적을 얻을 수 있었습니다.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>


                            <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">AP Calculus
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">코로나 이후 비대면 수업을 많이
                                        했었습니다.
                                        하지만
                                        아무리 그래도 대면 수업보다 효과가 떨어지는 건 어쩔 수 없는 일이었어요. 그러던 와중 지난 학기 수학 성적이 너무 낮게 나온 걸 보고 이대로는
                                        안
                                        되겠다는
                                        생각이 들었습니다. 그래서 방학 동안 혼자서라도 공부를 해야겠다는 결심을 했지만 공부중에도 뭔가 제가 잘하고 있는게 맞나 의문이 계속 들었고
                                        제대로 된
                                        피드백을 받고 싶다는 생각이 들었습니다. 그때 마침 엄마에게 아이비에드 과외 선생님을 소개받게 되었고 상담 후 바로 시작하기로 하였다. 다행히
                                        제가
                                        원했던
                                        조건이었던 미국 명문대 출신이었고 학생 지도 경험도 있어서 만족했습니다. 그리고 무엇보다도 다른 학원 강사들처럼 일방적으로 진도만 나가는
                                        스타일이
                                        아니라 나와
                                        소통하면서 진행한다는 점이 마음에 들었습니다. 첫 수업 때 저는 그동안 헷갈렸던 공식 증명 방법 및 간단한 예제 위주로 질문했고 바로 도움이
                                        되었습니다.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.ibReview  || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">IB Chemistry HL
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                        화학은 항상 어려웠던 과목 중 하나였는데, 선생님께서 이론을 친절하게 설명하며 복잡한 개념을 쉽게 이해할 수 있도록 도와주셨습니다. 시험 대비를
                                        위한 전략과 연습문제를 통해 실제 시험에서도 자신감을 가질 수 있었습니다. 아이비에드의 IB Chemistry 수업 덕분에 더 나은 성적을 얻을
                                        수 있었고, 이제 화학에 대한 흥미와 자신감이 한층 더 높아졌습니다.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.ibReview || this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">IB English HL
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                        워낙 약한 과목이었는데 수업하면서 문학과 언어를 깊이 있게 이해하고 분석하는 능력을 키우게 된 것 같아요. 다양한 작품을 해석하고 논의하는
                                        과정에서 비판적 사고가 크게 향상되었고, 작문 능력도 향상시킬 수 있었던 것 같아요! 선생님 지속적인 피드백이 실력을 높이는데 큰 도움이 된 것
                                        같아요!

                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>

                            <div class=" px-4 pt-4 ma-4" v-if=" this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">Geometry
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">미적분보다 오히려 기하학이 어려웠어요.
                                        그래서인지
                                        학원
                                        강의를 한다고 하더라도 수업 방식이 거의 문제풀이 위주여서 제대로 개념을 잡기 힘들었습니다. 그런 상황 속에서 아이비에드에서 첫 수업을
                                        진행했었습니다.
                                        먼저
                                        그동안 공부했던 방법 및 문제점 그리고 앞으로의 방향성에 대해서 이야기를 나눴습니다. 그러고 나서 곧바로 기본개념 설명 후 예제 풀이 순으로
                                        수업을
                                        진행했습니다. 확실히 혼자서 공부했을 때와는 차원이 달랐습니다.  무엇보다도 강사님께서는 단순히 암기식으로 가르치지 않고 원리를
                                        이해시켜주셨습니다.
                                        답을 맞추는 것이 전부가 아니라 무엇을 근거로, 어떤 생각의 흐름으로 이러한 답에 도달했는지를 계속 끊임없이 물어봐 주시고 지도해주셨습니다.
                                        그러다
                                        보니 저도
                                        생각하는 힘이 생겼고 못풀었던 문제에 대해서도 지금은 쉽게 풀 수 있었습니다.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.gpaReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">Algebra
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">칸 아카데미 인강 수강으로 기본 개념을
                                        잡고
                                        문제를 푸는
                                        형태로 공부를 하고 있었는데요. 복습을 해봐도 도저히 답이 나오질 않았습니다. 이대로 포기해야 되나 싶었지만 과외를 시작하고 역시나 혼자 끙끙
                                        앓았던
                                        시간이
                                        아까울 정도로 간단한 문제들이었더라구요.. 순간 허탈해지면서 동시에 안도감이 밀려왔습니다.
                                    </div>
                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.essayReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">English Literature
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">영어 (English Literature)
                                        는
                                        사실
                                        잘하는 사람을 구하기가 매우 힘들어요. 검증하는 방법도 제게 없을 뿐더러 주변에 일단 잘 없습니다. 솔직히 냉정하게 말하자면 주변사람들은 좋은
                                        선생님을
                                        제가
                                        가만히 있으면 잘 추천하지 않아요. 잘하는 분들은 보통 자기 할일이 바쁘거나 첨삭을 해서 지도해주는 것 또한 별개이기 때문인데요. 하지만
                                        아이비에드에서
                                        생각보다 쉽게 실력자 선생님을 소개시켜주었고 정말 마음에 쏙 들어서 계속 지도를 부탁드렸습니다.
                                    </div>

                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                            <div class=" px-4 pt-4 ma-4" v-if=" this.satReview || this.essayReview">
                                <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                     class="rounded-lg">
                                    <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                         class="pl-7 py-4 font-weight-medium">SAT Reading
                                    </div>
                                    <div class="px-4 pl-6" align="left" style="font-size: 14px;">SAT이란 시험에 대해 전체적으로 빠르게
                                        이해하는데
                                        도움을 주셨고 VOCAB  진짜 감동이에요. 정말 신경써서 잘해주셨고 Classical passage랑 American history 특히
                                        힘들었는데 문제
                                        유형이랑 전체적인 역사적 설명을 충분히 해주셔서 좋았습니다.
                                    </div>

                                    <div class="py-2"></div>
                                    <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                    <!--                            <div class="py-2"></div>-->
                                </div>
                            </div>
                        </div>
                        <div v-if="allReviews">
                            <div v-if="pageLocation == 1">
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> English (Writing)
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">미국 유학생활 2년 차인데 아직도
                                            영어실력이
                                            부족해서
                                            고생중입니다. 그러다가 유학 관련 과외를 찾아봤었는데 그러다가 알게 된 곳이 바로 아이비에드였습니다. 여기서 제 영어 writing이나
                                            전반적인
                                            영어
                                            과목에
                                            대한 상담 신청을 하니 친절하게 전화상담을 해주셨고 저의 상황에 맞는 선생님을 매칭해 주셨습니다. 무엇보다 화상수업이어서 유동적으로
                                            스케줄을
                                            조절 할
                                            수
                                            있었고 코로나 상관없이 고정적으로 수업을 들을 수 있어서 좋았습니다. 실제로 수업을 들어보니 어려운 개념도 쉽게 설명해 주셔서 이해도
                                            잘되고
                                            진도도
                                            혼자
                                            할때보다 확실히 많이 나갈 수 있었습니다. 덕분에 시험 준비하는데 큰 도움이 되고 있습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> AP Chemistry
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP Chemistry
                                            과외후기입니다.
                                            저는 현재
                                            미국
                                            고등학교 재학중이고 코로나때문에 학교수업도 온라인으로 진행되고있어서 혼자서 공부하기엔 무리가 있어서 수업을 받게되었습니다. 특히 Lab의
                                            중요성을
                                            강조해주셔서
                                            중요한 부분에 중점적으로 수업을 해주셨고 모르는 문제나 이해안가는부분들을 그때마다 설명해주셔서 너무 좋았어요! 특히 선생님께서 정말
                                            친절하시고
                                            학생입장에서
                                            배려많이해주시는게 느껴져서 좋았습니다 :)
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> SAT (Reading / Math)
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">9학년 끝난 여름방학에 SAT 학원
                                            두달
                                            다녀봤는데
                                            성적이 오르지 않아 상담 받아보게 됐어요. SAT는 절대 두달안에 점수 낼 수 없다고 말씀 해주시고 천천히 1년동안 준비해야 한다고 해서
                                            과외
                                            시작했어요.
                                            1년 동안 매주 단어외우고 문제풀고 모르는거 배우고 하다보니 11학년 들어가는 여름방학때 점수가 1500점이 넘었어요! 너무
                                            감사해요❣️
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                            </div>
                            <div v-if="pageLocation == 2">
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">Application Essay
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">이번에 Early Decision
                                            지원한
                                            학생이에요.
                                            학교 성적이나 SAT 점수는 자신이 있었는데, Application Essay를 어떻게 써야할지 도저히 감이 안오더라구요. 그래서
                                            아이비에드에서
                                            과외
                                            받기
                                            시작했어요. Application Essay의 목적이나 구조 하나하나 다 배우고 스토리라인 짜는거부터 최종 첨삭까지 도와주셔서 이번에 잘
                                            끝내게
                                            됐어요. 너무
                                            감사합니다!
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> AP Chemistry
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">작년 이맘때쯤 미국 대학 입시를
                                            앞두고
                                            AP
                                            Chemistry 공부를 하고 있었습니다. 혼자서는 도저히 진도가 나가지 않아서 학원을 알아보던 중 우연히 블로그 글을 보게 되었습니다.
                                            상담을
                                            했는데
                                            다른
                                            곳과 다르다는 것을 확실히 느꼈습니다. 먼저 레벨 테스트를 받았고 예상대로 기초가 부족하다는 진단이 나왔고 저에게 맞는 맞춤형 커리큘럼을
                                            짜주셨습니다.
                                            확실히
                                            커리큘럼을 짜주시는것부터가 다른 곳과 차이가 큽니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Psychology
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">2월 중순부터 3월 말까지 진행된
                                            ap
                                            psychology 과외 수업 관련 후기입니다. 온라인 미팅 방식으로 주2회씩 총 8번 이루어진 수업이었어요. 저의 경우 심리학
                                            전공자로서 기본
                                            이론
                                            베이스만
                                            가지고 있던 상황이었습니다. 따라서 전문 강사님께 확실한 개념 정리 및 문제풀이 스킬을 배우고 싶었어요. 실제로 AP 시험 준비 뿐만
                                            아니라
                                            대학교
                                            학점관리
                                            차원에서도 도움이 될만한 강의라고 생각했기 때문이죠. 다행히도 선생님께서 커버를 잘 해주셨고 덕분에 체계적으로 공부할 수 있었습니다.
                                            만약 학교
                                            수업만으로는
                                            부족함을 느끼시는 분이라면 해당 과목만큼은 과외 수강을 통해 보완하는것을 추천드려요.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                            </div>
                            <div v-if="pageLocation == 3">
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Computer Science A
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP 컴퓨터사이언스 과목 시험
                                            준비를 위해
                                            올해
                                            5월부터
                                            온라인과외를 시작했습니다. 솔직히 수학처럼 하면 되겠지 생각했는데 첫 학교 수업부터 멘붕이었습니다. 다행히 과외 선생님이 전공자다 보니
                                            실력이
                                            여태
                                            다른
                                            선생님과는 정말 남달랐어요. 현재 저는 10회차 수업중 7회차 진행중이며 2번의 모의시험도 치뤘습니다. 남은 3회차도 너무 기대
                                            됩니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Computer Science A
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP Computer Science
                                            A
                                            시험
                                            준비생입니다. 한 번도 접해보지 않은 과목이기에 혼자 공부하기엔 무리가 있을 것 같아 선생님을 찾게 되었습니다. 그러던 중 우연히 블로그
                                            글을
                                            보고
                                            연락드리게
                                            된 쌤! 첫 수업 후 정말 너무너무 만족스러웠습니다. 우선 꼼꼼한 설명 + 깔끔한 정리 자료 그리고 무엇보다 학생과의 소통 능력이
                                            뛰어나신 것
                                            같습니다.
                                            아직 더 수업을 들어봐야 알겠지만 현재로서는 대만족입니다! 앞으로도 잘 부탁드립니다 :)
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Computer Science Principles
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">학원으로 수학/통계학 쪽으로는 많이
                                            배웠지만
                                            컴퓨터
                                            공학쪽으로는 거의 배우지 않아서 걱정이 많았습니다. 그러다가 인터넷 검색을 통해 아이비에드를 알게 되었고 수강 후기 및 샘플 강의를 듣고
                                            나서
                                            신뢰가
                                            가서
                                            신청하게 되었습니다. 일단 다른 수업과는 달리 1:1 맞춤형 수업이라는 점이 마음에 들었습니다. 아무래도 대형강의보다는 개인 과외나
                                            소규모
                                            그룹수업이
                                            좀 더
                                            집중도 잘되고 효율적이지 않을까 싶었거든요. 또 시간대도 다양해서 원하는 시간대로 선택할 수 있다는 점도 좋았습니다. 아무튼 이러한
                                            장점들
                                            덕분에
                                            지금까지
                                            꾸준히 다니고 있습니다. 확실히 전공자에게 직접 지도를 받다 보니 부족한 부분을 빠르게 보완할 수 있었고 단기간에 성적 상승효과를 볼 수
                                            있었습니다.
                                        </div>
                                        <div class="py-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="pageLocation == 4">
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Calculus
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">미국 유학 준비중인 학생입니다.
                                            현재
                                            GPA
                                            관리하면서
                                            SAT 공부도 병행하고 있는데요. 수학 과목 같은 경우 한국어로도 어려운 미적분학을 영어로 배워야 하는 상황이기 때문에 미리 선행학습을
                                            하지
                                            않으면
                                            수업
                                            진도를 따라가기 어려울 것 같았어요. 그래서 학기 시작하기 전 겨울방학 동안 개념이라도 좀 잡아 놓으려고 학원을 알아보다가 우연히 상담
                                            받아보니
                                            일대일
                                            맞춤형
                                            수업 방식이라서 집중력 있게 배울 수 있을 것 같고 또 실제로 수강했던 학생들의 만족도가 높길래 여기서 배우기로 결정했어요. 한 달정도
                                            다녔는데
                                            확실히
                                            실력이
                                            늘긴 하더라고요. 앞으로도 꾸준히 다니면서 부족한 부분 채워나가야겠습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Calculus
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">코로나 이후 비대면 수업을 많이
                                            했었습니다.
                                            하지만
                                            아무리 그래도 대면 수업보다 효과가 떨어지는 건 어쩔 수 없는 일이었어요. 그러던 와중 지난 학기 수학 성적이 너무 낮게 나온 걸 보고
                                            이대로는
                                            안
                                            되겠다는
                                            생각이 들었습니다. 그래서 방학 동안 혼자서라도 공부를 해야겠다는 결심을 했지만 공부중에도 뭔가 제가 잘하고 있는게 맞나 의문이 계속
                                            들었고
                                            제대로 된
                                            피드백을 받고 싶다는 생각이 들었습니다. 그때 마침 엄마에게 아이비에드 과외 선생님을 소개받게 되었고 상담 후 바로 시작하기로 하였다.
                                            다행히
                                            제가
                                            원했던
                                            조건이었던 미국 명문대 출신이었고 학생 지도 경험도 있어서 만족했습니다. 그리고 무엇보다도 다른 학원 강사들처럼 일방적으로 진도만 나가는
                                            스타일이
                                            아니라 나와
                                            소통하면서 진행한다는 점이 마음에 들었습니다. 첫 수업 때 저는 그동안 헷갈렸던 공식 증명 방법 및 간단한 예제 위주로 질문했고 바로
                                            도움이
                                            되었습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">Geometry
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">미적분보다 오히려 기하학이
                                            어려웠어요.
                                            그래서인지
                                            학원
                                            강의를 한다고 하더라도 수업 방식이 거의 문제풀이 위주여서 제대로 개념을 잡기 힘들었습니다. 그런 상황 속에서 아이비에드에서 첫 수업을
                                            진행했었습니다.
                                            먼저
                                            그동안 공부했던 방법 및 문제점 그리고 앞으로의 방향성에 대해서 이야기를 나눴습니다. 그러고 나서 곧바로 기본개념 설명 후 예제 풀이
                                            순으로
                                            수업을
                                            진행했습니다. 확실히 혼자서 공부했을 때와는 차원이 달랐습니다.  무엇보다도 강사님께서는 단순히 암기식으로 가르치지 않고 원리를
                                            이해시켜주셨습니다.
                                            답을 맞추는 것이 전부가 아니라 무엇을 근거로, 어떤 생각의 흐름으로 이러한 답에 도달했는지를 계속 끊임없이 물어봐 주시고
                                            지도해주셨습니다.
                                            그러다
                                            보니 저도
                                            생각하는 힘이 생겼고 못풀었던 문제에 대해서도 지금은 쉽게 풀 수 있었습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                            </div>
                            <div v-if="pageLocation == 5">

                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">Algebra
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">칸 아카데미 인강 수강으로 기본
                                            개념을
                                            잡고
                                            문제를 푸는
                                            형태로 공부를 하고 있었는데요. 복습을 해봐도 도저히 답이 나오질 않았습니다. 이대로 포기해야 되나 싶었지만 과외를 시작하고 역시나 혼자
                                            끙끙
                                            앓았던
                                            시간이
                                            아까울 정도로 간단한 문제들이었더라구요.. 순간 허탈해지면서 동시에 안도감이 밀려왔습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">English Literature
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">영어 (English
                                            Literature)
                                            는
                                            사실
                                            잘하는 사람을 구하기가 매우 힘들어요. 검증하는 방법도 제게 없을 뿐더러 주변에 일단 잘 없습니다. 솔직히 냉정하게 말하자면 주변사람들은
                                            좋은
                                            선생님을
                                            제가
                                            가만히 있으면 잘 추천하지 않아요. 잘하는 분들은 보통 자기 할일이 바쁘거나 첨삭을 해서 지도해주는 것 또한 별개이기 때문인데요. 하지만
                                            아이비에드에서
                                            생각보다 쉽게 실력자 선생님을 소개시켜주었고 정말 마음에 쏙 들어서 계속 지도를 부탁드렸습니다.
                                        </div>

                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">SAT Reading
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">SAT이란 시험에 대해 전체적으로
                                            빠르게
                                            이해하는데
                                            도움을 주셨고 VOCAB  진짜 감동이에요. 정말 신경써서 잘해주셨고 Classical passage랑 American history
                                            특히
                                            힘들었는데 문제
                                            유형이랑 전체적인 역사적 설명을 충분히 해주셔서 좋았습니다.
                                        </div>

                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                            </div>
                            <div class="py-2"></div>
                            <v-pagination v-model="pageLocation" length="5"></v-pagination>
                        </div>
                    </div>
                    <div class="py-15"></div>
                </div>

            </div>

        </div>
        <div class="hidden-md-and-down" style="background-color: #f6f6f6">
            <div>
                <div class="ml-1 center-horizontal">
                    <!--                    <span class="black--text center-vertical pa-5">과목</span>-->
                    <v-chip-group
                            mandatory
                            class=""
                            column
                            show-arrows
                    >
                        <div>
                            <v-chip

                                    label
                                    style="justify-content: center; align-items: center; height: 60px; width: 140px;  border-radius: 40px !important; font-size: 18px"
                                    class=" "
                                    @click="this.showAllReviews"
                                    :outlined="selectedTab !== 'all'"
                                    color="primary"
                            >
                                전체
                            </v-chip>
                            <v-chip
                                    label
                                    style="justify-content: center; align-items: center; height: 60px; width: 140px;  border-radius: 40px !important; font-size: 18px"
                                    :outlined="selectedTab !== 'sat'"
                                    @click="this.showSATOnly"
                                    color="primary"
                                    class="rounded-xl "
                            >
                                SAT
                            </v-chip>
                            <v-chip
                                    :outlined="selectedTab !== 'act'"
                                    style="justify-content: center; align-items: center; height: 60px; width: 140px;  border-radius: 40px !important; font-size: 18px"
                                    class="rounded-xl "
                                    @click="this.showActReview"
                                    color="primary">
                                ACT
                            </v-chip>
                            <v-chip
                                    label
                                    style="justify-content: center; align-items: center; height: 60px; width: 140px;  border-radius: 40px !important; font-size: 18px"
                                    :outlined="selectedTab !== 'ap'"
                                    class="rounded-xl "
                                    @click="this.showAPOnly"
                                    color="primary">
                                AP
                            </v-chip>
                            <v-chip
                                    label
                                    :outlined="selectedTab !== 'ib'"
                                    class="rounded-xl "
                                    style="justify-content: center; align-items: center; height: 60px; width: 140px;  border-radius: 40px !important; font-size: 18px"
                                    @click="this.showIBOnly"
                                    color="primary">
                                IB
                            </v-chip>
                            <v-chip
                                    label
                                    :outlined="selectedTab !== 'gpa'"
                                    style="padding-right: 0.5%;justify-content: center; align-items: center; height: 60px; width: 140px;  border-radius: 40px !important; font-size: 18px"
                                    class="rounded-xl"
                                    @click="this.showGpaOnly"
                                    color="primary">
                                내신관리
                            </v-chip>
                            <v-chip
                                    label
                                    style="padding-right: 0.5%;justify-content: center; align-items: center; height: 60px; width: 140px;  border-radius: 40px !important; font-size: 18px"
                                    :outlined="selectedTab !== 'essay'"
                                    class="rounded-xl "
                                    @click="this.showEssayOnly"

                                    color="primary">
                                Essay
                            </v-chip>
                        </div>
                    </v-chip-group>
                </div>
                <div class="py-4"></div>


                <div class="center-horizontal">
                <div style="max-width: 1000px" class="">
                    <div style="background-color: #f6f6f6" class=" pt-4">
                        <div style="">
                            <div v-if="!allReviews">
                                <div class=" px-4 pt-4 ma-4" v-if=" this.essayReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black; "
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> English (Writing)
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">미국 유학생활 2년 차인데 아직도
                                            영어실력이
                                            부족해서
                                            고생중입니다. 그러다가 유학 관련 과외를 찾아봤었는데 그러다가 알게 된 곳이 바로 아이비에드였습니다. 여기서 제 영어 writing이나
                                            전반적인
                                            영어
                                            과목에
                                            대한 상담 신청을 하니 친절하게 전화상담을 해주셨고 저의 상황에 맞는 선생님을 매칭해 주셨습니다. 무엇보다 화상수업이어서 유동적으로
                                            스케줄을
                                            조절 할
                                            수
                                            있었고 코로나 상관없이 고정적으로 수업을 들을 수 있어서 좋았습니다. 실제로 수업을 들어보니 어려운 개념도 쉽게 설명해 주셔서 이해도
                                            잘되고
                                            진도도
                                            혼자
                                            할때보다 확실히 많이 나갈 수 있었습니다. 덕분에 시험 준비하는데 큰 도움이 되고 있습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.satReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black; "
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> SAT Reading
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                            긴 문장을 읽고 핵심 아이디어를 파악하는 기술과 어려운 어휘를 이해하는 방법을 배우는 과정에서 점차 능력이 향상 됐어요! SAT
                                            Reading
                                            너무 처음에 자신이 없어 힘들었는데 선생님이 차분하게 설명해주시고 격려해주셔서 자신감 얻고 원하는 점수 겟 했습니다! 감사합니다🙏
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> AP Chemistry
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP Chemistry
                                            과외후기입니다.
                                            저는 현재
                                            미국
                                            고등학교 재학중이고 코로나때문에 학교수업도 온라인으로 진행되고있어서 혼자서 공부하기엔 무리가 있어서 수업을 받게되었습니다. 특히 Lab의
                                            중요성을
                                            강조해주셔서
                                            중요한 부분에 중점적으로 수업을 해주셨고 모르는 문제나 이해안가는부분들을 그때마다 설명해주셔서 너무 좋았어요! 특히 선생님께서 정말
                                            친절하시고
                                            학생입장에서
                                            배려많이해주시는게 느껴져서 좋았습니다 :)
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.satReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> SAT (Reading / Math)
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">9학년 끝난 여름방학에 SAT 학원
                                            두달
                                            다녀봤는데
                                            성적이 오르지 않아 상담 받아보게 됐어요. SAT는 절대 두달안에 점수 낼 수 없다고 말씀 해주시고 천천히 1년동안 준비해야 한다고 해서
                                            과외
                                            시작했어요.
                                            1년 동안 매주 단어외우고 문제풀고 모르는거 배우고 하다보니 11학년 들어가는 여름방학때 점수가 1500점이 넘었어요! 너무
                                            감사해요❣️
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.essayReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">Application Essay
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">이번에 Early Decision
                                            지원한
                                            학생이에요.
                                            학교 성적이나 SAT 점수는 자신이 있었는데, Application Essay를 어떻게 써야할지 도저히 감이 안오더라구요. 그래서
                                            아이비에드에서
                                            과외
                                            받기
                                            시작했어요. Application Essay의 목적이나 구조 하나하나 다 배우고 스토리라인 짜는거부터 최종 첨삭까지 도와주셔서 이번에 잘
                                            끝내게
                                            됐어요. 너무
                                            감사합니다!
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.satReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black; "
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> SAT Reading
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                            처음에는 온라인 강의 보면서 혼자 공부하려고 했는데 아무래도 한계가 있더라구요. 그러다가 주변에서 SAT과외 추천 받아서 시작했어요.
                                            역시
                                            전문가는 다르더라구요. 제 문제점이랑 보완할 점 정확히 파악하셔서 커리큘럼 짜주시고 수업 방식도 학생에게 맞춰서 해주시니 성적이 오를 수
                                            밖에
                                            없어요.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>

                                <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> AP Chemistry
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">작년 이맘때쯤 미국 대학 입시를
                                            앞두고
                                            AP
                                            Chemistry 공부를 하고 있었습니다. 혼자서는 도저히 진도가 나가지 않아서 학원을 알아보던 중 우연히 블로그 글을 보게 되었습니다.
                                            상담을
                                            했는데
                                            다른
                                            곳과 다르다는 것을 확실히 느꼈습니다. 먼저 레벨 테스트를 받았고 예상대로 기초가 부족하다는 진단이 나왔고 저에게 맞는 맞춤형 커리큘럼을
                                            짜주셨습니다.
                                            확실히
                                            커리큘럼을 짜주시는것부터가 다른 곳과 차이가 큽니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Psychology
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">2월 중순부터 3월 말까지 진행된
                                            ap
                                            psychology 과외 수업 관련 후기입니다. 온라인 미팅 방식으로 주2회씩 총 8번 이루어진 수업이었어요. 저의 경우 심리학
                                            전공자로서 기본
                                            이론
                                            베이스만
                                            가지고 있던 상황이었습니다. 따라서 전문 강사님께 확실한 개념 정리 및 문제풀이 스킬을 배우고 싶었어요. 실제로 AP 시험 준비 뿐만
                                            아니라
                                            대학교
                                            학점관리
                                            차원에서도 도움이 될만한 강의라고 생각했기 때문이죠. 다행히도 선생님께서 커버를 잘 해주셨고 덕분에 체계적으로 공부할 수 있었습니다.
                                            만약 학교
                                            수업만으로는
                                            부족함을 느끼시는 분이라면 해당 과목만큼은 과외 수강을 통해 보완하는것을 추천드려요.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.satReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black; "
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium"> SAT Reading
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                            제가 원하던 대학에 합격할 수 있었던 가장 큰 이유는 바로 SAT 시험 준비였어요. 공부해야 하는 과목과 범위가 방대하다 보니 혼자서는
                                            도저히
                                            엄두가 안나더라구요. 처음에는 학원 수업을 들었는데 실력있는 선생님 찾기도 어렵고 내성적인 성격 탓에 질문하기도 어려웠어요. 그러다가
                                            아이비에드를 알게 돼서 퀄리티 높은 수업뿐만 직접 모르는 부분이나 문제 푸는 요령 등을 물어볼 수 있고 일대일 상담 후 맞춤 커리큘럼대로
                                            진행돼서 좋았어요. 무엇보다 모의고사 시스템이 잘 갖춰져있어서 실전 감각 익히기에 도움이 됐어요. 실제로 두 달만에 목표했던 점수 이상
                                            받을 수
                                            있었어요.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>

                                <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Computer Science A
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP 컴퓨터사이언스 과목 시험
                                            준비를 위해
                                            올해
                                            5월부터
                                            온라인과외를 시작했습니다. 솔직히 수학처럼 하면 되겠지 생각했는데 첫 학교 수업부터 멘붕이었습니다. 다행히 과외 선생님이 전공자다 보니
                                            실력이
                                            여태
                                            다른
                                            선생님과는 정말 남달랐어요. 현재 저는 10회차 수업중 7회차 진행중이며 2번의 모의시험도 치뤘습니다. 남은 3회차도 너무 기대
                                            됩니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Computer Science A
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP Computer Science
                                            A
                                            시험
                                            준비생입니다. 한 번도 접해보지 않은 과목이기에 혼자 공부하기엔 무리가 있을 것 같아 선생님을 찾게 되었습니다. 그러던 중 우연히 블로그
                                            글을
                                            보고
                                            연락드리게
                                            된 쌤! 첫 수업 후 정말 너무너무 만족스러웠습니다. 우선 꼼꼼한 설명 + 깔끔한 정리 자료 그리고 무엇보다 학생과의 소통 능력이
                                            뛰어나신 것
                                            같습니다.
                                            아직 더 수업을 들어봐야 알겠지만 현재로서는 대만족입니다! 앞으로도 잘 부탁드립니다 :)
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Computer Science Principles
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">학원으로 수학/통계학 쪽으로는 많이
                                            배웠지만
                                            컴퓨터
                                            공학쪽으로는 거의 배우지 않아서 걱정이 많았습니다. 그러다가 인터넷 검색을 통해 아이비에드를 알게 되었고 수강 후기 및 샘플 강의를 듣고
                                            나서
                                            신뢰가
                                            가서
                                            신청하게 되었습니다. 일단 다른 수업과는 달리 1:1 맞춤형 수업이라는 점이 마음에 들었습니다. 아무래도 대형강의보다는 개인 과외나
                                            소규모
                                            그룹수업이
                                            좀 더
                                            집중도 잘되고 효율적이지 않을까 싶었거든요. 또 시간대도 다양해서 원하는 시간대로 선택할 수 있다는 점도 좋았습니다. 아무튼 이러한
                                            장점들
                                            덕분에
                                            지금까지
                                            꾸준히 다니고 있습니다. 확실히 전공자에게 직접 지도를 받다 보니 부족한 부분을 빠르게 보완할 수 있었고 단기간에 성적 상승효과를 볼 수
                                            있었습니다.
                                        </div>
                                        <div class="py-2"></div>
                                    </div>
                                </div>


                                <div class=" px-4 pt-4 ma-4" v-if=" this.actReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">ACT
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;"> ACT 시험 대비 과외를 받으면서
                                            학습
                                            방법과 시험 전략에 대한 이해도가 크게 향상되었어요. 선생님의 지도 덕분에 어떤 문제를 어떤 순서로 풀어나가야 하는지를 배울 수 있었고,
                                            이것이
                                            실전에서 큰 도움이 되었습니다.
                                            ACT 시험 대비 과외를 받으면서 얻은 가장 큰 이점은 개인 맞춤형 지도를 받을 수 있다는 것이었어요. 선생님은 내 학습 스타일을
                                            고려하여 학습
                                            계획을 세우고, 어려움을 겪을 때마다 도움을 주셨어요. 이러한 지원 덕분에 더 좋은 결과를 얻을 수 있었습니다.

                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>

                                <div class=" px-4 pt-4 ma-4" v-if=" this.actReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">ACT
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;"> ACT 시험 대비 과외를 받으면서
                                            학습
                                            방법과 시험 전략에 대한 이해도가 크게 향상되었어요. 선생님의 지도 덕분에 어떤 문제를 어떤 순서로 풀어나가야 하는지를 배울 수 있었고,
                                            이것이
                                            실전에서 큰 도움이 되었습니다.
                                            ACT 시험의 어려운 부분 중 하나는 빠른 판단력과 분석력이에요. 선생님과 매주 다양한 유형의 문제를 풀어보면서 문제풀이 능력을 키울 수
                                            있도록
                                            도와주셨어요. 처음엔 낯선 문제가 나오면 당황했는데, 계속 문제풀이 하면서 설명 듣다보니 어렵지 않게 느껴지게 됐습니다!

                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>

                                <div class=" px-4 pt-4 ma-4" v-if=" this.actReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">ACT
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;"> ACT 시험 대비 과외를 받으면서
                                            학습
                                            방법과 시험 전략에 대한 이해도가 크게 향상되었어요. 선생님의 지도 덕분에 어떤 문제를 어떤 순서로 풀어나가야 하는지를 배울 수 있었고,
                                            이것이
                                            실전에서 큰 도움이 되었습니다.

                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>

                                <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Calculus
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">미국 유학 준비중인 학생입니다.
                                            현재
                                            GPA
                                            관리하면서
                                            SAT 공부도 병행하고 있는데요. 수학 과목 같은 경우 한국어로도 어려운 미적분학을 영어로 배워야 하는 상황이기 때문에 미리 선행학습을
                                            하지
                                            않으면
                                            수업
                                            진도를 따라가기 어려울 것 같았어요. 그래서 학기 시작하기 전 겨울방학 동안 개념이라도 좀 잡아 놓으려고 학원을 알아보다가 우연히 상담
                                            받아보니
                                            일대일
                                            맞춤형
                                            수업 방식이라서 집중력 있게 배울 수 있을 것 같고 또 실제로 수강했던 학생들의 만족도가 높길래 여기서 배우기로 결정했어요. 한 달정도
                                            다녔는데
                                            확실히
                                            실력이
                                            늘긴 하더라고요. 앞으로도 꾸준히 다니면서 부족한 부분 채워나가야겠습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.ibReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">IB Math HL, 12th grade
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                            IB Math HL 과목은 항상 어려웠는데, 아이비에드의 수업을 통해 개인 맞춤형 학습 계획을 세울 수 있었습니다. 선생님의 지도 덕분에
                                            수학
                                            실력이 크게 향상되었고, 시험에서도 좋은 성적을 얻을 수 있었습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>


                                <div class=" px-4 pt-4 ma-4" v-if=" this.apReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">AP Calculus
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">코로나 이후 비대면 수업을 많이
                                            했었습니다.
                                            하지만
                                            아무리 그래도 대면 수업보다 효과가 떨어지는 건 어쩔 수 없는 일이었어요. 그러던 와중 지난 학기 수학 성적이 너무 낮게 나온 걸 보고
                                            이대로는
                                            안
                                            되겠다는
                                            생각이 들었습니다. 그래서 방학 동안 혼자서라도 공부를 해야겠다는 결심을 했지만 공부중에도 뭔가 제가 잘하고 있는게 맞나 의문이 계속
                                            들었고
                                            제대로 된
                                            피드백을 받고 싶다는 생각이 들었습니다. 그때 마침 엄마에게 아이비에드 과외 선생님을 소개받게 되었고 상담 후 바로 시작하기로 하였다.
                                            다행히
                                            제가
                                            원했던
                                            조건이었던 미국 명문대 출신이었고 학생 지도 경험도 있어서 만족했습니다. 그리고 무엇보다도 다른 학원 강사들처럼 일방적으로 진도만 나가는
                                            스타일이
                                            아니라 나와
                                            소통하면서 진행한다는 점이 마음에 들었습니다. 첫 수업 때 저는 그동안 헷갈렸던 공식 증명 방법 및 간단한 예제 위주로 질문했고 바로
                                            도움이
                                            되었습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.ibReview  || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">IB Chemistry HL
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                            화학은 항상 어려웠던 과목 중 하나였는데, 선생님께서 이론을 친절하게 설명하며 복잡한 개념을 쉽게 이해할 수 있도록 도와주셨습니다. 시험
                                            대비를
                                            위한 전략과 연습문제를 통해 실제 시험에서도 자신감을 가질 수 있었습니다. 아이비에드의 IB Chemistry 수업 덕분에 더 나은
                                            성적을 얻을
                                            수 있었고, 이제 화학에 대한 흥미와 자신감이 한층 더 높아졌습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.ibReview || this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">IB English HL
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">
                                            워낙 약한 과목이었는데 수업하면서 문학과 언어를 깊이 있게 이해하고 분석하는 능력을 키우게 된 것 같아요. 다양한 작품을 해석하고
                                            논의하는
                                            과정에서 비판적 사고가 크게 향상되었고, 작문 능력도 향상시킬 수 있었던 것 같아요! 선생님 지속적인 피드백이 실력을 높이는데 큰 도움이
                                            된 것
                                            같아요!

                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>

                                <div class=" px-4 pt-4 ma-4" v-if=" this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">Geometry
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">미적분보다 오히려 기하학이
                                            어려웠어요.
                                            그래서인지
                                            학원
                                            강의를 한다고 하더라도 수업 방식이 거의 문제풀이 위주여서 제대로 개념을 잡기 힘들었습니다. 그런 상황 속에서 아이비에드에서 첫 수업을
                                            진행했었습니다.
                                            먼저
                                            그동안 공부했던 방법 및 문제점 그리고 앞으로의 방향성에 대해서 이야기를 나눴습니다. 그러고 나서 곧바로 기본개념 설명 후 예제 풀이
                                            순으로
                                            수업을
                                            진행했습니다. 확실히 혼자서 공부했을 때와는 차원이 달랐습니다.  무엇보다도 강사님께서는 단순히 암기식으로 가르치지 않고 원리를
                                            이해시켜주셨습니다.
                                            답을 맞추는 것이 전부가 아니라 무엇을 근거로, 어떤 생각의 흐름으로 이러한 답에 도달했는지를 계속 끊임없이 물어봐 주시고
                                            지도해주셨습니다.
                                            그러다
                                            보니 저도
                                            생각하는 힘이 생겼고 못풀었던 문제에 대해서도 지금은 쉽게 풀 수 있었습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.gpaReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">Algebra
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">칸 아카데미 인강 수강으로 기본
                                            개념을
                                            잡고
                                            문제를 푸는
                                            형태로 공부를 하고 있었는데요. 복습을 해봐도 도저히 답이 나오질 않았습니다. 이대로 포기해야 되나 싶었지만 과외를 시작하고 역시나 혼자
                                            끙끙
                                            앓았던
                                            시간이
                                            아까울 정도로 간단한 문제들이었더라구요.. 순간 허탈해지면서 동시에 안도감이 밀려왔습니다.
                                        </div>
                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.essayReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">English Literature
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">영어 (English
                                            Literature)
                                            는
                                            사실
                                            잘하는 사람을 구하기가 매우 힘들어요. 검증하는 방법도 제게 없을 뿐더러 주변에 일단 잘 없습니다. 솔직히 냉정하게 말하자면 주변사람들은
                                            좋은
                                            선생님을
                                            제가
                                            가만히 있으면 잘 추천하지 않아요. 잘하는 분들은 보통 자기 할일이 바쁘거나 첨삭을 해서 지도해주는 것 또한 별개이기 때문인데요. 하지만
                                            아이비에드에서
                                            생각보다 쉽게 실력자 선생님을 소개시켜주었고 정말 마음에 쏙 들어서 계속 지도를 부탁드렸습니다.
                                        </div>

                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                                <div class=" px-4 pt-4 ma-4" v-if=" this.satReview || this.essayReview">
                                    <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                         class="rounded-lg">
                                        <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                             class="pl-7 py-4 font-weight-medium">SAT Reading
                                        </div>
                                        <div class="px-4 pl-6" align="left" style="font-size: 14px;">SAT이란 시험에 대해 전체적으로
                                            빠르게
                                            이해하는데
                                            도움을 주셨고 VOCAB  진짜 감동이에요. 정말 신경써서 잘해주셨고 Classical passage랑 American history
                                            특히
                                            힘들었는데 문제
                                            유형이랑 전체적인 역사적 설명을 충분히 해주셔서 좋았습니다.
                                        </div>

                                        <div class="py-2"></div>
                                        <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                        <!--                            <div class="py-2"></div>-->
                                    </div>
                                </div>
                            </div>
                            <div v-if="allReviews">
                                <div v-if="pageLocation == 1">
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium"> English (Writing)
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">미국 유학생활 2년 차인데
                                                아직도
                                                영어실력이
                                                부족해서
                                                고생중입니다. 그러다가 유학 관련 과외를 찾아봤었는데 그러다가 알게 된 곳이 바로 아이비에드였습니다. 여기서 제 영어
                                                writing이나
                                                전반적인
                                                영어
                                                과목에
                                                대한 상담 신청을 하니 친절하게 전화상담을 해주셨고 저의 상황에 맞는 선생님을 매칭해 주셨습니다. 무엇보다 화상수업이어서
                                                유동적으로
                                                스케줄을
                                                조절 할
                                                수
                                                있었고 코로나 상관없이 고정적으로 수업을 들을 수 있어서 좋았습니다. 실제로 수업을 들어보니 어려운 개념도 쉽게 설명해 주셔서
                                                이해도
                                                잘되고
                                                진도도
                                                혼자
                                                할때보다 확실히 많이 나갈 수 있었습니다. 덕분에 시험 준비하는데 큰 도움이 되고 있습니다.
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium"> AP Chemistry
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP Chemistry
                                                과외후기입니다.
                                                저는 현재
                                                미국
                                                고등학교 재학중이고 코로나때문에 학교수업도 온라인으로 진행되고있어서 혼자서 공부하기엔 무리가 있어서 수업을 받게되었습니다. 특히
                                                Lab의
                                                중요성을
                                                강조해주셔서
                                                중요한 부분에 중점적으로 수업을 해주셨고 모르는 문제나 이해안가는부분들을 그때마다 설명해주셔서 너무 좋았어요! 특히 선생님께서
                                                정말
                                                친절하시고
                                                학생입장에서
                                                배려많이해주시는게 느껴져서 좋았습니다 :)
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium"> SAT (Reading / Math)
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">9학년 끝난 여름방학에
                                                SAT 학원
                                                두달
                                                다녀봤는데
                                                성적이 오르지 않아 상담 받아보게 됐어요. SAT는 절대 두달안에 점수 낼 수 없다고 말씀 해주시고 천천히 1년동안 준비해야
                                                한다고 해서
                                                과외
                                                시작했어요.
                                                1년 동안 매주 단어외우고 문제풀고 모르는거 배우고 하다보니 11학년 들어가는 여름방학때 점수가 1500점이 넘었어요! 너무
                                                감사해요❣️
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                </div>
                                <div v-if="pageLocation == 2">
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">Application Essay
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">이번에 Early
                                                Decision
                                                지원한
                                                학생이에요.
                                                학교 성적이나 SAT 점수는 자신이 있었는데, Application Essay를 어떻게 써야할지 도저히 감이 안오더라구요. 그래서
                                                아이비에드에서
                                                과외
                                                받기
                                                시작했어요. Application Essay의 목적이나 구조 하나하나 다 배우고 스토리라인 짜는거부터 최종 첨삭까지 도와주셔서
                                                이번에 잘
                                                끝내게
                                                됐어요. 너무
                                                감사합니다!
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium"> AP Chemistry
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">작년 이맘때쯤 미국 대학
                                                입시를
                                                앞두고
                                                AP
                                                Chemistry 공부를 하고 있었습니다. 혼자서는 도저히 진도가 나가지 않아서 학원을 알아보던 중 우연히 블로그 글을 보게
                                                되었습니다.
                                                상담을
                                                했는데
                                                다른
                                                곳과 다르다는 것을 확실히 느꼈습니다. 먼저 레벨 테스트를 받았고 예상대로 기초가 부족하다는 진단이 나왔고 저에게 맞는 맞춤형
                                                커리큘럼을
                                                짜주셨습니다.
                                                확실히
                                                커리큘럼을 짜주시는것부터가 다른 곳과 차이가 큽니다.
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">AP Psychology
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">2월 중순부터 3월 말까지
                                                진행된
                                                ap
                                                psychology 과외 수업 관련 후기입니다. 온라인 미팅 방식으로 주2회씩 총 8번 이루어진 수업이었어요. 저의 경우 심리학
                                                전공자로서 기본
                                                이론
                                                베이스만
                                                가지고 있던 상황이었습니다. 따라서 전문 강사님께 확실한 개념 정리 및 문제풀이 스킬을 배우고 싶었어요. 실제로 AP 시험 준비
                                                뿐만
                                                아니라
                                                대학교
                                                학점관리
                                                차원에서도 도움이 될만한 강의라고 생각했기 때문이죠. 다행히도 선생님께서 커버를 잘 해주셨고 덕분에 체계적으로 공부할 수
                                                있었습니다.
                                                만약 학교
                                                수업만으로는
                                                부족함을 느끼시는 분이라면 해당 과목만큼은 과외 수강을 통해 보완하는것을 추천드려요.
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                </div>
                                <div v-if="pageLocation == 3">
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">AP Computer Science A
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP 컴퓨터사이언스 과목
                                                시험
                                                준비를 위해
                                                올해
                                                5월부터
                                                온라인과외를 시작했습니다. 솔직히 수학처럼 하면 되겠지 생각했는데 첫 학교 수업부터 멘붕이었습니다. 다행히 과외 선생님이 전공자다
                                                보니
                                                실력이
                                                여태
                                                다른
                                                선생님과는 정말 남달랐어요. 현재 저는 10회차 수업중 7회차 진행중이며 2번의 모의시험도 치뤘습니다. 남은 3회차도 너무
                                                기대
                                                됩니다.
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">AP Computer Science A
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">AP Computer
                                                Science
                                                A
                                                시험
                                                준비생입니다. 한 번도 접해보지 않은 과목이기에 혼자 공부하기엔 무리가 있을 것 같아 선생님을 찾게 되었습니다. 그러던 중 우연히
                                                블로그
                                                글을
                                                보고
                                                연락드리게
                                                된 쌤! 첫 수업 후 정말 너무너무 만족스러웠습니다. 우선 꼼꼼한 설명 + 깔끔한 정리 자료 그리고 무엇보다 학생과의 소통
                                                능력이
                                                뛰어나신 것
                                                같습니다.
                                                아직 더 수업을 들어봐야 알겠지만 현재로서는 대만족입니다! 앞으로도 잘 부탁드립니다 :)
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">AP Computer Science Principles
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">학원으로 수학/통계학
                                                쪽으로는 많이
                                                배웠지만
                                                컴퓨터
                                                공학쪽으로는 거의 배우지 않아서 걱정이 많았습니다. 그러다가 인터넷 검색을 통해 아이비에드를 알게 되었고 수강 후기 및 샘플
                                                강의를 듣고
                                                나서
                                                신뢰가
                                                가서
                                                신청하게 되었습니다. 일단 다른 수업과는 달리 1:1 맞춤형 수업이라는 점이 마음에 들었습니다. 아무래도 대형강의보다는 개인
                                                과외나
                                                소규모
                                                그룹수업이
                                                좀 더
                                                집중도 잘되고 효율적이지 않을까 싶었거든요. 또 시간대도 다양해서 원하는 시간대로 선택할 수 있다는 점도 좋았습니다. 아무튼
                                                이러한
                                                장점들
                                                덕분에
                                                지금까지
                                                꾸준히 다니고 있습니다. 확실히 전공자에게 직접 지도를 받다 보니 부족한 부분을 빠르게 보완할 수 있었고 단기간에 성적 상승효과를
                                                볼 수
                                                있었습니다.
                                            </div>
                                            <div class="py-2"></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="pageLocation == 4">
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">AP Calculus
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">미국 유학 준비중인
                                                학생입니다.
                                                현재
                                                GPA
                                                관리하면서
                                                SAT 공부도 병행하고 있는데요. 수학 과목 같은 경우 한국어로도 어려운 미적분학을 영어로 배워야 하는 상황이기 때문에 미리
                                                선행학습을
                                                하지
                                                않으면
                                                수업
                                                진도를 따라가기 어려울 것 같았어요. 그래서 학기 시작하기 전 겨울방학 동안 개념이라도 좀 잡아 놓으려고 학원을 알아보다가 우연히
                                                상담
                                                받아보니
                                                일대일
                                                맞춤형
                                                수업 방식이라서 집중력 있게 배울 수 있을 것 같고 또 실제로 수강했던 학생들의 만족도가 높길래 여기서 배우기로 결정했어요. 한
                                                달정도
                                                다녔는데
                                                확실히
                                                실력이
                                                늘긴 하더라고요. 앞으로도 꾸준히 다니면서 부족한 부분 채워나가야겠습니다.
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">AP Calculus
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">코로나 이후 비대면 수업을
                                                많이
                                                했었습니다.
                                                하지만
                                                아무리 그래도 대면 수업보다 효과가 떨어지는 건 어쩔 수 없는 일이었어요. 그러던 와중 지난 학기 수학 성적이 너무 낮게 나온 걸
                                                보고
                                                이대로는
                                                안
                                                되겠다는
                                                생각이 들었습니다. 그래서 방학 동안 혼자서라도 공부를 해야겠다는 결심을 했지만 공부중에도 뭔가 제가 잘하고 있는게 맞나 의문이
                                                계속
                                                들었고
                                                제대로 된
                                                피드백을 받고 싶다는 생각이 들었습니다. 그때 마침 엄마에게 아이비에드 과외 선생님을 소개받게 되었고 상담 후 바로 시작하기로
                                                하였다.
                                                다행히
                                                제가
                                                원했던
                                                조건이었던 미국 명문대 출신이었고 학생 지도 경험도 있어서 만족했습니다. 그리고 무엇보다도 다른 학원 강사들처럼 일방적으로 진도만
                                                나가는
                                                스타일이
                                                아니라 나와
                                                소통하면서 진행한다는 점이 마음에 들었습니다. 첫 수업 때 저는 그동안 헷갈렸던 공식 증명 방법 및 간단한 예제 위주로 질문했고
                                                바로
                                                도움이
                                                되었습니다.
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">Geometry
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">미적분보다 오히려 기하학이
                                                어려웠어요.
                                                그래서인지
                                                학원
                                                강의를 한다고 하더라도 수업 방식이 거의 문제풀이 위주여서 제대로 개념을 잡기 힘들었습니다. 그런 상황 속에서 아이비에드에서 첫
                                                수업을
                                                진행했었습니다.
                                                먼저
                                                그동안 공부했던 방법 및 문제점 그리고 앞으로의 방향성에 대해서 이야기를 나눴습니다. 그러고 나서 곧바로 기본개념 설명 후 예제
                                                풀이
                                                순으로
                                                수업을
                                                진행했습니다. 확실히 혼자서 공부했을 때와는 차원이 달랐습니다.  무엇보다도 강사님께서는 단순히 암기식으로 가르치지 않고
                                                원리를
                                                이해시켜주셨습니다.
                                                답을 맞추는 것이 전부가 아니라 무엇을 근거로, 어떤 생각의 흐름으로 이러한 답에 도달했는지를 계속 끊임없이 물어봐 주시고
                                                지도해주셨습니다.
                                                그러다
                                                보니 저도
                                                생각하는 힘이 생겼고 못풀었던 문제에 대해서도 지금은 쉽게 풀 수 있었습니다.
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                </div>
                                <div v-if="pageLocation == 5">

                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">Algebra
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">칸 아카데미 인강 수강으로
                                                기본
                                                개념을
                                                잡고
                                                문제를 푸는
                                                형태로 공부를 하고 있었는데요. 복습을 해봐도 도저히 답이 나오질 않았습니다. 이대로 포기해야 되나 싶었지만 과외를 시작하고
                                                역시나 혼자
                                                끙끙
                                                앓았던
                                                시간이
                                                아까울 정도로 간단한 문제들이었더라구요.. 순간 허탈해지면서 동시에 안도감이 밀려왔습니다.
                                            </div>
                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">English Literature
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">영어 (English
                                                Literature)
                                                는
                                                사실
                                                잘하는 사람을 구하기가 매우 힘들어요. 검증하는 방법도 제게 없을 뿐더러 주변에 일단 잘 없습니다. 솔직히 냉정하게 말하자면
                                                주변사람들은
                                                좋은
                                                선생님을
                                                제가
                                                가만히 있으면 잘 추천하지 않아요. 잘하는 분들은 보통 자기 할일이 바쁘거나 첨삭을 해서 지도해주는 것 또한 별개이기 때문인데요.
                                                하지만
                                                아이비에드에서
                                                생각보다 쉽게 실력자 선생님을 소개시켜주었고 정말 마음에 쏙 들어서 계속 지도를 부탁드렸습니다.
                                            </div>

                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                    <div class=" px-4 pt-4 ma-4">
                                        <div style="background-color: white ;box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; color: black"
                                             class="rounded-lg">
                                            <div align="left" style="font-size: 17px; margin-left: -4px !important;"
                                                 class="pl-7 py-4 font-weight-medium">SAT Reading
                                            </div>
                                            <div class="px-4 pl-6" align="left" style="font-size: 14px;">SAT이란 시험에 대해
                                                전체적으로
                                                빠르게
                                                이해하는데
                                                도움을 주셨고 VOCAB  진짜 감동이에요. 정말 신경써서 잘해주셨고 Classical passage랑 American
                                                history
                                                특히
                                                힘들었는데 문제
                                                유형이랑 전체적인 역사적 설명을 충분히 해주셔서 좋았습니다.
                                            </div>

                                            <div class="py-2"></div>
                                            <!--                            <div class="px-4 pl-6" align="left" style="font-size: 12px; color: #A30717">더보기</div>-->
                                            <!--                            <div class="py-2"></div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="py-2"></div>
                                <v-pagination v-model="pageLocation" length="5"></v-pagination>
                            </div>
                        </div>
                        <div class="py-15"></div>
                    </div>
                </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import ReviewPage1 from "./ReviewSection/ReviewPage1";
    import ReviewPage2 from "./ReviewSection/ReviewPage2";
    import ReviewPage3 from "./ReviewSection/ReviewPage3";
    import ReviewPage4 from "./ReviewSection/ReviewPage4";
    import ReviewPage5 from "./ReviewSection/ReviewPage5";
    import ReviewPage6 from "./ReviewSection/ReviewPage6";
    import ReviewPage7 from "./ReviewSection/ReviewPage7";
    import ReviewPage8 from "./ReviewSection/ReviewPage8";
    import ReviewPage9 from "./ReviewSection/ReviewPage9";

    export default {
        name: "NewReview",
        components: {
            ReviewPage9,
            ReviewPage8,
            ReviewPage7, ReviewPage6,
            ReviewPage5,
            ReviewPage4,
            ReviewPage3,
            ReviewPage2,
            ReviewPage1
        },
        data() {
            return {
                selectedTab: '', // Initialize with the selected tab's value
                allReviews: true,
                scienceReviewOnly: false,
                mathReviewOnly: false,
                englishReviewOnly: false,
                testReviewOnly: false,
                pageLocation: 1,
                satReview: false,
                actReview: false,
                apReview: false,
                ibReview: false,
                gpaReview: false,
                essayReview: false,
            }
        },
        methods: {
            showAllReviews() {
                this.selectedTab = 'all';
                this.satReview = true;
                this.actReview = true;
                this.apReview = true;
                this.ibReview = true;
                this.gpaReview = true;
                this.essayReview = true;
            },
            showMathOnly() {
                // this.selectedTab = 'sat';

                this.allReviews = false
                this.scienceReviewOnly = false
                this.mathReviewOnly = true
                this.englishReviewOnly = false
                this.testReviewOnly = false
            },
            showScienceOnly() {
                this.allReviews = false
                this.scienceReviewOnly = true
                this.mathReviewOnly = false
                this.englishReviewOnly = false
                this.testReviewOnly = false
            },
            showEnglishOnly() {
                this.allReviews = false
                this.scienceReviewOnly = false
                this.mathReviewOnly = false
                this.englishReviewOnly = true
                this.testReviewOnly = false
            },
            showTestOnly() {
                this.allReviews = false
                this.scienceReviewOnly = false
                this.mathReviewOnly = false
                this.englishReviewOnly = false
                this.testReviewOnly = true
            },
            showSATOnly() {
                this.selectedTab = 'sat';
                this.satReview = true;
                this.actReview = false;
                this.apReview = false;
                this.ibReview = false;
                this.gpaReview = false;
                this.essayReview = false;
                this.allReviews = false;
            },
            showActReview() {
                this.selectedTab = 'act';

                this.allReviews = false;

                this.satReview = false;
                this.actReview = true;
                this.apReview = false;
                this.ibReview = false;
                this.gpaReview = false;
                this.essayReview = false;
            },
            showAPOnly() {
                this.selectedTab = 'ap';

                this.allReviews = false;

                this.satReview = false;
                this.actReview = false;
                this.apReview = true;
                this.ibReview = false;
                this.gpaReview = false;
                this.essayReview = false;
            },
            showIBOnly() {
                this.selectedTab = 'ib';

                this.allReviews = false;

                this.satReview = false;
                this.actReview = false;
                this.apReview = false;
                this.ibReview = true;
                this.gpaReview = false;
                this.essayReview = false;
            },
            showGpaOnly() {
                this.selectedTab = 'gpa';

                this.allReviews = false;

                this.satReview = false;
                this.actReview = false;
                this.apReview = false;
                this.ibReview = false;
                this.gpaReview = true;
                this.essayReview = false;
            },
            showEssayOnly() {

                this.selectedTab = 'essay';

                this.allReviews = false;

                this.satReview = false;
                this.actReview = false;
                this.apReview = false;
                this.ibReview = false;
                this.gpaReview = false;
                this.essayReview = true;
            },


        }
    }
</script>

<style scoped>
   .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

   .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

   .second-review-section {
        /*background-image: linear-gradient(92.88deg, #DE2828 9.16%, #FF6E6E 64.72%);*/
        /*box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;*/
        /*height: 100%;*/
        background-color: white !important;
        /*height: calc(100vh - 70px);*/
        /*width: 100%;*/
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        /*object-fit: contain;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        padding-top: 100px;
        /*padding-left: 30px;*/
        justify-content: center;
        align-items: normal;
    }

   .third-review-section {
        background-color: #f3eaeb;
    }
</style>
