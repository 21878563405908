const apPhysicsCConceptData = {
    bootcamp:[{
        classId: 0, class: {
            imgUrl: "https://i.postimg.cc/L5LzPcSX/maxresdefault.jpg",
            imgAlt: "IVYED AP Physics C",
            title: "AP Physics C 개념마스터",
            subheading: "미국 국제학교 선생님 출신",
            description: "AP Physics C, 교육경력 15년차 선생님과 함께 마스터",
            lectureStyle: "100% 온라인 강의",
            program: "단기반 신청 가능",
            necessity: ["AP Physics C, 독학으로 준비하기 너무 어려우신가요?", "AP Physics C, 제대로 가르쳐주는 곳 없나요?", "AP Physics C 시험에서 고득점을 받고싶나요?"],
            introduction: {
                title: "AP Physics C 개념이 어렵다면?",
                items: ["- AP Physics C 고득점 완성하는 반", "- 실제 Physics 과목 교육을 해오신 선생님의 Physics 1타 강의", "-  개념을 배우면서도 시험준비를 할 수 있는 AP Physics C 종합적 학습", "- Physics C를 완벽하게 이해시키고, 문제풀이까지 하는 강의", "- 개념과 개념을 적용하는 문제를 풀면서 문제 응용력 향상하는 강의"]
            },
            whyTakeThisCourse: [{
                title: "교육경력 15년차 베테랑 선생님의 Physics C 완벽 개념 마스터",
                info: ["15년간 가르치면서 생긴 노하우, 효율적인 Physics C 공부"]
            }, {
                title: "AP Physics 교육 경력에서 얻은 Physics 지식을 쉽게 이해하는 노하우 제공",
                info: []
            }, {title: "노하우로 작성된 AP Physics C 학습 자료 제공", info: []}, {title: "AP Physics C 개념 완벽 이해", info: []}],
            afterTakingCourse: [{
                title: "AP Physics C가 더 이상 어렵지 않습니다.",
                info: ["기초를 탄탄하게하고 문제 응용력을 키울 수 있을 것입니다."]
            }, {
                title: "개념 이해 및 문제 해결 능력 향상",
                info: ["개념을 배우면서 동시에 AP Physics C 시험을 준비하는 방법을 익힐 수 있으며, 문제 풀이 능력을 키울 수 있습니다."]
            }, {
                title: "Physics C를 영어로 해결하는 능력까지 겸비",
                info: ["Only English 과정으로, 영어로 나오는 AP 시험에 당황하지 않도록 연습 가능합니다."]
            }, {
                title: "Physics에 대한 흥미 증가",
                info: ["실제 학생들을 가르쳤던 경험을 토대로 수업에 집중할 수 있게 만드는 선생님의 즐거운 Physics C강의로 Physics에 대한 흥미가 증가합니다."]
            }],
            teacher: {
                userId: 124,
                name: "Jean",
                imgUrl: "https://i.postimg.cc/kgBFXtr2/jin.png",
                schoolLogoImgUrl: "https://i.postimg.cc/prJCMYM7/columbia-shield.jpg",
                experience: ["Columbia 석사", "미국 국제학교 교사 5년", "과외/강의 15년", "미국 암전문병원 연구원"],
                description: "안녕하세요. 거의 해외에서만 15년 이상 거주하다 보니 영어보다 한국어가 더 어려워진 Jean 선생님입니다.\n소개 글이 어색할 수 있습니다.\n저를 간단히 소개하자면 학창 시절부터 개인 과외 없이 혼자서 공부하면서 그래도 Columbia 석사를 마지막으로 졸업했고, 대학교를 다니면서도 교육에 대한 열정이 가득하여 15년 동안 중학생, 고등학생 대상으로 가르쳐왔습니다.그러다 운이 좋게 미국 국제 학교에서 이공계열 과목 수업을 5년 정도 맡아왔습니다.\n지금은 국제 학교를 나와 미국 암 전문병원 연구원에서 일을 하고 있습니다.AP Physics관련 과목들은 저에게 가장 자신있는 과목입니다.\n저의 경험과 실력으로 AP Physics C 다 이해시켜드리겠습니다. ",
                videoUrl: "https://www.youtube.com/embed/K-MkErHx4MA?si=GqnqGpTrTS6F_iwQ"
            },
            course: [{
                schedule: {
                    courseName: "AP Physics C 개념마스터 서부",
                    days: "화, 금",
                    time: "19:00 ~ 20:30",
                    timezone: "PST (서부)",
                    duration: "(01/15 ~ 03/15) 2개월",
                    minutes: "90분",
                    frequency: "주 2회",
                    shortDetails: "(01/15 ~ 03/15) 2개월"
                },
                objectives: {
                    korean: ["- AP Physics C의 핵심 주제인 고전 역학과 전자기학에 견고한 기초를 구축합니다.", "- 고급 문제 해결 능력과 복잡한 물리 시스템을 분석할 수 있는 능력을 개발합니다.", "- 물리에서 미적분과 미분 방정식을 포함한 고급 수학 개념과 그 응용에 대한 탐구를 진행합니다.", "- 고급 수준에서 운동, 힘, 일, 에너지 및 전기의 원리를 이해하고 적용합니다.", "- 물리 현상을 조사하고 이론적 모델을 검증하는 실험을 설계하고 수행하는 실험실 기술을 키웁니다.", "- AP Physics C의 역학 및 전기 및 자기주의 시험을 통해 포괄적인 검토와 연습 시험을 통해 학생들을 준비합니다.", "- 물리 시스템의 행동을 지배하는 기본 원리에 대한 심도있는 이해를 촉진합니다.", "- 비교적 명확하고 간결한 방식으로 과학적 개념과 결과를 설명하고 정당화할 수 있는 능력을 촉진합니다.", "- 물리학 개념의 역사적 발전과 자연 세계에 대한 이해에 미치는 영향을 탐구합니다"],
                    english: ["- Build a strong foundation in classical mechanics and electromagnetism, which are the core topics of AP Physics C.", "- Develop advanced problem-solving skills and the ability to analyze complex physical systems.", "- Explore advanced mathematical concepts and their applications in physics, including calculus and differential equations.", "- Understand and apply the principles of motion, forces, work, energy, and electricity at an advanced level.", "- Cultivate laboratory skills to design and conduct experiments that investigate physical phenomena and validate theoretical models.", "- Prepare students for the AP Physics C exams in both Mechanics and Electricity & Magnetism through comprehensive review and practice exams.", "- Foster an in-depth understanding of the fundamental principles that govern the behavior of physical systems.", "- Encourage critical thinking and the ability to explain and justify scientific concepts and findings in a clear and concise manner.", "- Explore the historical development of physics concepts and their impact on our understanding of the natural world."]
                },
                curriculum: [{
                    name: "Kinematics",
                    item: {content: ["- Definitions: One Dimension", "- Summary of Equations: One Dimension", "- Problem Solving: One Dimension", "- Definitions: Two Dimensions", "- Problem Solving: Two Dimensions", "- Projectile Motion", "- Relative Position, Velocity, and Acceleration ", "- Uniform Circular Motion", " - Acceleration In Nonuniform Circular Motion"]}
                }, {name: "Newton’s Laws", item: {content: ["- Mass vs Weight", ""]}}, {
                    name: "Work, Energy, and Power",
                    item: {content: ["- Work", "- Conservative and Nonconservative Forces", "- Gravitational Potential Energy", "- Spring Force (Hooke’s Law)", "- Elastic Potential Energy", "- Unification of Gravitational and Elastic Potential Energy", "- Power", "- Solving Conservation of Energy Problems (Equation Summary)"]}
                }, {
                    name: "Linear Momentum and Center of Mass",
                    item: {content: ["- Momentum", "- Center of Mass"]}
                }, {
                    name: "Rotation I: Kinematics, Force, Work, and Energy",
                    item: {content: ["- The Motivation for Developing New Parameters", "    - Fundamental Equations of Angular Kinematics", "    - The Relationships Between Angular and Linear Quantities", "    - Rotational Kinetic Energy", "    - Torque", "    - Rotational Analog of Newton’s Second Law", "    - Work Done by an External Force", "    - Power Associated with a Torque", "    - Equation Summary with Translational Analogs"]}
                }, {
                    name: "Rotation II: Inertia, Equilibrium, and Combined Rotation,Translation",
                    item: {content: ["    - Parallel Axis Theorem", "    - Angular Momentum", "    - Rolling Without Slipping", "    - Static Equilibrium for Extended Objects "]}
                }, {
                    name: "Simple Harmonic Motion",
                    item: {content: ["- Problem Solving: Calculating The Angular Frequency of Oscillation", "- Problem Solving: Using Energy Conservation"]}
                }, {
                    name: "Universal Gravitation",
                    item: {content: ["    - Gravitation Due to Spherically Symmetric Mass Distributions Relating to G", "     - Gravitational Field", "    - Kepler’s Third Law", "     - Gravitational Potential Energy"]}
                }, {
                    name: "Electrostatics",
                    item: {content: ["-Coulomb’s Law", "    - The Concept of Fields: Gravitational Fields and Electric Fields", "    - Potential and Voltage", "    - Calculating the Potential Due to a Continuous Charge Density: A General Approach", "    - Direct Conversion Between Electric Field and Potential", "    - Calculating the Electric Field Due to a Continuous Charge Density: A General Approach"]}
                }, {name: "Gauss’s Law", item: {content: ["- The Concept of Flux Gauss’s Law"]}}, {
                    name: "Capacitors",
                    item: {content: ["- Qualitative Introduction to Capacitors", "- Capacitance"]}
                }, {
                    name: "Analysis of Circuits Containing Batteries, Resistors and Capacitors",
                    item: {content: ["- Conventional Current", "    - Current Density and Drift Velocity", "    - Resistance", "    - Voltmeters and Ammeters", "    - Analysis of Circuits Containing Multiple Batteries: Kirchhoff’s Laws", "    - Kirchhoff’s Laws", "    - RC Circuits", "    - General Approach to Deriving Equations For RC Circuits", "    - Discharging a Capacitor", "    - Charging a Capacitor", "    - Time Constants"]}
                }, {
                    name: "Magnetic Fields",
                    item: {content: ["- What Is a Magnetic Field?", "    - Problem Solving: Mass Spectrometer Problems", "    - Sources of Magnetic Fields", "    - Ampere’s Law", "    - Problem Solving: How To Apply Ampere’s Law"]}
                }, {
                    name: "Electromagnetism",
                    item: {content: ["- Calculating The Magnetic Flux ΦB", "    - Faraday’s Law", "    - Lenz’s Law and Determining the Direction of the EMF", "    - Maxwell’s Equations"]}
                }, {
                    name: "Inductors",
                    item: {content: ["- Qualitative Understanding of Self-Inductance", "    - Quantitative Definition of Self-Inductance", "    - Energy Stored In an Inductor", "    - RL Circuits (Circuits Containing Inductors and Resistors)", "    - LC Circuits (Circuits Containing a Capacitor and an Inductor)", ""]}
                }]
            },{
                schedule: {
                    courseName: "AP Physics C 개념마스터 동부",
                    days: "화, 금",
                    time: "22:00 ~ 23:30",
                    timezone: "EST (동부)",
                    duration: "(01/15 ~ 03/15) 2개월",
                    minutes: "90분",
                    frequency: "주 2회",
                    shortDetails: "(01/15 ~ 03/15) 2개월"
                }, objectives: {}, curriculum: []
            },{
                schedule: {
                    courseName: "AP Physics C 개념마스터 KST",
                    days: "수, 토",
                    time: "12:00 ~ 13:30",
                    timezone: "KST",
                    duration: "(01/15 ~ 03/15) 2개월",
                    minutes: "90분",
                    frequency: "주 2회",
                    shortDetails: "(01/15 ~ 03/15) 2개월"
                }, objectives: {}, curriculum: []
            },],
            review: [{
                person: "미국 보딩 스쿨 11학년",
                experience: "\"학교에서 Physics C를 안 가르쳐 줘서 따로 알아봤어야 했는데, 그중 만나게 되었던 Jean 선생님! 덕분에 Physics C 점수 잘 받았어요!”",
                personImgUrl: "https://i.postimg.cc/7Yr3LDJ1/avatar4.png"
            }, {
                person: "미국 국제 학교 11학년",
                experience: "\"Phyiscs C 강의를 여기저기서 많이 들었었는데 Jean 선생님 강의로 정착했습니다.\"",
                personImgUrl: "https://i.postimg.cc/4dbjWxL1/avatar1.png"
            }, {
                person: "미국 국제 학교 11학년",
                experience: "\"Physics C가 너무 어렵고 점수도 안 나와서 포기할까 했었는데 Jean 선생님이 잘 지도해 주신 덕분에 Physics C 만점 받았어요..”",
                personImgUrl: "https://i.postimg.cc/7Yr3LDJ1/avatar4.png"
            }]
        }
    }]
}

export default apPhysicsCConceptData;
