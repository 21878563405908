<template>
    <v-container>
        <!--        <h1>{{currentUser}}</h1>-->
        <v-container v-if="currentUser.roles[0] == 'ROLE_TEACHER'">


            <v-card
                    class="mx-auto pa-5 primary--text"
                    max-width="800"
                    outlined
            >
                <span
                        :class="{
            'text-h7': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                    안녕하세요, {{currentUser.firstName}}님. 저희 IVY ED 에 가입 해주셔서 감사합니다!
                    <v-divider class="mt-3 mb-3"></v-divider>

                </span>
                <p>
                    아래 작성해주신 정보를 바탕으로 자동으로 학생을 매칭 해드려요.
                </p>
            </v-card>
            <!--        <h1>{{teacherContent}}</h1>-->
            <!--            {{items}}-->
            <!--            {{removeEmptyStringFromList(teacherContent.subjectsOfExpertise)}}-->
            <v-card
                    class="mx-auto pa-5 mt-2 primary--text"
                    max-width="800"
                    outlined
                    elevation="0"
            >
<!--                {{teacherContent}}-->
                <v-stepper
                        elevation="0"
                        v-model="e6"
                        vertical
                >
                    <v-stepper-step
                            :complete="e6 > 1"
                            step="1"
                    >
                        최종학력
                    </v-stepper-step>

                    <v-stepper-content step="1">
                        <v-autocomplete
                                v-model="teacherContent.highestLevelOfEducation"
                                :items="highestLevelOfEducation"
                                filled
                                label="최종학력"
                                :rules="[v => !!v || 'Item is required']"

                        ></v-autocomplete>
                        <v-btn
                                color="primary"
                                @click="e6 = 2"
                        >
                            Continue
                        </v-btn>
                        <v-btn @click="e6 = 1" text>
                            Back
                        </v-btn>

                    </v-stepper-content>
                    <!--                {{teacherRegisterData}}-->
                    <v-stepper-step
                            :complete="e6 > 2"
                            step="2"
                    >
                        대학교 / 대학원
                    </v-stepper-step>

                    <v-stepper-content step="2">
                        <v-card-subtitle>대학교</v-card-subtitle>
                        <v-row>
                            <v-col
                                    cols="12"
                                    sm="6"
                            >
                                <v-autocomplete
                                        v-model="teacherContent.undergrad"
                                        :items="schools"
                                        filled
                                        label="학교"
                                        :rules="[v => !!v || 'Item is required']"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                    cols="12"
                                    sm="6"
                            >
                                <v-text-field
                                        v-model="teacherContent.undergradMajor"
                                        filled
                                        label="전공"
                                        append-outer-icon="mdi-plus"
                                        placeholder="ex) Economics"
                                        @click:append-outer="addSchool = !addSchool"
                                        :rules="[v => !!v || 'Item is required']"

                                >
                                </v-text-field>
                            </v-col>
                            <v-row v-show="teacherContent.undergrad == '직접 입력'">
                                <v-col
                                        cols="12"
                                        sm="6"
                                >
                                    <v-text-field
                                            class="ma-2"
                                            v-model="undergrad"
                                            filled
                                            label="학교"
                                            placeholder="Ivy Ed University"
                                            :rules="[v => !!v || 'Item is required']"
                                    ></v-text-field>
                                </v-col>

                            </v-row>


                            <v-card elevation="0" v-show="addSchool">
                                <v-card-subtitle>대학원</v-card-subtitle>
                                <v-row>

                                    <v-col class="pa-3" sm="3">
                                        <v-select
                                                v-model="teacherContent.graduateType"
                                                :items="gradSchoolType"
                                                filled
                                                label="과정"

                                        ></v-select>
                                    </v-col>
                                    <v-col sm="4">
                                        <v-select
                                                v-model="teacherContent.gradSchool"
                                                :items="schools"
                                                filled
                                                label="학교"

                                        ></v-select>
                                    </v-col>

                                    <v-col sm="4">
                                        <v-text-field
                                                v-model="teacherContent.gradMajor"
                                                filled
                                                label="전공"
                                                placeholder="ex) Economics"
                                                :rules="[v => !!v || 'Item is required']"

                                        >
                                        </v-text-field>

                                    </v-col>

                                </v-row>
                            </v-card>
                        </v-row>
                        <v-btn
                                color="primary"
                                @click="e6 = 3"
                        >
                            Continue
                        </v-btn>
                        <v-btn @click="e6 = 1" text>
                            Back
                        </v-btn>
                    </v-stepper-content>


                    <v-stepper-step
                            :complete="e6 > 4"
                            step="3"
                    >
                        고등학교
                    </v-stepper-step>

                    <v-stepper-content step="3">
                        <v-row>
                            <v-col
                                    cols="12"
                                    sm="6"
                            >
                                <v-autocomplete
                                        v-model="teacherContent.highSchoolType"
                                        :items="possibleHighSchoolTypes"
                                        :rules="[v => !!v || 'Item is required']"
                                        label="고등학교 형태"
                                        required
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                    cols="12"
                                    sm="6"
                            >
                                <v-text-field
                                        v-model="teacherContent.highSchool"
                                        placeholder="Korea International School, South Korea"
                                        clearable
                                        :rules="[v => !!v || 'Item is required']"
                                        label="이름"
                                ></v-text-field>

                            </v-col>
                        </v-row>
                        <v-btn
                                color="primary"
                                @click="e6 = 4"
                        >
                            Continue
                        </v-btn>
                        <v-btn @click="e6 = 2" text>
                            Back
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                            :complete="e6 > 4"
                            step="4"
                    >
                        희망 과외 과목
                    </v-stepper-step>
                    <v-stepper-content step="4">
                        <v-col cols="12">
                            <v-list-item-title class="mb-2">과목</v-list-item-title>

                            <v-combobox
                                    v-model="teacherContent.subjectsOfExpertise"
                                    :items="mainSubjects"
                                    dense
                                    filled
                                    chips
                                    label="과목"
                                    multiple
                            ></v-combobox>

                            <v-combobox v-if="teacherContent.subjectsOfExpertise.includes('Mathematics')"
                                        :items="mathSubSubjects"
                                        v-model="teacherContent.selectedMathSubSubjects"
                                        dense
                                        filled
                                        chips
                                        label="수학 세부과목"
                                        multiple
                            ></v-combobox>
                            <v-combobox v-if="teacherContent.subjectsOfExpertise.includes('Science')"
                                        :items="scienceSubSubjects"
                                        v-model="teacherContent.selectedScienceSubSubjects"
                                        dense
                                        filled
                                        chips
                                        label="과학 세부과목"
                                        multiple
                            >
                            </v-combobox>
                            <v-combobox v-if="teacherContent.subjectsOfExpertise.includes('English')"
                                        :items="englishSubSubjects"
                                        v-model="teacherContent.selectedEnglishSubSubjects"
                                        dense
                                        filled
                                        chips
                                        label="과학 세부과목"
                                        multiple
                            >
                            </v-combobox>
                        </v-col>
                        <v-btn
                                color="primary"
                                @click="e6 = 5"
                        >
                            Continue
                        </v-btn>
                        <v-btn @click="e6 = 3" text>
                            Back
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                            :complete="e6 > 5"
                            step="5"
                    >
                        희망 수업 요일 및 시간대
                    </v-stepper-step>
                    <v-stepper-content step="5">
                        <v-col cols="12">
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col
                                                cols="12"
                                                sm="6">
                                            <v-combobox
                                                    v-model="teacherContent.availableDays"
                                                    :items="preferredLessonDays"
                                                    label="선호 수업 요일 (다중 선택 가능)"
                                                    multiple
                                                    chips
                                            ></v-combobox>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6">
                                            <v-combobox
                                                    v-model="teacherContent.availableTimes"
                                                    :items="preferredLessonTimes"
                                                    label="선호 시간대 (다중 선택 가능)"
                                                    multiple
                                                    chips
                                            ></v-combobox>

                                        </v-col>
                                        <v-combobox v-if="teacherContent.availableDays.includes('월')"
                                                    :items="preferredLessonTimeSlots"
                                                    v-model="teacherContent.detailedLessonTimes.monday"
                                                    dense
                                                    filled
                                                    chips
                                                    label="월요일 세부 시간"
                                                    multiple
                                                    class="ma-3 pa-3"
                                        ></v-combobox>

                                        <v-combobox v-if="teacherContent.availableDays.includes('화')"
                                                    :items="preferredLessonTimeSlots"
                                                    v-model="teacherContent.detailedLessonTimes.tuesday"
                                                    dense
                                                    filled
                                                    chips
                                                    label="화요일 세부 시간"
                                                    multiple
                                                    class="ma-3 pa-3"
                                        ></v-combobox>

                                        <v-combobox v-if="teacherContent.availableDays.includes('수')"
                                                    :items="preferredLessonTimeSlots"
                                                    v-model="teacherContent.detailedLessonTimes.wednesday"
                                                    dense
                                                    filled
                                                    chips
                                                    label="수요일 세부 시간"
                                                    multiple
                                                    class="ma-3 pa-3"
                                        ></v-combobox>

                                        <v-combobox v-if="teacherContent.availableDays.includes('목')"
                                                    :items="preferredLessonTimeSlots"
                                                    v-model="teacherContent.detailedLessonTimes.thursday"
                                                    dense
                                                    filled
                                                    chips
                                                    label="목요일 세부 시간"
                                                    multiple
                                                    class="ma-3 pa-3"
                                        ></v-combobox>

                                        <v-combobox v-if="teacherContent.availableDays.includes('금')"
                                                    :items="preferredLessonTimeSlots"
                                                    v-model="teacherContent.detailedLessonTimes.friday"
                                                    dense
                                                    filled
                                                    chips
                                                    label="금요일 세부 시간"
                                                    multiple
                                                    class="ma-3 pa-3"
                                        ></v-combobox>

                                        <v-combobox v-if="teacherContent.availableDays.includes('토')"
                                                    :items="preferredLessonTimeSlots"
                                                    v-model="teacherContent.detailedLessonTimes.saturday"
                                                    dense
                                                    filled
                                                    chips
                                                    label="토요일 세부 시간"
                                                    multiple
                                                    class="ma-3 pa-3"
                                        ></v-combobox>

                                        <v-combobox v-if="teacherContent.availableDays.includes('일')"
                                                    :items="preferredLessonTimeSlots"
                                                    v-model="teacherContent.detailedLessonTimes.sunday"
                                                    dense
                                                    filled
                                                    chips
                                                    label="일요일 세부 시간"
                                                    multiple
                                                    class="ma-3 pa-3"
                                        ></v-combobox>

                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea
                                            v-model="teacherContent.availableTimeRemarks"
                                            label="가능한 시간에 대해 자세히 설명 해주시면 도움이 됩니다 :) "
                                            flat
                                            placeholder="ex) 주말에는 오전에만 가능해요."
                                    ></v-textarea>
                                </v-col>
                            </v-row>


                        </v-col>
                        <v-btn
                                color="primary"
                                @click="e6 = 6"
                        >
                            Continue
                        </v-btn>
                        <v-btn @click="e6 = 4" text>
                            Back
                        </v-btn>
                    </v-stepper-content>


                    <v-stepper-step
                            :complete="e6 > 6"
                            step="6">
                        희망 과외 기간
                    </v-stepper-step>
                    <v-stepper-content step="6">
                        <v-row>
                            <v-col
                                    cols="12"
                                    sm="6">
                                <v-combobox
                                        v-model="teacherContent.demandingHoursPerWeek"
                                        :items="demandingHoursPerWeek"
                                        label="주당 과외 희망 시간"
                                        chips
                                ></v-combobox>
                            </v-col>
                            <v-col
                                    cols="12"
                                    sm="6">
                                <v-combobox
                                        v-model="teacherContent.demandingTotalDuration"
                                        :items="demandingTotalDuration"
                                        label="총 과외 희망 기간"
                                        chips
                                ></v-combobox>

                            </v-col>
                        </v-row>


                        <!--                        <v-btn color="primary"-->
                        <!--                                @click="updateTeacherInfo(currentUser.id, teacherContent)">-->
                        <!--                            Continue-->
                        <!--                        </v-btn>-->
                        <v-btn color="primary"
                               @click="e6 = e6+1">
                            Continue
                        </v-btn>
                        <v-btn @click="e6 = e6-1" text>
                            Back
                        </v-btn>

                    </v-stepper-content>


                    <v-stepper-step
                            :complete="e6 > 7"
                            step="7">
                        비대면 과외 경험
                    </v-stepper-step>

                    <v-stepper-content step="7">

                        <v-radio-group
                                v-model="teacherContent.hasRemoteTutoringExperience"
                                row
                        >
                            <v-radio
                                    label="네"
                                    value=true
                                    @click="hasRemoteExpereince= true"
                            ></v-radio>
                            <v-radio
                                    label="아니오"
                                    value=false
                                    @click="hasRemoteExpereince = false"
                            ></v-radio>
                        </v-radio-group>
                        <v-text-field v-show="hasRemoteExpereince"
                                      v-model="teacherContent.remoteToolsUsed"
                                      label="비대면 과외시 사용했던 툴"
                                      placeholder="ex) Zoom, Skype, etc."
                        ></v-text-field>


                        <!--                        <v-btn-->
                        <!--                                color="primary"-->
                        <!--                                to="/teacher/verify"-->
                        <!--                        >-->
                        <!--                            &lt;!&ndash;                            @click="pageDone=true"&ndash;&gt;-->
                        <!--                            Next-->
                        <!--                        </v-btn>-->
                        <!--                        <v-btn @click="e6 = 6" text>-->
                        <!--                            Back-->
                        <!--                        </v-btn>-->
                        <v-btn color="primary"
                               @click="e6 = e6+1">
                            Continue
                        </v-btn>
                        <v-btn @click="e6 = e6-1" text>
                            Back
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                            :complete="e6 > 8"
                            step="8">
                        수업 관련
                    </v-stepper-step>
                    <v-stepper-content step="8">
                        <v-combobox
                                v-model="teacherContent.preferredCoverMaterial"
                                :items="preferredCoverMaterial"
                                label="???"
                                multiple
                                chips
                        ></v-combobox>

                        <v-combobox
                                v-model="teacherContent.preferredLessonStyle"
                                :items="preferredLessonStyle"
                                label="선호 수업방식 (다중 선택 가능)"
                                multiple
                                chips
                        ></v-combobox>
                        <v-combobox
                                v-model="teacherContent.preferredLessonMaterial"
                                :items="preferredLessonMaterial"
                                label="선호 교재"
                                multiple
                                chips
                        ></v-combobox>


                        <!--                        <v-btn-->
                        <!--                                color="primary"-->
                        <!--                                to="/teacher/verify"-->
                        <!--                        >-->
                        <!--                            &lt;!&ndash;                            @click="pageDone=true"&ndash;&gt;-->
                        <!--                            Next-->
                        <!--                        </v-btn>-->
                        <!--                        <v-btn @click="e6 = 6" text>-->
                        <!--                            Back-->
                        <!--                        </v-btn>-->
                        <v-btn color="primary"
                               @click="e6 = e6+1">
                            Continue
                        </v-btn>
                        <v-btn @click="e6 = e6-1" text>
                            Back
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                            :complete="e6 > 9"
                            step="9">
                        Teacher Information
                    </v-stepper-step>
                    <v-stepper-content step="9">
                        <v-select
                                v-model="teacherContent.location"
                                :items="countryItems"
                                :rules="[v => !!v || 'Item is required']"
                                label="현재 거주 국가는 어디신가요?"
                                required
                        ></v-select>
                        <v-text-field v-if="teacherContent.location == '직접입력'"
                                      v-model="currentLocation"
                                      label="거주국가"
                                      required>
                        </v-text-field>
                        <v-textarea
                                outlined
                                v-model="teacherContent.personalIntro"
                                label="자기 소개 (위 내용 외 추가 내용위주로만 작성 해주세요."
                                flat
                                placeholder="ex) 미국 현지 동부 고등학생들 위주로 과외 경험이 있어요."
                        ></v-textarea>
                        <v-text-field
                                v-model="teacherContent.kakaoId"
                                label="KAKAO ID (핸드폰 번호와 다를 시)"
                                prepend-icon="mdi-account-box-multiple"
                                required>
                        </v-text-field>


                        <!--                        <v-btn-->
                        <!--                                color="primary"-->
                        <!--                                to="/teacher/
                        "-->
                        <!--                        >-->
                        <!--                            &lt;!&ndash;                            @click="pageDone=true"&ndash;&gt;-->
                        <!--                            Next-->
                        <!--                        </v-btn>-->
                        <!--                        <v-btn @click="e6 = 6" text>-->
                        <!--                            Back-->
                        <!--                        </v-btn>-->
                        <v-btn color="primary"
                               @click="e6 = e6+1">
                            Continue
                        </v-btn>
                        <v-btn @click="e6 = e6-1" text>
                            Back
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                            :complete="e6 > 10"
                            step="10">
                        완료
                    </v-stepper-step>
                    <v-stepper-content step="10">
                        <v-btn
                                color="primary"
                                @click="updateTeacherInfo(currentUser.id, teacherContent)"
                        >
                            <!--                        <v-btn color="primary"-->
                            <!--                                @click="updateTeacherInfo(currentUser.id, teacherContent)">-->
                            <!--                            Continue-->
                            <!--                            @click="pageDone=true"-->
                            Next
                        </v-btn>
                        <v-btn @click="e6 = 9" text>
                            Cancel
                        </v-btn>
                    </v-stepper-content>


                </v-stepper>

                <!--            <v-btn @click="updateTeacherInfo(currentUser.id, teacherContent)"> GO !</v-btn>-->
                <!--            {{pageDone}}-->
            </v-card>
        </v-container>
        <v-container v-if="currentUser.roles[0] != 'ROLE_TEACHER'">

            <RestrictedAcess></RestrictedAcess>
        </v-container>

    </v-container>
</template>

<script>
    import TeacherService from '../../services/teacher.service'
    import RestrictedAcess from "../helpers/RestrictedAcess";
    import info from "../../../info";

    export default {
        name: "TeacherRegister",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        components: {
            RestrictedAcess,
        },
        mounted() {
            this.fruitsCopy = [...this.schools];

            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.teacherContent.subjectsOfExpertise = this.removeEmptyStringFromList(this.teacherContent.subjectsOfExpertise);
                    this.teacherContent.availableTimes = this.removeEmptyStringFromList(this.teacherContent.availableTimes);
                    this.teacherContent.availableDays = this.removeEmptyStringFromList(this.teacherContent.availableDays);
                    this.teacherContent.preferredLessonStyle = this.removeEmptyStringFromList(this.teacherContent.preferredLessonStyle);
                    this.teacherContent.preferredLessonMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredLessonMaterial);
                    this.teacherContent.preferredCoverMaterial = this.removeEmptyStringFromList(this.teacherContent.preferredCoverMaterial);
                    this.teacherContent.selectedMathSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedMathSubSubjects);
                    this.teacherContent.selectedScienceSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedScienceSubSubjects);
                    this.teacherContent.selectedEnglishSubSubjects = this.removeEmptyStringFromList(this.teacherContent.selectedEnglishSubSubjects)
                    console.log(this.teacherContent.detailedLessonTimes)
                    console.log(JSON.parse(this.teacherContent.detailedLessonTimes))
                    this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)
                    console.log(this.teacherContent.detailedLessonTimes)

                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data() {
            return {
                searchTerm: "",
                hasRemoteExpereince: false,
                preferredLessonDays: info.preferredLessonDays,
                preferredLessonTimes: info.preferredLessonTimes,
                demandingHoursPerWeek: info.demandingHoursPerWeek,
                demandingTotalDuration: info.demandingTotalDuration,
                countryItems: info.countryItems,
                e6: 1,
                currentLocation: '',
                pageDone: false,
                addSchool: false,
                detailedLessonTimes: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: '',
                    saturday: '',
                    sunday: '',

                },
                undergrad: '',
                teacherRegisterData: {
                    subjects: [],
                    selectedSchools: [],
                    highSchool: [],
                    currentRegion: [],
                },
                preferredCoverMaterial: info.teacherRegister.preferredCoverMaterial,
                preferredLessonMaterial: info.teacherRegister.preferredLessonMaterials,
                preferredLessonStyle: info.teacherRegister.preferredLessonStyles,
                mainSubjects: info.mainSubjects,
                mathSubSubjects: info.mathSubSubjects,
                selectedMathSubSubjects: [],
                selectedScienceSubSubjects: [],
                selectedEnglishSubSubjects: [],
                scienceSubSubjects: info.scienceSubSubjects,
                englishSubSubjects: info.englishSubSubjects,
                select: [],
                highestLevelOfEducation: info.teacherRegister.highestLevelOfEducation,
                items: info.teacherRegister.subjectsOfExpertise,
                teacherContent: "",
                gradSchoolType: info.gradSchoolType,
                schools: info.americanUniversities,
                timeSlots: info.timeSlots,
                days: info.availableDays,
                possibleHighSchoolTypes: info.possibleHighSchoolTypes,
                next: false,
                fruitsCopy: [],
                preferredLessonTimeSlots: info.preferredLessonTimeSlots,
            }
        },
        methods: {
            searchFruits() {
                if (!this.searchTerm) {
                    this.schools = this.fruitsCopy;
                }

                this.schools = this.fruitsCopy.filter((fruit) => {
                    return fruit.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
                });
            },


            combineSubSubjects() {
                // const tempArray = [];
                // tempArray.concat(this.selectedMathSubSubjects, this.selectedScienceSubSubjects, this.selectedEnglishSubSubjects);
                this.teacherContent.subSubjectsOfExpertise = [...this.selectedMathSubSubjects, ...this.selectedScienceSubSubjects, ...this.selectedEnglishSubSubjects];
                this.e6 = 5;
            },

            moveToMain() {
                this.$router.push('/')
            },

            sayHello() {
                window.alert("hello")
            },

            remove(item) {
                this.chips.splice(this.chips.indexOf(item), 1)
                this.chips = [...this.chips]
            },

            removeEmptyStringFromList(input) {
                return input.filter(element => {
                    return element !== '';
                });
            },
            stringToList(input) {
                return input.split(";").filter(element => {
                    return element !== '';
                });
            },

            updateTeacherInfo(userId, requestBody) {
                if (this.teacherContent.location == '직접입력') {
                    this.teacherContent.location = this.currentLocation;
                }
                if (this.teacherContent.undergrad == '직접 입력') {
                    this.teacherContent.undergrad = this.undergrad;
                }
                requestBody.detailedLessonTimes = JSON.stringify(this.teacherContent.detailedLessonTimes);
                requestBody.undergradMajor = this.teacherContent.undergradMajor;
                TeacherService.updateTeacherInfo(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/teacher/verify")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }

        }
    }
</script>

<style scoped>

</style>
