<template>
    <div>
    <div class="hidden-lg-and-up slideshow-container" style="">


        <div :class="{ 'slide': true, 'show': currentIndex === 0 }"
             :style="slideTransitionStyle(0)" class=" center-vertical ">

            <div class="text-left px-5 slide-card hidden-lg-and-up">
                <div
                        class="center-vertical center-horizontal teacher-tag"

                >
                    선생님 정보
                </div>
                <!-- Your overflowing content goes here -->
                <div class="text-left card-container" style="">
                    <div class="center-horizontal text-center column1 pt-2">
                        <img height="120px" style="overflow: hidden" src="../../../assets/선생님avatar.png">
                        <div class="text-center" style=";font-size: 16px; font-weight: bold">김00 선생님</div>

                    </div>
                    <div class="center-horizontal text-center column2 pt-5">
                        <div class="ma-1" style="">한국 사립고</div>
                        <div class="ma-1" style="">Cornell University</div>
                        <div class="ma-1" style="">Computer Science</div>
                        <div class="ma-1" style="">SAT Score: 1590</div>
                    </div>

                </div>

            </div>


        </div>
        <div :class="{ 'slide': true, 'show': currentIndex === 1 }"
             :style="slideTransitionStyle(1)" class=" center-vertical ">
            <div class="text-left px-5 slide-card hidden-lg-and-up">
                <div
                        class="center-vertical center-horizontal teacher-tag"

                >
                    선생님 정보
                </div>
                <div class="text-left card-container" style="">
                    <div class="center-horizontal text-center column1">
                        <img height="120px" style="overflow: hidden" src="../../../assets/male-teacher.png">
                        <div class="pt-2 text-center" style=";font-size: 16px; font-weight: bold">이00 선생님</div>

                    </div>
                    <div class="center-horizontal text-center column2 pt-5">
                        <div class="ma-1" style="">미국 공립고</div>
                        <div class="ma-1" style="">UPenn 학부 졸업, 석사 재학</div>
                        <div class="ma-1" style="">Chemistry</div>
                        <div class="ma-1" style="">AP Bio, Chem: 5점</div>
                    </div>
                </div>

            </div>
            <div class="text-left px-5 mt-2 slide-card-pc hidden-md-and-down">
                <div
                        class="center-vertical center-horizontal teacher-tag"

                >
                    선생님 정보
                </div>
                <div class="text-left card-container" style="">
                    <div class="center-horizontal text-center column1">
                        <img height="120px" style="overflow: hidden" src="../../../assets/male-teacher.png">
                        <div class="text-center" style=";font-size: 16px; font-weight: bold">이00 선생님</div>

                    </div>
                    <div class="center-horizontal text-center column2 pt-5">
                        <div class="ma-1" style="">미국 공립고</div>
                        <div class="ma-1" style="">UPenn 학부 졸업, 석사 재학</div>
                        <div class="ma-1" style="">Chemistry</div>
                        <div class="ma-1" style="">국제학교 내신관리 전문</div>
                    </div>

                </div>

            </div>

        </div>

    </div>
    <div class="hidden-md-and-down slideshow-container-desktop pt-2" style="">


        <div :class="{ 'slide': true, 'show': currentIndex === 0 }"
             :style="slideTransitionStyle(0)" class=" center-vertical ">


            <div class="text-left px-5  slide-card-desktop">
                <div
                        class="center-vertical center-horizontal teacher-tag"

                >
                    선생님 정보
                </div>
                <!-- Your overflowing content goes here -->
                <div class="text-left card-container" style="">
                    <div class="center-horizontal text-center column1 pt-2">
                        <img height="150px" style="overflow: hidden" src="../../../assets/선생님avatar.png">
                        <div class="text-center" style=";font-size: 16px; font-weight: bold">김00 선생님</div>

                    </div>
                    <div class="center-horizontal text-center column2 description-desktop pt-5">
                        <div class="ma-1" style="">한국 사립고</div>
                        <div class="ma-1" style="">Cornell University</div>
                        <div class="ma-1" style="">Computer Science</div>
                        <div class="ma-1" style="">SAT Score: 1590</div>
                    </div>

                </div>


            </div>


        </div>
        <div :class="{ 'slide': true, 'show': currentIndex === 1 }"
             :style="slideTransitionStyle(1)" class=" center-vertical ">

            <div class="text-left px-5  slide-card-desktop">
                <div
                        class="center-vertical center-horizontal teacher-tag"

                >
                    선생님 정보
                </div>
                <div class="text-left card-container" style="">
                    <div class="center-horizontal text-center column1">
                        <img height="150px" style="overflow: hidden" src="../../../assets/male-teacher.png">
                        <div class="" style="padding-top: 10px"></div>
                        <div class="text-center" style=";font-size: 16px; font-weight: bold">이00 선생님</div>
                    </div>
                    <div class="center-horizontal text-center column2  description-desktop pt-5">
                        <div class="ma-1" style="">미국 공립고</div>
                        <div class="ma-1" style="">UPenn 학부 졸업, 석사 재학</div>
                        <div class="ma-1" style="">Chemistry</div>
                        <div class="ma-1" style="">국제학교 내신관리 전문</div>
                    </div>
                    <!-- Your overflowing content goes here -->
                    <!--                    <v-row>-->
                    <!--                        <v-col cols="4" class="text-center">-->
                    <!--                            <div class="center-horizontal text-center">-->
                    <!--                                <img height="120px" style="overflow: hidden" src="../../../assets/Artboard3.png">-->
                    <!--                                <div class="text-center" style=";font-size: 16px; font-weight: bold">이00 학생</div>-->

                    <!--                            </div>-->

                    <!--                        </v-col>-->
                    <!--                        <v-col cols="8" style="font-size: 13px" class="py-6 pl-6">-->
                    <!--                            <div class="ma-1" style="line-height: 20px">고등학교 3학년</div>-->
                    <!--                            <div class="ma-1" style="line-height: 20px">한국 사립고</div>-->
                    <!--                            <div class="ma-1" style="line-height: 20px">주중 19시 이후 희망</div>-->
                    <!--                            <div class="ma-1" style="line-height: 20px">영어 / 한국어 혼용</div>-->
                    <!--                            <div class="ma-1" style="line-height: 20px">SAT MATH를 집중적으로 배우고 싶어요</div>-->
                    <!--                        </v-col>-->
                    <!--                    </v-row>-->
                </div>

            </div>

        </div>

    </div>
    </div>
</template>

<script>
    export default {
        name: "TeacherSlider",
        data() {
            return {
                slides: ["Slide 1", "Slide 2"],
                currentIndex: 0,
            };
        },
        created() {
            setInterval(this.nextSlide, 5500); // Switch to the next slide every 2 seconds
        },
        methods: {
            nextSlide() {
                this.currentIndex = (this.currentIndex + 1) % this.slides.length;
            },
            slideTransitionStyle(index) {
                const totalSlides = this.slides.length;
                const distance = 300; // Adjust this value to set the sliding distance
                const offset = ((index - this.currentIndex + totalSlides) % totalSlides) * distance;
                return {
                    transform: `translateX(${offset}%)`, // Change the sign to move right
                    transition: "transform 1.5s ease-in-out",
                };
            },
        },
    };
</script>

<style scoped>
    .vertical-line {
        width: 2px; /* Adjust the width of the vertical line */
        height: 7px; /* Adjust the height of the vertical line */
        background-color: #d2cef5; /* Change the color of the vertical line */
    }

    .short-vertical-line {
        width: 2px; /* Adjust the width of the vertical line */
        height: 2px; /* Adjust the height of the vertical line */
        background-color: #beb9ee; /* Change the color of the vertical line */
    }

    .slideshow-container {
        /*position: relative;*/
        /*width: 90%;*/
        /*min-height: 400px;*/
        /*overflow: hidden; !* Ensure that only one slide is visible at a time *!*/
        position: relative;
        width: 100%;
        height: 300px;
        overflow: hidden; /* Ensure that only one slide is visible at a time */
    }

    .slide {
        width: 100%;
        /*height: 100%;*/
        text-align: center;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
    }

    .slide-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }

    .show .slide-content {
        opacity: 1;
    }

    /*.slide-card {*/
    /*    height: 210px;*/

    /*    width: 350px;*/
    /*    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;*/
    /*    padding: 20px;*/
    /*    margin-left: 30px;*/
    /*    margin-right:  30px;*/
    /*    !*margin-bottom:  30px;*!*/
    /*    margin-top: 10px;*/
    /*    border-radius: 10px;*/
    /*    background-color: white;*/

    /*}*/
    .slide-card {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        /*padding: 20px;*/
        /*margin-top: 30px;*/
        /*margin-left: 30px;*/
        /*margin-right: 30px;*/
        /*margin-bottom: 20px;*/
        border-radius: 10px;
        background-color: white;
        width: 90%;
        height: 210px;
        margin-top: 5px;
        max-width: 450px;
        max-height: 250px;
        /*font-family: S-CoreDream-4Regular;*/
        font-family: S-CoreDream-5Medium;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /*.teacher-tag {*/
    /*    font-weight: bold;*/
    /*    height: 13%;*/
    /*    border-radius: 5px;*/
    /*    background-color: #e98295;*/
    /*    color: white;*/
    /*    width: 32%;*/
    /*    right: 30px;*/
    /*    top: 5px;*/
    /*    height: 40px;*/

    /*    position: absolute;*/
    /*    z-index: 1;*/
    /*    font-size: 17px;*/
    /*    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);*/
    /*}*/
    .teacher-tag {
        font-weight: bold;
        /*height: 13%;*/
        border-radius: 5px;
        background-color: #e98295;
        color: white;
        width: 32%;
        right: -76%;
        top: -3%;
        height: 40px;
        font-size: 13px;

        position: relative;
        z-index: 1;
        /*font-size: 17px;*/
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .circle-with-checkmark {
        position: relative;
        width: 20px;
        height: 20px;
        border: 2px solid #5b58f6; /* Circle border color */
        border-radius: 50%; /* Make it a circle */
        background-color: #5b58f6; /* Circle background color (white in this case) */
    }

    .circle-with-checkmark::before {
        content: "\2714"; /* Checkmark unicode character */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 13px;
        color: #ffffff !important; /* Checkmark color (white in this case) */
    }

    .card-container {
        display: flex;
    }

    .column1 {
        flex: 1; /* Column 1 takes 1/3 of the container width */
        background-color: #ffffff;
        margin-top: -18px !important;
    }

    .column2 {
        flex: 2; /* Column 2 takes 2/3 of the container width */
        background-color: #ffffff;
        font-size: 13px;
        /*margin-top: -18px !important;*/
    }

    .slide-card-pc {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        /*padding: 20px;*/
        /*margin-top: 30px;*/
        /*margin-left: 30px;*/
        /*margin-right: 30px;*/
        /*margin-bottom: 20px;*/
        border-radius: 10px;
        background-color: white;
        width: 50%;
        height: 210px;
    }
    .slideshow-container-desktop {
        position: relative;
        width: 100%;
        min-height: 333px;
        overflow: hidden; /* Ensure that only one slide is visible at a time */
    }
    .slide-card-desktop {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        /*padding: 20px;*/
        /*margin-top: 30px;*/
        /*margin-left: 30px;*/
        /*margin-right: 30px;*/
        /*margin-bottom: 20px;*/
        border-radius: 10px;
        background-color: white;
        width: 450px;
        height: 250px;
        font-family: S-CoreDream-5Medium;
    }
    .description-desktop{
        font-size: 14px !important;
    }
</style>
