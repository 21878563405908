<template>
    <div>
        <v-btn
                fixed
                elevation="5"
                fab
                right
                bottom
                class="test-container hidden-xs-only chatting"
                href="https://pf.kakao.com/_NxlSSb/chat"
                target="_blank"
        >
            <div class="chatting white--text" style="letter-spacing: 0px; font-weight: bold; font-family: 'NEXON Lv1 Gothic OTF Bold'; ">
                채팅<br/>문의

            </div>
            <!--            <v-icon color="#ffe812"-->
<!--                    size="40">$vuetify.icons.kakao-->
<!--            </v-icon>-->
        </v-btn>
        <v-btn
                fixed
                elevation="5"
                fab
                right
                bottom
                class="mobile-container hidden-sm-and-up chatting"
                href="https://pf.kakao.com/_NxlSSb/chat"
                target="_blank"
        >
            <div class="chatting white--text" style="letter-spacing: 0px; font-weight: bold; font-family: 'NEXON Lv1 Gothic OTF Bold' ">
                채팅<br/>문의
            </div>
<!--            <v-icon color="#ffe812"-->
<!--                    size="40">$vuetify.icons.kakao-->
<!--            </v-icon>-->
        </v-btn>

    </div>
</template>

<script>
    export default {
        name: "KakaoFloatingButton"
    }
</script>

<style scoped>
    .test-container {
        bottom: 100px;
        right: 26px;
        position: fixed;
        color: #00FF00;
    }

    .mobile-container {
        bottom: 90px;
        right: 15px;
        position: fixed;
        color: #00FF00;

    }
    .chatting {
        background-image: linear-gradient(92.88deg, #195A84 9.16%, #2E6686 43.89%, #4A6E84 64.72%);
        /*background-image: linear-gradient(92.88deg, #b71c1c 9.16%, #c23e3e 43.89%, #cd6060 64.72%);*/

    }
</style>
