<template>
    <div>
        <v-speed-dial
                v-model="fab1"
                :top="top1"
                :bottom="bottom1"
                :right="right1"
                :left="left1"
                :direction="direction1"
                :transition="transition1"
                class="mobile-container hidden-sm-and-up white--text font-weight-bold"
        >
            <v-overlay
                    :value="overlay1"
                    :z-index="zIndex1"
                    :opacity="0.9"
            ></v-overlay>

            <template v-slot:activator>
                <v-btn
                        v-model="fab1"
                        class="main-btn-browser"
                        dark
                        fab
                >

                    <v-icon
                            v-if="fab1"
                            @click="overlay1 = false">
                        mdi-close
                    </v-icon>
                    <span @click="overlay1=true" v-else>
                        전화<br/>
                        상담
                    </span>

                </v-btn>


            </template>

            <v-btn
                    fab
                    dark
                    color="#FFFFFF"
                    href="/schedule/call"
            >
                <div style="margin-right: 85px">
                    상담 예약
                    <span class="mx-2"></span>
                    <v-icon color="primary" dark>mdi-alarm</v-icon>

                </div>
            </v-btn>
            <v-btn
                    fab
                    dark
                    color="#FFFFFF"
                    href="tel:070-8970-5223"

            >
                <div style="margin-right: 85px">
                    바로 상담
                    <span class="mx-2"></span>
                    <v-icon color="primary" dark>mdi-phone-outgoing</v-icon>

                </div>
            </v-btn>

        </v-speed-dial>
        <!--        <v-btn-->
        <!--                fixed-->
        <!--                elevation="5"-->
        <!--                fab-->
        <!--                right-->
        <!--                bottom-->
        <!--                class="mobile-container hidden-sm-and-up white&#45;&#45;text font-weight-bold"-->
        <!--                href="tel:070-8970-5223"-->
        <!--                target="_blank"-->
        <!--                color="#5C060D"-->


        <!--        >-->
        <!--            <div class="text-center center-horizontal center-vertical" style="font-size: 15px;">-->
        <!--                전화<br/>-->
        <!--                상담-->
        <!--                &lt;!&ndash;            <v-icon>&ndash;&gt;-->
        <!--                &lt;!&ndash;                mdi-phone&ndash;&gt;-->
        <!--                &lt;!&ndash;            </v-icon>&ndash;&gt;-->
        <!--            </div>-->
        <!--            &lt;!&ndash;        <v-icon color="#ffe812"&ndash;&gt;-->
        <!--            &lt;!&ndash;                size="30">$vuetify.icons.kakao&ndash;&gt;-->
        <!--            &lt;!&ndash;        </v-icon>&ndash;&gt;-->
        <!--        </v-btn>-->

        <v-speed-dial
                v-model="fab"
                :top="top"
                :bottom="bottom"
                :right="right"
                :left="left"
                :direction="direction"
                :transition="transition"
                class="big-container hidden-xs-only white--text font-weight-bold"
        >
            <v-overlay
                    v-model="overlay"
                    :z-index="zIndex"
                    :opacity="0.9"

            ></v-overlay>

            <template v-slot:activator>

                <v-btn
                        v-model="fab"
                        class="main-btn-browser"
                        dark
                        fab
                >

                    <v-icon
                            v-if="fab"
                            @click="overlay = false"
                    >
                        mdi-close
                    </v-icon>


                    <span @click="overlay=true" v-else>
                        전화<br/>
                        상담
                    </span>
                </v-btn>


            </template>
            <v-row>
                <v-col>
                    <v-btn
                            fab
                            dark
                            href="/schedule/call"
                            color="#FFFFFF">
                        <div style="margin-right: 85px">
                            상담 예약
                            <span class="mx-2"></span>
                            <v-icon color="primary" dark>mdi-alarm</v-icon>
                        </div>
                    </v-btn>
                </v-col>
            </v-row>

            <v-btn
                    fab
                    dark
                    color="#FFFFFF"
                    href="tel:070-8970-5223"

            >
                <div style="margin-right: 85px">
                    바로 상담
                    <span class="mx-2"></span>
                    <v-icon color="primary" dark>mdi-phone-outgoing</v-icon>

                </div>
            </v-btn>

        </v-speed-dial>
        <!--        <v-btn-->
        <!--                fixed-->
        <!--                elevation="5"-->
        <!--                fab-->
        <!--                right-->
        <!--                bottom-->
        <!--                class="big-container hidden-xs-only white&#45;&#45;text font-weight-bold"-->
        <!--                href="tel:070-8970-5223"-->
        <!--                target="_blank"-->
        <!--                color="#5C060D"-->


        <!--        >-->
        <!--            <div class="text-center center-horizontal center-vertical" style="font-size: 15px;">-->
        <!--                전화<br/>-->
        <!--                상담-->
        <!--                &lt;!&ndash;            <v-icon>&ndash;&gt;-->
        <!--                &lt;!&ndash;                mdi-phone&ndash;&gt;-->
        <!--                &lt;!&ndash;            </v-icon>&ndash;&gt;-->
        <!--            </div>-->
        <!--            &lt;!&ndash;        <v-icon color="#ffe812"&ndash;&gt;-->
        <!--            &lt;!&ndash;                size="30">$vuetify.icons.kakao&ndash;&gt;-->
        <!--            &lt;!&ndash;        </v-icon>&ndash;&gt;-->
        <!--        </v-btn>-->

    </div>

</template>

<script>
    export default {
        name: "ContactButtonSmall",
        data: () => ({
            direction: 'top',
            fab: false,
            fling: false,
            hover: false,
            tabs: null,
            top: false,
            right: true,
            bottom: true,
            left: false,
            transition: 'slide-y-reverse-transition',
            absolute: true,
            overlay: false,
            zIndex: 0,


            direction1: 'top',
            fab1: false,
            fling1: false,
            hover1: false,
            tabs1: null,
            top1: false,
            right1: true,
            bottom1: true,
            left1: false,
            transition1: 'slide-y-reverse-transition',
            absolute1: true,
            overlay1: false,
            zIndex1: 0,


            dialog: false,
            notifications: false,
            sound: true,
            widgets: false,


        }),

        watch: {
            // overlay (val) {
            //     val && setTimeout(() => {
            //         this.overlay = false
            //     }, 20000)
            // },
            top(val) {
                this.bottom = !val
            },
            right(val) {
                this.left = !val
            },
            bottom(val) {
                this.top = !val
            },
            left(val) {
                this.right = !val
            },
        },
    }
</script>

<style scoped>
    .big-container {
        /*background-image: linear-gradient(92.88deg, #b71c1c 9.16%, #c23e3e 43.89%, #cd6060 64.72%);*/
        color: black !important;
        font-family: "NEXON Lv1 Gothic OTF Bold";
        line-height: 1px;
        bottom: 170px;
        right: 26px;
        position: fixed;
    }

    .main-btn-browser {
        background-image: linear-gradient(92.88deg, #b71c1c 9.16%, #c23e3e 43.89%, #cd6060 64.72%);
        font-family: "NEXON Lv1 Gothic OTF Bold";

    }

    /** This is for documentation purposes and will not be needed in your application *!*/
    /*#create .v-speed-dial {*/
    /*    position: absolute;*/
    /*}*/

    /*#create .v-btn--floating {*/
    /*    position: relative;*/
    /*}*/
    .mobile-container {
        /*background-image: linear-gradient(144deg, #DE2828, #A20916 50%, #fffffb);*/
        /*background-image: #5C060D;*/
        /*background-image: linear-gradient(92.88deg, #b71c1c 9.16%, #c23e3e 43.89%, #cd6060 64.72%);*/
        font-family: "NEXON Lv1 Gothic OTF Bold";
        line-height: 1px;
        bottom: 160px;
        right: 15px;
        position: fixed;
        /*color: #00FF00;*/

    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

</style>
