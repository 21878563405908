<template>
<img src="../../assets/youtube.png">
</template>

<script>
    export default {
        name: "YoutubeIcon"
    }
</script>

<style scoped>

</style>
