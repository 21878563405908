<template>


    <div class="container">

        <div class="timeline-item" date-is='지원서 접수'>
            <div style="font-size: 12px">
                간단한 정보와 지원 동기를 작성합니다.
            </div>
        </div>

        <div class="timeline-item" date-is='온라인 상담 및 인터뷰'>
            <div style="font-size: 12px">

            아이비에드 상담사와 1:1 상담 인터뷰를 진행합니다.
            </div>
        </div>

        <div class="timeline-item" date-is=' 합격 & 사전준비 '>
            <div style="font-size: 12px">

            최종 합격 후, 사전 강의, 사전스터디, 토이프로젝트에 참여 하면서 AP CS를 준비합니다.
            </div>
        </div>
        <div class="timeline-item" date-is=' 부트캠프 시작 '>
            <div style="font-size: 12px">

            8주간의 여정을 시작합니다.
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Timeline"
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Oswald|Roboto:400,700');
    body {
        font-size: 14px;
        line-height: 1.5;
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: 'Oswald', sans-serif;
    }
    h1 {
        font-size: 2rem;
        margin-bottom: 0.5em;
    }
    p {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
    }
    .container {
        max-width: 1024px;
        width: 96%;
        margin: 0 auto;
    }
    .timeline-item {
        padding: 3em 2em 2em;
        position: relative;
        color: rgba(0, 0, 0, .7);
        border-left: 2px solid rgba(0, 0, 0, .3);
    }
    .timeline-item p {
        font-size: 1rem;
    }
    .timeline-item::before {
        content: attr(date-is);
        position: absolute;
        left: 2em;
        font-weight: bold;
        top: 1em;
        display: block;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 0.785rem;
    }
    .timeline-item::after {
        width: 10px;
        height: 10px;
        display: block;
        top: 1em;
        position: absolute;
        left: -7px;
        border-radius: 10px;
        content: '';
        border: 2px solid rgba(0, 0, 0, .3);
        background: white;
    }
    .timeline-item:last-child {
        border-image: linear-gradient(to bottom, rgba(0, 0, 0, .3) 60%, rgba(0, 0, 0, 0)) 1 100%;
    }

</style>
