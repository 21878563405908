<template>
    <div class="slider" style="">
        <div class="slide-track">
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/dartmouth-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/ivy-logo-1.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/yale-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/stanford-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/dartmouth-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/ivy-logo-1.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/yale-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/stanford-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/dartmouth-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/ivy-logo-1.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/yale-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/stanford-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/dartmouth-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/ivy-logo-1.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/yale-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/stanford-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/dartmouth-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/ivy-logo-1.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/yale-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/stanford-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/dartmouth-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/ivy-logo-1.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/yale-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/stanford-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/dartmouth-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/ivy-logo-1.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/yale-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/stanford-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/dartmouth-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/ivy-logo-1.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/yale-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/stanford-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/brown-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/dartmouth-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/ivy-logo-1.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/yale-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/stanford-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/harvard-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/upenn-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/cornell-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
            <div class="slide">
                <div class="circle center-vertical center-horizontal">
                    <img src="../../assets/princeton-logo.png" height="77%" width="77%" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SchoolCarousel"
    }
</script>

<style scoped>
    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        77% {
            transform: translateX(calc(-250px * 7));
        }
    }
    .slider {
        /*box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);*/
        height: 100px;
        margin: auto;
        overflow: hidden;
        position: relative;
        /*width: 960px;*/
    }
    .slider::before, .slider::after {
        /*background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 77%);*/
        content: "";
        height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }
    .slider::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }
    .slider::before {
        left: 0;
        top: 0;
    }
    .slider .slide-track {
        animation: scroll 40s linear infinite;
        display: flex;
        width: calc(250px * 14);
    }
    .slider .slide {
        /*background-color: #F6F6F6;*/
        height: 100px;
        width: 120px;
    }
    .circle {
        background: transparent;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        /*box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;*/
    }
</style>
