<template>
    <div class="">
        <!-- Add Credit dialog -->
        <v-dialog v-model="addCreditDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">Add Credit</v-card-title>
                <v-card-text>
                    <v-form ref="addCreditForm" lazy-validation>
                        <v-text-field v-model="creditToAdd" label="Number of Credits to Add" type="number"
                                      required></v-text-field>
                        <v-select v-model="selectedClass" :items="classOptions" label="Select Class"
                                  required></v-select>
                        <v-select v-model="selectedMainSubject" :items="mainSubjectOptions" label="Select Main Subject"
                                  required></v-select>
                        <v-select v-model="selectedSubSubject" :items="subSubjectOptions" label="Select Sub Subject"
                                  required></v-select>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeAddCreditDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" @click="submitAddCredit">Submit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="teacherInfoDialog" max-width="900px">
            <v-card>
                <v-card-title class="headline">Teacher Information</v-card-title>
                <v-card-text>
                    <table class="teacher-table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>First Name</th>
                            <th>Email</th>
                            <!--<th>Contact</th>-->
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="teacher in teachers" :key="teacher.id">
                            <td>{{ teacher.id }}</td>
                            <td>{{ teacher.firstName }}</td>
                            <td>{{ teacher.email }}</td>
                            <!--<td>{{ // teacher.contact }}</td>-->

                            <td>
                                <button @click="createNewLesson(selectedStudentForMatch.id, teacher.id)" class="action-button">Match</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="closeTeacherInfoDialog">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="py-6"></div>
        <div class="pa-5 text-center" style="font-size: 25px;">
            체험수업 생성
        </div>

        <div class="container">
            <v-text-field v-model="search" label="Search" class="mb-3"></v-text-field>
            <div>각 컬럼 클릭하면 정렬 가능</div>
            <table class="student-table">
                <thead>
                <tr>
                    <th @click="sortTable('id')">ID</th>
                    <th @click="sortTable('fullName')">Full Name</th>
                    <th @click="sortTable('contact')">Contact</th>
                    <th @click="sortTable('email')">Email</th>
                    <th @click="sortTable('studentRegisterStatus')">Status</th>
                    <th @click="sortTable('sumOfRemainingClasses')">잔여수강권</th>
                    <th>Add Credit</th>
                    <th>Match Teacher</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="student in filteredStudents" :key="student.id">
                    <td>{{ student.id }}</td>
                    <td>{{ student.fullName }}</td>
                    <td>{{ student.contact }}</td>
                    <td>{{ student.email }}</td>
                    <td>{{ student.studentRegisterStatus }}</td>
                    <td>{{ student.sumOfRemainingClasses }}</td>
                    <td>
                        <button @click="openAddCreditDialog(student)" class="action-button">Add Credit</button>
                    </td>
                    <td>
                        <button
                                @click="openTeacherInfoDialog(student.id)"
                                class="action-button"
                                :disabled="student.sumOfRemainingClasses <= 0"
                                :style="{ opacity: student.sumOfRemainingClasses <= 0 ? '0.5' : '1', cursor: student.sumOfRemainingClasses <= 0 ? 'not-allowed' : 'pointer' }"
                        >
                            Match Teacher
                        </button>


                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import StudentService from '../../services/student.service';
    import UserService from '../../services/user.service';
    import LessonService from '../../services/lesson.service';

    import info from "../../../info";

    export default {
        name: "MakeTrialClass",
        components: {},
        computed: {
            filteredStudents() {
                const searchTerm = this.search.toLowerCase();
                const filtered = this.students.filter(student =>
                    (student.fullName?.toLowerCase().includes(searchTerm) || false) ||
                    (student.contact?.toLowerCase().includes(searchTerm) || false) ||
                    (student.email?.toLowerCase().includes(searchTerm) || false) ||
                    (student.studentRegisterStatus?.toLowerCase().includes(searchTerm) || false)
                    // (student.sumOfRemainingClasses?.toString().toLowerCase().includes(searchTerm) || false)
                );

                return this.sortColumn ? filtered.sort(this.sortMethod) : filtered;
            },

        },
        methods: {

            createNewLesson(studentId, teacherId) {

                const requestBody = {
                    studentId: studentId+"",
                    teacherId: teacherId+"",
                };
                console.log(requestBody);
                LessonService.createNewLesson(requestBody).then(
                    (response) => {

                        this.lessons = response.data;
                        console.log(response.data)
                    },
                    (error) => {
                        this.lessons =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
                this.teacherInfoDialog = false;
                this.$router.push("/admin/lessons/scheduled");

            },

            getAllTeachers() {
                UserService.getAllUserTeachers().then(
                    (response) => {

                        this.teachers = response.data;
                        console.log(response.data)
                    },
                    (error) => {
                        this.userTeachers =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            openTeacherInfoDialog(studentId) {
                this.getAllTeachers();
                this.teacherInfoDialog = true;
                // Set the selected student for matching
                this.selectedStudentForMatch = this.students.find((student) => student.id === studentId);
            },

            closeTeacherInfoDialog() {
                this.teacherInfoDialog = false;
                // Reset the selected student after closing the dialog
                this.selectedStudentForMatch = null;
            },

            matchTeacherWithSelected(teacher) {
                // Implement the logic for matching the selected teacher with the selected student
                console.log(`Matching teacher ${teacher.fullName} with student ${this.selectedStudentForMatch.fullName}`);
                // Close the dialog after matching
                this.closeTeacherInfoDialog();
            },
            sortTable(column) {
                if (this.sortColumn === column) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortColumn = column;
                    this.sortDirection = true;
                }
            },
            sortMethod(a, b) {
                const modifier = this.sortDirection ? 1 : -1;

                if (a[this.sortColumn] < b[this.sortColumn]) return -1 * modifier;
                if (a[this.sortColumn] > b[this.sortColumn]) return 1 * modifier;
                return 0;
            },

            matchTeacher(studentId) {
                // Implement the logic for matching a teacher for the selected student
                console.log(`Matching teacher for student with id ${studentId}`);
            },
            addCredit(requestBody, userId) {
                StudentService.addCreditToStudent(requestBody, userId).then(
                    (response) => {
                        console.log(response.data)
                        this.$router.go(0);


                    },
                    (error) => {
                        this.response =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            openAddCreditDialog(student) {
                // You may want to initialize dropdown options here based on your data
                // For simplicity, I'm assuming you have methods like getClassOptions, getMainSubjectOptions, getSubSubjectOptions

                // this.classOptions = this.getClassOptions(); // Implement this method to fetch class options
                // this.mainSubjectOptions = this.getMainSubjectOptions(); // Implement this method to fetch main subject options
                // this.subSubjectOptions = this.getSubSubjectOptions(); // Implement this method to fetch sub subject options

                // Reset fields when opening the dialog
                this.creditToAdd = null;
                this.selectedClass = null;
                this.selectedMainSubject = null;
                this.selectedSubSubject = null;

                // Set the selected student for whom the credit is being added
                this.selectedStudent = student;

                this.addCreditDialog = true;
            },
            closeAddCreditDialog() {
                this.addCreditDialog = false;
            },
            submitAddCredit() {
                // Validate the form before submitting
                if (this.$refs.addCreditForm.validate()) {
                    const requestBody = {
                        credit: this.creditToAdd + "",
                        className: this.selectedClass + "",
                        subjectOfInterest: this.selectedMainSubject + "",
                        subSubjectOfInterest: this.selectedSubSubject + "",
                    };
                    console.log(requestBody);

                    // Assuming student.id is available in the component
                    const userId = this.selectedStudent.id;

                    // Call your addCredit method
                    this.addCredit(requestBody, userId);

                    // Close the dialog after submitting
                    this.addCreditDialog = false;

                }
            },

        },
        mounted() {
            StudentService.getAllStudents().then(
                (response) => {
                    this.students = response.data;
                },
                (error) => {
                    this.students =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data: () => ({
            students: [],
            search: '',
            sortColumn: null,
            sortDirection: true,
            response: [],
            addCreditDialog: false,
            creditToAdd: null,
            selectedClass: null,
            selectedMainSubject: null,
            selectedSubSubject: null,
            classOptions: info.classOptions, // Populate this with available class options
            mainSubjectOptions: info.mainSubjects, // Populate this with available main subject options
            subSubjectOptions: info.allOfSubSubjects, // Populate this with available sub subject options
            selectedStudent: null,
            teacherInfoDialog: false,
            teachers: [],
            lessons: [],
            teacherTableHeaders: [
                { text: 'ID', value: 'id' },
                { text: 'firstName', value: 'firstName' },
                { text: 'Email', value: 'email' },
                { text: 'Kakao', value: 'kakaoId' },
                { text: 'Actions', value: 'actions' },
            ],
            selectedStudentForMatch: null,
        }),
    };
</script>

<style scoped>
    .container {
        margin: 20px;
    }

    .student-table {
        border-collapse: collapse;
        width: 100%;
    }

    .student-table th, .student-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
        cursor: pointer;
    }

    .action-button {
        background-color: green;
        color: white;
        border: 1px solid green;
        padding: 5px 10px;
        margin: 5px;
        cursor: pointer;
    }
</style>
