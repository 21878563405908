<template>
    <div>
        <div class=" reveal">
            <div class="center-horizontal">
            <div class="hidden-lg-and-up">
                <v-chip
                        color="#ecced0"
                        text-color="white"
                        class="font-weight-bold"
                >
                    수업 준비
                </v-chip>
                <div class="py-2"></div>
                <v-card elevation="0" width="280px" class="black--text" style="background-color: #eeeeee"
                        max-width="600px">
                    <div class="px-4 py-4"
                         style="font-weight: bold; font-size: 20px; margin-bottom: -10px !important;">
                        과외 신청서 작성
                    </div>
                    <div class="px-4 pb-5">횟수 / 요일 / 원하는 선생님 타입까지</div>
                </v-card>
                <div class="py-1"></div>
                <v-card elevation="0" width="280px" class="black--text" style="background-color: #eeeeee"
                        max-width="600px">
                    <div class="px-4 py-4"
                         style="font-weight: bold; font-size: 20px; margin-bottom: -10px !important;">
                        선생님 매칭
                    </div>
                    <div class="px-4 pb-5">전원 아이비리그 출신 <br/>상위 20위권 대학 포함</div>
                </v-card>
            </div>
            </div>
            <div class="hidden-md-and-down ">
                <v-chip
                        color="#ecced0"
                        text-color="white"
                        class="font-weight-bold"
                        style="justify-content: center; align-items: center; height: 40px; width: 100px;  border-radius: 40px !important; font-size: 16px"
                >
                    수업 준비
                </v-chip>
                <div class="py-2"></div>
                <v-card elevation="0" width="350" height="150px" class="pt-2 center-vertical black--text" style="background-color: #eeeeee"
                        max-width="900px ">
                    <div class="px-4 py-4 "
                         style="font-weight: bold; font-size: 24px; margin-bottom: -10px !important;">
                        과외 신청서 작성
                    </div>
                    <div class="px-4 pb-5" style="font-size: 20px">횟수 / 요일 / 원하는 선생님 타입까지</div>
                </v-card>
                <div class="py-1"></div>
                <v-card elevation="0" width="350" height="150px" class="pt-2 center-vertical black--text" style="background-color: #eeeeee"
                        max-width="600px">
                    <div class="px-4 py-4"
                         style="font-weight: bold; font-size: 24px; margin-bottom: -10px !important;">
                        선생님 매칭
                    </div>
                    <div class="px-4 pb-5" style="font-size: 20px">전원 아이비리그 출신 <br/>상위 20위권 대학 포함</div>
                </v-card>
                <div class="py-5"></div>
            </div>


        </div>

    </div>
</template>

<script>
    export default {
        name: "AboutIvyed2",
        mounted() {
            window.addEventListener("scroll", this.reveal);
        },
        destroyed() {
            window.removeEventListener("scroll", this.reveal);
        },
        methods: {
            reveal() {
                var reveals = document.querySelectorAll(".reveal");

                for (var i = 0; i < reveals.length; i++) {
                    var windowHeight = window.innerHeight;
                    var elementTop = reveals[i].getBoundingClientRect().top;
                    var elementVisible = 150;

                    if (elementTop < windowHeight - elementVisible) {
                        reveals[i].classList.add("active");
                    } else {
                        reveals[i].classList.remove("active");
                    }
                }
            },
        },
    };
</script>

<style>
    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    body {
        /*background: #42455a;*/
    }

    section {
        /*min-height: 100vh;*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .each-title {
        font-size: 21px !important;
        text-align: left !important;
    }

    section:nth-child(1) {
        color: black;
    }

    section:nth-child(2) {
        color: black;
        background: #ffffff !important;
    }

    /* Add more styles for other sections as needed */
    section .container {
        /*margin: 100px;*/
        background-color: #ffffff !important;

    }

    section h1 {
        /*font-size: 3rem;*/
        /*margin: 20px;*/
    }

    section h2 {
        font-size: 40px;
        /*text-align: center;*/
        text-transform: uppercase;
    }

    section .text-container {
        display: flex;
        padding: 20px;
        margin-top: 10px;
    }

    section .text-container .text-box {
        /*margin: 20px;*/
        /*padding: 20px;*/
        /*background: #00c2cb;*/

    }

    section .text-container .text-box h3 {
        font-size: 30px;
        /*text-align: center;*/
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    @media (max-width: 900px) {
        section h1 {
            font-size: 2rem;
            /*text-align: center;*/
        }

        section .text-container {
            flex-direction: column;
        }
    }

    .reveal {
        position: relative;
        transform: translateY(150px);
        opacity: 0;
        transition: 1s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }
    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>

