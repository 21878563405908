const info = {
    home:
        {
            main_description: 'IVY 출신',
            secondary_description: '지금 바로 가입하세요!',
            selling_points: {
                first: "필요한 유학 과외선생님을 찾는데 얼마나 걸리셨나요?",
                answer_001: "더 이상 공부를 도와줄 선생님을 찾는데 시간 낭비 하지 마세요!",
                answer_002: "요청 후 2일안에 학생에게 딱 필요한 선생님을 찾아드려요."
            },
            page_three: {
                title: "성적 관리에만 집중 하세요!",
                subtitle: "이동시간 없이 100% 비대면 과외",
            }
        },
    logo_name: "IVyED",
    policies: {
        termsOfUse: "https://ivyed.notion.site/b186e716ffd443acbccc5ce45717e762",
        faq: "https://ivyed.notion.site/IVYED-FAQ-3d409c7775994ad598ca418c3ad833e6",
        personalInfoHandling: "https://ivyed.notion.site/53679ae3be6d4aad87f2d32438444eaf",
        personalInfoCollect: "https://ivyed.notion.site/6d4248d37ff64ac3bd7f5c553763f5c9",
        marketing: "https://ivyed.notion.site/f25259f37bfd426690222952f5dd5110",
        thirdParty: "https://ivyed.notion.site/3-31aea9057e5f479b91681e5c17b29cfe",
        refundTerms: "https://ivyed.notion.site/0103487dc54a49ba8b8293c8522a9ceb",
    },
    faq: {
        teachingMethod: [
            {
                question: "",
                answer: ""
            },
        ]
    },
    reviews: [
        {
            title: "Application 다 끝날때까지 아이비에드 할 마음이 100중에 100!",
            student: "김O섭 11학년 | AP Biology, AP Physics",
            review: "먼저 저는 Biology 계열로 대학 진학을 희망하는 학생입니다. 11학년이 되면서 SAT까지 공부 병행하다보니 도저히 혼자서는 안될 것 같아, 온라인 전문과외를 찾고 있었습니다. 그러다 아이비에드를 알게되어 상담 받은 뒤 체험수업을 해보고 괜찮을 것 같아 시작하게 되었어요."
        },
        {
            title: "",
            student: "",
            review: ""
        },
    ],
    classOptions: [
      "아이비에드 Math/CS 과외",
        "아이비에드 Science 과외",
        "아이비에드 Reading / Writing 과외"
    ],
    faqContent: {
        teacher:
            [
                {
                    title: '과외비는 어떤 주기로 정산 받나요?',
                    value: 'No money fo you',
                    category: '선생님',
                },
                {
                    title: '학생이 수업에 들어오질 않았어요.. :(',
                    value: 'Yo still get paid. no worries',
                    category: '선생님',
                },
            ],
        payment:
            [
                {
                    title: '결제 후 바로 수업 진행이 가능한가요?',
                    value: 'No mang, yo gotta wait a year',
                    category: '결제/환불',
                },
            ],
        student:
            [
                {
                    title: '아이패드 안주나요?',
                    value: 'Give us some time',
                    category: '기타',
                },
                {
                    title: '얼마에요?',
                    value: 'Give us some time',
                    category: '기타',
                }
            ],
        etc:
            [
                {
                    title: '매칭 된 선생님과는 어떻게 수업을 진행하나요?',
                    value: 'Hello World',
                    category: '수업 방식',
                },
            ]


    },
    teacherRegister: {
        highestLevelOfEducation: [
            "학부 재학중 (휴학 포함)",
            "학부 졸업",
            "대학원 재학중",
            "석사 졸업",
            "박사 졸업"
        ],
        subjectsOfExpertise:
            ['Computer Science', 'Mathematics', 'English Literature', 'Statistics', 'Writing', 'SAT', 'SAT2'],
        preferredLessonStyles: [
            '문제/과제풀이 위주',
            '개념 정리 위주'
        ],
        preferredLessonMaterials: [
            '학생의 학교 숙제, 교과서 위주',
            '선생님의 추천 및 준비 교재'
        ],
        preferredCoverMaterial: [
            '학교 수업 및 과제 보충',
            '입시 시험 대비'
        ],

    },
    preferredLessonTimeSlots: [
        "00~04시",
        "04~08시",
        "08~12시",
        "12~16시",
        "16~20시",
        "20~24시"
    ],
    preferredLessonTimes: [
        '오전',
        '오후',
        '저녁 이후'
    ],
    preferredLessonDays: [
        '월',
        '화',
        '수',
        '목',
        '금',
        '토',
        '일',
    ],
    demandingHoursPerWeek: [
        "1~2시간",
        "3~4시간",
        "5~8시간",
        "8시간~12시간",
        "최대한 많이",
    ],

    demandingTotalDuration: [
        // '1개월',
        // '2개월',
        '3개월',
        '4개월',
        '5개월',
        '6개월',
        '7개월',
        '8개월',
        '9개월',
        '10개월',
        '11개월',
        '12개월'
    ],
    countryItems: [
        '대한민국',
        '미국',
        '호주',
        '영국',
        '캐나다',
        '뉴질랜드',
        '중국',
        '일본',
        '싱가포르',
        '인도네시아',
        '직접입력',
    ],
    countryCodes: [
        "Australia +61",
        "USA / Canada +1",
        "China +86",
        "Guam +1-671",
        "Hong Kong +852",
        "Singapore +65",
        "South Korea +82",
        "United Kingdom +44",
        "직접 입력"],


    mainSubjects: [
        "Mathematics / Computer Science",
        "Science",
        "Reading / Writing"
    ],
    scienceSubSubjects: [
        // "Elementary school",
        "AP Physics 1",
        "AP Physics 2",
        "AP Physics C Mech",
        "AP Physics C E&M",
        "Physics",
        "AP Chemistry",
        "Chemistry",
        "AP Biology",
        "Biology",
        "AP Environmental Science",
        "AP Psychology",
        "Astronomy",
        "Environmental Science",
        "Science (Middle School)",
    ],
    allOfSubSubjects: [
        "AP Physics 1",
        "AP Physics 2",
        "AP Physics C Mech",
        "AP Physics C E&M",
        "Physics",
        "AP Psychology",
        "AP Chemistry",
        "Chemistry",
        "AP Biology",
        "Biology",
        "AP Environmental Science",
        "Astronomy",
        "Environmental Science",
        "Science (Middle School)",
        "AP CS Principles",
        "AP CS A",
        "CS & Programming",
        "Robotics",
        "Multi-variate Calculus",
        "AP Calc AB",
        "AP Calc BC",
        "Calculus",
        "Pre-calculus",
        "Algebra",
        "Pre-algebra",
        "Geometry",
        "AP Statistics",
        "Statistics",
        "SAT",
        "Math (Middle School)",
        "AP Literature",
        "AP Language",
        "Application Essay",
        "High school",
        "Middle school",
        // "Elementary school",
        "Writing",
        "Speech/Debate",
        "SAT Reading/Writing",
        "SSAT"
    ],
    mathSubSubjects: [
        // "Middle school",
        // // "Elementary school",
        // "Algebra",
        // "Geometry",
        // "Pre-calculus",
        // "Calculus",
        // "AP Calc AB",
        // "AP Calc BC",
        // "Statistics",
        // "AP Statistics",
        // "Multi-variate Calculus",
        // "AP CS Principles",
        // "AP CS A",
        // "SAT"
        "AP CS Principles",
        "AP CS A",
        "CS & Programming",
        "Robotics",
        "Multi-variate Calculus",
        "AP Calc AB",
        "AP Calc BC",
        "Calculus",
        "Pre-calculus",
        "Algebra",
        "Pre-algebra",
        "Geometry",
        "AP Statistics",
        "Statistics",
        "SAT",
        "Math (Middle School)",
        // "Test"
    ],
    englishSubSubjects: [
        "AP Literature",
        "AP Language",
        "Application Essay",
        "High school",
        "Middle school",
        // "Elementary school",
        "Writing",
        "Speech/Debate",
        "SAT Reading/Writing",
        "SSAT"
    ],

    availableDays: [
        "월요일",
        "화요일",
        "수요일",
        "목요일",
        "금요일",
        "토요일",
        "일요일",

    ],
    timeSlots: [
        "오전",
        "오후",
    ],
    financingOptions: [
        '일시불',
        // '1개월',
        '2개월',
        '3개월',
        '4개월',
        '5개월',
        '6개월',
        '7개월',
        '8개월',
        '9개월',
        '10개월',
        '11개월',
        '12개월',
    ],

    gradSchoolType: [
        '석사',
        '박사',
        '로스쿨',
        '의대',
        '치대'
    ],
    possibleHighSchoolTypes: [
        "통학 / Day-school",
        "기숙 / Boarding School",
        "외국인/국제학교",
        "외국어고등학교",
        "과학고등학교",
        "홈스쿨링",
    ],

    desiredMajors: [
        "Natural Science",
        "Engineering",
        "Business/Econ",
        "Arts",
        "Language",
        "Education",
        "기타"
    ],

    americanUniversities: [
        "직접 입력",
        "Amherst College",
        "Barnard College",
        "Bates College",
        "Boston College",
        "Boston University",
        "Bowdoin College",
        "Brandeis University",
        "Brown University",
        "California Institute of Technology",
        "Carleton College",
        "Carleton College",
        "Carnegie Mellon University",
        "Case Western Reserve University",
        "Claremont McKenna College",
        "Colby College",
        "Colgate University",
        "Colorado College",
        "Columbia University",
        "Cornell University",
        "Dartmouth College",
        "Davidson College",
        "Duke University",
        "Emory University",
        "Georgetown University",
        "Georgia Institute of Technology",
        "Grinnell College",
        "Hamilton College",
        "Harvard University",
        "Harvey Mudd College",
        "Haverford College",
        "Johns Hopkins University",
        "Lehigh University",
        "Macalester College",
        "Massachusetts Institute of Technology",
        "Middlebury College",
        "New York University",
        "Northwestern University",
        "Pomona College",
        "Princeton University",
        "Rice University",
        "Smith College",
        "Stanford University",
        "Swarthmore College",
        "Tufts University",
        "Tulane University",
        "University of California Berkeley",
        "University of California Davis",
        "University of California Irvine",
        "University of California Los Angeles",
        "University of California San Diego",
        "University of California Santa Barbara",
        "University of Chicago",
        "University of Florida",
        "University of Georgia",
        "University of Illinois Urbana Champaign",
        "University of Michigan Ann Arbor",
        "University of North Carolina Chapel Hill",
        "University of Notre Dame",
        "University of Pennsylvania",
        "University of Richmond",
        "University of Rochester",
        "University of Southern California",
        "University of Texas at Austin",
        "University of Virginia",
        "University of Wisconsin Madison",
        "Vanderbilt University",
        "Vassar College",
        "Wake Forest University",
        "Washington and Lee University",
        "Washington University in St. Louis",
        "Wellesley College",
        "Wesleyan University",
        "William & Mary",
        "Williams College",
        "Yale University",
    ],
    urls: {
        staging: "https://still-beyond-52824.herokuapp.com/api/",
        production: "https://backend-ie.herokuapp.com/api/",
        local: "http://localhost:8080/api/",
    },
    classStatus: [
        "FINISHED",
        // "TO_BE_PAID",
        // "PAID",
    ],
    bankList: ["기업은행",
        "국민은행",
        "우리은행",
        "신한은행",
        "하나은행",
        "농협은행",
        "지역농축협",
        "SC은행",
        "한국씨티은행",
        "우체국",
        "경남은행",
        "광주은행",
        "대구은행",
        "도이치",
        "부산은행",
        "산림조합",
        "산업은행",
        "저축은행",
        "새마을금고",
        "수협",
        "신협",
        "전북은행",
        "제주은행",
        "카카오뱅크",
        "케이뱅크",
        "토스뱅크",
        "BOA",
        "HSBC",
        "JP모간",
        "중국공상은행",
        "비앤피파리바은행",
        "중국건설은행",
        "중국은행서울지점",],

    securitiesList: ["교보증권",
        "대신증권",
        "미래에셋증권",
        "DB금융투자",
        "유안타증권",
        "메리증권",
        "부국증권",
        "삼성증권",
        "솔로몬투자증권",
        "신영증권",
        "신한금융투자",
        "NH투자증권",
        "유진투자증권",
        "이베스트증권",
        "키움증권",
        "하나금융투자",
        "하이투자",
        "한국투자",
        "한화증권",
        "KB증권",
        "현대차증권",
        "케이프투자증권",
        "SK증권",
        "카카오페이증권",
        "BNK투자증권",
        "IBK투자증권",
        "다올투자증권",
        "토스증권",
        "한국포스증권",],

    accountType: [
        "은행",
        "증권사"
    ],
    financingBenefits: [
        {
            "bank": "하나(외환)",
            "benefit": "2 ~ 8개월 무이자"
        },
        {
            "bank": "비씨",
            "benefit": "2 ~ 7개월 무이자"
        },
        {
            "bank": "현대",
            "benefit": "2 ~ 7개월 무이자"
        },
        {
            "bank": "신한",
            "benefit": "2 ~ 7개월 무이자"
        },
        {
            "bank": "KB국민",
            "benefit": "2 ~ 7개월 무이자"
        },
        {
            "bank": "삼성",
            "benefit": "2 ~ 6개월 무이자"
        },
        {
            "bank": "NH농협",
            "benefit": "2 ~ 8개월 무이자"
        },
        {
            "bank": "롯데",
            "benefit": "2 ~ 4개월 무이자"
        },
        {
            "bank": "우리",
            "benefit": "2 ~ 7개월 무이자"
        },
        {
            "bank": "전북",
            "benefit": "2 ~ 5개월 무이자"
        }
    ],

    bootCampPrograms: [
        // "AP Calculus AB 부트캠프 - Ashely 선생님",
        // "AP Calculus AB 부트캠프 - Henry 선생님",
        // "AP Chemistry 부트캠프 - Mr. Shin 선생님",
        "AP CSP & Python 부트캠프",
        "AP CSA & Java 부트캠프",
        "Academic Writing for beginners",
        "Academic Writing I",
        "Academic Writing II",
        "AP Chemistry - 개념완성반",
        "AP Chemistry - 문제풀이반",
        "AP Biology - 개념완성반",
        "AP Biology - 문제풀이반",
        "AP Physics C 개념마스터",
        "AP Physics 1, 실전대비 문제풀이반",
    ],

    newGradesList: ["Grade 5 (Y6)",
        "Grade 6 (Y7)",
        "Grade 7 (Y8)",
        "Grade 8 (Y9)",
        "Grade 9 (Y10)",
        "Grade 10 (Y11)",
        "Grade 11 (Y12)",
        "Grade 12 (Y13)",],
    channelList: [
        "구글 광고",
        "네이버 광고",
        "네이버 검색",
        "카카오/다음 검색",
        "구글 검색",
        "블로그",
        "네이버 카페",
        "인스타그램",
        "당근마켓",
    ],
    testBootcamp3: {
        firstSection: {
            title:"프론트엔드 개발자 부트캠프",
        }
    }

};

export default info;
