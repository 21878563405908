<template>
    <v-container>

        <v-card
                class="mx-auto  pa-5 primary--text"
                max-width="750"
                outlined
        >
            <span :class="{
            'text-h7': $vuetify.breakpoint.mdAndDown,
            'text-h5': $vuetify.breakpoint.lgAndUp
            }">
                <strong>비밀번호 찾기</strong>
            </span>
        </v-card>
        <!--        <v-divider></v-divider>-->
        <div>
            <!--            <StudentInfo v-if="studentContent.studentRegisterStatus != 'NOT_STARTED'"></StudentInfo>-->
            <v-card
                    class="mx-auto mt-5 primary--text"
                    max-width="750"
                    outlined
            >
                <v-card-subtitle> 가입하셨던 이메일을 입력 하시면, 해당 이메일로 임시 비밀번호를 보내드립니다.</v-card-subtitle>
                <!--        {{!enrollmentData}}-->
                <v-form class="ml-5 mr-5 mt-5 mb-5"
                        ref="form"
                        v-model="valid"
                >
                                    <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        Email (아이디)
                                    </span>
                    <v-row>
                        <v-col cols="12" class="pa-5">
                            <v-text-field
                                    :messages="['임시 비밀번호를 받으신 후 바로 비밀번호를 변경 해주세요.']"
                                    prepend-icon="mdi-email-outline"
                                    v-model="email"
                                    :rules="[rules.required, rules.email]"
                            >
                                Default Slot
                            </v-text-field>
                        </v-col>
                                <v-dialog
                                        v-model="dialog"
                                        width="500"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                :disabled="!valid"
                                                v-bind="attrs"
                                                v-on="on"
                                                rounded
                                                outlined
                                                color="primary"
                                                class="text-h9 mt-5 ml-14 mb-15  pa-5"
                                        >
                                            비밀번호 찾기
                                        </v-btn>

                                    </template>

                                    <v-card>
                                        <v-card-title class="text-h7 font-weight-bold primary white--text">
                                            임시 비밀번호 생성
                                        </v-card-title>

                                        <v-card-text class="pa-3 black--text">
                                            <strong>{{email}}</strong> 로 임시 비밀번호를 보내드립니다.
                                            <br/>
                                            <br/>
                                            임시 비밀번호를 받으신 후 바로 비밀번호를 변경 해주세요.

                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    color="primary"
                                                    text
                                                    large
                                                    @click="recoverPassword(email)"
                                            >
                                                임시 비밀번호 생성
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                    </v-row>
                </v-form>
            </v-card>
        </div>
    </v-container>
</template>

<script>
    import AuthService from '../../services/auth.service'

    export default {
        name: "PasswordRecovery",
        data() {

            return {
                email: "",
                userContent: "",
                valid: false,
                rules: {
                    required: (value) => !!value || '필수 입력 값입니다.',
                    email: (value) => {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Not a valid Email';
                    },
                },
            }
        },
        methods: {
            recoverPassword(email) {
                const requestBody = {
                    email: email
                };
                AuthService.resetPassword(requestBody).then(
                    (response) => {
                        this.userContent = response.data;
                        this.$router.push("/login")
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )


            },

        }
    }
</script>

<style scoped>

</style>
