<template>
<v-container v-if="currentUser.roles[0] == 'ROLE_ADMIN'">
    <v-card
            class="mx-auto mt-5 ml-5 mb-5 pl-5 pt-5 pb-5 pr-5 primary--text"
            max-width="750"
            outlined
    >
        <v-card-subtitle>
            <h1>ADMIN</h1>
        </v-card-subtitle>
        <v-container
                fluid
                class="pa-0"
        >
            <v-row align="center">
                <v-col
                        cols="12"
                        sm="6"
                >
                    <div class="text-center">
                        <div class="my-2">
                            <v-btn
                                    color="primary"
                                    dark
                                    x-large
                                    :to="'/admin/matching/initiate'"
                            >
                                선생님 / 학생 목록 보
                            </v-btn>
                            <v-divider class="my-5"></v-divider>
                            <v-btn
                                    color="primary"
                                    dark
                                    x-large
                                    :to="'/admin/lessons/scheduled'"
                            >
                                선생님 수업별 수업방 링크 추가
                            </v-btn>
<!--                            <v-divider class="my-5"></v-divider>-->
<!--                            <v-btn-->
<!--                                    color="primary"-->
<!--                                    dark-->
<!--                                    x-large-->
<!--                                    :to="'/admin/balance'"-->
<!--                            >-->
<!--                                정산-->
<!--                            </v-btn>-->
                            <v-divider class="my-5"></v-divider>
                            <v-btn
                                    color="primary"
                                    dark
                                    x-large
                                    :to="'/admin/renew/student'"
                            >
                                학생 잔여 크레딧 보기
                            </v-btn>
                            <v-divider class="my-5"></v-divider>
                            <v-btn
                                    color="primary"
                                    dark
                                    x-large
                                    :to="'/admin/create/trialclass'"
                            >
                                체험수업 생성
                            </v-btn>
                        </div>
<!--                        <div class="my-2">-->
<!--                            <v-btn-->
<!--                                    color="error"-->
<!--                                    dark-->
<!--                                    x-large-->
<!--                            >-->
<!--                                Large Button-->
<!--                            </v-btn>-->
<!--                        </div>-->
<!--                        <div class="my-2">-->
<!--                            <v-btn-->
<!--                                    x-large-->
<!--                                    color="success"-->
<!--                                    dark-->
<!--                            >-->
<!--                                Extra large Button-->
<!--                            </v-btn>-->
<!--                        </div>-->
                    </div>
                </v-col>
                <v-col
                        cols="12"
                        sm="6"
                >
                </v-col>
            </v-row>
        </v-container>
    </v-card>

</v-container>
</template>

<script>
    export default {
        name: "Admin",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
    }
</script>

<style scoped>

</style>
