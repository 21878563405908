<template>
    <v-container>
        <!--        <div v-for="item in this.userTeachers" :key="item.id">-->
        <!--&lt;!&ndash;            {{// item}}&ndash;&gt;-->

        <!--            <hr/>-->
        <!--        </div>-->
        <v-card width="100%">
            <!--            {{userTeachers}}-->
            <v-card-title class="text-h6">
                선생별 과외 리스트
            </v-card-title>
            <v-expansion-panels>
            <v-expansion-panel fluid v-for="item in userTeachers" :key="item.id">
                <v-expansion-panel-header>
                    <div>
                <v-card-subtitle class="text-strong text-h6 font-weight-bold">
                    Teacher: {{item.username}}
                </v-card-subtitle>
                        <span class="font-weight-bold">학생들</span>
                    <div v-for="eachLesson in item.teacher.lessons" :key="eachLesson.id">
                        <span>
                            {{eachLesson.studentEmail}} <br/>
                        </span>
                    </div>
                    </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
<!--                    {{item}}-->
                <!--                {{item.teacher.lessons}}-->
                <div v-for="eachLesson in item.teacher.lessons" :key="eachLesson.id">

                    <v-card color="#B181" class="pa-2" style="font-size: 20px">
                        <strong>Lesson ID:</strong> {{eachLesson.id}} <br/>
                        <strong>수업일지</strong> <br/>
                        {{eachLesson.reportLink}} <br/>

                        <strong>학생 :</strong> {{eachLesson.studentName}}, {{eachLesson.studentEmail}}<br/>
                        <strong>잔여 수강권 횟수:</strong> {{eachLesson.numberOfRemainingClassPoints}}
                        <div style=""><strong>lesson status:</strong><div v-if="eachLesson.status == 'CANCELLED'" style="color: red"> {{eachLesson.status}}</div>
                            <div v-if="eachLesson.status != 'CANCELLED'" style=""> {{eachLesson.status}}</div>

                        </div>
                        <div class="py-10">

                            <v-row justify="center">
                                <v-dialog max-width="600px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                color="primary"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                        >
                                            Class 생성하기
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <span class="">{{eachLesson.studentName}} 과외</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-toolbar-title class="black--text font-weight-bold mb-3">수업 일시 입력
                                                    (classTimeAt)
                                                </v-toolbar-title>
                                                <v-row class="">
                                                    <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="6"
                                                    >
                                                        <v-text-field
                                                                maxlength="10"
                                                                label="수업 날짜"
                                                                required
                                                                counter="10"
                                                                placeholder="20220524"
                                                                v-model="classTimeAt.date"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <!--                                                <v-col-->
                                                    <!--                                                        cols="12"-->
                                                    <!--                                                        sm="6"-->
                                                    <!--                                                        md="4"-->
                                                    <!--                                                >-->
                                                    <!--                                                    <v-text-field-->
                                                    <!--                                                            label="Legal middle name"-->
                                                    <!--                                                            hint="example of helper text only on focus"-->
                                                    <!--                                                    ></v-text-field>-->
                                                    <!--                                                </v-col>-->
                                                    <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="6"
                                                    >
                                                        <v-text-field
                                                                label="수업 시간"
                                                                placeholder="12:00"
                                                                v-model="classTimeAt.time"
                                                                required
                                                                counter="7"
                                                                maxlength="7"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <!--                                            {{new Date(classTimeAt.date)}}-->
                                                {{classAt}}
                                                <!--                                            {{this.convertTo24Hour(this.classTimeAt.time)}}-->
                                                <!--                                            // + "" +classTimeAt.time)}}-->
                                                <v-toolbar-title class="black--text font-weight-bold mb-3">총 수업 시간 및 상태
                                                </v-toolbar-title>
                                                <v-row>
                                                    <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="6"
                                                    >
                                                        <v-text-field
                                                                label="총 수업 시간 (HR)"
                                                                v-model="classCreateRequestBody.numberOfHours"
                                                                required
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                            cols="12"
                                                            sm="6"
                                                            md="6"
                                                    >
                                                        <v-select
                                                                :items="classStatus"
                                                                v-model="classCreateRequestBody.status"
                                                                label="수업 상태">

                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-toolbar-title class="black--text font-weight-bold mb-3">확인 날짜
                                                    (verifiedAt)
                                                </v-toolbar-title>
                                                <v-row class="text-h6 ma-3">
                                                    {{new Date(Date.now()).toLocaleDateString()}} {{ new
                                                    Date(Date.now()).toLocaleTimeString()}}
                                                </v-row>
                                                <v-row class="text-h6 ma-3">
                                                    {{currentTime}}
                                                </v-row>
                                                <br/>
                                                <br/>

                                                <div class="black--text">
                                                    <v-row>
                                                        {{item.username}} 선생님에게 지급해야할 금액 &nbsp;<strong>
                                                        {{item.teacher.hourlyWage *
                                                        classCreateRequestBody.numberOfHours}}원</strong>
                                                    </v-row>
                                                    <v-row>
                                                        {{eachLesson.studentName}} 학생에게 차감 될 수강권 갯수 &nbsp;<strong>
                                                        {{classCreateRequestBody.numberOfHours}}개</strong>
                                                    </v-row>
                                                    <v-row>
                                                        잔여 수강권 갯수&nbsp;
                                                        <strong>
                                                            {{eachLesson.numberOfRemainingClassPoints}}개</strong>
                                                    </v-row>
                                                </div>
                                                <br/>
                                                <br/>
                                                <!--                                            {{classCreateRequestBody}}-->
                                                {{item.teacher.id}}
                                                <v-row>
                                                    <strong>상태 설명</strong>
                                                    FINISHED - 수업 일지와 cross check 후 수업일자와 총 시간을 입력할때 사용하는 상태값
                                                </v-row>
                                            </v-container>
                                            <!--                                        <small>*indicates required field</small>-->
                                        </v-card-text>
<!--                                        {{// possibleToPay(eachLesson.numberOfRemainingClassPoints)}}}-->
                                        <div v-if="eachLesson.numberOfRemainingClassPoints- classCreateRequestBody.numberOfHours < 0">
                                            <div style="font-size: 40px; color: red; font-weight: bold">학생 수강권이 부족합니다!!</div>
                                        </div>
                                        <div v-if="eachLesson.numberOfRemainingClassPoints- classCreateRequestBody.numberOfHours >= 0">
                                            <div style="font-size: 15px; color: blue; font-weight: bold">생성 가능합니다.</div>
                                        </div>
                                        <v-card-actions>

                                            <v-spacer></v-spacer>

                                            <!--                                        <v-btn-->
                                            <!--                                                color="blue darken-1"-->
                                            <!--                                                text-->
                                            <!--                                                @click="dialog = false"-->
                                            <!--                                        >-->
                                            <!--                                            Close-->
                                            <!--                                        </v-btn>-->
                                            <!--                                        {{eachLesson.reportLink}}-->
                                            <v-btn
                                                    v-if="eachLesson.numberOfRemainingClassPoints- classCreateRequestBody.numberOfHours >= 0"
                                                    color="blue darken-1"
                                                    text
                                                    @click="createClass(classCreateRequestBody, eachLesson.id, item.teacher.hourlyWage, eachLesson.studentEmail, item.teacher.id, eachLesson.reportLink, classReport, '', 3, '')"
                                            >
                                                생성하기
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-row>
                        </div>
                        <v-card style="font-size: 16px !important;" flat outlined class="my-3" v-for="eachClass in eachLesson.classes" :key="eachClass.id">
                            <v-card-title v-if="eachClass.status == 'FINISHED'" class="blue--text">
                                정산 전
                            </v-card-title>
                            <v-card-title v-if="eachClass.status == 'PAID'" class="red--text">
                                정산 완료
                            </v-card-title>
                            <v-card-title>
                                class ID: {{eachClass.id}}
                            </v-card-title>
                            <div class="ma-2">
                                classTimeAt: {{eachClass.classTimeAt}} <br/>
                                verifiedAt: {{eachClass.verifiedAt}}<br/>
                                paidAt: {{eachClass.paidAt}}<br/>
                                status: {{eachClass.status}}<br/>
                                teacherHourlyWage: {{eachClass.teacherHourlyWage}}<br/>
                                numberOfHours: {{eachClass.numberOfHours}}<br/>
                                totalAmountToBePaid: {{eachClass.totalAmountToBePaid}}<br/>
                            </div>
                            <div class="pa-5" v-if="eachClass.status != 'PAID'">
                                <v-btn @click="markAsPaid(eachClass.id)" large color="secondary">
                                    정산 완료 (FINISHED -> PAID)
                                </v-btn>
                            </div>
                        </v-card>

                    </v-card>
                    <hr/>
                    <br/>
                    <br/>
                    <br/>
                    <!--                    {{eachLesson.classes}}-->
                </div>
                <!--                <v-data-table-->
                <!--                        :headers="lessonHeaders"-->
                <!--                        :items="item.teacher.lessons"-->
                <!--                        class="elevation-1"-->
                <!--                >-->

                <!--                </v-data-table>-->
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </v-container>
</template>

<script>
    import UserService from '../../services/user.service'
    import LessonService from '../../services/lesson.service'
    import ClassService from '../../services/class.service'
    import EmailService from '../../services/email.service'
    import info from "../../../info";

    export default {
        name: "AdminBalance",
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
            currentTime() {
                let currentDate = new Date(Date.now());
                const offset = currentDate.getTimezoneOffset()
                currentDate = new Date(currentDate.getTime() - (offset * 60 * 1000))
                let dateList = currentDate.toISOString().replace('Z', '').split('T');
                currentDate = dateList[0] + " " + dateList[1]
                return currentDate;
            },

            convertedTime() {
                return this.convertTo24Hour(this.classTimeAt.time)
            },
            possibleToPay(remainingPoints){
                return this.checkClassCreationValidity(remainingPoints)
            },

            classAt() {

                // if (this.classTimeAt.time.length > 0) {
                //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                //     this.classTimeAt.time = ;
                // }

                let tempTime = this.classTimeAt.date + " " + this.convertTo24Hour(this.classTimeAt.time.toLowerCase()) + ":00.000"
                return tempTime;
            }

        },
        methods: {
            checkClassCreationValidity(remainingPoints) {
                console.log("remainingPoints")
                console.log(remainingPoints)
                console.log(this.classCreateRequestBody.numberOfHours)
                if (remainingPoints - this.classCreateRequestBody.numberOfHours >= 0) {

                    this.classValid = true;
                    return true;
                } else {
                    return false;
                }
            },
            convertTo24Hour(time) {
                var hours = parseInt(time.substr(0, 2));
                if (time.indexOf('am') != -1 && hours == 12) {
                    time = time.replace('12', '0');
                }
                if (time.indexOf('pm') != -1 && hours < 12) {
                    time = time.replace(hours, (hours + 12));
                }
                return time.replace(/(am|pm)/, '');
            },
            sendReportUpdateEmail(requestBody) {
                EmailService.sendReportUpdateEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                        // because we send email to teacher after student, refresh after sending email to Teacher
                        if (requestBody.teacherId) {
                            this.$router.go(0)
                        }
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            sendNotificationEmailByUserId(requestBody, userId) {
                EmailService.sendEmailNotificationByUserId(requestBody, userId).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },



            createClass(requestBody, lessonId, hourlyWage, studentEmail, teacherId,reportLink, classReport, classEndedAt, homework, lessonScore, timezone) {
                // this.classCreateRequestBody.
                requestBody.classTimeAt = this.classAt;
                requestBody.verifiedAt = this.currentTime;
                requestBody.lessonId = lessonId + "";
                requestBody.classEndedAt = this.currentTime;
                    // 선생에게 지급해야할 액수 저장.
                requestBody.teacherHourlyWage = hourlyWage;
                requestBody.totalAmountToBePaid = requestBody.numberOfHours * hourlyWage;
                requestBody.classReport = classReport;
                requestBody.homework = homework;
                requestBody.lessonScore = 2 + "";
                requestBody.timezone = timezone;

                ClassService.createClassForConfirmation(requestBody).then(
                    (response) => {
                        console.log(response.data)
                        console.log(teacherId)
                        // to student
                        this.sendReportUpdateEmail({
                            "studentEmail": studentEmail,
                            "classTimeAt": requestBody.classTimeAt.substring(0, requestBody.classTimeAt.length - 7),
                            "reportLink": reportLink,
                            "emailBody": "STUDENT_REPORT_NOTIFICATION",

                        });
                        // to teacher
                        this.sendReportUpdateEmail({
                           "teacherId": teacherId + "",
                            "classTimeAt": requestBody.classTimeAt.substring(0, requestBody.classTimeAt.length - 7),
                            "reportLink": reportLink,
                            "emailBody": "TEACHER_REPORT_NOTIFICATION"
                        });
                        // this.sendNotificationEmail({
                        //     "email": studentEmail,
                        // });
                        //
                        // // to teacher
                        // this.sendNotificationEmailByUserId({
                        //     "emailBody": "TEACHER_REPORT_NOTIFICATION",
                        // }, teacherId);
                    },
                    (error) => {
                        this.classContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    },
                )
            },

            markAsPaid(classId) {
                ClassService.markAsPaid(classId).then(
                    (response) => {
                        console.log(response.data)
                        this.$router.go(0)
                    },
                    (error) => {
                        this.classContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    },
                )
            },
        },
        mounted() {

            UserService.getAllUserTeachers().then(
                (response) => {
                    this.userTeachers = response.data;


                },
                (error) => {
                    this.userTeachers =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

            LessonService.getAllLessons().then(
                (response) => {
                    this.allLessons = response.data;
                    return response.data;
                },
                (error) => {
                    this.lessonResponseBody =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        data: () => ({
            classValid: false,
            lessonHeaders: [
                {text: 'lesson_id', value: 'id', align: 'start',},
                {text: 'lesson_status', value: 'status'},
                {text: 'student_id', value: 'sid'},
                {text: 'pageCallLink', value: 'pageCallLink',},
                {text: 'teacherMatchingStatus', value: 'teacherMatchingStatus'},
                {text: 'studentMatchingStatus', value: 'studentMatchingStatus'},
                {text: 'schedule', value: 'schedule',},
                {text: 'message', value: 'message',},
            ],
            classReport : "",
            rules: {
                required: (value) => !!value || '필수 입력 값입니다.',
            },
            cancelValidation: false,
            classContent: [],
            userTeachers: [],
            allLessons: [],
            studentContent: [],
            studentsInLessons: [],
            test: [],
            users: [],
            allUserInfo: {},
            updateResponseBody: '',
            whiteboardRequestBody: {
                pageCallLink: "",
            },
            feedbackReportRequestBody: {
                reportLink: "",
            },

            cancelLessonRequestBody: {
                adminRemarks: "",
            },
            dialog: false,
            classStatus: info.classStatus,
            dateObject: {
                year: "",
                month: "",
                day: "",
                hour: "",
                minute: "",
            },
            classCreateRequestBody: {
                lessonId: "",
                classTimeAt: "",
                verifiedAt: "",
                paidAt: "",
                status: "",
                pageCallLink: "",
                reportLink: "",
                teacherHourlyWage: "",
                numberOfHours: "",
                totalAmountToBePaid: "",
                adminRemarks: "",
                classReport: "",
            },
            verifiedAt: {
                date: "",
                time: ""
            },
            classTimeAt: {
                date: "",
                time: ""
            },
            paidAt: {
                date: "",
                time: ""
            },
        }),
    }
</script>

<style scoped>

</style>
