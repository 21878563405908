<template>

    <div class="pa-5">
        <div v-show="!loading">
            <v-form>
                <v-card flat>
                    <div style="">
                        <v-toolbar
                                flat
                                dark
                                color="transparent"
                        >
                        </v-toolbar>
                        <div class="pl-6 pt-4">
                            <div class=" font-weight-bold" style="font-size: 22px">
                                수업 일지 작성
                            </div>
                            <div style="font-size: 15px">
                                오늘 진행한 수업에 대해 선생님의 의견을 작성하여 주세요.
                                <div class="mr-10">
                                    수업일지를 통해 학생이 선생님의 귀중한 피드백을 전달 받고 있어요.
                                </div>
                                <div class="py-1"></div>
                                <div class="mr-10">
                                    또 한 모든 정산은 수업일지를 기반으로 진행 되오니, 미작성시 정산이 원활하게 이루지지 않을 수 있습니다.
                                </div>
                            </div>
                            <v-divider class="my-4"></v-divider>
                            <div class="py-5"></div>
                            <div>
                                <div style="color: #757575; font-size: 15px"
                                     class="font-weight-bold">
                                    학생 선택
                                </div>
                                <div class="px-2 mr-4">
                                    <v-row align="center">
                                        <v-col cols="">
                                            <v-select
                                                    :rules="required"
                                                    v-model="selectedStudent"
                                                    :items="formattedStudentItems"
                                                    item-text="fullName"
                                                    item-value="student_id"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <div class="py-4"></div>
                            <div>
                                <div style="color: #757575; font-size: 15px"
                                     class="font-weight-bold">
                                    수업일시
                                </div>
                                <div class="px-2 mr-4">
                                    <v-row align="center">
                                        <v-col cols="4">
                                            <v-select
                                                    v-model="year"
                                                    item-title="state"
                                                    :items="years"
                                                    item-value="abbr"
                                                    label="Year"
                                                    :rules="required"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select
                                                    v-model="month"
                                                    item-title="state"
                                                    :items="months"
                                                    item-value="abbr"
                                                    label="Month"
                                                    :rules="required"

                                            ></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select
                                                    v-model="date"
                                                    item-title="state"
                                                    :items="days"
                                                    item-value="abbr"
                                                    label="Date"
                                                    :rules="required"

                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <div class="py-4"></div>
                            <div>
                                <div style="color: #757575; font-size: 15px"
                                     class="font-weight-bold">
                                    수업시간
                                </div>
                                <div class="px-2 mr-4">
                                    <v-row>
                                        <v-col cols="4">
                                            <v-select
                                                    v-model="timezone"
                                                    :items="timezones"
                                                    label="Timezone"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <div style="white-space: nowrap">
                                        <!--                                                {{tempTime}}-->

                                        <div class="font-weight-bold" style="font-size: 13px">
                                            <v-row>
                                                <v-col cols="6">
                                                    <div>
                                                        Started at
                                                    </div>
                                                </v-col>
                                                <v-col cols="6">
                                                    <div>
                                                        Ended at
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-row align="center">

                                            <v-col cols="3">
                                                <v-select
                                                        :rules="timeRules"
                                                        :maxlength="2"
                                                        :items="hours"
                                                        v-model="startHour"
                                                        placeholder="13"
                                                ></v-select>
                                            </v-col>
                                            :
                                            <v-col cols="2">
                                                <v-select
                                                        :items="['00', '30']"
                                                        v-model="startMinute"
                                                        :rules="required"
                                                        placeholder="00"
                                                ></v-select>
                                            </v-col>

                                            <v-col cols="1"></v-col>
                                            <v-col cols="3">
                                                <v-select
                                                        :rules="timeRules"
                                                        :maxlength="2"
                                                        :items="hours"
                                                        v-model="endHour"
                                                        placeholder="14"
                                                ></v-select>
                                            </v-col>
                                            :
                                            <v-col cols="2">
                                                <v-select
                                                        :items="['00', '30']"
                                                        :rules="required"
                                                        v-model="endMinute"
                                                        placeholder="00"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4" class="font-weight-bold">한국시간 기준</v-col>
                                            <!--                                                    <v-col cols="8">{{// this.convertToKST(this.year+"-"+month+"-"+date+" " +startHour+":"+startMinute, timezone)}}</v-col>-->
                                            <v-col cols="8"
                                                   v-if="timezone && startHour && endHour && startMinute && endMinute">
                                                {{timeToShowInReportEmail(convertToKST(year+"-"+month+"-"+date+
                                                " " +startHour+":"+startMinute, timezone))}}
                                            </v-col>
                                        </v-row>
                                    </div>

                                </div>

                            </div>
                            <div class="py-4"></div>
                            <div>
                                <v-row align="center">
                                    <v-col>
                                        <!-- 여기서 학생의 수강권 갯수를 비교하여 남은 수강권 이상으로 수업일지를 작성하지 못하게 하여야함.  -->
                                        <div style="color: #757575; font-size: 15px"
                                             class="font-weight-bold">
                                            총 수업시간 (시간)
                                        </div>
                                    </v-col>
                                    <!--                                    {{getStudentById(selectedStudent).sum_of_remaining_classes}}-->
                                    <v-col cols class="2" style="margin-right: 100px">
                                        <v-select
                                                :rules="required"
                                                v-model="classCreateRequestBody.numberOfHours"
                                                :items="classHours"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="8"></v-col>
                                </v-row>
                                <div style="border: 1px solid grey; border-radius: 8px" class="pa-2"
                                     v-if="selectedStudent && (getStudentById(selectedStudent).sum_of_remaining_classes < classCreateRequestBody.numberOfHours)">
                                    <div style="color: red;" class="font-italic font-weight-bold">* 학생의 수강권이 부족합니다.
                                    </div>
                                    <div><span class="font-weight-bold">현재 잔여 수강권:  </span>
                                        {{getStudentById(selectedStudent).sum_of_remaining_classes}} 개
                                    </div>
                                    <div style="font-size: 14px">학생의 수강권이 부족함으로 잔여 수강권만큼만 일지를 작성하신 후 해당사항을 운영팀에게 전달 부탁
                                        드립니다.
                                    </div>
                                </div>
                            </div>
                            <div class="py-4"></div>
                            <div>
                                <v-row align="center">
                                    <v-col>
                                        <div style="color: #757575; font-size: 15px"
                                             class="font-weight-bold">
                                            Lesson Score
                                        </div>
                                    </v-col>
                                    <v-col cols class="2" style="margin-right: 100px">
                                        <v-select
                                                :rules="required"

                                                v-model="lessonScore"
                                                :items="lessonScores"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="8"></v-col>
                                </v-row>
                            </div>

                            <div class="py-4"></div>
                            <div class="mr-10">
                                <div style="color: #757575; font-size: 15px"
                                     class="font-weight-bold">
                                    수업 진행 내용 & 이해도
                                </div>
                                <v-textarea
                                        :rules="required"

                                        v-model="classReport">

                                </v-textarea>
                            </div>
                            <div class="py-4"></div>
                            <div class="mr-10">
                                <div style="color: #757575; font-size: 15px"
                                     :rules="required"

                                     class="font-weight-bold">
                                    숙제
                                </div>
                                <v-textarea v-model="homework">
                                </v-textarea>
                            </div>

                            <div class="py-4"></div>
                            <div>
                                <div style="color: #757575; font-size: 15px"
                                     class="font-weight-bold">
                                    다음 수업일시
                                </div>
                                <div class="px-2 mr-4">
                                    <v-row align="center">
                                        <v-col cols="4">
                                            <v-select
                                                    v-model="nextClassYear"
                                                    item-title="state"
                                                    :items="years"
                                                    item-value="abbr"
                                                    label="Year"
                                                    :rules="required"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select
                                                    v-model="nextClassMonth"
                                                    item-title="state"
                                                    :items="months"
                                                    item-value="abbr"
                                                    label="Month"
                                                    :rules="required"

                                            ></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select
                                                    v-model="nextClassDate"
                                                    item-title="state"
                                                    :items="days"
                                                    item-value="abbr"
                                                    label="Date"
                                                    :rules="required"

                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                            <div class="py-4"></div>
                            <div>
                                <div style="color: #757575; font-size: 15px"
                                     class="font-weight-bold">
                                    다음 수업시간
                                </div>
                                <div class="px-2 mr-4">
                                    <v-row>
                                        <v-col cols="4">
                                            <v-select
                                                    v-model="nextClassTimezone"
                                                    :items="timezones"
                                                    label="Timezone"
                                                    :rules="required"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <div style="white-space: nowrap">
                                        <!--                                                {{tempTime}}-->

                                        <div class="font-weight-bold" style="font-size: 13px">
                                            <v-row>
                                                <v-col cols="6">
                                                    <div>
                                                        Starts at
                                                    </div>
                                                </v-col>

                                            </v-row>
                                        </div>
                                        <v-row align="center">
                                            <v-col cols="3">
                                                <v-select
                                                        :rules="timeRules"
                                                        :maxlength="2"
                                                        :items="hours"
                                                        v-model="nextClassStartHour"
                                                        placeholder="13"
                                                ></v-select>
                                            </v-col>
                                            :
                                            <v-col cols="2">
                                                <v-select
                                                        :items="['00', '30']"
                                                        :rules="required"
                                                        v-model="nextClassStartMinute"
                                                        placeholder="00"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="4" class="font-weight-bold">한국시간 기준</v-col>
                                            <!--                                                    <v-col cols="8">{{// this.convertToKST(this.year+"-"+month+"-"+date+" " +startHour+":"+startMinute, timezone)}}</v-col>-->
                                            <v-col cols="8"
                                                   v-if="nextClassTimezone && nextClassStartHour && nextClassStartMinute">
                                                {{timeToShowInReportEmail(convertToKST(nextClassYear+"-"+nextClassMonth+"-"+nextClassDate+
                                                " " +nextClassStartHour+":"+nextClassStartMinute, nextClassTimezone))}}
                                            </v-col>
                                        </v-row>
                                    </div>

                                </div>

                            </div>

                            <div class="text-center">
                                <v-btn elevation="0"
                                       :disabled="isDisabled || getStudentById(selectedStudent).sum_of_remaining_classes < classCreateRequestBody.numberOfHours"
                                       @click="createClass(classCreateRequestBody, getStudentById(selectedStudent).lesson_id, '40000', getStudentById(selectedStudent).student_email, getStudentById(selectedStudent).teacher_id, getStudentById(selectedStudent).report_link, classReport, classEndedAt, homework, lessonScore, timezone)"
                                       color="primary" width="80%">제출하기
                                </v-btn>

                            </div>
                            <div class="py-7"></div>
                        </div>
                    </div>
                </v-card>
            </v-form>
        </div>
        <div v-show="loading" class="center">
            <LoadingAnimation
                    :size="60"
                    :width="7"
                    color="primary"
                    indeterminate
            ></LoadingAnimation>
        </div>
    </div>
</template>

<script>
    import TeacherService from "../../../services/teacher.service"
    import StudentService from "../../../services/student.service"
    import LessonService from "../../../services/lesson.service"
    // import AllLessons from "./Lessons/AllLessons";
    import EmailService from "../../../services/email.service"
    import ClassService from "../../../services/class.service"
    import LoadingAnimation from "../../helpers/LoadingAnimation";

    export default {
        name: "ClassReportForm",
        components: {LoadingAnimation},
        computed: {

            // // sumOfRemainingClasses() {/*
            //     return this.getStudentById(selectedStudent).sum_of_remaining_classes;
            // },//


            tempTime() {
                const inputDate = new Date("2023-03-17T18:00:00.000Z");
                // {{"2023-03-17T18:00:00.000Z".substring(0, 15)}}
// Define the options for formatting
                const options = {
                    timeZone: "Asia/Seoul",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    hour12: false,
                };

// Convert the date to the desired format
                return inputDate.toLocaleString("en-KR", options);

            },
            currentUser() {
                return this.$store.state.auth.user;
            },
            allLessons: function () {
                return this.lessons.filter(function (lesson) {
                    return lesson.lesson_status == 0 || lesson.lesson_status == 1 || lesson.lesson_status == 5 || lesson.lesson_status == 6;
                })
            },

            studentList() {
                return this.allLessons
                    .filter((lesson) => lesson.status !== 'CANCELLED')
                    .map((lesson) => ({
                        student_id: lesson.student_id,
                        student_first_name: lesson.student_first_name,
                        student_last_name: lesson.student_last_name,
                        student_email: lesson.student_email,
                        teacher_id: lesson.teacher_id,
                        lesson_id: lesson.lesson_id,
                        report_link: lesson.report_link,
                        sum_of_remaining_classes: lesson.sum_of_remaining_classes
                    }));
            },
            formattedStudentItems() {
                return this.studentList
                    .filter((item) => item.sum_of_remaining_classes > 0)
                    .map((item) => ({
                        student_id: item.student_id,
                        fullName: `${item.student_last_name} ${item.student_first_name}`,
                    }));
            },
            timeRules() {
                return [
                    (value) => {
                        if (!value) {
                            return 'Please enter a time.';
                        }
                        if (value.length !== 2) {
                            return 'Time must be 2 characters long.';
                        }
                        if (!/^\d+$/.test(value)) {
                            return 'Time must contain only digits.';
                        }
                        const time = Number(value);
                        if (time < 0 || time > 23) {
                            return 'Time must be between 0 and 23.';
                        }
                        return true;
                    }
                ];
            },
            // required: (value) => !!value || '필수 입력',

            required() {
                return (value) => {
                    if (!value) {
                        return "필수 입력";
                    }
                }
            },

            canCreateClass(selectedStudent) {
                return () => {
                    if (this.getStudentById(selectedStudent).sum_of_remaining_classes < this.classCreateRequestBody.numberOfHours) {
                        return "수강권 부족"
                    }
                }
            },

            isDisabled() {
                return !this.classReport || !this.homework || !this.lessonScore || !this.classCreateRequestBody.numberOfHours
                    || !this.timezone || !this.year || !this.month || !this.date
                    || !this.startHour || !this.startMinute || !this.endHour || !this.endMinute || this.disableButton
                    || !this.nextClassDate || !this.nextClassStartHour || !this.nextClassYear || !this.nextClassTimezone || !this.nextClassMonth || !this.nextClassStartMinute
                    ;
                //    !this.numberOfHours || !this.month || !this.date || !this.year || !this.month || !this.date ||
            },
            classAt() {

                // if (this.classTimeAt.time.length > 0) {
                //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                //     this.classTimeAt.time = ;
                // }

                let tempTime = this.classTimeAt.date + " " + this.convertTo24Hour(this.classTimeAt.time.toLowerCase()) + ":00.000"
                return tempTime;
            },
            currentTime() {
                let currentDate = new Date(Date.now());
                const offset = currentDate.getTimezoneOffset()
                currentDate = new Date(currentDate.getTime() - (offset * 60 * 1000))
                let dateList = currentDate.toISOString().replace('Z', '').split('T');
                currentDate = dateList[0] + " " + dateList[1]
                return currentDate;
            },
        },

        watch: {
            // selectedStudent(newStudentId) {
            //     Fetch the student data when selectedStudent changes
            // this.studentData = this.getStudentById(newStudentId);
            // },
        },
        mounted() {

            if (!this.currentUser) {
                this.$router.push('/login');
            }
            TeacherService.getStudentsWhoHaveLessonsWithTeacherId(this.currentUser.id).then(
                (response) => {

                    this.lessons = response.data;
                    // this.teacherContent.detailedLessonTimes = JSON.parse(this.teacherContent.detailedLessonTimes)

                    this.parseLessonTimes()
                    // for (let i = 0; i < this.lessons.length; i++) {
                    //
                    // }
                    // console.log("lessons:")
                    // console.log(this.lessons)
                    // console.log("lessons:")
                    // this.parseLessonTimeJson(this.lessons)


                },
                (error) => {
                    this.lessons =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    // console.log(this.lessons)
                }
            );

            TeacherService.getTeacherInfo(this.currentUser.id).then(
                (response) => {
                    this.teacherContent = response.data;
                    this.setLessonStatuses(this.teacherContent)
                },
                (error) => {
                    this.teacherContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },

        data: () => ({
            selectedStudent: "",
            loading: false,
            isFormValid: false,
            classCreateRequestBody: {
                lessonId: "",
                classTimeAt: "",
                nextClassTimeAt: "",
                verifiedAt: "",
                paidAt: "",
                status: "",
                pageCallLink: "",
                reportLink: "",
                teacherHourlyWage: "",
                numberOfHours: "",
                totalAmountToBePaid: "",
                adminRemarks: "",
                classReport: "",
            },
            verifiedAt: {
                date: "",
                time: ""
            },
            classTimeAt: {
                date: "",
                time: ""
            },
            classEndedAt: {
                date: "",
                time: ""
            },
            paidAt: {
                date: "",
                time: ""
            },
            homework: "",
            classReport: "",
            lessonScore: "",
            lessonScores: [1, 2, 3],
            classHours: [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6],
            timezones: [
                "KST",
                "PST",
                "EST",
                "CET",
                "CEST",
                "SGT",

            ],
            timezone: "",
            startHour: "",
            startMinute: "",
            endHour: "",
            endMinute: "",
            year: "",
            month: "",
            date: "",
            nextClassYear: "",
            nextClassMonth: "",
            nextClassDate: "",
            nextClassTimezone: "",
            nextClassStartHour: "",
            nextClassStartMinute: "",
            years: [
                "2023",
                "2024"
            ],
            months: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
            ],
            days: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ],
            hours: [
                "00",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23"
            ],
            dialog: false,
            matchingOn: false,
            showLesson: false,
            lesson_asked: false,
            testValue: 1,
            lesson_message_sent_by_student: false,
            lesson_no_lessons: false,
            lesson_accepted: false,
            teacherLessons: {
                lesson_asked: [],
                lesson_message_sent_by_student: [],
                lesson_no_lessons: [],
                lesson_accepted: [],
            },
            lessonRequestBody: [],
            lessonResponseBody: [],
            lessonStudents: [],
            lessons: [],
            teacherContent: "",
            studentLessonContent: "",
            teacherLessonContent: "",
            studentContent: "",
            value: '',
            disableButton: false,

        }),

        methods: {
            getStudentById(studentId) {
                return this.studentList.find((student) => student.student_id === studentId);
            },

            timeToShowInReportEmail(dateString) {
                const inputDate = dateString;
                // {{"2023-03-17T18:00:00.000Z".substring(0, 15)}}
// Define the options for formatting
                const options = {
                    timeZone: "Asia/Seoul",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    hour12: false,
                };
                if (dateString == "Invalid Date") {
                    return "날짜 형식이 유효하지 않습니다.";
                } else {
                    return "[KST] " + inputDate.toLocaleString("en-KR", options) + "시";
                }
// Convert the date to the desired format

            },

            convertToKST(dateString, timeZone) {
                const date = new Date(dateString);

                const utcOffset = date.getTimezoneOffset() * 60 * 1000;
                const targetTimeZone = timeZone.toUpperCase();


                const timeZones = {
                    PST: 34,
                    EST: 31,
                    CET: 26,
                    CEST: 25,
                    SGT: 19
                    // Add more time zones and their respective offsets here
                };

                const kstOffset = 9;

                if (timeZone == "KST") {
                    return new Date(date.getTime())
                } else if (Object.prototype.hasOwnProperty.call(timeZones, targetTimeZone)) {
                    const targetOffset = timeZones[targetTimeZone];
                    const convertedTime = new Date(date.getTime() + (targetOffset - kstOffset) * 60 * 60 * 1000 + utcOffset);


                    return convertedTime;
                    // .toLocaleTimeString("en-US",options);
                } else {
                    throw new Error('Invalid time zone specified.');
                }
            },

            sendReportUpdateEmail(requestBody) {
                EmailService.sendReportUpdateEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data);

                        // because we send email to teacher after student, refresh after sending email to Teacher
                        if (requestBody.teacherId) {
                            // this.$router.go(0)
                        }
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            convertTo24Hour(time) {
                var hours = parseInt(time.substr(0, 2));
                if (time.indexOf('am') != -1 && hours == 12) {
                    time = time.replace('12', '0');
                }
                if (time.indexOf('pm') != -1 && hours < 12) {
                    time = time.replace(hours, (hours + 12));
                }
                return time.replace(/(am|pm)/, '');
            },

            createClass(requestBody, lessonId, hourlyWage, studentEmail, teacherId, reportLink, classReport, classEndedAt, homework, lessonScore, timezone) {
                // this.classCreateRequestBody.

                this.disableButton = true;
                this.loading = true;

                requestBody.classTimeAt = this.year + "-" + this.month + "-" + this.date + " "
                    + this.startHour + ":" + this.startMinute + ":" + "00";
                requestBody.classEndedAt = this.year + "-" + this.month + "-" + this.date + " "
                    + this.endHour + ":" + this.endMinute + ":" + "00";
                requestBody.nextClassTimeAt = this.nextClassYear + "-" + this.nextClassMonth + "-" + this.nextClassDate + " "
                    + this.nextClassStartHour + ":" + this.nextClassStartMinute + ":" + "00";


                requestBody.verifiedAt = this.currentTime;
                requestBody.lessonId = lessonId + "";


                requestBody.numberOfHours = requestBody.numberOfHours + "";
                // 선생에게 지급해야할 액수 저장.
                // requestBody.teacherHourlyWage;
                // 2023-05-14 12:00:00.000


                requestBody.totalAmountToBePaid = requestBody.numberOfHours * hourlyWage;
                requestBody.classReport = classReport;

                requestBody.homework = homework;
                requestBody.lessonScore = lessonScore + "";
                requestBody.timezone = timezone;
                requestBody.status = "FINISHED";
                // requestBody.numberOfHours = "2"; // temp
                // this.dialog = false;

                ClassService.createClassForConfirmation(requestBody).then(
                    (response) => {
                        console.log(response.data);
                        // to student
                        this.sendReportUpdateEmail({
                            "studentEmail": studentEmail,
                            // "classTimeAt": requestBody.classTimeAt.substring(0, requestBody.classTimeAt.length - 7),
                            "classTimeAt": this.timeToShowInReportEmail(this.convertToKST(requestBody.classTimeAt, timezone)),
                            "nextClassTimeAt": this.timeToShowInReportEmail(this.convertToKST(requestBody.nextClassTimeAt, timezone)),
                            "reportLink": reportLink,
                            "classReport": requestBody.classReport,
                            "homework": requestBody.homework,
                            "emailBody": "STUDENT_REPORT_NOTIFICATION",

                        });
                        // to teacher
                        this.sendReportUpdateEmail({
                            "teacherId": teacherId + "",
                            // "classTimeAt": this.convertToKST(requestBody.classTimeAt, timezone),
                            "studentEmail": studentEmail,
                            "classTimeAt": this.timeToShowInReportEmail(this.convertToKST(requestBody.classTimeAt, timezone)),
                            "nextClassTimeAt": this.timeToShowInReportEmail(this.convertToKST(requestBody.nextClassTimeAt, timezone)),
                            "reportLink": reportLink,
                            "emailBody": "TEACHER_REPORT_NOTIFICATION"
                        });
                        this.sendNotificationEmail({
                            "email": "support@ivyed.co.kr",
                            "teacherId": teacherId + "",
                            "studentEmail": studentEmail,
                            "classTimeAt": this.timeToShowInReportEmail(this.convertToKST(requestBody.classTimeAt, timezone)),
                            "emailBody": "CLASS_CREATED_NOTIFICATION"

                        });
                        console.log("here")
                        console.log(response.data)
                        console.log("here")
                        if (response.data) {
                            this.loading = false;
                            this.$router.push("/teacher/classreports")
                        }


                        // Code to be executed after the delay (3 seconds)
                        // this.$router.push("/teacher/classreports");

                        // This is where you can place the logic to hide the loading indicator or perform any other action


                        //
                        // // to teacher
                        // this.sendNotificationEmailByUserId({
                        //     "emailBody": "TEACHER_REPORT_NOTIFICATION",
                        // }, teacherId);
                    },
                    (error) => {
                        this.classContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log("error below")
                        console.log(error.response)
                    },
                )
            },


            getStudentInfoFromLessons(studentId) {
                for (let i = 0; i < this.lessons.length; i++) {
                    // console.log("what")
                    // console.log(this.lessons[i])
                    // console.log("what")
                    if (this.lessons[i].student_id == studentId) {
                        return this.lessons[i];
                    }
                }
            },

            submitTeacherInfo(userId, requestBody) {


                requestBody.registerStatus = 'PENDING'
                TeacherService.updateTeacherAboutTeacher(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        this.$router.push("/profile")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            parseLessonTimes() {
                for (let i = 0; i < this.allLessons.length; i++) {
                    this.allLessons[i].detailed_lesson_times = this.allLessons[i].detailed_lesson_times.replace(/\\/g, '');
                    let temp = this.allLessons[i].detailed_lesson_times
                    if (temp.charAt(0) == '"') {
                        temp = temp.substr(1);
                    }
                    if (temp.charAt(temp.length - 1) == '"') {
                        temp = temp.substr(0, temp.length - 1);
                    }
                    if (typeof temp == 'string' || temp instanceof String) {
                        temp = JSON.parse(temp);
                        this.allLessons[i].detailed_lesson_times = temp;
                    }
                }
            },

            parseLessonTimeJson(lessons) {

                for (let i = 0; i < lessons.length; i++) {
                    // console.log("here")
                    // console.log(lessons[i].detailed_lesson_times)
                    // console.log("here")

                }
            },

            setLessonStatuses(responseBody) {


                for (let i = 0; i < responseBody.lessons.length; i++) {
                    // console.log(responseBody.lessons[i].teacherMatchingStatus)
                    if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ASKED') {
                        this.teacherLessons.lesson_asked.push(responseBody.lessons[i])
                        this.lesson_asked = true
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'MESSAGE_SENT_BY_STUDENT') {
                        this.lesson_message_sent_by_student = true;
                        this.teacherLessons.lesson_message_sent_by_student.push(responseBody.lessons[i])
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'NO_LESSONS') {
                        this.teacherLessons.lesson_no_lessons.push(responseBody.lessons[i]);
                        this.lesson_no_lessons = true;
                    } else if (responseBody.lessons[i].teacherMatchingStatus == 'LESSON_ACCEPTED') {
                        this.teacherLessons.lesson_accepted.push(responseBody.lessons[i]);
                        this.lesson_accepted = true;
                    }

                }
                // console.log(this.lesson_message_sent_by_student)
            },

            getStudentInfo(studentId) {
                StudentService.getStudentInfo(studentId).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },

            getStudentInfoByLessonId(lessonId) {
                this.showLesson = true
                StudentService.getUserInfoByLessonId(lessonId).then(
                    (response) => {
                        this.lessonStudents = response.data;
                        // return response;
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            sendNotificationEmail(requestBody) {
                EmailService.sendNotificationToAdminClassCreated(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            proceedLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'TEACHER_ACCEPTED',
                    studentMatchingStatus: 'FOUND',
                    teacherMatchingStatus: 'TEACHER_PROCEEDING',
                },
                    // this.sendNotificationEmail({
                    //     'email': "to-student",
                    //     'lessonId': lessonId + "",
                    //     'emailBody': 'TEACHER_PROCEEDING'
                    // });
                    LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                        (response) => {
                            this.lessonResponseBody = response.data;
                            this.sendNotificationEmail({
                                'email': "to-student",
                                'lessonId': lessonId + "",
                                'emailBody': 'TEACHER_PROCEEDING'
                            });
                            window.location.reload();
                            return response.data;
                        },
                        (error) => {
                            this.lessonResponseBody =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
            },

            confirmLesson(lessonId) {
                this.lessonRequestBody = {
                    status: 'SCHEDULED',
                    studentMatchingStatus: 'MATCHED',
                    teacherMatchingStatus: 'LESSON_ACCEPTED',
                },

                    LessonService.updateLesson(lessonId, this.lessonRequestBody).then(
                        (response) => {
                            this.lessonResponseBody = response.data;
                            window.location.reload();

                            return response.data;
                        },
                        (error) => {
                            this.lessonResponseBody =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
            },


            rejectLesson(lessonId, requestBody) {
                LessonService.updateLesson(lessonId, requestBody).then(
                    (response) => {
                        this.lessonResponseBody = response.data;
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },


            getLessonInfo(lessonId) {
                LessonService.getLessonInfo(lessonId).then(
                    (response) => {
                        return response.data;
                    },
                    (error) => {
                        this.lessonResponseBody =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },


        }
    }
</script>

<style scoped>
    .popup-window {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .popup-content {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }

    .center {
        padding: 300px 0;
        /*border: 3px solid green;*/
        text-align: center;
    }

    .all-lessons-background {
        background-color: #F0F0F0;
        max-width: 800px;
        /*width: 100%;*/
    }

    /* CSS for Approach 1 or Approach 2 */

    .loading-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh; /* Set the height to full viewport height */
    }

    .center-vertical {
        flex-direction: column;
    }

    .center-horizontal {
        flex-direction: row;
    }

    .container {
        position: relative;
    }

    .center {
        padding: 300px 0;
        /*border: 3px solid green;*/
        text-align: center;
    }
</style>
