<template>
    <div>
        <v-container v-show="!loading" :class="{
                    // 'text-h4': $vuetify.breakpoint.lgAndUp,
                    'payment-background': $vuetify.breakpoint.smAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }">
            <!-- NEED TO ADD ONLY WHEN PAYMENT INFO IS AVAILABLE        -->

            <!--        {{enrollmentInfo}}-->
            <!--        <div v-if="!paymentInfo">-->
            <!--            <h2 class="text&#45;&#45;primary">Please fill out the payment form again</h2>-->
            <!--        </div>-->
            <!--        {{paymentInfo}}-->
            <RestrictedAcess v-if="studentContent.sumOfRemainingClasses > 0"></RestrictedAcess>
            <div v-if="studentContent.sumOfRemainingClasses <= 0">
                <!--            <v-toolbar-title>결제</v-toolbar-title>-->
                <v-card
                        class="mx-auto primary--text pa-5 ma-2"
                        max-width="750"
                        flat
                >

                    <v-card-title class="mt-5">
                        <!--                    <h1>상품 정보</h1>-->
                        <span
                                :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                        ><strong>수강 신청 정보</strong></span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-list-item three-line>
                        <v-list-item-content class="primary--text">
                            <!--                        <div class=" mb-4">-->
                            <!--                        <h1> 저희가  딱 맞는 선생님을 찾아드릴게요! </h1>-->
                            <!--                            <v-divider class="mb-3 mt-3"></v-divider>-->
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-3"><strong>희망수업 과목</strong></v-list-item-title>
                                    <span>{{this.studentContent.subjectsOfInterest.join(', ')}} - {{this.studentContent.subSubjects}}</span>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item three-line>
                                <v-list-item-content>
                                    <v-list-item-title><strong>수업 기간</strong></v-list-item-title>
                                    <span>
                                        {{this.studentContent.expectedLessonDuration}} 개월
                                    </span>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item three-line v-if="this.studentContent.subjectsOfInterest != 'Winter Camp'">
                                <v-list-item-content>
                                    <v-list-item-title><strong>횟수</strong></v-list-item-title>
                                    <span v-if="this.studentContent.subjectsOfInterest != 'AP Computer Science A Bootcamp'">
                                    주 {{(this.studentContent.expectedNumberOfLessonsPerWeek+"").charAt(0)}}회, {{(this.studentContent.expectedNumberOfLessonsPerWeek+"").substring(1,4)}}분 수업
                                        <!--                                        {{"주 " + (this.studentContent.expectedNumberOfLessonsPerWeek).charAt(0) + "회, " + (this.studentContent.expectedNumberOfLessonsPerWeek).substring(1,3)+"분 수업"}} • {{studentContent.expectedLessonDuration}}-->
                                </span>
                                    <span v-if="this.studentContent.subjectsOfInterest == 'AP Computer Science A Bootcamp'">
                                        주 1회, 4시간 수업
                                    </span>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item three-line v-if="this.studentContent.subjectsOfInterest == 'Winter Camp'">
                                <v-list-item-content>
                                    <v-list-item-title><strong>횟수</strong></v-list-item-title>
                                    <span v-if="this.studentContent.subjectsOfInterest != 'AP Computer Science A Bootcamp'">
                                    주 2회, 90분 수업
                                        <!--                                        {{"주 " + (this.studentContent.expectedNumberOfLessonsPerWeek).charAt(0) + "회, " + (this.studentContent.expectedNumberOfLessonsPerWeek).substring(1,3)+"분 수업"}} • {{studentContent.expectedLessonDuration}}-->
                                </span>
                                    <span v-if="this.studentContent.subjectsOfInterest == 'AP Computer Science A Bootcamp'">
                                        주 1회, 4시간 수업
                                    </span>
                                </v-list-item-content>
                            </v-list-item>


                            <!--                        <v-list-item two-line>-->
                            <!--                            <v-list-item-content>-->
                            <!--                                <v-list-item-title><strong>태블릿 선택</strong></v-list-item-title>-->
                            <!--                                <span>-->
                            <!--                                        {{this.studentContent.tablet}}-->
                            <!--                                    </span>-->

                            <!--                            </v-list-item-content>-->
                            <!--                        </v-list-item>-->
                            <!--                        </div>-->
                            <!--                    <v-list-item-title class="text-h5 mb-1">-->
                            <!--                        {{currentUser.lastName}} {{currentUser.firstName}} 님 환영합니다!-->
                            <!--                    </v-list-item-title>-->
                            <v-list-item-subtitle></v-list-item-subtitle>
                        </v-list-item-content>

                        <!--                    <v-list-item-avatar-->
                        <!--                            tile-->
                        <!--                            size="100"-->
                        <!--                    >-->
                        <!--                        &lt;!&ndash;                    <v-img src="../assets/IvyEd-cornell-color.png"></v-img>&ndash;&gt;-->
                        <!--                    </v-list-item-avatar>-->
                    </v-list-item>
                    <div v-if="currentUser">
                        <v-divider></v-divider>
                        <div class="text-center" v-if="studentContent.payableItem != 'payAmountDifferent'">
                            <v-btn
                                    color="primary"
                                    elevation="0"
                                    class="ma-4"
                                    to="/enroll"
                                    text
                            >
                                정보 변경 >
                            </v-btn>

                        </div>
                    </div>

                </v-card>
                <v-container fluid class="hidden-md-and-up payment-background"></v-container>

                <v-card
                        class="mx-auto primary--text pa-5"
                        max-width="750"
                        flat
                >
                    <v-card-title class="mt-5">
                    <span
                            :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                    ><strong>결제 금액</strong></span>
                    </v-card-title>
                    <v-divider class="mb-3 mt-3"></v-divider>
                    <v-list-item three-line>
                        <div class="primary--text">

                            <div class="text-overline mb-4">
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <div>

                                                    <v-list-item-title> 상품가격</v-list-item-title>
                                                </div>
                                            </v-col>
                                            <v-col>
                                                <div class="text-right" style="font-size: 16px">
                                                    {{(this.finalAmount ).toLocaleString()}} 원
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <div class="my-2"></div>


                                        <v-divider class="mt-2"></v-divider>
                                        <div class="my-4"></div>
                                        <v-row>
                                            <v-col>
                                                <v-list-item-title class="font-weight-bold" style="font-size: 20px"> 최종
                                                    결제 금액
                                                </v-list-item-title>
                                            </v-col>
                                            <v-col>
                                                <div class="text-right font-weight-bold" style="font-size: 20px">
                                                    {{(this.finalAmount).toLocaleString()}} 원
                                                </div>
                                            </v-col>
                                        </v-row>
                                        <!--                                        <v-list-item-subtitle class="font-weight-medium">-->
                                        <!--                                            {{studentContent.expectedLessonDuration}}개월 할부 시, 월-->
                                        <!--                                            {{(this.finalAmount/studentContent.expectedLessonDuration).toLocaleString()}}-->
                                        <!--                                            원-->
                                        <!--                                        </v-list-item-subtitle>-->
                                    </v-list-item-content>
                                </v-list-item>
                                <!--                            <v-list-item four-line>-->
                                <!--                                <v-list-item-content>-->
                                <!--                                    <v-list-item-title>{{studentContent.expectedLessonDuration}}개월 무이자 할부시</v-list-item-title>-->
                                <!--                                    <v-list-item-subtitle>-->

                                <!--                                        {{(this.requestBody.finalPrice/studentContent.expectedLessonDuration).toLocaleString()}} / 월-->
                                <!--                                    </v-list-item-subtitle>-->

                                <!--                                </v-list-item-content>-->
                                <!--                            </v-list-item>-->
                            </div>
                        </div>

                    </v-list-item>
                </v-card>

                <v-container fluid class="hidden-md-and-up  payment-background"></v-container>
                <v-form class=""
                        ref="form"
                        v-model="valid"
                >
                    <v-card
                            class="mx-auto primary--text pa-5 ma-2"
                            max-width="750"
                            flat
                    >
                        <v-card-title class="mt-5">
                    <span
                            :class="{
                    'text-h4': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                    ><strong>결제 수단</strong></span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid>
                            <v-radio-group v-model="radios" mandatory row>
                                <!--                                <template v-slot:label>-->
                                <!--                                    <div>Your favourite <strong>search engine</strong></div>-->
                                <!--                                </template>-->
                                <v-radio value="KoreaCard">
                                    <template v-slot:label>
                                        <div class="font-weight-bold">국내 카드</div>
                                    </template>
                                </v-radio>
                                <v-radio value="ForeignCard">
                                    <template v-slot:label>
                                        <div class="font-weight-bold">해외 카드</div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-container>
                        <template v-if="radios === 'KoreaCard'">
                            <v-row>
                                <v-col>
                                    <v-list-item-subtitle class="black--text mb-n4 mt-4">할부 개월</v-list-item-subtitle>
                                </v-col>
                                <v-col>
                                    <v-list-item-subtitle class="justify-right mb-n4  mt-4 margin-test">

                                        <v-dialog
                                                v-model="dialog"
                                                width="500"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <a
                                                        class="text-decoration-underline"
                                                        v-bind="attrs"
                                                        v-on="on">무이자 할부 가능
                                                </a>
                                                <v-icon class="" size="17px">mdi-information-outline</v-icon>


                                            </template>

                                            <v-card class="py-4 ">
                                                <v-card-subtitle class=" dialog-title">
                                                    무이자 할부 적용 가능 금액
                                                    <p class="dialog-subtitle">
                                                        5만원 이상
                                                    </p>
                                                </v-card-subtitle>

                                                <v-card-text>
                                                    <v-simple-table>
                                                        <template v-slot:default>
                                                            <thead>
                                                            <tr>
                                                                <th class="text-left" style="font-size: 16px">
                                                                    카드
                                                                </th>
                                                                <th class="text-left" style="font-size: 16px">
                                                                    무이자 혜택
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr
                                                                    v-for="(item,i) in financingBenefits"
                                                                    :key="i"
                                                            >
                                                                <td>{{ item.bank }}</td>
                                                                <td>{{ item.benefit }}</td>
                                                            </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                    <!--                                                BC 2 ~ 7개월 무이자 <br/>-->
                                                    <!--                                                KB국민 2 ~ 7개월 무이자 <br/>-->
                                                    <!--                                                하나(외환) 2 ~ 8개월 무이자 <br/>-->
                                                    <!--                                                삼성 2 ~ 6개월 무이자 <br/>-->
                                                    <!--                                                신한 2 ~ 7개월 무이자 <br/>-->
                                                    <!--                                                현대 2 ~ 7개월 무이자 <br/>-->
                                                    <!--                                                롯데 2 ~ 4개월 무이자 <br/>-->
                                                    <!--                                                NH농협 2 ~ 8개월 무이자 <br/>-->
                                                    <!--                                                전북 2 ~ 5개월 무이자 <br/>-->
                                                    <!--                                                우리 2 ~ 7개월 무이자 <br/>-->
                                                </v-card-text>

                                                <v-divider></v-divider>
                                                <v-card-actions class="justify-end">
                                                    <v-btn
                                                            text
                                                            @click="dialog = false"
                                                    >닫기
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>


                                    </v-list-item-subtitle>
                                </v-col>
                            </v-row>
                            <v-select
                                    v-model="requestBody.financing"
                                    :items="financingOptions"
                                    class="form-input-text"
                                    :rules="[rules.required, rules.listNotEmpty]"
                                    required
                            ></v-select>
                            <v-list-item-subtitle class="black--text mt-4 mb-n4">카드 번호</v-list-item-subtitle>
                            <v-text-field
                                    v-model="requestBody.cardNumber"
                                    :counter="16"
                                    :rules="[rules.creditCardNumberRules]"
                                    placeholder="'-' 생략하고 입력"
                                    class="form-input-text"
                                    required
                            ></v-text-field>

                            <v-list-item-subtitle class="black--text mb-n4 mt-4">카드 유효기간</v-list-item-subtitle>
                            <v-text-field
                                    v-model="requestBody.expiration"
                                    :rules="[rules.creditCardExpirationRules]"
                                    placeholder="월 (MM) / 년 (YY)"

                                    class="form-input-text"
                                    required
                            ></v-text-field>
                            <v-list-item-subtitle class="black--text mb-n4 mt-4">비밀번호 앞 2자리</v-list-item-subtitle>
                            <v-text-field
                                    v-model="requestBody.cardPassword"
                                    :counter="2"
                                    :rules="[rules.creditCardPasswordRules]"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"

                                    class="form-input-text"
                                    @click:append="showPassword = !showPassword"
                                    :type="showPassword ? 'text' : 'password'"


                                    placeholder="**"
                                    required
                            ></v-text-field>
                            <v-list-item-subtitle class="black--text mb-n4 mt-4">생년월일 6자리 (개인) / 사업자 번호 10자리 (법인)
                            </v-list-item-subtitle>

                            <v-text-field
                                    v-model="requestBody.cardBirthday"
                                    :counter="10"
                                    :rules="[rules.required ]"
                                    class="form-input-text"
                                    placeholder="'-' 생략하고 입력"
                                    required
                            ></v-text-field>

<!--                            <p class="mt-4 msg-text black&#45;&#45;text">* 결제한 수업이 모두 진행되면, <strong>위 카드로 다음 과외가 자동 결제</strong>됩니다.-->
<!--                            </p>-->
<!--                            <p class="msg-text black&#45;&#45;text">* 카드정보는 <strong>NICEPAY</strong> 전자결제 회사에서 암호화해 안전하게 관리합니다.-->
<!--                            </p>-->
                            <v-container class="text-center" fluid>

                                <v-btn
                                        color="secondary"
                                        class="mr-4"
                                        x-large
                                        :disabled="!valid"
                                        @click="createStudentPayment(requestBody, currentUser.id)"
                                >
                                    카드 정보 등록 및 결제하기
                                </v-btn>
                            </v-container>
                            <div v-if="message">
                                <v-alert type="error">
                                    {{ message }}
                                </v-alert>
                            </div>
                        </template>

                        <template v-if="radios === 'ForeignCard'">
                            <div class="text-center my-5">

                            </div>
                            <v-container class="text-center" fluid>

                                <v-btn
                                        color="secondary"
                                        class="mr-4"
                                        x-large
                                        @click="requestPay"
                                >
                                    해외카드 결제하기
                                </v-btn>


                            </v-container>
                            <div v-if="message">
                                <v-alert type="error">
                                    {{ message }}
                                </v-alert>
                            </div>
                        </template>

                    </v-card>
                </v-form>
            </div>
        </v-container>
        <div v-show="loading" class="center">
            <v-progress-circular
                    :size="60"
                    :width="7"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
            <!--            <LoadingAnimation></LoadingAnimation>-->
        </div>
    </div>
</template>

<script>
    import info from "../../../info";
    import StudentService from "../../services/student.service";
    import EmailService from "../../services/email.service";
    import RestrictedAcess from "../helpers/RestrictedAcess";
    import jQuery from 'jquery'

    var IMP = window.IMP; // 생략 가능
    IMP.init("imp61970794"); // 예: imp00000000
    export default {
        name: "Payment",
        components: {RestrictedAcess},
        computed: {
            enrollmentInfo() {
                return this.$store.state.enrollmentData;
            },

            paymentInfo() {
                return this.$store.state.studentRegisterData;
            },
            currentUser() {
                return this.$store.state.auth.user;
            },
            totalLessons() {
                return this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 4;
            },
            discountedRate() {
                if (this.studentContent.expectedLessonDuration == 1) {
                    return 20;
                } else if (this.studentContent.expectedLessonDuration == 3) {
                    return 40;
                } else if (this.studentContent.expectedLessonDuration == 12) {
                    return 40;
                } else if (this.studentContent.expectedLessonDuration == 2) {
                    return 28;
                }
                return "";
            },
            originalPrice() {
                if(this.studentContent.payableItem == 'payAmountDifferent') {
                    return this.finalAmount;
                }

                if (this.studentContent.subSubjectss == 'AP CS Principles' || this.studentContent.subSubjects == 'AP CS A' || this.studentContent.subSubjects == 'CS & Programming'
                    || this.studentContent.subSubjects == 'Multi-variate Calculus'
                    || this.studentContent.subSubjects == 'AP Physics C Mech' || this.studentContent.subSubjects == 'AP Physics C E&M') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.priceFromSelection = 100000
                } else if (this.studentContent.subSubjects == 'Test') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.priceFromSelection = 1000;
                } else if (this.studentContent.subSubjects == 'AP Calc AB' || this.studentContent.subSubjects == 'AP Calc BC' || this.studentContent.subSubjects == 'AP Statistics'
                    || this.studentContent.subSubjects == 'AP Physics 1' || this.studentContent.subSubjects == 'AP Physics 2'
                    || this.studentContent.subSubjects == 'AP Chemistry' || this.studentContent.subSubjects == 'AP Environmental Science'
                    || this.studentContent.subSubjects == 'AP Biology') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.priceFromSelection = 100000;
                } else if (this.studentContent.subSubjects == 'Calculus' || this.studentContent.subSubjects == 'Statistics') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.priceFromSelection = 100000;
                } else {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.priceFromSelection = 100000;
                }
                // eslint-disable-next-line no-unused-vars
                let perMonthPrice = 0;

                if (this.studentContent.expectedNumberOfLessonsPerWeek == 160) {
                    perMonthPrice = 439000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 190) {
                    perMonthPrice = 659000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 260) {
                    perMonthPrice = 799000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 290) {
                    perMonthPrice = 1139000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 360) {
                    perMonthPrice = 1139000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 390) {
                    perMonthPrice = 1619000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 1602) {
                    perMonthPrice = 800000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 1902) {
                    perMonthPrice = 1200000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 2602) {
                    perMonthPrice = 1550000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 2902) {
                    perMonthPrice = 2320000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 3602) {
                    perMonthPrice = 2320000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 3902) {
                    perMonthPrice = 3480000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 4602) {
                    perMonthPrice = 3100000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 460) {
                    perMonthPrice = 1519000
                } else if (this.studentContent.expectedNumberOfLessonsPerWeek == 2150) {
                    perMonthPrice = 999000
                } else if(this.studentContent.subjectsOfInterest == 'AP Computer Science A Bootcamp') {
                    perMonthPrice = 2400000
                } else if(this.studentContent.expectedNumberOfLessonsPerWeek == 3120) {
                    perMonthPrice = 2269000
                } else if(this.studentContent.expectedNumberOfLessonsPerWeek == 590) {
                    perMonthPrice = 2758000
                } else if(this.studentContent.subjectsOfInterest == 'Winter Camp') {
                    perMonthPrice = 480000
                }

                // return this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * this.priceFromSelection * 4
                return perMonthPrice
            }
            ,
            discountedAmount() {
                return this.originalPrice * .01 * this.discountedRate;
            },
            finalAmount() {
                if(this.studentContent.payableItem == 'payAmountDifferent') {
                    return this.studentContent.payments[this.studentContent.payments.length - 1].finalPrice;
                } else {
                    return this.originalPrice
                }

            },
            selectedDiscountRate() {
                if (this.studentContent.expectedLessonDuration == 1) {
                    return '20%';
                } else if (this.studentContent.expectedLessonDuration == 3) {
                    return '40%'
                } else if (this.studentContent.expectedLessonDuration == 12) {
                    return '40%'
                } else if (this.studentContent.expectedLessonDuration == 2) {
                    return '28%'
                }
                return '';
            },
            isTeacher() {
                // if teacher enters this page -> lead to profile page
                // if student has remaining lessons -> profile
                return this.currentUser.roles[0] == 'ROLE_TEACHER';
            },
            hasAlreadyPaid() {
                return this.studentContent.sumOfRemainingClasses > 0;
            }
        },

        created() {
            if (this.isTeacher) {
                // console.log("in isTeacher")
                this.$router.push("/profile");
            }
            if (this.hasAlreadyPaid) {
                // console.log("hasAlreadyPaid")

                this.$router.push("/profile");
            }
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
            // if (this.currentUser.roles[0] != 'ROLE_STUDENT') {
            //     this.$router.push('/profile');
            // }

            this.loading = true;
            this.requestBody.finalPrice = 120000 * parseInt(this.studentContent.expectedNumberOfLessonsPerWeek)
            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    // this.studentContent.subjectsOfInterest = this.removeEmptyStringFromList(this.studentContent.subjectsOfInterest);
                    // console.log("IN GET STUDENT INFO")
                    this.calculatePrices(this.studentContent)
                    // console.log("IN GET STUDENT INFO")
                    // console.log(this.$router.history)
                    // if (this.$router.currentRoute.name == 'payment' && this.studentContent.payments[0].active == true) {
                    //     this.$router.push('/profile')
                    // }
                    // console.log(this.$router.currentRoute == 'payment' && )
                    // if ( this.studentContent.payments[0].active == true) {
                    //     this.$router.push('/profile')
                    // }
                    this.loading = false;

                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );

        },
        methods: {
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            requestPay: function () {
                // IMP.request_pay(param, callback) 결제창 호출
                IMP.request_pay({ // param
                    pg: "eximbay",
                    pay_method: "card",
                    currency: 'KRW',
                    merchant_uid: this.currentUser.firstName + 0 + this.studentContent.expectedLessonDuration + this.studentContent.expectedNumberOfLessonsPerWeek + this.currentUser.id + Date.now(),
                    name: this.createOrderName(),
                    amount: Math.round(this.originalPrice),
                    buyer_email: this.currentUser.email,
                    buyer_name: this.currentUser.firstName + " " + this.currentUser.lastName,
                    buyer_tel: this.currentUser.countryCode + " " + this.currentUser.phoneNumber,
                    buyer_addr: "",
                    buyer_postcode: ""
                }, rsp => { // callback
                    if (rsp.success) { // 결제 성공 시: 결제 승인 또는 가상계좌 발급에 성공한 경우
                        // jQuery로 HTTP 요청
                        jQuery.ajax({
                            url: "https://www.ivyed.co.kr/payment/confirmation", // 예: https://www.myservice.com/payments/complete
                            method: "POST",
                            headers: {"Content-Type": "application/json"},
                            data: {
                                imp_uid: rsp.imp_uid,
                                merchant_uid: rsp.merchant_uid
                            }
                        }).done(function (data) {
                            console.log(data)
                            // 가맹점 서버 결제 API 성공시 로직

                        });
                        let requestBody = {};
                        requestBody.originalPrice = Math.round(this.originalPrice) + ""
                        requestBody.discountedPrice = Math.round(this.discountedAmount) + ""
                        requestBody.name = this.createOrderName();
                        requestBody.merchantUID = this.createOrderNumber();
                        //
                        StudentService.createStudentPaymentForeignCard(requestBody, this.currentUser.id).then(
                            (response) => {
                                this.paymentResponse = response.data;
                                this.sendNotificationEmail({
                                    'email': this.currentUser.email,
                                    'emailBody': 'orderConfirmation'
                                })
                                this.$router.push("/payment/confirmation")
                            },
                            (error) => {
                                this.teacherContent =
                                    (error.response &&
                                        error.response.data &&
                                        error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.handleErrorMessages(error);
                            }
                        )
                    } else {
                        console.log(rsp)
                        alert("결제에 실패하였습니다. 에러 내용: " + rsp.error_msg);
                    }
                });
            },


            calculatePrices() {
                this.requestBody.originalPrice = this.originalPrice
                // test- 20% discount
                // this.requestBody.discountedPrice = studentContent.expectedNumberOfLessonsPerWeek * this.originalPrice * studentContent.expectedLessonDuration * 4
                this.requestBody.finalPrice = this.originalPrice
            },
            calculateDiscountPrice(price) {
                let discounted = parseFloat(price) * 0.6;
                return discounted;
            },
            finalPrice(price) {
                return this.$store.state.studentRegisterData.duration.replace(/[^\d.]/g, '') * price;
            },
            applyFinancing(price) {
                return price / 12;
            },
            validate() {
                this.$refs.form.validate()
            },

            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            createOrderName() {
                if (this.studentContent.subjectsOfInterest == 'Mathematics / Computer Science') {
                    return "아이비에드 Math/CS 과외";
                } else if (this.studentContent.subjectsOfInterest == 'Science') {
                    return "아이비에드 Science 과외";
                } else if (this.studentContent.subjectsOfInterest == 'Reading / Writing') {
                    return "아이비에드 Reading / Writing 과외";
                }
            },

            createOrderNumber() {
                return 0 + this.studentContent.expectedLessonDuration + this.studentContent.expectedNumberOfLessonsPerWeek;
            },


            createStudentPayment(requestBody, userId) {
                requestBody.originalPrice = Math.round(this.originalPrice) + ""
                requestBody.finalPrice = Math.round(this.originalPrice) + ""
                requestBody.discountedPrice = (this.discountedAmount) + ""
                requestBody.name = this.createOrderName();
                requestBody.merchantUID = this.createOrderNumber();
                // console.log(requestBody.originalPrice);
                // console.log(requestBody.finalPrice);
                // console.log(requestBody.discountedPrice);
                // console.log(userId);
                // requestBody.finalPrice = 100 +"";
                // window.alert(requestBody.originalPrice + ", " + requestBody.finalPrice + ", " + requestBody.discountedPrice + ", " + requestBody.name + ", " + requestBody.merchantUID)
                StudentService.createStudentPayment(requestBody, userId).then(
                    (response) => {
                        this.paymentResponse = response.data;
                        this.sendNotificationEmail({
                            'email': this.currentUser.email,
                            'emailBody': 'orderConfirmation'
                        })
                        this.$router.push("/payment/confirmation")
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.handleErrorMessages(this.teacherContent);
                    }
                )
            },
            handleErrorMessages(message) {
                // this.message = message;
                if (message.includes("유효하지않은 카드번호를")) {
                    this.message = "카드정보 인증에 실패하였습니다 - 유효하지않은 카드번호를 입력하셨습니다."
                } else if (message.includes("유효기간")) {
                    this.message = "카드정보 인증에 실패하였습니다 - 유효기간 오류";
                } else if (message.includes("F113")) {
                    this.message = message;
                } else if (message.includes("비밀번호")) {
                    this.message = "카드정보 인증에 실패하였습니다 - 비밀번호 오류";
                } else if (message.includes("주민")) {
                    this.message = "카드정보 인증에 실패하였습니다 - 주민번호 or 사업자등록 번호 오류";
                } else if (message.includes("한도초과")) {
                    this.message = "[오류] 카드 한도초과";
                } else if (message.includes("할부개월오류")) {
                    this.message = "[오류] 할부개월 오류입니다.";
                } else {
                    this.message = message;
                }
                // else if (message.includes("Email")) {
                //     this.message = "이미 사용중인 이메일 주소입니다."
                // } else if (message.includes("parse")) {
                //     this.message = "위 필드를 모두 입력하셔야 가입이 가능합니다."
                // } else if (message.includes("Bad")) {
                //     this.message = "아이디 혹은 비밀번호가 일치하지 않습니다."
                // } else if (message.includes("disabled")) {
                //     this.message = "이메일 인증을 진행 해주세요."
                // }
            },
        },
        data() {
            return {
                priceForForeignCard: 0,
                priceFromSelection: 0,
                vat: 1.1,
                financingBenefits: info.financingBenefits,
                loading: true,
                rules: {
                    required: (value) => !!value || '필수 입력',
                    listNotEmpty: (value) => value.length > 0 || '필수 선택',
                    creditCardNumberRules: ((value) => !!value || '필수 입력') && ((value) => (value.length == 16 || value.length == 15) || ' \'-\' 을 제외한 16자리 카드번호를 입력해주세요.'),
                    creditCardExpirationRules: ((v) => !!v || '필수 입력') && (v => (v.match('[0-90-9]\\/[0-90-9]')) || ' MM/YY 형식으로 입력해주세요. (예. 02/24)'),
                    creditCardPasswordRules: ((v) => (v.match('[0-9][0-9]')) || ' 비밀번호 첫 두자리 숫자를 입력해주세요.') && (v => (v.length == 2) || '비밀번호 두자리만 입력해주세요'),
                    creditCardBirthDayRules: ((v) => (v.length == 6 || v.length == 8) || "올바른 번호를 입력해주세요."),

                },
                paymentResponse: "",
                message: "",
                dialog: false,
                showPassword: false,
                studentContent: "",
                agreements: {
                    student: {
                        checkbox: false,
                        title: "(학생 동의 항목) 아래의 약관 및 개인정보 처리에 모두 동의합니다.",
                        content: "",
                    },
                    parents: {
                        checkbox: false,
                        title: "(학부모 동의 항목) 아래의 약관 및 개인정보 처리에 모두 동의합니다. ",
                        content: "",
                    }
                },
                requestBody: {
                    // subjectsOfInterest: this.$store.state.studentRegisterData.subjectsOfInterest,
                    // currentLocation: this.$store.state.studentRegisterData.currentLocation,
                    // frequency: this.$store.state.studentRegisterData.expecte,
                    // duration: this.$store.state.studentRegisterData.duration,
                    // tablet: this.$store.state.studentRegisterData.tablet,
                    originalPrice: "",
                    discountRate: "",
                    discountedPrice: "",
                    paidAmount: "",
                    paymentType: "",
                    finalPrice: "",
                    cardNumber: "",
                    expiration: "",
                    cardPassword: "",
                    birthday: "",
                    financing: "",
                },
                // temp: this.requestBody.financing.replace(/[^0-9.]+/g, ""),
                radios: null,
                nameRules: [
                    v => !!v || '필수 입력',
                    v => (v && v.length <= 10) || 'Name must be less than 10 characters',
                ],
                cardNumberRules: [
                    // v => ,
                    v => !!v || '필수 입력' && (v.length == 16 || v.length == 15) || ' \'-\' 을 제외한 16자리 카드번호를 입력해주세요.',
                ],
                cardExpirationRules: [
                    v => !!v || '필수 입력',
                    v => (v.match('[0-90-9]\\/[0-90-9]')) || ' MM/YY 형식으로 입력해주세요. (예. 02/24)',
                ],
                cardPasswordRules: [
                    v => !!v || '필수 입력',
                    v => (v.match('[0-9][0-9]')) || ' 비밀번호 첫 두자리 숫자를 입력해주세요.',
                    v => (v.length == 2) || '비밀번호 두자리만 입력해주세요'
                ],
                birthdayRules: [
                    v => !!v || '필수 입력',
                    v => (v.length >= 6) || '생년월일은 6자리, 사업자 번호는 10자리 입력하여 주세요.',
                ],
                financingOptions: info.financingOptions,
                valid: false,
            }
        },
    }
</script>

<style scoped>
    .payment-background {
        background-color: #F7F8FA;
    }

    .form-input-text {
        font-size: 14px;
    }

    .justify-right {
        text-align: end;
    }

    .margin-test {
        font-size: 12px;

    }

    a:link {
        color: dimgray;
        background-color: transparent;
        text-decoration: underline;

    }

    .dialog-title {
        font-size: 18px;
        font-weight: bold;
    }

    .dialog-subtitle {
        font-size: 17px;
        font-weight: bold;
    }

    .msg-text {
        font-size: 10px;
    }

    .highlight-container, .highlight {
        position: relative;
        /*font-weight: bold;*/
        color: red;
    }

    .highlight-container {
        display: inline-block;
    }

    .highlight-container:before {
        content: " ";
        display: block;
        height: 90%;
        width: 100%;
        margin-left: -3px;
        margin-right: -3px;
        position: absolute;

        background: #FEDFDD;
        transform: rotate(2deg);
        top: -1px;
        left: -1px;
        border-radius: 20% 25% 20% 24%;
        padding: 10px 3px 3px 10px;
    }

    .center {
        padding: 300px 0;
        /*border: 3px solid green;*/
        text-align: center;
    }
</style>
