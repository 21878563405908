import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from './plugins/vuetify'
import Vuex from 'vuex';
import VueRouter from "vue-router";
import Meta from 'vue-meta'
// import VueAnalytics from "vue-analytics";
import VueGtag from 'vue-gtag'
import AOS from 'aos';
import 'aos/dist/aos.css'


Vue.config.productionTip = false;
// Vue.use(router);
// Vue.use(store);

Vue.use(VueRouter);
Vue.use(Vuex);

AOS.init();
// Vue.use(VueAnalytics, {
//   id:'321176109',
//   router
// });
Vue.use(VueGtag, {
    config: {
        id: 'G-XSHBZ4YD0F'
    },
});
// Vue.use(require('prerender-node').set('prerenderToken', '5zFoEAuekzeRhgKrrRkz'));

Vue.use(Meta);

const app = new Vue({
    router: router,
    store,
    vuetify,
    render: (h) => h(App)
});
// app.use(router);
// app.use(store);
app.$mount("#app");

